const city = {
  state: {
    cityName: '',
    citySelectedDataList: [],
    citySelected: {},
    totalConfirmedCases: '0',
    itemsCitySelected: [
      {
        dataDeAtualização: '',
        confirmados: '',
        obitos: '',
        novosCasos: '',
        novosObitos: '',
        letalidade: '',
      },
    ],
  },
  mutations: {
    SET_CITY_NAME(state, cityName) {
      state.cityName = cityName;
    },
    SET_CITY_SELECTED_DATA_LIST(state, citySelectedDataList) {
      state.citySelectedDataList = citySelectedDataList;
    },
    SET_CITY_SELECTED(state, citySelected) {
      console.log('oxiiii');
      citySelected.totalCases =
        citySelected.totalCases.indexOf('.') > -1
          ? citySelected.totalCases
          : parseInt(citySelected.totalCases).toLocaleString('pt-BR');

      citySelected.newCases =
        citySelected.newCases.indexOf('.') > -1
          ? citySelected.newCases
          : parseInt(citySelected.newCases).toLocaleString('pt-BR');

      citySelected.deaths =
        citySelected.deaths.indexOf('.') > -1
          ? citySelected.deaths
          : parseInt(citySelected.deaths).toLocaleString('pt-BR');

      citySelected.newDeaths =
        citySelected.newCases.indexOf('.') > -1
          ? citySelected.newCases
          : parseInt(citySelected.newDeaths).toLocaleString('pt-BR');

      state.citySelected = citySelected;
    },
    SET_TOTAL_CONFIRMED_CASES(state, totalConfirmedCases) {
      state.totalConfirmedCases = totalConfirmedCases;
    },
    SET_ITEMS_CITY_SELECTED(state, itemsCitySelected) {
      state.itemsCitySelected = itemsCitySelected;
    },
  },
  actions: {
    changeCityName(context, cityName) {
      context.commit('SET_CITY_NAME', cityName);
    },
    changeTotalConfirmerdCases(context, totalConfirmedCases) {
      context.commit('SET_TOTAL_CONFIRMED_CASES', totalConfirmedCases);
    },
    changeCitySelected(context, citySelected) {
      context.commit('SET_CITY_SELECTED', citySelected);
    },
    changeCitySelectedDataList(context, citySelectedDataList) {
      context.commit('SET_CITY_SELECTED_DATA_LIST', citySelectedDataList);
    },
    changeItemsCitySelected(context, itemsCitySelected) {
      context.commit('SET_ITEMS_CITY_SELECTED', itemsCitySelected);
    },
  },
  getters: {},
};

export { city };
