<template>
  <div class="pageBody">
    <BarHeader :activatePageBar="'PageCovid'" />
    <div class="buscar">
      <div class="box-buscar">
        <div class="input-select">
          <div class="icon-maker">
            <span class="material-icons">
              location_on
            </span>
          </div>
          <select
            v-model="geojsonMapRef"
            required
            class="select-custom"
            name="region"
            id="region"
          >
            <option value="" disabled selected>Região</option>
            <option value="Ceará">Ceará</option>
            <option value="Sertão dos Crateús">Sertão dos Crateús</option>
          </select>
        </div>
        <button @click="search()" class="search">Pesquisar</button>
      </div>
    </div>
    <div class="container">
      <div class="view-data">
        <div class="informacoes">
          <div class="cards-info">
            <div class="painel-info">
              <h3 class="text-custom-color-title">PAINEL CORONAVÍRUS</h3>

              <p class="text-custom-color" id="updateDate">
                ATUALIZAÇÃO: {{ this.updateDate }}
              </p>
              <p class="text-custom-color">CIDADE: {{ cityName }}</p>
              <p class="text-custom-color">
                Selecione uma cidade no mapa para ver as informações
              </p>
              <!-- <p id="nameCity"></p> -->
            </div>
            <div class="cards-covid">
              <CardHome :styleCard="cardConfirmados" />
              <CardHome :styleCard="cardObitos" />
              <CardHome :styleCard="cardLetalidade" />
              <!-- <CardHome :styleCard="cardRecuperados" /> -->
            </div>
            <div class="painel-info">
              <p class="text-custom-color">
                Os dados exibidos estão sendo contabilizados desde o primerio
                registro do Covid-19 no Brasil quarta-feira, 26/02/2020
              </p>
              <p class="text-custom-color" id="source">
                FONTE: {{ this.source }}
              </p>
            </div>
          </div>
          <div class="map-info">
            <div>
              <span id="doenca"></span>
              <div id="mapContainer"></div>
            </div>
          </div>
        </div>
        <!-- <div class="text-title">
          <h1>Cidades da região do Sertão dos Crateús</h1>
          <p>confiras os dados das cidades no grafico</p>
        </div> -->
        <div class="chartCovidRegion">
          <div class="text-title">
            <h1 class="text-custom-color-title">
              Região do Sertão dos Crateús
            </h1>
            <p class="text-custom-color">
              No gráfico abaixo é exibido o total de casos da covid-19 por cada
              cidade que faz parte da região do Sertão dos Crateús.
            </p>
          </div>
          <div class="boxContentChart">
            <div class="chart-container">
              <canvas id="chartCitiesRegion"></canvas>
            </div>
          </div>
        </div>

        <div class="table-data">
          <div class="text-title">
            <!-- <h1>Tabela das cidades da região do Sertão dos Crateús</h1> -->
            <p class="text-custom-color">
              Na tabela abaixo é exibido os dados do covid-19 por cada cidade
              que faz parte da região Sertão dos Crateús
            </p>
          </div>
          <div class="table-data-style">
            <b-table :items="items" :fields="fields" caption-top>
              <template class="title-table" #table-caption
                >Síntese de casos, óbitos, incidência e mortalidade</template
              >
            </b-table>
          </div>
        </div>

        <!-- INformações pela cidade em destaque -->
        <div class="chartCovidCity">
          <div class="text-title">
            <div>
              <label class="intervalDays" for="range-1"
                ><p class="text-custom-color-title">
                  SELECIONE O INTERVALO DE DIAS:
                </p>
                <div class="intervalValue text-custom-color-title">
                  <strong>{{ intervalValue }}</strong>
                </div></label
              >

              <b-form-input
                id="range-1"
                v-model="intervalValue"
                type="range"
                min="1"
                max="31"
              ></b-form-input>
            </div>

            <h1 class="text-custom-color-title">
              Gráfico referente a cidade {{ cityName }}
            </h1>

            <p class="text-custom-color">
              No gráfico abaixo é exibido o total de casos do covid-19 pelo
              intervalo de dias na cidade cidade selecionada
            </p>
          </div>
          <div class="boxContentChart" id="chartCitySelected">
            <div class="chart-container">
              <canvas id="chartFeaturedCity"></canvas>
            </div>
          </div>
        </div>

        <div class="table-data table-data-city">
          <div class="text-title">
            <h1 class="text-custom-color-title">
              Tabela referente a cidade {{ cityName }}
            </h1>

            <p class="text-custom-color">
              Na tabela abaixo é exibido os dados do covid-19 pela cidade
              selecionada no mapa. Os dados são mostrados de acordo com o
              intervalo selecionado.
            </p>
          </div>
          <div class="table-data-style" id="table-data-city-selected">
            <b-table
              :items="itemsCitySelected"
              :fields="fieldsCitySelected"
              caption-top
            >
              <template class="title-table" #table-caption
                >Síntese de casos, óbitos, incidência e mortalidade</template
              >
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <BarFooter />
  </div>
</template>

<script>
import BarHeader from '@/components/BarHeader.vue';
import CardHome from '@/components/CardHome.vue';
import BarFooter from '@/components/BarFooter.vue';
import BarChart from '@/components/BarChart.vue';

import config from '../config';

import store from '../store';
import Chart from 'chart.js';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import { geojsonState } from '../data/geojson';
import { geojsonRegion } from '../data/geojsonRegionSertaoCrateus';
import AppVue from '../App.vue';

export default {
  name: 'PageCovid',
  components: {
    BarHeader,
    CardHome,
    BarFooter,
    BarChart,
  },
  data() {
    return {
      cardConfirmados: {
        color: '#089C17',
        title: 'Confirmados',
        totalCasos: store.state.city.totalCases,
        novosCasos: '20.200',
        height: '106px',
        width: '335px',
        marginRight: '17px',
        cardStyleType: 'pageCovid',
        marginBottom: '20px',
      },
      cardObitos: {
        color: '#F91010',
        title: 'Óbitos',
        totalCasos: '100.000',
        novosCasos: '50.200',
        height: '106px',
        width: '335px',
        marginRight: '17px',
        cardStyleType: 'pageCovid',
        marginBottom: '20px',
      },
      cardLetalidade: {
        color: '#B4A200',
        title: 'Letalidade',
        totalCasos: '40.000',
        novosCasos: '210.200',
        height: '106px',
        width: '335px',
        marginRight: '17px',
        cardStyleType: 'pageCovid',
        marginBottom: '20px',
      },
      cardRecuperados: {
        color: '#0FB5DA',
        title: 'Recuperados',
        totalCasos: '120.000',
        novosCasos: '500',
        height: '106px',
        width: '335px',
        marginRight: '17px',
        cardStyleType: 'pageCovid',
        marginBottom: '20px',
      },
      center: [-5.31664, -39.5423],
      geojsonMapData: geojsonRegion,

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      fields: [
        'cidade',
        'confirmados',
        'obitos',
        'novosCasos',
        'novosObitos',
        'letalidade',
        'dataDeAtualização',
      ],
      items: [
        {
          cidade: '',
          confirmados: '',
          obitos: '',
          novosCasos: '',
          novosObitos: '',
          letalidade: '',
          dataDeAtualização: '',
        },
      ],
      fieldsCitySelected: [
        'dataDeAtualização',
        'confirmados',
        'obitos',
        'novosCasos',
        'novosObitos',
        'letalidade',
      ],
      // itemsCitySelected: [],
      baseURICovid: config.BACKEND_URL + '/covidRegionCrateus',
      baseURICovidInterval:
        config.BACKEND_URL + '/covidRegionCrateusQuantity',
      dataCovid: [],
      dataCovidRegion: [],
      updateDate: '',
      source: '',
      highlightCity: '',
      ibgeIdListCitiesRegion: [
        '2301257',
        '2303659',
        '2304103',
        '2305209',
        '2305605',
        '2305654',
        '2305902',
        '2308609',
        '2309300',
        '2309409',
        '2311009',
        '2312205',
        '2313203',
      ],
      dataIntervalCovid: [],
      dataCitySelected: [],
      geojsonMapRef: '',
      myMap: {},
      intervalValue: 1,
      // darkMode: false,
    };
  },
  methods: {
    search() {
      if (this.geojsonMapRef === 'Sertão dos Crateús')
        this.geojsonMapData = geojsonRegion;
      else this.geojsonMapData = geojsonState;

      this.myMap.remove();
      this.myMap = L.map('mapContainer').setView([-4.81664, -39.5423], 7.5);

      this.setupLeafletMap(this.dataCovid[0]);
    },

    createTableRegion() {
      var date = [],
        listItems = [];

      this.dataCovidRegion.forEach(function(index) {
        date = index.last_info_date.split('-');
        listItems.push({
          cidade: index.city,
          confirmados: index.totalCases,
          obitos: index.deaths,
          novosCasos: index.newCases,
          novosObitos: index.newDeaths,
          letalidade: index.deaths_by_totalCases,
          dataDeAtualização: date[2] + '/' + date[1] + '/' + date[0],
        });
      });
      this.items = listItems;
    },

    createChart(
      chartId,
      label,
      values,
      backgroundColorChart,
      boderColorChat,
      typeChart,
      options
    ) {
      const ctx = document.getElementById(chartId);

      const myChart = new Chart(ctx, {
        type: typeChart,
        data: {
          labels: label,
          datasets: [
            {
              label: 'Total de Casos',
              data: values,
              backgroundColor: backgroundColorChart,
              borderColor: boderColorChat,
              borderWidth: 3,
            },
          ],
        },

        options: { options },
        // responsive: true,
        plugins: {
          title: {
            display: true,
            text: (ctx) =>
              'Tooltip position mode: ' +
              ctx.chart.options.plugins.tooltip.position,
          },
        },
      });
    },

    filterCrateusRegionCities() {
      this.dataCovidRegion = this.dataCovid[0].listOfCities.filter((city) => {
        for (var i = 0; i < this.ibgeIdListCitiesRegion.length; i++) {
          if (city.ibgeID === this.ibgeIdListCitiesRegion[i]) return true;
        }
        return false;
      });
    },

    setupLeafletMap(dataCovid) {
      var geojson;
      var info = L.control();

      info.onAdd = function(mymap = this.myMap) {
        this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
        this.update();
        return this._div;
      };

      // method that we will use to update the control based on feature properties passed
      info.update = function(props) {};

      info.addTo(this.myMap);

      // ----- GEOJSON ---------

      L.tileLayer(
        'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiemV0cm92b2FkYSIsImEiOiJja2pvOHQ0dngxMW5wMnJuMDVseDBuaGMyIn0.51eWahbhlt9UqNZejV5zuQ',
        {
          id: 'mapbox/light-v9',
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          tileSize: 512,
          zoomOffset: -1,
        }
      ).addTo(this.myMap);

      function getColor(d) {
        return d > 5000
          ? '#800026'
          : d > 2000
          ? '#BD0026'
          : d > 1800
          ? '#E31A1C'
          : d > 1200
          ? '#FC4E2A'
          : d > 1000
          ? '#FD8D3C'
          : d > 800
          ? '#FEB24C'
          : d > 500
          ? '#FED976'
          : '#FFEDA0';
      }

      var dataCovidlist = dataCovid.listOfCities;
      // console.log(dataCovidlist);

      function style(feature) {
        var citySelection = dataCovidlist.find(
          (element) => element.city === feature.properties.name
        );

        if (citySelection === undefined) {
          return;
        }
        return {
          fillColor: getColor(citySelection.totalCases.replace('.', '')),
          weight: 2,
          opacity: 1,
          color: 'white',
          dashArray: '1',
          fillOpacity: 0.4,
        };
      }

      L.geoJson(this.geojsonMapData, { style: style }).addTo(this.myMap);

      function highlightFeature(e) {
        var layer = e.target;
        layer.setStyle({
          weight: 5,
          color: '#666',
          dashArray: '',
          fillOpacity: 0.7,
        });

        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
          layer.bringToFront();
        }
      }

      geojson = L.geoJson(this.geojsonMapData);

      function resetHighlight(e) {
        geojson.resetStyle(e.target);
      }

      var dataIntervalCitySelected = this.dataIntervalCovid;

      function updateChartAndTableByCitySelected(e) {
        var result = [];
        var data = {};

        dataIntervalCitySelected.filter((element) => {
          data = element.listOfCities.find(
            (value) =>
              value.city.replace('/CE', '') === e.target.feature.properties.name
          );

          result.push(data);
        });
        store.dispatch('changeCitySelectedDataList', result);
      }

      function zoomToFeature(e, map) {
        var value = dataCovidlist.find(
          (element) => element.city === e.target.feature.properties.name
        );

        store.dispatch('changeCityName', value.city);
        store.dispatch('changeCitySelected', value);

        var popupOptions = {
          maxWidth: '500',
          className: 'another-popup', // classname for another popup
        };

        L.popup({ className: 'pareina' })
          .setLatLng(e.latlng)
          .setContent(
            e.target.feature.properties.name + '</br>' + value.totalCases
          )
          .openOn(map);
        map.fitBounds(e.target.getBounds());
        updateChartAndTableByCitySelected(e);
      }

      function onEachFeature(feature, layer, myMap) {
        layer.on({
          mouseover: highlightFeature,
          mouseout: resetHighlight,
          click: (event) => zoomToFeature(event, myMap),
        });
      }

      geojson = L.geoJson(this.geojsonMapData, {
        style: style,
        onEachFeature: (feature, layer) =>
          onEachFeature(feature, layer, this.myMap),
      }).addTo(this.myMap);
    },

    alterCitySelected() {},
  },
  mounted() {
    this.myMap = L.map('mapContainer').setView([-4.81664, -39.5423], 7.5);
    this.$http.get(this.baseURICovid).then((result) => {
      this.dataCovid = result.data;

      this.dataCovid[0].listOfCities.map(
        (element) => (element.city = element.city.replace('/CE', ''))
      );

      this.updateDate = this.dataCovid[0].updateDate;
      this.source = this.dataCovid[0].source;
      this.filterCrateusRegionCities();

      var nameCities = [],
        totalCases = [];

      this.dataCovidRegion.forEach(function(index) {
        nameCities.push(index.city);
        totalCases.push(index.totalCases);
      });

      this.createChart(
        'chartCitiesRegion',
        nameCities,
        totalCases,
        'rgba(71, 183,132,.5)',
        '#47b784',
        'bar',
        {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }
      );

      this.createChart('chartFeaturedCity', [], [], '', '#4ba2eb', 'line', {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
      });
      this.createTableRegion();
    });
    this.$http.get(this.baseURICovidInterval).then((result) => {
      this.dataIntervalCovid = result.data;
      this.setupLeafletMap(this.dataCovid[0]);
    });

    // this.darkMode = store.state.systemConfig.darkMode;
  },

  computed: {
    cityName: () => store.state.city.cityName,
    citySelected: () => store.state.city.citySelected,
    citySelectedDataList: () => store.state.city.citySelectedDataList,
    itemsCitySelected: () => store.state.city.itemsCitySelected,
  },
  watch: {
    intervalValue: (intervalValue) => {
      store.dispatch('changeIntervalValue', intervalValue);
      var result = store.state.city.citySelected;
      // console.log();

      store.dispatch('changeCitySelected', {
        totalCases: '0',
        newCases: '0',
        deaths: '0',
        newDeaths: '0',
      });
      store.dispatch('changeCitySelected', result);
    },
    citySelected: () => {
      const listDateCity = [];
      const listValueCity = [];
      var citySelectedDataList_aux = store.state.city.citySelectedDataList;
      var intervalValue = store.state.systemConfig.intervalValue;

      citySelectedDataList_aux
        .slice()
        .reverse()
        .forEach(function(element, index) {
          if (index < intervalValue) {
            var date = element.last_info_date.split('-');
            date = date[2] + '/' + date[1] + '/' + date[0];

            listDateCity.push(date);
            listValueCity.push(element.totalCases);
          }
        });

      document.getElementById('chartCitySelected').innerHTML = '&nbsp;';
      document.getElementById('chartCitySelected').innerHTML =
        ' <div class="chart-container"><canvas id="chartFeaturedCity"></canvas> </div>';

      const ctx = document.getElementById('chartFeaturedCity');
      const myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: listDateCity,
          datasets: [
            {
              label: 'Total de Casos',
              data: listValueCity,
              backgroundColor: '',
              borderColor: '#4ba2eb',
              borderWidth: 3,
            },
          ],
        },
        responsive: true,
        options: {
          responsive: true,
          interaction: {
            intersect: false,
            mode: 'index',
          },
        },
        plugins: {
          title: {
            display: true,
            text: (ctx) =>
              'Tooltip position mode: ' +
              ctx.chart.options.plugins.tooltip.position,
          },
        },
      });
      myChart.update();

      var date = [],
        listItems = [];

      citySelectedDataList_aux.forEach(function(element, index) {
        if (index < intervalValue) {
          date = element.last_info_date.split('-');
          listItems.push({
            dataDeAtualização: date[2] + '/' + date[1] + '/' + date[0],
            confirmados: element.totalCases,
            obitos: element.deaths,
            novosCasos: element.newCases,
            novosObitos: element.newDeaths,
            letalidade: element.deaths_by_totalCases,
          });
        }
      });

      store.dispatch('changeItemsCitySelected', listItems);
    },
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1610px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: -40px;
    overflow: hidden;
  }
}

/* @media (max-width: 1000px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1000px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: -40px;
    overflow: hidden;
  }
} */

@media (max-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: -40px;
    overflow: hidden;
  }
  .informacoes {
    flex-direction: column-reverse !important;
    height: 100% !important;
  }
  .map-info {
    width: 100% !important;
    /* margin-top: 0px !important; */
  }
  .cards-info,
  .painel-info {
    width: 100% !important;
  }
  .cards-covid {
    display: flex;
    flex-direction: row;
  }
  .box-buscar {
    /* margin-left: 0px !important; */
  }
  .buscar {
    /* padding: 5px; */
    /* margin-top: 5px; */
    margin-left: 10px !important;
    /* transform: translateY(0px) !important; */
  }
  .chartCovidRegion {
    margin: 0px !important;
    max-width: 100% !important;
    padding-left: 10px !important;
  }
  .boxContentChart {
    padding-right: 10px !important;
    padding-left: 0px !important;
  }
  .table-data {
    padding-left: 10px !important;
    margin: 0px !important;
    width: 100% !important;
  }
  .table-data-style {
    overflow-x: scroll !important;
  }
  #mapContainer {
    border-top-right-radius: 60px;
    max-height: 480px;
  }
  .chartCovidRegion,
  .chartCovidCity {
    max-width: 80vw;
    margin-top: 70px;
    padding-right: 10px;
    margin: 0px !important;
    max-width: 100% !important;
    padding-left: 10px !important;
  }
  .input-select {
    border: solid 1px !important;
    border-color: #000 !important;
  }
  #table-data-city-selected {
    margin-bottom: 25px;
  }
  .custom-range {
    width: 90% !important;
    padding-left: 20px;
  }
}

@media (max-width: 830px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 830px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
  }
  .cards-covid {
    display: block !important;
    /* margin-top: 6%; */
    /* flex-direction: column !important; */
  }
  .map-info {
    /* width: 100% !important; */
    /* margin-top: +45px !important; */
  }
}

/* @media (max-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1410px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
  }
} */

@media (max-width: 480px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 480px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 10px !important;
    overflow: hidden;
  }
  .informacoes {
    flex-direction: column-reverse !important;
    height: 100% !important;
  }
  .map-info {
    width: 100% !important;
    /* margin-top: 45px !important; */
  }
  .cards-info,
  .painel-info {
    width: 100% !important;
  }
  .box-buscar {
    margin-left: 0px !important;
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
  }
  .buscar {
    padding: 5px;
    margin-top: 5px;
    padding-right: 15px;
    margin-left: 10px !important;
    transform: translateY(0px) !important;
  }
  .chartCovidRegion {
    margin: 0px !important;
    max-width: 100% !important;
    padding-left: 10px !important;
  }
  .boxContentChart {
    padding-right: 10px !important;
    padding-left: 0px !important;
  }
  .table-data {
    padding-left: 10px !important;
    margin: 0px !important;
    width: 100% !important;
  }
  .table-data-style {
    overflow-x: scroll !important;
  }
  #mapContainer {
    border-top-right-radius: 60px;
    max-height: 480px;
  }
  .chartCovidRegion,
  .chartCovidCity {
    max-width: 80vw;
    margin-top: 70px;
    padding-right: 10px;
    margin: 0px !important;
    max-width: 100% !important;
    padding-left: 10px !important;
  }
  .input-select {
    width: 100% !important;
    margin-right: 15px !important;
    border: solid 1px !important;
    border-color: #000 !important;
    margin-bottom: 7px;
  }
  .select-custom {
    width: 100% !important;
    margin-right: 8px !important;
  }
  .search {
    width: 100% !important;
  }
  #table-data-city-selected {
    margin-bottom: 25px;
  }
  .custom-range {
    width: 90% !important;
    padding-left: 20px;
  }
}

.buscar {
  transform: translateY(-60px);
  background-color: transparent;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* justify-items: center; */
}

.interval {
  background-color: transparent;
}

.box-buscar {
  display: flex;
  flex-direction: row;
  text-align: center;
  /* margin-left: 35vw; */
}

.box-interval {
  text-align: center;
}

.input-select {
  display: flex;
  border-radius: 7.5px;
  margin-right: 10px;
  background-color: #fafafc;
  width: 220px;
  text-align: left;
  height: 40px;
}

.input-select-interval {
  display: flex;
  border-radius: 7.5px;
  margin-right: 10px;
  background-color: #fafafc;
  width: 30%;
  text-align: left;
  height: 40px;
}

.input-select-interval > p {
  width: 10rem;
}

.icon-maker {
  height: 20px;
  margin-left: 12px;
  margin-top: 10px;
}

.icon-maker > span.material-icons {
  font-size: 20px;
}

select:required:invalid {
  color: gray;
}

option[value=''][disabled] {
  display: none;
}

option {
  color: black;
}

.select-custom {
  background-color: #fafafc;
  outline-style: none;
  margin-top: 10px;
  margin-left: 8px;
  border: none;
  height: 20px;
  width: 165px;
}

.select-custom::selection {
  border: none;
}

.search {
  font-family: 'Righteous', cursive;
  background-color: #181818;
  border: none;
  height: 40px;
  border-radius: 7.6px;
  width: 200px;
  color: #ededed;
}

/* ---------------------------------------- STYLE INFORMATIONS -------------------------------- */
.informacoes {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 780px;
}

/* ---------------------------------------------------------------------------- */
.cards-info {
  width: 30%;
  background-color: #f1f2f3;
}

.painel-info {
  font-family: 'Righteous', cursive;
  text-align: left;
  padding: 30px;
  padding-bottom: 0px;
}

#updateDate {
  margin-top: 15px;
}

#source {
  margin-top: 20px;
  word-wrap: break-word;
}

.painel-info > p {
  color: #686868;
  font-size: 14px;
  margin-bottom: 1px;
  font-weight: 400;
}

.cards-covid {
  padding: 30px;
  padding-top: 0px;
}
/* ---------------------------------------------------------------------------- */
.map-info {
  width: 70%;
  border-top-right-radius: 10px;
}

/* -----------------------------------------MAP -------------------------------------- */
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: black !important;
}

.pareina .leaflet-popup-content-wrapper {
  background: transparent;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #111 !important;
  color: #234c5e;
}

#mapContainer {
  border-top-right-radius: 60px;
  height: 780px;
}

.leaflet-control-zoom-in {
  background-color: red !important;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #000 !important;
  border-bottom: 1px solid #000;
  text-decoration: none;
  text-align: center;
  line-height: 26px;
  display: block;
  color: white;
  height: 26px;
  width: 26px;
}

.text-title {
  text-align: left;
  position: relative;
  margin-top: 40px;
}

.chartCovidRegion,
.chartCovidCity {
  max-width: 80vw;
  margin: auto;
  margin-top: 70px;
}

.search-interval {
  height: 40px;
  align-items: center;
}

.table-data {
  height: 100%;
  width: 80vw;
  margin: auto;
  margin-top: 70px;
}
/* .table {
  color: red !important;
} */
.table-data-style {
  background-color: white; /* background-color: #b1b1b1;  border-top: 1px solid #dee2e6;*/
  border-radius: 10px;
  padding: 0px 10px;
}

.table-data-city {
  margin-bottom: 50px;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.intervalDays {
  display: flex;
  flex-direction: row;
}

.intervalValue {
  margin-left: 5px;
}
.custom-range {
  width: 50%;
}
</style>
