const systemConfig = {
  state: {
    darkMode: localStorage.getItem('DarkMode') == 'true',
    intervalValue: 1,
  },
  mutations: {
    SET_DARK_MODE_ACTIVE(state, darkMode) {
      state.darkMode = darkMode;
    },
    SET_INTERVAL_VALUE(state, intervalValue) {
      state.intervalValue = intervalValue;
    },
  },
  actions: {
    changeDarkModeActive(context, darkMode) {
      context.commit('SET_DARK_MODE_ACTIVE', darkMode);
    },
    changeIntervalValue(context, intervalValue) {
      context.commit('SET_INTERVAL_VALUE', intervalValue);
    },
  },
};

export { systemConfig };
