<template>
  <div class="pageBody">
    <BarHeader :activatePageBar="'PageCidBr'" />

    <div class="buscar">
      <div class="box-buscar">
        <div class="input-select">
          <div class="icon-maker">
            <span class="material-icons">
              search
            </span>
          </div>
          <select
            required
            v-model="selectedDisease"
            class="select-custom"
            name="region"
            id="region"
          >
            <option value="" disabled selected>Doença</option>
            <option
              v-for="option in optionsListDiseases"
              v-bind:value="option"
              v-bind:key="option"
              >{{ option }}</option
            >
          </select>
        </div>
        <div class="input-select">
          <div class="icon-maker">
            <span class="material-icons">
              location_on
            </span>
          </div>
          <select
            v-model="geojsonMapRef"
            required
            class="select-custom"
            name="region"
            id="region"
          >
            <option value="" disabled selected>Região</option>
            <option value="Ceara">Ceará</option>
            <option value="Sertão dos Crateús">Sertão dos Crateús</option>
          </select>
        </div>
        <button @click="search()" class="search">Pesquisar</button>
      </div>
    </div>
    <div class="container">
      <div class="informacoes">
        <div class="cards-info">
          <div class="painel-info">
            <h3 class="text-custom-color-title">PAINEL DOENÇAS CID BR</h3>
            <p class="text-custom-color" id="atualizacao">
              ATUALIZAÇÃO: {{ this.updateDate }}
            </p>
            <p class="text-custom-color">
              CIDADE: {{ citySelectedCidBr.nome }}
            </p>
            <p class="text-custom-color">
              *Selecione uma cidade no mapa para ver as informações
            </p>
            <!-- <p id="source">FONTE: {{ this.source }}</p> -->
          </div>
          <div class="cards-covid">
            <CardHome :styleCard="cardNomeDoenca" />
            <CardHome :styleCard="cardCasos" />
          </div>
          <div class="painel-info">
            <p class="text-custom-color">
              Os dados exibidos estão sendo contabilizados desde de primerio de
              Janeiro de 2021. A cada ano os dados são zerados.
            </p>
            <p class="text-custom-color" id="source">
              FONTE: {{ this.source }}
            </p>
          </div>
        </div>
        <div class="map-info">
          <div id="mapCidBr">
            <div id="mapContainer"></div>
          </div>
        </div>
      </div>

      <div class="chartCidRegion">
        <div class="text-title">
          <h1 class="text-custom-color-title">
            Relação para {{ diseaseName }} no Sertão dos Crateús
          </h1>
          <p class="text-custom-color">
            No gráfico abaixo é exibido o total de casos da
            {{ diseaseName }} nas 13 regiões que fazem parte do Sertão dos
            Crateús.
          </p>
        </div>
        <div class="boxContentChart" id="chartCitiesRegionDinamic">
          <div class="chart-container">
            <canvas id="chartCitiesRegion"></canvas>
          </div>
        </div>
      </div>

      <div class="table-data">
        <div class="text-title">
          <p class="text-custom-color">
            Na tabela abaixo é exibido os dados relacionado a doença
            {{ diseaseName }} por cada cidade que faz parte da região Sertão dos
            Crateús
          </p>
        </div>
        <div class="table-data-style">
          <b-table
            :items="itemsSelectedByDiseaseRegion"
            :fields="fieldsRegionCrateus"
            caption-top
          >
            <template class="title-table" #table-caption
              >Síntese da doença por casos na região do Sertão dos
              Crateús</template
            >
          </b-table>
        </div>
      </div>

      <!-- INformações pela cidade em destaque -->
      <div class="chartCidRegion">
        <div class="text-title">
          <div>
            <label class="intervalDays" for="range-1"
              ><p class="text-custom-color-title">
                SELECIONE O INTERVALO DE DIAS:
              </p>
              <div class="intervalValue text-custom-color-title">
                <strong>{{ intervalValue }}</strong>
              </div></label
            >

            <b-form-input
              id="range-1"
              v-model="intervalValue"
              type="range"
              min="1"
              max="31"
            ></b-form-input>
          </div>

          <h1 class="text-custom-color-title">
            Gráfico da cidade {{ cityName }}
          </h1>

          <p class="text-custom-color">
            No gráfico abaixo é exibido o total de casos da doenca
            {{ diseaseName }} por dia na cidade selecionada
          </p>
        </div>
        <div class="boxContentChart" id="chartCitySelected">
          <div class="chart-container">
            <canvas id="chartFeaturedCity"></canvas>
          </div>
        </div>
      </div>

      <div class="table-data table-data-city">
        <div class="text-title">
          <h1 class="text-custom-color-title">
            Tabela referente a cidade {{ cityName }}
          </h1>

          <p class="text-custom-color">
            Na tabela abaixo é exibido os dados da doença {{ diseaseName }} pela
            cidade selecionada no mapa. Os dados são mostrados de acordo com o
            intervalo selecionado.
          </p>
        </div>
        <div class="table-data-style" id="table-data-city-selected">
          <b-table
            :items="itemsCitySelected"
            :fields="fieldsCitySelected"
            caption-top
          >
            <template class="title-table" #table-caption
              >Síntese da doença por casos na cidade</template
            >
          </b-table>
        </div>
      </div>
      <br />
    </div>

    <BarFooter />
  </div>
</template>

<script>
import BarHeader from '@/components/BarHeader.vue';
import CardHome from '@/components/CardHome.vue';
import BarFooter from '@/components/BarFooter.vue';
import BarChart from '@/components/BarChart.vue';

import config from '../config';

import store from '../store';
import Chart from 'chart.js';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import { geojsonState } from '../data/geojson';
import { geojsonRegion } from '../data/geojsonRegionSertaoCrateus';
export default {
  name: 'PageCidBr',
  // props: [''],
  components: {
    BarHeader,
    CardHome,
    BarFooter,
    BarChart,
  },
  data() {
    return {
      cardNomeDoenca: {
        color: '#0FB5DA',
        title: 'Doença',
        totalCasos: '',
        novosCasos: '',
        height: '120px',
        width: '335px',
        marginRight: '17px',
        cardStyleType: 'pageCidBr',
        marginBottom: '20px',
        subtitle: 'Doença de chagas',
      },
      cardCasos: {
        color: '#F91010',
        title: 'Casos',
        totalCasos: '',
        novosCasos: '500',
        height: '106px',
        width: '335px',
        marginRight: '17px',
        cardStyleType: 'pageCidBr',
        marginBottom: '20px',
        subtitle: 'Total de casos',
      },
      center: [-5.31664, -39.5423],
      geojsonMapData: geojsonState,

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      fieldsRegionCrateus: ['cidade', 'casos', 'dataDeAtualização'],
      fieldsCitySelected: ['cidade', 'casos', 'dataDeAtualização'],
      // itemsRegionCrateus: [],
      baseURICommonDiseases: config.BACKEND_URL + '/cidBrRegion',
      baseURICommonDiseasesInterval: config.BACKEND_URL + '/cidBrRegisterQuantity',
      dataCommonDiseases: [],
      dataCidBrRegion: [],
      updateDate: '',
      source: '',
      listNamesCitiesRegion: [
        'Poranga',
        'Crateús',
        'Ararendá',
        'Ipueiras',
        'Ipaporanga',
        'Tamboril',
        'Catunda',
        'Nova Russas',
        'Hidrolândia',
        'Monsenhor Tabosa',
        'Santa Quitéria',
        'Independência',
        'Novo Oriente',
      ],
      optionsListDiseases: [],
      selectedDisease: '',
      geojsonMapRef: '',
      myMap: {},
      intervalValue: 1,
      dataIntervalCidBr: [],
      dataIntervalCidBrByDisease: [],
    };
  },
  methods: {
    search() {
      if (this.selectedDisease !== '') {
        store.dispatch('changeDiseaseName', this.selectedDisease);
        this.filterDataByDisease(this.dataIntervalCidBr);
      }

      this.filterCitiesRegionCrateusByDisease();
      this.filterCitiesByDisease();

      if (this.geojsonMapRef === 'Sertão dos Crateús')
        this.geojsonMapData = geojsonRegion;
      else this.geojsonMapData = geojsonState;

      this.myMap.remove();
      this.myMap = L.map('mapContainer').setView([-4.81664, -39.5423], 7.5);

      this.setupLeafletMap();
    },

    filterDataByDisease(dataIntervalCidBr) {
      var diseaseName = store.getters.getDiseaseName;
      var data = {};
      this.dataIntervalCidBrByDisease = [];

      dataIntervalCidBr.filter((index) => {
        data = index.listOfCities.find((value) => value.doenca === diseaseName);
        data = { ...data, updateDate: index.updateDate };
        // console.log({ data });
        this.dataIntervalCidBrByDisease.push(data);
      });
    },

    createTableRegion(dataRegion) {
      var date = [],
        listItems = [];

      date = this.updateDate.split(' '); //devido está vindo como ToLocaleDateString
      date = date[1];
      dataRegion.forEach(function(index) {
        listItems.push({
          cidade: index.nome,
          casos: index.valor,
          dataDeAtualização: date,
        });
      });
      // this.itemsRegionCrateus = listItems;
      store.dispatch('changeItemsSelectedByDiseaseRegion', listItems);
    },

    diseaseNameList() {
      this.optionsListDiseases = this.dataCommonDiseases[0].listOfCities.map(
        (value) => value.doenca
      );
    },
    filterCitiesByDisease() {
      var diseaseName = store.getters.getDiseaseName;
      var citiesByDisease = [];

      this.dataCommonDiseases[0].listOfCities.map((value) => {
        if (value.doenca === diseaseName) {
          citiesByDisease.push(value);
        }
      });

      store.dispatch('changeDataCidBrByDisease', citiesByDisease[0]);
    },
    filterCitiesRegionCrateusByDisease() {
      var diseaseName = store.getters.getDiseaseName;
      this.dataCidBrRegion = [];
      this.dataCommonDiseases[0].listOfCities.map((value) => {
        if (value.doenca === diseaseName) {
          value.cidades.map((index) => {
            for (var i = 0; i < this.listNamesCitiesRegion.length; i++) {
              if (index.nome == this.listNamesCitiesRegion[i]) {
                this.dataCidBrRegion.push(index);
              }
            }
          });
        }
      });
      store.dispatch('changeDataCidBrRegionCrateus', this.dataCidBrRegion);
    },
    createChart(
      chartId,
      label,
      values,
      backgroundColorChart,
      boderColorChat,
      typeChart,
      options
    ) {
      const ctx = document.getElementById(chartId);

      const myChart = new Chart(ctx, {
        type: typeChart,
        data: {
          labels: label,
          datasets: [
            {
              label: 'Total de Casos',
              data: values,
              backgroundColor: backgroundColorChart,
              borderColor: boderColorChat,
              borderWidth: 3,
            },
          ],
        },

        // maintainAspectRatio: false,
        options: { options },
        plugins: {
          title: {
            display: true,
            text: (ctx) =>
              'Tooltip position mode: ' +
              ctx.chart.options.plugins.tooltip.position,
          },
        },
      });
      myChart.update();
    },

    setupLeafletMap() {
      var geojson;
      var info = L.control();

      info.onAdd = function(mymap = this.myMap) {
        this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
        this.update();
        return this._div;
      };

      // method that we will use to update the control based on feature properties passed
      info.update = function(props) {
        // console.log('SFSF');
        // this._div.innerHTML =
        //   '<h4>US Population Density</h4>' +
        //   (props
        //     ? '<b>' +
        //       props.name +
        //       '</b><br />' +
        //       props.density +
        //       ' people / mi<sup>2</sup>'
        //     : 'Hover over a state');
      };

      info.addTo(this.myMap);

      // ----- GEOJSON ---------

      L.tileLayer(
        'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiemV0cm92b2FkYSIsImEiOiJja2pvOHQ0dngxMW5wMnJuMDVseDBuaGMyIn0.51eWahbhlt9UqNZejV5zuQ',
        {
          id: 'mapbox/light-v9',
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          tileSize: 512,
          zoomOffset: -1,
        }
      ).addTo(this.myMap);

      function getColor(qtdCases) {
        return qtdCases > 600
          ? '#800026'
          : qtdCases > 500
          ? '#BD0026'
          : qtdCases > 100
          ? '#E31A1C'
          : qtdCases > 50
          ? '#FC4E2A'
          : qtdCases > 20
          ? '#FD8D3C'
          : qtdCases > 10
          ? '#FEB24C'
          : qtdCases > 4
          ? '#FED976'
          : '#FFEDA0';
      }

      function style(feature) {
        var dataCommonDiseaseslist = store.state.cidBr.dataCidBrByDisease;

        var city = dataCommonDiseaseslist.cidades.find(
          (element) => element.nome === feature.properties.name
        );
        if (city === undefined) {
          return;
        }

        return {
          fillColor: getColor(city.valor.replace('.', ''), city.nome),
          weight: 2,
          opacity: 1,
          color: 'white',
          dashArray: '1',
          fillOpacity: 0.4,
        };
      }

      L.geoJson(this.geojsonMapData, { style: style }).addTo(this.myMap);

      function highlightFeature(e) {
        var layer = e.target;
        layer.setStyle({
          weight: 5,
          color: '#666',
          dashArray: '',
          fillOpacity: 0.7,
        });

        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
          layer.bringToFront();
        }
      }

      geojson = L.geoJson(this.geojsonMapData);

      function resetHighlight(e) {
        geojson.resetStyle(e.target);
      }

      var dataIntervalCitySelected = this.dataIntervalCidBrByDisease;
      function updateChartAndTableByCitySelected(event) {
        var result = [];
        var data = {};
        // console.log('data:' + dataIntervalCitySelected);
        dataIntervalCitySelected.filter((element) => {
          // console.log(element);
          data = element.cidades.find(
            (value) => value.nome === event.target.feature.properties.name
          );
          data = { ...data, updateDate: element.updateDate };
          result.push(data);
        });
        store.dispatch('changeCitySelectedDataList', result);
        store.dispatch('changeCityName', data.nome);
      }

      function zoomToFeature(e, map) {
        var dataCommonDiseaseslist = store.state.cidBr.dataCidBrByDisease;

        var data = dataCommonDiseaseslist.cidades.find(
          (element) => element.nome === e.target.feature.properties.name
        );

        store.dispatch('changeCitySelectedCidBr', data);
        var popupOptions = {
          maxWidth: '500',
          className: 'another-popup', // classname for another popup
        };

        // console.log(map);
        L.popup({ className: 'pareina' })
          .setLatLng(e.latlng)
          .setContent(e.target.feature.properties.name + '</br>' + data.valor)
          .openOn(map);
        map.fitBounds(e.target.getBounds());

        updateChartAndTableByCitySelected(e);
      }

      function onEachFeature(feature, layer, myMap) {
        myMap.removeLayer(layer);
        layer.on({
          mouseover: highlightFeature,
          mouseout: resetHighlight,
          click: (event) => zoomToFeature(event, myMap),
        });
      }

      geojson = L.geoJson(this.geojsonMapData, {
        style: style,
        onEachFeature: (feature, layer) =>
          onEachFeature(feature, layer, this.myMap),
      }).addTo(this.myMap);
    },
  },
  created() {},
  mounted() {
    this.myMap = L.map('mapContainer').setView([-4.81664, -39.5423], 7.5);

    this.$http.get(this.baseURICommonDiseases).then((result) => {
      this.dataCommonDiseases = result.data;

      this.diseaseNameList();
      this.updateDate = this.dataCommonDiseases[0].updateDate;
      this.source = this.dataCommonDiseases[0].source;

      var date = this.updateDate.split(' '); //devido está vindo como ToLocaleDateString
      // date = date[1];
      store.dispatch('changeUpdateDateSplit', date[1]);

      this.filterCitiesRegionCrateusByDisease();

      var nameCities = [],
        totalCases = [];

      this.dataCidBrRegion.forEach(function(index) {
        nameCities.push(index.nome);
        totalCases.push(index.valor);
      });

      this.createTableRegion(this.dataCidBrRegion);

      this.createChart(
        'chartCitiesRegion',
        nameCities,
        totalCases,
        'rgba(71, 183,132,.5)',
        '#47b784',
        'bar',
        {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }
      );

      this.createChart('chartFeaturedCity', [], [], '', '#4ba2eb', 'line', {
        interaction: {
          intersect: false,
          mode: 'index',
        },
      });
      this.filterCitiesByDisease();
    });

    this.$http.get(this.baseURICommonDiseasesInterval).then((result) => {
      this.dataIntervalCidBr = result.data;
      // this.setupLeafletMap();
      this.filterDataByDisease(this.dataIntervalCidBr);
      this.setupLeafletMap();
    });
  },

  computed: {
    cityName: () => store.state.city.cityName,
    citySelectedDataList: () => store.state.city.citySelectedDataList,
    citySelectedCidBr: () => store.state.cidBr.citySelectedCidBr,
    itemsSelectedByDiseaseRegion: () =>
      store.state.cidBr.itemsSelectedByDiseaseRegion,
    diseaseName: () => store.state.cidBr.diseaseName,
    getDiseaseName() {
      return store.getters.getDiseaseName;
    },
    itemsCitySelected: () => store.state.city.itemsCitySelected,

    updateDateSplit: () => store.state.cidBr.updateDateSplit,
  },
  watch: {
    intervalValue: (intervalValue) => {
      store.dispatch('changeIntervalValue', intervalValue);
      var result = store.state.city.citySelectedDataList;

      store.dispatch('changeCitySelectedDataList', {
        totalCases: '0',
        newCases: '0',
        deaths: '0',
        newDeaths: '0',
      });
      store.dispatch('changeCitySelectedDataList', result);
    },
    dataCidBrRegion: () => {
      const nameCities = [];
      const totalCases = [];
      var dataCidBrRegion_aux = store.state.cidBr.dataCidBrRegionCrateus;
      var updateDateSplit = store.state.cidBr.updateDateSplit;
      // console.log('data cid update :' + dataCidBrRegion_aux);

      dataCidBrRegion_aux.forEach(function(index) {
        nameCities.push(index.nome);
        totalCases.push(index.valor);
      });

      document.getElementById('chartCitiesRegionDinamic').innerHTML = '&nbsp;';
      document.getElementById('chartCitiesRegionDinamic').innerHTML =
        ' <div class="chart-container"><canvas id="chartCitiesRegion"></canvas> </div>';

      const ctx = document.getElementById('chartCitiesRegion');
      const myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: nameCities,
          datasets: [
            {
              label: 'Total de Casos',
              data: totalCases,
              backgroundColor: 'rgba(71, 183,132,.5)',
              borderColor: '#47b784',
              borderWidth: 3,
            },
          ],
        },

        options: {
          responsive: true,
          interaction: {
            intersect: false,
            mode: 'index',
          },
        },
        plugins: {
          title: {
            display: true,
            text: (ctx) =>
              'Tooltip position mode: ' +
              ctx.chart.options.plugins.tooltip.position,
          },
        },
      });
      // myChart.update();

      var listItems = [];

      dataCidBrRegion_aux.forEach(function(index) {
        listItems.push({
          cidade: index.nome,
          casos: index.valor,
          dataDeAtualização: updateDateSplit,
        });
      });
      store.dispatch('changeItemsSelectedByDiseaseRegion', listItems);
    },
    citySelectedDataList: () => {
      const listDateCity = [];
      const listValueCity = [];
      var citySelectedDataList_aux = store.state.city.citySelectedDataList;
      var intervalValue = store.state.systemConfig.intervalValue;

      citySelectedDataList_aux
        .slice()
        .reverse()
        .forEach(function(element, index) {
          if (index < intervalValue) {
            listDateCity.push(element.updateDate.split(' ')[1]);
            listValueCity.push(element.valor);
          }
        });

      document.getElementById('chartCitySelected').innerHTML = '&nbsp;';
      document.getElementById('chartCitySelected').innerHTML =
        ' <div class="chart-container"><canvas id="chartFeaturedCity"></canvas> </div>';

      const ctx = document.getElementById('chartFeaturedCity');
      const myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: listDateCity,
          datasets: [
            {
              label: 'Total de Casos',
              data: listValueCity,
              backgroundColor: '',
              borderColor: '#4ba2eb',
              borderWidth: 3,
            },
          ],
        },

        options: {
          responsive: true,
          interaction: {
            intersect: false,
            mode: 'index',
          },
        },
        plugins: {
          title: {
            display: true,
            text: (ctx) =>
              'Tooltip position mode: ' +
              ctx.chart.options.plugins.tooltip.position,
          },
        },
      });

      var date = [],
        listItems = [];

      citySelectedDataList_aux.forEach(function(element, index) {
        if (index < intervalValue) {
          date = element.updateDate.split(' ')[1];
          listItems.push({
            cidade: element.nome,
            casos: element.valor,
            dataDeAtualização: date,
          });
        }
      });

      store.dispatch('changeItemsCitySelected', listItems);
    },
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1610px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: -40px;
    overflow: hidden;
  }
}

@media (max-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: -40px;
    overflow: hidden;
  }
  .informacoes {
    flex-direction: column-reverse !important;
    height: 100% !important;
  }
  .map-info {
    width: 100% !important;
    /* margin-top: 0px !important; */
  }
  .cards-info,
  .painel-info {
    width: 100% !important;
  }
  .cards-covid {
    display: flex;
    flex-direction: row;
  }
  .box-buscar {
    /* margin-left: 0px !important; */
  }
  .buscar {
    /* padding: 5px; */
    /* margin-top: 5px; */
    margin-left: 10px !important;
    /* transform: translateY(0px) !important; */
  }
  .chartCovidRegion {
    margin: 0px !important;
    max-width: 100% !important;
    padding-left: 10px !important;
  }
  .boxContentChart {
    padding-right: 10px !important;
    padding-left: 0px !important;
  }
  .table-data {
    padding-left: 10px !important;
    margin: 0px !important;
    width: 100% !important;
  }
  .table-data-style {
    overflow-x: scroll !important;
  }
  #mapContainer {
    border-top-right-radius: 60px;
    max-height: 480px;
  }
  .card {
    height: 127px !important;
  }

  .chartCovidRegion,
  .chartCovidCity {
    max-width: 80vw;
    margin-top: 70px;
    padding-right: 10px;
    margin: 0px !important;
    max-width: 100% !important;
    padding-left: 10px !important;
  }
  .input-select {
    border: solid 1px !important;
    border-color: #000 !important;
  }
  #table-data-city-selected {
    margin-bottom: 25px;
  }
  .custom-range {
    width: 90% !important;
    padding-left: 20px;
  }
}

@media (max-width: 830px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 830px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
  }
  .cards-covid {
    display: block !important;
    /* margin-top: 6%; */
    /* flex-direction: column !important; */
  }
  .map-info {
    /* width: 100% !important; */
    /* margin-top: +45px !important; */
  }
}

@media (max-width: 715px) {
  .box-buscar {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
  .box-buscar {
    margin-left: 0px !important;
  }
  .buscar {
    padding: 5px;
    margin-top: 5px;
    transform: translateY(0px) !important;
  }
}

@media (max-width: 480px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 480px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
  }
  .informacoes {
    flex-direction: column-reverse !important;
    height: 100% !important;
  }
  .map-info {
    width: 100% !important;
    margin-top: 45px !important;
  }
  .cards-info,
  .painel-info {
    width: 100% !important;
  }
  .box-buscar {
    margin-left: 0px !important;
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
  }
  .buscar {
    padding: 5px;
    margin-top: 5px;
    padding-right: 15px;
    transform: translateY(0px) !important;
  }

  .boxContentChart {
    padding-right: 10px !important;
    padding-left: 0px !important;
  }
  .table-data {
    padding-left: 10px !important;
    margin: 0px !important;
    width: 100% !important;
  }
  .table-data-style {
    overflow-x: scroll !important;
  }
  #mapContainer {
    border-top-right-radius: 60px;
    max-height: 480px;
  }
  .input-select {
    width: 100% !important;
    margin-right: 15px !important;
    border: solid 1px !important;
    border-color: #000 !important;
    margin-bottom: 7px;
  }
  .search {
    width: 100% !important;
  }
  .select-custom {
    width: 100% !important;
    margin-right: 8px !important;
  }
  #table-data-city-selected {
    margin-bottom: 25px;
  }
  .custom-range {
    width: 90% !important;
    padding-left: 20px;
  }

  .chartCidRegion {
    max-width: 80vw;
    margin-top: 70px;
    padding-right: 10px;
    margin: 0px !important;
    max-width: 100% !important;
    padding-left: 10px !important;
  }
}

.buscar {
  transform: translateY(-60px);
  display: flex;
  /* align-items: center; */
  justify-content: center;
  background-color: transparent;
}
.box-buscar {
  display: flex;
  flex-direction: row;
  text-align: center;
  /* text-align: center; */
  /* margin-left: 25%; */
}
.input-select {
  display: flex;

  border-radius: 7.5px;
  margin-right: 10px;
  background-color: #fafafc;
  width: 220px;
  text-align: left;
  height: 40px;
}
.icon-maker {
  height: 20px;
  margin-left: 12px;
  margin-top: 10px;
}
.icon-maker > span.material-icons {
  font-size: 20px;
}
select:required:invalid {
  color: gray;
}
option[value=''][disabled] {
  display: none;
}
option {
  color: black;
}
.select-custom {
  border: none;

  outline-style: none;
  height: 20px;
  background-color: #fafafc;
  width: 165px;
  margin-top: 10px;

  margin-left: 8px;
}
.select-custom::selection {
  border: none;
}
.search {
  font-family: 'Righteous', cursive;
  background-color: #181818;
  border: none;
  height: 40px;
  border-radius: 7.6px;
  width: 200px;
  color: #ededed;
}
/* ---------------------------------------- STYLE INFORMATIONS -------------------------------- */
.informacoes {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 780px;
}
/* ---------------------------------------------------------------------------- */
.cards-info {
  width: 30%;
  background-color: #f1f2f3;
}
.painel-info {
  font-family: 'Righteous', cursive;

  text-align: left;
  padding: 30px;
  padding-bottom: 0px;
}
#atualizacao {
  margin-top: 15px;
}
.painel-info > h3 {
  font-size: 26px;
}
.painel-info > p {
  color: #686868;
  font-size: 14px;
  margin-bottom: 1px;
  font-weight: 400;
}
.cards-covid {
  padding: 30px;
  padding-top: 0px;
}
/* ---------------------------------------------------------------------------- */
.map-info {
  width: 70%;
}

/* -----------------------------------------MAP -------------------------------------- */
/* .pareina {
  background: black;
}
.pareina .leaflet-popup-content-wrapper {
  background: transparent;
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #111 !important;
  color: #234c5e;
} */

#mapContainer {
  border-top-right-radius: 60px;
  height: 780px;
}

/* .text-title {
  text-align: left;
  position: relative;
  margin-top: 40px;
  padding-left: 10%;
} */

.table-data {
  height: 100%;
  width: 80vw;
  margin: auto;
  margin-top: 70px;
}
.table-data-style {
  background-color: white;
  border-radius: 10px;
  padding: 0px 10px;
}

.chartCidRegion {
  max-width: 80vw;
  margin: auto;
}

.text-title {
  text-align: left;
  position: relative;
  margin-top: 40px;
}
#source {
  margin-top: 20px;
  word-wrap: break-word;
}
.custom-range {
  width: 25%;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 0.8rem;
}
/* .intervalValue {
  width: 30px;
} */

.intervalDays {
  display: flex;
  flex-direction: row;
}

.intervalValue {
  margin-left: 5px;
}
.custom-range {
  width: 50%;
}
</style>
