import Vuex from 'vuex';
import Vue from 'vue';
import { city } from './city';
import { cidBrDiseases } from './cidBrDiseases';
import { systemConfig } from './systemConfig';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    city: city,
    cidBr: cidBrDiseases,
    systemConfig: systemConfig,
  },
});
