const geojsonState = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2300101',
        name: 'Abaiara',
        description: 'Abaiara',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.962617023, -7.400213519],
            [-38.9699430065, -7.425970715],
            [-38.9802096335, -7.4256557701],
            [-39.0057514064, -7.4248702064],
            [-39.0085042986, -7.4247851007],
            [-39.0386107935, -7.4238568803],
            [-39.0442544358, -7.4236828229],
            [-39.0777823302, -7.4226461221],
            [-39.0840265059, -7.4066304771],
            [-39.0902377832, -7.383012034],
            [-39.0956411399, -7.3698438945],
            [-39.0949647958, -7.3635665806],
            [-39.1008719796, -7.3429301091],
            [-39.1010908316, -7.3414524542],
            [-39.1015549879, -7.3277226515],
            [-39.095480973, -7.3181456369],
            [-39.0847055514, -7.309352611],
            [-39.0812032038, -7.2984556808],
            [-39.0905850231, -7.281708887],
            [-39.0835354706, -7.2786909717],
            [-39.0817136843, -7.2699891518],
            [-39.0894621198, -7.2658774962],
            [-39.0908677104, -7.2602473272],
            [-39.0916094225, -7.2593150187],
            [-39.0735578594, -7.2712409979],
            [-39.0613093049, -7.2843310049],
            [-39.0407580044, -7.2932186375],
            [-39.0352707997, -7.2951371054],
            [-39.0045049874, -7.3049062384],
            [-38.9990156117, -7.3117342122],
            [-38.9916996484, -7.3238963626],
            [-38.9850850716, -7.3348895108],
            [-38.9719671186, -7.387834805],
            [-38.962617023, -7.400213519],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2300150',
        name: 'Acarape',
        description: 'Acarape',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.6558110939, -4.1580425034],
            [-38.6238352705, -4.1657816487],
            [-38.6194224012, -4.1639959298],
            [-38.6193523149, -4.1696917337],
            [-38.6178489933, -4.1818789666],
            [-38.597292364, -4.2052701526],
            [-38.5820179064, -4.2221847025],
            [-38.580325952, -4.224057637],
            [-38.5741329488, -4.2309133638],
            [-38.5705033138, -4.2346349704],
            [-38.577925618, -4.2374566773],
            [-38.6199001396, -4.253414696],
            [-38.6627218529, -4.2696985523],
            [-38.6838870334, -4.2777490325],
            [-38.6840624465, -4.2778956373],
            [-38.6970700215, -4.2817921612],
            [-38.7093928996, -4.2908241401],
            [-38.7368094063, -4.2929956765],
            [-38.7369178392, -4.2929803037],
            [-38.7284790846, -4.2795417989],
            [-38.7233291184, -4.2443174303],
            [-38.7165663324, -4.2422744397],
            [-38.7167369062, -4.236301158],
            [-38.7116290975, -4.234125839],
            [-38.7156325402, -4.2215628152],
            [-38.7176272799, -4.2152984709],
            [-38.729588354, -4.1694709467],
            [-38.7270028212, -4.1694302214],
            [-38.7155025554, -4.1908290149],
            [-38.6973407587, -4.1718755824],
            [-38.6881178766, -4.1681912944],
            [-38.685752862, -4.1702058777],
            [-38.6670651706, -4.1604581699],
            [-38.6638726239, -4.1620483556],
            [-38.6558110939, -4.1580425034],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2300200',
        name: 'Acaraú',
        description: 'Acaraú',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.3169736621, -2.8053405837],
            [-40.2613127305, -2.8109814594],
            [-40.2559168785, -2.8085716061],
            [-40.2293568813, -2.8125043554],
            [-40.2166025594, -2.8143918858],
            [-40.1873413014, -2.8154633639],
            [-40.1883837104, -2.8125447422],
            [-40.1600803199, -2.8329318448],
            [-40.1475078334, -2.8391375724],
            [-40.1316955887, -2.833839269],
            [-40.1325097121, -2.8283401835],
            [-40.1276814971, -2.8251368936],
            [-40.095506548, -2.8280511824],
            [-40.0790861339, -2.8344626585],
            [-40.0763954655, -2.840536581],
            [-40.0670093492, -2.8413536753],
            [-40.0609223828, -2.8371030268],
            [-40.0487341675, -2.8372064434],
            [-40.0477777334, -2.8395102763],
            [-40.0432522215, -2.8407067001],
            [-40.02244383, -2.843559425],
            [-40.0174449795, -2.8375842622],
            [-40.0271452108, -2.8357218545],
            [-40.0246474981, -2.8340950302],
            [-39.9879704746, -2.849323391],
            [-39.9766099945, -2.8548568928],
            [-39.9863649866, -2.8451564959],
            [-39.9991466281, -2.841496163],
            [-39.9873378177, -2.8421203516],
            [-39.9525304952, -2.8608208793],
            [-39.9526194619, -2.8580731947],
            [-39.9690071904, -2.8486987307],
            [-39.9431280761, -2.8600984207],
            [-39.9468589769, -2.8705258326],
            [-39.9539719712, -2.8904081858],
            [-39.953187714, -2.8965590747],
            [-39.958392668, -2.8982514541],
            [-39.9623673391, -2.9186021796],
            [-39.9683552904, -2.9354979759],
            [-39.9706168359, -2.9690963423],
            [-39.9728962341, -2.9892447295],
            [-39.9740095521, -3.0012652795],
            [-39.9808923925, -3.010738362],
            [-39.9815526536, -3.0235483391],
            [-39.9810405754, -3.0352873003],
            [-39.9808263727, -3.0359782353],
            [-39.9723418083, -3.0617174586],
            [-39.9723958539, -3.0724736706],
            [-39.9797804237, -3.079897136],
            [-39.980019234, -3.0801372686],
            [-39.9905866864, -3.0955003154],
            [-39.9900357501, -3.114602634],
            [-39.9944464127, -3.134811032],
            [-39.9893832041, -3.160989605],
            [-39.9831695133, -3.1616462574],
            [-39.9920249527, -3.1826137651],
            [-39.9967157036, -3.1982411034],
            [-39.9920585037, -3.2116612305],
            [-39.9892004124, -3.2166640507],
            [-39.9798116536, -3.22158176],
            [-39.9770873649, -3.2321764099],
            [-39.9663096575, -3.2391429749],
            [-39.9437010141, -3.24531291],
            [-39.9267553743, -3.2658399432],
            [-39.9085526952, -3.2737722648],
            [-39.910057626, -3.2859064122],
            [-39.9223666393, -3.2937874083],
            [-39.9271816064, -3.311161529],
            [-39.9350335154, -3.3157623158],
            [-39.9758550468, -3.2578693848],
            [-39.9911212239, -3.2362060455],
            [-40.0025312507, -3.2203321506],
            [-40.0038327953, -3.2186214253],
            [-40.0368829051, -3.1734419493],
            [-40.0734089554, -3.12347731],
            [-40.0834235016, -3.1158451385],
            [-40.0828908348, -3.0828260255],
            [-40.0886963005, -3.0729756124],
            [-40.1133197356, -3.0511691128],
            [-40.1216811165, -3.0346137603],
            [-40.139330762, -3.0378957221],
            [-40.1473083956, -3.041730661],
            [-40.1543138128, -3.0335660575],
            [-40.1579187353, -3.020349298],
            [-40.1567011079, -2.9937888033],
            [-40.1608356139, -2.989924364],
            [-40.1645888755, -2.9935740654],
            [-40.1681784975, -2.9918687755],
            [-40.1669470133, -2.9849566957],
            [-40.1603956411, -2.9809748975],
            [-40.1602582788, -2.9776324862],
            [-40.1689767805, -2.9767375883],
            [-40.168846263, -2.9729909428],
            [-40.162724592, -2.9704399263],
            [-40.1733363028, -2.9650257513],
            [-40.1750017798, -2.959590917],
            [-40.1703483132, -2.9426709483],
            [-40.1702278651, -2.9317620794],
            [-40.1645271972, -2.9235803952],
            [-40.1664525212, -2.9171381286],
            [-40.1642972309, -2.9161272496],
            [-40.1612782727, -2.9174353887],
            [-40.1502584201, -2.9058456104],
            [-40.1484097894, -2.8904694714],
            [-40.1547116195, -2.8825382515],
            [-40.1996511674, -2.8747565279],
            [-40.2207571071, -2.8723995675],
            [-40.2333970116, -2.8709882511],
            [-40.2728580504, -2.8695792974],
            [-40.2728438223, -2.8784296326],
            [-40.2756646922, -2.8784276354],
            [-40.2848428969, -2.8773478186],
            [-40.3220162367, -2.8613428138],
            [-40.3225405113, -2.8611299881],
            [-40.3310045769, -2.8647157733],
            [-40.3621740049, -2.8653689084],
            [-40.3718928389, -2.8099085373],
            [-40.3535342537, -2.8080045835],
            [-40.3169736621, -2.8053405837],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2300309',
        name: 'Acopiara',
        description: 'Acopiara',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.5675853714, -5.983159303],
            [-39.5614151221, -6.0086701525],
            [-39.5583357571, -6.0116251799],
            [-39.5502540504, -6.0116346234],
            [-39.5443039916, -6.0011718503],
            [-39.5431429625, -5.9889714495],
            [-39.5309480213, -5.9774015016],
            [-39.5275026319, -5.9812509276],
            [-39.5118699344, -5.9831727957],
            [-39.5000022779, -5.9801173786],
            [-39.4956641464, -5.9838239953],
            [-39.4919444847, -5.9857317448],
            [-39.4868360897, -5.9833138357],
            [-39.4789433277, -5.987422416],
            [-39.465364425, -5.9807210791],
            [-39.4480018644, -5.9799433754],
            [-39.4216237096, -5.9886932517],
            [-39.4142231741, -5.9859832333],
            [-39.4129433009, -5.9763232112],
            [-39.406787431, -5.9757661745],
            [-39.3818062148, -5.9735065337],
            [-39.3635315864, -5.9807086555],
            [-39.364210179, -5.9821034361],
            [-39.3660927334, -5.9859737151],
            [-39.3644430936, -5.9923101506],
            [-39.3558360415, -5.9898867416],
            [-39.3484777619, -5.9936307638],
            [-39.3459161643, -6.0097197816],
            [-39.3379184556, -6.0017942427],
            [-39.3269751788, -6.0019718382],
            [-39.3188343535, -6.0058283334],
            [-39.3070599699, -5.9956295876],
            [-39.2979122786, -5.9901533192],
            [-39.2940201559, -5.9985288363],
            [-39.2785520718, -6.0051906164],
            [-39.2722841283, -5.9994922989],
            [-39.2568351952, -5.9952128927],
            [-39.2570815614, -5.99821954],
            [-39.2386049736, -5.9956833681],
            [-39.2391531911, -6.0005566864],
            [-39.234949123, -6.0005502452],
            [-39.229394891, -5.9886552483],
            [-39.2142102381, -5.9912448483],
            [-39.2135402937, -5.9913586911],
            [-39.2032495764, -5.9862465502],
            [-39.197667375, -5.9568233505],
            [-39.1811161998, -5.9641143329],
            [-39.1711155148, -5.961346654],
            [-39.1654271936, -5.9648968987],
            [-39.1620711819, -5.9559971361],
            [-39.1589794594, -5.9563595404],
            [-39.1452113448, -5.9578371269],
            [-39.1425210889, -5.9673819874],
            [-39.1372762765, -5.9649535059],
            [-39.1383125052, -5.9714181945],
            [-39.1306282295, -5.9834610145],
            [-39.1135896617, -5.9767540102],
            [-39.1130165239, -5.988784944],
            [-39.1022979881, -5.9830048464],
            [-39.0866569032, -5.9922583509],
            [-39.078158413, -5.9913371781],
            [-39.0721421551, -5.9981465563],
            [-39.0603693185, -5.9975428542],
            [-39.0598984367, -6.0087363093],
            [-39.0519373273, -6.0254762508],
            [-39.0536792229, -6.0432877007],
            [-39.0570811287, -6.0477416933],
            [-39.0635902285, -6.0451603745],
            [-39.0737796947, -6.051443648],
            [-39.0754171571, -6.0616115257],
            [-39.0829797142, -6.0697228089],
            [-39.0830355796, -6.0767608276],
            [-39.091365331, -6.0838720342],
            [-39.0894448658, -6.0936481998],
            [-39.0778314481, -6.1127997174],
            [-39.0789461928, -6.1181413547],
            [-39.0862568476, -6.1213271791],
            [-39.1222377945, -6.1420327143],
            [-39.1405180337, -6.1525537205],
            [-39.1870416942, -6.1435448552],
            [-39.2034035541, -6.1399207317],
            [-39.2176597244, -6.136761205],
            [-39.2596984358, -6.1274417266],
            [-39.3077186607, -6.1744490965],
            [-39.3162675115, -6.1828198004],
            [-39.3322893363, -6.1985088799],
            [-39.3428514191, -6.2088527203],
            [-39.3585790192, -6.2241030585],
            [-39.368898898, -6.2161545808],
            [-39.3723580791, -6.2169614026],
            [-39.3781163098, -6.2151617724],
            [-39.4059821524, -6.2336793719],
            [-39.4171960896, -6.2463437473],
            [-39.4163712553, -6.2609507178],
            [-39.4634207602, -6.2791930276],
            [-39.4629192601, -6.2848297072],
            [-39.4593669141, -6.3247458872],
            [-39.4681092916, -6.3237828221],
            [-39.4685557055, -6.329430376],
            [-39.4751416912, -6.3373062929],
            [-39.4849644127, -6.3352470676],
            [-39.4835560783, -6.3297852056],
            [-39.4911926587, -6.3245094508],
            [-39.5014434037, -6.3342321581],
            [-39.5086978333, -6.328754431],
            [-39.5221126787, -6.338088129],
            [-39.5285020018, -6.331552646],
            [-39.5236962218, -6.3259341856],
            [-39.542066443, -6.3194275373],
            [-39.5520814051, -6.3238589512],
            [-39.5593927662, -6.3316490207],
            [-39.5617400855, -6.3285185967],
            [-39.5779989289, -6.3138217622],
            [-39.5892300777, -6.3115343684],
            [-39.5903370456, -6.3068746734],
            [-39.6050455671, -6.3189355523],
            [-39.6053941345, -6.3223453088],
            [-39.6090890936, -6.3209420446],
            [-39.615696823, -6.32477072],
            [-39.6177796364, -6.3204866411],
            [-39.6260531561, -6.3182419124],
            [-39.6532206971, -6.3371194269],
            [-39.6704378037, -6.3349859145],
            [-39.6740857664, -6.3327780028],
            [-39.6802558782, -6.3371368546],
            [-39.6887383498, -6.3292648477],
            [-39.695375188, -6.3336221425],
            [-39.6946807005, -6.3366814928],
            [-39.7024769664, -6.3381982272],
            [-39.701550138, -6.3412558373],
            [-39.709677971, -6.3505935248],
            [-39.7196508974, -6.2712558355],
            [-39.7219229222, -6.2701733988],
            [-39.7249653844, -6.2687243071],
            [-39.7283275174, -6.274061497],
            [-39.7295069272, -6.2621470575],
            [-39.7343109747, -6.2561012087],
            [-39.7337020133, -6.2421939148],
            [-39.7218830465, -6.2346210625],
            [-39.7181345093, -6.2247221221],
            [-39.7170146081, -6.2141544236],
            [-39.7174399386, -6.210818929],
            [-39.7346256934, -6.2114863406],
            [-39.7336306999, -6.2244248125],
            [-39.7356510958, -6.2223523959],
            [-39.7505450489, -6.2269891018],
            [-39.7556437083, -6.2401041627],
            [-39.7592527553, -6.2381197812],
            [-39.7630283583, -6.2256756962],
            [-39.7631142968, -6.2096691243],
            [-39.7701644956, -6.204430975],
            [-39.7929058443, -6.2073309542],
            [-39.7987102386, -6.2091989715],
            [-39.7942117343, -6.1960561759],
            [-39.8003175288, -6.1951580275],
            [-39.79482041, -6.174542837],
            [-39.7981486368, -6.1681459917],
            [-39.7938141805, -6.157113118],
            [-39.799046487, -6.1541726268],
            [-39.7993116268, -6.1476191767],
            [-39.8074809148, -6.1427755137],
            [-39.8022362444, -6.1370912615],
            [-39.8135032156, -6.1349276704],
            [-39.8254732149, -6.1270257048],
            [-39.8291448098, -6.129288181],
            [-39.8312714687, -6.1377377307],
            [-39.8344841804, -6.1366719919],
            [-39.8513547761, -6.1138994498],
            [-39.8588136407, -6.1252725827],
            [-39.8629512596, -6.12647504],
            [-39.8675425135, -6.1250867473],
            [-39.8676837886, -6.1198865058],
            [-39.8757181847, -6.1200829725],
            [-39.8912975502, -6.1139407617],
            [-39.8936181492, -6.1111066201],
            [-39.8895184919, -6.0981560565],
            [-39.8859282472, -6.0967950961],
            [-39.8871987341, -6.0941554703],
            [-39.8756858014, -6.0907291449],
            [-39.8767397021, -6.0861779446],
            [-39.8635236244, -6.0808807308],
            [-39.8638983642, -6.0764125988],
            [-39.8566316978, -6.0728954556],
            [-39.8560674626, -6.0665582065],
            [-39.8422552643, -6.0587115809],
            [-39.825524552, -6.0415436698],
            [-39.8222998514, -6.0349960991],
            [-39.8261366602, -6.024996322],
            [-39.8151040211, -6.017385721],
            [-39.8121149229, -6.0116855649],
            [-39.7944720115, -6.0207647436],
            [-39.7850287792, -6.0188251369],
            [-39.7741059631, -6.0157466825],
            [-39.7686763227, -6.007473908],
            [-39.7541616241, -6.0062875321],
            [-39.7227139467, -5.9750367362],
            [-39.7298083988, -5.9902517573],
            [-39.7413890682, -5.9999229023],
            [-39.7458995563, -6.0080259022],
            [-39.7412203356, -6.0118256254],
            [-39.742238148, -6.0176895291],
            [-39.7316662975, -6.0207831651],
            [-39.7279951146, -6.0127050542],
            [-39.7245076369, -6.0139179204],
            [-39.726058433, -6.0065014669],
            [-39.7112276171, -6.0073172632],
            [-39.7041937913, -6.0042856789],
            [-39.6953924237, -6.01533467],
            [-39.6861168101, -6.0156937281],
            [-39.6844703529, -6.0210438026],
            [-39.6746431671, -6.0220884603],
            [-39.6701736427, -5.9943533391],
            [-39.6647774355, -5.9929760362],
            [-39.6522806149, -5.9662556313],
            [-39.6453869928, -5.9656804837],
            [-39.6074345514, -5.9854980555],
            [-39.6001707278, -5.9887598726],
            [-39.5940605866, -5.9745815165],
            [-39.5790041874, -5.968412024],
            [-39.5763565699, -5.9632139844],
            [-39.5642495707, -5.9605898153],
            [-39.5675853714, -5.983159303],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2300408',
        name: 'Aiuaba',
        description: 'Aiuaba',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.107002697, -6.3511663748],
            [-40.0847401575, -6.3682019186],
            [-40.0837214889, -6.3794072582],
            [-40.0747472367, -6.4013784308],
            [-40.0668871046, -6.4060387383],
            [-40.0596548228, -6.4184128639],
            [-40.0468810193, -6.4235588865],
            [-40.0282293352, -6.4378105286],
            [-40.0294589662, -6.4425209932],
            [-40.0381876938, -6.4399175948],
            [-40.0442946196, -6.4440364601],
            [-40.0493154843, -6.4530833244],
            [-40.0483840859, -6.4604334296],
            [-40.0519576768, -6.4671153672],
            [-40.047708424, -6.4708200407],
            [-40.0562099881, -6.4778823211],
            [-40.0545624721, -6.4871360937],
            [-40.0593339411, -6.4901098221],
            [-40.0550517294, -6.4953248101],
            [-40.0597656363, -6.499928985],
            [-40.0587759229, -6.5069868829],
            [-40.0497915133, -6.5146860599],
            [-40.0515231598, -6.5215680688],
            [-40.0413875614, -6.5313090853],
            [-40.0405920419, -6.5384269313],
            [-40.0312094796, -6.5427121327],
            [-40.0348163897, -6.5583196081],
            [-40.0301022554, -6.5627970605],
            [-40.0257143538, -6.5813175463],
            [-40.0342889538, -6.5869963263],
            [-40.0438582673, -6.5883115286],
            [-40.043914787, -6.5939112638],
            [-40.0404540045, -6.6007149109],
            [-40.0518756508, -6.6063260928],
            [-40.0574519791, -6.6216362907],
            [-40.0544227478, -6.6261372189],
            [-40.0399998673, -6.6272003408],
            [-40.0365675564, -6.637188553],
            [-40.0564985886, -6.6740201119],
            [-40.0484879733, -6.6740776201],
            [-40.0516512393, -6.679553907],
            [-40.0465625598, -6.6822855723],
            [-40.0400606957, -6.6727729735],
            [-40.0296146376, -6.6715726452],
            [-40.0289788122, -6.6761030997],
            [-40.0210777901, -6.6742784504],
            [-40.0210490322, -6.6783676978],
            [-40.0137160197, -6.6773308655],
            [-40.0082513989, -6.6868121469],
            [-40.004850882, -6.6864177427],
            [-40.0021338908, -6.6960412028],
            [-39.9980239217, -6.6968306267],
            [-39.9961957585, -6.701542636],
            [-40.0116047489, -6.7123377054],
            [-40.0263878753, -6.7321006327],
            [-40.0325180719, -6.7348119859],
            [-40.0469052682, -6.7548282529],
            [-40.0697351957, -6.7720314104],
            [-40.0720271691, -6.7794633624],
            [-40.0659891191, -6.7879891044],
            [-40.0687814205, -6.7919242147],
            [-40.078839895, -6.7958611279],
            [-40.0826201157, -6.7917850152],
            [-40.0950421538, -6.8028367238],
            [-40.1041430951, -6.806721747],
            [-40.1082966922, -6.8051931734],
            [-40.1166465181, -6.8190470811],
            [-40.1229406514, -6.8186920401],
            [-40.1400918679, -6.8223751754],
            [-40.1405190423, -6.8175881942],
            [-40.1449557661, -6.8184460026],
            [-40.1329983473, -6.8104759905],
            [-40.1351999513, -6.8073947337],
            [-40.1305710841, -6.8033944066],
            [-40.1389584315, -6.8018566891],
            [-40.1414640784, -6.7914539442],
            [-40.1498767701, -6.7843453669],
            [-40.1681983489, -6.7913901064],
            [-40.1693271793, -6.7772534793],
            [-40.1801250998, -6.7592760963],
            [-40.2030858305, -6.7741571776],
            [-40.2420421711, -6.776660338],
            [-40.2513816943, -6.78623015],
            [-40.258079826, -6.7858078831],
            [-40.2645877295, -6.796255443],
            [-40.2806876171, -6.7940587444],
            [-40.2914773189, -6.807575413],
            [-40.2977172017, -6.8075187198],
            [-40.3057897377, -6.7879154046],
            [-40.3100586655, -6.786117597],
            [-40.3153889174, -6.7911796028],
            [-40.3180521124, -6.8002318354],
            [-40.3312545382, -6.8039579196],
            [-40.3426007512, -6.8020332011],
            [-40.3481374556, -6.7963714133],
            [-40.3519835322, -6.8084256935],
            [-40.370141416, -6.8026706847],
            [-40.3884217891, -6.7965990027],
            [-40.4037687864, -6.8020881194],
            [-40.4164668336, -6.8121331902],
            [-40.4320292675, -6.8124239831],
            [-40.4386213214, -6.8062883086],
            [-40.4425611368, -6.7894743517],
            [-40.4516605084, -6.7833759238],
            [-40.4566664652, -6.7622028061],
            [-40.4593576574, -6.7538382193],
            [-40.4715680814, -6.7445350765],
            [-40.4735178947, -6.734478227],
            [-40.4923213804, -6.7315354498],
            [-40.5170181214, -6.7300958062],
            [-40.5614081572, -6.7199714869],
            [-40.6018368237, -6.7156128654],
            [-40.6160509513, -6.710003108],
            [-40.6320483656, -6.6985050059],
            [-40.6487689415, -6.6784307778],
            [-40.7098116302, -6.6757310231],
            [-40.7257216765, -6.6628064132],
            [-40.7318688443, -6.6531870938],
            [-40.7226096882, -6.6210952493],
            [-40.7216313904, -6.6172847367],
            [-40.7360481949, -6.5998118628],
            [-40.6798923909, -6.6016400901],
            [-40.6709449997, -6.613889275],
            [-40.6667237672, -6.6124084521],
            [-40.649929754, -6.6134302745],
            [-40.6446271055, -6.6062047554],
            [-40.6368757696, -6.6043763564],
            [-40.6213982594, -6.60407221],
            [-40.6107695719, -6.6081896385],
            [-40.6097004041, -6.6028009473],
            [-40.5975439611, -6.594027717],
            [-40.5965218723, -6.5839318468],
            [-40.5880727503, -6.5734846873],
            [-40.5897688197, -6.5622017258],
            [-40.5746004944, -6.5466424799],
            [-40.5630241396, -6.5445740341],
            [-40.5551244233, -6.5510433094],
            [-40.5418044648, -6.5486005046],
            [-40.5279781806, -6.5382314707],
            [-40.4825999927, -6.5278616369],
            [-40.459452639, -6.5279090122],
            [-40.4486572646, -6.514776104],
            [-40.4469051474, -6.4947713878],
            [-40.4395927764, -6.4881457972],
            [-40.3911512521, -6.4743292198],
            [-40.3878102482, -6.4652531637],
            [-40.3826165118, -6.4630660646],
            [-40.3691321813, -6.4504448232],
            [-40.3721788652, -6.43534343],
            [-40.3674222052, -6.4334489387],
            [-40.3665772742, -6.42843145],
            [-40.3517515932, -6.4211724563],
            [-40.3503151673, -6.4172898188],
            [-40.3532369973, -6.4168834106],
            [-40.3539706458, -6.4100991984],
            [-40.3348225494, -6.4149897929],
            [-40.3361131221, -6.408282983],
            [-40.3278469297, -6.4010323297],
            [-40.3276181362, -6.4008997344],
            [-40.3146356432, -6.397382794],
            [-40.3084854419, -6.4023023236],
            [-40.307788987, -6.3954779183],
            [-40.2950840664, -6.3906022956],
            [-40.2849641178, -6.3798676382],
            [-40.2807052953, -6.3835718113],
            [-40.2748115192, -6.3868772494],
            [-40.2605131481, -6.3855522029],
            [-40.2574461723, -6.3820217709],
            [-40.2607222506, -6.377685827],
            [-40.25665243, -6.3747565394],
            [-40.2497623005, -6.381156724],
            [-40.2501284113, -6.3869799087],
            [-40.2451394548, -6.3868300998],
            [-40.2414715614, -6.3920593656],
            [-40.2264024251, -6.386134655],
            [-40.2179049198, -6.3757701896],
            [-40.2065797224, -6.3801586669],
            [-40.186551085, -6.3649972459],
            [-40.1726464349, -6.3603648739],
            [-40.1627420895, -6.3610338244],
            [-40.1571496539, -6.3533133859],
            [-40.1481278701, -6.36011452],
            [-40.1420617855, -6.3508719406],
            [-40.1326243335, -6.3497113194],
            [-40.1170688734, -6.3480766161],
            [-40.107002697, -6.3511663748],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2300507',
        name: 'Alcântaras',
        description: 'Alcântaras',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.5211703597, -3.5451814379],
            [-40.5074220189, -3.5503561887],
            [-40.4898733049, -3.5498022841],
            [-40.4808600882, -3.5568638559],
            [-40.4817536814, -3.5620317894],
            [-40.4819192277, -3.5621582231],
            [-40.5008847257, -3.5767103117],
            [-40.4977537193, -3.5836677692],
            [-40.4909186384, -3.5839811157],
            [-40.4793021227, -3.5932959497],
            [-40.4879213462, -3.599650166],
            [-40.4975872041, -3.6047017791],
            [-40.4985893642, -3.6146788794],
            [-40.5051467093, -3.62510389],
            [-40.5024432379, -3.6271412027],
            [-40.4999031733, -3.6296216952],
            [-40.4931427329, -3.6354405194],
            [-40.5059610295, -3.64285309],
            [-40.5129578178, -3.6427750203],
            [-40.5203253406, -3.6378921729],
            [-40.5157299079, -3.6483911945],
            [-40.521662856, -3.6494874163],
            [-40.5309257062, -3.6515951594],
            [-40.5332015005, -3.6560401944],
            [-40.5496603092, -3.653794908],
            [-40.5775440441, -3.6477812397],
            [-40.5790964601, -3.6453683034],
            [-40.5762340634, -3.6442747665],
            [-40.5807239961, -3.6373722988],
            [-40.5900232337, -3.6393427036],
            [-40.5888759946, -3.6323667765],
            [-40.5950557558, -3.631298817],
            [-40.5934016758, -3.6217847055],
            [-40.5878883203, -3.6211610042],
            [-40.5981023827, -3.6170410261],
            [-40.6090031669, -3.6208692121],
            [-40.6178539791, -3.6075311097],
            [-40.6051834284, -3.606417551],
            [-40.6084240513, -3.5986178976],
            [-40.6105496465, -3.590414736],
            [-40.6049825683, -3.58917216],
            [-40.6086398366, -3.5821300428],
            [-40.5926925292, -3.5778864207],
            [-40.6007171226, -3.5730925133],
            [-40.5999939111, -3.5684853629],
            [-40.5893433048, -3.5624545178],
            [-40.5781477315, -3.5665193323],
            [-40.5706301266, -3.5647283888],
            [-40.5721267625, -3.5551671389],
            [-40.5569780073, -3.5386833975],
            [-40.5507063156, -3.5379899301],
            [-40.5472620179, -3.5274653554],
            [-40.5412577893, -3.5227082079],
            [-40.5316687421, -3.5149763277],
            [-40.5214153814, -3.512051859],
            [-40.5201406544, -3.5157606934],
            [-40.5146188466, -3.5318260307],
            [-40.5211703597, -3.5451814379],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2300606',
        name: 'Altaneira',
        description: 'Altaneira',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.6205687297, -6.9631085053],
            [-39.6333195077, -6.9683340317],
            [-39.6363745323, -6.9833101952],
            [-39.6452128566, -6.9911075656],
            [-39.6639182506, -6.9922585308],
            [-39.6632972391, -6.9948434549],
            [-39.6629005171, -6.995545943],
            [-39.6559903158, -7.0048964237],
            [-39.6722171907, -7.0088335182],
            [-39.6805053624, -7.0173812436],
            [-39.6905069356, -7.0167206975],
            [-39.7163259571, -7.0022257566],
            [-39.7255621323, -7.0046234582],
            [-39.7369522773, -7.0075804836],
            [-39.7434454467, -7.0092663763],
            [-39.7588687729, -7.0132707754],
            [-39.746912659, -7.0231994351],
            [-39.7472082174, -7.0231291577],
            [-39.7979633748, -7.0104948221],
            [-39.7769266578, -7.0027817639],
            [-39.7678927325, -6.9932480134],
            [-39.7650418059, -6.9930063921],
            [-39.7580446587, -6.992863417],
            [-39.7546142068, -6.986736207],
            [-39.7326152341, -6.9702875604],
            [-39.7208968892, -6.9666872508],
            [-39.7132087949, -6.9550375233],
            [-39.7095715875, -6.9544948744],
            [-39.7038787532, -6.9536462959],
            [-39.6974293897, -6.9612278173],
            [-39.690727417, -6.9613040888],
            [-39.6930183763, -6.9688406584],
            [-39.6904287193, -6.9700492443],
            [-39.6834742579, -6.9643290602],
            [-39.6791226918, -6.9648391361],
            [-39.639800652, -6.9459632481],
            [-39.6207841376, -6.9594699075],
            [-39.6205687297, -6.9631085053],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2300705',
        name: 'Alto Santo',
        description: 'Alto Santo',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.3940974766, -5.3625632144],
            [-38.3416894045, -5.4025996973],
            [-38.3401198393, -5.4037981471],
            [-38.3228060358, -5.4039096584],
            [-38.313233796, -5.3896970123],
            [-38.3027133163, -5.3897345745],
            [-38.2610422053, -5.4099107492],
            [-38.2599549299, -5.3934194625],
            [-38.2307255727, -5.3931525877],
            [-38.2235573172, -5.3896046496],
            [-38.2204106409, -5.3880469271],
            [-38.1824707951, -5.3692710531],
            [-38.1749178303, -5.3655333061],
            [-38.1584524576, -5.3573866109],
            [-38.1627859922, -5.3676103153],
            [-38.1643074418, -5.3712000212],
            [-38.168278615, -5.3805708195],
            [-38.1686999203, -5.3815651636],
            [-38.1915922973, -5.4356053313],
            [-38.1772541527, -5.4356215054],
            [-38.115814277, -5.4356855771],
            [-38.1114065066, -5.4356896836],
            [-38.0757001633, -5.435724063],
            [-38.0667884185, -5.4357510065],
            [-38.0118987305, -5.4359181721],
            [-38.0091562262, -5.4359265831],
            [-38.0086579257, -5.4359278031],
            [-37.9606833026, -5.4361631409],
            [-37.9516569475, -5.4362533851],
            [-37.9158431877, -5.4366082834],
            [-37.8808817894, -5.4357933077],
            [-37.888531335, -5.4489630424],
            [-37.8918792496, -5.4803684625],
            [-37.9003310903, -5.4912113757],
            [-37.9011885758, -5.4950822348],
            [-37.9016406023, -5.4971218514],
            [-37.9018995211, -5.5002704986],
            [-37.9171440736, -5.5049695037],
            [-37.9322878839, -5.502716401],
            [-37.9477098637, -5.5342533128],
            [-37.9896901582, -5.5554062344],
            [-37.9974323188, -5.5675469197],
            [-37.9991484982, -5.5749992266],
            [-37.9979803529, -5.5849256173],
            [-38.013616869, -5.5940060711],
            [-38.0339222702, -5.6367604705],
            [-38.0530359823, -5.6457116487],
            [-38.0789206911, -5.6657313109],
            [-38.0825303468, -5.6721493067],
            [-38.1049437476, -5.6711240802],
            [-38.1234767124, -5.6716437316],
            [-38.1301113444, -5.6759594284],
            [-38.139025094, -5.6747111543],
            [-38.1477302073, -5.6660087216],
            [-38.1482288337, -5.6587660174],
            [-38.1609787796, -5.6560063507],
            [-38.1714607676, -5.6513460061],
            [-38.1678505372, -5.6452495828],
            [-38.1846988007, -5.6412127787],
            [-38.1870865366, -5.6412692178],
            [-38.1955696492, -5.6345799615],
            [-38.2036694519, -5.634380312],
            [-38.2053480566, -5.6310232462],
            [-38.2137171203, -5.6326093296],
            [-38.2142726571, -5.6356021687],
            [-38.2143573022, -5.6388049813],
            [-38.218945655, -5.6399673772],
            [-38.2237587965, -5.6345119091],
            [-38.2270421322, -5.6430197246],
            [-38.2305023544, -5.6400862679],
            [-38.2324424764, -5.6431329584],
            [-38.2371491668, -5.6366282769],
            [-38.2482669213, -5.6468345404],
            [-38.2652304805, -5.6267215408],
            [-38.2677145349, -5.6014629115],
            [-38.417442131, -5.6243930834],
            [-38.4330490952, -5.6267318639],
            [-38.4338628989, -5.6101624178],
            [-38.4370568822, -5.5448805576],
            [-38.4461740631, -5.5448945434],
            [-38.4491311245, -5.5337966977],
            [-38.45705714, -5.534155129],
            [-38.4597618425, -5.4978599087],
            [-38.4573669617, -5.492045204],
            [-38.4509210305, -5.4893416878],
            [-38.4321094486, -5.4731048663],
            [-38.4307233924, -5.4670545938],
            [-38.4321701161, -5.4537849956],
            [-38.426325857, -5.4451817375],
            [-38.4431590815, -5.4279716707],
            [-38.4261244765, -5.4147456513],
            [-38.4272158377, -5.4099900718],
            [-38.4231422743, -5.4073425774],
            [-38.4253300598, -5.3925438666],
            [-38.4165469763, -5.3879545195],
            [-38.4125290931, -5.3738948846],
            [-38.406333911, -5.3698464014],
            [-38.3956692187, -5.3702388204],
            [-38.3940974766, -5.3625632144],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2300754',
        name: 'Amontada',
        description: 'Amontada',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.6621359103, -3.0176001495],
            [-39.6537923037, -3.0232730128],
            [-39.6469311356, -3.0225770565],
            [-39.6378126728, -3.018671124],
            [-39.6190619061, -3.0259491614],
            [-39.6096482807, -3.0444504953],
            [-39.5962969865, -3.0652402033],
            [-39.5893528308, -3.0702763896],
            [-39.5732356829, -3.071680169],
            [-39.5667697223, -3.0753500964],
            [-39.5504525315, -3.0793815081],
            [-39.5600385887, -3.1099779922],
            [-39.5652010656, -3.1264610169],
            [-39.56659811, -3.1309218649],
            [-39.5857089155, -3.1412445422],
            [-39.6088424983, -3.1537416144],
            [-39.617068466, -3.1490892403],
            [-39.6270531436, -3.150875142],
            [-39.6359204895, -3.1566958891],
            [-39.651666454, -3.1618748598],
            [-39.6567957294, -3.1625980258],
            [-39.6670793044, -3.1624531665],
            [-39.6800974678, -3.1955922208],
            [-39.679613066, -3.2057712553],
            [-39.6908677832, -3.2071636935],
            [-39.6897939194, -3.2203519269],
            [-39.7000583563, -3.2323152805],
            [-39.6989517132, -3.2409807549],
            [-39.7018728607, -3.2444746437],
            [-39.72771865, -3.244736315],
            [-39.7227573913, -3.2594349795],
            [-39.7197410202, -3.268368583],
            [-39.7162898623, -3.2785896097],
            [-39.702421638, -3.3196399777],
            [-39.7032111947, -3.3226198583],
            [-39.7033366753, -3.3227768622],
            [-39.7143950156, -3.3353029735],
            [-39.7263709475, -3.3379448397],
            [-39.7285230012, -3.3437204647],
            [-39.7384171119, -3.3564312152],
            [-39.7380327703, -3.3683306643],
            [-39.7333348047, -3.3749396725],
            [-39.7410043067, -3.3882148181],
            [-39.7350422382, -3.3958369174],
            [-39.741144217, -3.4062113267],
            [-39.7412924756, -3.4064669693],
            [-39.7535309841, -3.4147032775],
            [-39.7518091412, -3.4243305036],
            [-39.7673043859, -3.4423898786],
            [-39.7660571571, -3.4517489695],
            [-39.7693648343, -3.4578434815],
            [-39.7841606489, -3.4644658241],
            [-39.8674513752, -3.4504343928],
            [-39.8928678526, -3.4454934628],
            [-39.8948603619, -3.4451439062],
            [-39.8961625349, -3.4449151669],
            [-39.9183412677, -3.4388872687],
            [-39.9336050278, -3.4359743382],
            [-39.9514539157, -3.4329722859],
            [-39.9845631855, -3.4410405287],
            [-40.0135270179, -3.4377526438],
            [-40.0271025775, -3.4377725805],
            [-40.0254328544, -3.4324460233],
            [-40.0071685638, -3.4242127952],
            [-40.0016926991, -3.4116283961],
            [-39.9973107443, -3.4097309468],
            [-39.9932235179, -3.3890822687],
            [-39.9885880046, -3.3811457137],
            [-39.9884726931, -3.3661823812],
            [-39.9812554592, -3.3631335912],
            [-39.9733878568, -3.3553660754],
            [-39.9571991204, -3.3494740888],
            [-39.9415624911, -3.3369814216],
            [-39.9368944694, -3.3224398903],
            [-39.9326419738, -3.3191669552],
            [-39.9350335154, -3.3157623158],
            [-39.9271816064, -3.311161529],
            [-39.9223666393, -3.2937874083],
            [-39.910057626, -3.2859064122],
            [-39.9085526952, -3.2737722648],
            [-39.9069948626, -3.2734059517],
            [-39.9042844517, -3.2608272388],
            [-39.8920351276, -3.2419556669],
            [-39.8957240291, -3.2369500109],
            [-39.8944489676, -3.2307596399],
            [-39.8902879139, -3.2256029107],
            [-39.8928029769, -3.2191926558],
            [-39.9013412113, -3.2149593927],
            [-39.9038091766, -3.2057734697],
            [-39.8945810661, -3.1949246082],
            [-39.885319861, -3.1897995096],
            [-39.8797240741, -3.1874751933],
            [-39.8782034844, -3.1804150984],
            [-39.8740135634, -3.1788364758],
            [-39.8656149984, -3.1556939493],
            [-39.8640318388, -3.1299133188],
            [-39.8678029994, -3.1238888313],
            [-39.8676862275, -3.1230833971],
            [-39.8666371983, -3.1218927573],
            [-39.8228481186, -3.0924024003],
            [-39.8205282731, -3.0908410496],
            [-39.784326899, -3.0664696082],
            [-39.7615485368, -3.0511380164],
            [-39.7595584933, -3.0497982721],
            [-39.7387778117, -3.0358142789],
            [-39.738617324, -3.0357060821],
            [-39.7078557259, -3.0139714202],
            [-39.70656755, -3.0045620658],
            [-39.7136074787, -2.9995096764],
            [-39.6875321373, -2.9942913517],
            [-39.6750365605, -3.0020148092],
            [-39.6621359103, -3.0176001495],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2300804',
        name: 'Antonina do Norte',
        description: 'Antonina do Norte',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.9710304711, -6.6869249075],
            [-39.96848694, -6.6949485003],
            [-39.9627786113, -6.6942930085],
            [-39.9627133153, -6.7012184912],
            [-39.9566929252, -6.7011778069],
            [-39.9400823956, -6.7120012656],
            [-39.9293280905, -6.6975064025],
            [-39.9221431891, -6.6967558615],
            [-39.9126811271, -6.6877039051],
            [-39.9022304392, -6.6871568336],
            [-39.8824870522, -6.6754303159],
            [-39.874191224, -6.670168018],
            [-39.8763145824, -6.6804439232],
            [-39.8659316075, -6.6761683009],
            [-39.8644149751, -6.6797604217],
            [-39.8582453784, -6.67864461],
            [-39.8525774708, -6.6741482963],
            [-39.8486602164, -6.6749988318],
            [-39.8476523569, -6.6797190395],
            [-39.8379621539, -6.6790702988],
            [-39.8335768863, -6.6902429186],
            [-39.8363205688, -6.6954935286],
            [-39.832276022, -6.6994593776],
            [-39.83724659, -6.7081096676],
            [-39.8533328937, -6.7155415529],
            [-39.8783099342, -6.7347256755],
            [-39.9092554593, -6.7631287663],
            [-39.9139237046, -6.7669372994],
            [-39.9396174064, -6.7835259834],
            [-39.9865531576, -6.8146807071],
            [-40.0016948268, -6.8027287635],
            [-40.0221943591, -6.8134626688],
            [-40.0430538615, -6.8165854887],
            [-40.0549465995, -6.8290851347],
            [-40.0556956505, -6.8365407004],
            [-40.0507563304, -6.8432191815],
            [-40.0764529354, -6.8561938584],
            [-40.0871465949, -6.8469572573],
            [-40.1469585677, -6.8304603341],
            [-40.1449557661, -6.8184460026],
            [-40.1405190423, -6.8175881942],
            [-40.1400918679, -6.8223751754],
            [-40.1229406514, -6.8186920401],
            [-40.1166465181, -6.8190470811],
            [-40.1082966922, -6.8051931734],
            [-40.1041430951, -6.806721747],
            [-40.0950421538, -6.8028367238],
            [-40.0826201157, -6.7917850152],
            [-40.078839895, -6.7958611279],
            [-40.0687814205, -6.7919242147],
            [-40.0659891191, -6.7879891044],
            [-40.0720271691, -6.7794633624],
            [-40.0697351957, -6.7720314104],
            [-40.0469052682, -6.7548282529],
            [-40.0325180719, -6.7348119859],
            [-40.0263878753, -6.7321006327],
            [-40.0116047489, -6.7123377054],
            [-39.9961957585, -6.701542636],
            [-39.9980239217, -6.6968306267],
            [-40.0021338908, -6.6960412028],
            [-40.004850882, -6.6864177427],
            [-39.9884341023, -6.6811620237],
            [-39.9853658232, -6.6719064993],
            [-39.9756183226, -6.6685864429],
            [-39.9756414723, -6.6799457172],
            [-39.9815571416, -6.6859486179],
            [-39.9710304711, -6.6869249075],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2300903',
        name: 'Apuiarés',
        description: 'Apuiarés',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.3376427911, -3.864766997],
            [-39.3182196493, -3.9122690152],
            [-39.3088344638, -3.9352073656],
            [-39.2976167337, -3.9224429341],
            [-39.2951180001, -3.9194522471],
            [-39.2881932874, -3.9215938707],
            [-39.2812854193, -3.9154829987],
            [-39.2703341289, -3.9158774666],
            [-39.2404638719, -3.8875762898],
            [-39.2177434836, -3.8660542787],
            [-39.2071657717, -3.8777609445],
            [-39.2120914336, -3.8900104748],
            [-39.2148903553, -3.9017095665],
            [-39.2073655395, -3.9094590686],
            [-39.2009775644, -3.9101899502],
            [-39.2025856345, -3.9139712751],
            [-39.1925014633, -3.9212829474],
            [-39.1935314335, -3.9280990508],
            [-39.1875707247, -3.9347334524],
            [-39.184843358, -3.9370020714],
            [-39.1853650771, -3.9450003404],
            [-39.1873939955, -3.9557823966],
            [-39.1934503138, -3.9640178277],
            [-39.1922854123, -3.9708686549],
            [-39.1837166869, -3.9760238597],
            [-39.1901980297, -3.9912160603],
            [-39.1854148878, -4.0028437985],
            [-39.1923940631, -4.0123394456],
            [-39.1931446779, -4.0326684623],
            [-39.1970037924, -4.0339006397],
            [-39.2033611031, -4.0465724965],
            [-39.2145303818, -4.0509531942],
            [-39.2201911406, -4.0629820424],
            [-39.2256474084, -4.0658992304],
            [-39.2273132392, -4.0721637864],
            [-39.2379788683, -4.0779042349],
            [-39.2418071252, -4.0745128869],
            [-39.2586955493, -4.0774237493],
            [-39.270503598, -4.0847041314],
            [-39.2936080125, -4.0834156252],
            [-39.305507209, -4.0836304908],
            [-39.3232101614, -4.0891121306],
            [-39.3245330901, -4.0891259924],
            [-39.3729744772, -4.0404786451],
            [-39.3731258628, -4.0403271394],
            [-39.4434006697, -3.9704292255],
            [-39.4469740484, -3.9710441025],
            [-39.4468907068, -3.9792162355],
            [-39.451976375, -3.9828860762],
            [-39.4513956573, -3.9926024035],
            [-39.4597058374, -4.0036530792],
            [-39.4856964564, -4.0049008541],
            [-39.4846086466, -3.9995341298],
            [-39.4837910203, -3.9751514633],
            [-39.4655424558, -3.9427288125],
            [-39.4583345277, -3.9305229978],
            [-39.4517942503, -3.9129618289],
            [-39.4345859921, -3.8624733818],
            [-39.4284226723, -3.8649795856],
            [-39.4215347146, -3.8613246061],
            [-39.4154231135, -3.8710769914],
            [-39.4045869107, -3.8697762592],
            [-39.3990588411, -3.8797993033],
            [-39.3909002924, -3.8828796823],
            [-39.3894472836, -3.8791133501],
            [-39.3825587263, -3.87648391],
            [-39.3754754295, -3.862457181],
            [-39.3586672778, -3.8560164117],
            [-39.3492312874, -3.8364070593],
            [-39.3376427911, -3.864766997],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301000',
        name: 'Aquiraz',
        description: 'Aquiraz',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.412892352, -3.8153174414],
            [-38.4018389408, -3.8267754634],
            [-38.4015064515, -3.8233859332],
            [-38.3638974027, -3.8814697323],
            [-38.3428377656, -3.9033573761],
            [-38.3181313622, -3.925928288],
            [-38.303670774, -3.9353792465],
            [-38.2995936543, -3.9413894234],
            [-38.3082259141, -3.938710188],
            [-38.301530404, -3.943658896],
            [-38.2970034783, -3.9429391103],
            [-38.2997049743, -3.9369554222],
            [-38.28931432, -3.9410633548],
            [-38.2776955642, -3.9410779474],
            [-38.270898609, -3.9461824239],
            [-38.2554119889, -3.9655404403],
            [-38.231549141, -3.9965230082],
            [-38.2259097676, -4.002009235],
            [-38.2225058384, -4.0062924298],
            [-38.2269900933, -4.0079967158],
            [-38.2324236505, -4.0216930508],
            [-38.2540183707, -4.028005887],
            [-38.2920805002, -4.0206279758],
            [-38.2930181092, -4.0200770886],
            [-38.309434226, -4.0115489925],
            [-38.3159873455, -4.0172654878],
            [-38.3197509311, -4.0205487326],
            [-38.3414438067, -4.0394751719],
            [-38.347247462, -4.0445391683],
            [-38.3473404415, -4.0446202452],
            [-38.3702021135, -4.0645711315],
            [-38.3722351123, -4.0786030094],
            [-38.3850090297, -4.0708801764],
            [-38.4215014773, -4.0696006818],
            [-38.4217083813, -4.0696141423],
            [-38.4291600521, -4.070123184],
            [-38.4544917798, -4.0685469776],
            [-38.4755262138, -4.0612591383],
            [-38.5054164525, -4.0596600343],
            [-38.5355001282, -4.0409714197],
            [-38.527473913, -4.0301052009],
            [-38.527607419, -4.0229243399],
            [-38.515693773, -4.0133403769],
            [-38.5179731572, -4.0076048704],
            [-38.5147339662, -3.9726068203],
            [-38.5151869676, -3.9658117489],
            [-38.5152160066, -3.9653751264],
            [-38.5110117461, -3.9456922708],
            [-38.5086357112, -3.9367287691],
            [-38.4952979324, -3.934982028],
            [-38.4856709736, -3.9242680644],
            [-38.4788423597, -3.9284367858],
            [-38.4708276632, -3.926800889],
            [-38.4620807321, -3.9146951859],
            [-38.4537884382, -3.9213128669],
            [-38.4533940054, -3.9215500211],
            [-38.4491588523, -3.9204463271],
            [-38.4467600908, -3.9106197796],
            [-38.4437663403, -3.9030506856],
            [-38.433964037, -3.8928672894],
            [-38.4337334536, -3.8923912991],
            [-38.4332008536, -3.8863774451],
            [-38.4186091018, -3.8812347142],
            [-38.4155392383, -3.8792691039],
            [-38.4133808447, -3.8671105612],
            [-38.4138041247, -3.8563175531],
            [-38.417451746, -3.846281829],
            [-38.4224759926, -3.8443192631],
            [-38.4174463866, -3.827609063],
            [-38.4195211886, -3.8217619987],
            [-38.4126651681, -3.8213241486],
            [-38.412892352, -3.8153174414],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301109',
        name: 'Aracati',
        description: 'Aracati',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.7513364146, -4.4430235975],
            [-37.7428055498, -4.471258957],
            [-37.729031579, -4.4968075621],
            [-37.7055588435, -4.5204998708],
            [-37.6964317612, -4.5271040546],
            [-37.6882472306, -4.532410179],
            [-37.6841372996, -4.5370176709],
            [-37.6755017163, -4.5475842361],
            [-37.6687744871, -4.5558142483],
            [-37.6571064649, -4.5691149243],
            [-37.6539999135, -4.5726547096],
            [-37.6398827039, -4.5863461305],
            [-37.5973097349, -4.6224727165],
            [-37.581788282, -4.6291026013],
            [-37.5664197643, -4.6389874078],
            [-37.5401152347, -4.643872491],
            [-37.5434752618, -4.6533568189],
            [-37.5399250282, -4.660530711],
            [-37.5571419961, -4.72193111],
            [-37.5385967309, -4.742659908],
            [-37.5336362057, -4.7482023643],
            [-37.5044469144, -4.7915640286],
            [-37.5043437754, -4.7916146219],
            [-37.4626689254, -4.7814186382],
            [-37.4370462498, -4.7823422211],
            [-37.4229158437, -4.7778535329],
            [-37.4171270209, -4.7824135619],
            [-37.4106512815, -4.7799988341],
            [-37.4011516671, -4.7725716198],
            [-37.3989713325, -4.7758581044],
            [-37.3647623204, -4.7854569029],
            [-37.3566694153, -4.7951819376],
            [-37.3565046898, -4.8576523246],
            [-37.4461040621, -4.8805748225],
            [-37.4654529284, -4.8852288932],
            [-37.466739278, -4.8855381075],
            [-37.5390109897, -4.9027986118],
            [-37.5394672081, -4.902911047],
            [-37.5908251364, -4.9148846828],
            [-37.6399947953, -4.9260177488],
            [-37.6183171139, -4.8764176717],
            [-37.5916320097, -4.8154029741],
            [-37.5883468979, -4.8078957929],
            [-37.6111698992, -4.7678326925],
            [-37.6139976365, -4.7451821115],
            [-37.6187819937, -4.7169823003],
            [-37.6594318137, -4.715718409],
            [-37.6770742968, -4.7184554501],
            [-37.6849001314, -4.7146569215],
            [-37.6985224553, -4.7143461632],
            [-37.7052671629, -4.7190938528],
            [-37.7135476178, -4.7184068949],
            [-37.7210117469, -4.7196567998],
            [-37.7470455913, -4.7190773405],
            [-37.7569463339, -4.713659045],
            [-37.7781590131, -4.7090295616],
            [-37.789918504, -4.6995295069],
            [-37.7999346333, -4.6967631647],
            [-37.8074192284, -4.6769708689],
            [-37.8179135388, -4.6714388109],
            [-37.825157468, -4.6687481194],
            [-37.8303419889, -4.6613454195],
            [-37.8342453577, -4.666997513],
            [-37.8453400816, -4.6838760286],
            [-37.9085526882, -4.6634567531],
            [-37.93542126, -4.6619894594],
            [-37.9500323969, -4.6592618809],
            [-37.9638487508, -4.6563186099],
            [-37.983163736, -4.6613106804],
            [-37.9936005853, -4.6594415513],
            [-37.9949390774, -4.6468102417],
            [-37.9923028737, -4.6409373144],
            [-37.9763115532, -4.6293935427],
            [-37.9724161355, -4.6147395605],
            [-37.9863863847, -4.5835240896],
            [-37.9852703051, -4.5827017486],
            [-37.9928900544, -4.5628653062],
            [-37.9846446672, -4.5605894513],
            [-37.9805715414, -4.5411535848],
            [-37.9758302129, -4.5359877948],
            [-37.9644306264, -4.5328978944],
            [-37.9632619629, -4.5294539247],
            [-37.9385517448, -4.5256841081],
            [-37.9169809538, -4.5299927774],
            [-37.9105625653, -4.5367430072],
            [-37.8992421904, -4.5354150465],
            [-37.8692400462, -4.5436095091],
            [-37.8570060875, -4.5415538386],
            [-37.8478574255, -4.5345224455],
            [-37.8475195665, -4.5317087305],
            [-37.8473430246, -4.5213682571],
            [-37.8342427517, -4.5142541324],
            [-37.8318040491, -4.5074950926],
            [-37.8261530207, -4.5041521616],
            [-37.8059053027, -4.5058609074],
            [-37.7903923053, -4.5080713341],
            [-37.7765999165, -4.4783176226],
            [-37.7729227579, -4.4800697301],
            [-37.7714797912, -4.4768056553],
            [-37.7758405076, -4.4674526308],
            [-37.7685891997, -4.46125445],
            [-37.7692408439, -4.4540113892],
            [-37.7715312901, -4.4519503126],
            [-37.7762792128, -4.4571462706],
            [-37.7855179697, -4.4426961736],
            [-37.778860473, -4.4339405474],
            [-37.7762576091, -4.4310505417],
            [-37.7691748313, -4.4259868168],
            [-37.7513364146, -4.4430235975],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301208',
        name: 'Aracoiaba',
        description: 'Aracoiaba',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.759995032, -4.3076948889],
            [-38.7555659343, -4.3270436584],
            [-38.753669752, -4.3276784983],
            [-38.7451868462, -4.3517321149],
            [-38.7315893313, -4.3581633259],
            [-38.7303904606, -4.3658113076],
            [-38.7244054881, -4.366459573],
            [-38.7133910334, -4.3806317997],
            [-38.7134380267, -4.3836153461],
            [-38.6936325085, -4.3798430323],
            [-38.6913725053, -4.376985437],
            [-38.6802617338, -4.3901292325],
            [-38.6773215281, -4.400852023],
            [-38.6678392353, -4.4150050269],
            [-38.6639409141, -4.417743259],
            [-38.6564603097, -4.4140190055],
            [-38.6538116973, -4.4178787254],
            [-38.6466780151, -4.4164272457],
            [-38.639673244, -4.4077359839],
            [-38.6244739888, -4.4054444823],
            [-38.6136684943, -4.3891374862],
            [-38.6110957787, -4.3795890302],
            [-38.6024282486, -4.3818121202],
            [-38.6010611306, -4.3853430552],
            [-38.5958163521, -4.3832064386],
            [-38.5779237837, -4.3851343278],
            [-38.5668153798, -4.3769073508],
            [-38.5523902042, -4.3751554909],
            [-38.5466672729, -4.3704744493],
            [-38.5342811547, -4.3701946065],
            [-38.5333613455, -4.3776464138],
            [-38.5503615169, -4.3973474501],
            [-38.5746443225, -4.4184378309],
            [-38.5834470594, -4.4168968194],
            [-38.5896788457, -4.4231479625],
            [-38.5905089745, -4.4230983024],
            [-38.6029771435, -4.4223518281],
            [-38.6045011663, -4.4264775077],
            [-38.6146704548, -4.4297372963],
            [-38.6148863571, -4.4356702351],
            [-38.6267542498, -4.4386407395],
            [-38.6316157377, -4.4525942541],
            [-38.6371742246, -4.4534930502],
            [-38.636335184, -4.4581995722],
            [-38.6370201301, -4.4600626291],
            [-38.6328392408, -4.4704315796],
            [-38.6365305209, -4.4772798444],
            [-38.6370341622, -4.4778776564],
            [-38.6368967649, -4.4847260993],
            [-38.6248633387, -4.5035814171],
            [-38.6252341475, -4.5083812109],
            [-38.6196074434, -4.5186239385],
            [-38.6202526856, -4.5355514953],
            [-38.6374822927, -4.5504805067],
            [-38.6505480539, -4.5554656542],
            [-38.6448582771, -4.5714770701],
            [-38.6443795342, -4.5727227441],
            [-38.6328123287, -4.6050717257],
            [-38.6239494354, -4.6159721615],
            [-38.6101191937, -4.6486478981],
            [-38.6100759099, -4.6489324633],
            [-38.6104262708, -4.6566477719],
            [-38.6069545476, -4.6621564672],
            [-38.5927802029, -4.6694739693],
            [-38.5830012645, -4.6665064209],
            [-38.572974969, -4.6706503351],
            [-38.5801996835, -4.6708482172],
            [-38.5917177896, -4.6787623588],
            [-38.5974983775, -4.6841518126],
            [-38.6151344112, -4.6892008851],
            [-38.6236948242, -4.6979896826],
            [-38.6377237161, -4.6996354889],
            [-38.6382383863, -4.6950546035],
            [-38.6314755059, -4.6885143968],
            [-38.642505153, -4.6876903012],
            [-38.652896301, -4.6827160973],
            [-38.6650675886, -4.6603516872],
            [-38.6681969767, -4.6607022903],
            [-38.6707016209, -4.6666163746],
            [-38.6746228486, -4.6636644234],
            [-38.6759806196, -4.6570735613],
            [-38.6903688243, -4.6508540787],
            [-38.6867623692, -4.6446799588],
            [-38.6923441585, -4.64161977],
            [-38.6912292579, -4.6356009522],
            [-38.6944827018, -4.6274053909],
            [-38.6989040203, -4.6264732505],
            [-38.7008289931, -4.6125709166],
            [-38.7124428322, -4.6123670585],
            [-38.7218133691, -4.6205452101],
            [-38.735725474, -4.6180586994],
            [-38.7533080511, -4.6237746921],
            [-38.7634730749, -4.6220631488],
            [-38.7481790834, -4.5280395999],
            [-38.7634165393, -4.5260190952],
            [-38.7738342872, -4.5284386573],
            [-38.7762283694, -4.5177178427],
            [-38.7855345962, -4.5162623368],
            [-38.7909218018, -4.5104574012],
            [-38.8035882238, -4.5195648316],
            [-38.8114890675, -4.5157080954],
            [-38.8187245864, -4.5181648628],
            [-38.8278103675, -4.5152465313],
            [-38.8340317574, -4.4953640531],
            [-38.8339516459, -4.4746097547],
            [-38.8236925166, -4.4619530506],
            [-38.8170425371, -4.4424025379],
            [-38.8303990627, -4.4445575575],
            [-38.8430340914, -4.4548342699],
            [-38.8427106615, -4.3945106118],
            [-38.842677772, -4.3887300443],
            [-38.8415795389, -4.3615708439],
            [-38.8373750139, -4.3506703141],
            [-38.823280718, -4.3141344529],
            [-38.8180101548, -4.316771895],
            [-38.802885313, -4.3163367706],
            [-38.7890475801, -4.3219354793],
            [-38.7791850222, -4.3076025501],
            [-38.7645990003, -4.3052288569],
            [-38.759995032, -4.3076948889],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301257',
        name: 'Ararendá',
        description: 'Ararendá',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.6566878969, -4.708463615],
            [-40.6457134044, -4.7205942598],
            [-40.6413094869, -4.726979289],
            [-40.6482831831, -4.7336606623],
            [-40.6452511193, -4.7459393873],
            [-40.6373757028, -4.759978071],
            [-40.6336206763, -4.7840975471],
            [-40.6336440005, -4.788347378],
            [-40.6341183414, -4.7929474405],
            [-40.6474162416, -4.8065684095],
            [-40.6501306709, -4.8137713641],
            [-40.6595641836, -4.8309904599],
            [-40.6651977371, -4.8433506082],
            [-40.6765297694, -4.851369051],
            [-40.6966327438, -4.8626365783],
            [-40.7186169333, -4.8514752728],
            [-40.7211735064, -4.8476071881],
            [-40.7235624849, -4.8427748221],
            [-40.7621142409, -4.8662502795],
            [-40.7644274646, -4.8433265918],
            [-40.7806948284, -4.8350560791],
            [-40.7828390011, -4.8245331701],
            [-40.7951506218, -4.8165275257],
            [-40.7985622197, -4.8153902312],
            [-40.802097908, -4.8166588841],
            [-40.8094913447, -4.8167986101],
            [-40.8240701775, -4.8109876269],
            [-40.8327561475, -4.803208121],
            [-40.8422536208, -4.7855319385],
            [-40.8648672719, -4.7838249857],
            [-40.8640397221, -4.7878150791],
            [-40.8788615124, -4.78705221],
            [-40.8823367504, -4.7874000566],
            [-40.8832313035, -4.7811537029],
            [-40.8787196432, -4.7703433621],
            [-40.882257849, -4.7528302871],
            [-40.8760662136, -4.7461634758],
            [-40.8658736474, -4.7317404557],
            [-40.8712335549, -4.7251819631],
            [-40.8723047148, -4.7133389915],
            [-40.8634756415, -4.7151672781],
            [-40.858724653, -4.7119643726],
            [-40.8444651722, -4.7180641072],
            [-40.8331400192, -4.7177571124],
            [-40.8138381809, -4.7090937973],
            [-40.8078832136, -4.7047033523],
            [-40.7852215808, -4.7114148987],
            [-40.7811496542, -4.7079361111],
            [-40.7780165882, -4.7110039379],
            [-40.7621088959, -4.706217113],
            [-40.7523765428, -4.7111240627],
            [-40.7369041934, -4.7084548965],
            [-40.7316945571, -4.7141012842],
            [-40.6705221018, -4.7029342995],
            [-40.6611410839, -4.6866698966],
            [-40.6550834556, -4.689783756],
            [-40.6595881852, -4.7035256491],
            [-40.6566878969, -4.708463615],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301307',
        name: 'Araripe',
        description: 'Araripe',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.1538591258, -7.0712083039],
            [-40.1373551702, -7.1125605658],
            [-40.1012499671, -7.1269029289],
            [-40.0951308143, -7.1293329018],
            [-40.0720717583, -7.138488594],
            [-40.0500999003, -7.1472085596],
            [-39.9963329544, -7.1502105725],
            [-39.9808320972, -7.1429068941],
            [-39.9165825319, -7.0919044069],
            [-39.8973649922, -7.0968792479],
            [-39.8974971126, -7.0971448694],
            [-39.8948225048, -7.1224116961],
            [-39.8907525451, -7.1259490797],
            [-39.8869951228, -7.1406369075],
            [-39.8886941445, -7.1771232567],
            [-39.8889410406, -7.2130346142],
            [-39.8896556253, -7.3219521704],
            [-39.8897659426, -7.3394246161],
            [-39.904194857, -7.3379392335],
            [-39.9259799859, -7.3405947789],
            [-39.9982236819, -7.3731098338],
            [-40.0087295983, -7.3751638886],
            [-40.0870881011, -7.3826887554],
            [-40.1273875843, -7.3815107208],
            [-40.1504974713, -7.3735985397],
            [-40.1567664397, -7.3710528198],
            [-40.1953173737, -7.357744655],
            [-40.2203924951, -7.3408664197],
            [-40.2471657343, -7.3133149716],
            [-40.2471049944, -7.3053242866],
            [-40.2664504032, -7.2530233962],
            [-40.266386337, -7.2480570565],
            [-40.2585858668, -7.2368913066],
            [-40.2585126592, -7.2367976833],
            [-40.2488475325, -7.2238759637],
            [-40.2517845864, -7.2025416937],
            [-40.2669087025, -7.1855807077],
            [-40.2680531269, -7.1789915823],
            [-40.2608228752, -7.1423953891],
            [-40.2423783701, -7.1290976304],
            [-40.2400214581, -7.1120280986],
            [-40.2268062637, -7.1102162681],
            [-40.2253543661, -7.1045239637],
            [-40.2196656172, -7.1038566034],
            [-40.2149650718, -7.0977532187],
            [-40.2104472827, -7.0987887947],
            [-40.2063186954, -7.0942216951],
            [-40.182686891, -7.0853165772],
            [-40.1767039973, -7.0791331067],
            [-40.1744615051, -7.0767559258],
            [-40.1772802331, -7.0673255189],
            [-40.1680277365, -7.0589751366],
            [-40.1647394999, -7.0439285754],
            [-40.1538591258, -7.0712083039],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301406',
        name: 'Aratuba',
        description: 'Aratuba',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.0702173581, -4.3523861711],
            [-39.0632667966, -4.3583128713],
            [-39.060572598, -4.3567049057],
            [-39.057321894, -4.364757994],
            [-39.0501153913, -4.3584560461],
            [-39.0512759257, -4.3529695805],
            [-39.0370491418, -4.3599571849],
            [-39.0368689091, -4.3599134779],
            [-39.0251724714, -4.3610024832],
            [-39.0236470171, -4.3664672046],
            [-39.0235236555, -4.3666406133],
            [-39.0188803736, -4.3689926936],
            [-39.0161784083, -4.3758022366],
            [-39.0048998187, -4.3767083483],
            [-39.0016006101, -4.3797305082],
            [-38.9883552507, -4.377344118],
            [-38.9827162537, -4.3804315762],
            [-38.9770127695, -4.4211409274],
            [-38.9752778767, -4.4335086389],
            [-38.976289605, -4.4415845197],
            [-38.9838468831, -4.4484648837],
            [-38.9929617935, -4.4498461817],
            [-38.9978349334, -4.4674200331],
            [-38.9978635686, -4.4841323417],
            [-39.0063533198, -4.4824434484],
            [-39.0083207311, -4.4938865546],
            [-39.0151629345, -4.5044939155],
            [-39.0114652013, -4.5173785423],
            [-39.0221831521, -4.5131734304],
            [-39.0218680389, -4.5052843771],
            [-39.0276736937, -4.498358494],
            [-39.0263681709, -4.4913452282],
            [-39.0359545687, -4.4762397153],
            [-39.0307794834, -4.4634394621],
            [-39.0415206484, -4.4581453392],
            [-39.0476795552, -4.4470557319],
            [-39.0511987138, -4.4461373099],
            [-39.0505850485, -4.4358492848],
            [-39.0539539751, -4.4370165377],
            [-39.0768763573, -4.4254131939],
            [-39.0741745754, -4.4170508641],
            [-39.0784730019, -4.4170540991],
            [-39.0785887339, -4.4053794783],
            [-39.0897457203, -4.4128494463],
            [-39.0941608076, -4.3972070003],
            [-39.0989252208, -4.3934716666],
            [-39.0942744548, -4.3881027749],
            [-39.0957856341, -4.3815652399],
            [-39.0889293375, -4.3832000815],
            [-39.0855610937, -4.3939400137],
            [-39.0825373112, -4.3909053334],
            [-39.0675486982, -4.398493195],
            [-39.0655376159, -4.3959593756],
            [-39.0626677868, -4.3967434563],
            [-39.0618541679, -4.3919577278],
            [-39.066851237, -4.3885712986],
            [-39.065106338, -4.3790010072],
            [-39.0581320906, -4.3750323167],
            [-39.0727736606, -4.3662759267],
            [-39.0621992834, -4.361958347],
            [-39.0702173581, -4.3523861711],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301505',
        name: 'Arneiroz',
        description: 'Arneiroz',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.8895184919, -6.0981560565],
            [-39.8936181492, -6.1111066201],
            [-39.8912975502, -6.1139407617],
            [-39.8969741343, -6.1219399902],
            [-39.8918617221, -6.1294620052],
            [-39.8956228366, -6.1363167802],
            [-39.9008642865, -6.144415287],
            [-39.8935967072, -6.155483688],
            [-39.9183704391, -6.1818503487],
            [-39.9147954164, -6.1882217281],
            [-39.9165345687, -6.1904250751],
            [-39.9257144286, -6.1954354259],
            [-39.9395053639, -6.1936397086],
            [-39.9437115218, -6.1971055798],
            [-39.9476763816, -6.196083406],
            [-39.9865664648, -6.2203858055],
            [-40.0003849946, -6.2154786213],
            [-40.0078073796, -6.2195199609],
            [-40.0149623192, -6.2222850815],
            [-40.0209917497, -6.231121158],
            [-40.0188498443, -6.2398392367],
            [-40.024927105, -6.2541400923],
            [-40.0209786994, -6.2651027217],
            [-40.0247867759, -6.270680968],
            [-40.0284980432, -6.2794865673],
            [-40.0496556647, -6.2797878634],
            [-40.0482847108, -6.2862615159],
            [-40.0515656724, -6.2919068244],
            [-40.0471960927, -6.299890353],
            [-40.0512292582, -6.3017761941],
            [-40.0572388484, -6.2980115264],
            [-40.0633474534, -6.3120925061],
            [-40.0647767653, -6.3293518924],
            [-40.0711452353, -6.3409276164],
            [-40.0802206049, -6.345894954],
            [-40.0790944024, -6.3490520269],
            [-40.0727417956, -6.3488570572],
            [-40.0716278565, -6.3548606299],
            [-40.0772915839, -6.3542635487],
            [-40.0832708359, -6.3596038992],
            [-40.0847401575, -6.3682019186],
            [-40.107002697, -6.3511663748],
            [-40.1170688734, -6.3480766161],
            [-40.1326243335, -6.3497113194],
            [-40.1420617855, -6.3508719406],
            [-40.1481278701, -6.36011452],
            [-40.1571496539, -6.3533133859],
            [-40.1627420895, -6.3610338244],
            [-40.1726464349, -6.3603648739],
            [-40.186551085, -6.3649972459],
            [-40.2065797224, -6.3801586669],
            [-40.2179049198, -6.3757701896],
            [-40.2264024251, -6.386134655],
            [-40.2414715614, -6.3920593656],
            [-40.2451394548, -6.3868300998],
            [-40.2501284113, -6.3869799087],
            [-40.2497623005, -6.381156724],
            [-40.25665243, -6.3747565394],
            [-40.2607222506, -6.377685827],
            [-40.2574461723, -6.3820217709],
            [-40.2605131481, -6.3855522029],
            [-40.2748115192, -6.3868772494],
            [-40.2807052953, -6.3835718113],
            [-40.2849641178, -6.3798676382],
            [-40.2950840664, -6.3906022956],
            [-40.307788987, -6.3954779183],
            [-40.3084854419, -6.4023023236],
            [-40.3146356432, -6.397382794],
            [-40.3276181362, -6.4008997344],
            [-40.3278469297, -6.4010323297],
            [-40.4050064359, -6.3126661218],
            [-40.3009695266, -6.2059537104],
            [-40.3008641673, -6.2059387462],
            [-40.290073209, -6.2044138871],
            [-40.2869476803, -6.1921506715],
            [-40.2648169367, -6.1679405807],
            [-40.2602762194, -6.1667345295],
            [-40.2600286685, -6.1568017016],
            [-40.2549826788, -6.1480056007],
            [-40.2323769773, -6.1327319797],
            [-40.2257789227, -6.131446044],
            [-40.2204468813, -6.1352773328],
            [-40.2204182446, -6.1420635692],
            [-40.2122074658, -6.1355846574],
            [-40.2120673971, -6.1353771721],
            [-40.2049189119, -6.1333352056],
            [-40.1968656742, -6.1447667451],
            [-40.1798072029, -6.1402225044],
            [-40.1632732792, -6.1312707929],
            [-40.1613834533, -6.1259069171],
            [-40.1578596699, -6.1178879457],
            [-40.1416665092, -6.1136629783],
            [-40.1253540415, -6.1096864699],
            [-40.1234352212, -6.1139616941],
            [-40.1158466405, -6.1111451438],
            [-40.1079142652, -6.1164195202],
            [-40.0865861862, -6.1087783159],
            [-40.0839487665, -6.1140417298],
            [-40.0727634087, -6.1079070189],
            [-40.0660830392, -6.1104831266],
            [-40.0454168378, -6.0971292764],
            [-40.0401805909, -6.104195076],
            [-40.0311429455, -6.0985025749],
            [-40.0164111514, -6.096065072],
            [-40.0126256094, -6.1026287515],
            [-39.9862231164, -6.1023870777],
            [-39.9743369008, -6.099823808],
            [-39.9636161864, -6.1023149605],
            [-39.962579183, -6.0995737896],
            [-39.9566703492, -6.0992503178],
            [-39.9529219266, -6.0993820099],
            [-39.9473083788, -6.1043048411],
            [-39.9419146413, -6.1040143757],
            [-39.9354039689, -6.0965844925],
            [-39.9256733705, -6.0927284434],
            [-39.9177985757, -6.0821515376],
            [-39.9111271258, -6.0798241614],
            [-39.904891726, -6.0850658412],
            [-39.904099536, -6.0922559818],
            [-39.8895184919, -6.0981560565],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301604',
        name: 'Assaré',
        description: 'Assaré',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.8835530759, -6.8036817512],
            [-39.8716907697, -6.8176923507],
            [-39.8727921866, -6.8244746977],
            [-39.8810373046, -6.8322832192],
            [-39.7974271012, -6.8088366028],
            [-39.7894368151, -6.8065949796],
            [-39.7865585666, -6.8057886878],
            [-39.7744309923, -6.8023871355],
            [-39.7405460503, -6.8052327524],
            [-39.7403612039, -6.7990769038],
            [-39.7233888774, -6.7888986294],
            [-39.7217405714, -6.7849664941],
            [-39.7077818062, -6.7848186624],
            [-39.7064701845, -6.7912590458],
            [-39.6953084005, -6.7960087906],
            [-39.7000178873, -6.8002758686],
            [-39.6961849324, -6.8057643719],
            [-39.6906004644, -6.8302233199],
            [-39.6543713218, -6.8203437927],
            [-39.6529346211, -6.8256269156],
            [-39.6436177713, -6.8284206513],
            [-39.6367713737, -6.8389747718],
            [-39.6416086453, -6.8529321995],
            [-39.6680478165, -6.8629546121],
            [-39.6677854566, -6.8732283529],
            [-39.6622810207, -6.8820116843],
            [-39.6655513987, -6.8899015103],
            [-39.6629228353, -6.9070533594],
            [-39.6549060562, -6.8946513489],
            [-39.6507643555, -6.8931584882],
            [-39.6337175291, -6.893179944],
            [-39.6300313368, -6.9008914626],
            [-39.6118140584, -6.8923527983],
            [-39.5959882229, -6.8983662095],
            [-39.6002437979, -6.9031747639],
            [-39.5991142239, -6.9110238046],
            [-39.6017518914, -6.9145466247],
            [-39.611850598, -6.9213550191],
            [-39.6203109329, -6.9345072602],
            [-39.6246714429, -6.9368573978],
            [-39.6296315444, -6.9326763619],
            [-39.6349322689, -6.9351286343],
            [-39.639800652, -6.9459632481],
            [-39.6791226918, -6.9648391361],
            [-39.6834742579, -6.9643290602],
            [-39.6904287193, -6.9700492443],
            [-39.6930183763, -6.9688406584],
            [-39.690727417, -6.9613040888],
            [-39.6974293897, -6.9612278173],
            [-39.7038787532, -6.9536462959],
            [-39.7095715875, -6.9544948744],
            [-39.7132087949, -6.9550375233],
            [-39.7208968892, -6.9666872508],
            [-39.7326152341, -6.9702875604],
            [-39.7546142068, -6.986736207],
            [-39.7580446587, -6.992863417],
            [-39.7650418059, -6.9930063921],
            [-39.7678927325, -6.9932480134],
            [-39.7769266578, -7.0027817639],
            [-39.7979633748, -7.0104948221],
            [-39.7472082174, -7.0231291577],
            [-39.7610002822, -7.0365380829],
            [-39.7656560071, -7.0341325643],
            [-39.7680039172, -7.045139122],
            [-39.7643683722, -7.0483918526],
            [-39.7718133924, -7.0605176224],
            [-39.7828809345, -7.0607827604],
            [-39.7862437938, -7.0475861311],
            [-39.7899665078, -7.0421337989],
            [-39.7895349208, -7.0346565445],
            [-39.78356145, -7.0324070748],
            [-39.7889440351, -7.0298565208],
            [-39.8322131489, -7.0457301174],
            [-39.862654957, -7.0523133964],
            [-39.8721351557, -7.0663493237],
            [-39.8808964977, -7.0597930843],
            [-39.8890096875, -7.0528343023],
            [-39.899713255, -7.0682737647],
            [-39.9105225723, -7.0694572377],
            [-39.9159991049, -7.0746236169],
            [-39.9343827908, -7.0741065774],
            [-39.9440430969, -7.0779606936],
            [-39.9485410684, -7.0106810606],
            [-39.9659077275, -7.0018547661],
            [-39.9762372942, -7.0041254383],
            [-39.996842703, -7.0086545966],
            [-40.0573036253, -7.0055212979],
            [-40.0574487121, -7.0054841742],
            [-40.0763038705, -7.0045348218],
            [-40.082909574, -6.9830377879],
            [-40.0829487438, -6.9829097118],
            [-40.0748039534, -6.9812294662],
            [-40.0667319208, -6.9636125035],
            [-40.0670336353, -6.9559584784],
            [-40.0616527432, -6.9492300752],
            [-40.064619184, -6.943629662],
            [-40.0596557051, -6.9314876838],
            [-40.0610875684, -6.9283542099],
            [-40.0629060446, -6.9252466489],
            [-40.0565515483, -6.9182461203],
            [-40.0753422239, -6.9122876035],
            [-40.0798393202, -6.9154149551],
            [-40.1248595774, -6.887180061],
            [-40.114321684, -6.879078937],
            [-40.0915476265, -6.8602023073],
            [-40.0764529354, -6.8561938584],
            [-40.0507563304, -6.8432191815],
            [-40.0556956505, -6.8365407004],
            [-40.0549465995, -6.8290851347],
            [-40.0430538615, -6.8165854887],
            [-40.0221943591, -6.8134626688],
            [-40.0016948268, -6.8027287635],
            [-39.9865531576, -6.8146807071],
            [-39.9396174064, -6.7835259834],
            [-39.9139237046, -6.7669372994],
            [-39.9092554593, -6.7631287663],
            [-39.8986108835, -6.7809293594],
            [-39.8855854042, -6.7886093207],
            [-39.8835530759, -6.8036817512],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301703',
        name: 'Aurora',
        description: 'Aurora',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.8915514747, -6.8560450904],
            [-38.8677497734, -6.8606632052],
            [-38.8631176325, -6.8567200769],
            [-38.8560263357, -6.858905808],
            [-38.8193158493, -6.8563433237],
            [-38.8172196542, -6.8612518557],
            [-38.812856516, -6.8640443754],
            [-38.7859989455, -6.8630146222],
            [-38.7745883906, -6.8681739924],
            [-38.7757781177, -6.8844446178],
            [-38.7850817779, -6.8906213733],
            [-38.7841878914, -6.9057342763],
            [-38.764858111, -6.9105122381],
            [-38.7585725975, -6.9162245649],
            [-38.7584125172, -6.924876715],
            [-38.7620547447, -6.9261423612],
            [-38.7571367297, -6.9342153292],
            [-38.7553627592, -6.9397268417],
            [-38.7649949009, -6.9475355728],
            [-38.7569468511, -6.9577745855],
            [-38.7466110266, -6.9601778858],
            [-38.7477222617, -6.9663024057],
            [-38.7425237967, -6.9746011517],
            [-38.7515337414, -6.9881803088],
            [-38.7606102204, -6.9896518288],
            [-38.7646443757, -6.9934926227],
            [-38.7814998988, -7.001082208],
            [-38.8035150053, -6.9903311271],
            [-38.8255322723, -6.9890980304],
            [-38.8312592799, -6.9833073811],
            [-38.855833103, -6.9779710705],
            [-38.8638702531, -6.9941883059],
            [-38.8837395807, -7.0342870153],
            [-38.8869621439, -7.040505798],
            [-38.8981140159, -7.062041446],
            [-38.9086180674, -7.0823010036],
            [-38.9583079897, -7.0823101848],
            [-38.9607240874, -7.0846646512],
            [-38.9611820334, -7.0851111382],
            [-39.0248349137, -7.1509201371],
            [-39.0309979961, -7.152113255],
            [-39.0326704822, -7.149027704],
            [-39.039389886, -7.1487460891],
            [-39.0418377632, -7.1427678792],
            [-39.045352207, -7.1426024616],
            [-39.0512529868, -7.1384659204],
            [-39.0688849862, -7.1473767275],
            [-39.0722453961, -7.1434742574],
            [-39.0789185337, -7.1500981213],
            [-39.079694433, -7.1554012589],
            [-39.0906726631, -7.1561917878],
            [-39.0919190496, -7.16145656],
            [-39.0969435178, -7.1544975109],
            [-39.1135502323, -7.1574501004],
            [-39.1196729947, -7.1441906856],
            [-39.1283755572, -7.1438355526],
            [-39.1350498875, -7.1376159907],
            [-39.1350134448, -7.1322962793],
            [-39.1323258428, -7.1195794755],
            [-39.1203032628, -7.1116908024],
            [-39.116517472, -7.1028268704],
            [-39.1114751771, -7.0851957353],
            [-39.1243351498, -7.0804474539],
            [-39.1354452414, -7.0718446771],
            [-39.1432726819, -7.0600219731],
            [-39.157459938, -7.0285426164],
            [-39.1574685689, -7.0284719617],
            [-39.1548335428, -7.0165442861],
            [-39.1578628372, -7.0113014374],
            [-39.1619260512, -7.0054123497],
            [-39.1614237252, -6.9984555889],
            [-39.1553780503, -6.9946962229],
            [-39.1488299121, -6.9832198162],
            [-39.1302777064, -6.9809740388],
            [-39.116967714, -6.966029438],
            [-39.1081942841, -6.9626930733],
            [-39.1021197121, -6.9609060902],
            [-39.1005517744, -6.9533454387],
            [-39.0927493039, -6.943592571],
            [-39.0888048012, -6.9431670982],
            [-39.0876123518, -6.9360253992],
            [-39.0903267167, -6.9358514439],
            [-39.086230052, -6.9320517096],
            [-39.0715031141, -6.9312338707],
            [-39.0743312665, -6.9256353384],
            [-39.0671116681, -6.9207267327],
            [-39.0715578193, -6.9181284862],
            [-39.0447453684, -6.9080529067],
            [-39.0430779003, -6.9007512714],
            [-39.0335474511, -6.898123118],
            [-39.0218063028, -6.8950481612],
            [-39.0145271154, -6.877309244],
            [-38.9932084997, -6.8693438846],
            [-38.9790501406, -6.8677924605],
            [-38.9808196642, -6.8613057202],
            [-38.9715435799, -6.8625933387],
            [-38.9688497361, -6.8600535154],
            [-38.9507201752, -6.8671360775],
            [-38.9378026453, -6.8620782044],
            [-38.9141723167, -6.8603964788],
            [-38.9065561217, -6.8589317743],
            [-38.8915514747, -6.8560450904],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301802',
        name: 'Baixio',
        description: 'Baixio',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.8020898252, -6.6585280741],
            [-38.7942478111, -6.664990505],
            [-38.7852798192, -6.6641999325],
            [-38.7598074884, -6.6762715303],
            [-38.7524665165, -6.6742728089],
            [-38.7365071938, -6.6793465464],
            [-38.7016349469, -6.6978831357],
            [-38.6868867051, -6.704073258],
            [-38.6762805817, -6.7030493877],
            [-38.67251926, -6.7063204421],
            [-38.6659655383, -6.7194085832],
            [-38.6556227441, -6.7298433197],
            [-38.6571802989, -6.7403595525],
            [-38.6496185138, -6.751835672],
            [-38.6520495606, -6.7566513351],
            [-38.6692538566, -6.7589454905],
            [-38.680829329, -6.7544938898],
            [-38.6857540253, -6.766661165],
            [-38.6994591664, -6.7646819893],
            [-38.6996385907, -6.7618372553],
            [-38.7199747422, -6.768203377],
            [-38.7296046978, -6.7591504742],
            [-38.7493538072, -6.7555623268],
            [-38.7602682444, -6.7438811882],
            [-38.7605554906, -6.7369626662],
            [-38.7741058798, -6.7272173286],
            [-38.7738014544, -6.7179111145],
            [-38.7790536506, -6.7207242082],
            [-38.789945917, -6.7187987622],
            [-38.796831189, -6.7239860748],
            [-38.8029566911, -6.7236148515],
            [-38.8139074058, -6.7196773346],
            [-38.8371652003, -6.7093213706],
            [-38.8473431066, -6.7097338296],
            [-38.8519687403, -6.7078513866],
            [-38.8531670183, -6.6940334041],
            [-38.8588922186, -6.6925662467],
            [-38.859223834, -6.6884754157],
            [-38.8529798402, -6.6740355119],
            [-38.8438165036, -6.6664743732],
            [-38.8385292369, -6.6635529844],
            [-38.8343913586, -6.6653718719],
            [-38.8317351841, -6.6600659148],
            [-38.8020898252, -6.6585280741],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301851',
        name: 'Banabuiú',
        description: 'Banabuiú',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.7442993642, -5.09476807],
            [-38.726062538, -5.0949503095],
            [-38.7165141678, -5.091799831],
            [-38.6969425916, -5.0945406087],
            [-38.6650170072, -5.1163877978],
            [-38.6505675998, -5.1213334236],
            [-38.6433815664, -5.1192834684],
            [-38.6373557785, -5.1214762493],
            [-38.6299109567, -5.1169500661],
            [-38.618059763, -5.1185265061],
            [-38.6158092411, -5.1232301949],
            [-38.6175572417, -5.1317479397],
            [-38.6105803529, -5.1436266472],
            [-38.6137341506, -5.1513337863],
            [-38.6141048338, -5.1517781224],
            [-38.6775118502, -5.227805424],
            [-38.7034769618, -5.2226324631],
            [-38.7081657062, -5.2010076498],
            [-38.7185526846, -5.200491814],
            [-38.7394228507, -5.197640271],
            [-38.7680897073, -5.2017721603],
            [-38.8111166048, -5.2236867573],
            [-38.8470276618, -5.2849328977],
            [-38.8651750103, -5.2936318055],
            [-38.8684137924, -5.2968568764],
            [-38.8783407085, -5.3100280854],
            [-38.897891537, -5.3087332594],
            [-38.9158337783, -5.3202153286],
            [-38.9214344016, -5.329261642],
            [-38.9243874533, -5.3793716256],
            [-38.9210904132, -5.3999020029],
            [-38.9258413086, -5.4111816506],
            [-38.9221432388, -5.4117231491],
            [-38.9199992141, -5.419105271],
            [-38.92294822, -5.4337326061],
            [-38.916445146, -5.4320892036],
            [-38.9109503796, -5.4374970345],
            [-38.9068129826, -5.4517049021],
            [-38.9110611364, -5.4554022101],
            [-38.9244837428, -5.4579057195],
            [-38.9279482882, -5.4837027575],
            [-38.9348215798, -5.4851397727],
            [-38.9351988549, -5.4850011834],
            [-38.9448220487, -5.4910015825],
            [-38.958975324, -5.5132483225],
            [-38.9773097935, -5.521361849],
            [-38.981385858, -5.5147979192],
            [-38.9875260104, -5.5156186108],
            [-38.9897767648, -5.5120630681],
            [-38.9928114253, -5.5150636114],
            [-39.0034744585, -5.5040236501],
            [-39.0092901123, -5.5110910144],
            [-39.0306922142, -5.4858014134],
            [-39.0358607744, -5.5022931828],
            [-39.0413605407, -5.5034799467],
            [-39.0460829315, -5.5109913385],
            [-39.059934928, -5.5106943214],
            [-39.0627496199, -5.4972208494],
            [-39.0709681051, -5.4859003829],
            [-39.0683933036, -5.4731894384],
            [-39.0603708366, -5.4602804175],
            [-39.0558114264, -5.4189722995],
            [-39.0740291616, -5.4040756506],
            [-39.0847138074, -5.4032797697],
            [-39.0824168111, -5.3928428582],
            [-39.0749150353, -5.3682131124],
            [-39.0544424956, -5.3010421387],
            [-39.0534737984, -5.2978657142],
            [-39.053350183, -5.2974612773],
            [-39.0340844821, -5.2700859679],
            [-39.0398373586, -5.2492347053],
            [-39.031822369, -5.2261551953],
            [-39.0275645409, -5.2224723109],
            [-39.0370801083, -5.2221353667],
            [-39.0469837825, -5.2272257081],
            [-39.0521013282, -5.2245197644],
            [-39.052777698, -5.2241617967],
            [-39.0593388786, -5.2187689981],
            [-39.0863675849, -5.1686206201],
            [-39.0850761731, -5.1576492965],
            [-39.0840978202, -5.1515016969],
            [-39.0693621411, -5.154541839],
            [-39.0588703935, -5.1497946289],
            [-39.0504416668, -5.1570268098],
            [-39.0457328107, -5.157116518],
            [-39.0288813569, -5.1637551834],
            [-39.028580481, -5.1637736887],
            [-39.0218701627, -5.1625237576],
            [-39.0149264323, -5.1680244465],
            [-39.0093335353, -5.1611968655],
            [-39.0000028609, -5.1638692066],
            [-38.9773949103, -5.1443393394],
            [-38.9720318232, -5.1430102663],
            [-38.9662630962, -5.1479804573],
            [-38.9557330816, -5.1531860454],
            [-38.9389208076, -5.1540124919],
            [-38.9224617171, -5.1604613188],
            [-38.9013000253, -5.1644778254],
            [-38.8117525733, -5.0651633588],
            [-38.7918267814, -5.0784289572],
            [-38.7625015451, -5.0789744014],
            [-38.7442993642, -5.09476807],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301901',
        name: 'Barbalha',
        description: 'Barbalha',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.2356980973, -7.2269822531],
            [-39.2358448743, -7.2793999193],
            [-39.2358452659, -7.2796863485],
            [-39.2276670982, -7.2866504132],
            [-39.2315446322, -7.30167085],
            [-39.2325157041, -7.3054328816],
            [-39.2434535927, -7.3485064436],
            [-39.2560745931, -7.3967372237],
            [-39.2560689064, -7.4242906013],
            [-39.2560444623, -7.4866061851],
            [-39.3340086298, -7.4675626007],
            [-39.3341924449, -7.4675150604],
            [-39.368555508, -7.4591155238],
            [-39.3683361378, -7.4924502926],
            [-39.3683712146, -7.4932559831],
            [-39.3679177228, -7.5688864322],
            [-39.3676197568, -7.6235039531],
            [-39.3723345785, -7.6137612892],
            [-39.3836116642, -7.6081644942],
            [-39.3836949668, -7.6081155009],
            [-39.4192953001, -7.6040057551],
            [-39.4229119548, -7.5863994193],
            [-39.4308236565, -7.5776161025],
            [-39.43457254, -7.5733165395],
            [-39.4468580322, -7.5718856784],
            [-39.4479198537, -7.4568122632],
            [-39.4479384486, -7.45470261],
            [-39.4488090633, -7.3549376985],
            [-39.448907285, -7.3433421341],
            [-39.3912155019, -7.3022990058],
            [-39.3889184057, -7.300665107],
            [-39.3747659438, -7.2905981524],
            [-39.3502810192, -7.2731836324],
            [-39.3495365762, -7.2726546843],
            [-39.3490381409, -7.275775389],
            [-39.343045117, -7.2731953096],
            [-39.3315942724, -7.2682650149],
            [-39.3143945878, -7.2608600179],
            [-39.2895268443, -7.2501540458],
            [-39.2894098226, -7.2501039454],
            [-39.2731586252, -7.2431073709],
            [-39.2722930941, -7.242735087],
            [-39.261312932, -7.2380082634],
            [-39.2356980973, -7.2269822531],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301950',
        name: 'Barreira',
        description: 'Barreira',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5466672729, -4.3704744493],
            [-38.5523902042, -4.3751554909],
            [-38.5668153798, -4.3769073508],
            [-38.5779237837, -4.3851343278],
            [-38.5958163521, -4.3832064386],
            [-38.6010611306, -4.3853430552],
            [-38.6024282486, -4.3818121202],
            [-38.6110957787, -4.3795890302],
            [-38.6136684943, -4.3891374862],
            [-38.6244739888, -4.4054444823],
            [-38.639673244, -4.4077359839],
            [-38.6466780151, -4.4164272457],
            [-38.6538116973, -4.4178787254],
            [-38.6564603097, -4.4140190055],
            [-38.6639409141, -4.417743259],
            [-38.6678392353, -4.4150050269],
            [-38.6773215281, -4.400852023],
            [-38.6802617338, -4.3901292325],
            [-38.6913725053, -4.376985437],
            [-38.6936325085, -4.3798430323],
            [-38.6951763694, -4.3683405636],
            [-38.6970413362, -4.3544382735],
            [-38.7002712271, -4.3303445629],
            [-38.6836046488, -4.280159002],
            [-38.6838870334, -4.2777490325],
            [-38.6627218529, -4.2696985523],
            [-38.6199001396, -4.253414696],
            [-38.577925618, -4.2374566773],
            [-38.5705033138, -4.2346349704],
            [-38.5620921806, -4.2533675664],
            [-38.5605735237, -4.2567506919],
            [-38.5526977817, -4.266424215],
            [-38.5502243903, -4.2732078888],
            [-38.553991017, -4.28398195],
            [-38.5518927561, -4.297761731],
            [-38.5510434382, -4.3033349962],
            [-38.5481272083, -4.3224726285],
            [-38.5393857556, -4.350102863],
            [-38.5338873917, -4.3670590751],
            [-38.5335318724, -4.3681559358],
            [-38.5342811547, -4.3701946065],
            [-38.5466672729, -4.3704744493],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2302008',
        name: 'Barro',
        description: 'Barro',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.8035150053, -6.9903311271],
            [-38.7814998988, -7.001082208],
            [-38.7646443757, -6.9934926227],
            [-38.7583310335, -6.9980586714],
            [-38.7506372952, -6.9969088792],
            [-38.7302292797, -7.0116253432],
            [-38.716759624, -7.0165388628],
            [-38.7131627703, -7.0176805715],
            [-38.6910870418, -7.0287368839],
            [-38.6894473188, -7.0398266207],
            [-38.6726617473, -7.0458276162],
            [-38.6694951117, -7.047301726],
            [-38.6768880239, -7.0666994361],
            [-38.6710975265, -7.0701309489],
            [-38.6663338805, -7.0812790227],
            [-38.6594469319, -7.0851406167],
            [-38.6568002872, -7.0938627614],
            [-38.6618004828, -7.1097756784],
            [-38.6681895304, -7.1169189229],
            [-38.6770612365, -7.1305316764],
            [-38.6745810411, -7.1434054064],
            [-38.6749389693, -7.1553181581],
            [-38.6848396151, -7.1609402278],
            [-38.6763645614, -7.1671683754],
            [-38.6767757429, -7.1705324657],
            [-38.6826614311, -7.1755767386],
            [-38.6874758066, -7.1897572046],
            [-38.6730580542, -7.1848879984],
            [-38.643849825, -7.18335548],
            [-38.6361972652, -7.1933509981],
            [-38.6244491231, -7.1909174602],
            [-38.6194411361, -7.1966515529],
            [-38.6226976176, -7.2072660462],
            [-38.6190629747, -7.2203175212],
            [-38.6192766003, -7.2203489329],
            [-38.6338986437, -7.2224757168],
            [-38.649371554, -7.216021793],
            [-38.6561857669, -7.2228830092],
            [-38.6627988288, -7.2219514697],
            [-38.665497619, -7.2267834063],
            [-38.6722316614, -7.2293568593],
            [-38.7060829899, -7.2330507292],
            [-38.7184891331, -7.2345872722],
            [-38.7414025985, -7.2374253469],
            [-38.7626949369, -7.2345550137],
            [-38.7698883886, -7.2370637137],
            [-38.7749744666, -7.2361432594],
            [-38.7843190021, -7.2238402548],
            [-38.7970130781, -7.2183867226],
            [-38.8005822934, -7.226925776],
            [-38.8220767062, -7.2260894881],
            [-38.8415036, -7.239478576],
            [-38.8511370806, -7.2393950889],
            [-38.8576090246, -7.2353183897],
            [-38.860384839, -7.2406850358],
            [-38.8640471193, -7.2398006301],
            [-38.8686825167, -7.2447550238],
            [-38.8792274264, -7.2363818164],
            [-38.8986903683, -7.2420029409],
            [-38.9043764881, -7.2372322765],
            [-38.9077939597, -7.2397059976],
            [-38.913643317, -7.2415343798],
            [-38.9171612502, -7.230788768],
            [-38.9206022843, -7.2202861647],
            [-38.9470972741, -7.1444856345],
            [-38.9501028292, -7.1372226388],
            [-38.9260504402, -7.1155191018],
            [-38.9086180674, -7.0823010036],
            [-38.8981140159, -7.062041446],
            [-38.8869621439, -7.040505798],
            [-38.8837395807, -7.0342870153],
            [-38.8638702531, -6.9941883059],
            [-38.855833103, -6.9779710705],
            [-38.8312592799, -6.9833073811],
            [-38.8255322723, -6.9890980304],
            [-38.8035150053, -6.9903311271],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2302057',
        name: 'Barroquinha',
        description: 'Barroquinha',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-41.2330037896, -2.8894163716],
            [-41.1907834909, -2.8908578894],
            [-41.1734507992, -2.8873198915],
            [-41.11936162, -2.8925180364],
            [-41.1013416648, -2.900578759],
            [-41.0847195448, -2.9096838307],
            [-41.0765002466, -2.9279857678],
            [-41.0669804483, -2.9317609589],
            [-41.0662574645, -2.9398921824],
            [-41.0761676299, -2.9440533218],
            [-41.0647761003, -2.9622149822],
            [-41.0697085704, -2.9648702168],
            [-41.0749298077, -2.9763440525],
            [-41.0765607129, -2.9946644489],
            [-41.071871922, -2.9967091137],
            [-41.0786527852, -3.0045675321],
            [-41.0775452223, -3.0092401536],
            [-41.0839066351, -3.0127454779],
            [-41.0831850416, -3.0207738975],
            [-41.1003946665, -3.0296095258],
            [-41.1005653889, -3.030224483],
            [-41.0996415678, -3.0433381819],
            [-41.1038804244, -3.0513838567],
            [-41.1182678027, -3.0630050065],
            [-41.1187984388, -3.0637583349],
            [-41.1202303019, -3.0657880036],
            [-41.1114277505, -3.0801208579],
            [-41.1107027835, -3.0807693585],
            [-41.1018786073, -3.0942091901],
            [-41.1338031268, -3.1141562968],
            [-41.1507495718, -3.1247460725],
            [-41.158337765, -3.1220467125],
            [-41.1690972066, -3.0989276768],
            [-41.1706055111, -3.082093699],
            [-41.1572450369, -3.0705904408],
            [-41.1680415742, -3.0533647398],
            [-41.1798159715, -3.0471768125],
            [-41.1801761395, -3.0410874212],
            [-41.1892733415, -3.0441515243],
            [-41.185576042, -3.0215087016],
            [-41.1889210657, -3.0202045689],
            [-41.1939658027, -2.9977894487],
            [-41.226776386, -2.9819098246],
            [-41.247014565, -2.9721125602],
            [-41.2720570562, -2.9703218682],
            [-41.283137374, -2.9672785139],
            [-41.2980695776, -2.9680319336],
            [-41.316671058, -2.9600102551],
            [-41.3225831106, -2.9511082687],
            [-41.3211906789, -2.9350469786],
            [-41.322307069, -2.9209502668],
            [-41.2800513351, -2.8960191864],
            [-41.2731728808, -2.8892644277],
            [-41.2587545713, -2.8839557507],
            [-41.2330037896, -2.8894163716],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2302107',
        name: 'Baturité',
        description: 'Baturité',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.8068725414, -4.2506101108],
            [-38.8059037404, -4.2593368119],
            [-38.8024718339, -4.2580436724],
            [-38.8040775912, -4.2667722705],
            [-38.8011813691, -4.2719433955],
            [-38.7961405836, -4.270325674],
            [-38.7948552965, -4.2638619528],
            [-38.7825183865, -4.2715069079],
            [-38.7801717349, -4.277824555],
            [-38.796028299, -4.2904744375],
            [-38.7957453073, -4.2942153978],
            [-38.7672617298, -4.3042879989],
            [-38.7645990003, -4.3052288569],
            [-38.7791850222, -4.3076025501],
            [-38.7890475801, -4.3219354793],
            [-38.802885313, -4.3163367706],
            [-38.8180101548, -4.316771895],
            [-38.823280718, -4.3141344529],
            [-38.8373750139, -4.3506703141],
            [-38.8415795389, -4.3615708439],
            [-38.842677772, -4.3887300443],
            [-38.8427106615, -4.3945106118],
            [-38.8430340914, -4.4548342699],
            [-38.8303990627, -4.4445575575],
            [-38.8170425371, -4.4424025379],
            [-38.8236925166, -4.4619530506],
            [-38.8339516459, -4.4746097547],
            [-38.8340317574, -4.4953640531],
            [-38.8278103675, -4.5152465313],
            [-38.8187245864, -4.5181648628],
            [-38.8114890675, -4.5157080954],
            [-38.8035882238, -4.5195648316],
            [-38.7909218018, -4.5104574012],
            [-38.7855345962, -4.5162623368],
            [-38.7762283694, -4.5177178427],
            [-38.7738342872, -4.5284386573],
            [-38.7634165393, -4.5260190952],
            [-38.7481790834, -4.5280395999],
            [-38.7634730749, -4.6220631488],
            [-38.7676275547, -4.6234336724],
            [-38.8005994418, -4.5836227195],
            [-38.8019127842, -4.5820367499],
            [-38.8281297227, -4.515359597],
            [-38.8281744792, -4.5152469009],
            [-38.8573324066, -4.4406149079],
            [-38.8694905965, -4.4300421065],
            [-38.876312867, -4.4299367047],
            [-38.8785621031, -4.4321951182],
            [-38.8832972601, -4.4317433756],
            [-38.8958816449, -4.4241252313],
            [-38.9097572452, -4.4156590423],
            [-38.9124281971, -4.4089866344],
            [-38.9230987403, -4.4114288609],
            [-38.9352286879, -4.3980968492],
            [-38.94437801, -4.3876803371],
            [-38.9210958857, -4.357059222],
            [-38.9276535959, -4.3542883918],
            [-38.9378645231, -4.3551322944],
            [-38.9479492692, -4.3506588636],
            [-38.9509673476, -4.3568906979],
            [-38.9599338289, -4.358537538],
            [-38.9572082091, -4.353121627],
            [-38.9485500761, -4.3391201712],
            [-38.9465735042, -4.323465556],
            [-38.9333843624, -4.3237225027],
            [-38.9330707989, -4.3231429738],
            [-38.9177932685, -4.2948763554],
            [-38.916365618, -4.2915805459],
            [-38.9167103063, -4.2794231394],
            [-38.9187368093, -4.2763068826],
            [-38.9248138315, -4.2759496643],
            [-38.9202859372, -4.2729550703],
            [-38.9179021551, -4.264689917],
            [-38.908841912, -4.2628927608],
            [-38.9026431689, -4.2684016047],
            [-38.8973977241, -4.2684022907],
            [-38.8953197436, -4.2771498571],
            [-38.8919136042, -4.2779807908],
            [-38.8889342748, -4.2734304068],
            [-38.8923916475, -4.2633702484],
            [-38.8900791147, -4.2607879975],
            [-38.8782063287, -4.2631291847],
            [-38.8737964094, -4.2579778087],
            [-38.8757054964, -4.2545058695],
            [-38.8721275717, -4.2434946928],
            [-38.8631904669, -4.2394143144],
            [-38.8623577519, -4.2325887385],
            [-38.8561590368, -4.2304317146],
            [-38.8543727208, -4.2251605491],
            [-38.8493868455, -4.2297504129],
            [-38.8486606102, -4.230933032],
            [-38.8410880242, -4.2326247923],
            [-38.8432334834, -4.2384417324],
            [-38.8402603327, -4.2457152957],
            [-38.8310034153, -4.2506145367],
            [-38.8068725414, -4.2506101108],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2302206',
        name: 'Beberibe',
        description: 'Beberibe',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.117935236, -4.1452878373],
            [-38.0461025468, -4.2149642943],
            [-38.0417268157, -4.2195245892],
            [-38.0276269237, -4.2305738615],
            [-38.0119195014, -4.2471758508],
            [-38.0039077704, -4.2525193626],
            [-37.9891987487, -4.2606695179],
            [-37.9846127001, -4.2665680358],
            [-37.9561063342, -4.2962916842],
            [-37.9434044507, -4.3057745902],
            [-37.9162596047, -4.330340585],
            [-37.8875672009, -4.3534597888],
            [-37.8622773195, -4.3721524522],
            [-37.8496749579, -4.3795325441],
            [-37.835311246, -4.3821323267],
            [-37.8348645702, -4.3876736744],
            [-37.8215627613, -4.3946419603],
            [-37.8193902043, -4.4019986551],
            [-37.8256689237, -4.405357975],
            [-37.8353391487, -4.4017476876],
            [-37.8395686048, -4.404378789],
            [-37.8384278236, -4.4083990443],
            [-37.846029378, -4.4068461275],
            [-37.8465166742, -4.4020552235],
            [-37.8705050892, -4.4012232198],
            [-37.8737772425, -4.4010991941],
            [-37.8732526322, -4.4108848025],
            [-37.8805578096, -4.4128271848],
            [-37.8858972841, -4.4133155244],
            [-37.8840575673, -4.4068567854],
            [-37.8916175395, -4.4081178281],
            [-37.8930882799, -4.4121519061],
            [-37.8967796383, -4.4073745475],
            [-37.9042497039, -4.414458526],
            [-37.9089210845, -4.4000188844],
            [-37.9215956592, -4.4044065451],
            [-37.930420041, -4.3976010841],
            [-37.9413844005, -4.3986668703],
            [-37.9493361288, -4.3983010322],
            [-37.9482517203, -4.4104815219],
            [-37.9511973043, -4.416670953],
            [-37.9721101308, -4.4294962499],
            [-37.9678509778, -4.4632232913],
            [-37.9631224556, -4.4728306039],
            [-37.9567812585, -4.4678627797],
            [-37.9543729928, -4.4824290799],
            [-37.9600186396, -4.4901513345],
            [-37.9589443138, -4.4941306638],
            [-37.9644699596, -4.4990358966],
            [-37.9603717876, -4.5072970073],
            [-37.9658374608, -4.509411626],
            [-37.9699440455, -4.5163666799],
            [-37.9632619629, -4.5294539247],
            [-37.9644306264, -4.5328978944],
            [-37.9758302129, -4.5359877948],
            [-37.9805715414, -4.5411535848],
            [-37.9846446672, -4.5605894513],
            [-37.9928900544, -4.5628653062],
            [-37.9852703051, -4.5827017486],
            [-37.9863863847, -4.5835240896],
            [-37.9975907361, -4.5952406721],
            [-38.0003938241, -4.5928897496],
            [-38.0058435835, -4.5964846251],
            [-38.012736707, -4.5921071535],
            [-38.0208172803, -4.593119074],
            [-38.0256151221, -4.5984231546],
            [-38.0413360979, -4.5893236115],
            [-38.0547207798, -4.591513527],
            [-38.0552497815, -4.5920334614],
            [-38.076001768, -4.592202998],
            [-38.0842526054, -4.5885039104],
            [-38.0934043842, -4.5921624355],
            [-38.0955052607, -4.5928503305],
            [-38.1051193725, -4.5959990393],
            [-38.1104212189, -4.6046925201],
            [-38.105507064, -4.6122699195],
            [-38.1074766913, -4.6152738307],
            [-38.1258890667, -4.6177439718],
            [-38.1507214513, -4.6138454559],
            [-38.1836445919, -4.6220994777],
            [-38.1984197779, -4.6055785162],
            [-38.2092025319, -4.5945278731],
            [-38.2154438314, -4.5892110503],
            [-38.2180036641, -4.5872159407],
            [-38.2293405826, -4.5813603343],
            [-38.235140461, -4.5734717079],
            [-38.2815778923, -4.5485102259],
            [-38.2957009762, -4.5416083166],
            [-38.3220584993, -4.5186732821],
            [-38.3464711277, -4.5130156208],
            [-38.3602829515, -4.5004912046],
            [-38.3521687988, -4.4931117274],
            [-38.3364745661, -4.4895867457],
            [-38.3412248016, -4.4805184583],
            [-38.3394937114, -4.4715733669],
            [-38.334419784, -4.4685478519],
            [-38.3377774261, -4.4581950405],
            [-38.3315924469, -4.453371851],
            [-38.3283126309, -4.4513488383],
            [-38.3305597966, -4.4475857274],
            [-38.3278182324, -4.4374697905],
            [-38.3209163814, -4.4350328618],
            [-38.3082517103, -4.4443640762],
            [-38.3039540312, -4.4441066959],
            [-38.2945041853, -4.4392348155],
            [-38.2898356347, -4.4300120634],
            [-38.2943684952, -4.4229279157],
            [-38.2926501035, -4.419037982],
            [-38.2818620822, -4.4165362529],
            [-38.2790546843, -4.4084402959],
            [-38.2702004988, -4.4057771715],
            [-38.2693342141, -4.3991760492],
            [-38.2622418281, -4.3924038529],
            [-38.2614167357, -4.3805463836],
            [-38.2555633043, -4.3748137482],
            [-38.2564482129, -4.3692963032],
            [-38.2515628764, -4.3659865423],
            [-38.2493751679, -4.3569060536],
            [-38.2383557437, -4.2784491016],
            [-38.2277801578, -4.2057027432],
            [-38.227605286, -4.205620027],
            [-38.2055029086, -4.1951153217],
            [-38.1922269124, -4.1945687252],
            [-38.1895018515, -4.1969647047],
            [-38.1825851396, -4.1946766973],
            [-38.1745314926, -4.1771193364],
            [-38.1705060576, -4.1745057937],
            [-38.1717572064, -4.1665681623],
            [-38.1624444588, -4.1484656476],
            [-38.1617578823, -4.1202406488],
            [-38.1565442833, -4.1101851081],
            [-38.1527769575, -4.1060125096],
            [-38.1483091687, -4.1020435405],
            [-38.1471983068, -4.1012513542],
            [-38.117935236, -4.1452878373],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2302305',
        name: 'Bela Cruz',
        description: 'Bela Cruz',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.2654492531, -2.9393855521],
            [-40.2375282997, -2.9515054584],
            [-40.2033158694, -2.9447103616],
            [-40.1927190097, -2.9435663647],
            [-40.1862253076, -2.9437637728],
            [-40.1703483132, -2.9426709483],
            [-40.1750017798, -2.959590917],
            [-40.1733363028, -2.9650257513],
            [-40.162724592, -2.9704399263],
            [-40.168846263, -2.9729909428],
            [-40.1689767805, -2.9767375883],
            [-40.1602582788, -2.9776324862],
            [-40.1603956411, -2.9809748975],
            [-40.1669470133, -2.9849566957],
            [-40.1681784975, -2.9918687755],
            [-40.1645888755, -2.9935740654],
            [-40.1608356139, -2.989924364],
            [-40.1567011079, -2.9937888033],
            [-40.1579187353, -3.020349298],
            [-40.1543138128, -3.0335660575],
            [-40.1473083956, -3.041730661],
            [-40.139330762, -3.0378957221],
            [-40.1216811165, -3.0346137603],
            [-40.1133197356, -3.0511691128],
            [-40.0886963005, -3.0729756124],
            [-40.0828908348, -3.0828260255],
            [-40.0834235016, -3.1158451385],
            [-40.0835789372, -3.1157268],
            [-40.1205477205, -3.0873988777],
            [-40.1398403189, -3.0726097591],
            [-40.1406769747, -3.0787636141],
            [-40.1462564775, -3.0785813488],
            [-40.1525563843, -3.0894687035],
            [-40.1522821189, -3.0923849874],
            [-40.1477006535, -3.1068558282],
            [-40.150760559, -3.1085468483],
            [-40.1615607414, -3.116923667],
            [-40.1868619058, -3.1320176362],
            [-40.1909746306, -3.1393461178],
            [-40.1954459267, -3.1409197121],
            [-40.211002516, -3.1540633557],
            [-40.2204425149, -3.1590299561],
            [-40.228000759, -3.1587357849],
            [-40.2318610587, -3.1630600484],
            [-40.2373482291, -3.1679074217],
            [-40.2563162586, -3.1618584818],
            [-40.2609226953, -3.153146847],
            [-40.2624270462, -3.1389879448],
            [-40.2975365716, -3.2035601646],
            [-40.3155815174, -3.1928613366],
            [-40.3217508675, -3.1931604597],
            [-40.3401048155, -3.2032834627],
            [-40.3550065477, -3.2017238854],
            [-40.3632776241, -3.185574444],
            [-40.3610889102, -3.168993894],
            [-40.3655704879, -3.1541241765],
            [-40.375395065, -3.1410509628],
            [-40.3810943351, -3.1342002901],
            [-40.3914529294, -3.1296809717],
            [-40.4166479359, -3.1254156087],
            [-40.4318874529, -3.1060260722],
            [-40.4471704576, -3.1000773451],
            [-40.4631690113, -3.0852958406],
            [-40.4757610834, -3.0791282973],
            [-40.4830033555, -3.0673438421],
            [-40.4939786709, -3.0633043044],
            [-40.505347502, -2.9923054233],
            [-40.5108945936, -2.9562406466],
            [-40.4812781176, -2.9580652114],
            [-40.4663893451, -2.9598127395],
            [-40.4496347791, -2.9617794891],
            [-40.4065057161, -2.9668388417],
            [-40.3852671284, -2.9693285114],
            [-40.3831040724, -2.9695821501],
            [-40.3833366437, -2.9559592767],
            [-40.3574439082, -2.9627101849],
            [-40.358005835, -2.9523314391],
            [-40.3477878348, -2.9508714131],
            [-40.3422799694, -2.9495558328],
            [-40.3408422624, -2.9450109716],
            [-40.3355944802, -2.945642776],
            [-40.3244271545, -2.9481889314],
            [-40.3217822675, -2.9435736472],
            [-40.2836321588, -2.9407431849],
            [-40.2834965212, -2.940727491],
            [-40.2654492531, -2.9393855521],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2302404',
        name: 'Boa Viagem',
        description: 'Boa Viagem',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.7309553926, -4.7111878135],
            [-39.7254196332, -4.7200748607],
            [-39.7375624835, -4.7378186031],
            [-39.7261568837, -4.7497712583],
            [-39.7293110498, -4.7576485777],
            [-39.7258489129, -4.7631356336],
            [-39.7347968775, -4.768465531],
            [-39.7381033516, -4.7720045663],
            [-39.7280725007, -4.7821060842],
            [-39.7282014865, -4.7867521756],
            [-39.721705357, -4.7834222429],
            [-39.7037839559, -4.7830289278],
            [-39.6973376927, -4.7910499854],
            [-39.6909994879, -4.7931589934],
            [-39.6766689763, -4.789120929],
            [-39.6716790648, -4.7944767846],
            [-39.6610457366, -4.8076845002],
            [-39.6570548443, -4.8324966962],
            [-39.6418897824, -4.8448332077],
            [-39.6501683726, -4.8548339953],
            [-39.6518869044, -4.8624845635],
            [-39.6451007102, -4.8730963468],
            [-39.6361119927, -4.8868881007],
            [-39.628629757, -4.8919118216],
            [-39.6295872201, -4.8994541266],
            [-39.6188899213, -4.9073512897],
            [-39.6178054099, -4.9161958365],
            [-39.6177911769, -4.9163104125],
            [-39.6060879665, -4.940826172],
            [-39.6089702516, -4.9509716954],
            [-39.6030594036, -4.9608878814],
            [-39.6048357248, -4.9690521272],
            [-39.6134710787, -4.975331834],
            [-39.6167257812, -4.9843267489],
            [-39.6016173348, -5.0021665903],
            [-39.6088550517, -5.0133729332],
            [-39.6065216143, -5.0199084279],
            [-39.6117479412, -5.0307252051],
            [-39.6224737922, -5.0346913974],
            [-39.6293569305, -5.0428909895],
            [-39.6313520374, -5.0498030387],
            [-39.6337692841, -5.0545028953],
            [-39.6176231709, -5.0756079462],
            [-39.6177663617, -5.0813189169],
            [-39.5974739671, -5.0971963721],
            [-39.57986138, -5.0954301946],
            [-39.5494729641, -5.1135107488],
            [-39.5337809586, -5.1186579717],
            [-39.5338167003, -5.1187122278],
            [-39.5873750277, -5.2001584266],
            [-39.6136019025, -5.2400640347],
            [-39.6233970392, -5.2549706144],
            [-39.6262834898, -5.3083687178],
            [-39.6219660349, -5.3224071908],
            [-39.628130272, -5.3234052091],
            [-39.6313078563, -5.3360998415],
            [-39.6413110029, -5.3317062628],
            [-39.6447301573, -5.3332351785],
            [-39.6471237008, -5.3416249955],
            [-39.6562926956, -5.3365622906],
            [-39.6581850469, -5.3392662621],
            [-39.6560776613, -5.3448609266],
            [-39.6590560484, -5.3553257237],
            [-39.6621196731, -5.3590906122],
            [-39.6663923706, -5.3582026452],
            [-39.6677821278, -5.3623752091],
            [-39.6688427922, -5.362440538],
            [-39.682209075, -5.3633660789],
            [-39.6882257695, -5.3578674128],
            [-39.6915091787, -5.3592261271],
            [-39.6962908337, -5.3627444143],
            [-39.7046142497, -5.3603188776],
            [-39.7094818611, -5.3720709323],
            [-39.7201848214, -5.3766900168],
            [-39.7210166426, -5.3797366164],
            [-39.7266007305, -5.3792694793],
            [-39.7278872362, -5.3751136445],
            [-39.7332422229, -5.3683660567],
            [-39.7462550983, -5.3734313241],
            [-39.7475159505, -5.3684049585],
            [-39.7551393564, -5.3668769148],
            [-39.7601542611, -5.3697438502],
            [-39.7662383422, -5.3702605172],
            [-39.7706782786, -5.3765228704],
            [-39.7821620706, -5.3790213346],
            [-39.7994243533, -5.399651598],
            [-39.8038466312, -5.3932678298],
            [-39.8073276672, -5.3825224876],
            [-39.8163462059, -5.3933603806],
            [-39.8185252063, -5.3827078871],
            [-39.8246718311, -5.3816152349],
            [-39.8273353837, -5.3755645821],
            [-39.8361368189, -5.3698081856],
            [-39.8379833024, -5.3723750794],
            [-39.8450521668, -5.3673674835],
            [-39.8496280351, -5.3716127308],
            [-39.8755369744, -5.3736356462],
            [-39.8934628175, -5.3958653335],
            [-39.8972091065, -5.3970182253],
            [-39.9068111548, -5.3923386014],
            [-39.9128843046, -5.3857309219],
            [-39.9211207162, -5.3897222726],
            [-39.9252118473, -5.3811843041],
            [-39.9319102844, -5.378909098],
            [-39.9335549488, -5.3647002667],
            [-39.9434422281, -5.355812891],
            [-39.9424181615, -5.35049661],
            [-39.9332803502, -5.3481540084],
            [-39.9294383273, -5.3431677766],
            [-39.9299916889, -5.3378465229],
            [-39.9352822653, -5.3348731554],
            [-39.9926596184, -5.3486623515],
            [-40.000502255, -5.3468555994],
            [-40.0032691773, -5.3387068644],
            [-40.0151075314, -5.3404532179],
            [-40.0205332514, -5.3275777954],
            [-40.0224773334, -5.3241192508],
            [-40.0180852588, -5.3184787768],
            [-40.0278886284, -5.3111900235],
            [-40.0244218218, -5.3018656711],
            [-40.0291698912, -5.2962836438],
            [-40.0552230906, -5.2910662824],
            [-40.0550071377, -5.2848318275],
            [-40.0480291108, -5.2816749423],
            [-40.0468392976, -5.2717587021],
            [-40.0426715546, -5.2609651903],
            [-40.0459334631, -5.2428221134],
            [-40.0462533851, -5.2386930528],
            [-40.0373680223, -5.2302245239],
            [-40.0350283529, -5.2216001282],
            [-40.0208894806, -5.2054656644],
            [-40.0308663565, -5.2000489496],
            [-40.0294223594, -5.1916349533],
            [-40.0351439059, -5.1827923391],
            [-40.0550030899, -5.1809319524],
            [-40.0566482536, -5.18611449],
            [-40.0591659482, -5.1856861046],
            [-40.0747542835, -5.1650459527],
            [-40.0851272114, -5.1738090154],
            [-40.0913261684, -5.1724273035],
            [-40.0977372445, -5.1633676993],
            [-40.1126265233, -5.1667422511],
            [-40.1154360629, -5.1618537964],
            [-40.1215310477, -5.1651907117],
            [-40.1385520942, -5.1630764744],
            [-40.1409314093, -5.1690493907],
            [-40.1531745499, -5.1636369722],
            [-40.1586407427, -5.1665740492],
            [-40.1652492692, -5.1650160263],
            [-40.1650056429, -5.1647322491],
            [-40.048120066, -5.1073791268],
            [-40.0456846234, -5.1061847939],
            [-40.0018008778, -5.0846603074],
            [-39.9967763795, -5.0722861943],
            [-39.9792332677, -5.0569130011],
            [-39.9687026447, -5.0434072244],
            [-39.9731144638, -5.0298259275],
            [-39.9599450305, -5.0285775414],
            [-39.959713476, -5.0205515594],
            [-39.9660309717, -4.9603287007],
            [-39.9680666968, -4.9400960682],
            [-39.9600333814, -4.9390748274],
            [-39.9621432317, -4.9318610601],
            [-39.9570122215, -4.9269172322],
            [-39.9513935968, -4.9236451873],
            [-39.9468148236, -4.9290447481],
            [-39.9391139601, -4.9215213679],
            [-39.9338683448, -4.9238249426],
            [-39.9290265453, -4.9132540964],
            [-39.9228753441, -4.9128752123],
            [-39.9190734421, -4.9082773566],
            [-39.907697018, -4.9030636748],
            [-39.9021333754, -4.9051103765],
            [-39.9001128324, -4.901552868],
            [-39.9025958091, -4.899427287],
            [-39.8938660435, -4.8934605706],
            [-39.8919863684, -4.8925073595],
            [-39.8957479489, -4.8907204537],
            [-39.8925631889, -4.8867776754],
            [-39.9004586827, -4.8840030854],
            [-39.9086660196, -4.8744488361],
            [-39.9241355092, -4.864287901],
            [-39.9402608914, -4.8412397605],
            [-39.9448832992, -4.838227192],
            [-39.9490016642, -4.8261950354],
            [-39.9463970458, -4.821321982],
            [-39.9482988421, -4.8159774441],
            [-39.93003035, -4.7996887174],
            [-39.9289378471, -4.7950563595],
            [-39.9386828597, -4.7823709342],
            [-39.9350792554, -4.7766849093],
            [-39.9392926638, -4.7701640451],
            [-39.9282201008, -4.7741557181],
            [-39.9161993667, -4.7821034129],
            [-39.9142770316, -4.7885049363],
            [-39.8843638749, -4.7895323062],
            [-39.8781612957, -4.7830367031],
            [-39.8783714434, -4.7767318995],
            [-39.871238282, -4.7727401105],
            [-39.8728970014, -4.7658332427],
            [-39.8739050038, -4.7618496219],
            [-39.8638832366, -4.7519564944],
            [-39.8597245152, -4.7372677246],
            [-39.8419495935, -4.7391686838],
            [-39.839566543, -4.7346196406],
            [-39.8348138909, -4.7359058056],
            [-39.8335014939, -4.7328526485],
            [-39.8236635292, -4.730518391],
            [-39.8176233407, -4.73124702],
            [-39.8117646249, -4.7389419515],
            [-39.7983349349, -4.7431392801],
            [-39.7924750088, -4.7529707705],
            [-39.8056670464, -4.7077213729],
            [-39.7406256863, -4.7203000269],
            [-39.7289410847, -4.6964532673],
            [-39.7288094194, -4.6964989043],
            [-39.7309553926, -4.7111878135],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2302503',
        name: 'Brejo Santo',
        description: 'Brejo Santo',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.8821215666, -7.4574065729],
            [-38.8812264777, -7.4585541978],
            [-38.8531078714, -7.4945905958],
            [-38.8371834275, -7.516229379],
            [-38.8132532008, -7.5493721641],
            [-38.7747056111, -7.6027216642],
            [-38.717239438, -7.6264807291],
            [-38.7233716596, -7.6264767341],
            [-38.7352432699, -7.6337178837],
            [-38.7346106206, -7.6390500681],
            [-38.74146714, -7.6479658764],
            [-38.7424469978, -7.6599144896],
            [-38.7541120134, -7.659334538],
            [-38.7602573003, -7.6555933319],
            [-38.775073649, -7.6620258987],
            [-38.7797154573, -7.6611720766],
            [-38.7857601541, -7.6681821912],
            [-38.7903922134, -7.6707421723],
            [-38.7921347624, -7.6676891644],
            [-38.8118359875, -7.6683896772],
            [-38.8154705654, -7.6636502521],
            [-38.8193342444, -7.6644202702],
            [-38.8238854993, -7.6779367221],
            [-38.8147486591, -7.6875719586],
            [-38.8146682085, -7.6969830366],
            [-38.8216064162, -7.7143865223],
            [-38.8277534965, -7.7192065853],
            [-38.8341262678, -7.7192598854],
            [-38.8457463907, -7.7200071633],
            [-38.8513889785, -7.7155295404],
            [-38.8693883829, -7.7111339558],
            [-38.8805565339, -7.7245567522],
            [-38.8808287738, -7.7406642526],
            [-38.8807650907, -7.741216607],
            [-38.8804962475, -7.7435637357],
            [-38.8810412357, -7.7470242446],
            [-38.8944541719, -7.7500454799],
            [-38.8917048467, -7.6350188379],
            [-38.8910970389, -7.6099256869],
            [-38.901889059, -7.6175152673],
            [-38.9121642625, -7.6089197897],
            [-38.9171776905, -7.6085706011],
            [-38.9250249855, -7.6131140387],
            [-38.9364980445, -7.6092218834],
            [-38.9481709208, -7.6144879737],
            [-38.9529728152, -7.610079646],
            [-38.9579480592, -7.6132161393],
            [-38.9640292016, -7.6066464112],
            [-38.9674351782, -7.6079164321],
            [-38.9878294961, -7.6186969313],
            [-39.0123628008, -7.6233713841],
            [-39.0148010417, -7.630357949],
            [-39.027463686, -7.633400044],
            [-39.031665385, -7.6230103524],
            [-39.0316864636, -7.6109481127],
            [-39.0356160028, -7.5464336985],
            [-39.0357926253, -7.542827584],
            [-39.0363403209, -7.5315678393],
            [-39.0459464628, -7.5229867746],
            [-39.0500333558, -7.5193352954],
            [-39.0862341102, -7.4869805438],
            [-39.1210736755, -7.4692107872],
            [-39.1456878413, -7.4548617293],
            [-39.1165780088, -7.4613353232],
            [-39.0980490885, -7.4649812477],
            [-39.0881970299, -7.4620701449],
            [-39.0807776095, -7.4578607197],
            [-39.0809283149, -7.451726793],
            [-39.0758251586, -7.4475871478],
            [-39.0767910805, -7.4369772138],
            [-39.0777823302, -7.4226461221],
            [-39.0442544358, -7.4236828229],
            [-39.0386107935, -7.4238568803],
            [-39.0085042986, -7.4247851007],
            [-39.0057514064, -7.4248702064],
            [-38.9802096335, -7.4256557701],
            [-38.9699430065, -7.425970715],
            [-38.9435272645, -7.4268084913],
            [-38.9389291104, -7.4291001396],
            [-38.8821215666, -7.4574065729],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2302602',
        name: 'Camocim',
        description: 'Camocim',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.5842312419, -2.8487280195],
            [-40.5769474841, -2.8589885615],
            [-40.5768913188, -2.8666874642],
            [-40.5733445756, -2.8661609593],
            [-40.5604435036, -2.8813107331],
            [-40.5374572829, -2.8850916344],
            [-40.5280577522, -2.8911356559],
            [-40.5330442568, -2.9061094615],
            [-40.533766022, -2.9167174878],
            [-40.5248789886, -2.9275743889],
            [-40.5291617716, -2.9452631587],
            [-40.5152214913, -2.9545594316],
            [-40.5108945936, -2.9562406466],
            [-40.505347502, -2.9923054233],
            [-40.4939786709, -3.0633043044],
            [-40.5162168726, -3.0504668407],
            [-40.5319174936, -3.0339574816],
            [-40.558395438, -3.0261588032],
            [-40.5639362853, -3.0176791332],
            [-40.5638927643, -3.0172746351],
            [-40.6304390831, -3.0129564112],
            [-40.6641414824, -3.0107666309],
            [-40.6919609403, -3.0089585494],
            [-40.7530139918, -3.0207878559],
            [-40.8070521463, -3.0312602048],
            [-40.8076642349, -3.0313785048],
            [-40.8381267627, -3.0372824142],
            [-40.8423783684, -3.0345841095],
            [-40.8799086528, -3.0115187911],
            [-40.8886182899, -3.0130296257],
            [-40.9285371912, -3.0199554558],
            [-40.9562634547, -3.02476571],
            [-40.9946786988, -3.0325081758],
            [-41.0012271776, -3.0362765976],
            [-41.1014354987, -3.0939543133],
            [-41.1018786073, -3.0942091901],
            [-41.1107027835, -3.0807693585],
            [-41.1114277505, -3.0801208579],
            [-41.1202303019, -3.0657880036],
            [-41.1187984388, -3.0637583349],
            [-41.1182678027, -3.0630050065],
            [-41.1038804244, -3.0513838567],
            [-41.0996415678, -3.0433381819],
            [-41.1005653889, -3.030224483],
            [-41.1003946665, -3.0296095258],
            [-41.0831850416, -3.0207738975],
            [-41.0839066351, -3.0127454779],
            [-41.0775452223, -3.0092401536],
            [-41.0786527852, -3.0045675321],
            [-41.071871922, -2.9967091137],
            [-41.0765607129, -2.9946644489],
            [-41.0749298077, -2.9763440525],
            [-41.0697085704, -2.9648702168],
            [-41.0647761003, -2.9622149822],
            [-41.0761676299, -2.9440533218],
            [-41.0662574645, -2.9398921824],
            [-41.0669804483, -2.9317609589],
            [-41.0765002466, -2.9279857678],
            [-41.0847195448, -2.9096838307],
            [-41.1013416648, -2.900578759],
            [-41.0959773115, -2.8914824622],
            [-41.0848964992, -2.8953707559],
            [-41.057454078, -2.8959282798],
            [-41.008814113, -2.8917137558],
            [-40.9904147603, -2.8865008298],
            [-40.9621690582, -2.8808633328],
            [-40.9561172407, -2.8718354113],
            [-40.9201205555, -2.8741558821],
            [-40.9079462126, -2.8695475657],
            [-40.9057056096, -2.8584922225],
            [-40.8995598835, -2.8619601102],
            [-40.8795062665, -2.861847558],
            [-40.8741488927, -2.8587412925],
            [-40.8679952941, -2.8624913466],
            [-40.8614782741, -2.855908407],
            [-40.856024453, -2.864626824],
            [-40.8522839997, -2.8649698523],
            [-40.8441993665, -2.8844204512],
            [-40.8431343504, -2.8844207317],
            [-40.8296180283, -2.8768370657],
            [-40.8153944996, -2.8770480294],
            [-40.7947302415, -2.8689017734],
            [-40.7757225747, -2.853753097],
            [-40.7595863915, -2.8497708192],
            [-40.7526224895, -2.8510206386],
            [-40.6904027289, -2.8509604006],
            [-40.6715084264, -2.8467236368],
            [-40.6450668496, -2.8496905599],
            [-40.591865743, -2.8433999439],
            [-40.5735301742, -2.8374195853],
            [-40.5716465269, -2.8396522128],
            [-40.5842312419, -2.8487280195],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2302701',
        name: 'Campos Sales',
        description: 'Campos Sales',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.1693271793, -6.7772534793],
            [-40.1681983489, -6.7913901064],
            [-40.1498767701, -6.7843453669],
            [-40.1414640784, -6.7914539442],
            [-40.1389584315, -6.8018566891],
            [-40.1305710841, -6.8033944066],
            [-40.1351999513, -6.8073947337],
            [-40.1329983473, -6.8104759905],
            [-40.1449557661, -6.8184460026],
            [-40.1469585677, -6.8304603341],
            [-40.0871465949, -6.8469572573],
            [-40.0764529354, -6.8561938584],
            [-40.0915476265, -6.8602023073],
            [-40.114321684, -6.879078937],
            [-40.1248595774, -6.887180061],
            [-40.0798393202, -6.9154149551],
            [-40.0753422239, -6.9122876035],
            [-40.0565515483, -6.9182461203],
            [-40.0629060446, -6.9252466489],
            [-40.0610875684, -6.9283542099],
            [-40.0596557051, -6.9314876838],
            [-40.064619184, -6.943629662],
            [-40.0616527432, -6.9492300752],
            [-40.0670336353, -6.9559584784],
            [-40.0667319208, -6.9636125035],
            [-40.0748039534, -6.9812294662],
            [-40.0829487438, -6.9829097118],
            [-40.095375275, -6.9868687225],
            [-40.0984446717, -6.9967756027],
            [-40.0954506055, -6.9983025428],
            [-40.0998275519, -7.004355093],
            [-40.0958996233, -7.0103553818],
            [-40.1026153633, -7.010319952],
            [-40.1096374034, -7.0170483946],
            [-40.1169729852, -7.0183774498],
            [-40.1197863979, -7.02448242],
            [-40.1220099677, -7.0224832532],
            [-40.1198936082, -7.0150612739],
            [-40.133116349, -7.0014907766],
            [-40.1554432064, -7.0149307041],
            [-40.16886163, -7.0154769178],
            [-40.1880371312, -7.022730272],
            [-40.2115909533, -7.0288078162],
            [-40.2396594528, -7.0471396292],
            [-40.2498397554, -7.0581697253],
            [-40.2484436725, -7.0640276714],
            [-40.2576257293, -7.0705848571],
            [-40.2811135665, -7.0691144385],
            [-40.2983069354, -7.0779493169],
            [-40.3183970504, -7.0795367719],
            [-40.3210570411, -7.0807011022],
            [-40.3232879932, -7.0816780987],
            [-40.3215150588, -7.0901725212],
            [-40.3245869635, -7.0994708344],
            [-40.3200613937, -7.1090694718],
            [-40.323589454, -7.1153846044],
            [-40.3219517217, -7.1278126826],
            [-40.3349234288, -7.1273641501],
            [-40.3537242285, -7.120863651],
            [-40.3553203359, -7.1203102437],
            [-40.3701037748, -7.1151984609],
            [-40.447201681, -7.0885099989],
            [-40.4411547936, -7.0796087651],
            [-40.4590271109, -7.0781338575],
            [-40.4438899346, -7.0555305752],
            [-40.442912323, -7.0476940201],
            [-40.4273486595, -7.0334796838],
            [-40.4265721249, -7.0290589578],
            [-40.4294665923, -7.0274744568],
            [-40.431648218, -7.0252845377],
            [-40.4383155487, -7.0160581161],
            [-40.4276444629, -7.0180753678],
            [-40.4274822653, -7.0149499059],
            [-40.4058806634, -7.0049253799],
            [-40.4107042411, -6.9986744832],
            [-40.4289400791, -6.9942288637],
            [-40.4287522064, -6.9481020761],
            [-40.4241049672, -6.9368006569],
            [-40.424651062, -6.934869085],
            [-40.4334400359, -6.8955080629],
            [-40.4285924194, -6.8844598895],
            [-40.4281506689, -6.8638543191],
            [-40.4103866491, -6.8551797454],
            [-40.407182309, -6.8508929335],
            [-40.401850545, -6.8395895191],
            [-40.3867437908, -6.8296759152],
            [-40.3812630434, -6.8189177873],
            [-40.3702090034, -6.8075937269],
            [-40.370141416, -6.8026706847],
            [-40.3519835322, -6.8084256935],
            [-40.3481374556, -6.7963714133],
            [-40.3426007512, -6.8020332011],
            [-40.3312545382, -6.8039579196],
            [-40.3180521124, -6.8002318354],
            [-40.3153889174, -6.7911796028],
            [-40.3100586655, -6.786117597],
            [-40.3057897377, -6.7879154046],
            [-40.2977172017, -6.8075187198],
            [-40.2914773189, -6.807575413],
            [-40.2806876171, -6.7940587444],
            [-40.2645877295, -6.796255443],
            [-40.258079826, -6.7858078831],
            [-40.2513816943, -6.78623015],
            [-40.2420421711, -6.776660338],
            [-40.2030858305, -6.7741571776],
            [-40.1801250998, -6.7592760963],
            [-40.1693271793, -6.7772534793],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2302800',
        name: 'Canindé',
        description: 'Canindé',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.6661112206, -4.0681623146],
            [-39.643142745, -4.072219678],
            [-39.6278353415, -4.0694220592],
            [-39.6252263481, -4.0795383265],
            [-39.6191019735, -4.0753398628],
            [-39.616920724, -4.079393089],
            [-39.6106179872, -4.0793150554],
            [-39.6065348814, -4.0831368158],
            [-39.5972873505, -4.0940452037],
            [-39.5852021099, -4.0953769467],
            [-39.5761271756, -4.0922209512],
            [-39.5752816292, -4.0921088585],
            [-39.5565622878, -4.1021502677],
            [-39.5472175836, -4.0985344215],
            [-39.5450846542, -4.1010853779],
            [-39.540271898, -4.0896895189],
            [-39.5329266808, -4.0825866803],
            [-39.5307958047, -4.0895611738],
            [-39.5252861691, -4.093727655],
            [-39.5238893051, -4.1115002896],
            [-39.5175838179, -4.1144390775],
            [-39.5144497653, -4.1183092234],
            [-39.5254222559, -4.1222007833],
            [-39.5216719622, -4.1286990546],
            [-39.5232583966, -4.1349166212],
            [-39.5316191521, -4.1392631482],
            [-39.531335689, -4.1525376794],
            [-39.5590812934, -4.1804567317],
            [-39.5591764926, -4.1820143786],
            [-39.5575344065, -4.1871577202],
            [-39.5633509441, -4.2011087431],
            [-39.562998349, -4.2055871222],
            [-39.5698887975, -4.2181495372],
            [-39.5663521755, -4.2232937705],
            [-39.5562639016, -4.2182360294],
            [-39.5306154176, -4.2378374116],
            [-39.5144944067, -4.2385024814],
            [-39.5066919051, -4.242887206],
            [-39.5055592852, -4.266673041],
            [-39.4991452208, -4.2737777833],
            [-39.498416754, -4.283088155],
            [-39.494874134, -4.2863181212],
            [-39.491508456, -4.2893856055],
            [-39.4547733684, -4.2668685126],
            [-39.4342009755, -4.2542612991],
            [-39.4335179821, -4.2538431244],
            [-39.396399787, -4.2310989833],
            [-39.3947764553, -4.2301048427],
            [-39.3702048221, -4.2150524592],
            [-39.334564485, -4.193224881],
            [-39.3087203435, -4.2174695344],
            [-39.2932840043, -4.2323246245],
            [-39.2794021546, -4.2456801393],
            [-39.2439710587, -4.2797538586],
            [-39.2412915921, -4.282329155],
            [-39.208812884, -4.2870222352],
            [-39.2097965134, -4.2974294721],
            [-39.2054009959, -4.3057661004],
            [-39.2002250337, -4.3062515142],
            [-39.1673974076, -4.3017135068],
            [-39.1418748948, -4.2917787704],
            [-39.1283967863, -4.2881436961],
            [-39.0894484653, -4.2971560355],
            [-39.0777525855, -4.3026818336],
            [-39.0721141428, -4.3098213859],
            [-39.0676955037, -4.3117204246],
            [-39.0640299925, -4.3089757427],
            [-39.0618344375, -4.3123944021],
            [-39.0486186885, -4.3154831294],
            [-39.043979475, -4.309393647],
            [-39.0389556814, -4.3066303186],
            [-39.0358199936, -4.3088472701],
            [-39.0377941083, -4.318886318],
            [-39.0259430533, -4.3180696299],
            [-39.0273374467, -4.3278751152],
            [-39.0247807418, -4.3306764424],
            [-39.0333815405, -4.3352291557],
            [-39.0386098235, -4.3491189533],
            [-39.0512759257, -4.3529695805],
            [-39.0501153913, -4.3584560461],
            [-39.057321894, -4.364757994],
            [-39.060572598, -4.3567049057],
            [-39.0632667966, -4.3583128713],
            [-39.0702173581, -4.3523861711],
            [-39.0621992834, -4.361958347],
            [-39.0727736606, -4.3662759267],
            [-39.0581320906, -4.3750323167],
            [-39.065106338, -4.3790010072],
            [-39.066851237, -4.3885712986],
            [-39.0618541679, -4.3919577278],
            [-39.0626677868, -4.3967434563],
            [-39.0655376159, -4.3959593756],
            [-39.0675486982, -4.398493195],
            [-39.0825373112, -4.3909053334],
            [-39.0855610937, -4.3939400137],
            [-39.0889293375, -4.3832000815],
            [-39.0957856341, -4.3815652399],
            [-39.0942744548, -4.3881027749],
            [-39.0989252208, -4.3934716666],
            [-39.0941608076, -4.3972070003],
            [-39.0897457203, -4.4128494463],
            [-39.0785887339, -4.4053794783],
            [-39.0784730019, -4.4170540991],
            [-39.0741745754, -4.4170508641],
            [-39.0768763573, -4.4254131939],
            [-39.0539539751, -4.4370165377],
            [-39.0505850485, -4.4358492848],
            [-39.0511987138, -4.4461373099],
            [-39.0476795552, -4.4470557319],
            [-39.0415206484, -4.4581453392],
            [-39.0307794834, -4.4634394621],
            [-39.0846781711, -4.471469549],
            [-39.0792722147, -4.4851903379],
            [-39.0874185764, -4.4934358233],
            [-39.081811972, -4.5037387583],
            [-39.0850405156, -4.5188028643],
            [-39.0827495916, -4.5257323004],
            [-39.0806123746, -4.53116434],
            [-39.0832848062, -4.5360599308],
            [-39.0754727365, -4.5376691106],
            [-39.0756068135, -4.547795964],
            [-39.069663861, -4.5458519869],
            [-39.0659543445, -4.5612646121],
            [-39.1294221674, -4.6279742602],
            [-39.1307780965, -4.6283037123],
            [-39.1313490712, -4.6284553258],
            [-39.153516956, -4.6338273848],
            [-39.1564926021, -4.6340383834],
            [-39.1790912504, -4.6541179762],
            [-39.1823651288, -4.6502487786],
            [-39.2003080479, -4.6445270722],
            [-39.2039169088, -4.6520772883],
            [-39.2285351593, -4.6521049421],
            [-39.2368890337, -4.644220529],
            [-39.242000757, -4.643559822],
            [-39.2449196379, -4.6427110769],
            [-39.2548131846, -4.6474998526],
            [-39.2817538431, -4.6513724027],
            [-39.2856133315, -4.6589554601],
            [-39.2924559932, -4.6627910871],
            [-39.2945608476, -4.6888495926],
            [-39.2988995957, -4.6961527237],
            [-39.3060928353, -4.7076854241],
            [-39.3143074629, -4.7084908938],
            [-39.3289720414, -4.7165570603],
            [-39.3597255915, -4.7382819235],
            [-39.3665573439, -4.7362847202],
            [-39.3641721452, -4.7319437862],
            [-39.3664268499, -4.7253906424],
            [-39.3680245225, -4.7230284794],
            [-39.3645172176, -4.7206837713],
            [-39.3794131806, -4.7137812039],
            [-39.3814520612, -4.7006522345],
            [-39.3776525029, -4.699408221],
            [-39.3785197331, -4.6945808073],
            [-39.3892659764, -4.6974961188],
            [-39.3894092056, -4.692581933],
            [-39.3961978341, -4.6861973997],
            [-39.417673951, -4.6891917985],
            [-39.420841456, -4.6833331759],
            [-39.4224423312, -4.6813810441],
            [-39.422818952, -4.680921662],
            [-39.4259163524, -4.6752094405],
            [-39.4214984539, -4.6591186621],
            [-39.4250733547, -4.6568616741],
            [-39.4215718424, -4.6534007337],
            [-39.4210569682, -4.6429320266],
            [-39.4331255085, -4.6359985685],
            [-39.4317977188, -4.6246975025],
            [-39.4445576831, -4.6079198414],
            [-39.4534787928, -4.595618179],
            [-39.4481978762, -4.5899032486],
            [-39.44309989, -4.5747606891],
            [-39.443115831, -4.5745559714],
            [-39.4482572712, -4.5717070032],
            [-39.4509733439, -4.5620141247],
            [-39.456900314, -4.560707457],
            [-39.4573255806, -4.5536032422],
            [-39.4639234283, -4.5514839582],
            [-39.4662093677, -4.5361419936],
            [-39.4807586991, -4.5251875199],
            [-39.4826151322, -4.5042439404],
            [-39.4889486478, -4.4954012563],
            [-39.490709124, -4.491545905],
            [-39.4959199495, -4.4875755557],
            [-39.4938622248, -4.4836956083],
            [-39.5034728323, -4.4741093775],
            [-39.5028278923, -4.468302149],
            [-39.5092421101, -4.4657686214],
            [-39.5129864122, -4.468521395],
            [-39.5112822883, -4.4817125542],
            [-39.5145360721, -4.4823219934],
            [-39.5200316077, -4.4967869623],
            [-39.5234289835, -4.4977411958],
            [-39.529112383, -4.4652917569],
            [-39.5333867989, -4.4682634135],
            [-39.5430465996, -4.4661121543],
            [-39.5371411311, -4.4830138173],
            [-39.5393112758, -4.487366345],
            [-39.5462510519, -4.4859156428],
            [-39.5604297652, -4.4747757036],
            [-39.5619423495, -4.4700261041],
            [-39.5654367953, -4.4704540422],
            [-39.5729786953, -4.470785698],
            [-39.5895029006, -4.4902770851],
            [-39.5878610547, -4.497995948],
            [-39.5790343406, -4.4987102077],
            [-39.5804429552, -4.5083477189],
            [-39.5853617747, -4.5089421053],
            [-39.5823641605, -4.5123448117],
            [-39.5822017215, -4.5190608238],
            [-39.5892513188, -4.5197833146],
            [-39.6072251966, -4.5031626296],
            [-39.6268727048, -4.5053081115],
            [-39.6367864561, -4.497205163],
            [-39.6406460254, -4.5020933219],
            [-39.6441436121, -4.5016507692],
            [-39.6438339069, -4.5085209409],
            [-39.6588971574, -4.5119588269],
            [-39.6611208701, -4.5158567572],
            [-39.6605756693, -4.5262265381],
            [-39.6424874903, -4.5388608964],
            [-39.6684776309, -4.557645636],
            [-39.671391736, -4.5530448654],
            [-39.6736377928, -4.5560351706],
            [-39.6868273916, -4.5560049599],
            [-39.6887280067, -4.5560627149],
            [-39.6955646956, -4.5564552192],
            [-39.6998079676, -4.5458595257],
            [-39.7072397273, -4.5427412264],
            [-39.729007943, -4.5402690058],
            [-39.7457821324, -4.5446677086],
            [-39.7566453021, -4.5350865108],
            [-39.7640846959, -4.5344470914],
            [-39.7652541213, -4.5240811372],
            [-39.7546462416, -4.5115422357],
            [-39.7561406994, -4.5029611993],
            [-39.7211969489, -4.4840685811],
            [-39.7265214395, -4.473133836],
            [-39.7256757078, -4.4694588737],
            [-39.7338368211, -4.4640766413],
            [-39.7340542544, -4.4531236595],
            [-39.7295007113, -4.4497710601],
            [-39.732607083, -4.4393935919],
            [-39.7368987412, -4.4378599286],
            [-39.732048983, -4.4353608878],
            [-39.7399628836, -4.4308973348],
            [-39.7382240731, -4.4233131537],
            [-39.7525326276, -4.4067778343],
            [-39.7550263028, -4.399382635],
            [-39.7455803174, -4.3976911869],
            [-39.7376414646, -4.4015458142],
            [-39.727389593, -4.3934213323],
            [-39.7257693601, -4.3839890803],
            [-39.7115063625, -4.3816894551],
            [-39.7099732029, -4.3695113337],
            [-39.7144707001, -4.3551104487],
            [-39.7107804059, -4.3534968394],
            [-39.7102271253, -4.3480666207],
            [-39.7256727341, -4.3442270022],
            [-39.7347386568, -4.3322970475],
            [-39.7329494862, -4.3178449444],
            [-39.7252019976, -4.3182543718],
            [-39.7263982572, -4.3074390089],
            [-39.7322355646, -4.3077365815],
            [-39.7387236015, -4.2948290678],
            [-39.7329656408, -4.2876372725],
            [-39.7380864843, -4.2824594958],
            [-39.730147416, -4.275294387],
            [-39.745726612, -4.2599391254],
            [-39.7504205746, -4.254559735],
            [-39.7723597101, -4.245187519],
            [-39.7777722876, -4.2307510996],
            [-39.7770029823, -4.2234347839],
            [-39.7724765039, -4.2221960252],
            [-39.7802814233, -4.2107566722],
            [-39.7835607045, -4.1982326607],
            [-39.7901179121, -4.1942860939],
            [-39.7877170755, -4.1885701776],
            [-39.7757243093, -4.1809752106],
            [-39.7735507467, -4.1733229756],
            [-39.7685340743, -4.1584397217],
            [-39.7571141342, -4.1574742711],
            [-39.7553947643, -4.1342392228],
            [-39.7492504121, -4.1297455003],
            [-39.748882987, -4.1247411998],
            [-39.7421053757, -4.1138468229],
            [-39.7383087756, -4.1087269982],
            [-39.7209524149, -4.1028026234],
            [-39.7159630098, -4.0961004233],
            [-39.7055582509, -4.1045604401],
            [-39.7030863082, -4.0904643525],
            [-39.696554616, -4.0873549774],
            [-39.6829809861, -4.0806638006],
            [-39.6795314125, -4.0753699873],
            [-39.6645876159, -4.0812948809],
            [-39.6661112206, -4.0681623146],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2302909',
        name: 'Capistrano',
        description: 'Capistrano',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.9479492692, -4.3506588636],
            [-38.9378645231, -4.3551322944],
            [-38.9276535959, -4.3542883918],
            [-38.9210958857, -4.357059222],
            [-38.94437801, -4.3876803371],
            [-38.9352286879, -4.3980968492],
            [-38.9230987403, -4.4114288609],
            [-38.9124281971, -4.4089866344],
            [-38.9097572452, -4.4156590423],
            [-38.8958816449, -4.4241252313],
            [-38.8832972601, -4.4317433756],
            [-38.8785621031, -4.4321951182],
            [-38.876312867, -4.4299367047],
            [-38.8694905965, -4.4300421065],
            [-38.8573324066, -4.4406149079],
            [-38.8281744792, -4.5152469009],
            [-38.8281297227, -4.515359597],
            [-38.8019127842, -4.5820367499],
            [-38.8146498009, -4.5823018231],
            [-38.8227347124, -4.5765553567],
            [-38.8397502992, -4.5837273642],
            [-38.8377778484, -4.5702629603],
            [-38.8407307535, -4.5613648958],
            [-38.8348981872, -4.5571202594],
            [-38.8379922263, -4.549729013],
            [-38.8640654083, -4.5511027547],
            [-38.8689168315, -4.5468618785],
            [-38.8710296374, -4.5287924171],
            [-38.87911261, -4.5238653505],
            [-38.8944327506, -4.5300284397],
            [-38.9056228983, -4.52732946],
            [-38.9083586725, -4.5274414615],
            [-38.9165715361, -4.5185320379],
            [-38.9180476639, -4.5120996448],
            [-38.9286599491, -4.5149739589],
            [-38.9249345446, -4.5230521601],
            [-38.9341405225, -4.5235984861],
            [-38.9325245836, -4.5196291922],
            [-38.9356100826, -4.5160254096],
            [-38.9373062758, -4.4974540581],
            [-38.9416717953, -4.4926129926],
            [-38.9422239622, -4.4919831662],
            [-38.9479883603, -4.4875990505],
            [-38.9650483559, -4.4924474149],
            [-38.9685798063, -4.4918716627],
            [-38.9843311889, -4.4961669385],
            [-38.9978635686, -4.4841323417],
            [-38.9978349334, -4.4674200331],
            [-38.9929617935, -4.4498461817],
            [-38.9838468831, -4.4484648837],
            [-38.976289605, -4.4415845197],
            [-38.9752778767, -4.4335086389],
            [-38.9770127695, -4.4211409274],
            [-38.9827162537, -4.3804315762],
            [-38.9832072752, -4.3769451329],
            [-38.9717921841, -4.3710635432],
            [-38.9599338289, -4.358537538],
            [-38.9509673476, -4.3568906979],
            [-38.9479492692, -4.3506588636],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303006',
        name: 'Caridade',
        description: 'Caridade',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.9840493551, -4.043989723],
            [-38.957203584, -4.0723312526],
            [-38.960695511, -4.0767021604],
            [-38.9522755478, -4.0832264338],
            [-38.9533765963, -4.0911429215],
            [-38.9509990015, -4.1029469425],
            [-38.9459871033, -4.0960310799],
            [-38.9416283373, -4.100878862],
            [-38.9429788337, -4.1050028206],
            [-38.9383340727, -4.1093905289],
            [-38.9438221332, -4.1176152374],
            [-38.9396478018, -4.1252014158],
            [-38.9486063839, -4.1277621587],
            [-38.9476627179, -4.1310609197],
            [-38.9472306013, -4.1363684977],
            [-38.9543536886, -4.1366262652],
            [-38.9585483863, -4.1441533543],
            [-38.9574160398, -4.1551828272],
            [-38.9616097575, -4.1675103755],
            [-38.9607431277, -4.1714025362],
            [-38.9562169063, -4.1711437793],
            [-38.9562846278, -4.177888531],
            [-38.9516977971, -4.1823050528],
            [-38.9608100051, -4.1829509258],
            [-38.9593450903, -4.1884012894],
            [-38.9620743689, -4.1902173429],
            [-38.966603259, -4.1855460783],
            [-38.9707982331, -4.1900867839],
            [-38.9749929348, -4.1861960181],
            [-38.9761235632, -4.1977247599],
            [-38.9790541831, -4.1938498347],
            [-38.9793210621, -4.1962357767],
            [-38.9800708761, -4.1981104562],
            [-38.9825177175, -4.2042314156],
            [-38.9975008778, -4.2060463352],
            [-39.0058238094, -4.2114974254],
            [-39.0059582643, -4.2265491988],
            [-39.0190087328, -4.222655089],
            [-39.0264331477, -4.2271999628],
            [-39.0265327666, -4.227586641],
            [-39.0216078448, -4.230182036],
            [-39.0130188013, -4.228883434],
            [-39.0110848941, -4.2314769018],
            [-39.0098864465, -4.236020294],
            [-39.0028944354, -4.2329062055],
            [-38.9941355313, -4.2498025435],
            [-39.0031591921, -4.2495833564],
            [-39.019842064, -4.256325316],
            [-39.0015693607, -4.2531204531],
            [-38.9919213203, -4.2607500423],
            [-38.9952139375, -4.2600873064],
            [-38.9953842923, -4.2643990103],
            [-39.0000351877, -4.2597568383],
            [-39.0036116839, -4.2624084807],
            [-39.0070739176, -4.2723601942],
            [-39.003612966, -4.2734647698],
            [-39.0087994384, -4.2827270274],
            [-39.0116715214, -4.2883933602],
            [-39.0102521115, -4.2760093769],
            [-39.014792245, -4.2642890467],
            [-39.0142246964, -4.2693751366],
            [-39.019841901, -4.2765626113],
            [-39.0256902146, -4.2732449227],
            [-39.0306256559, -4.2747926816],
            [-39.0322142634, -4.2777788909],
            [-39.0273907789, -4.2855117089],
            [-39.0213187846, -4.2882843976],
            [-39.040273906, -4.2875086346],
            [-39.0351102004, -4.2910465583],
            [-39.0376072605, -4.2919309534],
            [-39.0391592873, -4.2947548649],
            [-39.0399567214, -4.2985493241],
            [-39.0453259496, -4.2996711804],
            [-39.043979475, -4.309393647],
            [-39.0486186885, -4.3154831294],
            [-39.0618344375, -4.3123944021],
            [-39.0640299925, -4.3089757427],
            [-39.0676955037, -4.3117204246],
            [-39.0721141428, -4.3098213859],
            [-39.0777525855, -4.3026818336],
            [-39.0894484653, -4.2971560355],
            [-39.1283967863, -4.2881436961],
            [-39.1418748948, -4.2917787704],
            [-39.1673974076, -4.3017135068],
            [-39.2002250337, -4.3062515142],
            [-39.2054009959, -4.3057661004],
            [-39.2097965134, -4.2974294721],
            [-39.208812884, -4.2870222352],
            [-39.2412915921, -4.282329155],
            [-39.2439710587, -4.2797538586],
            [-39.2794021546, -4.2456801393],
            [-39.2932840043, -4.2323246245],
            [-39.3087203435, -4.2174695344],
            [-39.334564485, -4.193224881],
            [-39.338727985, -4.1908747658],
            [-39.3352532758, -4.1841550081],
            [-39.3190088599, -4.1833616604],
            [-39.3164716737, -4.1736664546],
            [-39.3096006221, -4.1721661892],
            [-39.3094936496, -4.172011886],
            [-39.2912289305, -4.1456424914],
            [-39.2629880778, -4.1359506341],
            [-39.2580513726, -4.13425165],
            [-39.2071594222, -4.1340460443],
            [-39.1959509907, -4.09226887],
            [-39.1729453444, -4.0910066313],
            [-39.181279559, -4.0672740392],
            [-39.1896103156, -4.0576108278],
            [-39.1950880353, -4.0589850967],
            [-39.2036410429, -4.0528878982],
            [-39.2033611031, -4.0465724965],
            [-39.1145935913, -4.0593235307],
            [-39.0633435966, -4.0666776965],
            [-39.0288031407, -4.0627947353],
            [-39.0047860019, -3.9925501357],
            [-38.9840493551, -4.043989723],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303105',
        name: 'Cariré',
        description: 'Cariré',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.4159383232, -3.8062441794],
            [-40.4075479809, -3.8972336037],
            [-40.4061907406, -3.9119152819],
            [-40.4076210929, -3.9300619838],
            [-40.400088567, -3.9378843427],
            [-40.3969774835, -3.9430895492],
            [-40.4002274895, -3.953413526],
            [-40.3942565234, -3.9583590176],
            [-40.3950839255, -3.9670811742],
            [-40.38796244, -3.9789818635],
            [-40.3772552744, -3.9939128223],
            [-40.372251257, -3.9957483493],
            [-40.368506267, -4.006350003],
            [-40.3719714799, -4.0150158903],
            [-40.360757204, -4.0317845825],
            [-40.3607966706, -4.0319532091],
            [-40.3745768842, -4.0911706],
            [-40.3847825596, -4.0911936294],
            [-40.4312571056, -4.0912951012],
            [-40.4313904197, -4.0912946622],
            [-40.4704607648, -4.0863487907],
            [-40.4723680933, -4.0861073799],
            [-40.4976984457, -4.0613632302],
            [-40.511970134, -4.0475789194],
            [-40.5524765542, -4.0477507703],
            [-40.5672455628, -4.0478119824],
            [-40.5845940238, -4.0400238544],
            [-40.6057413451, -4.0305285015],
            [-40.6547923193, -4.0084930426],
            [-40.6468953094, -3.9768776444],
            [-40.6531283395, -3.9797849405],
            [-40.6581010047, -3.9710592001],
            [-40.6589672764, -3.9645587422],
            [-40.6540602846, -3.9547712614],
            [-40.6556702718, -3.9427661305],
            [-40.6653611661, -3.9387590267],
            [-40.674580121, -3.9349123328],
            [-40.6783664544, -3.9288090644],
            [-40.6826257166, -3.9315559656],
            [-40.695205779, -3.9258269587],
            [-40.7044278906, -3.9253335767],
            [-40.7133219089, -3.9180799331],
            [-40.7117885897, -3.9119484966],
            [-40.7084867216, -3.8989979229],
            [-40.7118149065, -3.8908235213],
            [-40.7115441204, -3.8849318791],
            [-40.7076320632, -3.8851817447],
            [-40.704758925, -3.8764523484],
            [-40.6948108665, -3.8699757801],
            [-40.6839824991, -3.864611536],
            [-40.6830806041, -3.8559026935],
            [-40.6722794849, -3.8508618606],
            [-40.6698766225, -3.8457725431],
            [-40.6667231516, -3.8362656572],
            [-40.6317902912, -3.8201268449],
            [-40.6285847702, -3.8186456942],
            [-40.6217455347, -3.8271307259],
            [-40.6141152653, -3.8423739848],
            [-40.6004522472, -3.8444042419],
            [-40.6001442919, -3.8467261751],
            [-40.5943619439, -3.8467096381],
            [-40.5845328278, -3.8582221141],
            [-40.5784191605, -3.8614640117],
            [-40.5698610457, -3.858605996],
            [-40.5697374474, -3.8585644809],
            [-40.5349185492, -3.8469388019],
            [-40.5330833908, -3.8522323243],
            [-40.5148711375, -3.8513244813],
            [-40.4933285174, -3.8507318058],
            [-40.4848407963, -3.8509084128],
            [-40.4222781596, -3.810352111],
            [-40.4159383232, -3.8062441794],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303204',
        name: 'Caririaçu',
        description: 'Caririaçu',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.3397068184, -6.9145967925],
            [-39.3388339635, -6.9279223086],
            [-39.3470953549, -6.9357371468],
            [-39.3458369983, -6.9378278835],
            [-39.3458372058, -6.9379645816],
            [-39.3481092797, -6.9422139504],
            [-39.3346309162, -6.9556691567],
            [-39.3362130164, -6.9620217555],
            [-39.3294082205, -6.9670393546],
            [-39.3308673433, -6.9806053375],
            [-39.3267728513, -6.9847629526],
            [-39.3170946606, -6.9334235196],
            [-39.3029116659, -6.9364897396],
            [-39.2974912127, -6.9330700138],
            [-39.2878597781, -6.9332331735],
            [-39.2702544787, -6.9269698665],
            [-39.2567059135, -6.9276737579],
            [-39.2535847069, -6.92498387],
            [-39.2498814716, -6.9311850481],
            [-39.2449259996, -6.9327150468],
            [-39.2359079299, -6.926714077],
            [-39.233822907, -6.9267739823],
            [-39.1671818669, -6.9286758678],
            [-39.167193732, -6.9345915358],
            [-39.1394829264, -6.9393217265],
            [-39.1189113789, -6.9426308952],
            [-39.1081942841, -6.9626930733],
            [-39.116967714, -6.966029438],
            [-39.1302777064, -6.9809740388],
            [-39.1488299121, -6.9832198162],
            [-39.1553780503, -6.9946962229],
            [-39.1614237252, -6.9984555889],
            [-39.1619260512, -7.0054123497],
            [-39.1578628372, -7.0113014374],
            [-39.1548335428, -7.0165442861],
            [-39.1574685689, -7.0284719617],
            [-39.157459938, -7.0285426164],
            [-39.1432726819, -7.0600219731],
            [-39.1354452414, -7.0718446771],
            [-39.1243351498, -7.0804474539],
            [-39.1114751771, -7.0851957353],
            [-39.116517472, -7.1028268704],
            [-39.1203032628, -7.1116908024],
            [-39.1323258428, -7.1195794755],
            [-39.1350134448, -7.1322962793],
            [-39.1364417609, -7.1303744147],
            [-39.1469204979, -7.1337745687],
            [-39.1520301715, -7.1297756301],
            [-39.1549024226, -7.1324662454],
            [-39.1644093587, -7.132264577],
            [-39.17995309, -7.147748446],
            [-39.1946118564, -7.1511390152],
            [-39.1956904745, -7.1421150523],
            [-39.2063225709, -7.1379141436],
            [-39.2063378819, -7.1377788771],
            [-39.2095869954, -7.1337714793],
            [-39.2070052262, -7.1271574216],
            [-39.2100253118, -7.1259294603],
            [-39.2150558611, -7.1296829655],
            [-39.2271623333, -7.1272893864],
            [-39.2378454358, -7.1335127161],
            [-39.2431125129, -7.1314027799],
            [-39.2356972589, -7.1225181194],
            [-39.2429172723, -7.1193962227],
            [-39.2497782828, -7.1053386169],
            [-39.2647846454, -7.0972872385],
            [-39.2669453921, -7.094338577],
            [-39.2811105483, -7.0937574635],
            [-39.2854390261, -7.1032560478],
            [-39.294825261, -7.1106467379],
            [-39.3297636122, -7.1098156944],
            [-39.3425417767, -7.1095108279],
            [-39.336915728, -7.0999452041],
            [-39.3495577107, -7.0893251839],
            [-39.3493073872, -7.0825167852],
            [-39.3571652446, -7.074372079],
            [-39.3511208246, -7.0561997163],
            [-39.3617288273, -7.0548965518],
            [-39.3778982458, -7.0534384119],
            [-39.3800982435, -7.0507980133],
            [-39.3875297303, -7.0559276773],
            [-39.3983355644, -7.0537583056],
            [-39.397773719, -7.0571646781],
            [-39.4074526005, -7.0550932573],
            [-39.4117575121, -7.0578715217],
            [-39.4223552338, -7.0551280566],
            [-39.4215830344, -7.050954308],
            [-39.4159790517, -7.0482464263],
            [-39.4205015789, -7.0443818281],
            [-39.4258774001, -7.0450745513],
            [-39.4248580695, -7.0488383148],
            [-39.4273094373, -7.0481559411],
            [-39.4295221659, -7.045984067],
            [-39.4535004656, -7.0077654973],
            [-39.4357109277, -6.9635975815],
            [-39.4224169786, -6.9630919478],
            [-39.4191600069, -6.9583658249],
            [-39.4182093638, -6.9569876286],
            [-39.3980250074, -6.9277072339],
            [-39.3948144904, -6.9230508222],
            [-39.3854229105, -6.9241182859],
            [-39.3783511788, -6.9198440429],
            [-39.3512864456, -6.9090948424],
            [-39.342967076, -6.9074249449],
            [-39.3359159336, -6.9052688523],
            [-39.3397068184, -6.9145967925],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303303',
        name: 'Cariús',
        description: 'Cariús',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.400982865, -6.4638525027],
            [-39.4003847853, -6.4653431816],
            [-39.3859130762, -6.4643727151],
            [-39.3842763046, -6.4705286087],
            [-39.3763896459, -6.4713060058],
            [-39.3749961949, -6.4747125682],
            [-39.3538528058, -6.4661071977],
            [-39.348069805, -6.4851457839],
            [-39.355970189, -6.4868274368],
            [-39.3523404998, -6.4921335735],
            [-39.3548070267, -6.4943659375],
            [-39.3527774176, -6.4999971771],
            [-39.3579893011, -6.5096166914],
            [-39.3442841964, -6.5222854352],
            [-39.341732713, -6.5346363291],
            [-39.3157836512, -6.5298354346],
            [-39.2825741579, -6.5266807568],
            [-39.2880819799, -6.533886915],
            [-39.2896288232, -6.5465950923],
            [-39.2943349809, -6.5492039835],
            [-39.296990027, -6.5584454585],
            [-39.2942694497, -6.5623258226],
            [-39.300079654, -6.5749299125],
            [-39.3128274529, -6.5844208386],
            [-39.3133507168, -6.5979451822],
            [-39.3168596312, -6.6036318947],
            [-39.3080552483, -6.6103580292],
            [-39.2965586206, -6.6084095128],
            [-39.2794958142, -6.6290221319],
            [-39.2834526105, -6.6334226146],
            [-39.2812234665, -6.6481066219],
            [-39.2896087911, -6.6572689064],
            [-39.2896164936, -6.6574320582],
            [-39.2904471203, -6.6744183068],
            [-39.3080379477, -6.6852907816],
            [-39.316666009, -6.6841743636],
            [-39.3187450527, -6.7026163702],
            [-39.3292649759, -6.7164027741],
            [-39.3273704845, -6.7189034614],
            [-39.3363350241, -6.7246718932],
            [-39.3353043214, -6.7289832972],
            [-39.3292539704, -6.7317013788],
            [-39.3249708096, -6.7366591877],
            [-39.3118753417, -6.7390822765],
            [-39.2979153584, -6.7337478523],
            [-39.3022272156, -6.7396920467],
            [-39.2965401248, -6.7391793255],
            [-39.3004759414, -6.7534138408],
            [-39.3131702971, -6.7578591731],
            [-39.3305390663, -6.770576997],
            [-39.338220373, -6.7654778786],
            [-39.3482194482, -6.7689459602],
            [-39.3534166935, -6.7613351792],
            [-39.3592783616, -6.7708862276],
            [-39.3654332995, -6.7583187067],
            [-39.3671421924, -6.7627840027],
            [-39.3820227218, -6.7694383446],
            [-39.3824245435, -6.7648440482],
            [-39.3868301476, -6.7657811471],
            [-39.3985587385, -6.7551543679],
            [-39.405166063, -6.7658938519],
            [-39.4119649268, -6.7694719013],
            [-39.4211354392, -6.7742128634],
            [-39.4167892801, -6.7827722044],
            [-39.4421982939, -6.7894975871],
            [-39.4484390598, -6.7865269907],
            [-39.4523850121, -6.7779012053],
            [-39.4676008701, -6.7749974916],
            [-39.4668098585, -6.7710620921],
            [-39.4711706118, -6.7703038925],
            [-39.4731855017, -6.7657860518],
            [-39.4781418316, -6.7554347037],
            [-39.4825056643, -6.7576280212],
            [-39.4882085061, -6.7520458633],
            [-39.4952583869, -6.7513497275],
            [-39.5015649552, -6.7546230749],
            [-39.5119946049, -6.7776287953],
            [-39.5149091014, -6.7768730831],
            [-39.5442521234, -6.7688505743],
            [-39.5490821871, -6.7775680687],
            [-39.5562769643, -6.7763443819],
            [-39.5590040761, -6.789394489],
            [-39.568969321, -6.7898663953],
            [-39.5675584863, -6.8163134263],
            [-39.5749971601, -6.8176796294],
            [-39.5733839598, -6.8202692873],
            [-39.5785711492, -6.8319880919],
            [-39.5669352787, -6.8374219537],
            [-39.5671029282, -6.8424346412],
            [-39.5706603408, -6.8421890486],
            [-39.570180152, -6.8460708839],
            [-39.5743801523, -6.8426690817],
            [-39.5751793476, -6.8467144958],
            [-39.5751942186, -6.8467903552],
            [-39.5870828926, -6.8493069105],
            [-39.5894073802, -6.8307614169],
            [-39.5952275257, -6.8317265883],
            [-39.5998997636, -6.8248150542],
            [-39.596660424, -6.8131229755],
            [-39.6103872364, -6.7977409991],
            [-39.6092061907, -6.7915395737],
            [-39.6164840407, -6.7899675204],
            [-39.5973881772, -6.7497313911],
            [-39.6103370415, -6.7478952115],
            [-39.6048323702, -6.7379847062],
            [-39.6064741473, -6.7359317322],
            [-39.6191114208, -6.7417286284],
            [-39.6252176077, -6.7393284172],
            [-39.6272959388, -6.7337971892],
            [-39.6329705322, -6.7383470008],
            [-39.6415737264, -6.7387617074],
            [-39.64343408, -6.7376514755],
            [-39.6457735885, -6.7367364754],
            [-39.6414806344, -6.7326391667],
            [-39.6385273126, -6.7210733731],
            [-39.6458942326, -6.7156515792],
            [-39.6488347517, -6.6747337856],
            [-39.655145064, -6.6738696829],
            [-39.6569917769, -6.6698773424],
            [-39.6548136087, -6.6653793373],
            [-39.6591464023, -6.6573887971],
            [-39.6582776481, -6.6512862476],
            [-39.6657547656, -6.6369798548],
            [-39.6803606325, -6.6399162603],
            [-39.6831777248, -6.6388493283],
            [-39.687725574, -6.6369516578],
            [-39.701005772, -6.6442906216],
            [-39.705218081, -6.6378440938],
            [-39.7125807472, -6.6348260513],
            [-39.719522407, -6.6394139116],
            [-39.7229241741, -6.6373203904],
            [-39.7302499427, -6.6402324026],
            [-39.7391315669, -6.6320352376],
            [-39.7209774501, -6.6238561887],
            [-39.6969228932, -6.6183130985],
            [-39.6778646459, -6.6227231273],
            [-39.6469152937, -6.6102047702],
            [-39.6224175356, -6.5948068897],
            [-39.5984195518, -6.5843928405],
            [-39.5997018817, -6.5783179527],
            [-39.5838261843, -6.5723517018],
            [-39.5838321011, -6.5779000049],
            [-39.5741267436, -6.5727963569],
            [-39.537503976, -6.5588764688],
            [-39.516524946, -6.541103707],
            [-39.5172464563, -6.5347136673],
            [-39.5132984835, -6.5275911258],
            [-39.4942490594, -6.5144556263],
            [-39.4881400896, -6.5100809374],
            [-39.4718609101, -6.5118812555],
            [-39.4617215808, -6.5084682474],
            [-39.4544706832, -6.4942579154],
            [-39.4468932694, -6.4926532833],
            [-39.4314103749, -6.4805291495],
            [-39.4323262737, -6.4741178906],
            [-39.4238327482, -6.4635600909],
            [-39.4011223763, -6.4635049675],
            [-39.400982865, -6.4638525027],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303402',
        name: 'Carnaubal',
        description: 'Carnaubal',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-41.0391502628, -4.0648794477],
            [-41.0317243215, -4.0654797354],
            [-40.9840630315, -4.056806269],
            [-40.9808177845, -4.0555976145],
            [-40.9585958119, -4.057129878],
            [-40.9405207853, -4.0573727892],
            [-40.9364601103, -4.0613106322],
            [-40.9239617223, -4.0734285592],
            [-40.9089506732, -4.0879791929],
            [-40.908879273, -4.0880485664],
            [-40.8906563575, -4.1057080346],
            [-40.8907235136, -4.1100161976],
            [-40.8900771588, -4.1302685842],
            [-40.8869001967, -4.1349024685],
            [-40.8871102995, -4.1426914947],
            [-40.8958930281, -4.1496138136],
            [-40.9005577945, -4.1585453832],
            [-40.900704075, -4.1586852507],
            [-40.9096918064, -4.1672819353],
            [-40.9330888344, -4.1896654173],
            [-40.9362838088, -4.1927216543],
            [-40.9386365876, -4.1949726155],
            [-40.9414139925, -4.1976298702],
            [-40.9717582656, -4.2266654875],
            [-40.9756276655, -4.2303692203],
            [-40.9842534918, -4.2105375209],
            [-40.9957517604, -4.2044633099],
            [-41.0061463196, -4.1900177761],
            [-41.0181855302, -4.2009803536],
            [-41.073174805, -4.1998903494],
            [-41.0822585652, -4.1892301993],
            [-41.0988992928, -4.1817205027],
            [-41.0987144546, -4.1814599728],
            [-41.090512799, -4.1699526648],
            [-41.1179564592, -4.164844938],
            [-41.1257772188, -4.1524515683],
            [-41.1277414999, -4.1389455138],
            [-41.1428005816, -4.1238644085],
            [-41.1390238166, -4.1206085733],
            [-41.1307266956, -4.1161663892],
            [-41.1177422846, -4.0910338591],
            [-41.1061646715, -4.0819897974],
            [-41.1056613105, -4.0747586946],
            [-41.0965781055, -4.062555502],
            [-41.0870678605, -4.0617770993],
            [-41.0639879653, -4.0495894394],
            [-41.0391502628, -4.0648794477],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303501',
        name: 'Cascavel',
        description: 'Cascavel',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.1787685039, -4.0579889629],
            [-38.1533751706, -4.093252425],
            [-38.1471983068, -4.1012513542],
            [-38.1483091687, -4.1020435405],
            [-38.1527769575, -4.1060125096],
            [-38.1565442833, -4.1101851081],
            [-38.1617578823, -4.1202406488],
            [-38.1624444588, -4.1484656476],
            [-38.1717572064, -4.1665681623],
            [-38.1705060576, -4.1745057937],
            [-38.1745314926, -4.1771193364],
            [-38.1825851396, -4.1946766973],
            [-38.1895018515, -4.1969647047],
            [-38.1922269124, -4.1945687252],
            [-38.2055029086, -4.1951153217],
            [-38.227605286, -4.205620027],
            [-38.2277801578, -4.2057027432],
            [-38.2383557437, -4.2784491016],
            [-38.2493751679, -4.3569060536],
            [-38.2515628764, -4.3659865423],
            [-38.2564482129, -4.3692963032],
            [-38.2555633043, -4.3748137482],
            [-38.2614167357, -4.3805463836],
            [-38.2622418281, -4.3924038529],
            [-38.2693342141, -4.3991760492],
            [-38.2702004988, -4.4057771715],
            [-38.2790546843, -4.4084402959],
            [-38.2818620822, -4.4165362529],
            [-38.2926501035, -4.419037982],
            [-38.2943684952, -4.4229279157],
            [-38.2898356347, -4.4300120634],
            [-38.2945041853, -4.4392348155],
            [-38.3039540312, -4.4441066959],
            [-38.3082517103, -4.4443640762],
            [-38.3209163814, -4.4350328618],
            [-38.3278182324, -4.4374697905],
            [-38.3305597966, -4.4475857274],
            [-38.3283126309, -4.4513488383],
            [-38.3315924469, -4.453371851],
            [-38.3377774261, -4.4581950405],
            [-38.334419784, -4.4685478519],
            [-38.3394937114, -4.4715733669],
            [-38.3412248016, -4.4805184583],
            [-38.3364745661, -4.4895867457],
            [-38.3521687988, -4.4931117274],
            [-38.3602829515, -4.5004912046],
            [-38.3610618866, -4.4992856568],
            [-38.36079788, -4.4894220857],
            [-38.3581996967, -4.4799481405],
            [-38.3614057239, -4.4716080948],
            [-38.3614658863, -4.4714523717],
            [-38.4185516564, -4.4054675371],
            [-38.4358899018, -4.3854106069],
            [-38.4516324099, -4.3677722121],
            [-38.4133059386, -4.3478666275],
            [-38.3973498527, -4.3395547899],
            [-38.3970703391, -4.3394349649],
            [-38.3853707534, -4.333315809],
            [-38.3866405489, -4.315998727],
            [-38.3897508354, -4.2734846139],
            [-38.3910232495, -4.2560493192],
            [-38.3923001428, -4.2385407001],
            [-38.3933110426, -4.224663429],
            [-38.3931738482, -4.2238401156],
            [-38.3930745146, -4.2232355639],
            [-38.3870785933, -4.1872105279],
            [-38.3806729895, -4.1448958668],
            [-38.3806494115, -4.1447244969],
            [-38.3762289947, -4.1099703404],
            [-38.3757944613, -4.1065551478],
            [-38.3722351123, -4.0786030094],
            [-38.3498175237, -4.0874849603],
            [-38.3272027266, -4.0912117901],
            [-38.3071726506, -4.0928268651],
            [-38.3034606378, -4.0934112896],
            [-38.3002132426, -4.0939591459],
            [-38.2922840535, -4.0950275939],
            [-38.2846425089, -4.1016145372],
            [-38.2887419926, -4.08019009],
            [-38.2933799341, -4.0624221133],
            [-38.2810319202, -4.0654123597],
            [-38.2641493261, -4.0731145585],
            [-38.2625830035, -4.0555291926],
            [-38.2592058235, -4.04440931],
            [-38.2572730646, -4.0382967334],
            [-38.2540183707, -4.028005887],
            [-38.2324236505, -4.0216930508],
            [-38.2269900933, -4.0079967158],
            [-38.2225058384, -4.0062924298],
            [-38.2020326175, -4.0330436408],
            [-38.1926705324, -4.0390966768],
            [-38.1787685039, -4.0579889629],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303600',
        name: 'Catarina',
        description: 'Catarina',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.8675425135, -6.1250867473],
            [-39.8629512596, -6.12647504],
            [-39.8588136407, -6.1252725827],
            [-39.8513547761, -6.1138994498],
            [-39.8344841804, -6.1366719919],
            [-39.8312714687, -6.1377377307],
            [-39.8291448098, -6.129288181],
            [-39.8254732149, -6.1270257048],
            [-39.8135032156, -6.1349276704],
            [-39.8022362444, -6.1370912615],
            [-39.8074809148, -6.1427755137],
            [-39.7993116268, -6.1476191767],
            [-39.799046487, -6.1541726268],
            [-39.7938141805, -6.157113118],
            [-39.7981486368, -6.1681459917],
            [-39.79482041, -6.174542837],
            [-39.8003175288, -6.1951580275],
            [-39.7942117343, -6.1960561759],
            [-39.7987102386, -6.2091989715],
            [-39.7929058443, -6.2073309542],
            [-39.7869151204, -6.2169271978],
            [-39.7888134424, -6.2243434468],
            [-39.7964174101, -6.2415291361],
            [-39.8080282386, -6.2456619023],
            [-39.8067125117, -6.2512997534],
            [-39.809361187, -6.2521863738],
            [-39.8059133903, -6.2603946657],
            [-39.8105033139, -6.2664183367],
            [-39.8168103034, -6.2630479902],
            [-39.8221648712, -6.2793391262],
            [-39.8211407139, -6.2880073041],
            [-39.8286482319, -6.2879626016],
            [-39.8381728903, -6.2966463701],
            [-39.8324695269, -6.2994766144],
            [-39.8304049284, -6.3071379474],
            [-39.8367622056, -6.323151972],
            [-39.8474786637, -6.3392585947],
            [-39.8452089646, -6.3473238621],
            [-39.8526976189, -6.3576903018],
            [-39.8641116429, -6.356563024],
            [-39.8795881244, -6.3633886522],
            [-39.8864340459, -6.3613618363],
            [-39.8946447271, -6.3678967195],
            [-39.8946355082, -6.3621535152],
            [-39.9069821498, -6.3616296378],
            [-39.9125720055, -6.3575192614],
            [-39.9244036665, -6.3518232208],
            [-39.9210785934, -6.3500140964],
            [-39.9246919508, -6.3403347599],
            [-39.9465969471, -6.331013189],
            [-39.9557060672, -6.3310083445],
            [-39.974402236, -6.3384306523],
            [-39.979469482, -6.3318771195],
            [-39.9894279997, -6.32731722],
            [-40.0028282918, -6.3342454493],
            [-40.0138204314, -6.3304963585],
            [-40.0156957938, -6.3338154054],
            [-40.0245075172, -6.3289624443],
            [-40.0411293289, -6.3388052039],
            [-40.0395879976, -6.3452563204],
            [-40.0474829315, -6.3495724752],
            [-40.049053962, -6.3569268483],
            [-40.0565415869, -6.3636614829],
            [-40.0619454205, -6.3664718425],
            [-40.0668116853, -6.3621292545],
            [-40.0755428272, -6.3687615474],
            [-40.0847401575, -6.3682019186],
            [-40.0832708359, -6.3596038992],
            [-40.0772915839, -6.3542635487],
            [-40.0716278565, -6.3548606299],
            [-40.0727417956, -6.3488570572],
            [-40.0790944024, -6.3490520269],
            [-40.0802206049, -6.345894954],
            [-40.0711452353, -6.3409276164],
            [-40.0647767653, -6.3293518924],
            [-40.0633474534, -6.3120925061],
            [-40.0572388484, -6.2980115264],
            [-40.0512292582, -6.3017761941],
            [-40.0471960927, -6.299890353],
            [-40.0515656724, -6.2919068244],
            [-40.0482847108, -6.2862615159],
            [-40.0496556647, -6.2797878634],
            [-40.0284980432, -6.2794865673],
            [-40.0247867759, -6.270680968],
            [-40.0209786994, -6.2651027217],
            [-40.024927105, -6.2541400923],
            [-40.0188498443, -6.2398392367],
            [-40.0209917497, -6.231121158],
            [-40.0149623192, -6.2222850815],
            [-40.0078073796, -6.2195199609],
            [-40.0003849946, -6.2154786213],
            [-39.9865664648, -6.2203858055],
            [-39.9476763816, -6.196083406],
            [-39.9437115218, -6.1971055798],
            [-39.9395053639, -6.1936397086],
            [-39.9257144286, -6.1954354259],
            [-39.9165345687, -6.1904250751],
            [-39.9147954164, -6.1882217281],
            [-39.9183704391, -6.1818503487],
            [-39.8935967072, -6.155483688],
            [-39.9008642865, -6.144415287],
            [-39.8956228366, -6.1363167802],
            [-39.8918617221, -6.1294620052],
            [-39.8969741343, -6.1219399902],
            [-39.8912975502, -6.1139407617],
            [-39.8757181847, -6.1200829725],
            [-39.8676837886, -6.1198865058],
            [-39.8675425135, -6.1250867473],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303659',
        name: 'Catunda',
        description: 'Catunda',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.0453690543, -4.4557995562],
            [-40.0367230387, -4.4568354935],
            [-40.0033139145, -4.4884692578],
            [-40.0055901129, -4.4977061992],
            [-40.0015300653, -4.5047811007],
            [-40.0074761273, -4.5224234707],
            [-40.0236161037, -4.5446318352],
            [-40.0313677184, -4.5670460902],
            [-40.0434469744, -4.5756027402],
            [-40.0573677647, -4.5680295552],
            [-40.0733430576, -4.5873572399],
            [-40.0893129913, -4.5880515631],
            [-40.1115024624, -4.594998023],
            [-40.1214763879, -4.6105393149],
            [-40.1120712233, -4.6119133151],
            [-40.1109350008, -4.6256602792],
            [-40.1075488814, -4.6273161263],
            [-40.105656718, -4.638054799],
            [-40.1076241778, -4.6422317152],
            [-40.1032784129, -4.6425483449],
            [-40.107043301, -4.644554286],
            [-40.1066719971, -4.6494262943],
            [-40.1038884678, -4.6608672694],
            [-40.0917048541, -4.664085984],
            [-40.0803863186, -4.6792762017],
            [-40.0801323593, -4.6838705248],
            [-40.0877079638, -4.6841153772],
            [-40.0808832081, -4.6922278229],
            [-40.0833813553, -4.6968175206],
            [-40.0781872972, -4.6966210535],
            [-40.0766550867, -4.7000800357],
            [-40.0608615061, -4.700311001],
            [-40.0667564288, -4.7080931347],
            [-40.0590854725, -4.7174973622],
            [-40.1118214157, -4.7518829545],
            [-40.1281963366, -4.7985066082],
            [-40.1324323034, -4.8037133668],
            [-40.1452907739, -4.8077347306],
            [-40.1551937207, -4.8093478761],
            [-40.1581871773, -4.8042217816],
            [-40.1789217003, -4.7879731637],
            [-40.1958390722, -4.7914430392],
            [-40.204231938, -4.7900483118],
            [-40.2212561616, -4.788687915],
            [-40.2448413181, -4.7772217867],
            [-40.268686972, -4.77308181],
            [-40.2844727229, -4.7496943797],
            [-40.3131542937, -4.7279672528],
            [-40.314432593, -4.7251302553],
            [-40.3103193264, -4.7231702948],
            [-40.3040404576, -4.7225256626],
            [-40.3051130989, -4.7164874578],
            [-40.3148649756, -4.7068444978],
            [-40.3142217888, -4.6892188811],
            [-40.3222482902, -4.6836361916],
            [-40.3312013443, -4.6666756396],
            [-40.3367064811, -4.6643428755],
            [-40.3366118571, -4.6545368031],
            [-40.3309807674, -4.6473711542],
            [-40.3314947222, -4.639505412],
            [-40.3170952263, -4.6357526458],
            [-40.318904265, -4.6220786334],
            [-40.3166020455, -4.617514462],
            [-40.311843731, -4.6176942747],
            [-40.3029346373, -4.6093307002],
            [-40.3034554993, -4.6047617281],
            [-40.2978855876, -4.6062129355],
            [-40.2970692595, -4.60379358],
            [-40.2942418728, -4.6006392981],
            [-40.2823834003, -4.6084959295],
            [-40.2773925749, -4.6069699537],
            [-40.2714776112, -4.611172422],
            [-40.2644815269, -4.6060382516],
            [-40.2526315275, -4.6055615436],
            [-40.2509171259, -4.6014148756],
            [-40.247043181, -4.6027909427],
            [-40.2363713145, -4.5926837445],
            [-40.2428411239, -4.5880253052],
            [-40.2390344488, -4.579441549],
            [-40.244092707, -4.5784249315],
            [-40.2330718146, -4.5572490698],
            [-40.2406180949, -4.5596849349],
            [-40.2429593471, -4.5575641352],
            [-40.2425616081, -4.5498046539],
            [-40.2400897997, -4.5496418007],
            [-40.2438808216, -4.523326256],
            [-40.246942353, -4.5199999029],
            [-40.2565313468, -4.5192686286],
            [-40.2679684114, -4.5069340562],
            [-40.2556720616, -4.4916005314],
            [-40.2562168653, -4.4866608393],
            [-40.2503026494, -4.4916919964],
            [-40.2490591237, -4.4876691273],
            [-40.2455661194, -4.4877580932],
            [-40.2475559313, -4.4799122778],
            [-40.2541106529, -4.4726006722],
            [-40.2082231165, -4.4654339965],
            [-40.1996982726, -4.4729768932],
            [-40.1935371506, -4.4949973748],
            [-40.1837785438, -4.5029307239],
            [-40.1828420751, -4.5075716086],
            [-40.1694285438, -4.5075423876],
            [-40.1523083351, -4.5056374819],
            [-40.1354442886, -4.5117477174],
            [-40.1201221971, -4.5040119911],
            [-40.1116151202, -4.5107778792],
            [-40.1009113557, -4.5083410351],
            [-40.0915591972, -4.5011270989],
            [-40.0957834881, -4.4927728585],
            [-40.1025814995, -4.492955937],
            [-40.0980298492, -4.4845258207],
            [-40.0898225793, -4.4824648432],
            [-40.0682810663, -4.4709611078],
            [-40.0577830958, -4.4545166449],
            [-40.0497558658, -4.4562748725],
            [-40.0461122574, -4.453141158],
            [-40.0453690543, -4.4557995562],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303709',
        name: 'Caucaia',
        description: 'Caucaia',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.6546406034, -3.6806664634],
            [-38.6387006246, -3.6869926016],
            [-38.6085363851, -3.6879010398],
            [-38.5949449754, -3.6932872986],
            [-38.5928119741, -3.691155826],
            [-38.5889721562, -3.6960497994],
            [-38.5917595555, -3.7014933091],
            [-38.6002849656, -3.7043225582],
            [-38.6225008644, -3.7040844419],
            [-38.6183660102, -3.7113928947],
            [-38.6202345036, -3.7166567531],
            [-38.6209728301, -3.7221818198],
            [-38.6095380621, -3.7250960532],
            [-38.6007838875, -3.7403477696],
            [-38.6013945068, -3.7487900236],
            [-38.6108866868, -3.7556931294],
            [-38.6143007358, -3.7628246121],
            [-38.6239317875, -3.7792531928],
            [-38.6362380791, -3.8002504984],
            [-38.6363445448, -3.8003876496],
            [-38.642923446, -3.8102938495],
            [-38.640103353, -3.8224666297],
            [-38.644135822, -3.8265027354],
            [-38.6447395955, -3.8351581083],
            [-38.6584345218, -3.84043996],
            [-38.668473547, -3.846068137],
            [-38.6846533355, -3.8459628002],
            [-38.6891866901, -3.8571123398],
            [-38.7006652723, -3.8712427851],
            [-38.7163561547, -3.8808616746],
            [-38.7254633797, -3.9013505494],
            [-38.7313198405, -3.9058144265],
            [-38.7315018142, -3.9121811937],
            [-38.7368707399, -3.9199086614],
            [-38.7363938163, -3.9288527612],
            [-38.7431191088, -3.9348588233],
            [-38.7549309601, -3.9282260624],
            [-38.7650543764, -3.9428846539],
            [-38.7753084204, -3.9511906918],
            [-38.7754650701, -3.9513865339],
            [-38.7873666488, -3.9662650867],
            [-38.8013588486, -3.9650403937],
            [-38.8110378133, -3.9608352916],
            [-38.8311960674, -3.9774160622],
            [-38.8489333789, -3.9920073867],
            [-38.8722802365, -3.9688283144],
            [-38.8851906019, -3.9644608268],
            [-38.9056173985, -3.9575507375],
            [-38.9214969943, -3.9521760521],
            [-38.9418004483, -3.9453044309],
            [-38.9429712988, -3.9449079807],
            [-38.9623063125, -3.9225408241],
            [-38.9581347059, -3.9145226545],
            [-38.9616337442, -3.9056579043],
            [-38.9682743206, -3.8970378703],
            [-38.9788930896, -3.894120322],
            [-38.9765036057, -3.8888766497],
            [-38.9871453877, -3.8685026875],
            [-38.983506199, -3.8625653161],
            [-38.9977775731, -3.8484763446],
            [-38.9986489798, -3.8355383542],
            [-38.9935575223, -3.8256514137],
            [-38.9870363271, -3.8219181696],
            [-38.9833630561, -3.8171797605],
            [-38.9813945993, -3.8188068058],
            [-38.9771168785, -3.8140312014],
            [-38.9810580148, -3.8053028219],
            [-38.9754442727, -3.7950062704],
            [-38.9809676785, -3.7834556202],
            [-38.9727748047, -3.77369609],
            [-38.9573657846, -3.7687717487],
            [-38.9568818768, -3.7521099617],
            [-38.9534291493, -3.7217835832],
            [-38.9602152946, -3.7191673255],
            [-38.9609039882, -3.711166074],
            [-38.940535532, -3.6842913194],
            [-38.9313641954, -3.6811573929],
            [-38.922209397, -3.6831330832],
            [-38.9235262419, -3.6722037706],
            [-38.9196091902, -3.6661136148],
            [-38.9089195809, -3.6718186632],
            [-38.8824946048, -3.685918576],
            [-38.8608911816, -3.6367394136],
            [-38.8534087017, -3.6197114913],
            [-38.8317359514, -3.5704135081],
            [-38.827243556, -3.566478843],
            [-38.8256999809, -3.5651252253],
            [-38.805645218, -3.5475604495],
            [-38.7805522297, -3.580763232],
            [-38.7750474517, -3.5885904979],
            [-38.7632072097, -3.6044286052],
            [-38.7448501474, -3.6189690412],
            [-38.7285702844, -3.6238107927],
            [-38.7043021605, -3.6366017693],
            [-38.6664742722, -3.6742708483],
            [-38.6546406034, -3.6806664634],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303808',
        name: 'Cedro',
        description: 'Cedro',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.1455003315, -6.4418590444],
            [-39.1450003538, -6.4535225981],
            [-39.1277776497, -6.4650502192],
            [-39.1217830938, -6.4663554563],
            [-39.1184479151, -6.4623222275],
            [-39.1070568847, -6.4645913575],
            [-39.1041934828, -6.4569123268],
            [-39.1017275485, -6.4610436073],
            [-39.0941080146, -6.4587447784],
            [-39.09430939, -6.4514058431],
            [-39.0795025751, -6.4512317337],
            [-39.0620891309, -6.44553601],
            [-39.0459582845, -6.4574917711],
            [-39.045305401, -6.4580008553],
            [-39.0063686044, -6.488382425],
            [-38.9867472549, -6.503684254],
            [-38.9636231884, -6.5217106763],
            [-38.9571427979, -6.5267614308],
            [-38.9247635434, -6.5595965151],
            [-38.9193889876, -6.5650444014],
            [-38.9235898221, -6.6071462039],
            [-38.9317397722, -6.6166197706],
            [-38.9433212909, -6.6317468688],
            [-38.9482548313, -6.6347772031],
            [-38.9510321097, -6.6437543089],
            [-38.9605079635, -6.6456666884],
            [-38.9629681018, -6.6529091741],
            [-38.9636751578, -6.6538843063],
            [-38.9806413908, -6.662968346],
            [-38.9981669775, -6.6584662292],
            [-39.0171587139, -6.6607332505],
            [-39.047069324, -6.6756460067],
            [-39.0531960602, -6.6595501414],
            [-39.0635714711, -6.6671163479],
            [-39.0573528968, -6.6828382716],
            [-39.0498860772, -6.684648279],
            [-39.0446370401, -6.6944241349],
            [-39.0627202744, -6.6993798882],
            [-39.0702004367, -6.7052954272],
            [-39.0823385236, -6.7054092797],
            [-39.0910268458, -6.7124023083],
            [-39.1090309445, -6.7141353794],
            [-39.1135709519, -6.7104305731],
            [-39.1258506086, -6.7107973115],
            [-39.1300033077, -6.7085740611],
            [-39.1318639532, -6.6989591394],
            [-39.1294185664, -6.6917234334],
            [-39.1351392061, -6.6878823998],
            [-39.1345827386, -6.6827564651],
            [-39.1410714788, -6.6771481499],
            [-39.1441393501, -6.6714940042],
            [-39.1612759282, -6.6745068732],
            [-39.1743271684, -6.6707614345],
            [-39.1713740161, -6.6626889538],
            [-39.1762024081, -6.6572783299],
            [-39.1739530355, -6.6452077092],
            [-39.1835074201, -6.6409718996],
            [-39.1773011151, -6.6321751014],
            [-39.1727943329, -6.622321161],
            [-39.180626887, -6.6180321681],
            [-39.1782235264, -6.6129118069],
            [-39.1936712149, -6.6096962285],
            [-39.2095154806, -6.6074141102],
            [-39.2147054514, -6.5958468002],
            [-39.221660321, -6.5938492311],
            [-39.2196326034, -6.5894397588],
            [-39.2255398515, -6.5884250872],
            [-39.2302595871, -6.5832095648],
            [-39.2394696493, -6.5832085832],
            [-39.2412256894, -6.5825441755],
            [-39.2813573297, -6.5673299434],
            [-39.2942694497, -6.5623258226],
            [-39.296990027, -6.5584454585],
            [-39.2943349809, -6.5492039835],
            [-39.2896288232, -6.5465950923],
            [-39.2880819799, -6.533886915],
            [-39.2825741579, -6.5266807568],
            [-39.2567817318, -6.5222941991],
            [-39.2510879455, -6.5263446923],
            [-39.2467476467, -6.5223962736],
            [-39.2367489041, -6.523765473],
            [-39.2275256334, -6.518837254],
            [-39.2265334749, -6.5030359677],
            [-39.2310323271, -6.492297286],
            [-39.2205648468, -6.490453527],
            [-39.2091898762, -6.4843749186],
            [-39.2060893716, -6.4642278111],
            [-39.1963012218, -6.4609042814],
            [-39.1839443655, -6.4525696352],
            [-39.1736126203, -6.4487680712],
            [-39.1672244011, -6.4497622553],
            [-39.1671499965, -6.4473666296],
            [-39.1455003315, -6.4418590444],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303907',
        name: 'Chaval',
        description: 'Chaval',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-41.185576042, -3.0215087016],
            [-41.1892733415, -3.0441515243],
            [-41.1801761395, -3.0410874212],
            [-41.1798159715, -3.0471768125],
            [-41.1680415742, -3.0533647398],
            [-41.1572450369, -3.0705904408],
            [-41.1706055111, -3.082093699],
            [-41.1690972066, -3.0989276768],
            [-41.158337765, -3.1220467125],
            [-41.1507495718, -3.1247460725],
            [-41.1661433855, -3.1343620119],
            [-41.2088508299, -3.1614492591],
            [-41.2370609315, -3.179745302],
            [-41.3176433069, -3.1794382632],
            [-41.3221108013, -3.1794214466],
            [-41.3256313523, -3.1686124367],
            [-41.3283363625, -3.1612219414],
            [-41.3189760862, -3.1497062155],
            [-41.3187823382, -3.1449717224],
            [-41.3020695395, -3.1297480499],
            [-41.2994869817, -3.1275734583],
            [-41.2850863532, -3.1129068341],
            [-41.2854464677, -3.1029042716],
            [-41.2719186758, -3.1005437116],
            [-41.267588022, -3.093468594],
            [-41.2562777148, -3.0876442885],
            [-41.2561546627, -3.0713220439],
            [-41.2609757523, -3.0667037546],
            [-41.2610894799, -3.0534807172],
            [-41.2601767652, -3.0476561819],
            [-41.2533930662, -3.0372189427],
            [-41.25494606, -3.0323647017],
            [-41.2486320328, -3.028319832],
            [-41.2554403968, -3.0198267556],
            [-41.256751407, -3.0042747154],
            [-41.2691697391, -2.9847097742],
            [-41.2720570562, -2.9703218682],
            [-41.247014565, -2.9721125602],
            [-41.226776386, -2.9819098246],
            [-41.1939658027, -2.9977894487],
            [-41.1889210657, -3.0202045689],
            [-41.185576042, -3.0215087016],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303931',
        name: 'Choró',
        description: 'Choró',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.1119232823, -4.6415546886],
            [-39.1089737875, -4.6425199193],
            [-39.1000037231, -4.6361589166],
            [-39.0962495787, -4.6431620133],
            [-39.0807542732, -4.6389774735],
            [-39.0744136725, -4.6479022163],
            [-39.0700370644, -4.6517972638],
            [-39.0590010089, -4.6510608461],
            [-39.0461425745, -4.6395970689],
            [-39.0298114199, -4.6436373043],
            [-39.0218710773, -4.6407279781],
            [-39.0125889697, -4.6442727593],
            [-39.0075583968, -4.6501330058],
            [-38.9959476887, -4.6472528858],
            [-38.9910340751, -4.6526960521],
            [-38.9830035772, -4.6539129921],
            [-38.9772863064, -4.6602746784],
            [-38.9783624812, -4.6683698761],
            [-38.9784998874, -4.6682695349],
            [-38.983278583, -4.6648320755],
            [-38.9926389498, -4.6663548445],
            [-38.9999199357, -4.674001001],
            [-39.0044480537, -4.6668271634],
            [-39.021867557, -4.6650335705],
            [-39.033753865, -4.6676840428],
            [-39.0436436677, -4.6934407812],
            [-39.0424792663, -4.7153551301],
            [-39.0495005372, -4.7153963478],
            [-39.0600592265, -4.7244346717],
            [-39.0726410759, -4.7262636095],
            [-39.1030017034, -4.7612466617],
            [-39.0872019196, -4.7614483702],
            [-39.0746008282, -4.7727301566],
            [-39.0682992394, -4.7648161235],
            [-39.0533507121, -4.7706348728],
            [-39.048791502, -4.7703761764],
            [-39.0378119541, -4.782230602],
            [-39.0460446747, -4.7827263661],
            [-39.0553937869, -4.7879702453],
            [-39.0583433441, -4.7962799634],
            [-39.0665757022, -4.7998413695],
            [-39.0655608102, -4.8046873207],
            [-39.0692186142, -4.8076569657],
            [-39.0662720846, -4.8125057052],
            [-39.0706418352, -4.8146813796],
            [-39.0701351582, -4.8206185775],
            [-39.0776553568, -4.8245743406],
            [-39.0851061578, -4.8226329556],
            [-39.082781228, -4.8272930373],
            [-39.0772610158, -4.8291073352],
            [-39.081035307, -4.841368465],
            [-39.0902964686, -4.8332472873],
            [-39.0976109788, -4.8333766369],
            [-39.101933017, -4.8376481876],
            [-39.1016664027, -4.8429560907],
            [-39.1047935322, -4.8417898824],
            [-39.1014685235, -4.8468401564],
            [-39.1040621437, -4.8485202545],
            [-39.1086509743, -4.8412717823],
            [-39.1161628059, -4.8428237904],
            [-39.1157648929, -4.8380334207],
            [-39.1199551522, -4.8386807739],
            [-39.123413188, -4.8461894525],
            [-39.1224835214, -4.8540851962],
            [-39.118227944, -4.8530502934],
            [-39.1206896634, -4.8578401766],
            [-39.1222855275, -4.8632775614],
            [-39.1334607771, -4.8738902704],
            [-39.1345920197, -4.879846652],
            [-39.1404449398, -4.8807517217],
            [-39.1523557662, -4.904439777],
            [-39.1629281056, -4.9063798028],
            [-39.1625306203, -4.910522295],
            [-39.168318108, -4.9166043873],
            [-39.1677213255, -4.9226908995],
            [-39.1646254758, -4.9243159073],
            [-39.1641952283, -4.9251546951],
            [-39.17337619, -4.9317507216],
            [-39.1761039554, -4.9384825077],
            [-39.1836886994, -4.9414578364],
            [-39.186531232, -4.9589121684],
            [-39.1987230628, -4.9658763882],
            [-39.2074250615, -4.9584074924],
            [-39.2249773066, -4.9611590793],
            [-39.224494397, -4.952122898],
            [-39.2319269829, -4.9418436249],
            [-39.2380241704, -4.9395446997],
            [-39.2478960147, -4.922749438],
            [-39.2721659155, -4.9089833031],
            [-39.2782745747, -4.8978330288],
            [-39.2848131934, -4.8931640916],
            [-39.2840957149, -4.8850227995],
            [-39.2894136685, -4.879589257],
            [-39.3037449284, -4.8800629934],
            [-39.2982796946, -4.869492155],
            [-39.2971125963, -4.8586429981],
            [-39.303147334, -4.8492119256],
            [-39.3088095408, -4.8389825841],
            [-39.3264010792, -4.834925684],
            [-39.3312557959, -4.8339449539],
            [-39.3423836829, -4.8163858768],
            [-39.3399091293, -4.809227597],
            [-39.34308348, -4.79904356],
            [-39.3398084635, -4.7967779595],
            [-39.33906045, -4.7905115185],
            [-39.342872443, -4.7936666607],
            [-39.3410969759, -4.7879560249],
            [-39.3456811171, -4.7877813535],
            [-39.343109646, -4.7773648791],
            [-39.345872957, -4.7762510393],
            [-39.3279755396, -4.7627899786],
            [-39.3168913956, -4.7514949085],
            [-39.3059820959, -4.7527042755],
            [-39.3005835991, -4.7432745841],
            [-39.2964921223, -4.7376043208],
            [-39.2900618801, -4.7389079673],
            [-39.297216193, -4.7287810193],
            [-39.302085702, -4.7229526241],
            [-39.3139888656, -4.7270622926],
            [-39.3147618104, -4.7220592761],
            [-39.3285995218, -4.7338936697],
            [-39.3556422502, -4.7512236547],
            [-39.3699990203, -4.7392047102],
            [-39.3718884071, -4.7328366801],
            [-39.3670182173, -4.7253930976],
            [-39.3664268499, -4.7253906424],
            [-39.3641721452, -4.7319437862],
            [-39.3665573439, -4.7362847202],
            [-39.3597255915, -4.7382819235],
            [-39.3289720414, -4.7165570603],
            [-39.3143074629, -4.7084908938],
            [-39.3060928353, -4.7076854241],
            [-39.2988995957, -4.6961527237],
            [-39.2945608476, -4.6888495926],
            [-39.2924559932, -4.6627910871],
            [-39.2856133315, -4.6589554601],
            [-39.2817538431, -4.6513724027],
            [-39.2548131846, -4.6474998526],
            [-39.2449196379, -4.6427110769],
            [-39.242000757, -4.643559822],
            [-39.2368890337, -4.644220529],
            [-39.2285351593, -4.6521049421],
            [-39.2039169088, -4.6520772883],
            [-39.2003080479, -4.6445270722],
            [-39.1823651288, -4.6502487786],
            [-39.1790912504, -4.6541179762],
            [-39.1564926021, -4.6340383834],
            [-39.153516956, -4.6338273848],
            [-39.1313490712, -4.6284553258],
            [-39.1307780965, -4.6283037123],
            [-39.1294221674, -4.6279742602],
            [-39.1292262028, -4.6279063992],
            [-39.1185605488, -4.6263000142],
            [-39.1119232823, -4.6415546886],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303956',
        name: 'Chorozinho',
        description: 'Chorozinho',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.4949882133, -4.2310864466],
            [-38.4815845162, -4.2384846803],
            [-38.4631989066, -4.2447407098],
            [-38.4516698699, -4.2397527114],
            [-38.443048862, -4.2311203634],
            [-38.4059899615, -4.2315144749],
            [-38.4009758157, -4.2263934778],
            [-38.3933110426, -4.224663429],
            [-38.3923001428, -4.2385407001],
            [-38.3910232495, -4.2560493192],
            [-38.3897508354, -4.2734846139],
            [-38.3866405489, -4.315998727],
            [-38.3853707534, -4.333315809],
            [-38.3970703391, -4.3394349649],
            [-38.3973498527, -4.3395547899],
            [-38.4133059386, -4.3478666275],
            [-38.4516324099, -4.3677722121],
            [-38.4358899018, -4.3854106069],
            [-38.4429330052, -4.4096240129],
            [-38.4613576522, -4.3968404416],
            [-38.4703340411, -4.3968344003],
            [-38.4733734052, -4.3915710171],
            [-38.494111777, -4.389071484],
            [-38.5060424892, -4.3789183519],
            [-38.521688439, -4.3782825197],
            [-38.5241608936, -4.3734084501],
            [-38.5335318724, -4.3681559358],
            [-38.5338873917, -4.3670590751],
            [-38.5393857556, -4.350102863],
            [-38.5481272083, -4.3224726285],
            [-38.5510434382, -4.3033349962],
            [-38.5518927561, -4.297761731],
            [-38.553991017, -4.28398195],
            [-38.5502243903, -4.2732078888],
            [-38.5526977817, -4.266424215],
            [-38.5605735237, -4.2567506919],
            [-38.5620921806, -4.2533675664],
            [-38.5705033138, -4.2346349704],
            [-38.5667785188, -4.2322092799],
            [-38.5543418179, -4.2347956033],
            [-38.5339771608, -4.233021198],
            [-38.5331118763, -4.2332619302],
            [-38.5258497339, -4.2260682516],
            [-38.4949882133, -4.2310864466],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304004',
        name: 'Coreaú',
        description: 'Coreaú',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.7617158958, -3.5048248264],
            [-40.7067213263, -3.5218848981],
            [-40.6913730566, -3.5100917719],
            [-40.681855112, -3.5118111205],
            [-40.6709589442, -3.5122941355],
            [-40.6705556875, -3.520077638],
            [-40.6700558522, -3.5297229581],
            [-40.6513140869, -3.5265546507],
            [-40.647554947, -3.5308504677],
            [-40.6166377788, -3.5309441505],
            [-40.6007007577, -3.5309926697],
            [-40.5589899687, -3.5311171832],
            [-40.5569780073, -3.5386833975],
            [-40.5721267625, -3.5551671389],
            [-40.5706301266, -3.5647283888],
            [-40.5781477315, -3.5665193323],
            [-40.5893433048, -3.5624545178],
            [-40.5999939111, -3.5684853629],
            [-40.6007171226, -3.5730925133],
            [-40.5926925292, -3.5778864207],
            [-40.6086398366, -3.5821300428],
            [-40.6049825683, -3.58917216],
            [-40.6105496465, -3.590414736],
            [-40.6084240513, -3.5986178976],
            [-40.6051834284, -3.606417551],
            [-40.6178539791, -3.6075311097],
            [-40.6090031669, -3.6208692121],
            [-40.5981023827, -3.6170410261],
            [-40.5878883203, -3.6211610042],
            [-40.5934016758, -3.6217847055],
            [-40.5950557558, -3.631298817],
            [-40.5888759946, -3.6323667765],
            [-40.5900232337, -3.6393427036],
            [-40.5807239961, -3.6373722988],
            [-40.5762340634, -3.6442747665],
            [-40.5790964601, -3.6453683034],
            [-40.5775440441, -3.6477812397],
            [-40.5496603092, -3.653794908],
            [-40.5673988869, -3.6528739007],
            [-40.6109401655, -3.6506117425],
            [-40.6134323394, -3.6586397096],
            [-40.6137362402, -3.6596177957],
            [-40.6187276496, -3.6756954747],
            [-40.6419365918, -3.7504975639],
            [-40.65667116, -3.7980281714],
            [-40.6644856464, -3.7986003882],
            [-40.7028280632, -3.8028423042],
            [-40.7112845384, -3.804168211],
            [-40.7149360834, -3.8061589553],
            [-40.7121297888, -3.8107007119],
            [-40.7145430437, -3.8166791971],
            [-40.7113307283, -3.8208571343],
            [-40.7187037458, -3.8227238432],
            [-40.7259546471, -3.8219245042],
            [-40.7399318227, -3.8280157164],
            [-40.7654613034, -3.864085793],
            [-40.7874815113, -3.8396756524],
            [-40.7895184909, -3.8374168022],
            [-40.7932676347, -3.8320703712],
            [-40.7924185387, -3.8227729959],
            [-40.7862719467, -3.8206023793],
            [-40.7829517632, -3.8226499689],
            [-40.775830272, -3.8033247448],
            [-40.774767752, -3.7960677484],
            [-40.7798957402, -3.7948247824],
            [-40.7798631064, -3.7919868291],
            [-40.7793379163, -3.7861772169],
            [-40.7828658886, -3.7800843963],
            [-40.7812838444, -3.7735428481],
            [-40.7863103163, -3.7704522716],
            [-40.779473414, -3.7578170158],
            [-40.7820722492, -3.7504336239],
            [-40.7803406008, -3.7357063077],
            [-40.7704198387, -3.7269091115],
            [-40.7665879063, -3.7201123713],
            [-40.7681145927, -3.7162703363],
            [-40.7630125448, -3.7144955306],
            [-40.7594426103, -3.7076827224],
            [-40.7786247041, -3.6844038517],
            [-40.7932835216, -3.6666104527],
            [-40.7935411564, -3.6662979035],
            [-40.817699766, -3.6369610355],
            [-40.8249927755, -3.6322994875],
            [-40.8306657223, -3.6335900981],
            [-40.8363460979, -3.6420583458],
            [-40.8412698991, -3.6419004622],
            [-40.8478595161, -3.6476215546],
            [-40.8607279477, -3.6481834662],
            [-40.8628102865, -3.6419558094],
            [-40.8675378253, -3.6417984938],
            [-40.871839555, -3.6478653828],
            [-40.8706308466, -3.6527195548],
            [-40.8878788496, -3.6621154344],
            [-40.8903912653, -3.6675334597],
            [-40.8954596057, -3.6315136991],
            [-40.8967267582, -3.6225052477],
            [-40.9034638957, -3.5745457303],
            [-40.8928658997, -3.559225388],
            [-40.8595340682, -3.5376324126],
            [-40.8568282733, -3.5358792817],
            [-40.8347597577, -3.5290206876],
            [-40.8226694977, -3.5252629285],
            [-40.7617158958, -3.5048248264],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304103',
        name: 'Crateús',
        description: 'Crateús',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.594371473, -4.9502484507],
            [-40.5666104143, -5.0185558848],
            [-40.5562534606, -5.0140553435],
            [-40.5380344351, -5.0130628247],
            [-40.5207433751, -5.0215739885],
            [-40.5056733469, -5.023702686],
            [-40.4952176107, -5.0354091438],
            [-40.4625601681, -5.0456350265],
            [-40.4512576869, -5.043960078],
            [-40.4490495396, -5.0350766266],
            [-40.4461765215, -5.0342766832],
            [-40.4317697224, -5.0507291029],
            [-40.4220441874, -5.0642149678],
            [-40.421243634, -5.0710224523],
            [-40.4037367764, -5.0831789555],
            [-40.3996141782, -5.0923439655],
            [-40.3741412445, -5.1026353245],
            [-40.3769476958, -5.1105565049],
            [-40.3728808779, -5.115091517],
            [-40.3729243246, -5.1152102884],
            [-40.3906753546, -5.164110105],
            [-40.3915980425, -5.1666523805],
            [-40.4141967763, -5.2289463476],
            [-40.479092614, -5.2738941182],
            [-40.4810756953, -5.2792233993],
            [-40.4868957714, -5.2948684037],
            [-40.5234785107, -5.3893259423],
            [-40.539185464, -5.389086791],
            [-40.5484316361, -5.3984170698],
            [-40.5543385102, -5.4263034406],
            [-40.6421380631, -5.4262841588],
            [-40.6456968311, -5.4262832195],
            [-40.6504211528, -5.4346858664],
            [-40.6492925289, -5.4387284072],
            [-40.6566518626, -5.4411917118],
            [-40.6606655975, -5.4479476748],
            [-40.6841785855, -5.4496452838],
            [-40.6815648956, -5.4663922073],
            [-40.6911956783, -5.4711707416],
            [-40.6912164579, -5.4652698352],
            [-40.6948775554, -5.4624518875],
            [-40.7080644676, -5.4670013833],
            [-40.715449287, -5.4674989146],
            [-40.7242633763, -5.4618572808],
            [-40.7308427858, -5.4671999303],
            [-40.7316663967, -5.4858345579],
            [-40.7622379938, -5.4800725905],
            [-40.7718306048, -5.4859770797],
            [-40.7719924359, -5.4946547894],
            [-40.7845777634, -5.4933420304],
            [-40.7935510882, -5.4967198999],
            [-40.7980616667, -5.4919257511],
            [-40.8069435762, -5.489551761],
            [-40.8119282711, -5.4884887],
            [-40.81489153, -5.479967502],
            [-40.8266857165, -5.4834265278],
            [-40.8451454123, -5.4792129674],
            [-40.8522120632, -5.4810784488],
            [-40.8584727765, -5.4898710419],
            [-40.8652380069, -5.4932366081],
            [-40.8736364188, -5.4933090175],
            [-40.8903549817, -5.4848318208],
            [-40.8927096577, -5.4798831907],
            [-40.9000703233, -5.4780206663],
            [-40.8995925285, -5.4734715774],
            [-40.9081686922, -5.4734928979],
            [-40.918140225, -5.4735173401],
            [-40.9154415656, -5.4657920757],
            [-40.920829707, -5.450456835],
            [-40.9366675938, -5.4381521494],
            [-40.9413923125, -5.4305815351],
            [-40.942089085, -5.419317281],
            [-40.9378193873, -5.4159645517],
            [-40.9173271743, -5.415901932],
            [-40.9111774179, -5.4069878515],
            [-40.9145276358, -5.3927551004],
            [-40.9286165553, -5.3754289991],
            [-40.93573106, -5.3655790584],
            [-40.9333501583, -5.3490159876],
            [-40.918080937, -5.3378669303],
            [-40.9131412159, -5.3190907432],
            [-40.9123615347, -5.3085869866],
            [-40.9128334315, -5.3076631837],
            [-40.9230873708, -5.3007314327],
            [-40.9263853939, -5.2916591437],
            [-40.9215723248, -5.2839698949],
            [-40.9238713348, -5.2744494318],
            [-40.9192702641, -5.2707733391],
            [-40.9114866463, -5.2705370922],
            [-40.9108353083, -5.2675653393],
            [-40.9326935034, -5.24483928],
            [-40.9407312245, -5.2415770042],
            [-40.9400259192, -5.2373793628],
            [-40.9336299426, -5.2363941275],
            [-40.9262504179, -5.231698601],
            [-40.9216560877, -5.2260637048],
            [-40.9316268468, -5.2008764568],
            [-40.9249183121, -5.1817096759],
            [-40.9286157374, -5.1712845888],
            [-40.9373741958, -5.166201789],
            [-40.9489480976, -5.1664894638],
            [-40.9581215863, -5.1541324119],
            [-40.9680472037, -5.1489734956],
            [-40.9820899764, -5.15313592],
            [-40.9882311686, -5.1383599756],
            [-41.0114632077, -5.1232145722],
            [-41.0137025024, -5.1054189625],
            [-41.0260324182, -5.1093104155],
            [-41.0336282131, -5.090677887],
            [-41.0398935012, -5.0909975558],
            [-41.0520407819, -5.1030165149],
            [-41.0797518817, -5.0907062025],
            [-41.080059187, -5.078304952],
            [-41.0869744516, -5.072929457],
            [-41.0877947836, -5.065227079],
            [-41.0954908399, -5.0612308266],
            [-41.0966318696, -5.0527625251],
            [-41.1315022114, -5.0476701052],
            [-41.1208526023, -5.0235607779],
            [-41.0514930258, -5.0236693105],
            [-41.0310445623, -5.0196257114],
            [-41.0307615761, -5.0195699621],
            [-40.8752328114, -4.9888169294],
            [-40.8649024481, -4.9867746161],
            [-40.8264412231, -4.9787872086],
            [-40.7150651975, -4.9477488653],
            [-40.7148185973, -4.9476797483],
            [-40.6518992559, -4.9854015222],
            [-40.6354497739, -4.9243361364],
            [-40.594371473, -4.9502484507],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304202',
        name: 'Crato',
        description: 'Crato',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.5045866813, -7.0075612778],
            [-39.5026263848, -7.0111458589],
            [-39.4935448749, -7.0097026978],
            [-39.4933790911, -7.0096766931],
            [-39.4836612118, -7.0051922537],
            [-39.476240532, -7.01167793],
            [-39.4677921735, -7.0131754197],
            [-39.4535004656, -7.0077654973],
            [-39.4295221659, -7.045984067],
            [-39.4273094373, -7.0481559411],
            [-39.4248580695, -7.0488383148],
            [-39.4258774001, -7.0450745513],
            [-39.4205015789, -7.0443818281],
            [-39.4159790517, -7.0482464263],
            [-39.4215830344, -7.050954308],
            [-39.4223552338, -7.0551280566],
            [-39.4117575121, -7.0578715217],
            [-39.4074526005, -7.0550932573],
            [-39.397773719, -7.0571646781],
            [-39.3983355644, -7.0537583056],
            [-39.3875297303, -7.0559276773],
            [-39.3800982435, -7.0507980133],
            [-39.3778982458, -7.0534384119],
            [-39.3617288273, -7.0548965518],
            [-39.3511208246, -7.0561997163],
            [-39.3571652446, -7.074372079],
            [-39.3493073872, -7.0825167852],
            [-39.3495577107, -7.0893251839],
            [-39.336915728, -7.0999452041],
            [-39.3425417767, -7.1095108279],
            [-39.3372378966, -7.1169758868],
            [-39.3443660926, -7.1340950479],
            [-39.3432560799, -7.140126398],
            [-39.3665108541, -7.1609335497],
            [-39.3665549565, -7.1804916188],
            [-39.3676912085, -7.2103440984],
            [-39.3570909739, -7.2259056448],
            [-39.3567735309, -7.2278718926],
            [-39.355754018, -7.2341838953],
            [-39.354028509, -7.2448650941],
            [-39.3495365762, -7.2726546843],
            [-39.3502810192, -7.2731836324],
            [-39.3747659438, -7.2905981524],
            [-39.3889184057, -7.300665107],
            [-39.3912155019, -7.3022990058],
            [-39.448907285, -7.3433421341],
            [-39.4488090633, -7.3549376985],
            [-39.4479384486, -7.45470261],
            [-39.4479198537, -7.4568122632],
            [-39.4468580322, -7.5718856784],
            [-39.4720923702, -7.574775751],
            [-39.4761655043, -7.5529215992],
            [-39.4846526215, -7.5379806289],
            [-39.4821691765, -7.5296451855],
            [-39.4693272127, -7.5201627863],
            [-39.4553164084, -7.5014552002],
            [-39.4581317434, -7.4738048968],
            [-39.4674954339, -7.4691851403],
            [-39.4751506647, -7.4722649383],
            [-39.4893711736, -7.4720177805],
            [-39.5019991024, -7.4637430426],
            [-39.5428872566, -7.484355029],
            [-39.5454909752, -7.4835362193],
            [-39.5524660214, -7.4802513572],
            [-39.5570929154, -7.4721394412],
            [-39.5656371659, -7.4485206321],
            [-39.5709125208, -7.43034076],
            [-39.5761718363, -7.427489954],
            [-39.5927156941, -7.4319462147],
            [-39.5986407538, -7.4283684621],
            [-39.5987035134, -7.3435046086],
            [-39.5987043059, -7.3419361299],
            [-39.5987400167, -7.2278520493],
            [-39.5987401406, -7.2095323105],
            [-39.598745364, -7.1696054481],
            [-39.598756196, -7.1291668298],
            [-39.5987527022, -7.1288219859],
            [-39.5987514877, -7.0800728948],
            [-39.5951551991, -7.0811920964],
            [-39.5882466285, -7.0749234964],
            [-39.5840577147, -7.0771414399],
            [-39.5781056866, -7.0745269045],
            [-39.5811920496, -7.0578701064],
            [-39.5787527374, -7.0556559952],
            [-39.5838900421, -7.0486270648],
            [-39.5800364266, -7.0435657308],
            [-39.5811100759, -7.0219559369],
            [-39.5776448928, -7.0189582027],
            [-39.5651410676, -7.0195071707],
            [-39.560330488, -7.0154432994],
            [-39.5166120636, -7.0029365479],
            [-39.5045866813, -7.0075612778],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304236',
        name: 'Croatá',
        description: 'Croatá',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.8904185058, -4.3138397549],
            [-40.8843237381, -4.3178432445],
            [-40.8778056947, -4.3139419242],
            [-40.8758934868, -4.3227209784],
            [-40.8698937559, -4.3234427446],
            [-40.858734076, -4.3151580707],
            [-40.8697904124, -4.3049701811],
            [-40.85265971, -4.2969454744],
            [-40.8392795259, -4.3129763185],
            [-40.8462838161, -4.320892404],
            [-40.8412100554, -4.3238351856],
            [-40.820012393, -4.3403527862],
            [-40.802751039, -4.3447688415],
            [-40.8039356129, -4.3533318236],
            [-40.8004884533, -4.3621036467],
            [-40.7990325462, -4.3632090797],
            [-40.7840565519, -4.3599524566],
            [-40.7768968236, -4.3617281477],
            [-40.769733147, -4.3484227215],
            [-40.7667014149, -4.3480043461],
            [-40.7643294977, -4.3598835334],
            [-40.7746486126, -4.3674501696],
            [-40.7704795672, -4.3810666818],
            [-40.7827617212, -4.3875325756],
            [-40.7895233271, -4.4059858219],
            [-40.7867941358, -4.4058222552],
            [-40.7783839438, -4.4074895814],
            [-40.7845547013, -4.4118976024],
            [-40.7853815457, -4.4186243161],
            [-40.7936203727, -4.4232289709],
            [-40.7886286922, -4.4305392029],
            [-40.7971663945, -4.4410375126],
            [-40.8109526479, -4.4430991522],
            [-40.8251662925, -4.4585439789],
            [-40.852965156, -4.465527712],
            [-40.864440677, -4.4638351473],
            [-40.8830453803, -4.4690175805],
            [-40.8885267556, -4.4719344423],
            [-40.9185552996, -4.4685195812],
            [-40.9208361221, -4.4692221342],
            [-41.0053746645, -4.4591378584],
            [-41.0274686131, -4.4565002899],
            [-41.0627637344, -4.4737779085],
            [-41.1199487671, -4.4785505693],
            [-41.1257154517, -4.4830084306],
            [-41.1458661214, -4.4697462124],
            [-41.1618680622, -4.4662905604],
            [-41.1771980797, -4.467296141],
            [-41.1761639685, -4.4466107227],
            [-41.1767610732, -4.4408856997],
            [-41.1593170121, -4.4101739988],
            [-41.160328797, -4.3889167861],
            [-41.1596379186, -4.3811164966],
            [-41.1505006352, -4.3702837599],
            [-41.1488301455, -4.3669491937],
            [-41.140196855, -4.3698863075],
            [-41.0069649897, -4.2721838684],
            [-40.982009066, -4.2777097355],
            [-40.9775939067, -4.2786874152],
            [-40.9268185235, -4.2899247813],
            [-40.8904759119, -4.3138028233],
            [-40.8904185058, -4.3138397549],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304251',
        name: 'Cruz',
        description: 'Cruz',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.4291293537, -2.810732287],
            [-40.3910053506, -2.8137040694],
            [-40.3718928389, -2.8099085373],
            [-40.3621740049, -2.8653689084],
            [-40.3310045769, -2.8647157733],
            [-40.3225405113, -2.8611299881],
            [-40.3220162367, -2.8613428138],
            [-40.2848428969, -2.8773478186],
            [-40.2756646922, -2.8784276354],
            [-40.2728438223, -2.8784296326],
            [-40.2728580504, -2.8695792974],
            [-40.2333970116, -2.8709882511],
            [-40.2207571071, -2.8723995675],
            [-40.1996511674, -2.8747565279],
            [-40.1547116195, -2.8825382515],
            [-40.1484097894, -2.8904694714],
            [-40.1502584201, -2.9058456104],
            [-40.1612782727, -2.9174353887],
            [-40.1642972309, -2.9161272496],
            [-40.1664525212, -2.9171381286],
            [-40.1645271972, -2.9235803952],
            [-40.1702278651, -2.9317620794],
            [-40.1703483132, -2.9426709483],
            [-40.1862253076, -2.9437637728],
            [-40.1927190097, -2.9435663647],
            [-40.2033158694, -2.9447103616],
            [-40.2375282997, -2.9515054584],
            [-40.2654492531, -2.9393855521],
            [-40.2834965212, -2.940727491],
            [-40.2836321588, -2.9407431849],
            [-40.3217822675, -2.9435736472],
            [-40.3244271545, -2.9481889314],
            [-40.3355944802, -2.945642776],
            [-40.3408422624, -2.9450109716],
            [-40.3422799694, -2.9495558328],
            [-40.3477878348, -2.9508714131],
            [-40.358005835, -2.9523314391],
            [-40.3574439082, -2.9627101849],
            [-40.3833366437, -2.9559592767],
            [-40.3831040724, -2.9695821501],
            [-40.3852671284, -2.9693285114],
            [-40.4065057161, -2.9668388417],
            [-40.4120640192, -2.9506105703],
            [-40.4306945091, -2.9437877897],
            [-40.4354587092, -2.9314844009],
            [-40.4307590157, -2.914173763],
            [-40.4355722268, -2.9003644394],
            [-40.4434953504, -2.8941173706],
            [-40.4543144127, -2.8918368172],
            [-40.4549240823, -2.8962416579],
            [-40.4587538156, -2.8943421787],
            [-40.4560070475, -2.8902193926],
            [-40.4537327629, -2.8787547999],
            [-40.4575690928, -2.8667681652],
            [-40.452254662, -2.8572375101],
            [-40.4573118488, -2.8388452861],
            [-40.4571823736, -2.8384620026],
            [-40.4570018229, -2.8222404522],
            [-40.4598050817, -2.8173827993],
            [-40.4597936437, -2.8170554084],
            [-40.4595358798, -2.8097524602],
            [-40.4546403162, -2.8038371989],
            [-40.4291293537, -2.810732287],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304269',
        name: 'Deputado Irapuan Pinheiro',
        description: 'Deputado Irapuan Pinheiro',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.2825531584, -5.7559400372],
            [-39.2784970062, -5.7618507881],
            [-39.2740012067, -5.7613687293],
            [-39.2682495455, -5.7638537041],
            [-39.2544556938, -5.7570736708],
            [-39.2373434638, -5.7695409985],
            [-39.2334735462, -5.769365858],
            [-39.2290930259, -5.7636345782],
            [-39.2217906715, -5.7667231481],
            [-39.2213880791, -5.7743916084],
            [-39.2093530194, -5.7819288173],
            [-39.2088480245, -5.7875779787],
            [-39.1848601557, -5.7909035847],
            [-39.1796967476, -5.7998736009],
            [-39.1627775929, -5.7973105943],
            [-39.175206132, -5.8365320745],
            [-39.1773368324, -5.843258686],
            [-39.1820358801, -5.8591921548],
            [-39.1801498729, -5.8599921583],
            [-39.1729996398, -5.8630234947],
            [-39.1702633189, -5.8679797744],
            [-39.1744656109, -5.8722920466],
            [-39.1729629916, -5.9000085343],
            [-39.1796286871, -5.9168018996],
            [-39.1782605725, -5.9261632343],
            [-39.1750507539, -5.9286441901],
            [-39.1768970226, -5.945253922],
            [-39.1724116214, -5.9492027565],
            [-39.1708442834, -5.9590819502],
            [-39.1711155148, -5.961346654],
            [-39.1811161998, -5.9641143329],
            [-39.197667375, -5.9568233505],
            [-39.2032495764, -5.9862465502],
            [-39.2135402937, -5.9913586911],
            [-39.2142102381, -5.9912448483],
            [-39.229394891, -5.9886552483],
            [-39.234949123, -6.0005502452],
            [-39.2391531911, -6.0005566864],
            [-39.2386049736, -5.9956833681],
            [-39.2570815614, -5.99821954],
            [-39.2568351952, -5.9952128927],
            [-39.2722841283, -5.9994922989],
            [-39.2785520718, -6.0051906164],
            [-39.2940201559, -5.9985288363],
            [-39.2979122786, -5.9901533192],
            [-39.3070599699, -5.9956295876],
            [-39.3188343535, -6.0058283334],
            [-39.3269751788, -6.0019718382],
            [-39.3379184556, -6.0017942427],
            [-39.3459161643, -6.0097197816],
            [-39.3484777619, -5.9936307638],
            [-39.3558360415, -5.9898867416],
            [-39.3644430936, -5.9923101506],
            [-39.3660927334, -5.9859737151],
            [-39.364210179, -5.9821034361],
            [-39.3635315864, -5.9807086555],
            [-39.35175133, -5.9521712553],
            [-39.3626177358, -5.9522893747],
            [-39.3672403193, -5.9464539821],
            [-39.3565260019, -5.9255313588],
            [-39.3602678795, -5.9242928156],
            [-39.3593497033, -5.9201744688],
            [-39.3654629739, -5.9139254487],
            [-39.3754095105, -5.9103522618],
            [-39.3683503042, -5.9067527248],
            [-39.3570400907, -5.896774843],
            [-39.3573447867, -5.8815405654],
            [-39.3491713071, -5.8782299192],
            [-39.3493201044, -5.8650763494],
            [-39.3548962171, -5.8626718101],
            [-39.3511177799, -5.8622607242],
            [-39.342461107, -5.8508765229],
            [-39.3301552322, -5.8474965813],
            [-39.3236726404, -5.8441607221],
            [-39.3243740446, -5.8357211838],
            [-39.343440366, -5.832344563],
            [-39.3433151419, -5.8293349126],
            [-39.3416936367, -5.8188738981],
            [-39.343851349, -5.8155327422],
            [-39.3412346049, -5.8077116551],
            [-39.3274914806, -5.7912496973],
            [-39.3295364796, -5.7873363629],
            [-39.3266395195, -5.7850222481],
            [-39.3090259598, -5.7794614296],
            [-39.3072812266, -5.771325533],
            [-39.3037503202, -5.7725781455],
            [-39.2825531584, -5.7559400372],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304277',
        name: 'Ererê',
        description: 'Ererê',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.2869858928, -5.8946147536],
            [-38.2734327328, -5.9094687674],
            [-38.2597947725, -5.9244099054],
            [-38.1596587799, -5.924564485],
            [-38.1650884442, -5.9317093621],
            [-38.1631413637, -5.9385011623],
            [-38.1644435557, -5.9461373069],
            [-38.1750824086, -5.9530067944],
            [-38.1832331862, -5.9498285726],
            [-38.1920197819, -5.9591697196],
            [-38.2056664853, -5.9639529691],
            [-38.2176746616, -5.9766027796],
            [-38.2296839936, -5.9864720271],
            [-38.2441541322, -5.9990624092],
            [-38.2518223456, -6.009762477],
            [-38.2522195309, -6.0427068018],
            [-38.2598177338, -6.0493202978],
            [-38.2588341467, -6.0533845588],
            [-38.2646246961, -6.0538524768],
            [-38.2696839936, -6.0622736935],
            [-38.2740465008, -6.063389771],
            [-38.2745506203, -6.0654032512],
            [-38.2766594194, -6.069556105],
            [-38.2904392376, -6.0727558361],
            [-38.3044522049, -6.0865717481],
            [-38.3195712577, -6.0766432763],
            [-38.336409461, -6.0800017858],
            [-38.3392370301, -6.0772958649],
            [-38.3464325765, -6.0668817637],
            [-38.34701395, -6.0803059759],
            [-38.3500727972, -6.0828476767],
            [-38.3648951078, -6.0880637487],
            [-38.3709690283, -6.084011349],
            [-38.3794224076, -6.0729213527],
            [-38.3780115682, -6.0664353415],
            [-38.3853408492, -6.06428378],
            [-38.3816045364, -6.052393321],
            [-38.3946417262, -6.0550154523],
            [-38.401185643, -6.053099612],
            [-38.4045270946, -6.0568940832],
            [-38.4110450793, -6.0421606053],
            [-38.424048962, -6.0119423365],
            [-38.4210784121, -6.0082964158],
            [-38.4166549402, -6.0088963595],
            [-38.4166575625, -6.0054570496],
            [-38.4077088125, -5.9987077271],
            [-38.3972000625, -5.9947051106],
            [-38.4000684351, -5.9749475162],
            [-38.403049832, -5.9543951636],
            [-38.4082124109, -5.9187716266],
            [-38.4112516724, -5.8977789531],
            [-38.4056553899, -5.9010477323],
            [-38.4067102562, -5.9083419029],
            [-38.4015320472, -5.917554084],
            [-38.3892864535, -5.9145141445],
            [-38.3856031662, -5.9167120419],
            [-38.3758085072, -5.9110709947],
            [-38.3722952043, -5.9118820171],
            [-38.363453944, -5.9055431795],
            [-38.3563193165, -5.8928775403],
            [-38.3470440773, -5.8879025467],
            [-38.3437253704, -5.8817152746],
            [-38.3162029992, -5.8692977524],
            [-38.3141371643, -5.8648458342],
            [-38.2869858928, -5.8946147536],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304285',
        name: 'Eusébio',
        description: 'Eusébio',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.4467600908, -3.9106197796],
            [-38.4491588523, -3.9204463271],
            [-38.4533940054, -3.9215500211],
            [-38.4537884382, -3.9213128669],
            [-38.4620807321, -3.9146951859],
            [-38.4708276632, -3.926800889],
            [-38.4788423597, -3.9284367858],
            [-38.4856709736, -3.9242680644],
            [-38.4952979324, -3.934982028],
            [-38.5086357112, -3.9367287691],
            [-38.5080440177, -3.9325422481],
            [-38.5075134234, -3.9287878717],
            [-38.5071726938, -3.9263736133],
            [-38.505596281, -3.9152243423],
            [-38.5043134473, -3.9061544194],
            [-38.5021046677, -3.8905392704],
            [-38.501823962, -3.8885528556],
            [-38.4994411658, -3.8881205009],
            [-38.4948821317, -3.8848793978],
            [-38.4910365545, -3.8729911874],
            [-38.4865049284, -3.8624697079],
            [-38.4773574512, -3.8617584041],
            [-38.4740894565, -3.854550638],
            [-38.4667910697, -3.8456747947],
            [-38.4640472172, -3.8420385458],
            [-38.4504462426, -3.8385079281],
            [-38.4482502042, -3.8335392045],
            [-38.4450748244, -3.8273775791],
            [-38.4468056799, -3.8201027676],
            [-38.4407566408, -3.8150171595],
            [-38.446053083, -3.8047875599],
            [-38.4299269608, -3.8100880356],
            [-38.4232888916, -3.8112635732],
            [-38.4195211886, -3.8217619987],
            [-38.4174463866, -3.827609063],
            [-38.4224759926, -3.8443192631],
            [-38.417451746, -3.846281829],
            [-38.4138041247, -3.8563175531],
            [-38.4133808447, -3.8671105612],
            [-38.4155392383, -3.8792691039],
            [-38.4186091018, -3.8812347142],
            [-38.4332008536, -3.8863774451],
            [-38.4337334536, -3.8923912991],
            [-38.433964037, -3.8928672894],
            [-38.4437663403, -3.9030506856],
            [-38.4467600908, -3.9106197796],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304301',
        name: 'Farias Brito',
        description: 'Farias Brito',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.5442521234, -6.7688505743],
            [-39.5149091014, -6.7768730831],
            [-39.5119946049, -6.7776287953],
            [-39.5136564698, -6.7832858622],
            [-39.509107412, -6.7916574197],
            [-39.511781607, -6.8073702904],
            [-39.5153900629, -6.8076951756],
            [-39.5078215301, -6.8163301593],
            [-39.5062246987, -6.8271270736],
            [-39.5005548497, -6.8367580897],
            [-39.5029624128, -6.8436655742],
            [-39.4935523762, -6.8464644894],
            [-39.4843902999, -6.8384357252],
            [-39.4805443625, -6.8507541563],
            [-39.4754434423, -6.859168269],
            [-39.4743910256, -6.8606505637],
            [-39.4741942413, -6.8672383695],
            [-39.4791403007, -6.8679631106],
            [-39.4857180244, -6.875974915],
            [-39.4844410485, -6.8809286137],
            [-39.4776402068, -6.8854198346],
            [-39.4828905074, -6.8974478775],
            [-39.4609337846, -6.8966117096],
            [-39.453459071, -6.896327034],
            [-39.4182013768, -6.89498237],
            [-39.3948144904, -6.9230508222],
            [-39.3980250074, -6.9277072339],
            [-39.4182093638, -6.9569876286],
            [-39.4191600069, -6.9583658249],
            [-39.4224169786, -6.9630919478],
            [-39.4357109277, -6.9635975815],
            [-39.4535004656, -7.0077654973],
            [-39.4677921735, -7.0131754197],
            [-39.476240532, -7.01167793],
            [-39.4836612118, -7.0051922537],
            [-39.4933790911, -7.0096766931],
            [-39.4935448749, -7.0097026978],
            [-39.5026263848, -7.0111458589],
            [-39.5045866813, -7.0075612778],
            [-39.5166120636, -7.0029365479],
            [-39.560330488, -7.0154432994],
            [-39.5651410676, -7.0195071707],
            [-39.5776448928, -7.0189582027],
            [-39.5811100759, -7.0219559369],
            [-39.5800364266, -7.0435657308],
            [-39.6105655111, -7.0314694544],
            [-39.610644358, -7.0314384691],
            [-39.631573452, -7.0231430049],
            [-39.6286379733, -7.0146706832],
            [-39.6438642871, -7.0147575351],
            [-39.6440930352, -7.0195730186],
            [-39.6563967349, -7.0190194093],
            [-39.6722926657, -7.023704005],
            [-39.6805053624, -7.0173812436],
            [-39.6722171907, -7.0088335182],
            [-39.6559903158, -7.0048964237],
            [-39.6629005171, -6.995545943],
            [-39.6632972391, -6.9948434549],
            [-39.6639182506, -6.9922585308],
            [-39.6452128566, -6.9911075656],
            [-39.6363745323, -6.9833101952],
            [-39.6333195077, -6.9683340317],
            [-39.6205687297, -6.9631085053],
            [-39.6207841376, -6.9594699075],
            [-39.639800652, -6.9459632481],
            [-39.6349322689, -6.9351286343],
            [-39.6296315444, -6.9326763619],
            [-39.6246714429, -6.9368573978],
            [-39.6203109329, -6.9345072602],
            [-39.611850598, -6.9213550191],
            [-39.6017518914, -6.9145466247],
            [-39.5991142239, -6.9110238046],
            [-39.6002437979, -6.9031747639],
            [-39.5959882229, -6.8983662095],
            [-39.6118140584, -6.8923527983],
            [-39.6300313368, -6.9008914626],
            [-39.6337175291, -6.893179944],
            [-39.6507643555, -6.8931584882],
            [-39.6549060562, -6.8946513489],
            [-39.6629228353, -6.9070533594],
            [-39.6655513987, -6.8899015103],
            [-39.6622810207, -6.8820116843],
            [-39.6677854566, -6.8732283529],
            [-39.6680478165, -6.8629546121],
            [-39.6416086453, -6.8529321995],
            [-39.6367713737, -6.8389747718],
            [-39.6436177713, -6.8284206513],
            [-39.6529346211, -6.8256269156],
            [-39.6543713218, -6.8203437927],
            [-39.6654194874, -6.7928844475],
            [-39.6566929496, -6.7916995874],
            [-39.657404931, -6.7968840856],
            [-39.6513304931, -6.8020805274],
            [-39.6312520519, -6.7924766485],
            [-39.6237660251, -6.7972720079],
            [-39.6164840407, -6.7899675204],
            [-39.6092061907, -6.7915395737],
            [-39.6103872364, -6.7977409991],
            [-39.596660424, -6.8131229755],
            [-39.5998997636, -6.8248150542],
            [-39.5952275257, -6.8317265883],
            [-39.5894073802, -6.8307614169],
            [-39.5870828926, -6.8493069105],
            [-39.5751942186, -6.8467903552],
            [-39.5751793476, -6.8467144958],
            [-39.5743801523, -6.8426690817],
            [-39.570180152, -6.8460708839],
            [-39.5706603408, -6.8421890486],
            [-39.5671029282, -6.8424346412],
            [-39.5669352787, -6.8374219537],
            [-39.5785711492, -6.8319880919],
            [-39.5733839598, -6.8202692873],
            [-39.5749971601, -6.8176796294],
            [-39.5675584863, -6.8163134263],
            [-39.568969321, -6.7898663953],
            [-39.5590040761, -6.789394489],
            [-39.5562769643, -6.7763443819],
            [-39.5490821871, -6.7775680687],
            [-39.5442521234, -6.7688505743],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304350',
        name: 'Forquilha',
        description: 'Forquilha',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.1258971677, -3.7156275314],
            [-40.1239075037, -3.7727216642],
            [-40.1302385157, -3.7881927285],
            [-40.1343964641, -3.805739302],
            [-40.1210393478, -3.8085320245],
            [-40.1211111878, -3.8202678279],
            [-40.1242474845, -3.8243246001],
            [-40.1265704401, -3.8479313099],
            [-40.1298463249, -3.8558910298],
            [-40.1359207202, -3.8564409012],
            [-40.139922556, -3.8745411081],
            [-40.1350837857, -3.8858926889],
            [-40.1388910649, -3.897021446],
            [-40.147092746, -3.8976204791],
            [-40.1495164727, -3.9022857967],
            [-40.1475747383, -3.9112930448],
            [-40.153330847, -3.9173689561],
            [-40.156746528, -3.9240813612],
            [-40.1665587126, -3.9306266244],
            [-40.1669712334, -3.9334620912],
            [-40.1676065397, -3.9368635947],
            [-40.1721575974, -3.9373634222],
            [-40.1727068208, -3.9444299868],
            [-40.1885554853, -3.9573677385],
            [-40.2152549822, -3.951947894],
            [-40.226683403, -3.9496276481],
            [-40.3051610132, -3.9336827585],
            [-40.3153537517, -3.8874560824],
            [-40.3205105517, -3.8640472942],
            [-40.3245174755, -3.8615715945],
            [-40.337239325, -3.8635502225],
            [-40.3418055533, -3.8702859698],
            [-40.3516287515, -3.8733213262],
            [-40.3659235745, -3.8697403483],
            [-40.3707457971, -3.8614246383],
            [-40.359753653, -3.8366144177],
            [-40.3611585662, -3.8288459634],
            [-40.3563601311, -3.8037634674],
            [-40.3461741368, -3.7796484849],
            [-40.3171486752, -3.7897437998],
            [-40.3092897848, -3.7888119209],
            [-40.3064881746, -3.7849658803],
            [-40.2968771787, -3.7861146163],
            [-40.3020852381, -3.7741043371],
            [-40.3081521255, -3.774201956],
            [-40.3112958783, -3.7701000289],
            [-40.3110902984, -3.7638996918],
            [-40.3018684958, -3.7565670127],
            [-40.2828535465, -3.7700439256],
            [-40.2876425642, -3.7538242275],
            [-40.2862904009, -3.7444594781],
            [-40.2831182938, -3.7429419388],
            [-40.2777077239, -3.7428593454],
            [-40.2702726228, -3.7626722903],
            [-40.2608519194, -3.7630313069],
            [-40.2605886015, -3.7677339743],
            [-40.2513113283, -3.7654260353],
            [-40.2500052177, -3.7720446349],
            [-40.2403376683, -3.7744816657],
            [-40.2331289504, -3.7721500012],
            [-40.2187234479, -3.7592143925],
            [-40.2224580752, -3.7561752905],
            [-40.2199836684, -3.7488076166],
            [-40.2221682036, -3.742009851],
            [-40.2277213584, -3.7372042667],
            [-40.2278940095, -3.7305292772],
            [-40.23263993, -3.7345343797],
            [-40.2416401041, -3.7323424404],
            [-40.2428784342, -3.7216300746],
            [-40.2364979664, -3.7141677156],
            [-40.239082595, -3.6993761576],
            [-40.211218777, -3.701149698],
            [-40.1262116739, -3.7065536144],
            [-40.1258971677, -3.7156275314],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304400',
        name: 'Fortaleza',
        description: 'Fortaleza',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5685052493, -3.7008904176],
            [-38.5569817953, -3.7055761929],
            [-38.5469073937, -3.7119944933],
            [-38.5415241129, -3.7148877377],
            [-38.5260888009, -3.7199844936],
            [-38.5195349779, -3.717935259],
            [-38.5109320411, -3.7197121369],
            [-38.4989230088, -3.7248595918],
            [-38.4894530208, -3.723365186],
            [-38.4769232101, -3.7191370064],
            [-38.4767336794, -3.7137844181],
            [-38.4725835643, -3.7079746384],
            [-38.4773130995, -3.7008055665],
            [-38.4662993502, -3.7136069242],
            [-38.4352958562, -3.7704954051],
            [-38.4304404975, -3.7779706429],
            [-38.4015064515, -3.8233859332],
            [-38.4018389408, -3.8267754634],
            [-38.412892352, -3.8153174414],
            [-38.4126651681, -3.8213241486],
            [-38.4195211886, -3.8217619987],
            [-38.4232888916, -3.8112635732],
            [-38.4299269608, -3.8100880356],
            [-38.446053083, -3.8047875599],
            [-38.4407566408, -3.8150171595],
            [-38.4468056799, -3.8201027676],
            [-38.4450748244, -3.8273775791],
            [-38.4482502042, -3.8335392045],
            [-38.4504462426, -3.8385079281],
            [-38.4640472172, -3.8420385458],
            [-38.4667910697, -3.8456747947],
            [-38.4740894565, -3.854550638],
            [-38.4773574512, -3.8617584041],
            [-38.4865049284, -3.8624697079],
            [-38.4910365545, -3.8729911874],
            [-38.4948821317, -3.8848793978],
            [-38.4994411658, -3.8881205009],
            [-38.501823962, -3.8885528556],
            [-38.5231023737, -3.8808839851],
            [-38.5410090395, -3.8608667829],
            [-38.5418062855, -3.8599750159],
            [-38.5418531974, -3.8599217377],
            [-38.5835422519, -3.8389986138],
            [-38.5957059818, -3.8325039559],
            [-38.6066653857, -3.8266508926],
            [-38.6362380791, -3.8002504984],
            [-38.6239317875, -3.7792531928],
            [-38.6143007358, -3.7628246121],
            [-38.6108866868, -3.7556931294],
            [-38.6013945068, -3.7487900236],
            [-38.6007838875, -3.7403477696],
            [-38.6095380621, -3.7250960532],
            [-38.6209728301, -3.7221818198],
            [-38.6202345036, -3.7166567531],
            [-38.6183660102, -3.7113928947],
            [-38.6225008644, -3.7040844419],
            [-38.6002849656, -3.7043225582],
            [-38.5917595555, -3.7014933091],
            [-38.5889721562, -3.6960497994],
            [-38.5928119741, -3.691155826],
            [-38.5795847339, -3.6938051457],
            [-38.5685052493, -3.7008904176],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304459',
        name: 'Fortim',
        description: 'Fortim',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.835311246, -4.3821323267],
            [-37.806987181, -4.398112147],
            [-37.7961207369, -4.395605224],
            [-37.7855647293, -4.3994961074],
            [-37.7778941836, -4.4031382845],
            [-37.7697499308, -4.4008881563],
            [-37.7634505086, -4.4228407442],
            [-37.7691748313, -4.4259868168],
            [-37.7762576091, -4.4310505417],
            [-37.778860473, -4.4339405474],
            [-37.7855179697, -4.4426961736],
            [-37.7762792128, -4.4571462706],
            [-37.7715312901, -4.4519503126],
            [-37.7692408439, -4.4540113892],
            [-37.7685891997, -4.46125445],
            [-37.7758405076, -4.4674526308],
            [-37.7714797912, -4.4768056553],
            [-37.7729227579, -4.4800697301],
            [-37.7765999165, -4.4783176226],
            [-37.7903923053, -4.5080713341],
            [-37.8059053027, -4.5058609074],
            [-37.8261530207, -4.5041521616],
            [-37.8318040491, -4.5074950926],
            [-37.8342427517, -4.5142541324],
            [-37.8473430246, -4.5213682571],
            [-37.8475195665, -4.5317087305],
            [-37.8478574255, -4.5345224455],
            [-37.8570060875, -4.5415538386],
            [-37.8692400462, -4.5436095091],
            [-37.8992421904, -4.5354150465],
            [-37.9105625653, -4.5367430072],
            [-37.9169809538, -4.5299927774],
            [-37.9385517448, -4.5256841081],
            [-37.9632619629, -4.5294539247],
            [-37.9699440455, -4.5163666799],
            [-37.9658374608, -4.509411626],
            [-37.9603717876, -4.5072970073],
            [-37.9644699596, -4.4990358966],
            [-37.9589443138, -4.4941306638],
            [-37.9600186396, -4.4901513345],
            [-37.9543729928, -4.4824290799],
            [-37.9567812585, -4.4678627797],
            [-37.9631224556, -4.4728306039],
            [-37.9678509778, -4.4632232913],
            [-37.9721101308, -4.4294962499],
            [-37.9511973043, -4.416670953],
            [-37.9482517203, -4.4104815219],
            [-37.9493361288, -4.3983010322],
            [-37.9413844005, -4.3986668703],
            [-37.930420041, -4.3976010841],
            [-37.9215956592, -4.4044065451],
            [-37.9089210845, -4.4000188844],
            [-37.9042497039, -4.414458526],
            [-37.8967796383, -4.4073745475],
            [-37.8930882799, -4.4121519061],
            [-37.8916175395, -4.4081178281],
            [-37.8840575673, -4.4068567854],
            [-37.8858972841, -4.4133155244],
            [-37.8805578096, -4.4128271848],
            [-37.8732526322, -4.4108848025],
            [-37.8737772425, -4.4010991941],
            [-37.8705050892, -4.4012232198],
            [-37.8465166742, -4.4020552235],
            [-37.846029378, -4.4068461275],
            [-37.8384278236, -4.4083990443],
            [-37.8395686048, -4.404378789],
            [-37.8353391487, -4.4017476876],
            [-37.8256689237, -4.405357975],
            [-37.8193902043, -4.4019986551],
            [-37.8215627613, -4.3946419603],
            [-37.8348645702, -4.3876736744],
            [-37.835311246, -4.3821323267],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304509',
        name: 'Frecheirinha',
        description: 'Frecheirinha',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.817699766, -3.6369610355],
            [-40.7935411564, -3.6662979035],
            [-40.7932835216, -3.6666104527],
            [-40.7786247041, -3.6844038517],
            [-40.7594426103, -3.7076827224],
            [-40.7630125448, -3.7144955306],
            [-40.7681145927, -3.7162703363],
            [-40.7665879063, -3.7201123713],
            [-40.7704198387, -3.7269091115],
            [-40.7803406008, -3.7357063077],
            [-40.7820722492, -3.7504336239],
            [-40.779473414, -3.7578170158],
            [-40.7863103163, -3.7704522716],
            [-40.7812838444, -3.7735428481],
            [-40.7828658886, -3.7800843963],
            [-40.7793379163, -3.7861772169],
            [-40.8193715585, -3.7880111559],
            [-40.8494156322, -3.7876239682],
            [-40.8936748776, -3.7813358654],
            [-40.8950124215, -3.7808312308],
            [-40.8934001555, -3.77615496],
            [-40.8925189685, -3.7736002591],
            [-40.8803405909, -3.738298591],
            [-40.8750150254, -3.7228676307],
            [-40.8900432233, -3.6699987426],
            [-40.8900603671, -3.6698747258],
            [-40.8903912653, -3.6675334597],
            [-40.8878788496, -3.6621154344],
            [-40.8706308466, -3.6527195548],
            [-40.871839555, -3.6478653828],
            [-40.8675378253, -3.6417984938],
            [-40.8628102865, -3.6419558094],
            [-40.8607279477, -3.6481834662],
            [-40.8478595161, -3.6476215546],
            [-40.8412698991, -3.6419004622],
            [-40.8363460979, -3.6420583458],
            [-40.8306657223, -3.6335900981],
            [-40.8249927755, -3.6322994875],
            [-40.817699766, -3.6369610355],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304608',
        name: 'General Sampaio',
        description: 'General Sampaio',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.4434006697, -3.9704292255],
            [-39.3731258628, -4.0403271394],
            [-39.3729744772, -4.0404786451],
            [-39.3245330901, -4.0891259924],
            [-39.3415265991, -4.0890203935],
            [-39.3668225048, -4.0887041559],
            [-39.3585519057, -4.096712478],
            [-39.3717071809, -4.110091975],
            [-39.3868036088, -4.1047334963],
            [-39.3993302707, -4.1045966739],
            [-39.4068756235, -4.0953274159],
            [-39.4361015032, -4.1008177536],
            [-39.5175838179, -4.1144390775],
            [-39.5238893051, -4.1115002896],
            [-39.5252861691, -4.093727655],
            [-39.5307958047, -4.0895611738],
            [-39.5329266808, -4.0825866803],
            [-39.5168293896, -4.0311625337],
            [-39.5077531919, -4.0021858467],
            [-39.4937026931, -3.9956506033],
            [-39.4846086466, -3.9995341298],
            [-39.4856964564, -4.0049008541],
            [-39.4597058374, -4.0036530792],
            [-39.4513956573, -3.9926024035],
            [-39.451976375, -3.9828860762],
            [-39.4468907068, -3.9792162355],
            [-39.4469740484, -3.9710441025],
            [-39.4434006697, -3.9704292255],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304657',
        name: 'Graça',
        description: 'Graça',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.7610435518, -3.9507685439],
            [-40.7504867471, -3.9569311497],
            [-40.7503862297, -3.9572995333],
            [-40.7371498751, -3.9621309499],
            [-40.7309563609, -3.9667012506],
            [-40.7307265078, -3.9674903443],
            [-40.729065557, -3.9757602584],
            [-40.7289625051, -3.9762818155],
            [-40.7246189956, -3.9980095753],
            [-40.712887681, -4.056648087],
            [-40.7119200979, -4.0614800247],
            [-40.6822218559, -4.0571674168],
            [-40.6905249585, -4.0765394209],
            [-40.6918789327, -4.0796990338],
            [-40.7175441693, -4.139606511],
            [-40.7258516385, -4.1394147691],
            [-40.7277971383, -4.1360758437],
            [-40.7341721578, -4.1416946698],
            [-40.7461168756, -4.1458140724],
            [-40.7497936773, -4.1336902185],
            [-40.7551229767, -4.1335612734],
            [-40.7683323744, -4.1300017106],
            [-40.7685610706, -4.1299006016],
            [-40.7734201221, -4.1277411722],
            [-40.7789829798, -4.1318178823],
            [-40.7967768054, -4.1321282111],
            [-40.803986486, -4.1304153629],
            [-40.8065172814, -4.1235181686],
            [-40.8150869353, -4.1233626531],
            [-40.8283616925, -4.1117642167],
            [-40.8360758619, -4.1119106117],
            [-40.8372343668, -4.1050439584],
            [-40.847684594, -4.1032064545],
            [-40.8475386306, -4.0905828722],
            [-40.8528450004, -4.089045575],
            [-40.8539029379, -4.0835250032],
            [-40.8574386786, -4.0740176516],
            [-40.8539263244, -4.0691414407],
            [-40.8540768702, -4.0658569391],
            [-40.8466349942, -4.0617863576],
            [-40.8481884098, -4.0567151762],
            [-40.8440941298, -4.0501338554],
            [-40.849112862, -4.0453475605],
            [-40.8429934325, -4.0423506691],
            [-40.8431574826, -4.0376733925],
            [-40.8427748609, -4.0260500679],
            [-40.8492210663, -4.0190185403],
            [-40.8454521527, -4.0081657319],
            [-40.8389760267, -4.007931843],
            [-40.8363851388, -4.0034907246],
            [-40.8382251808, -3.9970519911],
            [-40.8470243172, -3.9962037771],
            [-40.8456402707, -3.9915242406],
            [-40.8392744291, -3.9889371881],
            [-40.8392367261, -3.988874037],
            [-40.839057944, -3.9885690081],
            [-40.8393810424, -3.9709105655],
            [-40.8395719845, -3.9669404423],
            [-40.835916491, -3.9586062172],
            [-40.8223658973, -3.9569191039],
            [-40.8116337956, -3.9525066001],
            [-40.8042514199, -3.9556737861],
            [-40.7936747009, -3.9537535062],
            [-40.7661212541, -3.9535907109],
            [-40.7610435518, -3.9507685439],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304707',
        name: 'Granja',
        description: 'Granja',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.558395438, -3.0261588032],
            [-40.5319174936, -3.0339574816],
            [-40.5162168726, -3.0504668407],
            [-40.4939786709, -3.0633043044],
            [-40.5096853487, -3.1078294059],
            [-40.5098499221, -3.1079044949],
            [-40.5731782105, -3.1360654389],
            [-40.5989301647, -3.1232645693],
            [-40.647551812, -3.0990835313],
            [-40.6859418588, -3.0799826044],
            [-40.7506720207, -3.0504100552],
            [-40.7272139495, -3.1297195187],
            [-40.7087468344, -3.2130952233],
            [-40.7143913259, -3.2195755618],
            [-40.7136930176, -3.2301362497],
            [-40.7038522739, -3.2362099129],
            [-40.6970459296, -3.2340514639],
            [-40.6919228715, -3.2376745171],
            [-40.6920817401, -3.2421032241],
            [-40.7007640461, -3.2401444982],
            [-40.7945650174, -3.2189694138],
            [-40.8325695113, -3.2107137373],
            [-40.837670913, -3.2287212319],
            [-40.8286982116, -3.2303555162],
            [-40.8268336232, -3.2360577695],
            [-40.8392309456, -3.2419009133],
            [-40.8208615309, -3.259789788],
            [-40.8346740339, -3.2603992085],
            [-40.8388935043, -3.2671838271],
            [-40.8272012022, -3.2743465811],
            [-40.8158389524, -3.2712243786],
            [-40.8122006594, -3.2742161551],
            [-40.8205055504, -3.2958429682],
            [-40.8386662914, -3.3076960284],
            [-40.8469507118, -3.3074854275],
            [-40.8517746367, -3.3108645743],
            [-40.8526194365, -3.3169086959],
            [-40.8488907654, -3.3190815342],
            [-40.8502497449, -3.327420623],
            [-40.8555028686, -3.342572665],
            [-40.8800754847, -3.3642342656],
            [-40.8778109049, -3.3698099636],
            [-40.8391826, -3.358088978],
            [-40.8235530062, -3.3618864182],
            [-40.8253734433, -3.377937623],
            [-40.8363500186, -3.3797158272],
            [-40.8440933524, -3.4005167679],
            [-40.8526649228, -3.4109289912],
            [-40.8519604926, -3.4190375569],
            [-40.848744649, -3.4319462499],
            [-40.8645835189, -3.4499187169],
            [-40.8683351788, -3.4603790464],
            [-40.8681126817, -3.4793571672],
            [-40.8830804339, -3.4871546992],
            [-40.883266195, -3.4973912782],
            [-40.8744052194, -3.4997956708],
            [-40.8695788851, -3.5071061974],
            [-40.8884758064, -3.5220686388],
            [-40.9317991095, -3.4855138237],
            [-40.9616332367, -3.4594499232],
            [-40.9648466475, -3.4566413766],
            [-40.9652821512, -3.456260234],
            [-40.992942398, -3.4320860486],
            [-40.9983269848, -3.427378322],
            [-41.0030720613, -3.4223039328],
            [-41.0129831233, -3.4203695583],
            [-41.0162035376, -3.4122520348],
            [-41.0224603261, -3.4090088261],
            [-41.0260669012, -3.4075283602],
            [-41.0515303939, -3.4022554449],
            [-41.0485271772, -3.3911033523],
            [-41.0590723223, -3.3883481182],
            [-41.0929863213, -3.3704596306],
            [-41.0931263664, -3.3704182323],
            [-41.1250098796, -3.3610741547],
            [-41.1349822812, -3.3628074373],
            [-41.1411375391, -3.3591766742],
            [-41.1488004092, -3.3628287316],
            [-41.1589722841, -3.326028156],
            [-41.1696847882, -3.2872456732],
            [-41.1800032086, -3.32037349],
            [-41.1988118094, -3.3807925582],
            [-41.2165432539, -3.4377938885],
            [-41.2254752531, -3.4665224213],
            [-41.2333933935, -3.4631767082],
            [-41.2382902804, -3.4760572686],
            [-41.2468900511, -3.4817290182],
            [-41.2505831128, -3.4864525679],
            [-41.2746892439, -3.4852576481],
            [-41.2869411146, -3.4789545942],
            [-41.2988404899, -3.4906471593],
            [-41.3059659337, -3.4878956759],
            [-41.3196207755, -3.4938244444],
            [-41.3274488069, -3.4909663162],
            [-41.3292541596, -3.4654439028],
            [-41.3383203915, -3.4594909006],
            [-41.3476211096, -3.452144729],
            [-41.3435380926, -3.4401232417],
            [-41.3444052314, -3.418944446],
            [-41.3475951344, -3.4142511033],
            [-41.3556535249, -3.4104572743],
            [-41.3884471989, -3.4124596211],
            [-41.3934833199, -3.3991932627],
            [-41.4031936662, -3.3962058154],
            [-41.4002974978, -3.3775120719],
            [-41.4023268132, -3.3620218432],
            [-41.4183425576, -3.3701152968],
            [-41.423142768, -3.3676337458],
            [-41.4139763012, -3.3495665053],
            [-41.4119426002, -3.3403247352],
            [-41.3975900266, -3.3271742681],
            [-41.3998849077, -3.3182248127],
            [-41.3898819877, -3.2942318787],
            [-41.3864275649, -3.2883766156],
            [-41.3601296383, -3.2438152314],
            [-41.3221108013, -3.1794214466],
            [-41.3176433069, -3.1794382632],
            [-41.2370609315, -3.179745302],
            [-41.2088508299, -3.1614492591],
            [-41.1661433855, -3.1343620119],
            [-41.1507495718, -3.1247460725],
            [-41.1338031268, -3.1141562968],
            [-41.1018786073, -3.0942091901],
            [-41.1014354987, -3.0939543133],
            [-41.0012271776, -3.0362765976],
            [-40.9946786988, -3.0325081758],
            [-40.9562634547, -3.02476571],
            [-40.9285371912, -3.0199554558],
            [-40.8886182899, -3.0130296257],
            [-40.8799086528, -3.0115187911],
            [-40.8423783684, -3.0345841095],
            [-40.8381267627, -3.0372824142],
            [-40.8076642349, -3.0313785048],
            [-40.8070521463, -3.0312602048],
            [-40.7530139918, -3.0207878559],
            [-40.6919609403, -3.0089585494],
            [-40.6641414824, -3.0107666309],
            [-40.6304390831, -3.0129564112],
            [-40.5638927643, -3.0172746351],
            [-40.5639362853, -3.0176791332],
            [-40.558395438, -3.0261588032],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304806',
        name: 'Granjeiro',
        description: 'Granjeiro',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.1833399968, -6.8703144299],
            [-39.1686769316, -6.9022062679],
            [-39.167144601, -6.9113771526],
            [-39.1671818669, -6.9286758678],
            [-39.233822907, -6.9267739823],
            [-39.2359079299, -6.926714077],
            [-39.2449259996, -6.9327150468],
            [-39.2498814716, -6.9311850481],
            [-39.2535847069, -6.92498387],
            [-39.2567059135, -6.9276737579],
            [-39.2702544787, -6.9269698665],
            [-39.2878597781, -6.9332331735],
            [-39.2974912127, -6.9330700138],
            [-39.3029116659, -6.9364897396],
            [-39.3170946606, -6.9334235196],
            [-39.3267728513, -6.9847629526],
            [-39.3308673433, -6.9806053375],
            [-39.3294082205, -6.9670393546],
            [-39.3362130164, -6.9620217555],
            [-39.3346309162, -6.9556691567],
            [-39.3481092797, -6.9422139504],
            [-39.3458372058, -6.9379645816],
            [-39.3458369983, -6.9378278835],
            [-39.3470953549, -6.9357371468],
            [-39.3388339635, -6.9279223086],
            [-39.3397068184, -6.9145967925],
            [-39.3359159336, -6.9052688523],
            [-39.3290776121, -6.9033127613],
            [-39.3216005738, -6.9011729413],
            [-39.2715025321, -6.8875029337],
            [-39.2513981154, -6.8855590967],
            [-39.2258113991, -6.879675378],
            [-39.2196674305, -6.8767050534],
            [-39.2069430751, -6.8772431736],
            [-39.1956730063, -6.8668883565],
            [-39.1860910491, -6.8643278371],
            [-39.1833399968, -6.8703144299],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304905',
        name: 'Groaíras',
        description: 'Groaíras',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.3659235745, -3.8697403483],
            [-40.3516287515, -3.8733213262],
            [-40.3418055533, -3.8702859698],
            [-40.337239325, -3.8635502225],
            [-40.3245174755, -3.8615715945],
            [-40.3205105517, -3.8640472942],
            [-40.3153537517, -3.8874560824],
            [-40.3051610132, -3.9336827585],
            [-40.3383819053, -3.9696555586],
            [-40.360757204, -4.0317845825],
            [-40.3719714799, -4.0150158903],
            [-40.368506267, -4.006350003],
            [-40.372251257, -3.9957483493],
            [-40.3772552744, -3.9939128223],
            [-40.38796244, -3.9789818635],
            [-40.3950839255, -3.9670811742],
            [-40.3942565234, -3.9583590176],
            [-40.4002274895, -3.953413526],
            [-40.3969774835, -3.9430895492],
            [-40.400088567, -3.9378843427],
            [-40.4076210929, -3.9300619838],
            [-40.4061907406, -3.9119152819],
            [-40.4075479809, -3.8972336037],
            [-40.4159383232, -3.8062441794],
            [-40.4005849281, -3.8021192418],
            [-40.3893377643, -3.7990982043],
            [-40.3852239021, -3.826678308],
            [-40.3796956803, -3.8371602244],
            [-40.3801706743, -3.8467721241],
            [-40.3707457971, -3.8614246383],
            [-40.3659235745, -3.8697403483],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304954',
        name: 'Guaiúba',
        description: 'Guaiúba',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.6495922388, -4.0055525543],
            [-38.6354192798, -4.023207304],
            [-38.6272916376, -4.0229228407],
            [-38.6245781143, -4.0155402041],
            [-38.6165621226, -4.0156839698],
            [-38.6065072653, -4.0107895167],
            [-38.5743372628, -4.0521765513],
            [-38.6048064223, -4.0587801863],
            [-38.6122556893, -4.099718273],
            [-38.6129493565, -4.1035302838],
            [-38.6065857734, -4.1002028403],
            [-38.6023473912, -4.1072879237],
            [-38.5768772732, -4.1051680531],
            [-38.5721454853, -4.1150059849],
            [-38.5654919435, -4.1184929038],
            [-38.5665588526, -4.1228078317],
            [-38.570566519, -4.1283213949],
            [-38.6134746389, -4.1520417784],
            [-38.6194224012, -4.1639959298],
            [-38.6238352705, -4.1657816487],
            [-38.6558110939, -4.1580425034],
            [-38.6638726239, -4.1620483556],
            [-38.6670651706, -4.1604581699],
            [-38.685752862, -4.1702058777],
            [-38.6881178766, -4.1681912944],
            [-38.6973407587, -4.1718755824],
            [-38.7155025554, -4.1908290149],
            [-38.7270028212, -4.1694302214],
            [-38.729588354, -4.1694709467],
            [-38.7327071373, -4.1702894314],
            [-38.7329539012, -4.1779907821],
            [-38.7390312782, -4.1763879436],
            [-38.749844134, -4.1802669705],
            [-38.7601193952, -4.1784877865],
            [-38.7630779132, -4.1705416411],
            [-38.7743926931, -4.1688441164],
            [-38.7770134249, -4.1699791079],
            [-38.7827420731, -4.1554889786],
            [-38.7906637682, -4.1518233006],
            [-38.7908263032, -4.142828529],
            [-38.7903361164, -4.1347188839],
            [-38.7843026406, -4.1334204602],
            [-38.7872995745, -4.1297736032],
            [-38.7717413663, -4.1315526873],
            [-38.7691635563, -4.1279843162],
            [-38.7549776513, -4.1278991252],
            [-38.7481908318, -4.0925793826],
            [-38.7481564299, -4.0924023049],
            [-38.7453127044, -4.0721281611],
            [-38.735511783, -4.0713841586],
            [-38.7237598929, -4.0721335994],
            [-38.7223808297, -4.0677946509],
            [-38.7093664005, -4.0617150171],
            [-38.6992487505, -4.0517486767],
            [-38.6867177545, -4.0484909291],
            [-38.6716998982, -4.0387734917],
            [-38.6734985895, -4.0338475338],
            [-38.6738690688, -4.0290412939],
            [-38.6676361563, -4.0230810098],
            [-38.6673621686, -4.0180536135],
            [-38.6555587582, -4.0141055223],
            [-38.6495922388, -4.0055525543],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305001',
        name: 'Guaraciaba do Norte',
        description: 'Guaraciaba do Norte',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.803986486, -4.1304153629],
            [-40.7967768054, -4.1321282111],
            [-40.7789829798, -4.1318178823],
            [-40.7734201221, -4.1277411722],
            [-40.7685610706, -4.1299006016],
            [-40.7683323744, -4.1300017106],
            [-40.7551229767, -4.1335612734],
            [-40.7497936773, -4.1336902185],
            [-40.7461168756, -4.1458140724],
            [-40.7341721578, -4.1416946698],
            [-40.7277971383, -4.1360758437],
            [-40.7258516385, -4.1394147691],
            [-40.7175441693, -4.139606511],
            [-40.7123419179, -4.1515996395],
            [-40.7156679478, -4.1533267587],
            [-40.7120283684, -4.1569977428],
            [-40.7153665644, -4.1598066784],
            [-40.7097171001, -4.1630595988],
            [-40.7133028456, -4.1655591372],
            [-40.7142644555, -4.1776991719],
            [-40.7153606895, -4.1815405682],
            [-40.7240742908, -4.1827529461],
            [-40.7169089678, -4.187436329],
            [-40.727341782, -4.1917230898],
            [-40.7269257542, -4.1953326873],
            [-40.7287533278, -4.2013188567],
            [-40.7331101023, -4.2038679561],
            [-40.7353481394, -4.2110150078],
            [-40.7374974792, -4.2205239468],
            [-40.761871978, -4.2307191321],
            [-40.7631754943, -4.2306703864],
            [-40.7647627276, -4.231302909],
            [-40.7672395484, -4.2313899963],
            [-40.7720876673, -4.2320927208],
            [-40.7868674684, -4.2408598079],
            [-40.7898859579, -4.2537366682],
            [-40.796584392, -4.259181531],
            [-40.8026386261, -4.2663391639],
            [-40.8018200327, -4.2741940678],
            [-40.7909826722, -4.2921555567],
            [-40.7873272224, -4.3104098438],
            [-40.7906729045, -4.3225267233],
            [-40.7907878112, -4.3227039975],
            [-40.7991251426, -4.3307054159],
            [-40.802751039, -4.3447688415],
            [-40.820012393, -4.3403527862],
            [-40.8412100554, -4.3238351856],
            [-40.8462838161, -4.320892404],
            [-40.8392795259, -4.3129763185],
            [-40.85265971, -4.2969454744],
            [-40.8697904124, -4.3049701811],
            [-40.858734076, -4.3151580707],
            [-40.8698937559, -4.3234427446],
            [-40.8758934868, -4.3227209784],
            [-40.8778056947, -4.3139419242],
            [-40.8843237381, -4.3178432445],
            [-40.8904185058, -4.3138397549],
            [-40.8904759119, -4.3138028233],
            [-40.9268185235, -4.2899247813],
            [-40.9775939067, -4.2786874152],
            [-40.982009066, -4.2777097355],
            [-41.0069649897, -4.2721838684],
            [-41.140196855, -4.3698863075],
            [-41.1488301455, -4.3669491937],
            [-41.1330493329, -4.3397645644],
            [-41.1197982108, -4.3301863025],
            [-41.1190615338, -4.3238747729],
            [-41.1187508882, -4.3061450349],
            [-41.1355316384, -4.2331316605],
            [-41.0988992928, -4.1817205027],
            [-41.0822585652, -4.1892301993],
            [-41.073174805, -4.1998903494],
            [-41.0181855302, -4.2009803536],
            [-41.0061463196, -4.1900177761],
            [-40.9957517604, -4.2044633099],
            [-40.9842534918, -4.2105375209],
            [-40.9756276655, -4.2303692203],
            [-40.9717582656, -4.2266654875],
            [-40.9414139925, -4.1976298702],
            [-40.9386365876, -4.1949726155],
            [-40.9362838088, -4.1927216543],
            [-40.9330888344, -4.1896654173],
            [-40.9096918064, -4.1672819353],
            [-40.900704075, -4.1586852507],
            [-40.9005577945, -4.1585453832],
            [-40.8943674605, -4.1546374909],
            [-40.8862077983, -4.1522983044],
            [-40.8694244258, -4.15748808],
            [-40.8507463265, -4.153973152],
            [-40.8541703578, -4.1464715406],
            [-40.8503504071, -4.1411514067],
            [-40.8356830718, -4.1341330121],
            [-40.833202329, -4.1281230812],
            [-40.8150869353, -4.1233626531],
            [-40.8065172814, -4.1235181686],
            [-40.803986486, -4.1304153629],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305100',
        name: 'Guaramiranga',
        description: 'Guaramiranga',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.9442680265, -4.1823705923],
            [-38.9431594465, -4.1947862316],
            [-38.948758605, -4.1944990014],
            [-38.9487994989, -4.2049578655],
            [-38.9397700534, -4.2070594232],
            [-38.9401744739, -4.2120117214],
            [-38.9352033364, -4.212011235],
            [-38.9347681943, -4.2148486805],
            [-38.9357745829, -4.2174054412],
            [-38.9358730819, -4.2274819165],
            [-38.9391066762, -4.2295237692],
            [-38.9388853559, -4.2362058156],
            [-38.9300815767, -4.2367025192],
            [-38.9205025652, -4.2477532132],
            [-38.9057775194, -4.2495225934],
            [-38.9019738911, -4.2489715276],
            [-38.8975182206, -4.2553736965],
            [-38.8919136042, -4.2779807908],
            [-38.8953197436, -4.2771498571],
            [-38.8973977241, -4.2684022907],
            [-38.9026431689, -4.2684016047],
            [-38.908841912, -4.2628927608],
            [-38.9179021551, -4.264689917],
            [-38.9202859372, -4.2729550703],
            [-38.9248138315, -4.2759496643],
            [-38.9187368093, -4.2763068826],
            [-38.9167103063, -4.2794231394],
            [-38.916365618, -4.2915805459],
            [-38.9177932685, -4.2948763554],
            [-38.9330707989, -4.3231429738],
            [-38.9333843624, -4.3237225027],
            [-38.9452369229, -4.2905110871],
            [-38.94529616, -4.2903426078],
            [-38.9621395376, -4.2431120622],
            [-38.9696778453, -4.2219607823],
            [-38.9745663418, -4.2082381058],
            [-38.9852350034, -4.21033657],
            [-38.9879277598, -4.2180057635],
            [-39.0085990934, -4.2321264266],
            [-39.0119572395, -4.2275683847],
            [-39.0265327666, -4.227586641],
            [-39.0264331477, -4.2271999628],
            [-39.0190087328, -4.222655089],
            [-39.0059582643, -4.2265491988],
            [-39.0058238094, -4.2114974254],
            [-38.9975008778, -4.2060463352],
            [-38.9825177175, -4.2042314156],
            [-38.9800708761, -4.1981104562],
            [-38.9793210621, -4.1962357767],
            [-38.9790541831, -4.1938498347],
            [-38.9761235632, -4.1977247599],
            [-38.9749929348, -4.1861960181],
            [-38.9707982331, -4.1900867839],
            [-38.966603259, -4.1855460783],
            [-38.9620743689, -4.1902173429],
            [-38.9593450903, -4.1884012894],
            [-38.9608100051, -4.1829509258],
            [-38.9516977971, -4.1823050528],
            [-38.9462565102, -4.1800024748],
            [-38.9442680265, -4.1823705923],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305209',
        name: 'Hidrolândia',
        description: 'Hidrolândia',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.2475559313, -4.4799122778],
            [-40.2455661194, -4.4877580932],
            [-40.2490591237, -4.4876691273],
            [-40.2503026494, -4.4916919964],
            [-40.2562168653, -4.4866608393],
            [-40.2556720616, -4.4916005314],
            [-40.2679684114, -4.5069340562],
            [-40.2565313468, -4.5192686286],
            [-40.246942353, -4.5199999029],
            [-40.2438808216, -4.523326256],
            [-40.2400897997, -4.5496418007],
            [-40.2425616081, -4.5498046539],
            [-40.2429593471, -4.5575641352],
            [-40.2406180949, -4.5596849349],
            [-40.2330718146, -4.5572490698],
            [-40.244092707, -4.5784249315],
            [-40.2390344488, -4.579441549],
            [-40.2428411239, -4.5880253052],
            [-40.2363713145, -4.5926837445],
            [-40.247043181, -4.6027909427],
            [-40.2509171259, -4.6014148756],
            [-40.2526315275, -4.6055615436],
            [-40.2644815269, -4.6060382516],
            [-40.2714776112, -4.611172422],
            [-40.2773925749, -4.6069699537],
            [-40.2823834003, -4.6084959295],
            [-40.2942418728, -4.6006392981],
            [-40.2970692595, -4.60379358],
            [-40.2978855876, -4.6062129355],
            [-40.3034554993, -4.6047617281],
            [-40.3029346373, -4.6093307002],
            [-40.311843731, -4.6176942747],
            [-40.3166020455, -4.617514462],
            [-40.318904265, -4.6220786334],
            [-40.3170952263, -4.6357526458],
            [-40.3314947222, -4.639505412],
            [-40.3309807674, -4.6473711542],
            [-40.3366118571, -4.6545368031],
            [-40.3417651533, -4.6450855485],
            [-40.3464691642, -4.6318928466],
            [-40.3743901879, -4.5535305145],
            [-40.3874086066, -4.5477817684],
            [-40.3938310503, -4.5460921466],
            [-40.458814613, -4.5288147953],
            [-40.4667994241, -4.5254062925],
            [-40.5058094782, -4.5087484376],
            [-40.5091183304, -4.5073350457],
            [-40.5166232022, -4.5121183476],
            [-40.5878488585, -4.5575254258],
            [-40.5883015111, -4.5578012728],
            [-40.5983540809, -4.5295487796],
            [-40.5905057452, -4.5171906177],
            [-40.5903958737, -4.5099531267],
            [-40.5828621136, -4.5041308257],
            [-40.5839769242, -4.4912172538],
            [-40.5794706028, -4.4878890983],
            [-40.5848264124, -4.4755144066],
            [-40.5653679554, -4.4557609247],
            [-40.5538997261, -4.4380926054],
            [-40.5536975071, -4.4341985177],
            [-40.5609512754, -4.42936961],
            [-40.5649776458, -4.4191517065],
            [-40.5607333571, -4.4019704765],
            [-40.5605490479, -4.4010597239],
            [-40.5590460728, -4.3995765314],
            [-40.552338479, -4.398285281],
            [-40.5543070808, -4.3876791406],
            [-40.548753766, -4.3773311352],
            [-40.5231728552, -4.355641112],
            [-40.5217600494, -4.338508527],
            [-40.5141688747, -4.3268284509],
            [-40.499892315, -4.3256373181],
            [-40.4952856075, -4.3217830693],
            [-40.4882228005, -4.2991845263],
            [-40.4784408479, -4.2935160205],
            [-40.4782535966, -4.293407198],
            [-40.4757512127, -4.2891571333],
            [-40.4626891587, -4.2680006116],
            [-40.4481061039, -4.258286739],
            [-40.3984854129, -4.2615438892],
            [-40.3278262631, -4.2656161166],
            [-40.3031714947, -4.2668548764],
            [-40.3023959814, -4.2668941705],
            [-40.3161687468, -4.2820900652],
            [-40.314255509, -4.3015799971],
            [-40.3218097125, -4.3117554048],
            [-40.326032708, -4.3419670025],
            [-40.3198678649, -4.3460897894],
            [-40.3201464255, -4.3544126982],
            [-40.3119033115, -4.358687271],
            [-40.3130810747, -4.3652276366],
            [-40.3057527711, -4.3756063589],
            [-40.3047013233, -4.3876528173],
            [-40.3000836901, -4.3959660108],
            [-40.291921184, -4.4071987174],
            [-40.2807782639, -4.4150894875],
            [-40.2731132267, -4.434586189],
            [-40.2615553445, -4.4434678587],
            [-40.258388024, -4.4536623774],
            [-40.2603618022, -4.4621251089],
            [-40.2541106529, -4.4726006722],
            [-40.2475559313, -4.4799122778],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305233',
        name: 'Horizonte',
        description: 'Horizonte',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.4544917798, -4.0685469776],
            [-38.4291600521, -4.070123184],
            [-38.4217083813, -4.0696141423],
            [-38.4215014773, -4.0696006818],
            [-38.3850090297, -4.0708801764],
            [-38.3722351123, -4.0786030094],
            [-38.3757944613, -4.1065551478],
            [-38.3762289947, -4.1099703404],
            [-38.3806494115, -4.1447244969],
            [-38.4161866586, -4.1421371634],
            [-38.4588847518, -4.1390263966],
            [-38.4670670884, -4.1384295139],
            [-38.4892126251, -4.1400180112],
            [-38.5001220111, -4.1433829845],
            [-38.5196741278, -4.1363333344],
            [-38.5293932423, -4.1309232235],
            [-38.5420828467, -4.1306534968],
            [-38.542950528, -4.1281434163],
            [-38.570566519, -4.1283213949],
            [-38.5665588526, -4.1228078317],
            [-38.5581141048, -4.1198338568],
            [-38.5505386713, -4.0937599901],
            [-38.5456344005, -4.0881888895],
            [-38.5433637133, -4.0853356786],
            [-38.545696066, -4.0830219792],
            [-38.5366462224, -4.0778279256],
            [-38.5330865208, -4.0677874871],
            [-38.5336429017, -4.0581996699],
            [-38.5388094437, -4.0496367892],
            [-38.5355001282, -4.0409714197],
            [-38.5054164525, -4.0596600343],
            [-38.4755262138, -4.0612591383],
            [-38.4544917798, -4.0685469776],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305266',
        name: 'Ibaretama',
        description: 'Ibaretama',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.7008289931, -4.6125709166],
            [-38.6989040203, -4.6264732505],
            [-38.6944827018, -4.6274053909],
            [-38.6912292579, -4.6356009522],
            [-38.6923441585, -4.64161977],
            [-38.6867623692, -4.6446799588],
            [-38.6903688243, -4.6508540787],
            [-38.6759806196, -4.6570735613],
            [-38.6746228486, -4.6636644234],
            [-38.6707016209, -4.6666163746],
            [-38.6681969767, -4.6607022903],
            [-38.6650675886, -4.6603516872],
            [-38.652896301, -4.6827160973],
            [-38.642505153, -4.6876903012],
            [-38.6314755059, -4.6885143968],
            [-38.6382383863, -4.6950546035],
            [-38.6377237161, -4.6996354889],
            [-38.6236948242, -4.6979896826],
            [-38.6151344112, -4.6892008851],
            [-38.5974983775, -4.6841518126],
            [-38.5917177896, -4.6787623588],
            [-38.5801996835, -4.6708482172],
            [-38.572974969, -4.6706503351],
            [-38.5690347756, -4.6751055578],
            [-38.5548689137, -4.6736792644],
            [-38.5377019204, -4.6798411959],
            [-38.5285303918, -4.6798995787],
            [-38.5276709963, -4.6948742809],
            [-38.5325279422, -4.7077470288],
            [-38.5195567356, -4.7346097734],
            [-38.5230354753, -4.7354633118],
            [-38.5273908918, -4.7461520273],
            [-38.5345627003, -4.753481053],
            [-38.5399207901, -4.7569249066],
            [-38.5463045414, -4.7558866626],
            [-38.5620564079, -4.7600104833],
            [-38.5775448395, -4.7714874849],
            [-38.5781391673, -4.7967355059],
            [-38.5886478227, -4.8111788761],
            [-38.5967542633, -4.8122280389],
            [-38.5966179631, -4.8170090668],
            [-38.6012000741, -4.8188631017],
            [-38.6109414095, -4.8331592085],
            [-38.6380352229, -4.8497243361],
            [-38.6457626444, -4.8505287453],
            [-38.6497521364, -4.8507952955],
            [-38.6613321029, -4.8619553588],
            [-38.6661708257, -4.8718432231],
            [-38.6564506566, -4.888083608],
            [-38.6467260714, -4.8947896349],
            [-38.6491164057, -4.9021024468],
            [-38.6376581991, -4.9167131389],
            [-38.6385277281, -4.9272271728],
            [-38.6471286491, -4.9371621967],
            [-38.6483592359, -4.9376035817],
            [-38.702227077, -4.9023140295],
            [-38.7116892444, -4.9040079837],
            [-38.7117529971, -4.9040198739],
            [-38.7632323774, -4.9132379651],
            [-38.8070895366, -4.9210930246],
            [-38.8830837996, -4.9349656715],
            [-38.882398578, -4.9282548083],
            [-38.8851652027, -4.9266669808],
            [-38.890378603, -4.9140538038],
            [-38.8900151937, -4.8950392273],
            [-38.8952578327, -4.8889653584],
            [-38.9170454618, -4.8538429017],
            [-38.9217038417, -4.8363463568],
            [-38.9216272947, -4.8259322772],
            [-38.9080144357, -4.832937781],
            [-38.8981629148, -4.8339836609],
            [-38.8961355581, -4.8409944868],
            [-38.8833484552, -4.842924832],
            [-38.8758139855, -4.8516244379],
            [-38.8713191524, -4.8458264663],
            [-38.8598091282, -4.8392207197],
            [-38.8596127297, -4.8286950132],
            [-38.8132107121, -4.7586312615],
            [-38.801513029, -4.7409752112],
            [-38.8014067498, -4.7406479059],
            [-38.8739737181, -4.6792563469],
            [-38.8696208782, -4.6795604961],
            [-38.8596804371, -4.6644283976],
            [-38.8439072596, -4.6721406717],
            [-38.8290502944, -4.668219298],
            [-38.8258024881, -4.6710762645],
            [-38.813685161, -4.671377684],
            [-38.802734728, -4.6498912647],
            [-38.7905382505, -4.6471634234],
            [-38.7876963019, -4.6387182627],
            [-38.7821501272, -4.6409591506],
            [-38.7815050535, -4.6302814677],
            [-38.777958828, -4.6262814622],
            [-38.7748481369, -4.6318772981],
            [-38.7690219992, -4.6341454968],
            [-38.7625931829, -4.6318695342],
            [-38.7676275547, -4.6234336724],
            [-38.7634730749, -4.6220631488],
            [-38.7533080511, -4.6237746921],
            [-38.735725474, -4.6180586994],
            [-38.7218133691, -4.6205452101],
            [-38.7124428322, -4.6123670585],
            [-38.7008289931, -4.6125709166],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305308',
        name: 'Ibiapina',
        description: 'Ibiapina',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.8696131928, -3.8740257913],
            [-40.8664766693, -3.8790285951],
            [-40.8568157869, -3.8771221702],
            [-40.8592789672, -3.8893986532],
            [-40.8533194521, -3.8845503679],
            [-40.8550192295, -3.8932524577],
            [-40.850638719, -3.895111925],
            [-40.7775970424, -3.8834430095],
            [-40.7771834239, -3.8867577744],
            [-40.7833599543, -3.8890211787],
            [-40.7915675092, -3.9060544763],
            [-40.7980590889, -3.9178767816],
            [-40.8218666051, -3.9196458934],
            [-40.8334699806, -3.9240413856],
            [-40.8223658973, -3.9569191039],
            [-40.835916491, -3.9586062172],
            [-40.8395719845, -3.9669404423],
            [-40.8393810424, -3.9709105655],
            [-40.839057944, -3.9885690081],
            [-40.8473534216, -3.976521695],
            [-40.8608765955, -3.9784726684],
            [-40.8788457383, -3.9917177222],
            [-40.8937702071, -3.9936840297],
            [-40.921854333, -3.988152443],
            [-40.9249155733, -3.9866319219],
            [-40.9374114883, -3.9871220726],
            [-40.9640019977, -3.9825869133],
            [-40.9878749786, -3.9885949884],
            [-40.9956467043, -3.9877735176],
            [-41.0273083432, -3.990958797],
            [-41.0321060225, -3.9957398972],
            [-41.0473775321, -3.9924605329],
            [-41.0561578337, -3.9947520436],
            [-41.0744703364, -3.9957282006],
            [-41.0932213507, -4.0058767214],
            [-41.1070125794, -4.0017318085],
            [-41.118653114, -3.9862694174],
            [-41.1319399781, -3.9885713543],
            [-41.1431089835, -3.994555778],
            [-41.1521932579, -3.9948421737],
            [-41.1563182025, -3.9989838207],
            [-41.1640325478, -3.9982558569],
            [-41.181036194, -4.0128613809],
            [-41.1824833745, -4.0176948406],
            [-41.1922338781, -4.0202824505],
            [-41.1990989416, -4.0179780867],
            [-41.2085309635, -4.025204875],
            [-41.2204200612, -4.0262909816],
            [-41.2399326438, -4.0304714837],
            [-41.2498058685, -4.037375203],
            [-41.2563527367, -4.0344705747],
            [-41.2540354332, -4.0272995773],
            [-41.2384549387, -4.0150270873],
            [-41.2453549373, -4.006204014],
            [-41.2488659228, -3.9894920035],
            [-41.2438168315, -3.9860123864],
            [-41.243988164, -3.979381984],
            [-41.239743522, -3.9788208543],
            [-41.2338761188, -3.9674211949],
            [-41.2286501414, -3.9679987811],
            [-41.2200272902, -3.9678807535],
            [-41.1809489572, -3.9571335423],
            [-41.1672904199, -3.9499252098],
            [-41.133228549, -3.9427698135],
            [-41.1241166906, -3.9345456121],
            [-41.1073694052, -3.9338082328],
            [-41.1019240015, -3.9292513007],
            [-41.0675987148, -3.9187222151],
            [-41.0434319193, -3.9112800412],
            [-40.9815676212, -3.8922313381],
            [-40.9781482383, -3.8924203747],
            [-40.9645655188, -3.8937340392],
            [-40.9543831548, -3.9016980122],
            [-40.9510955377, -3.9030118561],
            [-40.9426569005, -3.907943527],
            [-40.9404617704, -3.9049483497],
            [-40.936183369, -3.9065598864],
            [-40.9319040705, -3.9071400289],
            [-40.9231020638, -3.8948652908],
            [-40.9176471908, -3.8889978092],
            [-40.9084013786, -3.8885764445],
            [-40.9021515393, -3.8879210851],
            [-40.8943913974, -3.8871070652],
            [-40.8960703517, -3.8785836094],
            [-40.8915534478, -3.8682416831],
            [-40.885827584, -3.8680599823],
            [-40.8845091557, -3.867667269],
            [-40.8731096633, -3.8683391625],
            [-40.8759032498, -3.8727991946],
            [-40.8696131928, -3.8740257913],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305332',
        name: 'Ibicuitinga',
        description: 'Ibicuitinga',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5261913967, -4.8847702314],
            [-38.5152624282, -4.8904838336],
            [-38.5057015652, -4.8884092137],
            [-38.4915354488, -4.8794274385],
            [-38.4645289359, -4.8812428931],
            [-38.462336149, -4.8724962357],
            [-38.4491702847, -4.878570483],
            [-38.4412936529, -4.8755261033],
            [-38.4212344669, -4.903240569],
            [-38.415273601, -4.9264848881],
            [-38.4045215658, -4.9472073801],
            [-38.4048687238, -4.9477743803],
            [-38.41411669, -4.9464237949],
            [-38.4225329217, -4.9309355036],
            [-38.4312978816, -4.9328390768],
            [-38.4314070601, -4.9328746944],
            [-38.4486758465, -4.9384069648],
            [-38.4784168187, -4.9479543139],
            [-38.4785389733, -4.9479753993],
            [-38.5037294916, -4.9560816212],
            [-38.5067855338, -4.9623352821],
            [-38.4977726672, -4.9657299356],
            [-38.5024018733, -4.9707574404],
            [-38.4994621113, -4.9787513075],
            [-38.5051499426, -4.9838939491],
            [-38.4919187094, -5.008336763],
            [-38.4824496098, -5.0060242772],
            [-38.4803648524, -5.0142834435],
            [-38.4858107589, -5.0295676927],
            [-38.48447214, -5.0349208079],
            [-38.4960885814, -5.0480932299],
            [-38.4975042455, -5.0668815519],
            [-38.4890920363, -5.0829716984],
            [-38.4905751729, -5.086132711],
            [-38.536071064, -5.0801730114],
            [-38.5638291481, -5.0759174864],
            [-38.6261926558, -5.0659005889],
            [-38.6271124287, -5.0594236866],
            [-38.6353056748, -5.0526267398],
            [-38.6438951119, -5.0526196394],
            [-38.658173208, -5.0458129393],
            [-38.6669065576, -5.0465692131],
            [-38.6791819903, -5.0575003624],
            [-38.686072439, -5.0563134858],
            [-38.6820205301, -5.0507956972],
            [-38.6796939912, -5.0368999928],
            [-38.6760002311, -5.0239045786],
            [-38.6771144879, -5.0043923641],
            [-38.669932083, -4.9979544594],
            [-38.6607673426, -4.9964349963],
            [-38.66015803, -4.9901633828],
            [-38.653606988, -4.9820507616],
            [-38.6473292174, -4.9803157089],
            [-38.6468337523, -4.9741737005],
            [-38.6417981758, -4.9612026018],
            [-38.6456210014, -4.9581684318],
            [-38.6483592359, -4.9376035817],
            [-38.6471286491, -4.9371621967],
            [-38.6309279153, -4.9254178982],
            [-38.6251689107, -4.9124327251],
            [-38.6314825979, -4.9109753306],
            [-38.6299890229, -4.9005089857],
            [-38.6245518642, -4.9006275449],
            [-38.5918145333, -4.8735010507],
            [-38.5593352586, -4.8767639002],
            [-38.5495200919, -4.8822334368],
            [-38.53210734, -4.8833824357],
            [-38.5261913967, -4.8847702314],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305357',
        name: 'Icapuí',
        description: 'Icapuí',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.3575336634, -4.6890785263],
            [-37.3568917701, -4.6920773374],
            [-37.3514551423, -4.6917515774],
            [-37.3517408212, -4.6942724758],
            [-37.3416139957, -4.6919958321],
            [-37.3248464117, -4.7013433051],
            [-37.3022999359, -4.7234830031],
            [-37.2870249759, -4.7425133998],
            [-37.2762750005, -4.7688856376],
            [-37.2665773682, -4.7994685115],
            [-37.2526422354, -4.8313258683],
            [-37.3565046898, -4.8576523246],
            [-37.3566694153, -4.7951819376],
            [-37.3647623204, -4.7854569029],
            [-37.3989713325, -4.7758581044],
            [-37.4011516671, -4.7725716198],
            [-37.4106512815, -4.7799988341],
            [-37.4171270209, -4.7824135619],
            [-37.4229158437, -4.7778535329],
            [-37.4370462498, -4.7823422211],
            [-37.4626689254, -4.7814186382],
            [-37.5043437754, -4.7916146219],
            [-37.5044469144, -4.7915640286],
            [-37.5336362057, -4.7482023643],
            [-37.5385967309, -4.742659908],
            [-37.5571419961, -4.72193111],
            [-37.5399250282, -4.660530711],
            [-37.5434752618, -4.6533568189],
            [-37.5401152347, -4.643872491],
            [-37.5218122211, -4.6383110907],
            [-37.4987251148, -4.6255211157],
            [-37.492150608, -4.6290077308],
            [-37.4727590112, -4.6487333035],
            [-37.4239977469, -4.665580494],
            [-37.4145066677, -4.6709494267],
            [-37.3780725302, -4.6805238665],
            [-37.3575336634, -4.6890785263],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305407',
        name: 'Icó',
        description: 'Icó',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.7105606947, -6.1100320677],
            [-38.7197607382, -6.1344923073],
            [-38.7314545958, -6.1506581604],
            [-38.7356684977, -6.1655237148],
            [-38.7339862222, -6.1782376395],
            [-38.7309951964, -6.1866126594],
            [-38.7260664171, -6.2003979332],
            [-38.7206742997, -6.1996047458],
            [-38.7073535254, -6.2061732544],
            [-38.6940274801, -6.2066024841],
            [-38.6852274325, -6.2113728457],
            [-38.6793048917, -6.2104804425],
            [-38.6758608066, -6.2022007347],
            [-38.6692525659, -6.2007477698],
            [-38.6654761553, -6.1951749876],
            [-38.6564497367, -6.1919871925],
            [-38.6546983471, -6.1842928801],
            [-38.6351695162, -6.1785967399],
            [-38.6306099267, -6.1735262789],
            [-38.61694432, -6.1688012718],
            [-38.6011197496, -6.1762671303],
            [-38.59965482, -6.1843762063],
            [-38.5925815268, -6.1895319617],
            [-38.5884205537, -6.2007967397],
            [-38.5791032805, -6.2072139902],
            [-38.5710231089, -6.2076284983],
            [-38.5711953714, -6.2144621891],
            [-38.5606811193, -6.2293151953],
            [-38.5518139428, -6.233203007],
            [-38.5478174709, -6.2318109456],
            [-38.51935974, -6.2226409471],
            [-38.5230903263, -6.2279222337],
            [-38.5286132515, -6.239473558],
            [-38.5381131265, -6.2464999633],
            [-38.5454989308, -6.2450296608],
            [-38.5540686133, -6.26299873],
            [-38.562559613, -6.267561843],
            [-38.5783616155, -6.2799095315],
            [-38.5711605243, -6.2840459966],
            [-38.5700248532, -6.2925530952],
            [-38.569943906, -6.2995962697],
            [-38.5656702051, -6.302191465],
            [-38.5809436137, -6.3158128832],
            [-38.5811896567, -6.3306486642],
            [-38.5767227478, -6.3470299958],
            [-38.5628352496, -6.3523531733],
            [-38.5629557057, -6.3556344055],
            [-38.5649076428, -6.3562957254],
            [-38.5670007411, -6.3611278158],
            [-38.5757227023, -6.3622942762],
            [-38.5727545852, -6.3745197058],
            [-38.5826106804, -6.3736463391],
            [-38.5921126267, -6.3778483499],
            [-38.5945906314, -6.3861722321],
            [-38.6016944251, -6.3892243628],
            [-38.5937751933, -6.3964628243],
            [-38.58896109, -6.3951812918],
            [-38.5811938846, -6.3963344031],
            [-38.5820046005, -6.3999127668],
            [-38.577372321, -6.4024854745],
            [-38.5730613044, -6.4034013138],
            [-38.5511701053, -6.3977692289],
            [-38.5423631538, -6.4015821121],
            [-38.5304809449, -6.3927283593],
            [-38.5259616681, -6.3943291831],
            [-38.5175572083, -6.408333659],
            [-38.5241645252, -6.4066848943],
            [-38.5539129193, -6.4323648486],
            [-38.555092791, -6.4371115164],
            [-38.5560523423, -6.4472442584],
            [-38.5653032544, -6.4511050529],
            [-38.5694639334, -6.4544775232],
            [-38.5700574744, -6.4697966402],
            [-38.5946195941, -6.5000435985],
            [-38.6119351786, -6.5127925196],
            [-38.6157584504, -6.5277463173],
            [-38.6101669641, -6.5318949622],
            [-38.6268382408, -6.5314556386],
            [-38.6530861319, -6.5475207857],
            [-38.6595837229, -6.5457294826],
            [-38.6796515952, -6.5588880189],
            [-38.6867854313, -6.5624157585],
            [-38.6893600312, -6.5645544654],
            [-38.7027063357, -6.5557068447],
            [-38.7352252167, -6.570268606],
            [-38.7361988037, -6.5823390075],
            [-38.7312947662, -6.5854012658],
            [-38.7509437653, -6.5916589992],
            [-38.7556009402, -6.5960577699],
            [-38.760721911, -6.6055804002],
            [-38.7676708372, -6.6059291428],
            [-38.7738454268, -6.6121116542],
            [-38.79570376, -6.5990081862],
            [-38.8328224083, -6.593966574],
            [-38.8400736712, -6.5979406596],
            [-38.8543854889, -6.5970741032],
            [-38.8625315421, -6.5867747542],
            [-38.8767639583, -6.5813593634],
            [-38.9100863591, -6.5774978717],
            [-38.9150222139, -6.574912405],
            [-38.9193889876, -6.5650444014],
            [-38.9247635434, -6.5595965151],
            [-38.9571427979, -6.5267614308],
            [-38.9636231884, -6.5217106763],
            [-38.9867472549, -6.503684254],
            [-39.0063686044, -6.488382425],
            [-39.045305401, -6.4580008553],
            [-39.0459582845, -6.4574917711],
            [-39.0620891309, -6.44553601],
            [-39.0795025751, -6.4512317337],
            [-39.09430939, -6.4514058431],
            [-39.0941080146, -6.4587447784],
            [-39.1017275485, -6.4610436073],
            [-39.1041934828, -6.4569123268],
            [-39.1070568847, -6.4645913575],
            [-39.1184479151, -6.4623222275],
            [-39.1217830938, -6.4663554563],
            [-39.1277776497, -6.4650502192],
            [-39.1450003538, -6.4535225981],
            [-39.1455003315, -6.4418590444],
            [-39.1297834226, -6.4261925437],
            [-39.1307663666, -6.4184542901],
            [-39.1363353805, -6.4168608739],
            [-39.1388361903, -6.4122995662],
            [-39.1313345184, -6.4072756932],
            [-39.1295841657, -6.4013961138],
            [-39.1238079935, -6.4004648674],
            [-39.117670479, -6.3901650684],
            [-39.0943022968, -6.3784561243],
            [-39.0820807477, -6.3635071899],
            [-39.0584496785, -6.384622022],
            [-39.0441659741, -6.3806290669],
            [-39.0383344168, -6.3751113657],
            [-39.0134177818, -6.3740511431],
            [-38.9987784789, -6.377778321],
            [-38.9899989878, -6.3749251716],
            [-38.9870536965, -6.3776957732],
            [-38.9804210891, -6.3797272915],
            [-38.9835550525, -6.3593882311],
            [-38.9830833607, -6.3499285018],
            [-38.9732788992, -6.3390439665],
            [-38.9573402668, -6.3343970525],
            [-38.949387322, -6.3385393337],
            [-38.9407288186, -6.3354635106],
            [-38.9357530233, -6.3388186449],
            [-38.9265034567, -6.3330256061],
            [-38.915402852, -6.3094838685],
            [-38.8835806412, -6.3285567143],
            [-38.8367867763, -6.2946409607],
            [-38.8200682947, -6.2699095221],
            [-38.8200332617, -6.2683744688],
            [-38.8255858227, -6.2619274399],
            [-38.8302487038, -6.2475939815],
            [-38.8281809509, -6.2418955135],
            [-38.831157554, -6.2367142494],
            [-38.8248440872, -6.2158821163],
            [-38.8112245235, -6.1627123283],
            [-38.8006223025, -6.1213590929],
            [-38.7874496413, -6.1201385326],
            [-38.7833600653, -6.1146718839],
            [-38.7661660008, -6.1236705347],
            [-38.7559603433, -6.1122333118],
            [-38.7455829164, -6.111182587],
            [-38.745835099, -6.1149214987],
            [-38.736671239, -6.1174574091],
            [-38.7333908313, -6.1239458313],
            [-38.7227534422, -6.1214804243],
            [-38.7105606947, -6.1100320677],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305506',
        name: 'Iguatu',
        description: 'Iguatu',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.2806407098, -6.2111109514],
            [-39.264996289, -6.2225221512],
            [-39.2650931578, -6.2281972864],
            [-39.2708587622, -6.2338699195],
            [-39.2911379474, -6.232935301],
            [-39.2971219872, -6.2374528499],
            [-39.300111631, -6.2509256994],
            [-39.3016708331, -6.2618549262],
            [-39.2983679426, -6.2696153942],
            [-39.2891809644, -6.2768766117],
            [-39.2787227978, -6.2790855732],
            [-39.267508798, -6.2746821657],
            [-39.2565471038, -6.2695554302],
            [-39.2388379829, -6.2753684033],
            [-39.2109349982, -6.2669712104],
            [-39.2090722318, -6.2665473568],
            [-39.1950476065, -6.263358241],
            [-39.1926442074, -6.2624337068],
            [-39.1461993144, -6.2569706381],
            [-39.1266981325, -6.266425366],
            [-39.1189619506, -6.264376753],
            [-39.0971065925, -6.2420951442],
            [-39.0783758566, -6.2357178887],
            [-39.0645851063, -6.2382635074],
            [-39.0563479447, -6.2587018408],
            [-39.0528353998, -6.2960150896],
            [-39.0716121705, -6.3111784538],
            [-39.0824218017, -6.3331155731],
            [-39.0881047671, -6.3340296927],
            [-39.0940007814, -6.3346784153],
            [-39.0979406009, -6.3408275616],
            [-39.1018176314, -6.3554444968],
            [-39.1211237388, -6.3828200559],
            [-39.130495155, -6.3874270173],
            [-39.1295841657, -6.4013961138],
            [-39.1313345184, -6.4072756932],
            [-39.1388361903, -6.4122995662],
            [-39.1363353805, -6.4168608739],
            [-39.1307663666, -6.4184542901],
            [-39.1297834226, -6.4261925437],
            [-39.1455003315, -6.4418590444],
            [-39.1671499965, -6.4473666296],
            [-39.1672244011, -6.4497622553],
            [-39.1736126203, -6.4487680712],
            [-39.1839443655, -6.4525696352],
            [-39.1963012218, -6.4609042814],
            [-39.2060893716, -6.4642278111],
            [-39.2091898762, -6.4843749186],
            [-39.2205648468, -6.490453527],
            [-39.2310323271, -6.492297286],
            [-39.2265334749, -6.5030359677],
            [-39.2275256334, -6.518837254],
            [-39.2367489041, -6.523765473],
            [-39.2467476467, -6.5223962736],
            [-39.2510879455, -6.5263446923],
            [-39.2567817318, -6.5222941991],
            [-39.2825741579, -6.5266807568],
            [-39.3157836512, -6.5298354346],
            [-39.341732713, -6.5346363291],
            [-39.3442841964, -6.5222854352],
            [-39.3579893011, -6.5096166914],
            [-39.3527774176, -6.4999971771],
            [-39.3548070267, -6.4943659375],
            [-39.3523404998, -6.4921335735],
            [-39.355970189, -6.4868274368],
            [-39.348069805, -6.4851457839],
            [-39.3538528058, -6.4661071977],
            [-39.3749961949, -6.4747125682],
            [-39.3763896459, -6.4713060058],
            [-39.3842763046, -6.4705286087],
            [-39.3859130762, -6.4643727151],
            [-39.4003847853, -6.4653431816],
            [-39.400982865, -6.4638525027],
            [-39.4011223763, -6.4635049675],
            [-39.4074962021, -6.4505953508],
            [-39.4118323066, -6.4418106937],
            [-39.4107213624, -6.4338548728],
            [-39.4090444298, -6.4218469587],
            [-39.4070307907, -6.4074322492],
            [-39.4164930362, -6.4028245399],
            [-39.4174491714, -6.3955101834],
            [-39.4199751017, -6.3966233952],
            [-39.4215541148, -6.3920560137],
            [-39.4331698169, -6.396915783],
            [-39.4463379712, -6.3955304036],
            [-39.4572751142, -6.3854345979],
            [-39.4643867368, -6.3770581756],
            [-39.472893339, -6.3745546954],
            [-39.4692814236, -6.3574399486],
            [-39.4645547199, -6.3554713156],
            [-39.4593669141, -6.3247458872],
            [-39.4629192601, -6.2848297072],
            [-39.4634207602, -6.2791930276],
            [-39.4163712553, -6.2609507178],
            [-39.4171960896, -6.2463437473],
            [-39.4059821524, -6.2336793719],
            [-39.3781163098, -6.2151617724],
            [-39.3723580791, -6.2169614026],
            [-39.368898898, -6.2161545808],
            [-39.3585790192, -6.2241030585],
            [-39.3428514191, -6.2088527203],
            [-39.3322893363, -6.1985088799],
            [-39.3162675115, -6.1828198004],
            [-39.3077186607, -6.1744490965],
            [-39.2928170137, -6.1736677561],
            [-39.2771392572, -6.1715953741],
            [-39.2708346397, -6.1784513786],
            [-39.2714121207, -6.1826436001],
            [-39.2773876207, -6.187472097],
            [-39.2758626877, -6.1937020912],
            [-39.2794460243, -6.198064601],
            [-39.2767479294, -6.2036420588],
            [-39.2806407098, -6.2111109514],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305605',
        name: 'Independência',
        description: 'Independência',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.1866693432, -5.1678347849],
            [-40.1882548386, -5.1757380376],
            [-40.1806126447, -5.170019174],
            [-40.1702215739, -5.1744573992],
            [-40.1652492692, -5.1650160263],
            [-40.1586407427, -5.1665740492],
            [-40.1531745499, -5.1636369722],
            [-40.1409314093, -5.1690493907],
            [-40.1385520942, -5.1630764744],
            [-40.1215310477, -5.1651907117],
            [-40.1154360629, -5.1618537964],
            [-40.1126265233, -5.1667422511],
            [-40.0977372445, -5.1633676993],
            [-40.0913261684, -5.1724273035],
            [-40.0851272114, -5.1738090154],
            [-40.0747542835, -5.1650459527],
            [-40.0591659482, -5.1856861046],
            [-40.0566482536, -5.18611449],
            [-40.0550030899, -5.1809319524],
            [-40.0351439059, -5.1827923391],
            [-40.0294223594, -5.1916349533],
            [-40.0308663565, -5.2000489496],
            [-40.0208894806, -5.2054656644],
            [-40.0350283529, -5.2216001282],
            [-40.0373680223, -5.2302245239],
            [-40.0462533851, -5.2386930528],
            [-40.0459334631, -5.2428221134],
            [-40.0426715546, -5.2609651903],
            [-40.0468392976, -5.2717587021],
            [-40.0480291108, -5.2816749423],
            [-40.0550071377, -5.2848318275],
            [-40.0552230906, -5.2910662824],
            [-40.0291698912, -5.2962836438],
            [-40.0244218218, -5.3018656711],
            [-40.0278886284, -5.3111900235],
            [-40.0180852588, -5.3184787768],
            [-40.0224773334, -5.3241192508],
            [-40.0205332514, -5.3275777954],
            [-40.023166357, -5.3372459216],
            [-40.0281730951, -5.3415423329],
            [-40.0271121258, -5.3458636901],
            [-40.0385021466, -5.3534632485],
            [-40.0550060348, -5.3578442221],
            [-40.0556693713, -5.3638497564],
            [-40.0516471377, -5.366106347],
            [-40.056420488, -5.3699556732],
            [-40.0610865729, -5.3895028851],
            [-40.0583072461, -5.3964660694],
            [-40.0686819362, -5.4028140014],
            [-40.0772915018, -5.4211822505],
            [-40.08296452, -5.4223230097],
            [-40.0834148989, -5.4275373885],
            [-40.0906285285, -5.4358617336],
            [-40.0897541876, -5.4402839131],
            [-40.1009237726, -5.4433276281],
            [-40.0974663132, -5.4464706366],
            [-40.1007912641, -5.451370283],
            [-40.1066998786, -5.4520951255],
            [-40.1091549428, -5.4620221682],
            [-40.1062554196, -5.4671820483],
            [-40.1119109247, -5.4909604987],
            [-40.1080279323, -5.5060296447],
            [-40.111731141, -5.5167589984],
            [-40.1088995969, -5.5270140995],
            [-40.110009235, -5.5347127602],
            [-40.1151077896, -5.5386629738],
            [-40.1187227726, -5.5481599212],
            [-40.1238265611, -5.5543111831],
            [-40.1410986354, -5.5456526548],
            [-40.1430660765, -5.5408538645],
            [-40.1587833298, -5.5456638237],
            [-40.1668110076, -5.5448108445],
            [-40.1728088475, -5.5599348776],
            [-40.1795203231, -5.5585581651],
            [-40.1851430082, -5.5616256013],
            [-40.1859066847, -5.5704686093],
            [-40.1944190847, -5.5740347157],
            [-40.2266770578, -5.5929993052],
            [-40.2289280796, -5.599726461],
            [-40.2196352357, -5.6044093453],
            [-40.2241314464, -5.6064859651],
            [-40.2328218432, -5.6143233846],
            [-40.2293638345, -5.6187765181],
            [-40.2336456513, -5.6307793359],
            [-40.2272323908, -5.6486979259],
            [-40.2308984385, -5.6595580963],
            [-40.2201622487, -5.6674575851],
            [-40.2105561487, -5.6913214027],
            [-40.2216768316, -5.6992630282],
            [-40.2256048051, -5.7093298468],
            [-40.2364015213, -5.7093948155],
            [-40.2405255028, -5.7078017214],
            [-40.2408134175, -5.7192397042],
            [-40.2558007446, -5.7284892664],
            [-40.2816617237, -5.7514886256],
            [-40.2842633463, -5.7575963201],
            [-40.2960546096, -5.7496947684],
            [-40.3116419975, -5.7464233408],
            [-40.3118702195, -5.7462483575],
            [-40.3196081902, -5.7422947497],
            [-40.3409548017, -5.7481401444],
            [-40.3516956027, -5.7433186736],
            [-40.3681174045, -5.7453906654],
            [-40.3709858427, -5.7526436999],
            [-40.3806148058, -5.759000121],
            [-40.3820838853, -5.7677509838],
            [-40.3883387955, -5.7764463147],
            [-40.3976112427, -5.7806029971],
            [-40.4119952948, -5.8100433627],
            [-40.4170057795, -5.8116484534],
            [-40.4445673317, -5.8257493948],
            [-40.4529940611, -5.8281976381],
            [-40.4729632963, -5.8260779729],
            [-40.4762258588, -5.8317005813],
            [-40.4854085764, -5.8347113421],
            [-40.4939496366, -5.827694415],
            [-40.4941594224, -5.8276473792],
            [-40.5159584386, -5.8245562368],
            [-40.5115887151, -5.8151091403],
            [-40.5208899173, -5.8132373593],
            [-40.5237969413, -5.8172748809],
            [-40.5298969249, -5.8164236433],
            [-40.5331198071, -5.8239370245],
            [-40.5481987003, -5.8319465062],
            [-40.5570075843, -5.8420389179],
            [-40.5650381583, -5.8416306763],
            [-40.5691162029, -5.8285508421],
            [-40.5659416239, -5.8214144708],
            [-40.5687192324, -5.8137399492],
            [-40.5673669553, -5.7942690452],
            [-40.563975954, -5.7864476382],
            [-40.569666681, -5.7713924143],
            [-40.5705735902, -5.7541006409],
            [-40.5569645019, -5.7389649451],
            [-40.5468461927, -5.7395142538],
            [-40.5377370049, -5.7225596142],
            [-40.5705076577, -5.7393802764],
            [-40.5981540455, -5.7373947027],
            [-40.6027436489, -5.7258191324],
            [-40.6115987122, -5.7239547456],
            [-40.6129665243, -5.7270149719],
            [-40.620553325, -5.7184141382],
            [-40.6193042334, -5.7131912264],
            [-40.6335814899, -5.7006417317],
            [-40.6274174481, -5.6940193309],
            [-40.6245825668, -5.6952647447],
            [-40.6194769631, -5.6912836043],
            [-40.6175564794, -5.6839481857],
            [-40.6324367397, -5.6800109743],
            [-40.6407648231, -5.6842768099],
            [-40.6459279125, -5.67696288],
            [-40.6532735557, -5.6676966827],
            [-40.6470165773, -5.6653651163],
            [-40.6475928295, -5.65739738],
            [-40.6382047157, -5.6438390471],
            [-40.6345707666, -5.6468108685],
            [-40.6314640944, -5.6435508276],
            [-40.6282224422, -5.6312017587],
            [-40.6380956571, -5.6244317518],
            [-40.6385992938, -5.6195783371],
            [-40.6383969232, -5.6153423693],
            [-40.6266924201, -5.6085300781],
            [-40.62003231, -5.5984349009],
            [-40.6277678972, -5.5853414626],
            [-40.62704382, -5.5786522829],
            [-40.6214397937, -5.5761647543],
            [-40.6210950804, -5.5702828331],
            [-40.6131645724, -5.57077488],
            [-40.6136372766, -5.5649517294],
            [-40.6086012283, -5.5579106561],
            [-40.6160310027, -5.5482406905],
            [-40.6206744437, -5.5291378545],
            [-40.5969607997, -5.4907941339],
            [-40.5841777049, -5.4670258354],
            [-40.5771452987, -5.4629716737],
            [-40.5686890317, -5.4379910084],
            [-40.5525067147, -5.4318854758],
            [-40.5543385102, -5.4263034406],
            [-40.5484316361, -5.3984170698],
            [-40.539185464, -5.389086791],
            [-40.5234785107, -5.3893259423],
            [-40.4868957714, -5.2948684037],
            [-40.4810756953, -5.2792233993],
            [-40.479092614, -5.2738941182],
            [-40.4141967763, -5.2289463476],
            [-40.406863071, -5.2236564651],
            [-40.3812657896, -5.2051931191],
            [-40.3548781102, -5.1861618498],
            [-40.3312935271, -5.1691562519],
            [-40.3109613, -5.1563658766],
            [-40.3054180925, -5.1667447573],
            [-40.2980273003, -5.1700122128],
            [-40.2695833099, -5.1622610631],
            [-40.2571999385, -5.1659775031],
            [-40.2577928948, -5.1712645966],
            [-40.248404695, -5.167869001],
            [-40.2416665541, -5.1706520529],
            [-40.2389919495, -5.1624728751],
            [-40.2280344553, -5.1522426321],
            [-40.2187567277, -5.1519594746],
            [-40.2114418013, -5.1557516534],
            [-40.1983111916, -5.1648356443],
            [-40.1866693432, -5.1678347849],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305654',
        name: 'Ipaporanga',
        description: 'Ipaporanga',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.8422536208, -4.7855319385],
            [-40.8327561475, -4.803208121],
            [-40.8240701775, -4.8109876269],
            [-40.8094913447, -4.8167986101],
            [-40.802097908, -4.8166588841],
            [-40.7985622197, -4.8153902312],
            [-40.7951506218, -4.8165275257],
            [-40.7828390011, -4.8245331701],
            [-40.7806948284, -4.8350560791],
            [-40.7644274646, -4.8433265918],
            [-40.7621142409, -4.8662502795],
            [-40.7235624849, -4.8427748221],
            [-40.7211735064, -4.8476071881],
            [-40.7186169333, -4.8514752728],
            [-40.6966327438, -4.8626365783],
            [-40.6765297694, -4.851369051],
            [-40.6651977371, -4.8433506082],
            [-40.6595641836, -4.8309904599],
            [-40.6501306709, -4.8137713641],
            [-40.6474162416, -4.8065684095],
            [-40.6341183414, -4.7929474405],
            [-40.615580218, -4.8131107439],
            [-40.6014789385, -4.8323347679],
            [-40.5990021368, -4.8357101305],
            [-40.5420839082, -4.9148046882],
            [-40.5967579912, -4.9203140548],
            [-40.6354497739, -4.9243361364],
            [-40.6518992559, -4.9854015222],
            [-40.7148185973, -4.9476797483],
            [-40.7150651975, -4.9477488653],
            [-40.8264412231, -4.9787872086],
            [-40.8649024481, -4.9867746161],
            [-40.8752328114, -4.9888169294],
            [-41.0307615761, -5.0195699621],
            [-41.0310445623, -5.0196257114],
            [-41.0514930258, -5.0236693105],
            [-41.0522684105, -5.0173429206],
            [-41.0433627781, -4.9981110563],
            [-41.0358559025, -4.9651406136],
            [-41.0242394428, -4.9536847296],
            [-41.0218953883, -4.9545129413],
            [-41.0079280402, -4.9393146618],
            [-41.0078176048, -4.935417573],
            [-41.0033948372, -4.933884382],
            [-41.0061972178, -4.9306680922],
            [-40.9997381382, -4.9280891595],
            [-40.9918261974, -4.9151510187],
            [-40.9505009652, -4.8871208831],
            [-40.941502835, -4.8824787084],
            [-40.9266187242, -4.8858713734],
            [-40.9189133878, -4.8833563117],
            [-40.9092680384, -4.8745027772],
            [-40.8836147519, -4.8662821315],
            [-40.8790581456, -4.8678966605],
            [-40.8720772686, -4.8626733493],
            [-40.880316263, -4.8594152859],
            [-40.8751754115, -4.8531949969],
            [-40.8810375033, -4.8430803064],
            [-40.879523278, -4.8392327106],
            [-40.8760110689, -4.8320617473],
            [-40.879720831, -4.8295563509],
            [-40.8775457102, -4.818081445],
            [-40.879310732, -4.8127382985],
            [-40.885901015, -4.797102836],
            [-40.8801441126, -4.7996136248],
            [-40.8823041454, -4.7933117072],
            [-40.8788615124, -4.78705221],
            [-40.8640397221, -4.7878150791],
            [-40.8648672719, -4.7838249857],
            [-40.8422536208, -4.7855319385],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305704',
        name: 'Ipaumirim',
        description: 'Ipaumirim',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.8029566911, -6.7236148515],
            [-38.796831189, -6.7239860748],
            [-38.789945917, -6.7187987622],
            [-38.7790536506, -6.7207242082],
            [-38.7738014544, -6.7179111145],
            [-38.7741058798, -6.7272173286],
            [-38.7605554906, -6.7369626662],
            [-38.7602682444, -6.7438811882],
            [-38.7493538072, -6.7555623268],
            [-38.7296046978, -6.7591504742],
            [-38.7199747422, -6.768203377],
            [-38.6996385907, -6.7618372553],
            [-38.6994591664, -6.7646819893],
            [-38.6857540253, -6.766661165],
            [-38.680829329, -6.7544938898],
            [-38.6692538566, -6.7589454905],
            [-38.6520495606, -6.7566513351],
            [-38.6523266964, -6.7593634686],
            [-38.6399145349, -6.7635889417],
            [-38.6286713146, -6.7773082687],
            [-38.6135745027, -6.7820373882],
            [-38.6170593568, -6.7935774021],
            [-38.6291162625, -6.7895008853],
            [-38.6397791784, -6.7887947677],
            [-38.6419720473, -6.7912789347],
            [-38.6351061833, -6.8042195039],
            [-38.6480849323, -6.8069848196],
            [-38.6544150107, -6.8128607009],
            [-38.6552662573, -6.8137647743],
            [-38.6575198777, -6.8161594368],
            [-38.6614344433, -6.8232763524],
            [-38.6752158929, -6.829810743],
            [-38.6775910983, -6.8341123139],
            [-38.6699811125, -6.8429688241],
            [-38.6719207227, -6.8465764733],
            [-38.6768472843, -6.8539264398],
            [-38.6750629898, -6.8614279073],
            [-38.6781652584, -6.8636641559],
            [-38.6863371702, -6.8653902219],
            [-38.7000558722, -6.8762007334],
            [-38.7087766761, -6.8740240756],
            [-38.7138068535, -6.8763821357],
            [-38.7136586546, -6.8857450548],
            [-38.7283035031, -6.8869740835],
            [-38.7311780883, -6.8888700272],
            [-38.7299342711, -6.8964118247],
            [-38.7559246557, -6.903189941],
            [-38.764858111, -6.9105122381],
            [-38.7841878914, -6.9057342763],
            [-38.7850817779, -6.8906213733],
            [-38.7757781177, -6.8844446178],
            [-38.7745883906, -6.8681739924],
            [-38.7859989455, -6.8630146222],
            [-38.812856516, -6.8640443754],
            [-38.8172196542, -6.8612518557],
            [-38.8193158493, -6.8563433237],
            [-38.8560263357, -6.858905808],
            [-38.8356707677, -6.84755612],
            [-38.811042307, -6.8465083312],
            [-38.809610861, -6.8388250567],
            [-38.8153580071, -6.8256677031],
            [-38.8136104877, -6.8200449688],
            [-38.8186148015, -6.8071703643],
            [-38.8168661664, -6.8001034967],
            [-38.8202118185, -6.7882446734],
            [-38.8232529764, -6.7778992291],
            [-38.7901572596, -6.7726058151],
            [-38.828900078, -6.7445370867],
            [-38.827998636, -6.7364066536],
            [-38.8425596416, -6.7249356545],
            [-38.8479562448, -6.7185512177],
            [-38.8473431066, -6.7097338296],
            [-38.8371652003, -6.7093213706],
            [-38.8139074058, -6.7196773346],
            [-38.8029566911, -6.7236148515],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305803',
        name: 'Ipu',
        description: 'Ipu',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.6944960332, -4.2292095224],
            [-40.6921392069, -4.2308759045],
            [-40.7015242668, -4.2442593904],
            [-40.6966269916, -4.2453959943],
            [-40.6934479639, -4.2504425287],
            [-40.6932655473, -4.268029977],
            [-40.6975851718, -4.2796012083],
            [-40.7044804095, -4.2865029749],
            [-40.6891584513, -4.2913314855],
            [-40.6806037739, -4.2914898834],
            [-40.6827605742, -4.297007647],
            [-40.6778681378, -4.2991091659],
            [-40.6703760485, -4.2963379349],
            [-40.6726970563, -4.3004071146],
            [-40.6637796943, -4.308417064],
            [-40.6613846574, -4.3033991717],
            [-40.6519511547, -4.2965521662],
            [-40.6471415946, -4.2955975142],
            [-40.6238112653, -4.3038626202],
            [-40.6196528083, -4.3104720356],
            [-40.6096206195, -4.3044875857],
            [-40.5952547156, -4.3094334226],
            [-40.5785299555, -4.3070526466],
            [-40.568420656, -4.3136733979],
            [-40.5571451404, -4.3138557918],
            [-40.546267291, -4.3093914372],
            [-40.5391092289, -4.3075667047],
            [-40.4782535966, -4.293407198],
            [-40.4784408479, -4.2935160205],
            [-40.4882228005, -4.2991845263],
            [-40.4952856075, -4.3217830693],
            [-40.499892315, -4.3256373181],
            [-40.5141688747, -4.3268284509],
            [-40.5217600494, -4.338508527],
            [-40.5231728552, -4.355641112],
            [-40.548753766, -4.3773311352],
            [-40.5543070808, -4.3876791406],
            [-40.552338479, -4.398285281],
            [-40.5590460728, -4.3995765314],
            [-40.5605490479, -4.4010597239],
            [-40.5607333571, -4.4019704765],
            [-40.5649776458, -4.4191517065],
            [-40.5609512754, -4.42936961],
            [-40.5536975071, -4.4341985177],
            [-40.5538997261, -4.4380926054],
            [-40.5653679554, -4.4557609247],
            [-40.5848264124, -4.4755144066],
            [-40.5794706028, -4.4878890983],
            [-40.5839769242, -4.4912172538],
            [-40.5828621136, -4.5041308257],
            [-40.5903958737, -4.5099531267],
            [-40.5905057452, -4.5171906177],
            [-40.5983540809, -4.5295487796],
            [-40.5883015111, -4.5578012728],
            [-40.593741392, -4.5620694678],
            [-40.5970964094, -4.5574653864],
            [-40.6140187773, -4.550927173],
            [-40.6241225063, -4.5325579207],
            [-40.6360165568, -4.5220371719],
            [-40.6441364496, -4.5046244551],
            [-40.6464862397, -4.4873974795],
            [-40.65609479, -4.4699168428],
            [-40.6570915926, -4.4637406184],
            [-40.6616766326, -4.4622279623],
            [-40.6771249479, -4.4381781434],
            [-40.6808039982, -4.4266244858],
            [-40.6797721148, -4.4336541617],
            [-40.6949856263, -4.4513554928],
            [-40.7031034132, -4.4806962687],
            [-40.7068546715, -4.4826792253],
            [-40.7163237088, -4.4761945216],
            [-40.7334364683, -4.4644736673],
            [-40.7741627877, -4.4365684251],
            [-40.7826622558, -4.4307418521],
            [-40.7936203727, -4.4232289709],
            [-40.7853815457, -4.4186243161],
            [-40.7845547013, -4.4118976024],
            [-40.7783839438, -4.4074895814],
            [-40.7867941358, -4.4058222552],
            [-40.7895233271, -4.4059858219],
            [-40.7827617212, -4.3875325756],
            [-40.7704795672, -4.3810666818],
            [-40.7746486126, -4.3674501696],
            [-40.7643294977, -4.3598835334],
            [-40.7667014149, -4.3480043461],
            [-40.769733147, -4.3484227215],
            [-40.7768968236, -4.3617281477],
            [-40.7840565519, -4.3599524566],
            [-40.7990325462, -4.3632090797],
            [-40.8004884533, -4.3621036467],
            [-40.8039356129, -4.3533318236],
            [-40.802751039, -4.3447688415],
            [-40.7991251426, -4.3307054159],
            [-40.7907878112, -4.3227039975],
            [-40.7906729045, -4.3225267233],
            [-40.7873272224, -4.3104098438],
            [-40.7909826722, -4.2921555567],
            [-40.8018200327, -4.2741940678],
            [-40.8026386261, -4.2663391639],
            [-40.796584392, -4.259181531],
            [-40.7898859579, -4.2537366682],
            [-40.7868674684, -4.2408598079],
            [-40.7720876673, -4.2320927208],
            [-40.7672395484, -4.2313899963],
            [-40.7647627276, -4.231302909],
            [-40.7631754943, -4.2306703864],
            [-40.761871978, -4.2307191321],
            [-40.7374974792, -4.2205239468],
            [-40.7353481394, -4.2110150078],
            [-40.7331101023, -4.2038679561],
            [-40.7287533278, -4.2013188567],
            [-40.6819730061, -4.2117024651],
            [-40.6817556019, -4.2141126337],
            [-40.690248625, -4.2220852145],
            [-40.694431474, -4.2221833247],
            [-40.6944960332, -4.2292095224],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305902',
        name: 'Ipueiras',
        description: 'Ipueiras',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.7741627877, -4.4365684251],
            [-40.7334364683, -4.4644736673],
            [-40.7163237088, -4.4761945216],
            [-40.7068546715, -4.4826792253],
            [-40.7031034132, -4.4806962687],
            [-40.6949856263, -4.4513554928],
            [-40.6797721148, -4.4336541617],
            [-40.6808039982, -4.4266244858],
            [-40.6771249479, -4.4381781434],
            [-40.6616766326, -4.4622279623],
            [-40.6570915926, -4.4637406184],
            [-40.65609479, -4.4699168428],
            [-40.6464862397, -4.4873974795],
            [-40.6441364496, -4.5046244551],
            [-40.6360165568, -4.5220371719],
            [-40.6241225063, -4.5325579207],
            [-40.6140187773, -4.550927173],
            [-40.5970964094, -4.5574653864],
            [-40.593741392, -4.5620694678],
            [-40.5883015111, -4.5578012728],
            [-40.5878488585, -4.5575254258],
            [-40.5876024863, -4.574794015],
            [-40.5834125889, -4.5780082309],
            [-40.5879214706, -4.5976692773],
            [-40.602056366, -4.6187591472],
            [-40.6223375769, -4.6254527794],
            [-40.6260720273, -4.6216288789],
            [-40.6262774719, -4.6220103779],
            [-40.6611410839, -4.6866698966],
            [-40.6705221018, -4.7029342995],
            [-40.7316945571, -4.7141012842],
            [-40.7369041934, -4.7084548965],
            [-40.7523765428, -4.7111240627],
            [-40.7621088959, -4.706217113],
            [-40.7780165882, -4.7110039379],
            [-40.7811496542, -4.7079361111],
            [-40.7852215808, -4.7114148987],
            [-40.8078832136, -4.7047033523],
            [-40.8138381809, -4.7090937973],
            [-40.8331400192, -4.7177571124],
            [-40.8444651722, -4.7180641072],
            [-40.858724653, -4.7119643726],
            [-40.8634756415, -4.7151672781],
            [-40.8723047148, -4.7133389915],
            [-40.8668315385, -4.7062643915],
            [-40.8673662452, -4.698926707],
            [-40.8628115586, -4.6906602252],
            [-40.8527291239, -4.6856107523],
            [-40.8546973615, -4.6773814015],
            [-40.8442362599, -4.6729521321],
            [-40.8570692523, -4.6729514269],
            [-40.8608699721, -4.6794525126],
            [-40.8710056297, -4.6858820332],
            [-40.8745355138, -4.6915431256],
            [-40.8743071586, -4.701556221],
            [-40.8881432437, -4.7166420869],
            [-40.954540492, -4.7158950021],
            [-40.9628969878, -4.6873171004],
            [-40.9685238536, -4.6918144773],
            [-40.973438945, -4.6891213409],
            [-40.9874905206, -4.6596503871],
            [-40.9881152544, -4.6598406515],
            [-41.0078553873, -4.6510084704],
            [-41.0211747698, -4.6389816944],
            [-41.0300474219, -4.6398444106],
            [-41.0348487051, -4.6349116635],
            [-41.0515460697, -4.6290640474],
            [-41.0691546736, -4.6147309115],
            [-41.0870258155, -4.6130724808],
            [-41.0991019006, -4.6023725264],
            [-41.114231088, -4.6013811023],
            [-41.1464042503, -4.599271889],
            [-41.158800175, -4.593687272],
            [-41.1650587148, -4.5844738716],
            [-41.1836982512, -4.5918681823],
            [-41.1874737348, -4.5880509996],
            [-41.1849410845, -4.5765975413],
            [-41.2076719394, -4.5853370934],
            [-41.2168696406, -4.5833568915],
            [-41.2197816958, -4.5740700963],
            [-41.2394837713, -4.573143929],
            [-41.2421652858, -4.5711315337],
            [-41.2403654145, -4.5680713142],
            [-41.2217057308, -4.5672766215],
            [-41.2137380735, -4.5522949061],
            [-41.207557408, -4.5473562883],
            [-41.2034166963, -4.5280395537],
            [-41.190402206, -4.5151472015],
            [-41.188973482, -4.4882991096],
            [-41.1771980797, -4.467296141],
            [-41.1618680622, -4.4662905604],
            [-41.1458661214, -4.4697462124],
            [-41.1257154517, -4.4830084306],
            [-41.1199487671, -4.4785505693],
            [-41.0627637344, -4.4737779085],
            [-41.0274686131, -4.4565002899],
            [-41.0053746645, -4.4591378584],
            [-40.9208361221, -4.4692221342],
            [-40.9185552996, -4.4685195812],
            [-40.8885267556, -4.4719344423],
            [-40.8830453803, -4.4690175805],
            [-40.864440677, -4.4638351473],
            [-40.852965156, -4.465527712],
            [-40.8251662925, -4.4585439789],
            [-40.8109526479, -4.4430991522],
            [-40.7971663945, -4.4410375126],
            [-40.7886286922, -4.4305392029],
            [-40.7936203727, -4.4232289709],
            [-40.7826622558, -4.4307418521],
            [-40.7741627877, -4.4365684251],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2306009',
        name: 'Iracema',
        description: 'Iracema',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.2585201146, -5.6613170395],
            [-38.2669840662, -5.6819630955],
            [-38.264180939, -5.6900062467],
            [-38.2661791311, -5.6959114504],
            [-38.2592219234, -5.7128708123],
            [-38.2598583242, -5.7162676614],
            [-38.2485955507, -5.7315870002],
            [-38.2566844292, -5.7371002433],
            [-38.2502696945, -5.7530564085],
            [-38.244843266, -5.7826245631],
            [-38.2447579995, -5.7828602764],
            [-38.2459470447, -5.786029609],
            [-38.2414671318, -5.791210011],
            [-38.244514749, -5.7968317797],
            [-38.2360584866, -5.8007692976],
            [-38.2367694499, -5.8076464015],
            [-38.2258846102, -5.8182936678],
            [-38.2265234246, -5.8244476999],
            [-38.2226498777, -5.8294095105],
            [-38.2271010081, -5.833364322],
            [-38.2158826352, -5.8376531973],
            [-38.2109685385, -5.8414932561],
            [-38.2002904, -5.842622217],
            [-38.1992310904, -5.8464442617],
            [-38.1858025614, -5.8423489542],
            [-38.1838947344, -5.84680484],
            [-38.1676352296, -5.8527678968],
            [-38.1595651252, -5.8600080081],
            [-38.1487491197, -5.8606352735],
            [-38.145918559, -5.8690376588],
            [-38.1515430863, -5.8757965419],
            [-38.1512113263, -5.8803807257],
            [-38.145752768, -5.8914541872],
            [-38.1517198512, -5.9019126888],
            [-38.1586446739, -5.9040834968],
            [-38.1639378409, -5.9144075621],
            [-38.1589490034, -5.924181544],
            [-38.1596587799, -5.924564485],
            [-38.2597947725, -5.9244099054],
            [-38.2734327328, -5.9094687674],
            [-38.2869858928, -5.8946147536],
            [-38.3141371643, -5.8648458342],
            [-38.3162029992, -5.8692977524],
            [-38.3437253704, -5.8817152746],
            [-38.3470440773, -5.8879025467],
            [-38.3563193165, -5.8928775403],
            [-38.363453944, -5.9055431795],
            [-38.3722952043, -5.9118820171],
            [-38.3758085072, -5.9110709947],
            [-38.3856031662, -5.9167120419],
            [-38.3892864535, -5.9145141445],
            [-38.4015320472, -5.917554084],
            [-38.4067102562, -5.9083419029],
            [-38.4056553899, -5.9010477323],
            [-38.4112516724, -5.8977789531],
            [-38.4138472597, -5.9047825222],
            [-38.4191672131, -5.9018900646],
            [-38.4170383409, -5.8864813412],
            [-38.4193776012, -5.8795959141],
            [-38.4341308807, -5.8853920554],
            [-38.442693848, -5.8763583336],
            [-38.4491320374, -5.8787144658],
            [-38.4594901092, -5.8664056492],
            [-38.4631196718, -5.8734771025],
            [-38.4744006996, -5.8771140618],
            [-38.4750431516, -5.8881718366],
            [-38.486700328, -5.8881793824],
            [-38.4826952472, -5.883645371],
            [-38.4663037053, -5.8247283549],
            [-38.4639668971, -5.8043213684],
            [-38.467755893, -5.8020716971],
            [-38.4619353106, -5.7919860757],
            [-38.4625542334, -5.7803536487],
            [-38.4769955325, -5.7804222352],
            [-38.4772888612, -5.7804622602],
            [-38.4778719635, -5.7756848072],
            [-38.4825495123, -5.7756893497],
            [-38.4812945559, -5.7678927058],
            [-38.4910942947, -5.7719425186],
            [-38.4892423644, -5.7663108986],
            [-38.4948865885, -5.7674716524],
            [-38.4957048642, -5.7648740053],
            [-38.4886548294, -5.7596696682],
            [-38.4888086453, -5.7533193469],
            [-38.4851696475, -5.7527395259],
            [-38.4814636565, -5.7459504175],
            [-38.4927489686, -5.7487028811],
            [-38.487331295, -5.7449452003],
            [-38.4876309051, -5.7413364775],
            [-38.4687766349, -5.7332340104],
            [-38.4630561645, -5.7359726665],
            [-38.4642494471, -5.7294775145],
            [-38.4603125613, -5.7309181632],
            [-38.4613570247, -5.7270213337],
            [-38.4593578873, -5.7208075436],
            [-38.4615182335, -5.7134511072],
            [-38.4579598369, -5.7062290888],
            [-38.4584842361, -5.713796018],
            [-38.4520153141, -5.7147449226],
            [-38.4508216893, -5.7122845034],
            [-38.4533664048, -5.7016260078],
            [-38.4572318054, -5.70053648],
            [-38.4567654803, -5.6855898884],
            [-38.4592180418, -5.6809930239],
            [-38.4562760277, -5.6747073244],
            [-38.4501548107, -5.6745599354],
            [-38.4514986154, -5.6662268492],
            [-38.4414550101, -5.6566463427],
            [-38.4437067861, -5.6540512082],
            [-38.4413362328, -5.6497668934],
            [-38.4451246751, -5.6417536406],
            [-38.4368335792, -5.6379162023],
            [-38.4330490952, -5.6267318639],
            [-38.417442131, -5.6243930834],
            [-38.2677145349, -5.6014629115],
            [-38.2652304805, -5.6267215408],
            [-38.2482669213, -5.6468345404],
            [-38.247162077, -5.6510572351],
            [-38.2557001369, -5.6581213401],
            [-38.2583949193, -5.6611751461],
            [-38.2585201146, -5.6613170395],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2306108',
        name: 'Irauçuba',
        description: 'Irauçuba',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.7128602091, -3.6547143469],
            [-39.7127247388, -3.6547411931],
            [-39.7074538263, -3.6366434268],
            [-39.707989666, -3.6236165055],
            [-39.7022573033, -3.6180959282],
            [-39.6972872129, -3.6418102884],
            [-39.7089073072, -3.6832400654],
            [-39.7090168641, -3.6843074407],
            [-39.7067972091, -3.6911833094],
            [-39.6953133217, -3.6995441749],
            [-39.6900983621, -3.7085772093],
            [-39.686637529, -3.7264663824],
            [-39.6865870998, -3.7336623465],
            [-39.6856747002, -3.7402675542],
            [-39.6861238987, -3.7426627885],
            [-39.6862570438, -3.7433752836],
            [-39.6775600994, -3.7576037596],
            [-39.6594452731, -3.7640053767],
            [-39.6533659537, -3.7732671425],
            [-39.6518874943, -3.7928918656],
            [-39.6462505615, -3.8018352889],
            [-39.6415095543, -3.8206819522],
            [-39.6616283228, -3.8243649524],
            [-39.6669245371, -3.8315850244],
            [-39.679524721, -3.8326767464],
            [-39.6828641216, -3.837307318],
            [-39.6922557845, -3.8357140623],
            [-39.6932805118, -3.8437127529],
            [-39.7075835683, -3.8530607084],
            [-39.7130001928, -3.868026909],
            [-39.721114209, -3.8715667029],
            [-39.7124204938, -3.8850941149],
            [-39.7195362223, -3.886614647],
            [-39.7155613733, -3.8935321012],
            [-39.7175022269, -3.898538388],
            [-39.7113104467, -3.9030217322],
            [-39.7161714249, -3.9177178889],
            [-39.7118721739, -3.9229724511],
            [-39.710768995, -3.9289922247],
            [-39.7048341023, -3.9465898138],
            [-39.716033394, -3.9545933033],
            [-39.7139195375, -3.9580041697],
            [-39.7193699433, -3.9708520346],
            [-39.7079459254, -3.9767453375],
            [-39.7033918612, -3.9930282817],
            [-39.7042867211, -4.0013513715],
            [-39.6998086664, -4.0035453325],
            [-39.708063458, -4.0113435381],
            [-39.7080856349, -4.0172986941],
            [-39.6953655528, -4.0218174364],
            [-39.6921295424, -4.0222200718],
            [-39.6842046433, -4.0354447221],
            [-39.6752412897, -4.0555289319],
            [-39.6712563407, -4.0742340583],
            [-39.6645876159, -4.0812948809],
            [-39.6795314125, -4.0753699873],
            [-39.6829809861, -4.0806638006],
            [-39.696554616, -4.0873549774],
            [-39.7030863082, -4.0904643525],
            [-39.7055582509, -4.1045604401],
            [-39.7159630098, -4.0961004233],
            [-39.7209524149, -4.1028026234],
            [-39.7383087756, -4.1087269982],
            [-39.7421053757, -4.1138468229],
            [-39.748882987, -4.1247411998],
            [-39.7492504121, -4.1297455003],
            [-39.7553947643, -4.1342392228],
            [-39.7571141342, -4.1574742711],
            [-39.7685340743, -4.1584397217],
            [-39.7735507467, -4.1733229756],
            [-39.7805556903, -4.1747412955],
            [-39.7878650214, -4.1719936774],
            [-39.7876186923, -4.1669055082],
            [-39.7950018159, -4.1592257342],
            [-39.7937117354, -4.1548439501],
            [-39.7974887115, -4.1536657937],
            [-39.8044123756, -4.1407534992],
            [-39.8053828579, -4.1393479963],
            [-39.8083137002, -4.1351010384],
            [-39.8067770748, -4.125160331],
            [-39.8103496628, -4.1202258072],
            [-39.8109460862, -4.1162329392],
            [-39.8402638263, -4.113148138],
            [-39.857245565, -4.1023842879],
            [-39.8489708579, -4.0877869926],
            [-39.8532208899, -4.0778826326],
            [-39.8766403267, -4.0626881341],
            [-39.8714517896, -4.0513368235],
            [-39.8726850701, -4.0465372701],
            [-39.8683938627, -4.0439094655],
            [-39.8878244259, -4.0389394637],
            [-39.8983083369, -4.0427657885],
            [-39.9020057959, -4.0383065837],
            [-39.905578902, -4.0404974682],
            [-39.914986105, -4.0344198805],
            [-39.9226931926, -4.0378481005],
            [-39.9261521125, -4.0352129601],
            [-39.9210912746, -4.0254092658],
            [-39.9218802001, -4.0201147082],
            [-39.9139082633, -4.0191038057],
            [-39.9157991584, -4.0069689793],
            [-39.9097946616, -4.0042848719],
            [-39.914428977, -4.0022370997],
            [-39.9157264318, -3.9975006817],
            [-39.9224893615, -3.9976461191],
            [-39.9243534463, -3.9880184519],
            [-39.9278807618, -3.9892852231],
            [-39.9314629136, -3.9860938838],
            [-39.93943836, -3.989022014],
            [-39.9416149494, -3.9758183872],
            [-39.9453905705, -3.9752260266],
            [-39.9520796124, -3.963924522],
            [-39.9730814607, -3.9459492282],
            [-39.9934855994, -3.9361774907],
            [-39.999770796, -3.9278006206],
            [-39.9970812692, -3.9196661364],
            [-40.0069380359, -3.8986558213],
            [-39.9237739053, -3.9471062002],
            [-39.9506304674, -3.85946953],
            [-39.9506790419, -3.8593113948],
            [-39.9708625528, -3.7933517939],
            [-39.9757235251, -3.7774545803],
            [-40.0116653878, -3.7614377971],
            [-40.0130701734, -3.7539967061],
            [-40.0154383965, -3.7413047254],
            [-40.0128934384, -3.7387816081],
            [-40.0141741508, -3.7249687687],
            [-40.0177757039, -3.7219849436],
            [-40.0153716229, -3.7119695029],
            [-39.9657475883, -3.7157357432],
            [-39.9143482426, -3.7210177601],
            [-39.9119593989, -3.7177027393],
            [-39.9063988832, -3.6990504241],
            [-39.9082849083, -3.6915057452],
            [-39.9000334349, -3.6777426685],
            [-39.9001645545, -3.6614414642],
            [-39.8316053881, -3.604131433],
            [-39.8314916598, -3.6040366909],
            [-39.8169367193, -3.6039090285],
            [-39.807236218, -3.6044251875],
            [-39.8091241294, -3.6096439735],
            [-39.8025101945, -3.6124389289],
            [-39.8006323995, -3.6186751383],
            [-39.7795564516, -3.6356276282],
            [-39.7804750756, -3.6414541599],
            [-39.7128602091, -3.6547143469],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2306207',
        name: 'Itaiçaba',
        description: 'Itaiçaba',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.9085526882, -4.6634567531],
            [-37.8453400816, -4.6838760286],
            [-37.8342453577, -4.666997513],
            [-37.8303419889, -4.6613454195],
            [-37.825157468, -4.6687481194],
            [-37.8179135388, -4.6714388109],
            [-37.8074192284, -4.6769708689],
            [-37.7999346333, -4.6967631647],
            [-37.789918504, -4.6995295069],
            [-37.7781590131, -4.7090295616],
            [-37.7569463339, -4.713659045],
            [-37.7470455913, -4.7190773405],
            [-37.7210117469, -4.7196567998],
            [-37.7135476178, -4.7184068949],
            [-37.7052671629, -4.7190938528],
            [-37.6985224553, -4.7143461632],
            [-37.6849001314, -4.7146569215],
            [-37.6770742968, -4.7184554501],
            [-37.6594318137, -4.715718409],
            [-37.6187819937, -4.7169823003],
            [-37.6233206229, -4.7180306936],
            [-37.7126108811, -4.738662495],
            [-37.788497308, -4.7562010645],
            [-37.7895511401, -4.754030549],
            [-37.792587167, -4.7489467852],
            [-37.792625948, -4.7302905366],
            [-37.8067129458, -4.7350383001],
            [-37.8068149064, -4.7350727784],
            [-37.8262345277, -4.7416181087],
            [-37.8262335354, -4.7629809753],
            [-37.8319223087, -4.7629704372],
            [-37.9350107574, -4.76277712],
            [-37.9359706816, -4.7612007421],
            [-37.9360767173, -4.7610264444],
            [-37.9497852911, -4.7385350916],
            [-37.9570744092, -4.72653547],
            [-37.9513475709, -4.7263782721],
            [-37.9500323969, -4.6592618809],
            [-37.93542126, -4.6619894594],
            [-37.9085526882, -4.6634567531],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2306256',
        name: 'Itaitinga',
        description: 'Itaitinga',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5505386713, -4.0937599901],
            [-38.5581141048, -4.1198338568],
            [-38.5665588526, -4.1228078317],
            [-38.5654919435, -4.1184929038],
            [-38.5721454853, -4.1150059849],
            [-38.5768772732, -4.1051680531],
            [-38.6023473912, -4.1072879237],
            [-38.6065857734, -4.1002028403],
            [-38.6129493565, -4.1035302838],
            [-38.6122556893, -4.099718273],
            [-38.6048064223, -4.0587801863],
            [-38.5743372628, -4.0521765513],
            [-38.5727022553, -4.0098755558],
            [-38.5657246075, -3.9656287201],
            [-38.564863651, -3.9601733374],
            [-38.5639534787, -3.9562147419],
            [-38.5576578952, -3.9288385089],
            [-38.5455496279, -3.8762296077],
            [-38.5418062855, -3.8599750159],
            [-38.5410090395, -3.8608667829],
            [-38.5231023737, -3.8808839851],
            [-38.501823962, -3.8885528556],
            [-38.5021046677, -3.8905392704],
            [-38.5043134473, -3.9061544194],
            [-38.505596281, -3.9152243423],
            [-38.5071726938, -3.9263736133],
            [-38.5075134234, -3.9287878717],
            [-38.5080440177, -3.9325422481],
            [-38.5086357112, -3.9367287691],
            [-38.5110117461, -3.9456922708],
            [-38.5152160066, -3.9653751264],
            [-38.5151869676, -3.9658117489],
            [-38.5147339662, -3.9726068203],
            [-38.5179731572, -4.0076048704],
            [-38.515693773, -4.0133403769],
            [-38.527607419, -4.0229243399],
            [-38.527473913, -4.0301052009],
            [-38.5355001282, -4.0409714197],
            [-38.5388094437, -4.0496367892],
            [-38.5336429017, -4.0581996699],
            [-38.5330865208, -4.0677874871],
            [-38.5366462224, -4.0778279256],
            [-38.545696066, -4.0830219792],
            [-38.5433637133, -4.0853356786],
            [-38.5456344005, -4.0881888895],
            [-38.5505386713, -4.0937599901],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2306306',
        name: 'Itapagé',
        description: 'Itapagé',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.6935483895, -3.624126886],
            [-39.6800859359, -3.6479226765],
            [-39.6677603111, -3.6453349912],
            [-39.6532092923, -3.6515378473],
            [-39.6478029594, -3.6523385986],
            [-39.6493020665, -3.6470480924],
            [-39.6353458582, -3.6368594041],
            [-39.6314060855, -3.6376067741],
            [-39.6129135145, -3.6411136223],
            [-39.6214782272, -3.6316031158],
            [-39.6102141677, -3.6345830932],
            [-39.6072252227, -3.6353739965],
            [-39.5998211708, -3.6253377238],
            [-39.5941489775, -3.6249439612],
            [-39.5619250632, -3.634037421],
            [-39.5581702884, -3.6411770427],
            [-39.557841263, -3.6523616344],
            [-39.5535270561, -3.6536439962],
            [-39.5426709063, -3.6545854565],
            [-39.5360503762, -3.6592671452],
            [-39.5255425327, -3.6594931336],
            [-39.5224196453, -3.6547080714],
            [-39.5201643761, -3.655816386],
            [-39.5156919725, -3.6572973843],
            [-39.5142122605, -3.6688456607],
            [-39.514380827, -3.6756129801],
            [-39.510861373, -3.6949695493],
            [-39.5133781181, -3.6974422615],
            [-39.5098536379, -3.7024779124],
            [-39.4967361712, -3.7110875218],
            [-39.4967746686, -3.7152593416],
            [-39.495497826, -3.721203925],
            [-39.482031772, -3.7222257384],
            [-39.4794787074, -3.7264600329],
            [-39.4733263554, -3.7266470262],
            [-39.4696762425, -3.7302019391],
            [-39.4572797458, -3.7416495677],
            [-39.4563674538, -3.7476452883],
            [-39.4434380377, -3.7449500458],
            [-39.4330842815, -3.7641859742],
            [-39.4357783893, -3.7724929226],
            [-39.4321140459, -3.7836311814],
            [-39.4361998839, -3.7911253245],
            [-39.4295898492, -3.7962945871],
            [-39.4261518506, -3.8229192052],
            [-39.4239716157, -3.8255446428],
            [-39.4172902831, -3.8248210661],
            [-39.4155985689, -3.832049122],
            [-39.4290151605, -3.854069314],
            [-39.4391511417, -3.8529096735],
            [-39.4567532569, -3.8613188315],
            [-39.4592001687, -3.8572055665],
            [-39.4761496663, -3.8572591855],
            [-39.4796636555, -3.8179851297],
            [-39.487679595, -3.8130790109],
            [-39.4882072366, -3.8171246943],
            [-39.4926010811, -3.8183981975],
            [-39.492320462, -3.7593242932],
            [-39.5151764245, -3.7459263724],
            [-39.5203772711, -3.7677174484],
            [-39.5357571796, -3.7795605968],
            [-39.5360442612, -3.7797817326],
            [-39.5761933562, -3.8107033303],
            [-39.5782693432, -3.8129479781],
            [-39.5819205255, -3.8208261628],
            [-39.5880716141, -3.8226588455],
            [-39.5935566776, -3.8252670845],
            [-39.6139238436, -3.8205924135],
            [-39.6221977346, -3.8242527225],
            [-39.6236394917, -3.8289503814],
            [-39.629053192, -3.8256533433],
            [-39.6344996908, -3.8283120786],
            [-39.6415095543, -3.8206819522],
            [-39.6462505615, -3.8018352889],
            [-39.6518874943, -3.7928918656],
            [-39.6533659537, -3.7732671425],
            [-39.6594452731, -3.7640053767],
            [-39.6775600994, -3.7576037596],
            [-39.6862570438, -3.7433752836],
            [-39.6861238987, -3.7426627885],
            [-39.6856747002, -3.7402675542],
            [-39.6865870998, -3.7336623465],
            [-39.686637529, -3.7264663824],
            [-39.6900983621, -3.7085772093],
            [-39.6953133217, -3.6995441749],
            [-39.7067972091, -3.6911833094],
            [-39.7090168641, -3.6843074407],
            [-39.7089073072, -3.6832400654],
            [-39.6972872129, -3.6418102884],
            [-39.7022573033, -3.6180959282],
            [-39.7021728389, -3.6179174663],
            [-39.6982352896, -3.6109959316],
            [-39.6931167512, -3.6107149208],
            [-39.6896206448, -3.6141886884],
            [-39.6935483895, -3.624126886],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2306405',
        name: 'Itapipoca',
        description: 'Itapipoca',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.4373266821, -3.1464889929],
            [-39.4118050357, -3.1598477142],
            [-39.4021831463, -3.1720417936],
            [-39.3839799183, -3.1837433555],
            [-39.3855717789, -3.186980494],
            [-39.3916278719, -3.1845065757],
            [-39.4001504562, -3.1860175937],
            [-39.4047141976, -3.191512],
            [-39.4083736409, -3.1860757553],
            [-39.4095859047, -3.1914320963],
            [-39.4063601527, -3.199973271],
            [-39.4105691159, -3.2085753416],
            [-39.4200320068, -3.2023786253],
            [-39.4311545054, -3.2072296587],
            [-39.4324030102, -3.2096848935],
            [-39.4422136855, -3.2098646337],
            [-39.4495600604, -3.2062264019],
            [-39.450060355, -3.211270775],
            [-39.4628059395, -3.2139599827],
            [-39.4560844129, -3.2095371655],
            [-39.4660581037, -3.2072745231],
            [-39.4777432686, -3.1991875705],
            [-39.4839134037, -3.2065511874],
            [-39.4817087645, -3.2107422813],
            [-39.4873027181, -3.2123739937],
            [-39.4887232579, -3.207647121],
            [-39.485178244, -3.2035702497],
            [-39.4874410409, -3.2020198693],
            [-39.4907997079, -3.202164279],
            [-39.4919280221, -3.2069202585],
            [-39.5024352235, -3.1993276514],
            [-39.5055125311, -3.2039643417],
            [-39.5096648097, -3.2022303513],
            [-39.5094056259, -3.2061166873],
            [-39.5073345202, -3.2093532251],
            [-39.5106010517, -3.2103367966],
            [-39.5111477091, -3.2163628257],
            [-39.505983218, -3.2169574293],
            [-39.5069098516, -3.228426029],
            [-39.5091208952, -3.2290350626],
            [-39.5058553152, -3.2330180575],
            [-39.5091849008, -3.2330643159],
            [-39.5050142332, -3.2359778626],
            [-39.5044590779, -3.2432827492],
            [-39.5152778588, -3.2550816571],
            [-39.5197112015, -3.2687033206],
            [-39.5221846738, -3.2761476189],
            [-39.514554137, -3.2829816675],
            [-39.5131089244, -3.2892751946],
            [-39.5050697594, -3.2932135637],
            [-39.5115224317, -3.3126268157],
            [-39.5116741541, -3.3138628332],
            [-39.5037047872, -3.3216517484],
            [-39.5009990328, -3.3292332588],
            [-39.5043274669, -3.3403274547],
            [-39.5028202807, -3.3526036704],
            [-39.4939795156, -3.3581438825],
            [-39.492653236, -3.3670108136],
            [-39.4957069767, -3.3695576424],
            [-39.4907269792, -3.3716743441],
            [-39.4913925367, -3.3788280605],
            [-39.4867581516, -3.3813482192],
            [-39.4805179915, -3.397525146],
            [-39.4706438844, -3.4026710597],
            [-39.4673369287, -3.4015203834],
            [-39.4631423173, -3.4138914398],
            [-39.4558156821, -3.4215373125],
            [-39.4574231536, -3.4252135927],
            [-39.4509174345, -3.4327606517],
            [-39.4515596966, -3.4434805574],
            [-39.4457438271, -3.4470097969],
            [-39.4385557053, -3.4492393499],
            [-39.4272812063, -3.4694108439],
            [-39.4271419849, -3.476516767],
            [-39.4334808162, -3.4778576421],
            [-39.4345487495, -3.4837361767],
            [-39.4307793359, -3.4935138397],
            [-39.4162565481, -3.5026317759],
            [-39.4164411325, -3.515355568],
            [-39.4137348719, -3.5187342508],
            [-39.4096534823, -3.5325455885],
            [-39.4009495585, -3.54794456],
            [-39.411255841, -3.5519490292],
            [-39.4158077864, -3.5525707719],
            [-39.4260203357, -3.562414544],
            [-39.4319117594, -3.5654434088],
            [-39.4348108538, -3.5739077999],
            [-39.4394760439, -3.5787885165],
            [-39.4505484311, -3.5822860291],
            [-39.4507305323, -3.5824369053],
            [-39.4543096986, -3.5848866429],
            [-39.4924804917, -3.581374783],
            [-39.5041561497, -3.575908388],
            [-39.5048293438, -3.5753552565],
            [-39.5028525247, -3.5811900513],
            [-39.5125604174, -3.5813858288],
            [-39.5316063636, -3.5817696988],
            [-39.5505272321, -3.5821513268],
            [-39.5506505111, -3.5821540949],
            [-39.5545721961, -3.5822326666],
            [-39.5719526145, -3.6009878017],
            [-39.5727427729, -3.6018406083],
            [-39.5798355582, -3.6094947732],
            [-39.587344053, -3.6175984772],
            [-39.593995885, -3.624780286],
            [-39.5941489775, -3.6249439612],
            [-39.5998211708, -3.6253377238],
            [-39.6072252227, -3.6353739965],
            [-39.6102141677, -3.6345830932],
            [-39.6214782272, -3.6316031158],
            [-39.6129135145, -3.6411136223],
            [-39.6314060855, -3.6376067741],
            [-39.6353458582, -3.6368594041],
            [-39.6493020665, -3.6470480924],
            [-39.6478029594, -3.6523385986],
            [-39.6532092923, -3.6515378473],
            [-39.6677603111, -3.6453349912],
            [-39.6800859359, -3.6479226765],
            [-39.6935483895, -3.624126886],
            [-39.6896206448, -3.6141886884],
            [-39.6931167512, -3.6107149208],
            [-39.6982352896, -3.6109959316],
            [-39.7021728389, -3.6179174663],
            [-39.7022573033, -3.6180959282],
            [-39.707989666, -3.6236165055],
            [-39.7074538263, -3.6366434268],
            [-39.7127247388, -3.6547411931],
            [-39.7561147328, -3.6033847195],
            [-39.7750620723, -3.5892068551],
            [-39.7750283107, -3.5814165809],
            [-39.7657656769, -3.5700884525],
            [-39.7628977392, -3.5675133812],
            [-39.7572487582, -3.5573932974],
            [-39.7691943093, -3.5297019046],
            [-39.7678516633, -3.5246365837],
            [-39.7778634517, -3.5024505492],
            [-39.7843698015, -3.5008955324],
            [-39.7878517165, -3.4683669924],
            [-39.7841606489, -3.4644658241],
            [-39.7693648343, -3.4578434815],
            [-39.7660571571, -3.4517489695],
            [-39.7673043859, -3.4423898786],
            [-39.7518091412, -3.4243305036],
            [-39.7535309841, -3.4147032775],
            [-39.7412924756, -3.4064669693],
            [-39.741144217, -3.4062113267],
            [-39.7350422382, -3.3958369174],
            [-39.7410043067, -3.3882148181],
            [-39.7333348047, -3.3749396725],
            [-39.7380327703, -3.3683306643],
            [-39.7384171119, -3.3564312152],
            [-39.7285230012, -3.3437204647],
            [-39.7263709475, -3.3379448397],
            [-39.7143950156, -3.3353029735],
            [-39.7033366753, -3.3227768622],
            [-39.7032111947, -3.3226198583],
            [-39.702421638, -3.3196399777],
            [-39.7162898623, -3.2785896097],
            [-39.7197410202, -3.268368583],
            [-39.7227573913, -3.2594349795],
            [-39.72771865, -3.244736315],
            [-39.7018728607, -3.2444746437],
            [-39.6989517132, -3.2409807549],
            [-39.7000583563, -3.2323152805],
            [-39.6897939194, -3.2203519269],
            [-39.6908677832, -3.2071636935],
            [-39.679613066, -3.2057712553],
            [-39.6800974678, -3.1955922208],
            [-39.6670793044, -3.1624531665],
            [-39.6567957294, -3.1625980258],
            [-39.651666454, -3.1618748598],
            [-39.6359204895, -3.1566958891],
            [-39.6270531436, -3.150875142],
            [-39.617068466, -3.1490892403],
            [-39.6088424983, -3.1537416144],
            [-39.5857089155, -3.1412445422],
            [-39.56659811, -3.1309218649],
            [-39.5652010656, -3.1264610169],
            [-39.5600385887, -3.1099779922],
            [-39.5504525315, -3.0793815081],
            [-39.5299278909, -3.0941708296],
            [-39.5211972053, -3.1025991404],
            [-39.4871093547, -3.11804422],
            [-39.4794518202, -3.1258708879],
            [-39.4684707936, -3.1390833279],
            [-39.4644452566, -3.1417658964],
            [-39.4373266821, -3.1464889929],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2306504',
        name: 'Itapiúna',
        description: 'Itapiúna',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.0221831521, -4.5131734304],
            [-39.0114652013, -4.5173785423],
            [-39.0151629345, -4.5044939155],
            [-39.0083207311, -4.4938865546],
            [-39.0063533198, -4.4824434484],
            [-38.9978635686, -4.4841323417],
            [-38.9843311889, -4.4961669385],
            [-38.9685798063, -4.4918716627],
            [-38.9650483559, -4.4924474149],
            [-38.9479883603, -4.4875990505],
            [-38.9422239622, -4.4919831662],
            [-38.9416717953, -4.4926129926],
            [-38.9373062758, -4.4974540581],
            [-38.9356100826, -4.5160254096],
            [-38.9325245836, -4.5196291922],
            [-38.9341405225, -4.5235984861],
            [-38.9249345446, -4.5230521601],
            [-38.9286599491, -4.5149739589],
            [-38.9180476639, -4.5120996448],
            [-38.9165715361, -4.5185320379],
            [-38.9083586725, -4.5274414615],
            [-38.9056228983, -4.52732946],
            [-38.8944327506, -4.5300284397],
            [-38.87911261, -4.5238653505],
            [-38.8710296374, -4.5287924171],
            [-38.8689168315, -4.5468618785],
            [-38.8640654083, -4.5511027547],
            [-38.8379922263, -4.549729013],
            [-38.8348981872, -4.5571202594],
            [-38.8407307535, -4.5613648958],
            [-38.8377778484, -4.5702629603],
            [-38.8397502992, -4.5837273642],
            [-38.8227347124, -4.5765553567],
            [-38.8146498009, -4.5823018231],
            [-38.8019127842, -4.5820367499],
            [-38.8005994418, -4.5836227195],
            [-38.7676275547, -4.6234336724],
            [-38.7625931829, -4.6318695342],
            [-38.7690219992, -4.6341454968],
            [-38.7748481369, -4.6318772981],
            [-38.777958828, -4.6262814622],
            [-38.7815050535, -4.6302814677],
            [-38.7821501272, -4.6409591506],
            [-38.7876963019, -4.6387182627],
            [-38.7905382505, -4.6471634234],
            [-38.802734728, -4.6498912647],
            [-38.813685161, -4.671377684],
            [-38.8258024881, -4.6710762645],
            [-38.8290502944, -4.668219298],
            [-38.8439072596, -4.6721406717],
            [-38.8596804371, -4.6644283976],
            [-38.8696208782, -4.6795604961],
            [-38.8739737181, -4.6792563469],
            [-38.8774656239, -4.6857615267],
            [-38.8753660276, -4.6898160934],
            [-38.8809613977, -4.6935999543],
            [-38.8834451683, -4.7088839066],
            [-38.8908267715, -4.71448557],
            [-38.9045821769, -4.7126704223],
            [-38.9013958245, -4.7197812952],
            [-38.9220567739, -4.7361276424],
            [-38.9261018117, -4.7391136781],
            [-38.9431149412, -4.7408142469],
            [-38.9512280533, -4.7168606883],
            [-38.9650994588, -4.7048368692],
            [-38.9609898362, -4.6957719221],
            [-38.9496505199, -4.685067801],
            [-38.9505075488, -4.6810006772],
            [-38.9535562835, -4.6669615158],
            [-38.9657287923, -4.6656065713],
            [-38.9783624812, -4.6683698761],
            [-38.9772863064, -4.6602746784],
            [-38.9830035772, -4.6539129921],
            [-38.9910340751, -4.6526960521],
            [-38.9959476887, -4.6472528858],
            [-39.0075583968, -4.6501330058],
            [-39.0125889697, -4.6442727593],
            [-39.0218710773, -4.6407279781],
            [-39.0298114199, -4.6436373043],
            [-39.0461425745, -4.6395970689],
            [-39.0590010089, -4.6510608461],
            [-39.0700370644, -4.6517972638],
            [-39.0744136725, -4.6479022163],
            [-39.0807542732, -4.6389774735],
            [-39.0962495787, -4.6431620133],
            [-39.1000037231, -4.6361589166],
            [-39.1089737875, -4.6425199193],
            [-39.1119232823, -4.6415546886],
            [-39.1185605488, -4.6263000142],
            [-39.1292262028, -4.6279063992],
            [-39.1294221674, -4.6279742602],
            [-39.0659543445, -4.5612646121],
            [-39.069663861, -4.5458519869],
            [-39.0756068135, -4.547795964],
            [-39.0754727365, -4.5376691106],
            [-39.0832848062, -4.5360599308],
            [-39.0806123746, -4.53116434],
            [-39.0827495916, -4.5257323004],
            [-39.0850405156, -4.5188028643],
            [-39.081811972, -4.5037387583],
            [-39.0874185764, -4.4934358233],
            [-39.0792722147, -4.4851903379],
            [-39.0846781711, -4.471469549],
            [-39.0307794834, -4.4634394621],
            [-39.0359545687, -4.4762397153],
            [-39.0263681709, -4.4913452282],
            [-39.0276736937, -4.498358494],
            [-39.0218680389, -4.5052843771],
            [-39.0221831521, -4.5131734304],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2306553',
        name: 'Itarema',
        description: 'Itarema',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.930255817, -2.8683761357],
            [-39.912385426, -2.8770113463],
            [-39.9045646996, -2.8756084263],
            [-39.8966418015, -2.88414175],
            [-39.8919208895, -2.8818728987],
            [-39.8863128917, -2.8843407374],
            [-39.8575462679, -2.9027785864],
            [-39.832093269, -2.9237870809],
            [-39.8232995072, -2.9271394918],
            [-39.7988269004, -2.9457314673],
            [-39.7920600574, -2.9485355545],
            [-39.7678791581, -2.9602018342],
            [-39.7412538002, -2.9853679671],
            [-39.7353546272, -2.9864985679],
            [-39.7136074787, -2.9995096764],
            [-39.70656755, -3.0045620658],
            [-39.7078557259, -3.0139714202],
            [-39.738617324, -3.0357060821],
            [-39.7387778117, -3.0358142789],
            [-39.7595584933, -3.0497982721],
            [-39.7615485368, -3.0511380164],
            [-39.784326899, -3.0664696082],
            [-39.8205282731, -3.0908410496],
            [-39.8228481186, -3.0924024003],
            [-39.8666371983, -3.1218927573],
            [-39.8676862275, -3.1230833971],
            [-39.8678029994, -3.1238888313],
            [-39.8640318388, -3.1299133188],
            [-39.8656149984, -3.1556939493],
            [-39.8740135634, -3.1788364758],
            [-39.8782034844, -3.1804150984],
            [-39.8797240741, -3.1874751933],
            [-39.885319861, -3.1897995096],
            [-39.8945810661, -3.1949246082],
            [-39.9038091766, -3.2057734697],
            [-39.9013412113, -3.2149593927],
            [-39.8928029769, -3.2191926558],
            [-39.8902879139, -3.2256029107],
            [-39.8944489676, -3.2307596399],
            [-39.8957240291, -3.2369500109],
            [-39.8920351276, -3.2419556669],
            [-39.9042844517, -3.2608272388],
            [-39.9069948626, -3.2734059517],
            [-39.9085526952, -3.2737722648],
            [-39.9267553743, -3.2658399432],
            [-39.9437010141, -3.24531291],
            [-39.9663096575, -3.2391429749],
            [-39.9770873649, -3.2321764099],
            [-39.9798116536, -3.22158176],
            [-39.9892004124, -3.2166640507],
            [-39.9920585037, -3.2116612305],
            [-39.9967157036, -3.1982411034],
            [-39.9920249527, -3.1826137651],
            [-39.9831695133, -3.1616462574],
            [-39.9893832041, -3.160989605],
            [-39.9944464127, -3.134811032],
            [-39.9900357501, -3.114602634],
            [-39.9905866864, -3.0955003154],
            [-39.980019234, -3.0801372686],
            [-39.9797804237, -3.079897136],
            [-39.9723958539, -3.0724736706],
            [-39.9723418083, -3.0617174586],
            [-39.9808263727, -3.0359782353],
            [-39.9810405754, -3.0352873003],
            [-39.9815526536, -3.0235483391],
            [-39.9808923925, -3.010738362],
            [-39.9740095521, -3.0012652795],
            [-39.9728962341, -2.9892447295],
            [-39.9706168359, -2.9690963423],
            [-39.9683552904, -2.9354979759],
            [-39.9623673391, -2.9186021796],
            [-39.958392668, -2.8982514541],
            [-39.953187714, -2.8965590747],
            [-39.9539719712, -2.8904081858],
            [-39.9468589769, -2.8705258326],
            [-39.9431280761, -2.8600984207],
            [-39.9423117517, -2.8578193578],
            [-39.930255817, -2.8683761357],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2306603',
        name: 'Itatira',
        description: 'Itatira',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.529112383, -4.4652917569],
            [-39.5234289835, -4.4977411958],
            [-39.5200316077, -4.4967869623],
            [-39.5145360721, -4.4823219934],
            [-39.5112822883, -4.4817125542],
            [-39.5129864122, -4.468521395],
            [-39.5092421101, -4.4657686214],
            [-39.5028278923, -4.468302149],
            [-39.5034728323, -4.4741093775],
            [-39.4938622248, -4.4836956083],
            [-39.4959199495, -4.4875755557],
            [-39.490709124, -4.491545905],
            [-39.4889486478, -4.4954012563],
            [-39.4826151322, -4.5042439404],
            [-39.4807586991, -4.5251875199],
            [-39.4662093677, -4.5361419936],
            [-39.4639234283, -4.5514839582],
            [-39.4573255806, -4.5536032422],
            [-39.456900314, -4.560707457],
            [-39.4509733439, -4.5620141247],
            [-39.4482572712, -4.5717070032],
            [-39.443115831, -4.5745559714],
            [-39.44309989, -4.5747606891],
            [-39.4481978762, -4.5899032486],
            [-39.4534787928, -4.595618179],
            [-39.4445576831, -4.6079198414],
            [-39.4317977188, -4.6246975025],
            [-39.4331255085, -4.6359985685],
            [-39.4210569682, -4.6429320266],
            [-39.4215718424, -4.6534007337],
            [-39.4250733547, -4.6568616741],
            [-39.4214984539, -4.6591186621],
            [-39.4259163524, -4.6752094405],
            [-39.422818952, -4.680921662],
            [-39.4224423312, -4.6813810441],
            [-39.420841456, -4.6833331759],
            [-39.417673951, -4.6891917985],
            [-39.3961978341, -4.6861973997],
            [-39.3894092056, -4.692581933],
            [-39.3892659764, -4.6974961188],
            [-39.3785197331, -4.6945808073],
            [-39.3776525029, -4.699408221],
            [-39.3814520612, -4.7006522345],
            [-39.3794131806, -4.7137812039],
            [-39.3645172176, -4.7206837713],
            [-39.3680245225, -4.7230284794],
            [-39.3664268499, -4.7253906424],
            [-39.3670182173, -4.7253930976],
            [-39.466631852, -4.7258129313],
            [-39.475203057, -4.7258485556],
            [-39.512666281, -4.7279248571],
            [-39.5697863612, -4.746983397],
            [-39.5788867448, -4.7469056694],
            [-39.6456820027, -4.724461006],
            [-39.6498022994, -4.7230756276],
            [-39.6763112225, -4.7141617019],
            [-39.6797385882, -4.7130092894],
            [-39.7107339442, -4.7025830571],
            [-39.7288094194, -4.6964989043],
            [-39.7289410847, -4.6964532673],
            [-39.7263280925, -4.6898666207],
            [-39.7286771568, -4.6859171877],
            [-39.7206371117, -4.6861175904],
            [-39.719129117, -4.6807093387],
            [-39.7142238025, -4.6778507245],
            [-39.7193888, -4.6743421948],
            [-39.728922774, -4.6710250122],
            [-39.7261063929, -4.667145407],
            [-39.7315319721, -4.665675073],
            [-39.7154425007, -4.6547467816],
            [-39.7386415429, -4.6524967682],
            [-39.7441238799, -4.6457428957],
            [-39.7482460548, -4.6497481064],
            [-39.7561507672, -4.6464946245],
            [-39.7656926478, -4.6476309813],
            [-39.7732714917, -4.6431141379],
            [-39.7786144102, -4.6304324078],
            [-39.776452972, -4.6260451328],
            [-39.7623395639, -4.6284784267],
            [-39.7642253516, -4.6196289317],
            [-39.7593240359, -4.619825603],
            [-39.7544788364, -4.6106653753],
            [-39.7436907119, -4.6061591645],
            [-39.7416580525, -4.5998608652],
            [-39.719755833, -4.5994473612],
            [-39.685229849, -4.5756694295],
            [-39.6862515332, -4.5670146648],
            [-39.6893701306, -4.5652330887],
            [-39.6887280067, -4.5560627149],
            [-39.6868273916, -4.5560049599],
            [-39.6736377928, -4.5560351706],
            [-39.671391736, -4.5530448654],
            [-39.6684776309, -4.557645636],
            [-39.6424874903, -4.5388608964],
            [-39.6605756693, -4.5262265381],
            [-39.6611208701, -4.5158567572],
            [-39.6588971574, -4.5119588269],
            [-39.6438339069, -4.5085209409],
            [-39.6441436121, -4.5016507692],
            [-39.6406460254, -4.5020933219],
            [-39.6367864561, -4.497205163],
            [-39.6268727048, -4.5053081115],
            [-39.6072251966, -4.5031626296],
            [-39.5892513188, -4.5197833146],
            [-39.5822017215, -4.5190608238],
            [-39.5823641605, -4.5123448117],
            [-39.5853617747, -4.5089421053],
            [-39.5804429552, -4.5083477189],
            [-39.5790343406, -4.4987102077],
            [-39.5878610547, -4.497995948],
            [-39.5895029006, -4.4902770851],
            [-39.5729786953, -4.470785698],
            [-39.5654367953, -4.4704540422],
            [-39.5619423495, -4.4700261041],
            [-39.5604297652, -4.4747757036],
            [-39.5462510519, -4.4859156428],
            [-39.5393112758, -4.487366345],
            [-39.5371411311, -4.4830138173],
            [-39.5430465996, -4.4661121543],
            [-39.5333867989, -4.4682634135],
            [-39.529112383, -4.4652917569],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2306702',
        name: 'Jaguaretama',
        description: 'Jaguaretama',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.7192604301, -5.2516103801],
            [-38.7109981418, -5.2527130801],
            [-38.6985125282, -5.2446080313],
            [-38.7093726481, -5.2641420082],
            [-38.7117472816, -5.2684138866],
            [-38.7148350782, -5.2739693957],
            [-38.7056460868, -5.2816003876],
            [-38.6269086535, -5.3479247179],
            [-38.60380689, -5.3679043268],
            [-38.5690214357, -5.3982846971],
            [-38.568790625, -5.3984841899],
            [-38.518699968, -5.4392566315],
            [-38.5152892776, -5.4421011819],
            [-38.5307123449, -5.4485644072],
            [-38.5508211204, -5.4685908295],
            [-38.5161152779, -5.500726677],
            [-38.5279491429, -5.5110257743],
            [-38.5375954454, -5.5325039385],
            [-38.5966205322, -5.5417792226],
            [-38.6213634821, -5.5679984467],
            [-38.6370660702, -5.6026133677],
            [-38.6294031365, -5.6008587628],
            [-38.6241665864, -5.6049272346],
            [-38.6151734864, -5.6051340136],
            [-38.6509091584, -5.652989692],
            [-38.6994513709, -5.7180262464],
            [-38.6935895597, -5.7488051606],
            [-38.6930316837, -5.7517327591],
            [-38.6990320938, -5.7614998489],
            [-38.7159719641, -5.7606722435],
            [-38.7610877497, -5.770393944],
            [-38.7644703381, -5.7739557293],
            [-38.7653920576, -5.7864445358],
            [-38.7732528805, -5.7833356676],
            [-38.8040394098, -5.787267718],
            [-38.8798468441, -5.6591066696],
            [-38.9091641339, -5.595855407],
            [-38.9092956095, -5.5955587808],
            [-38.907562949, -5.5649134159],
            [-38.9015938101, -5.4694381735],
            [-38.9069677938, -5.4667484087],
            [-38.9075554551, -5.4572283965],
            [-38.9110611364, -5.4554022101],
            [-38.9068129826, -5.4517049021],
            [-38.9109503796, -5.4374970345],
            [-38.916445146, -5.4320892036],
            [-38.92294822, -5.4337326061],
            [-38.9199992141, -5.419105271],
            [-38.9221432388, -5.4117231491],
            [-38.9258413086, -5.4111816506],
            [-38.9210904132, -5.3999020029],
            [-38.9243874533, -5.3793716256],
            [-38.9214344016, -5.329261642],
            [-38.9158337783, -5.3202153286],
            [-38.897891537, -5.3087332594],
            [-38.8783407085, -5.3100280854],
            [-38.8684137924, -5.2968568764],
            [-38.8651750103, -5.2936318055],
            [-38.8470276618, -5.2849328977],
            [-38.8111166048, -5.2236867573],
            [-38.7680897073, -5.2017721603],
            [-38.7394228507, -5.197640271],
            [-38.7185526846, -5.200491814],
            [-38.7139137578, -5.2225268147],
            [-38.7152312508, -5.2351671017],
            [-38.7214714334, -5.2432678162],
            [-38.7192604301, -5.2516103801],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2306801',
        name: 'Jaguaribara',
        description: 'Jaguaribara',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.4597618425, -5.4978599087],
            [-38.45705714, -5.534155129],
            [-38.4491311245, -5.5337966977],
            [-38.4461740631, -5.5448945434],
            [-38.4370568822, -5.5448805576],
            [-38.4338628989, -5.6101624178],
            [-38.4330490952, -5.6267318639],
            [-38.4368335792, -5.6379162023],
            [-38.4451246751, -5.6417536406],
            [-38.4413362328, -5.6497668934],
            [-38.4437067861, -5.6540512082],
            [-38.4414550101, -5.6566463427],
            [-38.4514986154, -5.6662268492],
            [-38.4501548107, -5.6745599354],
            [-38.4562760277, -5.6747073244],
            [-38.4592180418, -5.6809930239],
            [-38.4567654803, -5.6855898884],
            [-38.4572318054, -5.70053648],
            [-38.4533664048, -5.7016260078],
            [-38.4508216893, -5.7122845034],
            [-38.4520153141, -5.7147449226],
            [-38.4584842361, -5.713796018],
            [-38.4579598369, -5.7062290888],
            [-38.4615182335, -5.7134511072],
            [-38.4593578873, -5.7208075436],
            [-38.4613570247, -5.7270213337],
            [-38.4603125613, -5.7309181632],
            [-38.4642494471, -5.7294775145],
            [-38.4630561645, -5.7359726665],
            [-38.4687766349, -5.7332340104],
            [-38.4876309051, -5.7413364775],
            [-38.487331295, -5.7449452003],
            [-38.4927489686, -5.7487028811],
            [-38.4814636565, -5.7459504175],
            [-38.4851696475, -5.7527395259],
            [-38.4888086453, -5.7533193469],
            [-38.4886548294, -5.7596696682],
            [-38.4957048642, -5.7648740053],
            [-38.4948865885, -5.7674716524],
            [-38.4892423644, -5.7663108986],
            [-38.4910942947, -5.7719425186],
            [-38.4812945559, -5.7678927058],
            [-38.4825495123, -5.7756893497],
            [-38.4778719635, -5.7756848072],
            [-38.4772888612, -5.7804622602],
            [-38.4874112633, -5.7820757481],
            [-38.4898669813, -5.78538097],
            [-38.4984709544, -5.7841206228],
            [-38.5275243104, -5.7583132711],
            [-38.5377515205, -5.7501643422],
            [-38.5488312712, -5.7545149112],
            [-38.5608894098, -5.7453220404],
            [-38.5924437072, -5.7454780942],
            [-38.6775283652, -5.7458893163],
            [-38.6798274727, -5.7458999456],
            [-38.6930316837, -5.7517327591],
            [-38.6935895597, -5.7488051606],
            [-38.6994513709, -5.7180262464],
            [-38.6509091584, -5.652989692],
            [-38.6151734864, -5.6051340136],
            [-38.6241665864, -5.6049272346],
            [-38.6294031365, -5.6008587628],
            [-38.6370660702, -5.6026133677],
            [-38.6213634821, -5.5679984467],
            [-38.5966205322, -5.5417792226],
            [-38.5375954454, -5.5325039385],
            [-38.5279491429, -5.5110257743],
            [-38.5161152779, -5.500726677],
            [-38.5508211204, -5.4685908295],
            [-38.5307123449, -5.4485644072],
            [-38.5152892776, -5.4421011819],
            [-38.5069804971, -5.4386188851],
            [-38.5000252823, -5.4357043865],
            [-38.4125290931, -5.3738948846],
            [-38.4165469763, -5.3879545195],
            [-38.4253300598, -5.3925438666],
            [-38.4231422743, -5.4073425774],
            [-38.4272158377, -5.4099900718],
            [-38.4261244765, -5.4147456513],
            [-38.4431590815, -5.4279716707],
            [-38.426325857, -5.4451817375],
            [-38.4321701161, -5.4537849956],
            [-38.4307233924, -5.4670545938],
            [-38.4321094486, -5.4731048663],
            [-38.4509210305, -5.4893416878],
            [-38.4573669617, -5.492045204],
            [-38.4597618425, -5.4978599087],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2306900',
        name: 'Jaguaribe',
        description: 'Jaguaribe',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5608894098, -5.7453220404],
            [-38.5488312712, -5.7545149112],
            [-38.5377515205, -5.7501643422],
            [-38.5275243104, -5.7583132711],
            [-38.4984709544, -5.7841206228],
            [-38.4898669813, -5.78538097],
            [-38.4874112633, -5.7820757481],
            [-38.4772888612, -5.7804622602],
            [-38.4769955325, -5.7804222352],
            [-38.4853140861, -5.7896087904],
            [-38.4868310532, -5.7972485779],
            [-38.4809940746, -5.7978709832],
            [-38.4883456773, -5.8055761348],
            [-38.4818968928, -5.8114873738],
            [-38.476869139, -5.8106576912],
            [-38.4768669465, -5.8250091279],
            [-38.4848494619, -5.8329640564],
            [-38.4820817008, -5.8419082642],
            [-38.4897686708, -5.8754976266],
            [-38.4955881215, -5.8627048799],
            [-38.5127834303, -5.8771714389],
            [-38.5128440463, -5.8901060811],
            [-38.5044460421, -5.894146364],
            [-38.5044268287, -5.9013989817],
            [-38.5041096575, -5.9169397621],
            [-38.49823125, -5.9156947236],
            [-38.4996369974, -5.9282215798],
            [-38.4944063013, -5.9172062958],
            [-38.4864036201, -5.9165096155],
            [-38.4912881146, -5.9181695515],
            [-38.4950356341, -5.9251891943],
            [-38.497914712, -5.9528570611],
            [-38.5036481366, -5.9565779926],
            [-38.5028627497, -5.9635969045],
            [-38.5082741607, -5.9695587386],
            [-38.5084609404, -5.9749973055],
            [-38.5147600007, -5.9800950956],
            [-38.5128384467, -5.9894523448],
            [-38.5048384887, -5.9842163178],
            [-38.4860325314, -5.9478620387],
            [-38.4828441905, -5.9478585041],
            [-38.4875810192, -5.9573621661],
            [-38.4741428589, -5.9375278693],
            [-38.4615415043, -5.9300830213],
            [-38.4568399427, -5.9218532818],
            [-38.4571867259, -5.9283210669],
            [-38.4494755228, -5.9190912495],
            [-38.4577342516, -5.9377970897],
            [-38.4500978502, -5.9358849255],
            [-38.4554696716, -5.9464882341],
            [-38.4752248894, -5.9509114493],
            [-38.4815224901, -5.9576610704],
            [-38.5057684804, -5.9996909811],
            [-38.5112203442, -6.0154956125],
            [-38.5294052214, -6.0386189437],
            [-38.5308908674, -6.0419123343],
            [-38.5258745014, -6.0274558921],
            [-38.5302631627, -6.0304865425],
            [-38.5241077117, -6.0219490964],
            [-38.5280042886, -6.021538999],
            [-38.5277980148, -6.0132815027],
            [-38.5253166967, -6.0169943039],
            [-38.5240446868, -6.0112122045],
            [-38.5278801098, -6.000756288],
            [-38.5371998459, -6.0377896313],
            [-38.5412282494, -6.0471512151],
            [-38.5363443356, -6.0471366538],
            [-38.5442001382, -6.0526585594],
            [-38.5407971075, -6.0551333602],
            [-38.544324108, -6.0741301084],
            [-38.5547204715, -6.0789889417],
            [-38.5592618932, -6.0868059538],
            [-38.5571043191, -6.0893201615],
            [-38.5608893883, -6.0895609252],
            [-38.5618069178, -6.0934138602],
            [-38.5610192919, -6.1022224217],
            [-38.5571980338, -6.0975400311],
            [-38.5579390473, -6.1180523553],
            [-38.5660274726, -6.1311306507],
            [-38.5658990727, -6.1150270435],
            [-38.5840131266, -6.1439448274],
            [-38.5737465931, -6.1374676334],
            [-38.5788030657, -6.1458561126],
            [-38.5877136144, -6.1662321911],
            [-38.6011197496, -6.1762671303],
            [-38.61694432, -6.1688012718],
            [-38.6306099267, -6.1735262789],
            [-38.6351695162, -6.1785967399],
            [-38.6546983471, -6.1842928801],
            [-38.6564497367, -6.1919871925],
            [-38.6654761553, -6.1951749876],
            [-38.6692525659, -6.2007477698],
            [-38.6758608066, -6.2022007347],
            [-38.6793048917, -6.2104804425],
            [-38.6852274325, -6.2113728457],
            [-38.6940274801, -6.2066024841],
            [-38.7073535254, -6.2061732544],
            [-38.7206742997, -6.1996047458],
            [-38.7260664171, -6.2003979332],
            [-38.7309951964, -6.1866126594],
            [-38.7339862222, -6.1782376395],
            [-38.7356684977, -6.1655237148],
            [-38.7314545958, -6.1506581604],
            [-38.7197607382, -6.1344923073],
            [-38.7105606947, -6.1100320677],
            [-38.7227534422, -6.1214804243],
            [-38.7333908313, -6.1239458313],
            [-38.736671239, -6.1174574091],
            [-38.745835099, -6.1149214987],
            [-38.7455829164, -6.111182587],
            [-38.7559603433, -6.1122333118],
            [-38.7661660008, -6.1236705347],
            [-38.7833600653, -6.1146718839],
            [-38.7874496413, -6.1201385326],
            [-38.8006223025, -6.1213590929],
            [-38.8041982575, -6.1293030737],
            [-38.813075274, -6.1356130419],
            [-38.8205395206, -6.1372484454],
            [-38.821475702, -6.123633852],
            [-38.8341403545, -6.1263322724],
            [-38.8421721095, -6.1351955528],
            [-38.8467844124, -6.1316247436],
            [-38.8497810395, -6.1317949314],
            [-38.8504205531, -6.1375638103],
            [-38.8613395634, -6.1335575613],
            [-38.8644367536, -6.1385406337],
            [-38.8711333162, -6.1399432552],
            [-38.878721398, -6.1473741676],
            [-38.9040486038, -6.1392539265],
            [-38.9083959062, -6.0846588637],
            [-38.914613629, -6.0865862247],
            [-38.9378655926, -6.0786165438],
            [-38.9493641756, -6.0807523907],
            [-38.9605295263, -6.0910779168],
            [-38.9697484382, -6.0938734076],
            [-38.9718676898, -6.102631692],
            [-38.97407151, -6.1014412699],
            [-38.9703981303, -6.08412101],
            [-38.9724807503, -6.0788862247],
            [-38.9688055638, -6.0762692796],
            [-38.9670932114, -6.0608033387],
            [-38.9604758392, -6.0471253197],
            [-38.9648050728, -6.0373999036],
            [-38.96186329, -6.0186824198],
            [-38.9587562901, -6.0148738389],
            [-38.952047671, -6.0151429182],
            [-38.9468338746, -6.0049886074],
            [-38.9530037156, -6.0011821843],
            [-38.9539906979, -5.9942765219],
            [-38.9529770569, -5.9891753412],
            [-38.9470872297, -5.9859992416],
            [-38.9450049405, -5.9761045581],
            [-38.9472375144, -5.9702202656],
            [-38.9351395314, -5.9650422402],
            [-38.9378942568, -5.9570783698],
            [-38.9336929452, -5.9492360035],
            [-38.9208926172, -5.9507528107],
            [-38.9148320912, -5.9404109759],
            [-38.9146940909, -5.9401756152],
            [-38.8788308034, -5.8755588243],
            [-38.8805627843, -5.8704100599],
            [-38.8760358801, -5.8688142763],
            [-38.8807241914, -5.859930874],
            [-38.8729788124, -5.8571847772],
            [-38.8697477036, -5.8593463919],
            [-38.8602009361, -5.8546737753],
            [-38.8620812881, -5.8513405577],
            [-38.8568860445, -5.8474461133],
            [-38.8557891059, -5.8349719263],
            [-38.8434139091, -5.834649915],
            [-38.8402430533, -5.8325254461],
            [-38.8393350659, -5.8198639813],
            [-38.8332753065, -5.8191646433],
            [-38.8376638147, -5.8168739878],
            [-38.8369490297, -5.8136221519],
            [-38.814058306, -5.7971270219],
            [-38.8087821143, -5.7953288453],
            [-38.8040394098, -5.787267718],
            [-38.7732528805, -5.7833356676],
            [-38.7653920576, -5.7864445358],
            [-38.7644703381, -5.7739557293],
            [-38.7610877497, -5.770393944],
            [-38.7159719641, -5.7606722435],
            [-38.6990320938, -5.7614998489],
            [-38.6930316837, -5.7517327591],
            [-38.6798274727, -5.7458999456],
            [-38.6775283652, -5.7458893163],
            [-38.5924437072, -5.7454780942],
            [-38.5608894098, -5.7453220404],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2307007',
        name: 'Jaguaruana',
        description: 'Jaguaruana',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.6139976365, -4.7451821115],
            [-37.6111698992, -4.7678326925],
            [-37.5883468979, -4.8078957929],
            [-37.5916320097, -4.8154029741],
            [-37.6183171139, -4.8764176717],
            [-37.6399947953, -4.9260177488],
            [-37.6509228227, -4.9652191141],
            [-37.6599203417, -4.9975182344],
            [-37.6767975772, -5.0521466735],
            [-37.7607055794, -5.0079220349],
            [-37.8140201567, -4.9878174255],
            [-37.8203030346, -4.9837724133],
            [-37.8869924218, -4.9333624761],
            [-37.8885024981, -4.932220363],
            [-37.8993023455, -4.9145848277],
            [-37.906668416, -4.9025367505],
            [-37.8978076156, -4.8828003867],
            [-37.8950730359, -4.8768407848],
            [-37.8949848595, -4.876648178],
            [-37.8823718981, -4.849166084],
            [-37.8897465685, -4.8345754738],
            [-37.8983272733, -4.8204316162],
            [-37.8985780328, -4.8200178945],
            [-37.9350107574, -4.76277712],
            [-37.8319223087, -4.7629704372],
            [-37.8262335354, -4.7629809753],
            [-37.8262345277, -4.7416181087],
            [-37.8068149064, -4.7350727784],
            [-37.8067129458, -4.7350383001],
            [-37.792625948, -4.7302905366],
            [-37.792587167, -4.7489467852],
            [-37.7895511401, -4.754030549],
            [-37.788497308, -4.7562010645],
            [-37.7126108811, -4.738662495],
            [-37.6233206229, -4.7180306936],
            [-37.6187819937, -4.7169823003],
            [-37.6139976365, -4.7451821115],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2307106',
        name: 'Jardim',
        description: 'Jardim',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.0730645887, -7.6261683],
            [-39.1154886281, -7.6806206385],
            [-39.1171450591, -7.6839480681],
            [-39.1172037339, -7.6840577275],
            [-39.1228052434, -7.6944668941],
            [-39.128645452, -7.6976416873],
            [-39.1300811234, -7.7059362127],
            [-39.1461904306, -7.717858246],
            [-39.1706425674, -7.7148327159],
            [-39.1807747981, -7.7090702059],
            [-39.1889937356, -7.6997064142],
            [-39.1898650399, -7.6985138054],
            [-39.2079761013, -7.6890729704],
            [-39.2223832491, -7.6963963508],
            [-39.2343788537, -7.6963935863],
            [-39.2438130132, -7.7049356249],
            [-39.2522053808, -7.7045234791],
            [-39.2500285255, -7.6980356711],
            [-39.2471040973, -7.6902414662],
            [-39.2506715402, -7.6805118364],
            [-39.2516787679, -7.6784638549],
            [-39.2649785502, -7.6694238184],
            [-39.2660363137, -7.6643583152],
            [-39.2720420497, -7.6597264718],
            [-39.2833919409, -7.6638012825],
            [-39.305206537, -7.6655245427],
            [-39.3110286542, -7.6548295869],
            [-39.3210803003, -7.653818817],
            [-39.3316322553, -7.6478011193],
            [-39.331726175, -7.6477603964],
            [-39.3380835286, -7.6442400019],
            [-39.3424842937, -7.6352110218],
            [-39.3544384347, -7.6297212642],
            [-39.3676197568, -7.6235039531],
            [-39.3679177228, -7.5688864322],
            [-39.3683712146, -7.4932559831],
            [-39.3683361378, -7.4924502926],
            [-39.368555508, -7.4591155238],
            [-39.3341924449, -7.4675150604],
            [-39.3340086298, -7.4675626007],
            [-39.2560444623, -7.4866061851],
            [-39.1949219175, -7.5025505028],
            [-39.173727334, -7.5597341583],
            [-39.1636869913, -7.5686370212],
            [-39.1634755839, -7.5687710401],
            [-39.1360729639, -7.5861749061],
            [-39.1222969259, -7.5949243368],
            [-39.1048980688, -7.6059691718],
            [-39.0974628847, -7.6106866799],
            [-39.0730645887, -7.6261683],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2307205',
        name: 'Jati',
        description: 'Jati',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.9640292016, -7.6066464112],
            [-38.9579480592, -7.6132161393],
            [-38.9529728152, -7.610079646],
            [-38.9481709208, -7.6144879737],
            [-38.9364980445, -7.6092218834],
            [-38.9250249855, -7.6131140387],
            [-38.9171776905, -7.6085706011],
            [-38.9121642625, -7.6089197897],
            [-38.901889059, -7.6175152673],
            [-38.8910970389, -7.6099256869],
            [-38.8917048467, -7.6350188379],
            [-38.8944541719, -7.7500454799],
            [-38.8999967379, -7.7444675125],
            [-38.9152210827, -7.7440791054],
            [-38.9178910965, -7.7467590393],
            [-38.9157542296, -7.7581300188],
            [-38.9198621715, -7.7541592137],
            [-38.9272701971, -7.7533981489],
            [-38.9336947388, -7.7584220028],
            [-38.9392212346, -7.7583645377],
            [-38.9414911587, -7.7620940795],
            [-38.9416075182, -7.762734992],
            [-38.9406657431, -7.7710046355],
            [-38.9460257683, -7.780989253],
            [-38.9396082446, -7.7946973372],
            [-38.952498321, -7.8101371662],
            [-38.9536921817, -7.821109124],
            [-38.9645832518, -7.8294198929],
            [-38.9590040822, -7.83785476],
            [-38.9617565466, -7.8437659059],
            [-38.9658448587, -7.8452249392],
            [-38.9648311487, -7.8372772297],
            [-38.9692535492, -7.8349973276],
            [-38.9690291307, -7.8277076726],
            [-38.9574713338, -7.8163203085],
            [-38.9566072685, -7.8106566194],
            [-38.9703411707, -7.7755248687],
            [-39.0084986942, -7.7761116655],
            [-39.0203438037, -7.7728272787],
            [-39.0534394921, -7.7636443946],
            [-39.0607858854, -7.7544597682],
            [-39.0829860107, -7.726696293],
            [-39.1171450591, -7.6839480681],
            [-39.1154886281, -7.6806206385],
            [-39.0730645887, -7.6261683],
            [-39.0656653623, -7.6201194928],
            [-39.0447452972, -7.6412523321],
            [-39.027463686, -7.633400044],
            [-39.0148010417, -7.630357949],
            [-39.0123628008, -7.6233713841],
            [-38.9878294961, -7.6186969313],
            [-38.9674351782, -7.6079164321],
            [-38.9640292016, -7.6066464112],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2307254',
        name: 'Jijoca de Jericoacoara',
        description: 'Jijoca de Jericoacoara',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.452254662, -2.8572375101],
            [-40.4575690928, -2.8667681652],
            [-40.4537327629, -2.8787547999],
            [-40.4560070475, -2.8902193926],
            [-40.4587538156, -2.8943421787],
            [-40.4549240823, -2.8962416579],
            [-40.4543144127, -2.8918368172],
            [-40.4434953504, -2.8941173706],
            [-40.4355722268, -2.9003644394],
            [-40.4307590157, -2.914173763],
            [-40.4354587092, -2.9314844009],
            [-40.4306945091, -2.9437877897],
            [-40.4120640192, -2.9506105703],
            [-40.4065057161, -2.9668388417],
            [-40.4496347791, -2.9617794891],
            [-40.4663893451, -2.9598127395],
            [-40.4812781176, -2.9580652114],
            [-40.5108945936, -2.9562406466],
            [-40.5152214913, -2.9545594316],
            [-40.5291617716, -2.9452631587],
            [-40.5248789886, -2.9275743889],
            [-40.533766022, -2.9167174878],
            [-40.5330442568, -2.9061094615],
            [-40.5280577522, -2.8911356559],
            [-40.5374572829, -2.8850916344],
            [-40.5604435036, -2.8813107331],
            [-40.5733445756, -2.8661609593],
            [-40.5768913188, -2.8666874642],
            [-40.5769474841, -2.8589885615],
            [-40.5842312419, -2.8487280195],
            [-40.5716465269, -2.8396522128],
            [-40.5735301742, -2.8374195853],
            [-40.5629220497, -2.828592316],
            [-40.5333145392, -2.8176967736],
            [-40.5193923545, -2.8022634976],
            [-40.5204443283, -2.7906142879],
            [-40.4992860753, -2.7840322639],
            [-40.4546403162, -2.8038371989],
            [-40.4595358798, -2.8097524602],
            [-40.4597936437, -2.8170554084],
            [-40.4598050817, -2.8173827993],
            [-40.4570018229, -2.8222404522],
            [-40.4571823736, -2.8384620026],
            [-40.4573118488, -2.8388452861],
            [-40.452254662, -2.8572375101],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2307304',
        name: 'Juazeiro do Norte',
        description: 'Juazeiro do Norte',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.2497782828, -7.1053386169],
            [-39.2429172723, -7.1193962227],
            [-39.2356972589, -7.1225181194],
            [-39.2431125129, -7.1314027799],
            [-39.2378454358, -7.1335127161],
            [-39.2271623333, -7.1272893864],
            [-39.2150558611, -7.1296829655],
            [-39.2100253118, -7.1259294603],
            [-39.2070052262, -7.1271574216],
            [-39.2095869954, -7.1337714793],
            [-39.2063378819, -7.1377788771],
            [-39.2063225709, -7.1379141436],
            [-39.1956904745, -7.1421150523],
            [-39.1946118564, -7.1511390152],
            [-39.2003461255, -7.1579939779],
            [-39.2007414914, -7.1611965927],
            [-39.2044124241, -7.1610945889],
            [-39.2045941651, -7.1667374606],
            [-39.2017117589, -7.1784579101],
            [-39.1977567135, -7.1818338609],
            [-39.2089518872, -7.1867707276],
            [-39.2165293323, -7.2222672481],
            [-39.2184253364, -7.2227339778],
            [-39.2185359477, -7.2227313168],
            [-39.2356980973, -7.2269822531],
            [-39.261312932, -7.2380082634],
            [-39.2722930941, -7.242735087],
            [-39.2731586252, -7.2431073709],
            [-39.2894098226, -7.2501039454],
            [-39.2895268443, -7.2501540458],
            [-39.3143945878, -7.2608600179],
            [-39.3315942724, -7.2682650149],
            [-39.343045117, -7.2731953096],
            [-39.3490381409, -7.275775389],
            [-39.3495365762, -7.2726546843],
            [-39.354028509, -7.2448650941],
            [-39.355754018, -7.2341838953],
            [-39.3567735309, -7.2278718926],
            [-39.3570909739, -7.2259056448],
            [-39.3676912085, -7.2103440984],
            [-39.3665549565, -7.1804916188],
            [-39.3665108541, -7.1609335497],
            [-39.3432560799, -7.140126398],
            [-39.3443660926, -7.1340950479],
            [-39.3372378966, -7.1169758868],
            [-39.3425417767, -7.1095108279],
            [-39.3297636122, -7.1098156944],
            [-39.294825261, -7.1106467379],
            [-39.2854390261, -7.1032560478],
            [-39.2811105483, -7.0937574635],
            [-39.2669453921, -7.094338577],
            [-39.2647846454, -7.0972872385],
            [-39.2497782828, -7.1053386169],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2307403',
        name: 'Jucás',
        description: 'Jucás',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.5779989289, -6.3138217622],
            [-39.5617400855, -6.3285185967],
            [-39.5593927662, -6.3316490207],
            [-39.5520814051, -6.3238589512],
            [-39.542066443, -6.3194275373],
            [-39.5236962218, -6.3259341856],
            [-39.5285020018, -6.331552646],
            [-39.5221126787, -6.338088129],
            [-39.5086978333, -6.328754431],
            [-39.5014434037, -6.3342321581],
            [-39.4911926587, -6.3245094508],
            [-39.4835560783, -6.3297852056],
            [-39.4849644127, -6.3352470676],
            [-39.4751416912, -6.3373062929],
            [-39.4685557055, -6.329430376],
            [-39.4681092916, -6.3237828221],
            [-39.4593669141, -6.3247458872],
            [-39.4645547199, -6.3554713156],
            [-39.4692814236, -6.3574399486],
            [-39.472893339, -6.3745546954],
            [-39.4643867368, -6.3770581756],
            [-39.4572751142, -6.3854345979],
            [-39.4463379712, -6.3955304036],
            [-39.4331698169, -6.396915783],
            [-39.4215541148, -6.3920560137],
            [-39.4199751017, -6.3966233952],
            [-39.4174491714, -6.3955101834],
            [-39.4164930362, -6.4028245399],
            [-39.4070307907, -6.4074322492],
            [-39.4090444298, -6.4218469587],
            [-39.4107213624, -6.4338548728],
            [-39.4118323066, -6.4418106937],
            [-39.4074962021, -6.4505953508],
            [-39.4011223763, -6.4635049675],
            [-39.4238327482, -6.4635600909],
            [-39.4323262737, -6.4741178906],
            [-39.4314103749, -6.4805291495],
            [-39.4468932694, -6.4926532833],
            [-39.4544706832, -6.4942579154],
            [-39.4617215808, -6.5084682474],
            [-39.4718609101, -6.5118812555],
            [-39.4881400896, -6.5100809374],
            [-39.4942490594, -6.5144556263],
            [-39.5132984835, -6.5275911258],
            [-39.5172464563, -6.5347136673],
            [-39.516524946, -6.541103707],
            [-39.537503976, -6.5588764688],
            [-39.5741267436, -6.5727963569],
            [-39.5838321011, -6.5779000049],
            [-39.5838261843, -6.5723517018],
            [-39.5997018817, -6.5783179527],
            [-39.5984195518, -6.5843928405],
            [-39.6224175356, -6.5948068897],
            [-39.6469152937, -6.6102047702],
            [-39.6778646459, -6.6227231273],
            [-39.6969228932, -6.6183130985],
            [-39.7209774501, -6.6238561887],
            [-39.7391315669, -6.6320352376],
            [-39.7340678837, -6.6174768668],
            [-39.7381137911, -6.6110092072],
            [-39.7191805878, -6.6181573489],
            [-39.7209706991, -6.613227176],
            [-39.7293902229, -6.612460275],
            [-39.7335290237, -6.5996222402],
            [-39.7747254548, -6.6244128138],
            [-39.7775328532, -6.6197316789],
            [-39.7703059088, -6.6118789388],
            [-39.7749484079, -6.6087847036],
            [-39.7636237171, -6.5963548209],
            [-39.770420994, -6.585942855],
            [-39.7511925535, -6.5814088866],
            [-39.7498650149, -6.5648347621],
            [-39.7413853778, -6.5618204344],
            [-39.7373101485, -6.5497595206],
            [-39.7292163944, -6.5391750622],
            [-39.7354107162, -6.5364179236],
            [-39.7388083098, -6.5293686211],
            [-39.7419068588, -6.5295344665],
            [-39.7495956267, -6.5206743746],
            [-39.7503726367, -6.5113778408],
            [-39.7445562465, -6.5043934169],
            [-39.7247658419, -6.4951272361],
            [-39.7378636855, -6.4863786653],
            [-39.7398591931, -6.4818257789],
            [-39.7599128547, -6.4744865211],
            [-39.7739170333, -6.4603341973],
            [-39.7666900771, -6.441986832],
            [-39.7757490993, -6.4296182858],
            [-39.776336876, -6.4124125832],
            [-39.7659035829, -6.4014381103],
            [-39.7581401021, -6.3994064303],
            [-39.7507212807, -6.3886667071],
            [-39.7433033866, -6.3858337445],
            [-39.7395041321, -6.3749612382],
            [-39.7270294954, -6.3704160769],
            [-39.7272018213, -6.3669018307],
            [-39.7196628437, -6.3594523798],
            [-39.7141471563, -6.3588491299],
            [-39.7143647962, -6.3533049358],
            [-39.709677971, -6.3505935248],
            [-39.701550138, -6.3412558373],
            [-39.7024769664, -6.3381982272],
            [-39.6946807005, -6.3366814928],
            [-39.695375188, -6.3336221425],
            [-39.6887383498, -6.3292648477],
            [-39.6802558782, -6.3371368546],
            [-39.6740857664, -6.3327780028],
            [-39.6704378037, -6.3349859145],
            [-39.6532206971, -6.3371194269],
            [-39.6260531561, -6.3182419124],
            [-39.6177796364, -6.3204866411],
            [-39.615696823, -6.32477072],
            [-39.6090890936, -6.3209420446],
            [-39.6053941345, -6.3223453088],
            [-39.6050455671, -6.3189355523],
            [-39.5903370456, -6.3068746734],
            [-39.5892300777, -6.3115343684],
            [-39.5779989289, -6.3138217622],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2307502',
        name: 'Lavras da Mangabeira',
        description: 'Lavras da Mangabeira',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.8474983309, -6.656961979],
            [-38.8438165036, -6.6664743732],
            [-38.8529798402, -6.6740355119],
            [-38.859223834, -6.6884754157],
            [-38.8588922186, -6.6925662467],
            [-38.8531670183, -6.6940334041],
            [-38.8519687403, -6.7078513866],
            [-38.8473431066, -6.7097338296],
            [-38.8479562448, -6.7185512177],
            [-38.8425596416, -6.7249356545],
            [-38.827998636, -6.7364066536],
            [-38.828900078, -6.7445370867],
            [-38.7901572596, -6.7726058151],
            [-38.8232529764, -6.7778992291],
            [-38.8202118185, -6.7882446734],
            [-38.8168661664, -6.8001034967],
            [-38.8186148015, -6.8071703643],
            [-38.8136104877, -6.8200449688],
            [-38.8153580071, -6.8256677031],
            [-38.809610861, -6.8388250567],
            [-38.811042307, -6.8465083312],
            [-38.8356707677, -6.84755612],
            [-38.8560263357, -6.858905808],
            [-38.8631176325, -6.8567200769],
            [-38.8677497734, -6.8606632052],
            [-38.8915514747, -6.8560450904],
            [-38.9065561217, -6.8589317743],
            [-38.9141723167, -6.8603964788],
            [-38.9378026453, -6.8620782044],
            [-38.9507201752, -6.8671360775],
            [-38.9688497361, -6.8600535154],
            [-38.9715435799, -6.8625933387],
            [-38.9808196642, -6.8613057202],
            [-38.9790501406, -6.8677924605],
            [-38.9932084997, -6.8693438846],
            [-39.0145271154, -6.877309244],
            [-39.0218063028, -6.8950481612],
            [-39.0335474511, -6.898123118],
            [-39.0430779003, -6.9007512714],
            [-39.0447453684, -6.9080529067],
            [-39.0715578193, -6.9181284862],
            [-39.0671116681, -6.9207267327],
            [-39.0743312665, -6.9256353384],
            [-39.0715031141, -6.9312338707],
            [-39.086230052, -6.9320517096],
            [-39.0903267167, -6.9358514439],
            [-39.0876123518, -6.9360253992],
            [-39.0888048012, -6.9431670982],
            [-39.0927493039, -6.943592571],
            [-39.1005517744, -6.9533454387],
            [-39.1021197121, -6.9609060902],
            [-39.1081942841, -6.9626930733],
            [-39.1189113789, -6.9426308952],
            [-39.1394829264, -6.9393217265],
            [-39.167193732, -6.9345915358],
            [-39.1671818669, -6.9286758678],
            [-39.167144601, -6.9113771526],
            [-39.1686769316, -6.9022062679],
            [-39.1833399968, -6.8703144299],
            [-39.1860910491, -6.8643278371],
            [-39.1854004461, -6.8595429498],
            [-39.1804118262, -6.8250066042],
            [-39.1629485394, -6.7921853579],
            [-39.1603261736, -6.7872590631],
            [-39.1408826106, -6.7507279312],
            [-39.1292243812, -6.7222097285],
            [-39.1090309445, -6.7141353794],
            [-39.0910268458, -6.7124023083],
            [-39.0823385236, -6.7054092797],
            [-39.0702004367, -6.7052954272],
            [-39.0627202744, -6.6993798882],
            [-39.0446370401, -6.6944241349],
            [-39.0498860772, -6.684648279],
            [-39.0573528968, -6.6828382716],
            [-39.0635714711, -6.6671163479],
            [-39.0531960602, -6.6595501414],
            [-39.047069324, -6.6756460067],
            [-39.0171587139, -6.6607332505],
            [-38.9981669775, -6.6584662292],
            [-38.9806413908, -6.662968346],
            [-38.9636751578, -6.6538843063],
            [-38.9629681018, -6.6529091741],
            [-38.9605079635, -6.6456666884],
            [-38.9510321097, -6.6437543089],
            [-38.9482548313, -6.6347772031],
            [-38.9433212909, -6.6317468688],
            [-38.9317397722, -6.6166197706],
            [-38.9235898221, -6.6071462039],
            [-38.9193889876, -6.5650444014],
            [-38.9150222139, -6.574912405],
            [-38.9100863591, -6.5774978717],
            [-38.8767639583, -6.5813593634],
            [-38.8768354229, -6.5852446442],
            [-38.8692203417, -6.5927616302],
            [-38.8733045717, -6.6120216461],
            [-38.8474983309, -6.656961979],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2307601',
        name: 'Limoeiro do Norte',
        description: 'Limoeiro do Norte',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.1938191384, -4.9698260139],
            [-38.1703006343, -4.99091586],
            [-38.1208889593, -5.0307685225],
            [-38.1003148459, -5.0524162376],
            [-38.1002490743, -5.052485241],
            [-38.0786960998, -5.0703241513],
            [-38.0623652156, -5.0803344774],
            [-38.0616084353, -5.0807963475],
            [-38.056578465, -5.0829714678],
            [-38.0419360993, -5.0893002894],
            [-38.0450317031, -5.0799237473],
            [-38.0392481715, -5.0782847459],
            [-38.0022585185, -5.0789904703],
            [-37.9989743921, -5.0814683421],
            [-37.9985761731, -5.0817687862],
            [-37.995121749, -5.0841293037],
            [-37.9718013475, -5.1000633507],
            [-37.969505286, -5.1030795015],
            [-37.9456566981, -5.1345787694],
            [-37.9227932127, -5.1750991729],
            [-37.8938518993, -5.194306613],
            [-37.8271864158, -5.2224725453],
            [-37.8237526365, -5.2240308294],
            [-37.7831751931, -5.2424396538],
            [-37.7901132091, -5.2545667773],
            [-37.7915138643, -5.2683818465],
            [-37.7822606741, -5.2863473476],
            [-37.7827648341, -5.2944258126],
            [-37.7865246108, -5.2967794214],
            [-37.916958689, -5.2968049557],
            [-37.9249777322, -5.2968069191],
            [-37.9537512448, -5.2733651837],
            [-37.9672007223, -5.254032265],
            [-37.9934226641, -5.245449403],
            [-38.0045755332, -5.2364033059],
            [-38.0148879483, -5.2246370369],
            [-38.0541897007, -5.1967633281],
            [-38.0674406056, -5.1879694384],
            [-38.0723697436, -5.1812284526],
            [-38.0914117287, -5.1905448398],
            [-38.1077428155, -5.1957083866],
            [-38.1146545564, -5.1996994522],
            [-38.1276065153, -5.209327575],
            [-38.1405263521, -5.2112287126],
            [-38.1423150409, -5.2107598429],
            [-38.150881435, -5.2085142013],
            [-38.1648236437, -5.2048597189],
            [-38.207313812, -5.1937176993],
            [-38.2310958623, -5.161254129],
            [-38.2384651457, -5.1688793688],
            [-38.2362757044, -5.1787530115],
            [-38.2236781775, -5.1941120005],
            [-38.2250032872, -5.2051197914],
            [-38.2288623575, -5.2068157893],
            [-38.2325780134, -5.2155675978],
            [-38.2382071931, -5.2095080141],
            [-38.2339290834, -5.2003329085],
            [-38.2383712051, -5.1881402592],
            [-38.2481770117, -5.1796155533],
            [-38.2461889903, -5.173193377],
            [-38.2485154171, -5.1678342655],
            [-38.244098009, -5.1586764072],
            [-38.2433674121, -5.1563133363],
            [-38.243318821, -5.1559513122],
            [-38.2405058111, -5.1350633096],
            [-38.2315238974, -5.1045964238],
            [-38.2138102064, -5.0458332237],
            [-38.2136000538, -5.0101068417],
            [-38.213615611, -4.9740426177],
            [-38.2125434519, -4.9740692821],
            [-38.1992695983, -4.974399719],
            [-38.1938191384, -4.9698260139],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2307635',
        name: 'Madalena',
        description: 'Madalena',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.5788867448, -4.7469056694],
            [-39.5697863612, -4.746983397],
            [-39.512666281, -4.7279248571],
            [-39.475203057, -4.7258485556],
            [-39.466631852, -4.7258129313],
            [-39.3670182173, -4.7253930976],
            [-39.3718884071, -4.7328366801],
            [-39.3699990203, -4.7392047102],
            [-39.3556422502, -4.7512236547],
            [-39.3285995218, -4.7338936697],
            [-39.3147618104, -4.7220592761],
            [-39.3139888656, -4.7270622926],
            [-39.302085702, -4.7229526241],
            [-39.297216193, -4.7287810193],
            [-39.2900618801, -4.7389079673],
            [-39.2964921223, -4.7376043208],
            [-39.3005835991, -4.7432745841],
            [-39.3059820959, -4.7527042755],
            [-39.3168913956, -4.7514949085],
            [-39.3279755396, -4.7627899786],
            [-39.345872957, -4.7762510393],
            [-39.343109646, -4.7773648791],
            [-39.3456811171, -4.7877813535],
            [-39.3410969759, -4.7879560249],
            [-39.342872443, -4.7936666607],
            [-39.3471996411, -4.803091591],
            [-39.3448906916, -4.8120001664],
            [-39.3483934682, -4.8221913576],
            [-39.357481239, -4.8371403066],
            [-39.3654500085, -4.8408625809],
            [-39.3626139353, -4.846410065],
            [-39.3644456875, -4.8491823011],
            [-39.3574765687, -4.8535899753],
            [-39.3606384845, -4.856517188],
            [-39.3538887593, -4.8593481412],
            [-39.3530627481, -4.8791726667],
            [-39.3467564337, -4.8951478349],
            [-39.3515046093, -4.8973681698],
            [-39.3538914935, -4.9076669688],
            [-39.3601410742, -4.9124413082],
            [-39.3583114082, -4.9180548062],
            [-39.3647541842, -4.9340292201],
            [-39.3586449726, -4.9374551143],
            [-39.3548377872, -4.9528609678],
            [-39.3501101243, -4.9571505316],
            [-39.3474209952, -4.9605412235],
            [-39.3541114515, -4.9658528516],
            [-39.3498630615, -4.974942801],
            [-39.3548691995, -4.982180665],
            [-39.3627282731, -4.9756043317],
            [-39.3754424284, -4.9717264895],
            [-39.3741943011, -4.9662772302],
            [-39.3800849904, -4.9569934528],
            [-39.3850629565, -4.9549634052],
            [-39.3851404477, -4.9459585179],
            [-39.389642621, -4.9426126799],
            [-39.3862803226, -4.9356867631],
            [-39.3965268245, -4.927863631],
            [-39.4135547743, -4.9412293724],
            [-39.4224787213, -4.9430841929],
            [-39.4213125658, -4.9500266762],
            [-39.4411918285, -4.9543340418],
            [-39.4658413242, -4.9632423605],
            [-39.4725335927, -4.9753246921],
            [-39.4728362862, -4.9755414894],
            [-39.4800585456, -4.9799796283],
            [-39.4752007681, -5.0006214207],
            [-39.4699078882, -5.0104378031],
            [-39.4724672529, -5.0136925229],
            [-39.4778594348, -5.0205515917],
            [-39.4821424312, -5.0259996466],
            [-39.5023238117, -5.0516758451],
            [-39.5152193606, -5.0507856705],
            [-39.5223239126, -5.0502950471],
            [-39.5480859415, -5.0485152837],
            [-39.6293569305, -5.0428909895],
            [-39.6224737922, -5.0346913974],
            [-39.6117479412, -5.0307252051],
            [-39.6065216143, -5.0199084279],
            [-39.6088550517, -5.0133729332],
            [-39.6016173348, -5.0021665903],
            [-39.6167257812, -4.9843267489],
            [-39.6134710787, -4.975331834],
            [-39.6048357248, -4.9690521272],
            [-39.6030594036, -4.9608878814],
            [-39.6089702516, -4.9509716954],
            [-39.6060879665, -4.940826172],
            [-39.6177911769, -4.9163104125],
            [-39.6178054099, -4.9161958365],
            [-39.6188899213, -4.9073512897],
            [-39.6295872201, -4.8994541266],
            [-39.628629757, -4.8919118216],
            [-39.6361119927, -4.8868881007],
            [-39.6451007102, -4.8730963468],
            [-39.6518869044, -4.8624845635],
            [-39.6501683726, -4.8548339953],
            [-39.6418897824, -4.8448332077],
            [-39.6570548443, -4.8324966962],
            [-39.6610457366, -4.8076845002],
            [-39.6716790648, -4.7944767846],
            [-39.6766689763, -4.789120929],
            [-39.6909994879, -4.7931589934],
            [-39.6973376927, -4.7910499854],
            [-39.7037839559, -4.7830289278],
            [-39.721705357, -4.7834222429],
            [-39.7282014865, -4.7867521756],
            [-39.7280725007, -4.7821060842],
            [-39.7381033516, -4.7720045663],
            [-39.7347968775, -4.768465531],
            [-39.7258489129, -4.7631356336],
            [-39.7293110498, -4.7576485777],
            [-39.7261568837, -4.7497712583],
            [-39.7375624835, -4.7378186031],
            [-39.7254196332, -4.7200748607],
            [-39.7309553926, -4.7111878135],
            [-39.7288094194, -4.6964989043],
            [-39.7107339442, -4.7025830571],
            [-39.6797385882, -4.7130092894],
            [-39.6763112225, -4.7141617019],
            [-39.6498022994, -4.7230756276],
            [-39.6456820027, -4.724461006],
            [-39.5788867448, -4.7469056694],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2307650',
        name: 'Maracanaú',
        description: 'Maracanaú',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5835422519, -3.8389986138],
            [-38.5418531974, -3.8599217377],
            [-38.5502821416, -3.8588317426],
            [-38.5728070156, -3.8658868788],
            [-38.5820090927, -3.8766812587],
            [-38.5967202658, -3.8818015654],
            [-38.605393557, -3.8816315794],
            [-38.6204040775, -3.889212402],
            [-38.6249825273, -3.8905598945],
            [-38.613990304, -3.9261901594],
            [-38.6168155987, -3.9341922042],
            [-38.6214768757, -3.9447807322],
            [-38.6344563901, -3.9580506815],
            [-38.6299541846, -3.9738594315],
            [-38.63894168, -3.9806390249],
            [-38.6380433101, -3.9760096812],
            [-38.6427557635, -3.9744672284],
            [-38.6506344012, -3.9707064047],
            [-38.6483886456, -3.9644933526],
            [-38.6579829755, -3.9538791733],
            [-38.6560359896, -3.9475553382],
            [-38.6713203876, -3.9489172794],
            [-38.6715831177, -3.9467722384],
            [-38.6737219842, -3.93649388],
            [-38.6786689573, -3.9322028995],
            [-38.6635109724, -3.9213404075],
            [-38.6574718681, -3.920374059],
            [-38.6565730965, -3.9067961981],
            [-38.6306172207, -3.8833165636],
            [-38.6511624492, -3.876982603],
            [-38.6766378219, -3.8636710763],
            [-38.6891866901, -3.8571123398],
            [-38.6846533355, -3.8459628002],
            [-38.668473547, -3.846068137],
            [-38.6584345218, -3.84043996],
            [-38.6447395955, -3.8351581083],
            [-38.644135822, -3.8265027354],
            [-38.640103353, -3.8224666297],
            [-38.642923446, -3.8102938495],
            [-38.6363445448, -3.8003876496],
            [-38.6362380791, -3.8002504984],
            [-38.6066653857, -3.8266508926],
            [-38.5957059818, -3.8325039559],
            [-38.5835422519, -3.8389986138],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2307700',
        name: 'Maranguape',
        description: 'Maranguape',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.6786689573, -3.9322028995],
            [-38.6737219842, -3.93649388],
            [-38.6715831177, -3.9467722384],
            [-38.6713203876, -3.9489172794],
            [-38.6560359896, -3.9475553382],
            [-38.6579829755, -3.9538791733],
            [-38.6483886456, -3.9644933526],
            [-38.6506344012, -3.9707064047],
            [-38.6427557635, -3.9744672284],
            [-38.6460561091, -3.9860747625],
            [-38.6514181442, -3.9915899965],
            [-38.6576444701, -3.992431596],
            [-38.6586963579, -3.9995822662],
            [-38.6638205259, -4.0010284073],
            [-38.668971013, -4.0034102181],
            [-38.6694146206, -4.0084406976],
            [-38.6622054594, -4.0136919496],
            [-38.6673621686, -4.0180536135],
            [-38.6676361563, -4.0230810098],
            [-38.6738690688, -4.0290412939],
            [-38.6734985895, -4.0338475338],
            [-38.6716998982, -4.0387734917],
            [-38.6867177545, -4.0484909291],
            [-38.6992487505, -4.0517486767],
            [-38.7093664005, -4.0617150171],
            [-38.7223808297, -4.0677946509],
            [-38.7237598929, -4.0721335994],
            [-38.735511783, -4.0713841586],
            [-38.7453127044, -4.0721281611],
            [-38.7481564299, -4.0924023049],
            [-38.7583669599, -4.074935597],
            [-38.7604207079, -4.0724068189],
            [-38.7710923295, -4.0962682329],
            [-38.7767478893, -4.0982855427],
            [-38.7899152573, -4.0911054625],
            [-38.7964423293, -4.0875454439],
            [-38.7979671169, -4.0867137206],
            [-38.8306506392, -4.0688823953],
            [-38.8348373084, -4.0761642094],
            [-38.8303633461, -4.0830615942],
            [-38.8360516043, -4.0849333645],
            [-38.8413622533, -4.0937934284],
            [-38.8391292981, -4.1018311505],
            [-38.8390789863, -4.1020111114],
            [-38.8550301511, -4.1219920706],
            [-38.8580132532, -4.1303737342],
            [-38.8533820436, -4.1377574059],
            [-38.9081372166, -4.1148797419],
            [-38.9129591546, -4.1128641554],
            [-38.9172443756, -4.1110728959],
            [-38.919193088, -4.1102589119],
            [-38.9219935743, -4.1090881075],
            [-38.92552412, -4.1076124201],
            [-38.9416283373, -4.100878862],
            [-38.9459871033, -4.0960310799],
            [-38.9509990015, -4.1029469425],
            [-38.9533765963, -4.0911429215],
            [-38.9522755478, -4.0832264338],
            [-38.960695511, -4.0767021604],
            [-38.957203584, -4.0723312526],
            [-38.9840493551, -4.043989723],
            [-39.0047860019, -3.9925501357],
            [-38.987949027, -3.9795706192],
            [-38.9618432841, -3.9594507382],
            [-38.9429712988, -3.9449079807],
            [-38.9418004483, -3.9453044309],
            [-38.9214969943, -3.9521760521],
            [-38.9056173985, -3.9575507375],
            [-38.8851906019, -3.9644608268],
            [-38.8722802365, -3.9688283144],
            [-38.8489333789, -3.9920073867],
            [-38.8311960674, -3.9774160622],
            [-38.8110378133, -3.9608352916],
            [-38.8013588486, -3.9650403937],
            [-38.7873666488, -3.9662650867],
            [-38.7754650701, -3.9513865339],
            [-38.7753084204, -3.9511906918],
            [-38.7650543764, -3.9428846539],
            [-38.7549309601, -3.9282260624],
            [-38.7431191088, -3.9348588233],
            [-38.7363938163, -3.9288527612],
            [-38.7368707399, -3.9199086614],
            [-38.7315018142, -3.9121811937],
            [-38.7313198405, -3.9058144265],
            [-38.7254633797, -3.9013505494],
            [-38.7163561547, -3.8808616746],
            [-38.7006652723, -3.8712427851],
            [-38.6891866901, -3.8571123398],
            [-38.6766378219, -3.8636710763],
            [-38.6511624492, -3.876982603],
            [-38.6306172207, -3.8833165636],
            [-38.6565730965, -3.9067961981],
            [-38.6574718681, -3.920374059],
            [-38.6635109724, -3.9213404075],
            [-38.6786689573, -3.9322028995],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2307809',
        name: 'Marco',
        description: 'Marco',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.3550065477, -3.2017238854],
            [-40.3401048155, -3.2032834627],
            [-40.3217508675, -3.1931604597],
            [-40.3155815174, -3.1928613366],
            [-40.2975365716, -3.2035601646],
            [-40.2624270462, -3.1389879448],
            [-40.2609226953, -3.153146847],
            [-40.2563162586, -3.1618584818],
            [-40.2373482291, -3.1679074217],
            [-40.2318610587, -3.1630600484],
            [-40.228000759, -3.1587357849],
            [-40.2204425149, -3.1590299561],
            [-40.211002516, -3.1540633557],
            [-40.1954459267, -3.1409197121],
            [-40.1909746306, -3.1393461178],
            [-40.1868619058, -3.1320176362],
            [-40.1615607414, -3.116923667],
            [-40.150760559, -3.1085468483],
            [-40.1477006535, -3.1068558282],
            [-40.1522821189, -3.0923849874],
            [-40.1525563843, -3.0894687035],
            [-40.1462564775, -3.0785813488],
            [-40.1406769747, -3.0787636141],
            [-40.1398403189, -3.0726097591],
            [-40.1205477205, -3.0873988777],
            [-40.0835789372, -3.1157268],
            [-40.0834235016, -3.1158451385],
            [-40.0734089554, -3.12347731],
            [-40.0368829051, -3.1734419493],
            [-40.0038327953, -3.2186214253],
            [-40.0870078675, -3.1986100173],
            [-40.0947859857, -3.1962250937],
            [-40.1207326635, -3.1976991785],
            [-40.1217710273, -3.1984860063],
            [-40.1359777341, -3.2092439112],
            [-40.1429558893, -3.2145289123],
            [-40.1872717747, -3.2480987126],
            [-40.2370264049, -3.2858022809],
            [-40.2657342931, -3.2709544781],
            [-40.2781430442, -3.2645347219],
            [-40.275215967, -3.3073726712],
            [-40.2864765266, -3.3018215783],
            [-40.2868191406, -3.3014525518],
            [-40.2919822495, -3.299162369],
            [-40.3000291631, -3.3014044179],
            [-40.3138573818, -3.2895305751],
            [-40.3139969377, -3.2894603456],
            [-40.332339606, -3.2827293674],
            [-40.3382855866, -3.2836616809],
            [-40.3411458289, -3.2884333814],
            [-40.3475020455, -3.2885956914],
            [-40.3566460369, -3.2827114849],
            [-40.3566731096, -3.2773642001],
            [-40.3608411723, -3.2734543569],
            [-40.3868912256, -3.2714764752],
            [-40.3871423046, -3.2637409983],
            [-40.3924885287, -3.2604386816],
            [-40.3995588884, -3.2533073667],
            [-40.4059237686, -3.2384806178],
            [-40.4169730474, -3.2386508483],
            [-40.4183358678, -3.2321120221],
            [-40.4216727225, -3.2241043733],
            [-40.4214336393, -3.2234186669],
            [-40.4211938565, -3.2185177826],
            [-40.4293703824, -3.2153764301],
            [-40.4347811796, -3.1998069782],
            [-40.4421130784, -3.1945258504],
            [-40.4494600186, -3.1804657933],
            [-40.4546711741, -3.1696396825],
            [-40.4532004461, -3.1502667129],
            [-40.4632576819, -3.1380064172],
            [-40.47169853, -3.1337748524],
            [-40.4816488221, -3.1221009687],
            [-40.4973922602, -3.1201429876],
            [-40.5078511467, -3.1141991527],
            [-40.5098499221, -3.1079044949],
            [-40.5096853487, -3.1078294059],
            [-40.4939786709, -3.0633043044],
            [-40.4830033555, -3.0673438421],
            [-40.4757610834, -3.0791282973],
            [-40.4631690113, -3.0852958406],
            [-40.4471704576, -3.1000773451],
            [-40.4318874529, -3.1060260722],
            [-40.4166479359, -3.1254156087],
            [-40.3914529294, -3.1296809717],
            [-40.3810943351, -3.1342002901],
            [-40.375395065, -3.1410509628],
            [-40.3655704879, -3.1541241765],
            [-40.3610889102, -3.168993894],
            [-40.3632776241, -3.185574444],
            [-40.3550065477, -3.2017238854],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2307908',
        name: 'Martinópole',
        description: 'Martinópole',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.5348940074, -3.2298388208],
            [-40.5382289944, -3.2380575796],
            [-40.5287179034, -3.2388607317],
            [-40.5390593932, -3.2432157335],
            [-40.6691726626, -3.2472710998],
            [-40.6920817401, -3.2421032241],
            [-40.6919228715, -3.2376745171],
            [-40.6970459296, -3.2340514639],
            [-40.7038522739, -3.2362099129],
            [-40.7136930176, -3.2301362497],
            [-40.7143913259, -3.2195755618],
            [-40.7087468344, -3.2130952233],
            [-40.7272139495, -3.1297195187],
            [-40.7506720207, -3.0504100552],
            [-40.6859418588, -3.0799826044],
            [-40.647551812, -3.0990835313],
            [-40.5989301647, -3.1232645693],
            [-40.5731782105, -3.1360654389],
            [-40.5712792881, -3.1537845546],
            [-40.5747525394, -3.166072307],
            [-40.5572788111, -3.1743272219],
            [-40.5465560598, -3.1888651126],
            [-40.5460363401, -3.2032184891],
            [-40.5376146421, -3.2173678413],
            [-40.5348940074, -3.2298388208],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2308005',
        name: 'Massapê',
        description: 'Massapê',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.3402658347, -3.3583570083],
            [-40.3364076584, -3.4001634924],
            [-40.3336114172, -3.403801324],
            [-40.3080958578, -3.4088807756],
            [-40.3053947188, -3.4141415169],
            [-40.2934530924, -3.4373969866],
            [-40.2694971054, -3.4828194254],
            [-40.2614142388, -3.4848520471],
            [-40.2586438506, -3.4937879126],
            [-40.2518422336, -3.4892806195],
            [-40.2561905641, -3.4981956687],
            [-40.2569757928, -3.5179401706],
            [-40.256432429, -3.5182822273],
            [-40.25224931, -3.5246892513],
            [-40.2550270505, -3.5572651131],
            [-40.2703087417, -3.5717306382],
            [-40.278727546, -3.5727606392],
            [-40.2816170906, -3.5841571102],
            [-40.298909484, -3.5916097358],
            [-40.2930389244, -3.6077160982],
            [-40.3016832351, -3.6212690869],
            [-40.3129709389, -3.6209097129],
            [-40.3200332395, -3.6167131067],
            [-40.3343055436, -3.6223310091],
            [-40.3480346373, -3.6145313635],
            [-40.3611820483, -3.6132230125],
            [-40.3636197909, -3.6168882518],
            [-40.3759760602, -3.6114863771],
            [-40.3821157803, -3.596129206],
            [-40.4050700379, -3.5759220366],
            [-40.4183510217, -3.5656902306],
            [-40.4136806824, -3.5649350615],
            [-40.4133847581, -3.559437527],
            [-40.4128844082, -3.5501318169],
            [-40.4122398639, -3.5381465222],
            [-40.4305786305, -3.5240354923],
            [-40.4306441899, -3.5239845237],
            [-40.4509248622, -3.5184444997],
            [-40.4643872374, -3.5213416626],
            [-40.468384571, -3.5078500493],
            [-40.4757472764, -3.5051411726],
            [-40.4775153487, -3.4948287799],
            [-40.4844916023, -3.5037203226],
            [-40.4991772359, -3.5097584553],
            [-40.5066993024, -3.5074736851],
            [-40.507457631, -3.5112611305],
            [-40.5172887899, -3.5112307558],
            [-40.5201406544, -3.5157606934],
            [-40.5214153814, -3.512051859],
            [-40.5316687421, -3.5149763277],
            [-40.5412577893, -3.5227082079],
            [-40.5339923138, -3.5024754337],
            [-40.556541088, -3.4832723576],
            [-40.5482194611, -3.4684675365],
            [-40.5365937119, -3.4477884527],
            [-40.5141251433, -3.3930014103],
            [-40.4925267721, -3.3911610223],
            [-40.4607731869, -3.3823934556],
            [-40.4533086057, -3.3833720238],
            [-40.4445120286, -3.3795826737],
            [-40.4158391443, -3.3692384712],
            [-40.4127225857, -3.3757323913],
            [-40.3996687643, -3.3721766224],
            [-40.3938413066, -3.3815257708],
            [-40.3760321637, -3.3798728896],
            [-40.3513446767, -3.3675843385],
            [-40.3402658347, -3.3583570083],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2308104',
        name: 'Mauriti',
        description: 'Mauriti',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.649371554, -7.216021793],
            [-38.6338986437, -7.2224757168],
            [-38.6192766003, -7.2203489329],
            [-38.6190629747, -7.2203175212],
            [-38.6150144135, -7.2297814775],
            [-38.6050711087, -7.2364705696],
            [-38.6002908898, -7.2343622839],
            [-38.5947596051, -7.2461244537],
            [-38.5827301976, -7.2479781601],
            [-38.5565785886, -7.2392772896],
            [-38.5516081579, -7.2430739684],
            [-38.5540918801, -7.2514526044],
            [-38.5517467812, -7.2631555133],
            [-38.5417915355, -7.265496889],
            [-38.5422781605, -7.2779865512],
            [-38.5342339256, -7.2933880828],
            [-38.5397573575, -7.3058127252],
            [-38.5500020706, -7.3150591868],
            [-38.548729861, -7.3254162928],
            [-38.549029391, -7.3345923826],
            [-38.551858185, -7.3401951213],
            [-38.5608303989, -7.3578587066],
            [-38.5761281523, -7.3762034188],
            [-38.5832482409, -7.3977981119],
            [-38.5900306313, -7.400776054],
            [-38.5957717852, -7.4143497862],
            [-38.5841440698, -7.4159452174],
            [-38.5838972458, -7.4327719695],
            [-38.5946647173, -7.4335314724],
            [-38.5957208318, -7.4382244208],
            [-38.6054495065, -7.4441667886],
            [-38.6024905238, -7.4531522347],
            [-38.6029734746, -7.4571858665],
            [-38.6445646797, -7.4596127649],
            [-38.645818537, -7.4629329679],
            [-38.6413367123, -7.4645613598],
            [-38.6354693867, -7.4746115487],
            [-38.630550001, -7.4869235389],
            [-38.6456416319, -7.5015196094],
            [-38.646121234, -7.5171342533],
            [-38.6543036035, -7.5218153266],
            [-38.6511380638, -7.5273963568],
            [-38.6523566341, -7.5392689858],
            [-38.6561334557, -7.5457867763],
            [-38.6532179716, -7.5541615826],
            [-38.6587766803, -7.5602133013],
            [-38.6549708979, -7.5651361326],
            [-38.6741133356, -7.5689244314],
            [-38.6794901708, -7.5743161293],
            [-38.6884625635, -7.5817790305],
            [-38.702259074, -7.5806144665],
            [-38.7106693979, -7.5862269531],
            [-38.7126678752, -7.6079462545],
            [-38.7179445127, -7.6089484023],
            [-38.7146195831, -7.6158656493],
            [-38.717239438, -7.6264807291],
            [-38.7747056111, -7.6027216642],
            [-38.8132532008, -7.5493721641],
            [-38.8371834275, -7.516229379],
            [-38.8531078714, -7.4945905958],
            [-38.8812264777, -7.4585541978],
            [-38.8821215666, -7.4574065729],
            [-38.879193785, -7.4504066274],
            [-38.8611825584, -7.4073543969],
            [-38.8414163922, -7.3601300515],
            [-38.8361460379, -7.3475419106],
            [-38.8319205884, -7.3374498158],
            [-38.8175380977, -7.3031087837],
            [-38.8136938843, -7.293933812],
            [-38.8129655902, -7.2921938956],
            [-38.8090172584, -7.2827719736],
            [-38.808938621, -7.2825823251],
            [-38.8027670313, -7.2678544688],
            [-38.7980761756, -7.2566617268],
            [-38.7843190021, -7.2238402548],
            [-38.7749744666, -7.2361432594],
            [-38.7698883886, -7.2370637137],
            [-38.7626949369, -7.2345550137],
            [-38.7414025985, -7.2374253469],
            [-38.7184891331, -7.2345872722],
            [-38.7060829899, -7.2330507292],
            [-38.6722316614, -7.2293568593],
            [-38.665497619, -7.2267834063],
            [-38.6627988288, -7.2219514697],
            [-38.6561857669, -7.2228830092],
            [-38.649371554, -7.216021793],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2308203',
        name: 'Meruoca',
        description: 'Meruoca',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.468384571, -3.5078500493],
            [-40.4643872374, -3.5213416626],
            [-40.4509248622, -3.5184444997],
            [-40.4306441899, -3.5239845237],
            [-40.4305786305, -3.5240354923],
            [-40.4122398639, -3.5381465222],
            [-40.4128844082, -3.5501318169],
            [-40.4133847581, -3.559437527],
            [-40.4136806824, -3.5649350615],
            [-40.4183510217, -3.5656902306],
            [-40.4050700379, -3.5759220366],
            [-40.3821157803, -3.596129206],
            [-40.3759760602, -3.6114863771],
            [-40.3636197909, -3.6168882518],
            [-40.3862944526, -3.6222946718],
            [-40.4030284503, -3.6261876765],
            [-40.3972851125, -3.6320379231],
            [-40.3975878562, -3.6360312554],
            [-40.4089743407, -3.6437739811],
            [-40.4404437815, -3.6371904586],
            [-40.452031521, -3.6354618738],
            [-40.4550848736, -3.6397937847],
            [-40.4638533234, -3.6407185944],
            [-40.4687384993, -3.6340428058],
            [-40.4734271771, -3.6376351763],
            [-40.4999031733, -3.6296216952],
            [-40.5024432379, -3.6271412027],
            [-40.5051467093, -3.62510389],
            [-40.4985893642, -3.6146788794],
            [-40.4975872041, -3.6047017791],
            [-40.4879213462, -3.599650166],
            [-40.4793021227, -3.5932959497],
            [-40.4909186384, -3.5839811157],
            [-40.4977537193, -3.5836677692],
            [-40.5008847257, -3.5767103117],
            [-40.4819192277, -3.5621582231],
            [-40.4817536814, -3.5620317894],
            [-40.4808600882, -3.5568638559],
            [-40.4898733049, -3.5498022841],
            [-40.5074220189, -3.5503561887],
            [-40.5211703597, -3.5451814379],
            [-40.5146188466, -3.5318260307],
            [-40.5201406544, -3.5157606934],
            [-40.5172887899, -3.5112307558],
            [-40.507457631, -3.5112611305],
            [-40.5066993024, -3.5074736851],
            [-40.4991772359, -3.5097584553],
            [-40.4844916023, -3.5037203226],
            [-40.4775153487, -3.4948287799],
            [-40.4757472764, -3.5051411726],
            [-40.468384571, -3.5078500493],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2308302',
        name: 'Milagres',
        description: 'Milagres',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.9171612502, -7.230788768],
            [-38.913643317, -7.2415343798],
            [-38.9077939597, -7.2397059976],
            [-38.9043764881, -7.2372322765],
            [-38.8986903683, -7.2420029409],
            [-38.8792274264, -7.2363818164],
            [-38.8686825167, -7.2447550238],
            [-38.8640471193, -7.2398006301],
            [-38.860384839, -7.2406850358],
            [-38.8576090246, -7.2353183897],
            [-38.8511370806, -7.2393950889],
            [-38.8415036, -7.239478576],
            [-38.8220767062, -7.2260894881],
            [-38.8005822934, -7.226925776],
            [-38.7970130781, -7.2183867226],
            [-38.7843190021, -7.2238402548],
            [-38.7980761756, -7.2566617268],
            [-38.8027670313, -7.2678544688],
            [-38.808938621, -7.2825823251],
            [-38.8090172584, -7.2827719736],
            [-38.8129655902, -7.2921938956],
            [-38.8136938843, -7.293933812],
            [-38.8175380977, -7.3031087837],
            [-38.8319205884, -7.3374498158],
            [-38.8361460379, -7.3475419106],
            [-38.8414163922, -7.3601300515],
            [-38.8611825584, -7.4073543969],
            [-38.879193785, -7.4504066274],
            [-38.8821215666, -7.4574065729],
            [-38.9389291104, -7.4291001396],
            [-38.9435272645, -7.4268084913],
            [-38.9699430065, -7.425970715],
            [-38.962617023, -7.400213519],
            [-38.9719671186, -7.387834805],
            [-38.9850850716, -7.3348895108],
            [-38.9916996484, -7.3238963626],
            [-38.9990156117, -7.3117342122],
            [-39.0045049874, -7.3049062384],
            [-39.0352707997, -7.2951371054],
            [-39.0407580044, -7.2932186375],
            [-39.0613093049, -7.2843310049],
            [-39.0735578594, -7.2712409979],
            [-39.0916094225, -7.2593150187],
            [-39.0913589404, -7.2538370084],
            [-39.0872236442, -7.251261751],
            [-39.0887819749, -7.2442831513],
            [-39.0842220205, -7.2392200827],
            [-39.0857737476, -7.234532032],
            [-39.0739309719, -7.2270074239],
            [-39.0731480479, -7.2264732157],
            [-39.0254666647, -7.1939748037],
            [-39.0248349137, -7.1509201371],
            [-38.9611820334, -7.0851111382],
            [-38.9607240874, -7.0846646512],
            [-38.9583079897, -7.0823101848],
            [-38.9086180674, -7.0823010036],
            [-38.9260504402, -7.1155191018],
            [-38.9501028292, -7.1372226388],
            [-38.9470972741, -7.1444856345],
            [-38.9206022843, -7.2202861647],
            [-38.9171612502, -7.230788768],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2308351',
        name: 'Milhã',
        description: 'Milhã',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.0320488435, -5.5006933846],
            [-39.0756859049, -5.600757597],
            [-39.0895386857, -5.6311183256],
            [-39.0942593337, -5.6414644568],
            [-39.1326077415, -5.7283552534],
            [-39.1326316238, -5.7284083655],
            [-39.1625335011, -5.7965411147],
            [-39.1627775929, -5.7973105943],
            [-39.1796967476, -5.7998736009],
            [-39.1848601557, -5.7909035847],
            [-39.2088480245, -5.7875779787],
            [-39.2093530194, -5.7819288173],
            [-39.2213880791, -5.7743916084],
            [-39.2217906715, -5.7667231481],
            [-39.2290930259, -5.7636345782],
            [-39.2334735462, -5.769365858],
            [-39.2373434638, -5.7695409985],
            [-39.2544556938, -5.7570736708],
            [-39.2682495455, -5.7638537041],
            [-39.2740012067, -5.7613687293],
            [-39.2674913059, -5.7538917699],
            [-39.2606438751, -5.7152610201],
            [-39.2728657916, -5.7087645127],
            [-39.268282122, -5.6930946207],
            [-39.2610618382, -5.6777683487],
            [-39.2800937674, -5.6671998809],
            [-39.2761765036, -5.6602254148],
            [-39.2760759456, -5.6511179064],
            [-39.2738052837, -5.6517014449],
            [-39.2671809756, -5.6398849234],
            [-39.2693991332, -5.6327146978],
            [-39.251911144, -5.6007887568],
            [-39.2464685399, -5.5920873287],
            [-39.2456005619, -5.584054359],
            [-39.2321527719, -5.577905764],
            [-39.2233788176, -5.5590665888],
            [-39.224609148, -5.544924258],
            [-39.2069507861, -5.5438537634],
            [-39.2013068602, -5.5414305568],
            [-39.1992808453, -5.535191387],
            [-39.1918078065, -5.5286886109],
            [-39.1766126628, -5.5273691043],
            [-39.1667552331, -5.5234370194],
            [-39.1644502176, -5.5193645872],
            [-39.1596331983, -5.5186880538],
            [-39.1582413658, -5.515021185],
            [-39.1523336385, -5.5159874707],
            [-39.1456294678, -5.5086504282],
            [-39.1396651212, -5.507442217],
            [-39.1330689773, -5.5118408716],
            [-39.1159397612, -5.5067247416],
            [-39.1088405165, -5.510879513],
            [-39.0958823163, -5.511847731],
            [-39.0909666452, -5.5064409304],
            [-39.0871942825, -5.5103999716],
            [-39.0886862217, -5.5283606334],
            [-39.0756288115, -5.5332850127],
            [-39.059934928, -5.5106943214],
            [-39.0460829315, -5.5109913385],
            [-39.0413605407, -5.5034799467],
            [-39.0358607744, -5.5022931828],
            [-39.0306922142, -5.4858014134],
            [-39.0291881645, -5.4929539036],
            [-39.0320488435, -5.5006933846],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2308377',
        name: 'Miraíma',
        description: 'Miraíma',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.9183412677, -3.4388872687],
            [-39.8961625349, -3.4449151669],
            [-39.8948603619, -3.4451439062],
            [-39.8928678526, -3.4454934628],
            [-39.8674513752, -3.4504343928],
            [-39.7841606489, -3.4644658241],
            [-39.7878517165, -3.4683669924],
            [-39.7843698015, -3.5008955324],
            [-39.7778634517, -3.5024505492],
            [-39.7678516633, -3.5246365837],
            [-39.7691943093, -3.5297019046],
            [-39.7572487582, -3.5573932974],
            [-39.7628977392, -3.5675133812],
            [-39.7657656769, -3.5700884525],
            [-39.7750283107, -3.5814165809],
            [-39.7750620723, -3.5892068551],
            [-39.7561147328, -3.6033847195],
            [-39.7127247388, -3.6547411931],
            [-39.7128602091, -3.6547143469],
            [-39.7804750756, -3.6414541599],
            [-39.7795564516, -3.6356276282],
            [-39.8006323995, -3.6186751383],
            [-39.8025101945, -3.6124389289],
            [-39.8091241294, -3.6096439735],
            [-39.807236218, -3.6044251875],
            [-39.8169367193, -3.6039090285],
            [-39.8314916598, -3.6040366909],
            [-39.8316053881, -3.604131433],
            [-39.9001645545, -3.6614414642],
            [-39.9000334349, -3.6777426685],
            [-39.9082849083, -3.6915057452],
            [-39.9063988832, -3.6990504241],
            [-39.9119593989, -3.7177027393],
            [-39.9143482426, -3.7210177601],
            [-39.9657475883, -3.7157357432],
            [-40.0153716229, -3.7119695029],
            [-40.0202086844, -3.6935836272],
            [-40.015162776, -3.6860089416],
            [-40.0154585099, -3.670748386],
            [-40.0152230767, -3.6702670502],
            [-40.0076029224, -3.6555150827],
            [-40.0087823913, -3.6458106094],
            [-40.0063094928, -3.6436382999],
            [-39.9938280113, -3.627267056],
            [-39.9935648526, -3.618101583],
            [-39.9861595893, -3.6122508504],
            [-39.9863414994, -3.6048740346],
            [-39.9728966722, -3.5712291191],
            [-39.9975669834, -3.5806055275],
            [-40.0105002337, -3.5781222017],
            [-40.0220108354, -3.5826448831],
            [-40.03806194, -3.580762454],
            [-40.038677193, -3.5809086034],
            [-40.0438844708, -3.5809771383],
            [-40.0530382624, -3.5897707849],
            [-40.0704839224, -3.594056705],
            [-40.0703240848, -3.5938566871],
            [-40.0516974611, -3.5818203586],
            [-40.0421171997, -3.5662183149],
            [-40.0433821509, -3.5631576892],
            [-40.0448485403, -3.5596959823],
            [-40.0490583195, -3.5338036943],
            [-40.0454746055, -3.5309318356],
            [-40.0457816118, -3.5225221399],
            [-40.041336064, -3.5177759226],
            [-40.041002097, -3.5041672306],
            [-40.0366680048, -3.4961339188],
            [-40.0412813018, -3.4741917994],
            [-40.0376136194, -3.461315288],
            [-40.025264614, -3.4477704205],
            [-40.0269976745, -3.4433197533],
            [-40.0271025775, -3.4377725805],
            [-40.0135270179, -3.4377526438],
            [-39.9845631855, -3.4410405287],
            [-39.9514539157, -3.4329722859],
            [-39.9336050278, -3.4359743382],
            [-39.9183412677, -3.4388872687],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2308401',
        name: 'Missão Velha',
        description: 'Missão Velha',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.1520301715, -7.1297756301],
            [-39.1469204979, -7.1337745687],
            [-39.1364417609, -7.1303744147],
            [-39.1350134448, -7.1322962793],
            [-39.1350498875, -7.1376159907],
            [-39.1283755572, -7.1438355526],
            [-39.1196729947, -7.1441906856],
            [-39.1135502323, -7.1574501004],
            [-39.0969435178, -7.1544975109],
            [-39.0919190496, -7.16145656],
            [-39.0906726631, -7.1561917878],
            [-39.079694433, -7.1554012589],
            [-39.0789185337, -7.1500981213],
            [-39.0722453961, -7.1434742574],
            [-39.0688849862, -7.1473767275],
            [-39.0512529868, -7.1384659204],
            [-39.045352207, -7.1426024616],
            [-39.0418377632, -7.1427678792],
            [-39.039389886, -7.1487460891],
            [-39.0326704822, -7.149027704],
            [-39.0309979961, -7.152113255],
            [-39.0248349137, -7.1509201371],
            [-39.0254666647, -7.1939748037],
            [-39.0731480479, -7.2264732157],
            [-39.0739309719, -7.2270074239],
            [-39.0857737476, -7.234532032],
            [-39.0842220205, -7.2392200827],
            [-39.0887819749, -7.2442831513],
            [-39.0872236442, -7.251261751],
            [-39.0913589404, -7.2538370084],
            [-39.0916094225, -7.2593150187],
            [-39.0908677104, -7.2602473272],
            [-39.0894621198, -7.2658774962],
            [-39.0817136843, -7.2699891518],
            [-39.0835354706, -7.2786909717],
            [-39.0905850231, -7.281708887],
            [-39.0812032038, -7.2984556808],
            [-39.0847055514, -7.309352611],
            [-39.095480973, -7.3181456369],
            [-39.1015549879, -7.3277226515],
            [-39.1010908316, -7.3414524542],
            [-39.1008719796, -7.3429301091],
            [-39.0949647958, -7.3635665806],
            [-39.0956411399, -7.3698438945],
            [-39.0902377832, -7.383012034],
            [-39.0840265059, -7.4066304771],
            [-39.0777823302, -7.4226461221],
            [-39.0767910805, -7.4369772138],
            [-39.0758251586, -7.4475871478],
            [-39.0809283149, -7.451726793],
            [-39.0807776095, -7.4578607197],
            [-39.0881970299, -7.4620701449],
            [-39.0980490885, -7.4649812477],
            [-39.1165780088, -7.4613353232],
            [-39.1456878413, -7.4548617293],
            [-39.1538887673, -7.4500837641],
            [-39.1593767154, -7.4571007082],
            [-39.1904335231, -7.4968105797],
            [-39.1949219175, -7.5025505028],
            [-39.2560444623, -7.4866061851],
            [-39.2560689064, -7.4242906013],
            [-39.2560745931, -7.3967372237],
            [-39.2434535927, -7.3485064436],
            [-39.2325157041, -7.3054328816],
            [-39.2315446322, -7.30167085],
            [-39.2276670982, -7.2866504132],
            [-39.2358452659, -7.2796863485],
            [-39.2358448743, -7.2793999193],
            [-39.2356980973, -7.2269822531],
            [-39.2185359477, -7.2227313168],
            [-39.2184253364, -7.2227339778],
            [-39.2165293323, -7.2222672481],
            [-39.2089518872, -7.1867707276],
            [-39.1977567135, -7.1818338609],
            [-39.2017117589, -7.1784579101],
            [-39.2045941651, -7.1667374606],
            [-39.2044124241, -7.1610945889],
            [-39.2007414914, -7.1611965927],
            [-39.2003461255, -7.1579939779],
            [-39.1946118564, -7.1511390152],
            [-39.17995309, -7.147748446],
            [-39.1644093587, -7.132264577],
            [-39.1549024226, -7.1324662454],
            [-39.1520301715, -7.1297756301],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2308500',
        name: 'Mombaça',
        description: 'Mombaça',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.8158776963, -5.5671203554],
            [-39.8102535939, -5.5716048001],
            [-39.8043826416, -5.5712726638],
            [-39.8022061408, -5.5763842022],
            [-39.7747106321, -5.590480441],
            [-39.7719914954, -5.5902883732],
            [-39.7692149292, -5.5827516052],
            [-39.7610096604, -5.5815566812],
            [-39.7500861938, -5.5751856336],
            [-39.7447064373, -5.5813725709],
            [-39.7438979124, -5.5939749102],
            [-39.7376413645, -5.5977275784],
            [-39.7308941071, -5.595839794],
            [-39.6981071801, -5.601037691],
            [-39.6926628461, -5.6037669173],
            [-39.6934965261, -5.6113066907],
            [-39.68739203, -5.6133795204],
            [-39.6781712007, -5.6211528755],
            [-39.6742460591, -5.6205120969],
            [-39.6617529424, -5.6091054435],
            [-39.6544294552, -5.6123732159],
            [-39.6319524347, -5.6058232362],
            [-39.6301748513, -5.6227131704],
            [-39.6380013479, -5.6342370685],
            [-39.6372778415, -5.6392489184],
            [-39.605517712, -5.6204043497],
            [-39.6016824895, -5.6251419868],
            [-39.5874981816, -5.606850759],
            [-39.5779896251, -5.6100705944],
            [-39.571046104, -5.6072065966],
            [-39.5610651841, -5.6142014919],
            [-39.5432425795, -5.613216883],
            [-39.5110820402, -5.6306138213],
            [-39.5002932614, -5.617002795],
            [-39.483256983, -5.6150212486],
            [-39.4809252256, -5.6206448063],
            [-39.4821551775, -5.6341436389],
            [-39.4768261601, -5.6445581228],
            [-39.4688646506, -5.6433977564],
            [-39.4568345288, -5.6529423174],
            [-39.453583448, -5.6510474987],
            [-39.4411132238, -5.6570422104],
            [-39.4311141758, -5.6545197161],
            [-39.4372826441, -5.6635137223],
            [-39.4505896561, -5.6729432911],
            [-39.4508358784, -5.6774679711],
            [-39.4596784789, -5.6877738495],
            [-39.4725889646, -5.6868881758],
            [-39.4751137912, -5.7013214928],
            [-39.4793980812, -5.7026463174],
            [-39.4795003817, -5.7090674565],
            [-39.4902889175, -5.723362282],
            [-39.4997542797, -5.7235969187],
            [-39.5028199055, -5.7312887555],
            [-39.5028849356, -5.7324798898],
            [-39.5060259447, -5.7393769549],
            [-39.5550243834, -5.8414321762],
            [-39.5561220707, -5.8484617235],
            [-39.5516225962, -5.8527089087],
            [-39.5460010102, -5.8622200703],
            [-39.5516795918, -5.886123322],
            [-39.5516886942, -5.8862577009],
            [-39.5504134836, -5.908769893],
            [-39.557915509, -5.9261053025],
            [-39.5598129327, -5.953128305],
            [-39.5642495707, -5.9605898153],
            [-39.5763565699, -5.9632139844],
            [-39.5790041874, -5.968412024],
            [-39.5940605866, -5.9745815165],
            [-39.6001707278, -5.9887598726],
            [-39.6074345514, -5.9854980555],
            [-39.6453869928, -5.9656804837],
            [-39.6522806149, -5.9662556313],
            [-39.6647774355, -5.9929760362],
            [-39.6701736427, -5.9943533391],
            [-39.6746431671, -6.0220884603],
            [-39.6844703529, -6.0210438026],
            [-39.6861168101, -6.0156937281],
            [-39.6953924237, -6.01533467],
            [-39.7041937913, -6.0042856789],
            [-39.7112276171, -6.0073172632],
            [-39.726058433, -6.0065014669],
            [-39.7245076369, -6.0139179204],
            [-39.7279951146, -6.0127050542],
            [-39.7316662975, -6.0207831651],
            [-39.742238148, -6.0176895291],
            [-39.7412203356, -6.0118256254],
            [-39.7458995563, -6.0080259022],
            [-39.7413890682, -5.9999229023],
            [-39.7298083988, -5.9902517573],
            [-39.7227139467, -5.9750367362],
            [-39.7541616241, -6.0062875321],
            [-39.7686763227, -6.007473908],
            [-39.7741059631, -6.0157466825],
            [-39.7850287792, -6.0188251369],
            [-39.7944720115, -6.0207647436],
            [-39.8121149229, -6.0116855649],
            [-39.8151040211, -6.017385721],
            [-39.8261366602, -6.024996322],
            [-39.8222998514, -6.0349960991],
            [-39.825524552, -6.0415436698],
            [-39.8422552643, -6.0587115809],
            [-39.8560674626, -6.0665582065],
            [-39.8566316978, -6.0728954556],
            [-39.8638983642, -6.0764125988],
            [-39.8635236244, -6.0808807308],
            [-39.8767397021, -6.0861779446],
            [-39.8756858014, -6.0907291449],
            [-39.8871987341, -6.0941554703],
            [-39.8859282472, -6.0967950961],
            [-39.8895184919, -6.0981560565],
            [-39.904099536, -6.0922559818],
            [-39.904891726, -6.0850658412],
            [-39.9111271258, -6.0798241614],
            [-39.9177985757, -6.0821515376],
            [-39.9256733705, -6.0927284434],
            [-39.9354039689, -6.0965844925],
            [-39.9419146413, -6.1040143757],
            [-39.9473083788, -6.1043048411],
            [-39.9529219266, -6.0993820099],
            [-39.9566703492, -6.0992503178],
            [-39.9632849088, -6.0931007821],
            [-39.9626088369, -6.0874764461],
            [-39.9500696437, -6.0795519281],
            [-39.9486823249, -6.0818460896],
            [-39.9347377764, -6.0793587457],
            [-39.9207462511, -6.0677678713],
            [-39.910100255, -6.0580458689],
            [-39.905282732, -6.0486584443],
            [-39.9058893581, -6.0362527744],
            [-39.8892875464, -6.0340333527],
            [-39.8828924703, -6.0199301512],
            [-39.8719675363, -6.0138583064],
            [-39.8726559016, -6.008370584],
            [-39.8737082494, -6.0055294512],
            [-39.8683321912, -6.0057704158],
            [-39.8628015107, -6.0010004638],
            [-39.8624334983, -5.9950975429],
            [-39.8553079064, -5.9867543188],
            [-39.8472501236, -5.9896217471],
            [-39.8416321125, -5.9851161938],
            [-39.8445383049, -5.9807933893],
            [-39.8347817804, -5.975893112],
            [-39.8318493229, -5.9630270308],
            [-39.8337608179, -5.9563030728],
            [-39.8368262173, -5.9288035692],
            [-39.8494013906, -5.9330844791],
            [-39.853470535, -5.928317428],
            [-39.8533075069, -5.9218993922],
            [-39.858383041, -5.919834622],
            [-39.8654085602, -5.9137047389],
            [-39.8772596992, -5.909699664],
            [-39.8687810495, -5.898223565],
            [-39.8623124825, -5.8974065884],
            [-39.8639632059, -5.8950864825],
            [-39.865218078, -5.8927021625],
            [-39.8607060821, -5.8892997015],
            [-39.8676034962, -5.8893175525],
            [-39.8699462493, -5.8832733512],
            [-39.8766378619, -5.8845448973],
            [-39.8906942198, -5.8961333583],
            [-39.9055926165, -5.8967840864],
            [-39.9108701811, -5.9004595562],
            [-39.9205193976, -5.8967888886],
            [-39.9269427323, -5.8881183004],
            [-39.9319389818, -5.8890012015],
            [-39.9388263763, -5.8834904923],
            [-39.938206249, -5.8795593954],
            [-39.9465329322, -5.8797391925],
            [-39.95361382, -5.8731767772],
            [-39.9594666018, -5.872706492],
            [-39.9461666544, -5.8624404405],
            [-39.9401406592, -5.8622347564],
            [-39.937893308, -5.8566780371],
            [-39.9320951078, -5.8539549506],
            [-39.920012842, -5.8321989692],
            [-39.9350217943, -5.8259092184],
            [-39.9483535007, -5.8255488702],
            [-39.9498488497, -5.8216444043],
            [-39.9540157693, -5.8210232652],
            [-39.9604131922, -5.8163140291],
            [-39.9664280758, -5.8218318275],
            [-39.9747943, -5.8212355367],
            [-39.9856592791, -5.8133228445],
            [-39.9853895692, -5.7985800061],
            [-39.9898506922, -5.7913835003],
            [-39.9922059412, -5.7796617357],
            [-40.0089218645, -5.7742289916],
            [-40.0031843273, -5.7634997999],
            [-40.0073854472, -5.7475026947],
            [-40.0025586762, -5.7353737122],
            [-40.008178894, -5.7344486651],
            [-40.0078680195, -5.7142882515],
            [-40.0154677047, -5.7069958236],
            [-40.0214708139, -5.7104749114],
            [-40.0359062999, -5.7016675737],
            [-40.0426036206, -5.6806811874],
            [-40.0494695706, -5.6739182034],
            [-40.0368229416, -5.6472299438],
            [-40.0328359488, -5.6444064694],
            [-40.0341317594, -5.6407750471],
            [-40.0215365368, -5.642828539],
            [-40.0193575508, -5.6386234183],
            [-40.0223184825, -5.6352067096],
            [-40.0109348719, -5.6255727768],
            [-39.9903876164, -5.6336772289],
            [-39.9876892228, -5.6312602289],
            [-39.9815111672, -5.6343420359],
            [-39.9732536105, -5.6305611501],
            [-39.9682584184, -5.6322729713],
            [-39.9629443325, -5.6179305664],
            [-39.9580457979, -5.6190563209],
            [-39.9548071716, -5.6280624636],
            [-39.957728859, -5.6331174369],
            [-39.9519628834, -5.6393047692],
            [-39.9413289464, -5.6367431857],
            [-39.9396257409, -5.6327084323],
            [-39.9317166514, -5.6330622494],
            [-39.9381601443, -5.6269712585],
            [-39.928202465, -5.6211004669],
            [-39.9233149774, -5.6291332275],
            [-39.9252180317, -5.6352114193],
            [-39.9179754955, -5.6365096204],
            [-39.9112987273, -5.6408292318],
            [-39.9027459733, -5.6360745813],
            [-39.8934197691, -5.6294730831],
            [-39.8769656803, -5.6276983367],
            [-39.8757195085, -5.6384027856],
            [-39.8668934146, -5.6299512329],
            [-39.8594429707, -5.6260202644],
            [-39.8545938124, -5.6284616202],
            [-39.8508530313, -5.6252062314],
            [-39.8492329435, -5.6126087488],
            [-39.8453466661, -5.6095021006],
            [-39.8475216221, -5.6032230814],
            [-39.8411584163, -5.600167368],
            [-39.8311847693, -5.5821338515],
            [-39.8233042295, -5.5688148813],
            [-39.8158776963, -5.5671203554],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2308609',
        name: 'Monsenhor Tabosa',
        description: 'Monsenhor Tabosa',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.9245443581, -4.7348789161],
            [-39.9282201008, -4.7741557181],
            [-39.9392926638, -4.7701640451],
            [-39.9350792554, -4.7766849093],
            [-39.9386828597, -4.7823709342],
            [-39.9289378471, -4.7950563595],
            [-39.93003035, -4.7996887174],
            [-39.9482988421, -4.8159774441],
            [-39.9463970458, -4.821321982],
            [-39.9490016642, -4.8261950354],
            [-39.9448832992, -4.838227192],
            [-39.9402608914, -4.8412397605],
            [-39.9241355092, -4.864287901],
            [-39.9086660196, -4.8744488361],
            [-39.9004586827, -4.8840030854],
            [-39.8925631889, -4.8867776754],
            [-39.8957479489, -4.8907204537],
            [-39.8919863684, -4.8925073595],
            [-39.8938660435, -4.8934605706],
            [-39.9025958091, -4.899427287],
            [-39.9001128324, -4.901552868],
            [-39.9021333754, -4.9051103765],
            [-39.907697018, -4.9030636748],
            [-39.9190734421, -4.9082773566],
            [-39.9228753441, -4.9128752123],
            [-39.9290265453, -4.9132540964],
            [-39.9338683448, -4.9238249426],
            [-39.9391139601, -4.9215213679],
            [-39.9468148236, -4.9290447481],
            [-39.9513935968, -4.9236451873],
            [-39.9570122215, -4.9269172322],
            [-39.9621432317, -4.9318610601],
            [-39.9600333814, -4.9390748274],
            [-39.9680666968, -4.9400960682],
            [-39.9660309717, -4.9603287007],
            [-39.959713476, -5.0205515594],
            [-39.9599450305, -5.0285775414],
            [-39.9731144638, -5.0298259275],
            [-39.9687026447, -5.0434072244],
            [-39.9792332677, -5.0569130011],
            [-39.9967763795, -5.0722861943],
            [-40.0018008778, -5.0846603074],
            [-40.0456846234, -5.1061847939],
            [-40.048120066, -5.1073791268],
            [-40.1650056429, -5.1647322491],
            [-40.1656711681, -5.1590272115],
            [-40.162214329, -5.1550534812],
            [-40.1450867833, -5.152247056],
            [-40.1457828305, -5.1461748849],
            [-40.1344240843, -5.130784154],
            [-40.1369452736, -5.1218901169],
            [-40.1346433849, -5.1113144805],
            [-40.1398089007, -5.1048810298],
            [-40.1352269667, -5.0990823621],
            [-40.149592309, -5.0910271178],
            [-40.1501254039, -5.0826671677],
            [-40.1550601628, -5.0811064251],
            [-40.1537010055, -5.0767426301],
            [-40.1516140371, -5.075897618],
            [-40.1510973957, -5.0757629331],
            [-40.1421416033, -5.07379224],
            [-40.1485395806, -5.0597954699],
            [-40.1474537439, -5.0505846227],
            [-40.1474117797, -5.0502313372],
            [-40.1542949419, -5.043468822],
            [-40.1499394077, -5.0335170843],
            [-40.136714408, -5.0291844529],
            [-40.1218306835, -5.0297888084],
            [-40.1078683177, -5.0236907],
            [-40.0996977861, -5.0012905248],
            [-40.1047773974, -4.9970105656],
            [-40.1142539502, -5.0001141105],
            [-40.1183594303, -4.9954104006],
            [-40.1154235883, -4.9878853262],
            [-40.1192006285, -4.9851957373],
            [-40.1333844784, -4.9919131198],
            [-40.1307452049, -4.9870810613],
            [-40.1364483501, -4.9800560406],
            [-40.1333080551, -4.9771374725],
            [-40.1360315568, -4.9733001553],
            [-40.1571662714, -4.9692321252],
            [-40.1650861102, -4.9644999474],
            [-40.1653522047, -4.9376358795],
            [-40.1949755071, -4.908330249],
            [-40.1970293416, -4.9059402099],
            [-40.1683087185, -4.8981409391],
            [-40.1590349848, -4.9022196742],
            [-40.1440584962, -4.902163364],
            [-40.139562993, -4.8984776577],
            [-40.1365267906, -4.8888905276],
            [-40.1129226459, -4.881824664],
            [-40.106474481, -4.8710542983],
            [-40.111171237, -4.8499985364],
            [-40.138276332, -4.823003746],
            [-40.1420245234, -4.813705735],
            [-40.1452907739, -4.8077347306],
            [-40.1324323034, -4.8037133668],
            [-40.1281963366, -4.7985066082],
            [-40.1118214157, -4.7518829545],
            [-40.0590854725, -4.7174973622],
            [-39.9949769693, -4.7257850198],
            [-39.9556412024, -4.7308657504],
            [-39.9245443581, -4.7348789161],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2308708',
        name: 'Morada Nova',
        description: 'Morada Nova',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.235140461, -4.5734717079],
            [-38.2293405826, -4.5813603343],
            [-38.2180036641, -4.5872159407],
            [-38.2154438314, -4.5892110503],
            [-38.2437027905, -4.6210914933],
            [-38.2553218365, -4.6325379438],
            [-38.2711005789, -4.6487610209],
            [-38.2758385483, -4.6536860578],
            [-38.2856260359, -4.6628338184],
            [-38.2883348861, -4.6654124535],
            [-38.2925568172, -4.6745350258],
            [-38.3032276341, -4.680556104],
            [-38.3238378952, -4.6726208536],
            [-38.3290665863, -4.6740100463],
            [-38.363418566, -4.7017129407],
            [-38.3606708136, -4.7043472139],
            [-38.3624181383, -4.7130863867],
            [-38.3706073724, -4.7194863416],
            [-38.3721741613, -4.7217337312],
            [-38.390487894, -4.74587522],
            [-38.4075247368, -4.7565250361],
            [-38.4083391791, -4.7567477954],
            [-38.4113362609, -4.762843122],
            [-38.4207406313, -4.7576264689],
            [-38.421597465, -4.7680917741],
            [-38.4407217504, -4.790269393],
            [-38.4528196104, -4.7992537913],
            [-38.45886392, -4.8103131154],
            [-38.4684731095, -4.816415618],
            [-38.4596746349, -4.8206608186],
            [-38.458572989, -4.8211922693],
            [-38.4468885352, -4.8324780788],
            [-38.4354779795, -4.8337913937],
            [-38.4302639728, -4.8481558946],
            [-38.4304077657, -4.8495600739],
            [-38.4296412773, -4.8570460451],
            [-38.4153163803, -4.8632264118],
            [-38.3959132804, -4.857318449],
            [-38.3785308628, -4.8512336756],
            [-38.3717835614, -4.8543457269],
            [-38.3613072535, -4.8466817193],
            [-38.3564214365, -4.8508544881],
            [-38.3376196336, -4.8606975627],
            [-38.3253056041, -4.8548064033],
            [-38.3197796128, -4.8578136662],
            [-38.3110575021, -4.8571743583],
            [-38.300007526, -4.8682524524],
            [-38.290340291, -4.8693932678],
            [-38.2833085955, -4.8787999807],
            [-38.267500528, -4.8772664579],
            [-38.2609031353, -4.8842042403],
            [-38.2601569656, -4.8856151677],
            [-38.2319393897, -4.9389497858],
            [-38.213615611, -4.9740426177],
            [-38.2136000538, -5.0101068417],
            [-38.2138102064, -5.0458332237],
            [-38.2315238974, -5.1045964238],
            [-38.2405058111, -5.1350633096],
            [-38.243318821, -5.1559513122],
            [-38.2433674121, -5.1563133363],
            [-38.244098009, -5.1586764072],
            [-38.2485154171, -5.1678342655],
            [-38.2461889903, -5.173193377],
            [-38.2481770117, -5.1796155533],
            [-38.2383712051, -5.1881402592],
            [-38.2339290834, -5.2003329085],
            [-38.2382071931, -5.2095080141],
            [-38.2662498764, -5.2246545283],
            [-38.2697675109, -5.232432013],
            [-38.3001551555, -5.2585334869],
            [-38.3167560887, -5.2653146199],
            [-38.3283989406, -5.2775550655],
            [-38.3385017653, -5.2967695628],
            [-38.3350412119, -5.3046207328],
            [-38.3278981069, -5.3136973953],
            [-38.342661175, -5.3249195061],
            [-38.3427456345, -5.3249686684],
            [-38.3539112464, -5.330249124],
            [-38.3626172067, -5.3488485033],
            [-38.3748049213, -5.3556382226],
            [-38.3923082933, -5.3554659364],
            [-38.3940974766, -5.3625632144],
            [-38.3956692187, -5.3702388204],
            [-38.406333911, -5.3698464014],
            [-38.4125290931, -5.3738948846],
            [-38.5000252823, -5.4357043865],
            [-38.5069804971, -5.4386188851],
            [-38.5152892776, -5.4421011819],
            [-38.518699968, -5.4392566315],
            [-38.568790625, -5.3984841899],
            [-38.5690214357, -5.3982846971],
            [-38.60380689, -5.3679043268],
            [-38.6269086535, -5.3479247179],
            [-38.7056460868, -5.2816003876],
            [-38.7148350782, -5.2739693957],
            [-38.7117472816, -5.2684138866],
            [-38.7093726481, -5.2641420082],
            [-38.6985125282, -5.2446080313],
            [-38.7109981418, -5.2527130801],
            [-38.7192604301, -5.2516103801],
            [-38.7214714334, -5.2432678162],
            [-38.7152312508, -5.2351671017],
            [-38.7139137578, -5.2225268147],
            [-38.7185526846, -5.200491814],
            [-38.7081657062, -5.2010076498],
            [-38.7034769618, -5.2226324631],
            [-38.6775118502, -5.227805424],
            [-38.6141048338, -5.1517781224],
            [-38.6137341506, -5.1513337863],
            [-38.6105803529, -5.1436266472],
            [-38.6175572417, -5.1317479397],
            [-38.6158092411, -5.1232301949],
            [-38.618059763, -5.1185265061],
            [-38.6164451651, -5.110878963],
            [-38.6210329314, -5.0907284454],
            [-38.6234192675, -5.0852992578],
            [-38.6288657936, -5.084272092],
            [-38.6261115953, -5.0813788095],
            [-38.6284520888, -5.0786822001],
            [-38.6261926558, -5.0659005889],
            [-38.5638291481, -5.0759174864],
            [-38.536071064, -5.0801730114],
            [-38.4905751729, -5.086132711],
            [-38.4890920363, -5.0829716984],
            [-38.4975042455, -5.0668815519],
            [-38.4960885814, -5.0480932299],
            [-38.48447214, -5.0349208079],
            [-38.4858107589, -5.0295676927],
            [-38.4803648524, -5.0142834435],
            [-38.4824496098, -5.0060242772],
            [-38.4919187094, -5.008336763],
            [-38.5051499426, -4.9838939491],
            [-38.4994621113, -4.9787513075],
            [-38.5024018733, -4.9707574404],
            [-38.4977726672, -4.9657299356],
            [-38.5067855338, -4.9623352821],
            [-38.5037294916, -4.9560816212],
            [-38.4785389733, -4.9479753993],
            [-38.4784168187, -4.9479543139],
            [-38.4486758465, -4.9384069648],
            [-38.4314070601, -4.9328746944],
            [-38.4312978816, -4.9328390768],
            [-38.4225329217, -4.9309355036],
            [-38.41411669, -4.9464237949],
            [-38.4048687238, -4.9477743803],
            [-38.4045215658, -4.9472073801],
            [-38.415273601, -4.9264848881],
            [-38.4212344669, -4.903240569],
            [-38.4412936529, -4.8755261033],
            [-38.4491702847, -4.878570483],
            [-38.462336149, -4.8724962357],
            [-38.4645289359, -4.8812428931],
            [-38.4915354488, -4.8794274385],
            [-38.5057015652, -4.8884092137],
            [-38.5152624282, -4.8904838336],
            [-38.5261913967, -4.8847702314],
            [-38.53210734, -4.8833824357],
            [-38.5495200919, -4.8822334368],
            [-38.5593352586, -4.8767639002],
            [-38.5918145333, -4.8735010507],
            [-38.6245518642, -4.9006275449],
            [-38.6299890229, -4.9005089857],
            [-38.6314825979, -4.9109753306],
            [-38.6251689107, -4.9124327251],
            [-38.6309279153, -4.9254178982],
            [-38.6471286491, -4.9371621967],
            [-38.6385277281, -4.9272271728],
            [-38.6376581991, -4.9167131389],
            [-38.6491164057, -4.9021024468],
            [-38.6467260714, -4.8947896349],
            [-38.6564506566, -4.888083608],
            [-38.6661708257, -4.8718432231],
            [-38.6613321029, -4.8619553588],
            [-38.6497521364, -4.8507952955],
            [-38.6457626444, -4.8505287453],
            [-38.6380352229, -4.8497243361],
            [-38.6109414095, -4.8331592085],
            [-38.6012000741, -4.8188631017],
            [-38.5966179631, -4.8170090668],
            [-38.5967542633, -4.8122280389],
            [-38.5886478227, -4.8111788761],
            [-38.5781391673, -4.7967355059],
            [-38.5775448395, -4.7714874849],
            [-38.5620564079, -4.7600104833],
            [-38.5463045414, -4.7558866626],
            [-38.5399207901, -4.7569249066],
            [-38.5345627003, -4.753481053],
            [-38.5273908918, -4.7461520273],
            [-38.5230354753, -4.7354633118],
            [-38.5195567356, -4.7346097734],
            [-38.5325279422, -4.7077470288],
            [-38.5276709963, -4.6948742809],
            [-38.5285303918, -4.6798995787],
            [-38.5274261352, -4.6793340763],
            [-38.5155360387, -4.6730433675],
            [-38.5009180419, -4.6533757692],
            [-38.4738534317, -4.6489325996],
            [-38.4669739087, -4.6455329191],
            [-38.4635072067, -4.6501774058],
            [-38.4572252712, -4.6537279347],
            [-38.4486180863, -4.6500715655],
            [-38.4483941933, -4.6447880047],
            [-38.4333067298, -4.6365366174],
            [-38.4242701524, -4.6262181312],
            [-38.4216457749, -4.6182945297],
            [-38.4080557533, -4.606840349],
            [-38.4045913067, -4.596142759],
            [-38.3982591491, -4.5914388726],
            [-38.3917329674, -4.5882024332],
            [-38.3886432497, -4.5788682034],
            [-38.3830322849, -4.5818216642],
            [-38.3732753937, -4.5809026772],
            [-38.3687796404, -4.5763482062],
            [-38.3711865377, -4.5644018961],
            [-38.3676831469, -4.5610990986],
            [-38.3687476078, -4.5473316695],
            [-38.3646632425, -4.5406143454],
            [-38.3686959084, -4.5399556494],
            [-38.3725848054, -4.5304372075],
            [-38.3819255779, -4.5262356309],
            [-38.3819726192, -4.5183850856],
            [-38.3640084055, -4.506935079],
            [-38.3602829515, -4.5004912046],
            [-38.3464711277, -4.5130156208],
            [-38.3220584993, -4.5186732821],
            [-38.2957009762, -4.5416083166],
            [-38.2815778923, -4.5485102259],
            [-38.235140461, -4.5734717079],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2308807',
        name: 'Moraújo',
        description: 'Moraújo',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.6367469138, -3.3557576078],
            [-40.6010586491, -3.3689518342],
            [-40.6297634675, -3.3917181664],
            [-40.6196797567, -3.397022418],
            [-40.617433522, -3.4052204602],
            [-40.5978344612, -3.4123907589],
            [-40.5922055079, -3.4101266805],
            [-40.5891077074, -3.4122991641],
            [-40.5857366219, -3.4087103336],
            [-40.5705488022, -3.4117494931],
            [-40.5643859966, -3.4056786486],
            [-40.5613660484, -3.4065440391],
            [-40.566979439, -3.4280699291],
            [-40.5697530959, -3.4387078008],
            [-40.553919736, -3.4397432479],
            [-40.5484114998, -3.4468709433],
            [-40.5365937119, -3.4477884527],
            [-40.5482194611, -3.4684675365],
            [-40.556541088, -3.4832723576],
            [-40.5339923138, -3.5024754337],
            [-40.5412577893, -3.5227082079],
            [-40.5472620179, -3.5274653554],
            [-40.5507063156, -3.5379899301],
            [-40.5569780073, -3.5386833975],
            [-40.5589899687, -3.5311171832],
            [-40.6007007577, -3.5309926697],
            [-40.6166377788, -3.5309441505],
            [-40.647554947, -3.5308504677],
            [-40.6513140869, -3.5265546507],
            [-40.6700558522, -3.5297229581],
            [-40.6705556875, -3.520077638],
            [-40.6709589442, -3.5122941355],
            [-40.681855112, -3.5118111205],
            [-40.6913730566, -3.5100917719],
            [-40.7067213263, -3.5218848981],
            [-40.7617158958, -3.5048248264],
            [-40.8226694977, -3.5252629285],
            [-40.8347597577, -3.5290206876],
            [-40.8568282733, -3.5358792817],
            [-40.8314875605, -3.4997137728],
            [-40.8213282833, -3.4710036812],
            [-40.8267803476, -3.4538144768],
            [-40.8490443104, -3.4193782457],
            [-40.813458057, -3.4262210008],
            [-40.7981191286, -3.4179738349],
            [-40.7863399333, -3.422248151],
            [-40.7804092756, -3.4210662105],
            [-40.7783014728, -3.4239059831],
            [-40.771507253, -3.4220490484],
            [-40.7368051351, -3.437719216],
            [-40.7370167647, -3.4307262998],
            [-40.7201527504, -3.4227606831],
            [-40.7196446311, -3.408322856],
            [-40.7229055045, -3.4046916722],
            [-40.7206451168, -3.3939353066],
            [-40.7277776267, -3.3859364177],
            [-40.7232782028, -3.3825982067],
            [-40.7108970623, -3.392161946],
            [-40.7125143669, -3.3959097427],
            [-40.7002212021, -3.4011117839],
            [-40.6944288042, -3.3999637844],
            [-40.6889509657, -3.391616023],
            [-40.6790677621, -3.388565815],
            [-40.6773290877, -3.3768436067],
            [-40.6685109969, -3.3631039607],
            [-40.6627232811, -3.3611036653],
            [-40.6547912104, -3.3637086466],
            [-40.6527318557, -3.3582672173],
            [-40.640341609, -3.3609322275],
            [-40.6367469138, -3.3557576078],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2308906',
        name: 'Morrinhos',
        description: 'Morrinhos',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.9758550468, -3.2578693848],
            [-39.9350335154, -3.3157623158],
            [-39.9326419738, -3.3191669552],
            [-39.9368944694, -3.3224398903],
            [-39.9415624911, -3.3369814216],
            [-39.9571991204, -3.3494740888],
            [-39.9733878568, -3.3553660754],
            [-39.9812554592, -3.3631335912],
            [-39.9884726931, -3.3661823812],
            [-40.0000840765, -3.3611544003],
            [-40.0286486227, -3.3626774945],
            [-40.0460064119, -3.3637540371],
            [-40.0612583219, -3.3688678445],
            [-40.0711946196, -3.3668210257],
            [-40.081485436, -3.3561791202],
            [-40.0924286137, -3.3568062328],
            [-40.0828281184, -3.3146368338],
            [-40.0915562893, -3.3157684444],
            [-40.0917122289, -3.3157503773],
            [-40.1013907135, -3.3187767417],
            [-40.101926899, -3.3187876065],
            [-40.1161387394, -3.3140616302],
            [-40.1317552083, -3.3137663972],
            [-40.1455378459, -3.3032254147],
            [-40.1585287685, -3.3099597893],
            [-40.1640047016, -3.3159279098],
            [-40.1737525442, -3.3196637093],
            [-40.1855678908, -3.3170812874],
            [-40.1976496278, -3.3072850951],
            [-40.2000564902, -3.3047541708],
            [-40.2017186286, -3.3070502969],
            [-40.2568886225, -3.3120037965],
            [-40.2651158585, -3.3127419265],
            [-40.2742337658, -3.3135606587],
            [-40.299617486, -3.3158388844],
            [-40.2999167735, -3.3158657881],
            [-40.3246220101, -3.3180836883],
            [-40.3258506304, -3.3181934783],
            [-40.3409283475, -3.3195470935],
            [-40.3411458289, -3.2884333814],
            [-40.3382855866, -3.2836616809],
            [-40.332339606, -3.2827293674],
            [-40.3139969377, -3.2894603456],
            [-40.3138573818, -3.2895305751],
            [-40.3000291631, -3.3014044179],
            [-40.2919822495, -3.299162369],
            [-40.2868191406, -3.3014525518],
            [-40.2864765266, -3.3018215783],
            [-40.275215967, -3.3073726712],
            [-40.2781430442, -3.2645347219],
            [-40.2657342931, -3.2709544781],
            [-40.2370264049, -3.2858022809],
            [-40.1872717747, -3.2480987126],
            [-40.1429558893, -3.2145289123],
            [-40.1359777341, -3.2092439112],
            [-40.1217710273, -3.1984860063],
            [-40.1207326635, -3.1976991785],
            [-40.0947859857, -3.1962250937],
            [-40.0870078675, -3.1986100173],
            [-40.0038327953, -3.2186214253],
            [-40.0025312507, -3.2203321506],
            [-39.9911212239, -3.2362060455],
            [-39.9758550468, -3.2578693848],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2309003',
        name: 'Mucambo',
        description: 'Mucambo',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.7113307283, -3.8208571343],
            [-40.693602191, -3.8359506931],
            [-40.6698766225, -3.8457725431],
            [-40.6722794849, -3.8508618606],
            [-40.6830806041, -3.8559026935],
            [-40.6839824991, -3.864611536],
            [-40.6948108665, -3.8699757801],
            [-40.704758925, -3.8764523484],
            [-40.7076320632, -3.8851817447],
            [-40.7115441204, -3.8849318791],
            [-40.7118149065, -3.8908235213],
            [-40.7084867216, -3.8989979229],
            [-40.7117885897, -3.9119484966],
            [-40.7133219089, -3.9180799331],
            [-40.7044278906, -3.9253335767],
            [-40.695205779, -3.9258269587],
            [-40.6826257166, -3.9315559656],
            [-40.6783664544, -3.9288090644],
            [-40.674580121, -3.9349123328],
            [-40.6653611661, -3.9387590267],
            [-40.664260968, -3.9470682149],
            [-40.6684489733, -3.9459309803],
            [-40.6717897598, -3.9497177673],
            [-40.6712364715, -3.953064051],
            [-40.6653886871, -3.9523180788],
            [-40.6689306519, -3.9603365152],
            [-40.6766076712, -3.9540438249],
            [-40.6844583676, -3.9576673268],
            [-40.6911786566, -3.9545997912],
            [-40.7036537657, -3.9639885521],
            [-40.7123297101, -3.9641070201],
            [-40.7182122319, -3.9606120327],
            [-40.728424555, -3.967884076],
            [-40.7309563609, -3.9667012506],
            [-40.7371498751, -3.9621309499],
            [-40.7503862297, -3.9572995333],
            [-40.7504867471, -3.9569311497],
            [-40.7610435518, -3.9507685439],
            [-40.7661212541, -3.9535907109],
            [-40.7936747009, -3.9537535062],
            [-40.8042514199, -3.9556737861],
            [-40.8116337956, -3.9525066001],
            [-40.8223658973, -3.9569191039],
            [-40.8334699806, -3.9240413856],
            [-40.8218666051, -3.9196458934],
            [-40.7980590889, -3.9178767816],
            [-40.7915675092, -3.9060544763],
            [-40.7833599543, -3.8890211787],
            [-40.7771834239, -3.8867577744],
            [-40.7775970424, -3.8834430095],
            [-40.850638719, -3.895111925],
            [-40.8550192295, -3.8932524577],
            [-40.8533194521, -3.8845503679],
            [-40.8592789672, -3.8893986532],
            [-40.8568157869, -3.8771221702],
            [-40.8664766693, -3.8790285951],
            [-40.8696131928, -3.8740257913],
            [-40.8759032498, -3.8727991946],
            [-40.8731096633, -3.8683391625],
            [-40.8710764521, -3.8708666618],
            [-40.860896984, -3.8684398437],
            [-40.8463778275, -3.8635708773],
            [-40.8387448843, -3.8705572497],
            [-40.8301708665, -3.8656752993],
            [-40.8254841301, -3.8681884533],
            [-40.8164321752, -3.8603153083],
            [-40.8131857423, -3.8513208198],
            [-40.8054373578, -3.8455782667],
            [-40.7977027727, -3.8471792787],
            [-40.7941962358, -3.8438031665],
            [-40.7968770512, -3.841026287],
            [-40.7928152386, -3.839931542],
            [-40.7942410337, -3.8367947625],
            [-40.7895184909, -3.8374168022],
            [-40.7874815113, -3.8396756524],
            [-40.7654613034, -3.864085793],
            [-40.7399318227, -3.8280157164],
            [-40.7259546471, -3.8219245042],
            [-40.7187037458, -3.8227238432],
            [-40.7113307283, -3.8208571343],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2309102',
        name: 'Mulungu',
        description: 'Mulungu',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.9696778453, -4.2219607823],
            [-38.9621395376, -4.2431120622],
            [-38.94529616, -4.2903426078],
            [-38.9452369229, -4.2905110871],
            [-38.9333843624, -4.3237225027],
            [-38.9465735042, -4.323465556],
            [-38.9485500761, -4.3391201712],
            [-38.9572082091, -4.353121627],
            [-38.9599338289, -4.358537538],
            [-38.9717921841, -4.3710635432],
            [-38.9832072752, -4.3769451329],
            [-38.9827162537, -4.3804315762],
            [-38.9883552507, -4.377344118],
            [-39.0016006101, -4.3797305082],
            [-39.0048998187, -4.3767083483],
            [-39.0161784083, -4.3758022366],
            [-39.0188803736, -4.3689926936],
            [-39.0235236555, -4.3666406133],
            [-39.0236470171, -4.3664672046],
            [-39.0251724714, -4.3610024832],
            [-39.0368689091, -4.3599134779],
            [-39.0370491418, -4.3599571849],
            [-39.0512759257, -4.3529695805],
            [-39.0386098235, -4.3491189533],
            [-39.0333815405, -4.3352291557],
            [-39.0247807418, -4.3306764424],
            [-39.0273374467, -4.3278751152],
            [-39.0259430533, -4.3180696299],
            [-39.0377941083, -4.318886318],
            [-39.0358199936, -4.3088472701],
            [-39.0389556814, -4.3066303186],
            [-39.043979475, -4.309393647],
            [-39.0453259496, -4.2996711804],
            [-39.0399567214, -4.2985493241],
            [-39.0391592873, -4.2947548649],
            [-39.0376072605, -4.2919309534],
            [-39.0351102004, -4.2910465583],
            [-39.040273906, -4.2875086346],
            [-39.0213187846, -4.2882843976],
            [-39.0273907789, -4.2855117089],
            [-39.0322142634, -4.2777788909],
            [-39.0306256559, -4.2747926816],
            [-39.0256902146, -4.2732449227],
            [-39.019841901, -4.2765626113],
            [-39.0142246964, -4.2693751366],
            [-39.014792245, -4.2642890467],
            [-39.0102521115, -4.2760093769],
            [-39.0116715214, -4.2883933602],
            [-39.0087994384, -4.2827270274],
            [-39.003612966, -4.2734647698],
            [-39.0070739176, -4.2723601942],
            [-39.0036116839, -4.2624084807],
            [-39.0000351877, -4.2597568383],
            [-38.9953842923, -4.2643990103],
            [-38.9952139375, -4.2600873064],
            [-38.9919213203, -4.2607500423],
            [-39.0015693607, -4.2531204531],
            [-39.019842064, -4.256325316],
            [-39.0031591921, -4.2495833564],
            [-38.9941355313, -4.2498025435],
            [-39.0028944354, -4.2329062055],
            [-39.0098864465, -4.236020294],
            [-39.0110848941, -4.2314769018],
            [-39.0130188013, -4.228883434],
            [-39.0216078448, -4.230182036],
            [-39.0265327666, -4.227586641],
            [-39.0119572395, -4.2275683847],
            [-39.0085990934, -4.2321264266],
            [-38.9879277598, -4.2180057635],
            [-38.9852350034, -4.21033657],
            [-38.9745663418, -4.2082381058],
            [-38.9696778453, -4.2219607823],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2309201',
        name: 'Nova Olinda',
        description: 'Nova Olinda',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.6805053624, -7.0173812436],
            [-39.6722926657, -7.023704005],
            [-39.6563967349, -7.0190194093],
            [-39.6440930352, -7.0195730186],
            [-39.6438642871, -7.0147575351],
            [-39.6286379733, -7.0146706832],
            [-39.631573452, -7.0231430049],
            [-39.610644358, -7.0314384691],
            [-39.6105655111, -7.0314694544],
            [-39.5800364266, -7.0435657308],
            [-39.5838900421, -7.0486270648],
            [-39.5787527374, -7.0556559952],
            [-39.5811920496, -7.0578701064],
            [-39.5781056866, -7.0745269045],
            [-39.5840577147, -7.0771414399],
            [-39.5882466285, -7.0749234964],
            [-39.5951551991, -7.0811920964],
            [-39.5987514877, -7.0800728948],
            [-39.5987527022, -7.1288219859],
            [-39.598756196, -7.1291668298],
            [-39.598745364, -7.1696054481],
            [-39.5987401406, -7.2095323105],
            [-39.6057985596, -7.2100988365],
            [-39.6272894216, -7.1943823788],
            [-39.6650538615, -7.179832018],
            [-39.6929208272, -7.1702907909],
            [-39.7046048465, -7.1568382639],
            [-39.7111086451, -7.1405193383],
            [-39.7152177832, -7.1351453737],
            [-39.7420655319, -7.0969160303],
            [-39.74370188, -7.0945844365],
            [-39.7459297021, -7.0815069171],
            [-39.7487513889, -7.0798063862],
            [-39.7452399894, -7.0706808293],
            [-39.7559847389, -7.0585321379],
            [-39.7541012583, -7.053045733],
            [-39.7569009173, -7.0449777745],
            [-39.746912659, -7.0231994351],
            [-39.7588687729, -7.0132707754],
            [-39.7434454467, -7.0092663763],
            [-39.7369522773, -7.0075804836],
            [-39.7255621323, -7.0046234582],
            [-39.7163259571, -7.0022257566],
            [-39.6905069356, -7.0167206975],
            [-39.6805053624, -7.0173812436],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2309300',
        name: 'Nova Russas',
        description: 'Nova Russas',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.3743901879, -4.5535305145],
            [-40.4227333117, -4.6611123752],
            [-40.4228705312, -4.6615170092],
            [-40.4378439843, -4.7056778474],
            [-40.4402719333, -4.7128408607],
            [-40.4466596341, -4.7316890192],
            [-40.4572596071, -4.7481126676],
            [-40.4826387759, -4.7874432991],
            [-40.4923193342, -4.8024489859],
            [-40.4977374686, -4.8108460595],
            [-40.522440011, -4.8477379611],
            [-40.5398369657, -4.843412951],
            [-40.5458101956, -4.8679186812],
            [-40.5395989336, -4.8767406801],
            [-40.5399776086, -4.8924588483],
            [-40.5366749266, -4.8997870021],
            [-40.5420839082, -4.9148046882],
            [-40.5990021368, -4.8357101305],
            [-40.6014789385, -4.8323347679],
            [-40.615580218, -4.8131107439],
            [-40.6341183414, -4.7929474405],
            [-40.6336440005, -4.788347378],
            [-40.6336206763, -4.7840975471],
            [-40.6373757028, -4.759978071],
            [-40.6452511193, -4.7459393873],
            [-40.6482831831, -4.7336606623],
            [-40.6413094869, -4.726979289],
            [-40.6457134044, -4.7205942598],
            [-40.6566878969, -4.708463615],
            [-40.6595881852, -4.7035256491],
            [-40.6550834556, -4.689783756],
            [-40.6611410839, -4.6866698966],
            [-40.6262774719, -4.6220103779],
            [-40.6260720273, -4.6216288789],
            [-40.6223375769, -4.6254527794],
            [-40.602056366, -4.6187591472],
            [-40.5879214706, -4.5976692773],
            [-40.5834125889, -4.5780082309],
            [-40.5876024863, -4.574794015],
            [-40.5878488585, -4.5575254258],
            [-40.5166232022, -4.5121183476],
            [-40.5091183304, -4.5073350457],
            [-40.5058094782, -4.5087484376],
            [-40.4667994241, -4.5254062925],
            [-40.458814613, -4.5288147953],
            [-40.3938310503, -4.5460921466],
            [-40.3874086066, -4.5477817684],
            [-40.3743901879, -4.5535305145],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2309409',
        name: 'Novo Oriente',
        description: 'Novo Oriente',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.5841777049, -5.4670258354],
            [-40.5969607997, -5.4907941339],
            [-40.6206744437, -5.5291378545],
            [-40.6160310027, -5.5482406905],
            [-40.6086012283, -5.5579106561],
            [-40.6136372766, -5.5649517294],
            [-40.6131645724, -5.57077488],
            [-40.6210950804, -5.5702828331],
            [-40.6214397937, -5.5761647543],
            [-40.62704382, -5.5786522829],
            [-40.6277678972, -5.5853414626],
            [-40.62003231, -5.5984349009],
            [-40.6266924201, -5.6085300781],
            [-40.6383969232, -5.6153423693],
            [-40.6385992938, -5.6195783371],
            [-40.6380956571, -5.6244317518],
            [-40.6282224422, -5.6312017587],
            [-40.6314640944, -5.6435508276],
            [-40.6345707666, -5.6468108685],
            [-40.6382047157, -5.6438390471],
            [-40.6475928295, -5.65739738],
            [-40.6470165773, -5.6653651163],
            [-40.6532735557, -5.6676966827],
            [-40.6459279125, -5.67696288],
            [-40.6407648231, -5.6842768099],
            [-40.6324367397, -5.6800109743],
            [-40.6175564794, -5.6839481857],
            [-40.6194769631, -5.6912836043],
            [-40.6245825668, -5.6952647447],
            [-40.6274174481, -5.6940193309],
            [-40.6335814899, -5.7006417317],
            [-40.6193042334, -5.7131912264],
            [-40.620553325, -5.7184141382],
            [-40.6129665243, -5.7270149719],
            [-40.6165641375, -5.7346345041],
            [-40.6238668587, -5.7327392097],
            [-40.6399007896, -5.7184020204],
            [-40.6364020916, -5.7120322914],
            [-40.6398086579, -5.7066916362],
            [-40.6544838275, -5.700874305],
            [-40.6594386643, -5.6943736757],
            [-40.6695809161, -5.6679902405],
            [-40.6906675985, -5.6561469637],
            [-40.6950444515, -5.6429925458],
            [-40.6962631216, -5.6446756739],
            [-40.7504931829, -5.7192902406],
            [-40.7507949237, -5.7190917448],
            [-40.7568379783, -5.7166575643],
            [-40.760362836, -5.7235465282],
            [-40.7672778267, -5.7251046996],
            [-40.7800812078, -5.7356224415],
            [-40.7921690432, -5.7338597072],
            [-40.8143580491, -5.7545212097],
            [-40.8287448599, -5.7568738264],
            [-40.8451692977, -5.7705104408],
            [-40.8655528886, -5.7657742587],
            [-40.8685836436, -5.7674730568],
            [-40.8815376484, -5.7703284201],
            [-40.9094227418, -5.7653247081],
            [-40.9173806345, -5.7444017357],
            [-40.9131691552, -5.7263550501],
            [-40.9247775137, -5.7087585647],
            [-40.93000516, -5.6858230195],
            [-40.9383969302, -5.6729497939],
            [-40.9287497873, -5.6627396859],
            [-40.9108607847, -5.6561681156],
            [-40.9073860598, -5.641955905],
            [-40.9024918809, -5.629268928],
            [-40.9009119164, -5.6114811218],
            [-40.9122187411, -5.5896410268],
            [-40.9141131793, -5.5670019565],
            [-40.9325842638, -5.5468902193],
            [-40.9313817015, -5.5360388245],
            [-40.9360823114, -5.5259983182],
            [-40.928506777, -5.5174586915],
            [-40.9283873532, -5.5065585105],
            [-40.9326834016, -5.5003282235],
            [-40.9289487104, -5.4926983233],
            [-40.9287847818, -5.4829442009],
            [-40.918140225, -5.4735173401],
            [-40.9081686922, -5.4734928979],
            [-40.8995925285, -5.4734715774],
            [-40.9000703233, -5.4780206663],
            [-40.8927096577, -5.4798831907],
            [-40.8903549817, -5.4848318208],
            [-40.8736364188, -5.4933090175],
            [-40.8652380069, -5.4932366081],
            [-40.8584727765, -5.4898710419],
            [-40.8522120632, -5.4810784488],
            [-40.8451454123, -5.4792129674],
            [-40.8266857165, -5.4834265278],
            [-40.81489153, -5.479967502],
            [-40.8119282711, -5.4884887],
            [-40.8069435762, -5.489551761],
            [-40.7980616667, -5.4919257511],
            [-40.7935510882, -5.4967198999],
            [-40.7845777634, -5.4933420304],
            [-40.7719924359, -5.4946547894],
            [-40.7718306048, -5.4859770797],
            [-40.7622379938, -5.4800725905],
            [-40.7316663967, -5.4858345579],
            [-40.7308427858, -5.4671999303],
            [-40.7242633763, -5.4618572808],
            [-40.715449287, -5.4674989146],
            [-40.7080644676, -5.4670013833],
            [-40.6948775554, -5.4624518875],
            [-40.6912164579, -5.4652698352],
            [-40.6911956783, -5.4711707416],
            [-40.6815648956, -5.4663922073],
            [-40.6841785855, -5.4496452838],
            [-40.6606655975, -5.4479476748],
            [-40.6566518626, -5.4411917118],
            [-40.6492925289, -5.4387284072],
            [-40.6504211528, -5.4346858664],
            [-40.6456968311, -5.4262832195],
            [-40.6421380631, -5.4262841588],
            [-40.5543385102, -5.4263034406],
            [-40.5525067147, -5.4318854758],
            [-40.5686890317, -5.4379910084],
            [-40.5771452987, -5.4629716737],
            [-40.5841777049, -5.4670258354],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2309458',
        name: 'Ocara',
        description: 'Ocara',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.4613576522, -4.3968404416],
            [-38.4429330052, -4.4096240129],
            [-38.4358899018, -4.3854106069],
            [-38.4185516564, -4.4054675371],
            [-38.3614658863, -4.4714523717],
            [-38.3614057239, -4.4716080948],
            [-38.3581996967, -4.4799481405],
            [-38.36079788, -4.4894220857],
            [-38.3610618866, -4.4992856568],
            [-38.3602829515, -4.5004912046],
            [-38.3640084055, -4.506935079],
            [-38.3819726192, -4.5183850856],
            [-38.3819255779, -4.5262356309],
            [-38.3725848054, -4.5304372075],
            [-38.3686959084, -4.5399556494],
            [-38.3646632425, -4.5406143454],
            [-38.3687476078, -4.5473316695],
            [-38.3676831469, -4.5610990986],
            [-38.3711865377, -4.5644018961],
            [-38.3687796404, -4.5763482062],
            [-38.3732753937, -4.5809026772],
            [-38.3830322849, -4.5818216642],
            [-38.3886432497, -4.5788682034],
            [-38.3917329674, -4.5882024332],
            [-38.3982591491, -4.5914388726],
            [-38.4045913067, -4.596142759],
            [-38.4080557533, -4.606840349],
            [-38.4216457749, -4.6182945297],
            [-38.4242701524, -4.6262181312],
            [-38.4333067298, -4.6365366174],
            [-38.4483941933, -4.6447880047],
            [-38.4486180863, -4.6500715655],
            [-38.4572252712, -4.6537279347],
            [-38.4635072067, -4.6501774058],
            [-38.4669739087, -4.6455329191],
            [-38.4738534317, -4.6489325996],
            [-38.5009180419, -4.6533757692],
            [-38.5155360387, -4.6730433675],
            [-38.5274261352, -4.6793340763],
            [-38.5285303918, -4.6798995787],
            [-38.5377019204, -4.6798411959],
            [-38.5548689137, -4.6736792644],
            [-38.5690347756, -4.6751055578],
            [-38.572974969, -4.6706503351],
            [-38.5830012645, -4.6665064209],
            [-38.5927802029, -4.6694739693],
            [-38.6069545476, -4.6621564672],
            [-38.6104262708, -4.6566477719],
            [-38.6100759099, -4.6489324633],
            [-38.6101191937, -4.6486478981],
            [-38.6239494354, -4.6159721615],
            [-38.6328123287, -4.6050717257],
            [-38.6443795342, -4.5727227441],
            [-38.6448582771, -4.5714770701],
            [-38.6505480539, -4.5554656542],
            [-38.6374822927, -4.5504805067],
            [-38.6202526856, -4.5355514953],
            [-38.6196074434, -4.5186239385],
            [-38.6252341475, -4.5083812109],
            [-38.6248633387, -4.5035814171],
            [-38.6368967649, -4.4847260993],
            [-38.6370341622, -4.4778776564],
            [-38.6365305209, -4.4772798444],
            [-38.6328392408, -4.4704315796],
            [-38.6370201301, -4.4600626291],
            [-38.636335184, -4.4581995722],
            [-38.6371742246, -4.4534930502],
            [-38.6316157377, -4.4525942541],
            [-38.6267542498, -4.4386407395],
            [-38.6148863571, -4.4356702351],
            [-38.6146704548, -4.4297372963],
            [-38.6045011663, -4.4264775077],
            [-38.6029771435, -4.4223518281],
            [-38.5905089745, -4.4230983024],
            [-38.5896788457, -4.4231479625],
            [-38.5834470594, -4.4168968194],
            [-38.5746443225, -4.4184378309],
            [-38.5503615169, -4.3973474501],
            [-38.5333613455, -4.3776464138],
            [-38.5342811547, -4.3701946065],
            [-38.5335318724, -4.3681559358],
            [-38.5241608936, -4.3734084501],
            [-38.521688439, -4.3782825197],
            [-38.5060424892, -4.3789183519],
            [-38.494111777, -4.389071484],
            [-38.4733734052, -4.3915710171],
            [-38.4703340411, -4.3968344003],
            [-38.4613576522, -4.3968404416],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2309508',
        name: 'Orós',
        description: 'Orós',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.9378655926, -6.0786165438],
            [-38.914613629, -6.0865862247],
            [-38.9083959062, -6.0846588637],
            [-38.9040486038, -6.1392539265],
            [-38.878721398, -6.1473741676],
            [-38.8711333162, -6.1399432552],
            [-38.8644367536, -6.1385406337],
            [-38.8613395634, -6.1335575613],
            [-38.8504205531, -6.1375638103],
            [-38.8497810395, -6.1317949314],
            [-38.8467844124, -6.1316247436],
            [-38.8421721095, -6.1351955528],
            [-38.8341403545, -6.1263322724],
            [-38.821475702, -6.123633852],
            [-38.8205395206, -6.1372484454],
            [-38.813075274, -6.1356130419],
            [-38.8041982575, -6.1293030737],
            [-38.8006223025, -6.1213590929],
            [-38.8112245235, -6.1627123283],
            [-38.8248440872, -6.2158821163],
            [-38.831157554, -6.2367142494],
            [-38.8281809509, -6.2418955135],
            [-38.8302487038, -6.2475939815],
            [-38.8255858227, -6.2619274399],
            [-38.8200332617, -6.2683744688],
            [-38.8200682947, -6.2699095221],
            [-38.8367867763, -6.2946409607],
            [-38.8835806412, -6.3285567143],
            [-38.915402852, -6.3094838685],
            [-38.9265034567, -6.3330256061],
            [-38.9357530233, -6.3388186449],
            [-38.9407288186, -6.3354635106],
            [-38.949387322, -6.3385393337],
            [-38.9573402668, -6.3343970525],
            [-38.9732788992, -6.3390439665],
            [-38.9830833607, -6.3499285018],
            [-38.9835550525, -6.3593882311],
            [-38.9804210891, -6.3797272915],
            [-38.9870536965, -6.3776957732],
            [-38.9899989878, -6.3749251716],
            [-38.9987784789, -6.377778321],
            [-39.0134177818, -6.3740511431],
            [-39.0383344168, -6.3751113657],
            [-39.0441659741, -6.3806290669],
            [-39.0584496785, -6.384622022],
            [-39.0820807477, -6.3635071899],
            [-39.0943022968, -6.3784561243],
            [-39.117670479, -6.3901650684],
            [-39.1238079935, -6.4004648674],
            [-39.1295841657, -6.4013961138],
            [-39.130495155, -6.3874270173],
            [-39.1211237388, -6.3828200559],
            [-39.1018176314, -6.3554444968],
            [-39.0979406009, -6.3408275616],
            [-39.0940007814, -6.3346784153],
            [-39.0881047671, -6.3340296927],
            [-39.0824218017, -6.3331155731],
            [-39.0716121705, -6.3111784538],
            [-39.0528353998, -6.2960150896],
            [-39.0563479447, -6.2587018408],
            [-39.0420719389, -6.2482693958],
            [-39.018730507, -6.2312134833],
            [-39.0032817626, -6.2199263777],
            [-38.9913881919, -6.1902630138],
            [-38.9833597203, -6.15867505],
            [-38.9861966805, -6.1565120797],
            [-38.9688395436, -6.122308397],
            [-38.9751713209, -6.1195619247],
            [-38.9709212322, -6.1092631921],
            [-38.9775855489, -6.105586759],
            [-38.9718676898, -6.102631692],
            [-38.9697484382, -6.0938734076],
            [-38.9605295263, -6.0910779168],
            [-38.9493641756, -6.0807523907],
            [-38.9378655926, -6.0786165438],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2309607',
        name: 'Pacajus',
        description: 'Pacajus',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5196741278, -4.1363333344],
            [-38.5001220111, -4.1433829845],
            [-38.4892126251, -4.1400180112],
            [-38.4670670884, -4.1384295139],
            [-38.4588847518, -4.1390263966],
            [-38.4161866586, -4.1421371634],
            [-38.3806494115, -4.1447244969],
            [-38.3806729895, -4.1448958668],
            [-38.3870785933, -4.1872105279],
            [-38.3930745146, -4.2232355639],
            [-38.3931738482, -4.2238401156],
            [-38.3933110426, -4.224663429],
            [-38.4009758157, -4.2263934778],
            [-38.4059899615, -4.2315144749],
            [-38.443048862, -4.2311203634],
            [-38.4516698699, -4.2397527114],
            [-38.4631989066, -4.2447407098],
            [-38.4815845162, -4.2384846803],
            [-38.4949882133, -4.2310864466],
            [-38.5258497339, -4.2260682516],
            [-38.5331118763, -4.2332619302],
            [-38.5339771608, -4.233021198],
            [-38.5543418179, -4.2347956033],
            [-38.5667785188, -4.2322092799],
            [-38.5705033138, -4.2346349704],
            [-38.5741329488, -4.2309133638],
            [-38.580325952, -4.224057637],
            [-38.5820179064, -4.2221847025],
            [-38.597292364, -4.2052701526],
            [-38.6178489933, -4.1818789666],
            [-38.6193523149, -4.1696917337],
            [-38.6194224012, -4.1639959298],
            [-38.6134746389, -4.1520417784],
            [-38.570566519, -4.1283213949],
            [-38.542950528, -4.1281434163],
            [-38.5420828467, -4.1306534968],
            [-38.5293932423, -4.1309232235],
            [-38.5196741278, -4.1363333344],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2309706',
        name: 'Pacatuba',
        description: 'Pacatuba',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5455496279, -3.8762296077],
            [-38.5576578952, -3.9288385089],
            [-38.5639534787, -3.9562147419],
            [-38.564863651, -3.9601733374],
            [-38.5657246075, -3.9656287201],
            [-38.5727022553, -4.0098755558],
            [-38.5743372628, -4.0521765513],
            [-38.6065072653, -4.0107895167],
            [-38.6165621226, -4.0156839698],
            [-38.6245781143, -4.0155402041],
            [-38.6272916376, -4.0229228407],
            [-38.6354192798, -4.023207304],
            [-38.6495922388, -4.0055525543],
            [-38.6555587582, -4.0141055223],
            [-38.6673621686, -4.0180536135],
            [-38.6622054594, -4.0136919496],
            [-38.6694146206, -4.0084406976],
            [-38.668971013, -4.0034102181],
            [-38.6638205259, -4.0010284073],
            [-38.6586963579, -3.9995822662],
            [-38.6576444701, -3.992431596],
            [-38.6514181442, -3.9915899965],
            [-38.6460561091, -3.9860747625],
            [-38.6427557635, -3.9744672284],
            [-38.6380433101, -3.9760096812],
            [-38.63894168, -3.9806390249],
            [-38.6299541846, -3.9738594315],
            [-38.6344563901, -3.9580506815],
            [-38.6214768757, -3.9447807322],
            [-38.6168155987, -3.9341922042],
            [-38.613990304, -3.9261901594],
            [-38.6249825273, -3.8905598945],
            [-38.6204040775, -3.889212402],
            [-38.605393557, -3.8816315794],
            [-38.5967202658, -3.8818015654],
            [-38.5820090927, -3.8766812587],
            [-38.5728070156, -3.8658868788],
            [-38.5502821416, -3.8588317426],
            [-38.5418531974, -3.8599217377],
            [-38.5418062855, -3.8599750159],
            [-38.5455496279, -3.8762296077],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2309805',
        name: 'Pacoti',
        description: 'Pacoti',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.9359993406, -4.1297459493],
            [-38.9358003234, -4.1333362024],
            [-38.9314716553, -4.1330729902],
            [-38.9289339018, -4.1386281217],
            [-38.9441915669, -4.1460902242],
            [-38.9384154308, -4.1516731834],
            [-38.9272629983, -4.1494573554],
            [-38.9237376399, -4.1520330984],
            [-38.9153227288, -4.1437872928],
            [-38.8997303542, -4.1456993381],
            [-38.8990591909, -4.1460732708],
            [-38.8970831558, -4.1462403863],
            [-38.8878850559, -4.1611761799],
            [-38.8822266909, -4.1703636072],
            [-38.8808153061, -4.1726534555],
            [-38.8568786315, -4.1628632704],
            [-38.8559049406, -4.174081051],
            [-38.8556548418, -4.1769568015],
            [-38.8555853497, -4.1772386514],
            [-38.8540248516, -4.1835785397],
            [-38.8440259924, -4.2241942391],
            [-38.8493868455, -4.2297504129],
            [-38.8543727208, -4.2251605491],
            [-38.8561590368, -4.2304317146],
            [-38.8623577519, -4.2325887385],
            [-38.8631904669, -4.2394143144],
            [-38.8721275717, -4.2434946928],
            [-38.8757054964, -4.2545058695],
            [-38.8737964094, -4.2579778087],
            [-38.8782063287, -4.2631291847],
            [-38.8900791147, -4.2607879975],
            [-38.8923916475, -4.2633702484],
            [-38.8889342748, -4.2734304068],
            [-38.8919136042, -4.2779807908],
            [-38.8975182206, -4.2553736965],
            [-38.9019738911, -4.2489715276],
            [-38.9057775194, -4.2495225934],
            [-38.9205025652, -4.2477532132],
            [-38.9300815767, -4.2367025192],
            [-38.9388853559, -4.2362058156],
            [-38.9391066762, -4.2295237692],
            [-38.9358730819, -4.2274819165],
            [-38.9357745829, -4.2174054412],
            [-38.9347681943, -4.2148486805],
            [-38.9352033364, -4.212011235],
            [-38.9401744739, -4.2120117214],
            [-38.9397700534, -4.2070594232],
            [-38.9487994989, -4.2049578655],
            [-38.948758605, -4.1944990014],
            [-38.9431594465, -4.1947862316],
            [-38.9442680265, -4.1823705923],
            [-38.9462565102, -4.1800024748],
            [-38.9516977971, -4.1823050528],
            [-38.9562846278, -4.177888531],
            [-38.9562169063, -4.1711437793],
            [-38.9607431277, -4.1714025362],
            [-38.9616097575, -4.1675103755],
            [-38.9574160398, -4.1551828272],
            [-38.9585483863, -4.1441533543],
            [-38.9543536886, -4.1366262652],
            [-38.9472306013, -4.1363684977],
            [-38.9476627179, -4.1310609197],
            [-38.9408053034, -4.1327227393],
            [-38.9359993406, -4.1297459493],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2309904',
        name: 'Pacujá',
        description: 'Pacujá',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.6556702718, -3.9427661305],
            [-40.6540602846, -3.9547712614],
            [-40.6589672764, -3.9645587422],
            [-40.6581010047, -3.9710592001],
            [-40.6531283395, -3.9797849405],
            [-40.6468953094, -3.9768776444],
            [-40.6547923193, -4.0084930426],
            [-40.6670942265, -4.0254193873],
            [-40.671158153, -4.0279604586],
            [-40.6686097836, -4.0327135239],
            [-40.672259798, -4.0389720556],
            [-40.6795785296, -4.0449884312],
            [-40.6782772388, -4.053202922],
            [-40.6822218559, -4.0571674168],
            [-40.7119200979, -4.0614800247],
            [-40.712887681, -4.056648087],
            [-40.7246189956, -3.9980095753],
            [-40.7289625051, -3.9762818155],
            [-40.729065557, -3.9757602584],
            [-40.7307265078, -3.9674903443],
            [-40.7309563609, -3.9667012506],
            [-40.728424555, -3.967884076],
            [-40.7182122319, -3.9606120327],
            [-40.7123297101, -3.9641070201],
            [-40.7036537657, -3.9639885521],
            [-40.6911786566, -3.9545997912],
            [-40.6844583676, -3.9576673268],
            [-40.6766076712, -3.9540438249],
            [-40.6689306519, -3.9603365152],
            [-40.6653886871, -3.9523180788],
            [-40.6712364715, -3.953064051],
            [-40.6717897598, -3.9497177673],
            [-40.6684489733, -3.9459309803],
            [-40.664260968, -3.9470682149],
            [-40.6653611661, -3.9387590267],
            [-40.6556702718, -3.9427661305],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2310001',
        name: 'Palhano',
        description: 'Palhano',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.9863863847, -4.5835240896],
            [-37.9724161355, -4.6147395605],
            [-37.9763115532, -4.6293935427],
            [-37.9923028737, -4.6409373144],
            [-37.9949390774, -4.6468102417],
            [-37.9936005853, -4.6594415513],
            [-37.983163736, -4.6613106804],
            [-37.9638487508, -4.6563186099],
            [-37.9500323969, -4.6592618809],
            [-37.9513475709, -4.7263782721],
            [-37.9570744092, -4.72653547],
            [-37.9497852911, -4.7385350916],
            [-37.9360767173, -4.7610264444],
            [-37.9359706816, -4.7612007421],
            [-37.9350107574, -4.76277712],
            [-37.8985780328, -4.8200178945],
            [-37.9315721806, -4.8242479751],
            [-38.0431314303, -4.8404275515],
            [-38.0607026788, -4.8111707496],
            [-38.0580047691, -4.7916743937],
            [-38.0479508098, -4.7789656495],
            [-38.0495421711, -4.7731877904],
            [-38.0527353217, -4.7690209555],
            [-38.0587272026, -4.7600772028],
            [-38.0616027628, -4.752346326],
            [-38.0702231957, -4.748825615],
            [-38.0843661432, -4.7319998349],
            [-38.0957012117, -4.7038008753],
            [-38.1229677382, -4.6847596732],
            [-38.144889255, -4.6678806652],
            [-38.1556350602, -4.6534850121],
            [-38.1667255777, -4.6374694901],
            [-38.1836445919, -4.6220994777],
            [-38.1507214513, -4.6138454559],
            [-38.1258890667, -4.6177439718],
            [-38.1074766913, -4.6152738307],
            [-38.105507064, -4.6122699195],
            [-38.1104212189, -4.6046925201],
            [-38.1051193725, -4.5959990393],
            [-38.0955052607, -4.5928503305],
            [-38.0934043842, -4.5921624355],
            [-38.0842526054, -4.5885039104],
            [-38.076001768, -4.592202998],
            [-38.0552497815, -4.5920334614],
            [-38.0547207798, -4.591513527],
            [-38.0413360979, -4.5893236115],
            [-38.0256151221, -4.5984231546],
            [-38.0208172803, -4.593119074],
            [-38.012736707, -4.5921071535],
            [-38.0058435835, -4.5964846251],
            [-38.0003938241, -4.5928897496],
            [-37.9975907361, -4.5952406721],
            [-37.9863863847, -4.5835240896],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2310100',
        name: 'Palmácia',
        description: 'Palmácia',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.7899152573, -4.0911054625],
            [-38.7767478893, -4.0982855427],
            [-38.7710923295, -4.0962682329],
            [-38.7604207079, -4.0724068189],
            [-38.7583669599, -4.074935597],
            [-38.7481564299, -4.0924023049],
            [-38.7481908318, -4.0925793826],
            [-38.7549776513, -4.1278991252],
            [-38.7691635563, -4.1279843162],
            [-38.7717413663, -4.1315526873],
            [-38.7872995745, -4.1297736032],
            [-38.7843026406, -4.1334204602],
            [-38.7903361164, -4.1347188839],
            [-38.7908263032, -4.142828529],
            [-38.7939065728, -4.1433619699],
            [-38.8041646886, -4.1454556533],
            [-38.806409744, -4.1518930236],
            [-38.8034126729, -4.159617571],
            [-38.8131879892, -4.1628615509],
            [-38.8163348565, -4.1639058815],
            [-38.8517614749, -4.1756649819],
            [-38.8556548418, -4.1769568015],
            [-38.8559049406, -4.174081051],
            [-38.8568786315, -4.1628632704],
            [-38.8808153061, -4.1726534555],
            [-38.8822266909, -4.1703636072],
            [-38.8878850559, -4.1611761799],
            [-38.8970831558, -4.1462403863],
            [-38.8990591909, -4.1460732708],
            [-38.8997303542, -4.1456993381],
            [-38.9153227288, -4.1437872928],
            [-38.9237376399, -4.1520330984],
            [-38.9272629983, -4.1494573554],
            [-38.9384154308, -4.1516731834],
            [-38.9441915669, -4.1460902242],
            [-38.9289339018, -4.1386281217],
            [-38.9314716553, -4.1330729902],
            [-38.9358003234, -4.1333362024],
            [-38.9359993406, -4.1297459493],
            [-38.9408053034, -4.1327227393],
            [-38.9476627179, -4.1310609197],
            [-38.9486063839, -4.1277621587],
            [-38.9396478018, -4.1252014158],
            [-38.9438221332, -4.1176152374],
            [-38.9383340727, -4.1093905289],
            [-38.9429788337, -4.1050028206],
            [-38.9416283373, -4.100878862],
            [-38.92552412, -4.1076124201],
            [-38.9219935743, -4.1090881075],
            [-38.919193088, -4.1102589119],
            [-38.9172443756, -4.1110728959],
            [-38.9129591546, -4.1128641554],
            [-38.9081372166, -4.1148797419],
            [-38.8533820436, -4.1377574059],
            [-38.8580132532, -4.1303737342],
            [-38.8550301511, -4.1219920706],
            [-38.8390789863, -4.1020111114],
            [-38.8391292981, -4.1018311505],
            [-38.8413622533, -4.0937934284],
            [-38.8360516043, -4.0849333645],
            [-38.8303633461, -4.0830615942],
            [-38.8348373084, -4.0761642094],
            [-38.8306506392, -4.0688823953],
            [-38.7979671169, -4.0867137206],
            [-38.7964423293, -4.0875454439],
            [-38.7899152573, -4.0911054625],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2310209',
        name: 'Paracuru',
        description: 'Paracuru',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.9945954541, -3.3970749414],
            [-38.9660621217, -3.4191213716],
            [-38.948863473, -3.4426997478],
            [-38.9380835207, -3.4508223064],
            [-38.9337554127, -3.4636859338],
            [-38.9317542833, -3.4649837233],
            [-38.932528769, -3.4709581069],
            [-38.9383051499, -3.4710841312],
            [-38.9389239435, -3.4830328892],
            [-38.9450858027, -3.4893998077],
            [-38.9531877564, -3.4978332551],
            [-38.9538580635, -3.4981251838],
            [-38.9612858142, -3.5090813778],
            [-38.9689915469, -3.5111215043],
            [-38.9687657915, -3.5191901468],
            [-38.9627689188, -3.524966045],
            [-38.9644825425, -3.5319301418],
            [-38.9610133511, -3.5351601992],
            [-38.958938487, -3.5412752193],
            [-38.9615058575, -3.5478017011],
            [-38.9620497719, -3.5483322097],
            [-38.9740435066, -3.5526742949],
            [-38.9877916212, -3.5616391149],
            [-38.996562766, -3.5546209212],
            [-39.0076413441, -3.5513783333],
            [-39.0257814178, -3.5551259668],
            [-39.0375721039, -3.5503957482],
            [-39.0567334226, -3.5457437133],
            [-39.0694218857, -3.544421475],
            [-39.0850087171, -3.5509529067],
            [-39.0899243828, -3.5563824792],
            [-39.0943160332, -3.5465158328],
            [-39.1075501512, -3.5428683227],
            [-39.1080951801, -3.5376060858],
            [-39.0988302423, -3.5324701525],
            [-39.0638559184, -3.5371412609],
            [-39.0489173651, -3.5286157499],
            [-39.0752588482, -3.528542822],
            [-39.1133789301, -3.5284379613],
            [-39.1188771348, -3.5284219201],
            [-39.1331564109, -3.5283820874],
            [-39.1694662659, -3.528279212],
            [-39.1653413212, -3.5212028305],
            [-39.1656884324, -3.5139288169],
            [-39.1642446936, -3.5067295922],
            [-39.1528573641, -3.4936018674],
            [-39.1428668202, -3.485450622],
            [-39.1420637201, -3.4805806654],
            [-39.1392226326, -3.4807777836],
            [-39.1371693617, -3.4825871761],
            [-39.1218664589, -3.4872833621],
            [-39.1206413429, -3.4789376849],
            [-39.1152809869, -3.4758776268],
            [-39.1213342573, -3.4551802217],
            [-39.1182513082, -3.4493173634],
            [-39.1219530496, -3.4398586597],
            [-39.1197243636, -3.4353850566],
            [-39.1071256496, -3.4370961993],
            [-39.1055312804, -3.4324662687],
            [-39.1086994791, -3.4240373534],
            [-39.1032341784, -3.4190473498],
            [-39.103168518, -3.4189877499],
            [-39.0936104971, -3.4155598606],
            [-39.0884451573, -3.4098068192],
            [-39.0829777741, -3.4068275312],
            [-39.0642125597, -3.4128871346],
            [-39.0580755246, -3.409803403],
            [-39.0660951732, -3.4039977231],
            [-39.0661960932, -3.4014750379],
            [-39.0624821981, -3.4012792723],
            [-39.0527787742, -3.4087352632],
            [-39.0395627004, -3.4108700946],
            [-39.0259125185, -3.4027743617],
            [-39.0161091169, -3.4030427823],
            [-39.0076755168, -3.3960741432],
            [-38.9945954541, -3.3970749414],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2310258',
        name: 'Paraipaba',
        description: 'Paraipaba',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.1608509253, -3.3312289241],
            [-39.1390560206, -3.3454936618],
            [-39.1272574755, -3.343238665],
            [-39.1180809608, -3.3455644734],
            [-39.1123668446, -3.3469901762],
            [-39.0910910076, -3.384178478],
            [-39.0792351156, -3.3962688736],
            [-39.0660951732, -3.4039977231],
            [-39.0580755246, -3.409803403],
            [-39.0642125597, -3.4128871346],
            [-39.0829777741, -3.4068275312],
            [-39.0884451573, -3.4098068192],
            [-39.0936104971, -3.4155598606],
            [-39.103168518, -3.4189877499],
            [-39.1032341784, -3.4190473498],
            [-39.1086994791, -3.4240373534],
            [-39.1055312804, -3.4324662687],
            [-39.1071256496, -3.4370961993],
            [-39.1197243636, -3.4353850566],
            [-39.1219530496, -3.4398586597],
            [-39.1182513082, -3.4493173634],
            [-39.1213342573, -3.4551802217],
            [-39.1152809869, -3.4758776268],
            [-39.1206413429, -3.4789376849],
            [-39.1218664589, -3.4872833621],
            [-39.1371693617, -3.4825871761],
            [-39.1392226326, -3.4807777836],
            [-39.1420637201, -3.4805806654],
            [-39.1428668202, -3.485450622],
            [-39.1528573641, -3.4936018674],
            [-39.1642446936, -3.5067295922],
            [-39.1656884324, -3.5139288169],
            [-39.2143336288, -3.5140480864],
            [-39.2839441915, -3.5142139598],
            [-39.282307767, -3.5011539681],
            [-39.2618660416, -3.4897418504],
            [-39.2634177461, -3.4821626327],
            [-39.2561454644, -3.4656879221],
            [-39.2560688133, -3.4654127715],
            [-39.2571840309, -3.4560106232],
            [-39.2528716247, -3.447578897],
            [-39.2554541334, -3.4386864213],
            [-39.2502516081, -3.4044454024],
            [-39.2617696215, -3.3862746817],
            [-39.2629161171, -3.3828417229],
            [-39.261286, -3.3779006389],
            [-39.2501819505, -3.3706755139],
            [-39.2550463649, -3.3598909572],
            [-39.23825382, -3.3592698393],
            [-39.2157278964, -3.3504335967],
            [-39.2072159837, -3.3564253035],
            [-39.2025659554, -3.3545523651],
            [-39.1956936594, -3.3453150039],
            [-39.1806687464, -3.3406527392],
            [-39.1750937921, -3.329537535],
            [-39.1608509253, -3.3312289241],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2310308',
        name: 'Parambu',
        description: 'Parambu',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.4860526678, -5.9822393911],
            [-40.4740559091, -6.0014272072],
            [-40.4755621108, -6.0068911657],
            [-40.4646550353, -6.0154989888],
            [-40.4681811172, -6.0204633158],
            [-40.4717859463, -6.0274266485],
            [-40.4811110164, -6.0331052293],
            [-40.4778297526, -6.0522437912],
            [-40.4858706099, -6.0655206369],
            [-40.4856389082, -6.0734752747],
            [-40.488801571, -6.0775684338],
            [-40.4869280805, -6.0811333449],
            [-40.4942514612, -6.0836476185],
            [-40.4964296462, -6.0875770754],
            [-40.5038694171, -6.1010004852],
            [-40.5259922073, -6.1409198232],
            [-40.5331761461, -6.1609476868],
            [-40.5039024495, -6.1876974935],
            [-40.4886327933, -6.2023649019],
            [-40.4921524186, -6.2164335516],
            [-40.4894826402, -6.2216017446],
            [-40.4952018533, -6.2265950012],
            [-40.4950428412, -6.2433813828],
            [-40.5032311027, -6.2563847155],
            [-40.5072117702, -6.2569173279],
            [-40.5188231011, -6.2698901705],
            [-40.5343703745, -6.276125641],
            [-40.5329848175, -6.2794328299],
            [-40.553863246, -6.2854606831],
            [-40.5553993226, -6.2898432381],
            [-40.5654280913, -6.2928299566],
            [-40.5719704237, -6.2934198864],
            [-40.5776378437, -6.2883983285],
            [-40.5874626699, -6.2941446803],
            [-40.5894499209, -6.3006753071],
            [-40.5896017251, -6.3142186693],
            [-40.6087029919, -6.3377981608],
            [-40.6042078901, -6.348489482],
            [-40.5919730047, -6.3404646965],
            [-40.5817539721, -6.340496145],
            [-40.5748239873, -6.3607839141],
            [-40.5661677629, -6.350927106],
            [-40.5535081691, -6.3473345243],
            [-40.5537604274, -6.3422316663],
            [-40.542976376, -6.3399651274],
            [-40.5324816335, -6.3479613408],
            [-40.5182887664, -6.3467647336],
            [-40.5110601401, -6.339465232],
            [-40.507467812, -6.3433690156],
            [-40.4987609965, -6.3434784314],
            [-40.4817347909, -6.3374766122],
            [-40.4788469481, -6.3321275294],
            [-40.471145602, -6.3301253225],
            [-40.4694702741, -6.3344906583],
            [-40.4615224139, -6.3330567841],
            [-40.4558155961, -6.3364666717],
            [-40.4464621661, -6.3317212547],
            [-40.4402833315, -6.3324786496],
            [-40.4391002139, -6.3324996225],
            [-40.4324913515, -6.3334818019],
            [-40.42968368, -6.3248953644],
            [-40.4255941355, -6.3246031413],
            [-40.4221412587, -6.3195065053],
            [-40.4088047806, -6.3169053414],
            [-40.4050064359, -6.3126661218],
            [-40.3278469297, -6.4010323297],
            [-40.3361131221, -6.408282983],
            [-40.3348225494, -6.4149897929],
            [-40.3539706458, -6.4100991984],
            [-40.3532369973, -6.4168834106],
            [-40.3503151673, -6.4172898188],
            [-40.3517515932, -6.4211724563],
            [-40.3665772742, -6.42843145],
            [-40.3674222052, -6.4334489387],
            [-40.3721788652, -6.43534343],
            [-40.3691321813, -6.4504448232],
            [-40.3826165118, -6.4630660646],
            [-40.3878102482, -6.4652531637],
            [-40.3911512521, -6.4743292198],
            [-40.4395927764, -6.4881457972],
            [-40.4469051474, -6.4947713878],
            [-40.4486572646, -6.514776104],
            [-40.459452639, -6.5279090122],
            [-40.4825999927, -6.5278616369],
            [-40.5279781806, -6.5382314707],
            [-40.5418044648, -6.5486005046],
            [-40.5551244233, -6.5510433094],
            [-40.5630241396, -6.5445740341],
            [-40.5746004944, -6.5466424799],
            [-40.5897688197, -6.5622017258],
            [-40.5880727503, -6.5734846873],
            [-40.5965218723, -6.5839318468],
            [-40.5975439611, -6.594027717],
            [-40.6097004041, -6.6028009473],
            [-40.6107695719, -6.6081896385],
            [-40.6213982594, -6.60407221],
            [-40.6368757696, -6.6043763564],
            [-40.6446271055, -6.6062047554],
            [-40.649929754, -6.6134302745],
            [-40.6667237672, -6.6124084521],
            [-40.6709449997, -6.613889275],
            [-40.6798923909, -6.6016400901],
            [-40.7360481949, -6.5998118628],
            [-40.7398544641, -6.577098313],
            [-40.7454807007, -6.5651491149],
            [-40.787296732, -6.5233820735],
            [-40.7917506224, -6.5124638421],
            [-40.7895678368, -6.5002342547],
            [-40.7962138504, -6.4703043803],
            [-40.7934040558, -6.45979268],
            [-40.7960146637, -6.4263608514],
            [-40.8047829777, -6.3927352617],
            [-40.7997164276, -6.3813059877],
            [-40.7899755454, -6.3714931411],
            [-40.7789433427, -6.3428919477],
            [-40.7797636953, -6.3272768715],
            [-40.7816383613, -6.3161670585],
            [-40.787141306, -6.3038609742],
            [-40.8038095521, -6.2924743489],
            [-40.8101328846, -6.2866678635],
            [-40.8201908436, -6.2628031145],
            [-40.833737191, -6.2440312087],
            [-40.8518521574, -6.2238254386],
            [-40.8468580293, -6.2026186879],
            [-40.852472659, -6.1746663858],
            [-40.8499699593, -6.1521622081],
            [-40.8453666598, -6.1346006195],
            [-40.8573409682, -6.1049054063],
            [-40.8073741803, -6.1047276609],
            [-40.8106163785, -6.0957612882],
            [-40.8074147996, -6.0919042538],
            [-40.8004470028, -6.0966907894],
            [-40.7860022555, -6.0903290132],
            [-40.7714182083, -6.0910575531],
            [-40.7636936817, -6.0894675361],
            [-40.7584482145, -6.0929379608],
            [-40.7467307435, -6.0899975747],
            [-40.7471100879, -6.0823237008],
            [-40.7361156877, -6.0768098579],
            [-40.7326127436, -6.0693942729],
            [-40.7377231787, -6.0629807313],
            [-40.7370903638, -6.0580115845],
            [-40.731304856, -6.0518563944],
            [-40.7233993573, -6.0520768896],
            [-40.7172820506, -6.0459687423],
            [-40.7154269526, -6.0401472423],
            [-40.7031710841, -6.0335561045],
            [-40.7015551916, -6.0250785566],
            [-40.6950547399, -6.0181289404],
            [-40.6758985219, -6.0091818872],
            [-40.6372158256, -6.0022200493],
            [-40.6336941913, -5.9976580893],
            [-40.6174206149, -5.9933568073],
            [-40.6142863954, -5.9894604605],
            [-40.5983771909, -5.9940018037],
            [-40.5974170655, -6.0030380115],
            [-40.5941189682, -6.0024697756],
            [-40.5882938943, -5.9925271601],
            [-40.5781570163, -5.9982174038],
            [-40.564368106, -5.9990416121],
            [-40.562525703, -6.0034675289],
            [-40.5528836711, -6.0084154206],
            [-40.5516629059, -6.0037914338],
            [-40.5419488413, -6.0046778649],
            [-40.5363204459, -6.0003588179],
            [-40.5356871968, -5.9953636094],
            [-40.5259091819, -5.9953083384],
            [-40.5169713926, -5.9871551948],
            [-40.5068336141, -5.9912688919],
            [-40.4980144872, -5.990879785],
            [-40.491885796, -5.9817928809],
            [-40.4860526678, -5.9822393911],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2310407',
        name: 'Paramoti',
        description: 'Paramoti',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.2036410429, -4.0528878982],
            [-39.1950880353, -4.0589850967],
            [-39.1896103156, -4.0576108278],
            [-39.181279559, -4.0672740392],
            [-39.1729453444, -4.0910066313],
            [-39.1959509907, -4.09226887],
            [-39.2071594222, -4.1340460443],
            [-39.2580513726, -4.13425165],
            [-39.2629880778, -4.1359506341],
            [-39.2912289305, -4.1456424914],
            [-39.3094936496, -4.172011886],
            [-39.3096006221, -4.1721661892],
            [-39.3164716737, -4.1736664546],
            [-39.3190088599, -4.1833616604],
            [-39.3352532758, -4.1841550081],
            [-39.338727985, -4.1908747658],
            [-39.334564485, -4.193224881],
            [-39.3702048221, -4.2150524592],
            [-39.3947764553, -4.2301048427],
            [-39.396399787, -4.2310989833],
            [-39.4335179821, -4.2538431244],
            [-39.4342009755, -4.2542612991],
            [-39.4547733684, -4.2668685126],
            [-39.491508456, -4.2893856055],
            [-39.494874134, -4.2863181212],
            [-39.498416754, -4.283088155],
            [-39.4991452208, -4.2737777833],
            [-39.5055592852, -4.266673041],
            [-39.5066919051, -4.242887206],
            [-39.5144944067, -4.2385024814],
            [-39.5306154176, -4.2378374116],
            [-39.5562639016, -4.2182360294],
            [-39.5663521755, -4.2232937705],
            [-39.5698887975, -4.2181495372],
            [-39.562998349, -4.2055871222],
            [-39.5633509441, -4.2011087431],
            [-39.5575344065, -4.1871577202],
            [-39.5591764926, -4.1820143786],
            [-39.5590812934, -4.1804567317],
            [-39.531335689, -4.1525376794],
            [-39.5316191521, -4.1392631482],
            [-39.5232583966, -4.1349166212],
            [-39.5216719622, -4.1286990546],
            [-39.5254222559, -4.1222007833],
            [-39.5144497653, -4.1183092234],
            [-39.5175838179, -4.1144390775],
            [-39.4361015032, -4.1008177536],
            [-39.4068756235, -4.0953274159],
            [-39.3993302707, -4.1045966739],
            [-39.3868036088, -4.1047334963],
            [-39.3717071809, -4.110091975],
            [-39.3585519057, -4.096712478],
            [-39.3668225048, -4.0887041559],
            [-39.3415265991, -4.0890203935],
            [-39.3245330901, -4.0891259924],
            [-39.3232101614, -4.0891121306],
            [-39.305507209, -4.0836304908],
            [-39.2936080125, -4.0834156252],
            [-39.270503598, -4.0847041314],
            [-39.2586955493, -4.0774237493],
            [-39.2418071252, -4.0745128869],
            [-39.2379788683, -4.0779042349],
            [-39.2273132392, -4.0721637864],
            [-39.2256474084, -4.0658992304],
            [-39.2201911406, -4.0629820424],
            [-39.2145303818, -4.0509531942],
            [-39.2033611031, -4.0465724965],
            [-39.2036410429, -4.0528878982],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2310506',
        name: 'Pedra Branca',
        description: 'Pedra Branca',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.0205332514, -5.3275777954],
            [-40.0151075314, -5.3404532179],
            [-40.0032691773, -5.3387068644],
            [-40.000502255, -5.3468555994],
            [-39.9926596184, -5.3486623515],
            [-39.9352822653, -5.3348731554],
            [-39.9299916889, -5.3378465229],
            [-39.9294383273, -5.3431677766],
            [-39.9332803502, -5.3481540084],
            [-39.9424181615, -5.35049661],
            [-39.9434422281, -5.355812891],
            [-39.9335549488, -5.3647002667],
            [-39.9319102844, -5.378909098],
            [-39.9252118473, -5.3811843041],
            [-39.9211207162, -5.3897222726],
            [-39.9128843046, -5.3857309219],
            [-39.9068111548, -5.3923386014],
            [-39.8972091065, -5.3970182253],
            [-39.8934628175, -5.3958653335],
            [-39.8755369744, -5.3736356462],
            [-39.8496280351, -5.3716127308],
            [-39.8450521668, -5.3673674835],
            [-39.8379833024, -5.3723750794],
            [-39.8361368189, -5.3698081856],
            [-39.8273353837, -5.3755645821],
            [-39.8246718311, -5.3816152349],
            [-39.8185252063, -5.3827078871],
            [-39.8163462059, -5.3933603806],
            [-39.8073276672, -5.3825224876],
            [-39.8038466312, -5.3932678298],
            [-39.7994243533, -5.399651598],
            [-39.7821620706, -5.3790213346],
            [-39.7706782786, -5.3765228704],
            [-39.7662383422, -5.3702605172],
            [-39.7601542611, -5.3697438502],
            [-39.7551393564, -5.3668769148],
            [-39.7475159505, -5.3684049585],
            [-39.7462550983, -5.3734313241],
            [-39.7332422229, -5.3683660567],
            [-39.7278872362, -5.3751136445],
            [-39.7266007305, -5.3792694793],
            [-39.7210166426, -5.3797366164],
            [-39.7201848214, -5.3766900168],
            [-39.7094818611, -5.3720709323],
            [-39.7046142497, -5.3603188776],
            [-39.6962908337, -5.3627444143],
            [-39.6915091787, -5.3592261271],
            [-39.6882257695, -5.3578674128],
            [-39.682209075, -5.3633660789],
            [-39.6688427922, -5.362440538],
            [-39.6677821278, -5.3623752091],
            [-39.6602579948, -5.3657135348],
            [-39.6618428347, -5.371688602],
            [-39.6577183202, -5.3745277205],
            [-39.6638253773, -5.3794463808],
            [-39.6627683945, -5.387640992],
            [-39.670736394, -5.3840704325],
            [-39.6734638063, -5.3865615279],
            [-39.6663287309, -5.3964262048],
            [-39.6694500279, -5.4058200143],
            [-39.6695005313, -5.4059720424],
            [-39.657916983, -5.429372141],
            [-39.6568622111, -5.4390733256],
            [-39.6492840609, -5.4408228449],
            [-39.6430151354, -5.4466500684],
            [-39.6454268156, -5.4523138401],
            [-39.6362842076, -5.4642730044],
            [-39.6368844069, -5.4731003855],
            [-39.6285611347, -5.4802738944],
            [-39.6216544281, -5.4804007353],
            [-39.6044863676, -5.4893715735],
            [-39.601588663, -5.4978433618],
            [-39.596100398, -5.502102094],
            [-39.5807285197, -5.5040357471],
            [-39.5743537051, -5.5139249752],
            [-39.5703455745, -5.5149144977],
            [-39.5591354335, -5.5184270642],
            [-39.5521776249, -5.5284799063],
            [-39.5611378896, -5.531997627],
            [-39.5756647145, -5.5268609774],
            [-39.5803847202, -5.5359703736],
            [-39.5843733804, -5.5357040496],
            [-39.5831826965, -5.5498057213],
            [-39.5863417721, -5.5587447263],
            [-39.6008029065, -5.585780702],
            [-39.6250789406, -5.6078083228],
            [-39.6319524347, -5.6058232362],
            [-39.6544294552, -5.6123732159],
            [-39.6617529424, -5.6091054435],
            [-39.6742460591, -5.6205120969],
            [-39.6781712007, -5.6211528755],
            [-39.68739203, -5.6133795204],
            [-39.6934965261, -5.6113066907],
            [-39.6926628461, -5.6037669173],
            [-39.6981071801, -5.601037691],
            [-39.7308941071, -5.595839794],
            [-39.7376413645, -5.5977275784],
            [-39.7438979124, -5.5939749102],
            [-39.7447064373, -5.5813725709],
            [-39.7500861938, -5.5751856336],
            [-39.7610096604, -5.5815566812],
            [-39.7692149292, -5.5827516052],
            [-39.7719914954, -5.5902883732],
            [-39.7747106321, -5.590480441],
            [-39.8022061408, -5.5763842022],
            [-39.8043826416, -5.5712726638],
            [-39.8102535939, -5.5716048001],
            [-39.8158776963, -5.5671203554],
            [-39.8233042295, -5.5688148813],
            [-39.8311847693, -5.5821338515],
            [-39.8411584163, -5.600167368],
            [-39.8475216221, -5.6032230814],
            [-39.8453466661, -5.6095021006],
            [-39.8492329435, -5.6126087488],
            [-39.8508530313, -5.6252062314],
            [-39.8545938124, -5.6284616202],
            [-39.8594429707, -5.6260202644],
            [-39.8668934146, -5.6299512329],
            [-39.8757195085, -5.6384027856],
            [-39.8769656803, -5.6276983367],
            [-39.8934197691, -5.6294730831],
            [-39.9027459733, -5.6360745813],
            [-39.9112987273, -5.6408292318],
            [-39.9179754955, -5.6365096204],
            [-39.9252180317, -5.6352114193],
            [-39.9233149774, -5.6291332275],
            [-39.928202465, -5.6211004669],
            [-39.9381601443, -5.6269712585],
            [-39.9317166514, -5.6330622494],
            [-39.9396257409, -5.6327084323],
            [-39.9413289464, -5.6367431857],
            [-39.9519628834, -5.6393047692],
            [-39.957728859, -5.6331174369],
            [-39.9548071716, -5.6280624636],
            [-39.9580457979, -5.6190563209],
            [-39.9629443325, -5.6179305664],
            [-39.9682584184, -5.6322729713],
            [-39.9732536105, -5.6305611501],
            [-39.9815111672, -5.6343420359],
            [-39.9876892228, -5.6312602289],
            [-39.9903876164, -5.6336772289],
            [-40.0109348719, -5.6255727768],
            [-40.0223184825, -5.6352067096],
            [-40.0193575508, -5.6386234183],
            [-40.0215365368, -5.642828539],
            [-40.0341317594, -5.6407750471],
            [-40.0328359488, -5.6444064694],
            [-40.0368229416, -5.6472299438],
            [-40.0494695706, -5.6739182034],
            [-40.0426036206, -5.6806811874],
            [-40.0359062999, -5.7016675737],
            [-40.0214708139, -5.7104749114],
            [-40.0440452949, -5.7105974577],
            [-40.047427645, -5.6956237573],
            [-40.053682316, -5.6928134812],
            [-40.0552903086, -5.6874206425],
            [-40.0649631996, -5.6883742488],
            [-40.0662330282, -5.6764677566],
            [-40.0711676808, -5.6742205617],
            [-40.0732435332, -5.6683522211],
            [-40.0533826217, -5.6404100487],
            [-40.0633391104, -5.6364727475],
            [-40.0498765125, -5.619732948],
            [-40.0512986858, -5.6098600299],
            [-40.0589669704, -5.6078718534],
            [-40.0550667853, -5.5971524569],
            [-40.0642527561, -5.5893885499],
            [-40.0755673897, -5.58922233],
            [-40.0776427859, -5.5831186195],
            [-40.0532335389, -5.5738506669],
            [-40.0447272134, -5.5655265762],
            [-40.0345263137, -5.5647499247],
            [-40.0143451293, -5.533877578],
            [-40.0180682572, -5.5288916142],
            [-40.0133211846, -5.5194409248],
            [-40.0208631714, -5.5132110866],
            [-40.0250968377, -5.5144988633],
            [-40.0254665559, -5.5084581792],
            [-40.0307260547, -5.5074192936],
            [-40.0297646279, -5.4958128761],
            [-40.0256411777, -5.4899256658],
            [-40.0323324971, -5.485139631],
            [-40.0386019296, -5.4906389636],
            [-40.043632886, -5.4888344191],
            [-40.053580929, -5.4875497216],
            [-40.0470519578, -5.4669080706],
            [-40.0495124634, -5.462895092],
            [-40.0554165876, -5.4620891081],
            [-40.0570321824, -5.4384955025],
            [-40.0563674042, -5.4338610726],
            [-40.0649166839, -5.433050357],
            [-40.0603538921, -5.4297877912],
            [-40.0611130599, -5.4265461447],
            [-40.0677209457, -5.4251495373],
            [-40.061098255, -5.4177652701],
            [-40.0612355591, -5.4123132316],
            [-40.0653192707, -5.4116304121],
            [-40.0583072461, -5.3964660694],
            [-40.0610865729, -5.3895028851],
            [-40.056420488, -5.3699556732],
            [-40.0516471377, -5.366106347],
            [-40.0556693713, -5.3638497564],
            [-40.0550060348, -5.3578442221],
            [-40.0385021466, -5.3534632485],
            [-40.0271121258, -5.3458636901],
            [-40.0281730951, -5.3415423329],
            [-40.023166357, -5.3372459216],
            [-40.0205332514, -5.3275777954],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2310605',
        name: 'Penaforte',
        description: 'Penaforte',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.9703411707, -7.7755248687],
            [-38.9566072685, -7.8106566194],
            [-38.9574713338, -7.8163203085],
            [-38.9690291307, -7.8277076726],
            [-38.9692535492, -7.8349973276],
            [-38.9648311487, -7.8372772297],
            [-38.9658448587, -7.8452249392],
            [-38.9742817203, -7.8425657662],
            [-38.9818873426, -7.8457169084],
            [-39.0010512122, -7.8397174301],
            [-39.0042305016, -7.8355273397],
            [-39.0133338658, -7.8124645364],
            [-39.0176843936, -7.8122575914],
            [-39.0260355063, -7.8242265592],
            [-39.0533978111, -7.8188961892],
            [-39.0645218487, -7.8390956407],
            [-39.0909395629, -7.8577737606],
            [-39.0950776495, -7.8528554744],
            [-39.0954090062, -7.846027464],
            [-39.0874590738, -7.835763194],
            [-39.0913623891, -7.824404495],
            [-39.0901969428, -7.8091962544],
            [-39.1015575849, -7.786042392],
            [-39.0999759354, -7.7752996456],
            [-39.1022588882, -7.7706302046],
            [-39.1059956242, -7.7598183265],
            [-39.1234949049, -7.742838206],
            [-39.1335943967, -7.7248702158],
            [-39.1433541232, -7.7181393933],
            [-39.1461904306, -7.717858246],
            [-39.1300811234, -7.7059362127],
            [-39.128645452, -7.6976416873],
            [-39.1228052434, -7.6944668941],
            [-39.1172037339, -7.6840577275],
            [-39.1171450591, -7.6839480681],
            [-39.0829860107, -7.726696293],
            [-39.0607858854, -7.7544597682],
            [-39.0534394921, -7.7636443946],
            [-39.0203438037, -7.7728272787],
            [-39.0084986942, -7.7761116655],
            [-38.9703411707, -7.7755248687],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2310704',
        name: 'Pentecoste',
        description: 'Pentecoste',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.0009490011, -3.6858881807],
            [-38.9609039882, -3.711166074],
            [-38.9602152946, -3.7191673255],
            [-38.9534291493, -3.7217835832],
            [-38.9568818768, -3.7521099617],
            [-38.9573657846, -3.7687717487],
            [-38.9727748047, -3.77369609],
            [-38.9809676785, -3.7834556202],
            [-38.9754442727, -3.7950062704],
            [-38.9810580148, -3.8053028219],
            [-38.9771168785, -3.8140312014],
            [-38.9813945993, -3.8188068058],
            [-38.9833630561, -3.8171797605],
            [-38.9870363271, -3.8219181696],
            [-38.9935575223, -3.8256514137],
            [-38.9986489798, -3.8355383542],
            [-38.9977775731, -3.8484763446],
            [-38.983506199, -3.8625653161],
            [-38.9871453877, -3.8685026875],
            [-38.9765036057, -3.8888766497],
            [-38.9788930896, -3.894120322],
            [-38.9682743206, -3.8970378703],
            [-38.9616337442, -3.9056579043],
            [-38.9581347059, -3.9145226545],
            [-38.9623063125, -3.9225408241],
            [-38.9429712988, -3.9449079807],
            [-38.9618432841, -3.9594507382],
            [-38.987949027, -3.9795706192],
            [-39.0047860019, -3.9925501357],
            [-39.0288031407, -4.0627947353],
            [-39.0633435966, -4.0666776965],
            [-39.1145935913, -4.0593235307],
            [-39.2033611031, -4.0465724965],
            [-39.1970037924, -4.0339006397],
            [-39.1931446779, -4.0326684623],
            [-39.1923940631, -4.0123394456],
            [-39.1854148878, -4.0028437985],
            [-39.1901980297, -3.9912160603],
            [-39.1837166869, -3.9760238597],
            [-39.1922854123, -3.9708686549],
            [-39.1934503138, -3.9640178277],
            [-39.1873939955, -3.9557823966],
            [-39.1853650771, -3.9450003404],
            [-39.184843358, -3.9370020714],
            [-39.1875707247, -3.9347334524],
            [-39.1935314335, -3.9280990508],
            [-39.1925014633, -3.9212829474],
            [-39.2025856345, -3.9139712751],
            [-39.2009775644, -3.9101899502],
            [-39.2073655395, -3.9094590686],
            [-39.2148903553, -3.9017095665],
            [-39.2120914336, -3.8900104748],
            [-39.2071657717, -3.8777609445],
            [-39.2177434836, -3.8660542787],
            [-39.2404638719, -3.8875762898],
            [-39.2703341289, -3.9158774666],
            [-39.2812854193, -3.9154829987],
            [-39.2881932874, -3.9215938707],
            [-39.2951180001, -3.9194522471],
            [-39.2976167337, -3.9224429341],
            [-39.3088344638, -3.9352073656],
            [-39.3182196493, -3.9122690152],
            [-39.3376427911, -3.864766997],
            [-39.3492312874, -3.8364070593],
            [-39.3586672778, -3.8560164117],
            [-39.3754754295, -3.862457181],
            [-39.3825587263, -3.87648391],
            [-39.3894472836, -3.8791133501],
            [-39.3909002924, -3.8828796823],
            [-39.3990588411, -3.8797993033],
            [-39.4045869107, -3.8697762592],
            [-39.4154231135, -3.8710769914],
            [-39.4215347146, -3.8613246061],
            [-39.4284226723, -3.8649795856],
            [-39.4345859921, -3.8624733818],
            [-39.4290151605, -3.854069314],
            [-39.4155985689, -3.832049122],
            [-39.4172902831, -3.8248210661],
            [-39.4239716157, -3.8255446428],
            [-39.4261518506, -3.8229192052],
            [-39.4295898492, -3.7962945871],
            [-39.4361998839, -3.7911253245],
            [-39.4321140459, -3.7836311814],
            [-39.4233935058, -3.7859670739],
            [-39.3987771597, -3.7776266092],
            [-39.3962577555, -3.7637991502],
            [-39.3798664781, -3.765072843],
            [-39.3720277783, -3.7691411833],
            [-39.3649829022, -3.7672639979],
            [-39.3627841338, -3.7509390017],
            [-39.355551039, -3.74180351],
            [-39.3510743224, -3.7382602273],
            [-39.3521130182, -3.7341338393],
            [-39.3478310605, -3.7339191019],
            [-39.3385939719, -3.7440283741],
            [-39.3219218087, -3.7326636673],
            [-39.3101975846, -3.7338340995],
            [-39.2968131888, -3.7384383328],
            [-39.2764101429, -3.7365083723],
            [-39.2425676877, -3.7129543369],
            [-39.2139112601, -3.693015219],
            [-39.1842805135, -3.6976501433],
            [-39.1559718283, -3.7020760327],
            [-39.1435902704, -3.7040113049],
            [-39.0024376734, -3.6857542445],
            [-39.0009490011, -3.6858881807],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2310803',
        name: 'Pereiro',
        description: 'Pereiro',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.4639668971, -5.8043213684],
            [-38.4663037053, -5.8247283549],
            [-38.4826952472, -5.883645371],
            [-38.486700328, -5.8881793824],
            [-38.4750431516, -5.8881718366],
            [-38.4744006996, -5.8771140618],
            [-38.4631196718, -5.8734771025],
            [-38.4594901092, -5.8664056492],
            [-38.4491320374, -5.8787144658],
            [-38.442693848, -5.8763583336],
            [-38.4341308807, -5.8853920554],
            [-38.4193776012, -5.8795959141],
            [-38.4170383409, -5.8864813412],
            [-38.4191672131, -5.9018900646],
            [-38.4138472597, -5.9047825222],
            [-38.4112516724, -5.8977789531],
            [-38.4082124109, -5.9187716266],
            [-38.403049832, -5.9543951636],
            [-38.4000684351, -5.9749475162],
            [-38.3972000625, -5.9947051106],
            [-38.4077088125, -5.9987077271],
            [-38.4166575625, -6.0054570496],
            [-38.4166549402, -6.0088963595],
            [-38.4210784121, -6.0082964158],
            [-38.424048962, -6.0119423365],
            [-38.4110450793, -6.0421606053],
            [-38.4045270946, -6.0568940832],
            [-38.412836069, -6.0579254319],
            [-38.4270850177, -6.0689811529],
            [-38.4308985619, -6.07509721],
            [-38.4469652743, -6.0848821392],
            [-38.4404199179, -6.0964786462],
            [-38.4573193484, -6.1076237178],
            [-38.4730731983, -6.135607269],
            [-38.4760639502, -6.1440170271],
            [-38.4717141748, -6.1483531385],
            [-38.4725835145, -6.1515822291],
            [-38.4907369431, -6.1701122284],
            [-38.5004065066, -6.1792861694],
            [-38.5009734292, -6.1905506983],
            [-38.5124248508, -6.1916265702],
            [-38.5268518674, -6.1929816052],
            [-38.5255434298, -6.1978438374],
            [-38.5289338055, -6.2133438644],
            [-38.51935974, -6.2226409471],
            [-38.5478174709, -6.2318109456],
            [-38.5518139428, -6.233203007],
            [-38.5606811193, -6.2293151953],
            [-38.5711953714, -6.2144621891],
            [-38.5710231089, -6.2076284983],
            [-38.5791032805, -6.2072139902],
            [-38.5884205537, -6.2007967397],
            [-38.5925815268, -6.1895319617],
            [-38.59965482, -6.1843762063],
            [-38.6011197496, -6.1762671303],
            [-38.5877136144, -6.1662321911],
            [-38.5788030657, -6.1458561126],
            [-38.5737465931, -6.1374676334],
            [-38.5840131266, -6.1439448274],
            [-38.5658990727, -6.1150270435],
            [-38.5660274726, -6.1311306507],
            [-38.5579390473, -6.1180523553],
            [-38.5571980338, -6.0975400311],
            [-38.5610192919, -6.1022224217],
            [-38.5618069178, -6.0934138602],
            [-38.5608893883, -6.0895609252],
            [-38.5571043191, -6.0893201615],
            [-38.5592618932, -6.0868059538],
            [-38.5547204715, -6.0789889417],
            [-38.544324108, -6.0741301084],
            [-38.5407971075, -6.0551333602],
            [-38.5442001382, -6.0526585594],
            [-38.5363443356, -6.0471366538],
            [-38.5412282494, -6.0471512151],
            [-38.5371998459, -6.0377896313],
            [-38.5278801098, -6.000756288],
            [-38.5240446868, -6.0112122045],
            [-38.5253166967, -6.0169943039],
            [-38.5277980148, -6.0132815027],
            [-38.5280042886, -6.021538999],
            [-38.5241077117, -6.0219490964],
            [-38.5302631627, -6.0304865425],
            [-38.5258745014, -6.0274558921],
            [-38.5308908674, -6.0419123343],
            [-38.5294052214, -6.0386189437],
            [-38.5112203442, -6.0154956125],
            [-38.5057684804, -5.9996909811],
            [-38.4815224901, -5.9576610704],
            [-38.4752248894, -5.9509114493],
            [-38.4554696716, -5.9464882341],
            [-38.4500978502, -5.9358849255],
            [-38.4577342516, -5.9377970897],
            [-38.4494755228, -5.9190912495],
            [-38.4571867259, -5.9283210669],
            [-38.4568399427, -5.9218532818],
            [-38.4615415043, -5.9300830213],
            [-38.4741428589, -5.9375278693],
            [-38.4875810192, -5.9573621661],
            [-38.4828441905, -5.9478585041],
            [-38.4860325314, -5.9478620387],
            [-38.5048384887, -5.9842163178],
            [-38.5128384467, -5.9894523448],
            [-38.5147600007, -5.9800950956],
            [-38.5084609404, -5.9749973055],
            [-38.5082741607, -5.9695587386],
            [-38.5028627497, -5.9635969045],
            [-38.5036481366, -5.9565779926],
            [-38.497914712, -5.9528570611],
            [-38.4950356341, -5.9251891943],
            [-38.4912881146, -5.9181695515],
            [-38.4864036201, -5.9165096155],
            [-38.4944063013, -5.9172062958],
            [-38.4996369974, -5.9282215798],
            [-38.49823125, -5.9156947236],
            [-38.5041096575, -5.9169397621],
            [-38.5044268287, -5.9013989817],
            [-38.5044460421, -5.894146364],
            [-38.5128440463, -5.8901060811],
            [-38.5127834303, -5.8771714389],
            [-38.4955881215, -5.8627048799],
            [-38.4897686708, -5.8754976266],
            [-38.4820817008, -5.8419082642],
            [-38.4848494619, -5.8329640564],
            [-38.4768669465, -5.8250091279],
            [-38.476869139, -5.8106576912],
            [-38.4818968928, -5.8114873738],
            [-38.4883456773, -5.8055761348],
            [-38.4809940746, -5.7978709832],
            [-38.4868310532, -5.7972485779],
            [-38.4853140861, -5.7896087904],
            [-38.4769955325, -5.7804222352],
            [-38.4625542334, -5.7803536487],
            [-38.4619353106, -5.7919860757],
            [-38.467755893, -5.8020716971],
            [-38.4639668971, -5.8043213684],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2310852',
        name: 'Pindoretama',
        description: 'Pindoretama',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.2625830035, -4.0555291926],
            [-38.2641493261, -4.0731145585],
            [-38.2810319202, -4.0654123597],
            [-38.2933799341, -4.0624221133],
            [-38.2887419926, -4.08019009],
            [-38.2846425089, -4.1016145372],
            [-38.2922840535, -4.0950275939],
            [-38.3002132426, -4.0939591459],
            [-38.3034606378, -4.0934112896],
            [-38.3071726506, -4.0928268651],
            [-38.3272027266, -4.0912117901],
            [-38.3498175237, -4.0874849603],
            [-38.3722351123, -4.0786030094],
            [-38.3702021135, -4.0645711315],
            [-38.3473404415, -4.0446202452],
            [-38.347247462, -4.0445391683],
            [-38.3414438067, -4.0394751719],
            [-38.3197509311, -4.0205487326],
            [-38.3159873455, -4.0172654878],
            [-38.309434226, -4.0115489925],
            [-38.2930181092, -4.0200770886],
            [-38.2920805002, -4.0206279758],
            [-38.2540183707, -4.028005887],
            [-38.2572730646, -4.0382967334],
            [-38.2592058235, -4.04440931],
            [-38.2625830035, -4.0555291926],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2310902',
        name: 'Piquet Carneiro',
        description: 'Piquet Carneiro',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.4566632068, -5.7304879087],
            [-39.4513879821, -5.737549881],
            [-39.4335672394, -5.7276811486],
            [-39.3857561019, -5.7533318399],
            [-39.3503668114, -5.772306377],
            [-39.3266395195, -5.7850222481],
            [-39.3295364796, -5.7873363629],
            [-39.3274914806, -5.7912496973],
            [-39.3412346049, -5.8077116551],
            [-39.343851349, -5.8155327422],
            [-39.3416936367, -5.8188738981],
            [-39.3433151419, -5.8293349126],
            [-39.343440366, -5.832344563],
            [-39.3243740446, -5.8357211838],
            [-39.3236726404, -5.8441607221],
            [-39.3301552322, -5.8474965813],
            [-39.342461107, -5.8508765229],
            [-39.3511177799, -5.8622607242],
            [-39.3548962171, -5.8626718101],
            [-39.3493201044, -5.8650763494],
            [-39.3491713071, -5.8782299192],
            [-39.3573447867, -5.8815405654],
            [-39.3570400907, -5.896774843],
            [-39.3683503042, -5.9067527248],
            [-39.3754095105, -5.9103522618],
            [-39.3654629739, -5.9139254487],
            [-39.3593497033, -5.9201744688],
            [-39.3602678795, -5.9242928156],
            [-39.3565260019, -5.9255313588],
            [-39.3672403193, -5.9464539821],
            [-39.3626177358, -5.9522893747],
            [-39.35175133, -5.9521712553],
            [-39.3635315864, -5.9807086555],
            [-39.3818062148, -5.9735065337],
            [-39.406787431, -5.9757661745],
            [-39.4129433009, -5.9763232112],
            [-39.4142231741, -5.9859832333],
            [-39.4216237096, -5.9886932517],
            [-39.4480018644, -5.9799433754],
            [-39.465364425, -5.9807210791],
            [-39.4789433277, -5.987422416],
            [-39.4868360897, -5.9833138357],
            [-39.4919444847, -5.9857317448],
            [-39.4956641464, -5.9838239953],
            [-39.5000022779, -5.9801173786],
            [-39.5118699344, -5.9831727957],
            [-39.5275026319, -5.9812509276],
            [-39.5309480213, -5.9774015016],
            [-39.5431429625, -5.9889714495],
            [-39.5443039916, -6.0011718503],
            [-39.5502540504, -6.0116346234],
            [-39.5583357571, -6.0116251799],
            [-39.5614151221, -6.0086701525],
            [-39.5675853714, -5.983159303],
            [-39.5642495707, -5.9605898153],
            [-39.5598129327, -5.953128305],
            [-39.557915509, -5.9261053025],
            [-39.5504134836, -5.908769893],
            [-39.5516886942, -5.8862577009],
            [-39.5516795918, -5.886123322],
            [-39.5460010102, -5.8622200703],
            [-39.5516225962, -5.8527089087],
            [-39.5561220707, -5.8484617235],
            [-39.5550243834, -5.8414321762],
            [-39.5060259447, -5.7393769549],
            [-39.5028849356, -5.7324798898],
            [-39.5028199055, -5.7312887555],
            [-39.4997542797, -5.7235969187],
            [-39.4902889175, -5.723362282],
            [-39.4795003817, -5.7090674565],
            [-39.4793980812, -5.7026463174],
            [-39.4751137912, -5.7013214928],
            [-39.4710385454, -5.71181577],
            [-39.4594665744, -5.7126320806],
            [-39.4625899186, -5.7180759978],
            [-39.4560055074, -5.7249406396],
            [-39.4566632068, -5.7304879087],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2310951',
        name: 'Pires Ferreira',
        description: 'Pires Ferreira',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.5890299942, -4.2005995793],
            [-40.5785322121, -4.2072826588],
            [-40.5641723851, -4.2055890193],
            [-40.5526936331, -4.2131187203],
            [-40.5302803283, -4.2500667693],
            [-40.5190896933, -4.2569607275],
            [-40.5073372926, -4.2471925167],
            [-40.4939587707, -4.24536468],
            [-40.4889708358, -4.2430542268],
            [-40.4839226028, -4.2325382569],
            [-40.4501871543, -4.2080952674],
            [-40.4497577506, -4.2075662582],
            [-40.4497870728, -4.2080497741],
            [-40.4497911908, -4.2081228687],
            [-40.4503483027, -4.2171979615],
            [-40.441755021, -4.2343486186],
            [-40.43924064, -4.2506239125],
            [-40.4481061039, -4.258286739],
            [-40.4626891587, -4.2680006116],
            [-40.4757512127, -4.2891571333],
            [-40.4782535966, -4.293407198],
            [-40.5391092289, -4.3075667047],
            [-40.546267291, -4.3093914372],
            [-40.5571451404, -4.3138557918],
            [-40.568420656, -4.3136733979],
            [-40.5785299555, -4.3070526466],
            [-40.5952547156, -4.3094334226],
            [-40.6096206195, -4.3044875857],
            [-40.6196528083, -4.3104720356],
            [-40.6238112653, -4.3038626202],
            [-40.6471415946, -4.2955975142],
            [-40.6519511547, -4.2965521662],
            [-40.6613846574, -4.3033991717],
            [-40.6637796943, -4.308417064],
            [-40.6726970563, -4.3004071146],
            [-40.6703760485, -4.2963379349],
            [-40.6778681378, -4.2991091659],
            [-40.6827605742, -4.297007647],
            [-40.6806037739, -4.2914898834],
            [-40.6891584513, -4.2913314855],
            [-40.7044804095, -4.2865029749],
            [-40.6975851718, -4.2796012083],
            [-40.6932655473, -4.268029977],
            [-40.6934479639, -4.2504425287],
            [-40.6966269916, -4.2453959943],
            [-40.7015242668, -4.2442593904],
            [-40.6921392069, -4.2308759045],
            [-40.6944960332, -4.2292095224],
            [-40.694431474, -4.2221833247],
            [-40.690248625, -4.2220852145],
            [-40.6817556019, -4.2141126337],
            [-40.6819730061, -4.2117024651],
            [-40.6141624836, -4.2076153447],
            [-40.6089438913, -4.2073007629],
            [-40.6087099054, -4.2072871575],
            [-40.5890299942, -4.2005995793],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311009',
        name: 'Poranga',
        description: 'Poranga',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-41.2168696406, -4.5833568915],
            [-41.2076719394, -4.5853370934],
            [-41.1849410845, -4.5765975413],
            [-41.1874737348, -4.5880509996],
            [-41.1836982512, -4.5918681823],
            [-41.1650587148, -4.5844738716],
            [-41.158800175, -4.593687272],
            [-41.1464042503, -4.599271889],
            [-41.114231088, -4.6013811023],
            [-41.0991019006, -4.6023725264],
            [-41.0870258155, -4.6130724808],
            [-41.0691546736, -4.6147309115],
            [-41.0515460697, -4.6290640474],
            [-41.0348487051, -4.6349116635],
            [-41.0300474219, -4.6398444106],
            [-41.0211747698, -4.6389816944],
            [-41.0078553873, -4.6510084704],
            [-40.9881152544, -4.6598406515],
            [-40.9874905206, -4.6596503871],
            [-40.973438945, -4.6891213409],
            [-40.9685238536, -4.6918144773],
            [-40.9628969878, -4.6873171004],
            [-40.954540492, -4.7158950021],
            [-40.8881432437, -4.7166420869],
            [-40.8743071586, -4.701556221],
            [-40.8745355138, -4.6915431256],
            [-40.8710056297, -4.6858820332],
            [-40.8608699721, -4.6794525126],
            [-40.8570692523, -4.6729514269],
            [-40.8442362599, -4.6729521321],
            [-40.8546973615, -4.6773814015],
            [-40.8527291239, -4.6856107523],
            [-40.8628115586, -4.6906602252],
            [-40.8673662452, -4.698926707],
            [-40.8668315385, -4.7062643915],
            [-40.8723047148, -4.7133389915],
            [-40.8712335549, -4.7251819631],
            [-40.8658736474, -4.7317404557],
            [-40.8760662136, -4.7461634758],
            [-40.882257849, -4.7528302871],
            [-40.8787196432, -4.7703433621],
            [-40.8832313035, -4.7811537029],
            [-40.8823367504, -4.7874000566],
            [-40.8788615124, -4.78705221],
            [-40.8823041454, -4.7933117072],
            [-40.8801441126, -4.7996136248],
            [-40.885901015, -4.797102836],
            [-40.879310732, -4.8127382985],
            [-40.8775457102, -4.818081445],
            [-40.879720831, -4.8295563509],
            [-40.8760110689, -4.8320617473],
            [-40.879523278, -4.8392327106],
            [-40.8810375033, -4.8430803064],
            [-40.8751754115, -4.8531949969],
            [-40.880316263, -4.8594152859],
            [-40.8720772686, -4.8626733493],
            [-40.8790581456, -4.8678966605],
            [-40.8836147519, -4.8662821315],
            [-40.9092680384, -4.8745027772],
            [-40.9189133878, -4.8833563117],
            [-40.9266187242, -4.8858713734],
            [-40.941502835, -4.8824787084],
            [-40.9505009652, -4.8871208831],
            [-40.9918261974, -4.9151510187],
            [-40.9997381382, -4.9280891595],
            [-41.0061972178, -4.9306680922],
            [-41.0033948372, -4.933884382],
            [-41.0078176048, -4.935417573],
            [-41.0079280402, -4.9393146618],
            [-41.0218953883, -4.9545129413],
            [-41.0242394428, -4.9536847296],
            [-41.0358559025, -4.9651406136],
            [-41.0433627781, -4.9981110563],
            [-41.0522684105, -5.0173429206],
            [-41.0514930258, -5.0236693105],
            [-41.1208526023, -5.0235607779],
            [-41.1222260635, -5.0076827656],
            [-41.1350932133, -4.9974326009],
            [-41.1379041397, -4.9841016427],
            [-41.146096629, -4.9918129335],
            [-41.1505482501, -4.9917250114],
            [-41.1798422948, -4.9471597547],
            [-41.1886359347, -4.9402337182],
            [-41.1981783198, -4.9370698572],
            [-41.2034169076, -4.949364186],
            [-41.2092372521, -4.9455750742],
            [-41.2102136966, -4.9382308139],
            [-41.220992343, -4.9378447966],
            [-41.2225755567, -4.9261235956],
            [-41.2345536094, -4.9053595491],
            [-41.2277288549, -4.890603128],
            [-41.2491993369, -4.8693057191],
            [-41.2395406658, -4.8559959628],
            [-41.2395023817, -4.8484615307],
            [-41.2420895076, -4.824851384],
            [-41.232458956, -4.8153540744],
            [-41.232091335, -4.8041521482],
            [-41.2250559299, -4.7974354929],
            [-41.2235769929, -4.7902909391],
            [-41.2096017522, -4.7829629962],
            [-41.2076413183, -4.7763846445],
            [-41.2123917684, -4.7716412452],
            [-41.2415445745, -4.7646438914],
            [-41.2476348119, -4.7611672657],
            [-41.2491587079, -4.7556591271],
            [-41.234859552, -4.7461453095],
            [-41.2362758646, -4.7383681489],
            [-41.2337097732, -4.7333575579],
            [-41.2021184337, -4.7015117418],
            [-41.1813424737, -4.6734202416],
            [-41.1735322066, -4.6681614658],
            [-41.1746361864, -4.6649526435],
            [-41.1852260186, -4.6615844158],
            [-41.2024421564, -4.6378123548],
            [-41.2289565161, -4.6197124153],
            [-41.233061893, -4.6098066018],
            [-41.2226666222, -4.5894096553],
            [-41.2208410048, -4.578553964],
            [-41.2394837713, -4.573143929],
            [-41.2197816958, -4.5740700963],
            [-41.2168696406, -4.5833568915],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311108',
        name: 'Porteiras',
        description: 'Porteiras',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.0316864636, -7.6109481127],
            [-39.031665385, -7.6230103524],
            [-39.027463686, -7.633400044],
            [-39.0447452972, -7.6412523321],
            [-39.0656653623, -7.6201194928],
            [-39.0730645887, -7.6261683],
            [-39.0974628847, -7.6106866799],
            [-39.1048980688, -7.6059691718],
            [-39.1222969259, -7.5949243368],
            [-39.1360729639, -7.5861749061],
            [-39.1634755839, -7.5687710401],
            [-39.1636869913, -7.5686370212],
            [-39.173727334, -7.5597341583],
            [-39.1949219175, -7.5025505028],
            [-39.1904335231, -7.4968105797],
            [-39.1593767154, -7.4571007082],
            [-39.1538887673, -7.4500837641],
            [-39.1456878413, -7.4548617293],
            [-39.1210736755, -7.4692107872],
            [-39.0862341102, -7.4869805438],
            [-39.0500333558, -7.5193352954],
            [-39.0459464628, -7.5229867746],
            [-39.0363403209, -7.5315678393],
            [-39.0357926253, -7.542827584],
            [-39.0356160028, -7.5464336985],
            [-39.0316864636, -7.6109481127],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311207',
        name: 'Potengi',
        description: 'Potengi',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.0573036253, -7.0055212979],
            [-39.996842703, -7.0086545966],
            [-39.9762372942, -7.0041254383],
            [-39.9659077275, -7.0018547661],
            [-39.9485410684, -7.0106810606],
            [-39.9440430969, -7.0779606936],
            [-39.9343827908, -7.0741065774],
            [-39.9159991049, -7.0746236169],
            [-39.9105225723, -7.0694572377],
            [-39.899713255, -7.0682737647],
            [-39.8890096875, -7.0528343023],
            [-39.8904999568, -7.0704717359],
            [-39.8829627391, -7.0886960298],
            [-39.8944739489, -7.0924861028],
            [-39.8973649922, -7.0968792479],
            [-39.9165825319, -7.0919044069],
            [-39.9808320972, -7.1429068941],
            [-39.9963329544, -7.1502105725],
            [-40.0500999003, -7.1472085596],
            [-40.0720717583, -7.138488594],
            [-40.0951308143, -7.1293329018],
            [-40.1012499671, -7.1269029289],
            [-40.1373551702, -7.1125605658],
            [-40.1538591258, -7.0712083039],
            [-40.1647394999, -7.0439285754],
            [-40.1664912548, -7.039534537],
            [-40.1434021473, -7.0312413935],
            [-40.1432319172, -7.0276742726],
            [-40.1290828312, -7.0204625448],
            [-40.1231955989, -7.0184543526],
            [-40.1220099677, -7.0224832532],
            [-40.1197863979, -7.02448242],
            [-40.1169729852, -7.0183774498],
            [-40.1096374034, -7.0170483946],
            [-40.1026153633, -7.010319952],
            [-40.0958996233, -7.0103553818],
            [-40.0998275519, -7.004355093],
            [-40.0954506055, -6.9983025428],
            [-40.0984446717, -6.9967756027],
            [-40.095375275, -6.9868687225],
            [-40.0829487438, -6.9829097118],
            [-40.082909574, -6.9830377879],
            [-40.0763038705, -7.0045348218],
            [-40.0574487121, -7.0054841742],
            [-40.0573036253, -7.0055212979],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311231',
        name: 'Potiretama',
        description: 'Potiretama',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.1955696492, -5.6345799615],
            [-38.1870865366, -5.6412692178],
            [-38.1846988007, -5.6412127787],
            [-38.1678505372, -5.6452495828],
            [-38.1714607676, -5.6513460061],
            [-38.1609787796, -5.6560063507],
            [-38.1482288337, -5.6587660174],
            [-38.1477302073, -5.6660087216],
            [-38.139025094, -5.6747111543],
            [-38.1301113444, -5.6759594284],
            [-38.1234767124, -5.6716437316],
            [-38.1049437476, -5.6711240802],
            [-38.0825303468, -5.6721493067],
            [-38.0824941062, -5.6980105898],
            [-38.0897535099, -5.7200867591],
            [-38.0868196837, -5.7301292038],
            [-38.0748782131, -5.7230761471],
            [-38.0466607692, -5.7298697277],
            [-38.0582280892, -5.7540727391],
            [-38.0582450734, -5.7544581877],
            [-38.0758074301, -5.7806627265],
            [-38.111196949, -5.8182628509],
            [-38.1185160343, -5.8324409666],
            [-38.1159373845, -5.8475928444],
            [-38.1245889768, -5.8872178609],
            [-38.1422053508, -5.9012463313],
            [-38.1463715494, -5.915783331],
            [-38.1589490034, -5.924181544],
            [-38.1639378409, -5.9144075621],
            [-38.1586446739, -5.9040834968],
            [-38.1517198512, -5.9019126888],
            [-38.145752768, -5.8914541872],
            [-38.1512113263, -5.8803807257],
            [-38.1515430863, -5.8757965419],
            [-38.145918559, -5.8690376588],
            [-38.1487491197, -5.8606352735],
            [-38.1595651252, -5.8600080081],
            [-38.1676352296, -5.8527678968],
            [-38.1838947344, -5.84680484],
            [-38.1858025614, -5.8423489542],
            [-38.1992310904, -5.8464442617],
            [-38.2002904, -5.842622217],
            [-38.2109685385, -5.8414932561],
            [-38.2158826352, -5.8376531973],
            [-38.2271010081, -5.833364322],
            [-38.2226498777, -5.8294095105],
            [-38.2265234246, -5.8244476999],
            [-38.2258846102, -5.8182936678],
            [-38.2367694499, -5.8076464015],
            [-38.2360584866, -5.8007692976],
            [-38.244514749, -5.7968317797],
            [-38.2414671318, -5.791210011],
            [-38.2459470447, -5.786029609],
            [-38.2447579995, -5.7828602764],
            [-38.244843266, -5.7826245631],
            [-38.2502696945, -5.7530564085],
            [-38.2566844292, -5.7371002433],
            [-38.2485955507, -5.7315870002],
            [-38.2598583242, -5.7162676614],
            [-38.2592219234, -5.7128708123],
            [-38.2661791311, -5.6959114504],
            [-38.264180939, -5.6900062467],
            [-38.2669840662, -5.6819630955],
            [-38.2585201146, -5.6613170395],
            [-38.2583949193, -5.6611751461],
            [-38.2557001369, -5.6581213401],
            [-38.247162077, -5.6510572351],
            [-38.2482669213, -5.6468345404],
            [-38.2371491668, -5.6366282769],
            [-38.2324424764, -5.6431329584],
            [-38.2305023544, -5.6400862679],
            [-38.2270421322, -5.6430197246],
            [-38.2237587965, -5.6345119091],
            [-38.218945655, -5.6399673772],
            [-38.2143573022, -5.6388049813],
            [-38.2142726571, -5.6356021687],
            [-38.2137171203, -5.6326093296],
            [-38.2053480566, -5.6310232462],
            [-38.2036694519, -5.634380312],
            [-38.1955696492, -5.6345799615],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311264',
        name: 'Quiterianópolis',
        description: 'Quiterianópolis',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.6364020916, -5.7120322914],
            [-40.6399007896, -5.7184020204],
            [-40.6238668587, -5.7327392097],
            [-40.6165641375, -5.7346345041],
            [-40.6129665243, -5.7270149719],
            [-40.6115987122, -5.7239547456],
            [-40.6027436489, -5.7258191324],
            [-40.5981540455, -5.7373947027],
            [-40.5705076577, -5.7393802764],
            [-40.5377370049, -5.7225596142],
            [-40.5468461927, -5.7395142538],
            [-40.5569645019, -5.7389649451],
            [-40.5705735902, -5.7541006409],
            [-40.569666681, -5.7713924143],
            [-40.563975954, -5.7864476382],
            [-40.5673669553, -5.7942690452],
            [-40.5687192324, -5.8137399492],
            [-40.5659416239, -5.8214144708],
            [-40.5691162029, -5.8285508421],
            [-40.5650381583, -5.8416306763],
            [-40.5856125619, -5.8377612259],
            [-40.5867064312, -5.8344002671],
            [-40.6020783501, -5.8377946293],
            [-40.6016808316, -5.8436216291],
            [-40.6184500647, -5.852099888],
            [-40.6281506262, -5.8525214488],
            [-40.6415359781, -5.8396455424],
            [-40.6520910052, -5.8442914427],
            [-40.6654845371, -5.8515027996],
            [-40.6728420824, -5.8691689807],
            [-40.6803814241, -5.871197066],
            [-40.6816810086, -5.8799429189],
            [-40.6741762017, -5.8924326534],
            [-40.673290279, -5.9033144926],
            [-40.6623905344, -5.9117021045],
            [-40.6632351078, -5.914038254],
            [-40.6872344882, -5.9144388012],
            [-40.6973722657, -5.9237722842],
            [-40.7056495532, -5.9301963381],
            [-40.7054335686, -5.9387176089],
            [-40.7156365752, -5.9411182638],
            [-40.7098343814, -5.9479487869],
            [-40.7140151935, -5.9595166392],
            [-40.7094020363, -5.9671965746],
            [-40.7188214228, -5.9644976545],
            [-40.7286044896, -5.9728792352],
            [-40.7364071886, -5.9735207466],
            [-40.7461951756, -5.9829233256],
            [-40.7453006275, -5.9885269264],
            [-40.7375214316, -5.9922176217],
            [-40.7405983382, -6.0130130452],
            [-40.7342310673, -6.0218074521],
            [-40.7341043684, -6.0298948555],
            [-40.7154269526, -6.0401472423],
            [-40.7172820506, -6.0459687423],
            [-40.7233993573, -6.0520768896],
            [-40.731304856, -6.0518563944],
            [-40.7370903638, -6.0580115845],
            [-40.7377231787, -6.0629807313],
            [-40.7326127436, -6.0693942729],
            [-40.7361156877, -6.0768098579],
            [-40.7471100879, -6.0823237008],
            [-40.7467307435, -6.0899975747],
            [-40.7584482145, -6.0929379608],
            [-40.7636936817, -6.0894675361],
            [-40.7714182083, -6.0910575531],
            [-40.7860022555, -6.0903290132],
            [-40.8004470028, -6.0966907894],
            [-40.8074147996, -6.0919042538],
            [-40.8106163785, -6.0957612882],
            [-40.8073741803, -6.1047276609],
            [-40.8573409682, -6.1049054063],
            [-40.8724737641, -6.0562338437],
            [-40.8839076463, -6.0473971982],
            [-40.9018314551, -6.0488105359],
            [-40.9059334555, -6.0461237228],
            [-40.9071751684, -6.0442680087],
            [-40.9035751151, -6.0204835196],
            [-40.8894472017, -6.0035894554],
            [-40.887730077, -5.997516101],
            [-40.8875053811, -5.9949394146],
            [-40.886982644, -5.9889336679],
            [-40.8752012716, -5.977437595],
            [-40.8732624065, -5.9663650734],
            [-40.879844013, -5.9172745536],
            [-40.8882148848, -5.9122407857],
            [-40.8971471568, -5.9009048782],
            [-40.8932204314, -5.8836449501],
            [-40.8932719323, -5.8834169281],
            [-40.905273358, -5.8488840921],
            [-40.9086850053, -5.8251587869],
            [-40.9054465965, -5.8010625863],
            [-40.9094227418, -5.7653247081],
            [-40.8815376484, -5.7703284201],
            [-40.8685836436, -5.7674730568],
            [-40.8655528886, -5.7657742587],
            [-40.8451692977, -5.7705104408],
            [-40.8287448599, -5.7568738264],
            [-40.8143580491, -5.7545212097],
            [-40.7921690432, -5.7338597072],
            [-40.7800812078, -5.7356224415],
            [-40.7672778267, -5.7251046996],
            [-40.760362836, -5.7235465282],
            [-40.7568379783, -5.7166575643],
            [-40.7507949237, -5.7190917448],
            [-40.7504931829, -5.7192902406],
            [-40.6962631216, -5.6446756739],
            [-40.6950444515, -5.6429925458],
            [-40.6906675985, -5.6561469637],
            [-40.6695809161, -5.6679902405],
            [-40.6594386643, -5.6943736757],
            [-40.6544838275, -5.700874305],
            [-40.6398086579, -5.7066916362],
            [-40.6364020916, -5.7120322914],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311306',
        name: 'Quixadá',
        description: 'Quixadá',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.0044480537, -4.6668271634],
            [-38.9999199357, -4.674001001],
            [-38.9926389498, -4.6663548445],
            [-38.983278583, -4.6648320755],
            [-38.9784998874, -4.6682695349],
            [-38.9783624812, -4.6683698761],
            [-38.9657287923, -4.6656065713],
            [-38.9535562835, -4.6669615158],
            [-38.9505075488, -4.6810006772],
            [-38.9496505199, -4.685067801],
            [-38.9609898362, -4.6957719221],
            [-38.9650994588, -4.7048368692],
            [-38.9512280533, -4.7168606883],
            [-38.9431149412, -4.7408142469],
            [-38.9261018117, -4.7391136781],
            [-38.9220567739, -4.7361276424],
            [-38.9013958245, -4.7197812952],
            [-38.9045821769, -4.7126704223],
            [-38.8908267715, -4.71448557],
            [-38.8834451683, -4.7088839066],
            [-38.8809613977, -4.6935999543],
            [-38.8753660276, -4.6898160934],
            [-38.8774656239, -4.6857615267],
            [-38.8739737181, -4.6792563469],
            [-38.8014067498, -4.7406479059],
            [-38.801513029, -4.7409752112],
            [-38.8132107121, -4.7586312615],
            [-38.8596127297, -4.8286950132],
            [-38.8598091282, -4.8392207197],
            [-38.8713191524, -4.8458264663],
            [-38.8758139855, -4.8516244379],
            [-38.8833484552, -4.842924832],
            [-38.8961355581, -4.8409944868],
            [-38.8981629148, -4.8339836609],
            [-38.9080144357, -4.832937781],
            [-38.9216272947, -4.8259322772],
            [-38.9217038417, -4.8363463568],
            [-38.9170454618, -4.8538429017],
            [-38.8952578327, -4.8889653584],
            [-38.8900151937, -4.8950392273],
            [-38.890378603, -4.9140538038],
            [-38.8851652027, -4.9266669808],
            [-38.882398578, -4.9282548083],
            [-38.8830837996, -4.9349656715],
            [-38.8070895366, -4.9210930246],
            [-38.7632323774, -4.9132379651],
            [-38.7117529971, -4.9040198739],
            [-38.7116892444, -4.9040079837],
            [-38.702227077, -4.9023140295],
            [-38.6483592359, -4.9376035817],
            [-38.6456210014, -4.9581684318],
            [-38.6417981758, -4.9612026018],
            [-38.6468337523, -4.9741737005],
            [-38.6473292174, -4.9803157089],
            [-38.653606988, -4.9820507616],
            [-38.66015803, -4.9901633828],
            [-38.6607673426, -4.9964349963],
            [-38.669932083, -4.9979544594],
            [-38.6771144879, -5.0043923641],
            [-38.6760002311, -5.0239045786],
            [-38.6796939912, -5.0368999928],
            [-38.6820205301, -5.0507956972],
            [-38.686072439, -5.0563134858],
            [-38.6791819903, -5.0575003624],
            [-38.6669065576, -5.0465692131],
            [-38.658173208, -5.0458129393],
            [-38.6438951119, -5.0526196394],
            [-38.6353056748, -5.0526267398],
            [-38.6271124287, -5.0594236866],
            [-38.6261926558, -5.0659005889],
            [-38.6284520888, -5.0786822001],
            [-38.6261115953, -5.0813788095],
            [-38.6288657936, -5.084272092],
            [-38.6234192675, -5.0852992578],
            [-38.6210329314, -5.0907284454],
            [-38.6164451651, -5.110878963],
            [-38.618059763, -5.1185265061],
            [-38.6299109567, -5.1169500661],
            [-38.6373557785, -5.1214762493],
            [-38.6433815664, -5.1192834684],
            [-38.6505675998, -5.1213334236],
            [-38.6650170072, -5.1163877978],
            [-38.6969425916, -5.0945406087],
            [-38.7165141678, -5.091799831],
            [-38.726062538, -5.0949503095],
            [-38.7442993642, -5.09476807],
            [-38.7625015451, -5.0789744014],
            [-38.7918267814, -5.0784289572],
            [-38.8117525733, -5.0651633588],
            [-38.9013000253, -5.1644778254],
            [-38.9224617171, -5.1604613188],
            [-38.9389208076, -5.1540124919],
            [-38.9557330816, -5.1531860454],
            [-38.9662630962, -5.1479804573],
            [-38.9720318232, -5.1430102663],
            [-38.9773949103, -5.1443393394],
            [-39.0000028609, -5.1638692066],
            [-39.0093335353, -5.1611968655],
            [-39.0149264323, -5.1680244465],
            [-39.0218701627, -5.1625237576],
            [-39.028580481, -5.1637736887],
            [-39.0288813569, -5.1637551834],
            [-39.0457328107, -5.157116518],
            [-39.0504416668, -5.1570268098],
            [-39.0588703935, -5.1497946289],
            [-39.0693621411, -5.154541839],
            [-39.0840978202, -5.1515016969],
            [-39.0850761731, -5.1576492965],
            [-39.0938936155, -5.1490419601],
            [-39.1282623777, -5.1468565018],
            [-39.1373187204, -5.1375194743],
            [-39.1418165802, -5.145904984],
            [-39.1604890715, -5.1361324549],
            [-39.168865159, -5.1314894743],
            [-39.1663680773, -5.1245294815],
            [-39.1596440667, -5.1216015178],
            [-39.1682468225, -5.1131706924],
            [-39.1680830175, -5.1061326953],
            [-39.1492353153, -5.1052498659],
            [-39.1579076902, -5.0980116757],
            [-39.1552292575, -5.0853210772],
            [-39.161946949, -5.0849386198],
            [-39.1523418505, -5.0714709094],
            [-39.1607243934, -5.0620841637],
            [-39.1573091603, -5.0588367118],
            [-39.1572022238, -5.0513785657],
            [-39.1659502777, -5.0534258833],
            [-39.1825886444, -5.0396396423],
            [-39.1910892041, -5.0278176438],
            [-39.1973425711, -5.0088367917],
            [-39.2015324722, -5.0085888795],
            [-39.2051443507, -5.0186462619],
            [-39.2132526572, -5.0096839533],
            [-39.2188028054, -5.0110029257],
            [-39.2245302948, -5.0130051384],
            [-39.2332210462, -5.0018219107],
            [-39.2332516831, -4.9823336876],
            [-39.2267617484, -4.9733830198],
            [-39.2249773066, -4.9611590793],
            [-39.2074250615, -4.9584074924],
            [-39.1987230628, -4.9658763882],
            [-39.186531232, -4.9589121684],
            [-39.1836886994, -4.9414578364],
            [-39.1761039554, -4.9384825077],
            [-39.17337619, -4.9317507216],
            [-39.1641952283, -4.9251546951],
            [-39.1646254758, -4.9243159073],
            [-39.1677213255, -4.9226908995],
            [-39.168318108, -4.9166043873],
            [-39.1625306203, -4.910522295],
            [-39.1629281056, -4.9063798028],
            [-39.1523557662, -4.904439777],
            [-39.1404449398, -4.8807517217],
            [-39.1345920197, -4.879846652],
            [-39.1334607771, -4.8738902704],
            [-39.1222855275, -4.8632775614],
            [-39.1206896634, -4.8578401766],
            [-39.118227944, -4.8530502934],
            [-39.1224835214, -4.8540851962],
            [-39.123413188, -4.8461894525],
            [-39.1199551522, -4.8386807739],
            [-39.1157648929, -4.8380334207],
            [-39.1161628059, -4.8428237904],
            [-39.1086509743, -4.8412717823],
            [-39.1040621437, -4.8485202545],
            [-39.1014685235, -4.8468401564],
            [-39.1047935322, -4.8417898824],
            [-39.1016664027, -4.8429560907],
            [-39.101933017, -4.8376481876],
            [-39.0976109788, -4.8333766369],
            [-39.0902964686, -4.8332472873],
            [-39.081035307, -4.841368465],
            [-39.0772610158, -4.8291073352],
            [-39.082781228, -4.8272930373],
            [-39.0851061578, -4.8226329556],
            [-39.0776553568, -4.8245743406],
            [-39.0701351582, -4.8206185775],
            [-39.0706418352, -4.8146813796],
            [-39.0662720846, -4.8125057052],
            [-39.0692186142, -4.8076569657],
            [-39.0655608102, -4.8046873207],
            [-39.0665757022, -4.7998413695],
            [-39.0583433441, -4.7962799634],
            [-39.0553937869, -4.7879702453],
            [-39.0460446747, -4.7827263661],
            [-39.0378119541, -4.782230602],
            [-39.048791502, -4.7703761764],
            [-39.0533507121, -4.7706348728],
            [-39.0682992394, -4.7648161235],
            [-39.0746008282, -4.7727301566],
            [-39.0872019196, -4.7614483702],
            [-39.1030017034, -4.7612466617],
            [-39.0726410759, -4.7262636095],
            [-39.0600592265, -4.7244346717],
            [-39.0495005372, -4.7153963478],
            [-39.0424792663, -4.7153551301],
            [-39.0436436677, -4.6934407812],
            [-39.033753865, -4.6676840428],
            [-39.021867557, -4.6650335705],
            [-39.0044480537, -4.6668271634],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311355',
        name: 'Quixelô',
        description: 'Quixelô',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.0157589523, -6.0004809651],
            [-39.006672843, -6.0182608079],
            [-38.9919407641, -6.0199607081],
            [-38.9865229323, -6.0284114327],
            [-38.9835580202, -6.0468602579],
            [-38.9851603289, -6.0690121795],
            [-38.9896695213, -6.0762621456],
            [-38.98561236, -6.0787957082],
            [-38.9830164645, -6.0895066815],
            [-38.9779222218, -6.0916026075],
            [-38.9807121335, -6.0994135685],
            [-38.9775855489, -6.105586759],
            [-38.9709212322, -6.1092631921],
            [-38.9751713209, -6.1195619247],
            [-38.9688395436, -6.122308397],
            [-38.9861966805, -6.1565120797],
            [-38.9833597203, -6.15867505],
            [-38.9913881919, -6.1902630138],
            [-39.0032817626, -6.2199263777],
            [-39.018730507, -6.2312134833],
            [-39.0420719389, -6.2482693958],
            [-39.0563479447, -6.2587018408],
            [-39.0645851063, -6.2382635074],
            [-39.0783758566, -6.2357178887],
            [-39.0971065925, -6.2420951442],
            [-39.1189619506, -6.264376753],
            [-39.1266981325, -6.266425366],
            [-39.1461993144, -6.2569706381],
            [-39.1926442074, -6.2624337068],
            [-39.1950476065, -6.263358241],
            [-39.2090722318, -6.2665473568],
            [-39.2109349982, -6.2669712104],
            [-39.2388379829, -6.2753684033],
            [-39.2565471038, -6.2695554302],
            [-39.267508798, -6.2746821657],
            [-39.2787227978, -6.2790855732],
            [-39.2891809644, -6.2768766117],
            [-39.2983679426, -6.2696153942],
            [-39.3016708331, -6.2618549262],
            [-39.300111631, -6.2509256994],
            [-39.2971219872, -6.2374528499],
            [-39.2911379474, -6.232935301],
            [-39.2708587622, -6.2338699195],
            [-39.2650931578, -6.2281972864],
            [-39.264996289, -6.2225221512],
            [-39.2806407098, -6.2111109514],
            [-39.2767479294, -6.2036420588],
            [-39.2794460243, -6.198064601],
            [-39.2758626877, -6.1937020912],
            [-39.2773876207, -6.187472097],
            [-39.2714121207, -6.1826436001],
            [-39.2708346397, -6.1784513786],
            [-39.2771392572, -6.1715953741],
            [-39.2928170137, -6.1736677561],
            [-39.3077186607, -6.1744490965],
            [-39.2596984358, -6.1274417266],
            [-39.2176597244, -6.136761205],
            [-39.2034035541, -6.1399207317],
            [-39.1870416942, -6.1435448552],
            [-39.1405180337, -6.1525537205],
            [-39.1222377945, -6.1420327143],
            [-39.0862568476, -6.1213271791],
            [-39.0789461928, -6.1181413547],
            [-39.0778314481, -6.1127997174],
            [-39.0894448658, -6.0936481998],
            [-39.091365331, -6.0838720342],
            [-39.0830355796, -6.0767608276],
            [-39.0829797142, -6.0697228089],
            [-39.0754171571, -6.0616115257],
            [-39.0737796947, -6.051443648],
            [-39.0635902285, -6.0451603745],
            [-39.0570811287, -6.0477416933],
            [-39.0536792229, -6.0432877007],
            [-39.0519373273, -6.0254762508],
            [-39.0455465003, -6.024752001],
            [-39.0429833513, -6.0280296889],
            [-39.0393492708, -6.0229873434],
            [-39.0329567781, -6.0279681257],
            [-39.0297112648, -6.0255731115],
            [-39.0336699043, -6.0071639557],
            [-39.0214692531, -6.008929062],
            [-39.0157589523, -6.0004809651],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311405',
        name: 'Quixeramobim',
        description: 'Quixeramobim',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.2982796946, -4.869492155],
            [-39.3037449284, -4.8800629934],
            [-39.2894136685, -4.879589257],
            [-39.2840957149, -4.8850227995],
            [-39.2848131934, -4.8931640916],
            [-39.2782745747, -4.8978330288],
            [-39.2721659155, -4.9089833031],
            [-39.2478960147, -4.922749438],
            [-39.2380241704, -4.9395446997],
            [-39.2319269829, -4.9418436249],
            [-39.224494397, -4.952122898],
            [-39.2249773066, -4.9611590793],
            [-39.2267617484, -4.9733830198],
            [-39.2332516831, -4.9823336876],
            [-39.2332210462, -5.0018219107],
            [-39.2245302948, -5.0130051384],
            [-39.2188028054, -5.0110029257],
            [-39.2132526572, -5.0096839533],
            [-39.2051443507, -5.0186462619],
            [-39.2015324722, -5.0085888795],
            [-39.1973425711, -5.0088367917],
            [-39.1910892041, -5.0278176438],
            [-39.1825886444, -5.0396396423],
            [-39.1659502777, -5.0534258833],
            [-39.1572022238, -5.0513785657],
            [-39.1573091603, -5.0588367118],
            [-39.1607243934, -5.0620841637],
            [-39.1523418505, -5.0714709094],
            [-39.161946949, -5.0849386198],
            [-39.1552292575, -5.0853210772],
            [-39.1579076902, -5.0980116757],
            [-39.1492353153, -5.1052498659],
            [-39.1680830175, -5.1061326953],
            [-39.1682468225, -5.1131706924],
            [-39.1596440667, -5.1216015178],
            [-39.1663680773, -5.1245294815],
            [-39.168865159, -5.1314894743],
            [-39.1604890715, -5.1361324549],
            [-39.1418165802, -5.145904984],
            [-39.1373187204, -5.1375194743],
            [-39.1282623777, -5.1468565018],
            [-39.0938936155, -5.1490419601],
            [-39.0850761731, -5.1576492965],
            [-39.0863675849, -5.1686206201],
            [-39.0593388786, -5.2187689981],
            [-39.052777698, -5.2241617967],
            [-39.0521013282, -5.2245197644],
            [-39.0469837825, -5.2272257081],
            [-39.0370801083, -5.2221353667],
            [-39.0275645409, -5.2224723109],
            [-39.031822369, -5.2261551953],
            [-39.0398373586, -5.2492347053],
            [-39.0340844821, -5.2700859679],
            [-39.053350183, -5.2974612773],
            [-39.0534737984, -5.2978657142],
            [-39.0544424956, -5.3010421387],
            [-39.0749150353, -5.3682131124],
            [-39.0824168111, -5.3928428582],
            [-39.0847138074, -5.4032797697],
            [-39.0740291616, -5.4040756506],
            [-39.0558114264, -5.4189722995],
            [-39.0603708366, -5.4602804175],
            [-39.0683933036, -5.4731894384],
            [-39.0709681051, -5.4859003829],
            [-39.0627496199, -5.4972208494],
            [-39.059934928, -5.5106943214],
            [-39.0756288115, -5.5332850127],
            [-39.0886862217, -5.5283606334],
            [-39.0871942825, -5.5103999716],
            [-39.0909666452, -5.5064409304],
            [-39.0958823163, -5.511847731],
            [-39.1088405165, -5.510879513],
            [-39.1159397612, -5.5067247416],
            [-39.1330689773, -5.5118408716],
            [-39.1396651212, -5.507442217],
            [-39.1456294678, -5.5086504282],
            [-39.1523336385, -5.5159874707],
            [-39.1582413658, -5.515021185],
            [-39.1596331983, -5.5186880538],
            [-39.1644502176, -5.5193645872],
            [-39.1667552331, -5.5234370194],
            [-39.1766126628, -5.5273691043],
            [-39.1918078065, -5.5286886109],
            [-39.1992808453, -5.535191387],
            [-39.2013068602, -5.5414305568],
            [-39.2069507861, -5.5438537634],
            [-39.224609148, -5.544924258],
            [-39.2233788176, -5.5590665888],
            [-39.2321527719, -5.577905764],
            [-39.2456005619, -5.584054359],
            [-39.2464685399, -5.5920873287],
            [-39.2595095189, -5.5805674051],
            [-39.2529300161, -5.5675962504],
            [-39.2536150647, -5.5605473263],
            [-39.2652977838, -5.5535337024],
            [-39.2635242156, -5.5475406823],
            [-39.277971818, -5.5249687],
            [-39.2834573009, -5.5250977193],
            [-39.3020040096, -5.509933788],
            [-39.3100048078, -5.5105480071],
            [-39.3111023369, -5.5108431131],
            [-39.3513678311, -5.5216755822],
            [-39.3516673222, -5.5217563169],
            [-39.3668134353, -5.5258305148],
            [-39.3995422514, -5.5095159336],
            [-39.4000627622, -5.5092409101],
            [-39.4422753872, -5.4868931524],
            [-39.4566575749, -5.4792755915],
            [-39.4636453588, -5.4755750561],
            [-39.4838217146, -5.4466755794],
            [-39.5087982384, -5.4108835078],
            [-39.5306919563, -5.3753155714],
            [-39.5502533374, -5.3772447948],
            [-39.5660056749, -5.3796842925],
            [-39.5680304244, -5.3997319185],
            [-39.5771918057, -5.4033712155],
            [-39.5842812933, -5.4074083713],
            [-39.5977533182, -5.3996792609],
            [-39.6006775641, -5.4068651875],
            [-39.6134747402, -5.4056345435],
            [-39.6178380649, -5.398796262],
            [-39.6315274591, -5.398471868],
            [-39.6428877274, -5.3832688553],
            [-39.6489643313, -5.3831191194],
            [-39.6492522231, -5.3755363189],
            [-39.6577183202, -5.3745277205],
            [-39.6618428347, -5.371688602],
            [-39.6602579948, -5.3657135348],
            [-39.6677821278, -5.3623752091],
            [-39.6663923706, -5.3582026452],
            [-39.6621196731, -5.3590906122],
            [-39.6590560484, -5.3553257237],
            [-39.6560776613, -5.3448609266],
            [-39.6581850469, -5.3392662621],
            [-39.6562926956, -5.3365622906],
            [-39.6471237008, -5.3416249955],
            [-39.6447301573, -5.3332351785],
            [-39.6413110029, -5.3317062628],
            [-39.6313078563, -5.3360998415],
            [-39.628130272, -5.3234052091],
            [-39.6219660349, -5.3224071908],
            [-39.6262834898, -5.3083687178],
            [-39.6233970392, -5.2549706144],
            [-39.6136019025, -5.2400640347],
            [-39.5873750277, -5.2001584266],
            [-39.5338167003, -5.1187122278],
            [-39.5337809586, -5.1186579717],
            [-39.5494729641, -5.1135107488],
            [-39.57986138, -5.0954301946],
            [-39.5974739671, -5.0971963721],
            [-39.6177663617, -5.0813189169],
            [-39.6176231709, -5.0756079462],
            [-39.6337692841, -5.0545028953],
            [-39.6313520374, -5.0498030387],
            [-39.6293569305, -5.0428909895],
            [-39.5480859415, -5.0485152837],
            [-39.5223239126, -5.0502950471],
            [-39.5152193606, -5.0507856705],
            [-39.5023238117, -5.0516758451],
            [-39.4821424312, -5.0259996466],
            [-39.4778594348, -5.0205515917],
            [-39.4724672529, -5.0136925229],
            [-39.4699078882, -5.0104378031],
            [-39.4752007681, -5.0006214207],
            [-39.4800585456, -4.9799796283],
            [-39.4728362862, -4.9755414894],
            [-39.4725335927, -4.9753246921],
            [-39.4658413242, -4.9632423605],
            [-39.4411918285, -4.9543340418],
            [-39.4213125658, -4.9500266762],
            [-39.4224787213, -4.9430841929],
            [-39.4135547743, -4.9412293724],
            [-39.3965268245, -4.927863631],
            [-39.3862803226, -4.9356867631],
            [-39.389642621, -4.9426126799],
            [-39.3851404477, -4.9459585179],
            [-39.3850629565, -4.9549634052],
            [-39.3800849904, -4.9569934528],
            [-39.3741943011, -4.9662772302],
            [-39.3754424284, -4.9717264895],
            [-39.3627282731, -4.9756043317],
            [-39.3548691995, -4.982180665],
            [-39.3498630615, -4.974942801],
            [-39.3541114515, -4.9658528516],
            [-39.3474209952, -4.9605412235],
            [-39.3501101243, -4.9571505316],
            [-39.3548377872, -4.9528609678],
            [-39.3586449726, -4.9374551143],
            [-39.3647541842, -4.9340292201],
            [-39.3583114082, -4.9180548062],
            [-39.3601410742, -4.9124413082],
            [-39.3538914935, -4.9076669688],
            [-39.3515046093, -4.8973681698],
            [-39.3467564337, -4.8951478349],
            [-39.3530627481, -4.8791726667],
            [-39.3538887593, -4.8593481412],
            [-39.3606384845, -4.856517188],
            [-39.3574765687, -4.8535899753],
            [-39.3644456875, -4.8491823011],
            [-39.3626139353, -4.846410065],
            [-39.3654500085, -4.8408625809],
            [-39.357481239, -4.8371403066],
            [-39.3483934682, -4.8221913576],
            [-39.3448906916, -4.8120001664],
            [-39.3471996411, -4.803091591],
            [-39.342872443, -4.7936666607],
            [-39.33906045, -4.7905115185],
            [-39.3398084635, -4.7967779595],
            [-39.34308348, -4.79904356],
            [-39.3399091293, -4.809227597],
            [-39.3423836829, -4.8163858768],
            [-39.3312557959, -4.8339449539],
            [-39.3264010792, -4.834925684],
            [-39.3088095408, -4.8389825841],
            [-39.303147334, -4.8492119256],
            [-39.2971125963, -4.8586429981],
            [-39.2982796946, -4.869492155],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311504',
        name: 'Quixeré',
        description: 'Quixeré',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.8942114062, -4.9755315054],
            [-37.8865926317, -4.9844090733],
            [-37.8622399709, -4.9775794896],
            [-37.8510378746, -4.9840867686],
            [-37.8443588901, -4.991809505],
            [-37.8340286182, -4.9948542725],
            [-37.828042826, -4.9865027319],
            [-37.8203030346, -4.9837724133],
            [-37.8140201567, -4.9878174255],
            [-37.7607055794, -5.0079220349],
            [-37.6767975772, -5.0521466735],
            [-37.6772764001, -5.0536978488],
            [-37.7101471861, -5.0607620744],
            [-37.7236455003, -5.0693158169],
            [-37.7312827951, -5.0841388363],
            [-37.7330779753, -5.1002529778],
            [-37.73994665, -5.1100292136],
            [-37.7646108168, -5.1392276682],
            [-37.7759649236, -5.1867309671],
            [-37.7896838645, -5.2105512596],
            [-37.7878169316, -5.2188083148],
            [-37.7830814794, -5.2286552508],
            [-37.7831751931, -5.2424396538],
            [-37.8237526365, -5.2240308294],
            [-37.8271864158, -5.2224725453],
            [-37.8938518993, -5.194306613],
            [-37.9227932127, -5.1750991729],
            [-37.9456566981, -5.1345787694],
            [-37.969505286, -5.1030795015],
            [-37.9718013475, -5.1000633507],
            [-37.995121749, -5.0841293037],
            [-37.9985761731, -5.0817687862],
            [-37.9989743921, -5.0814683421],
            [-38.0022585185, -5.0789904703],
            [-38.0392481715, -5.0782847459],
            [-38.0450317031, -5.0799237473],
            [-38.0419360993, -5.0893002894],
            [-38.056578465, -5.0829714678],
            [-38.0616084353, -5.0807963475],
            [-38.0623652156, -5.0803344774],
            [-38.0613002927, -5.0796002288],
            [-38.0329818141, -5.0379686481],
            [-38.0176633569, -5.0243454394],
            [-38.0135774196, -5.0263561611],
            [-38.0086508613, -5.0168538955],
            [-37.9938672641, -5.0125066247],
            [-37.9665116082, -5.0069718857],
            [-37.9491109012, -5.0077383027],
            [-37.9457698879, -5.0030157137],
            [-37.9362593395, -5.0024719173],
            [-37.9273134125, -4.9975678535],
            [-37.916842032, -4.9875115943],
            [-37.900402784, -4.9765656545],
            [-37.8989108347, -4.9694077238],
            [-37.8936051194, -4.9643576931],
            [-37.8942114062, -4.9755315054],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311603',
        name: 'Redenção',
        description: 'Redenção',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.7827420731, -4.1554889786],
            [-38.7770134249, -4.1699791079],
            [-38.7743926931, -4.1688441164],
            [-38.7630779132, -4.1705416411],
            [-38.7601193952, -4.1784877865],
            [-38.749844134, -4.1802669705],
            [-38.7390312782, -4.1763879436],
            [-38.7329539012, -4.1779907821],
            [-38.7327071373, -4.1702894314],
            [-38.729588354, -4.1694709467],
            [-38.7176272799, -4.2152984709],
            [-38.7156325402, -4.2215628152],
            [-38.7116290975, -4.234125839],
            [-38.7167369062, -4.236301158],
            [-38.7165663324, -4.2422744397],
            [-38.7233291184, -4.2443174303],
            [-38.7284790846, -4.2795417989],
            [-38.7369178392, -4.2929803037],
            [-38.7368094063, -4.2929956765],
            [-38.7093928996, -4.2908241401],
            [-38.6970700215, -4.2817921612],
            [-38.6840624465, -4.2778956373],
            [-38.6838870334, -4.2777490325],
            [-38.6836046488, -4.280159002],
            [-38.7002712271, -4.3303445629],
            [-38.6970413362, -4.3544382735],
            [-38.6951763694, -4.3683405636],
            [-38.6936325085, -4.3798430323],
            [-38.7134380267, -4.3836153461],
            [-38.7133910334, -4.3806317997],
            [-38.7244054881, -4.366459573],
            [-38.7303904606, -4.3658113076],
            [-38.7315893313, -4.3581633259],
            [-38.7451868462, -4.3517321149],
            [-38.753669752, -4.3276784983],
            [-38.7555659343, -4.3270436584],
            [-38.759995032, -4.3076948889],
            [-38.7645990003, -4.3052288569],
            [-38.7672617298, -4.3042879989],
            [-38.7957453073, -4.2942153978],
            [-38.796028299, -4.2904744375],
            [-38.7801717349, -4.277824555],
            [-38.7825183865, -4.2715069079],
            [-38.7948552965, -4.2638619528],
            [-38.7961405836, -4.270325674],
            [-38.8011813691, -4.2719433955],
            [-38.8040775912, -4.2667722705],
            [-38.8024718339, -4.2580436724],
            [-38.8059037404, -4.2593368119],
            [-38.8068725414, -4.2506101108],
            [-38.8310034153, -4.2506145367],
            [-38.8402603327, -4.2457152957],
            [-38.8432334834, -4.2384417324],
            [-38.8410880242, -4.2326247923],
            [-38.8486606102, -4.230933032],
            [-38.8493868455, -4.2297504129],
            [-38.8440259924, -4.2241942391],
            [-38.8540248516, -4.1835785397],
            [-38.8555853497, -4.1772386514],
            [-38.8556548418, -4.1769568015],
            [-38.8517614749, -4.1756649819],
            [-38.8163348565, -4.1639058815],
            [-38.8131879892, -4.1628615509],
            [-38.8034126729, -4.159617571],
            [-38.806409744, -4.1518930236],
            [-38.8041646886, -4.1454556533],
            [-38.7939065728, -4.1433619699],
            [-38.7908263032, -4.142828529],
            [-38.7906637682, -4.1518233006],
            [-38.7827420731, -4.1554889786],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311702',
        name: 'Reriutaba',
        description: 'Reriutaba',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.517894903, -4.1664354327],
            [-40.5267370413, -4.1710178198],
            [-40.5387086036, -4.1692128808],
            [-40.5390027159, -4.172409124],
            [-40.5433566511, -4.171456472],
            [-40.5527675209, -4.1765988301],
            [-40.5621462733, -4.1817242049],
            [-40.6041224086, -4.2046651555],
            [-40.6089438913, -4.2073007629],
            [-40.6141624836, -4.2076153447],
            [-40.6819730061, -4.2117024651],
            [-40.7287533278, -4.2013188567],
            [-40.7269257542, -4.1953326873],
            [-40.727341782, -4.1917230898],
            [-40.7169089678, -4.187436329],
            [-40.7240742908, -4.1827529461],
            [-40.7153606895, -4.1815405682],
            [-40.7142644555, -4.1776991719],
            [-40.7133028456, -4.1655591372],
            [-40.7097171001, -4.1630595988],
            [-40.7153665644, -4.1598066784],
            [-40.7120283684, -4.1569977428],
            [-40.7156679478, -4.1533267587],
            [-40.7123419179, -4.1515996395],
            [-40.7175441693, -4.139606511],
            [-40.6918789327, -4.0796990338],
            [-40.6905249585, -4.0765394209],
            [-40.6822218559, -4.0571674168],
            [-40.6782772388, -4.053202922],
            [-40.6795785296, -4.0449884312],
            [-40.672259798, -4.0389720556],
            [-40.6686097836, -4.0327135239],
            [-40.671158153, -4.0279604586],
            [-40.6670942265, -4.0254193873],
            [-40.6547923193, -4.0084930426],
            [-40.6057413451, -4.0305285015],
            [-40.5845940238, -4.0400238544],
            [-40.5672455628, -4.0478119824],
            [-40.5524765542, -4.0477507703],
            [-40.511970134, -4.0475789194],
            [-40.4976984457, -4.0613632302],
            [-40.4723680933, -4.0861073799],
            [-40.5355519063, -4.0976688904],
            [-40.5338669347, -4.0989737112],
            [-40.5308079618, -4.1060020515],
            [-40.5259312512, -4.1084872398],
            [-40.5234971407, -4.121815358],
            [-40.5143285653, -4.1343284312],
            [-40.5169388262, -4.1407185884],
            [-40.5145787654, -4.148914791],
            [-40.5188103927, -4.159713912],
            [-40.517894903, -4.1664354327],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311801',
        name: 'Russas',
        description: 'Russas',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.144889255, -4.6678806652],
            [-38.1229677382, -4.6847596732],
            [-38.0957012117, -4.7038008753],
            [-38.0843661432, -4.7319998349],
            [-38.0702231957, -4.748825615],
            [-38.0616027628, -4.752346326],
            [-38.0587272026, -4.7600772028],
            [-38.0527353217, -4.7690209555],
            [-38.0495421711, -4.7731877904],
            [-38.0479508098, -4.7789656495],
            [-38.0580047691, -4.7916743937],
            [-38.0607026788, -4.8111707496],
            [-38.0431314303, -4.8404275515],
            [-37.9315721806, -4.8242479751],
            [-37.8985780328, -4.8200178945],
            [-37.8983272733, -4.8204316162],
            [-37.8897465685, -4.8345754738],
            [-37.8823718981, -4.849166084],
            [-37.8949848595, -4.876648178],
            [-37.8950730359, -4.8768407848],
            [-37.8978076156, -4.8828003867],
            [-37.906668416, -4.9025367505],
            [-37.8993023455, -4.9145848277],
            [-37.8885024981, -4.932220363],
            [-37.8869924218, -4.9333624761],
            [-37.8203030346, -4.9837724133],
            [-37.828042826, -4.9865027319],
            [-37.8340286182, -4.9948542725],
            [-37.8443588901, -4.991809505],
            [-37.8510378746, -4.9840867686],
            [-37.8622399709, -4.9775794896],
            [-37.8865926317, -4.9844090733],
            [-37.8942114062, -4.9755315054],
            [-37.8936051194, -4.9643576931],
            [-37.8989108347, -4.9694077238],
            [-37.900402784, -4.9765656545],
            [-37.916842032, -4.9875115943],
            [-37.9273134125, -4.9975678535],
            [-37.9362593395, -5.0024719173],
            [-37.9457698879, -5.0030157137],
            [-37.9491109012, -5.0077383027],
            [-37.9665116082, -5.0069718857],
            [-37.9938672641, -5.0125066247],
            [-38.0086508613, -5.0168538955],
            [-38.0135774196, -5.0263561611],
            [-38.0176633569, -5.0243454394],
            [-38.0329818141, -5.0379686481],
            [-38.0613002927, -5.0796002288],
            [-38.0623652156, -5.0803344774],
            [-38.0786960998, -5.0703241513],
            [-38.1002490743, -5.052485241],
            [-38.1003148459, -5.0524162376],
            [-38.1208889593, -5.0307685225],
            [-38.1703006343, -4.99091586],
            [-38.1938191384, -4.9698260139],
            [-38.1992695983, -4.974399719],
            [-38.2125434519, -4.9740692821],
            [-38.213615611, -4.9740426177],
            [-38.2319393897, -4.9389497858],
            [-38.2601569656, -4.8856151677],
            [-38.2609031353, -4.8842042403],
            [-38.267500528, -4.8772664579],
            [-38.2833085955, -4.8787999807],
            [-38.290340291, -4.8693932678],
            [-38.300007526, -4.8682524524],
            [-38.3110575021, -4.8571743583],
            [-38.3197796128, -4.8578136662],
            [-38.3253056041, -4.8548064033],
            [-38.3376196336, -4.8606975627],
            [-38.3564214365, -4.8508544881],
            [-38.3613072535, -4.8466817193],
            [-38.3717835614, -4.8543457269],
            [-38.3785308628, -4.8512336756],
            [-38.3959132804, -4.857318449],
            [-38.4153163803, -4.8632264118],
            [-38.4296412773, -4.8570460451],
            [-38.4304077657, -4.8495600739],
            [-38.4302639728, -4.8481558946],
            [-38.4354779795, -4.8337913937],
            [-38.4468885352, -4.8324780788],
            [-38.458572989, -4.8211922693],
            [-38.4596746349, -4.8206608186],
            [-38.4684731095, -4.816415618],
            [-38.45886392, -4.8103131154],
            [-38.4528196104, -4.7992537913],
            [-38.4407217504, -4.790269393],
            [-38.421597465, -4.7680917741],
            [-38.4207406313, -4.7576264689],
            [-38.4113362609, -4.762843122],
            [-38.4083391791, -4.7567477954],
            [-38.4075247368, -4.7565250361],
            [-38.390487894, -4.74587522],
            [-38.3721741613, -4.7217337312],
            [-38.3706073724, -4.7194863416],
            [-38.3624181383, -4.7130863867],
            [-38.3606708136, -4.7043472139],
            [-38.363418566, -4.7017129407],
            [-38.3290665863, -4.6740100463],
            [-38.3238378952, -4.6726208536],
            [-38.3032276341, -4.680556104],
            [-38.2925568172, -4.6745350258],
            [-38.2883348861, -4.6654124535],
            [-38.2856260359, -4.6628338184],
            [-38.2758385483, -4.6536860578],
            [-38.2711005789, -4.6487610209],
            [-38.2553218365, -4.6325379438],
            [-38.2437027905, -4.6210914933],
            [-38.2154438314, -4.5892110503],
            [-38.2092025319, -4.5945278731],
            [-38.1984197779, -4.6055785162],
            [-38.1836445919, -4.6220994777],
            [-38.1667255777, -4.6374694901],
            [-38.1556350602, -4.6534850121],
            [-38.144889255, -4.6678806652],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311900',
        name: 'Saboeiro',
        description: 'Saboeiro',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.7592527553, -6.2381197812],
            [-39.7556437083, -6.2401041627],
            [-39.7505450489, -6.2269891018],
            [-39.7356510958, -6.2223523959],
            [-39.7336306999, -6.2244248125],
            [-39.7346256934, -6.2114863406],
            [-39.7174399386, -6.210818929],
            [-39.7170146081, -6.2141544236],
            [-39.7181345093, -6.2247221221],
            [-39.7218830465, -6.2346210625],
            [-39.7337020133, -6.2421939148],
            [-39.7343109747, -6.2561012087],
            [-39.7295069272, -6.2621470575],
            [-39.7283275174, -6.274061497],
            [-39.7249653844, -6.2687243071],
            [-39.7219229222, -6.2701733988],
            [-39.7196508974, -6.2712558355],
            [-39.709677971, -6.3505935248],
            [-39.7143647962, -6.3533049358],
            [-39.7141471563, -6.3588491299],
            [-39.7196628437, -6.3594523798],
            [-39.7272018213, -6.3669018307],
            [-39.7270294954, -6.3704160769],
            [-39.7395041321, -6.3749612382],
            [-39.7433033866, -6.3858337445],
            [-39.7507212807, -6.3886667071],
            [-39.7581401021, -6.3994064303],
            [-39.7659035829, -6.4014381103],
            [-39.776336876, -6.4124125832],
            [-39.7757490993, -6.4296182858],
            [-39.7666900771, -6.441986832],
            [-39.7739170333, -6.4603341973],
            [-39.7599128547, -6.4744865211],
            [-39.7398591931, -6.4818257789],
            [-39.7378636855, -6.4863786653],
            [-39.7247658419, -6.4951272361],
            [-39.7445562465, -6.5043934169],
            [-39.7503726367, -6.5113778408],
            [-39.7495956267, -6.5206743746],
            [-39.7419068588, -6.5295344665],
            [-39.7388083098, -6.5293686211],
            [-39.7354107162, -6.5364179236],
            [-39.7292163944, -6.5391750622],
            [-39.7373101485, -6.5497595206],
            [-39.7413853778, -6.5618204344],
            [-39.7498650149, -6.5648347621],
            [-39.7511925535, -6.5814088866],
            [-39.770420994, -6.585942855],
            [-39.7636237171, -6.5963548209],
            [-39.7749484079, -6.6087847036],
            [-39.7703059088, -6.6118789388],
            [-39.7775328532, -6.6197316789],
            [-39.7747254548, -6.6244128138],
            [-39.7335290237, -6.5996222402],
            [-39.7293902229, -6.612460275],
            [-39.7209706991, -6.613227176],
            [-39.7191805878, -6.6181573489],
            [-39.7381137911, -6.6110092072],
            [-39.7629856934, -6.6249596847],
            [-39.7737422202, -6.6275628924],
            [-39.7719359902, -6.6335137084],
            [-39.7781260624, -6.6424670652],
            [-39.7752012022, -6.656676156],
            [-39.7831315113, -6.6687668956],
            [-39.7952207138, -6.6724387976],
            [-39.8103337845, -6.6877077989],
            [-39.832276022, -6.6994593776],
            [-39.8363205688, -6.6954935286],
            [-39.8335768863, -6.6902429186],
            [-39.8379621539, -6.6790702988],
            [-39.8476523569, -6.6797190395],
            [-39.8486602164, -6.6749988318],
            [-39.8525774708, -6.6741482963],
            [-39.8582453784, -6.67864461],
            [-39.8644149751, -6.6797604217],
            [-39.8659316075, -6.6761683009],
            [-39.8763145824, -6.6804439232],
            [-39.874191224, -6.670168018],
            [-39.8824870522, -6.6754303159],
            [-39.9022304392, -6.6871568336],
            [-39.9126811271, -6.6877039051],
            [-39.9221431891, -6.6967558615],
            [-39.9293280905, -6.6975064025],
            [-39.9400823956, -6.7120012656],
            [-39.9566929252, -6.7011778069],
            [-39.9627133153, -6.7012184912],
            [-39.9627786113, -6.6942930085],
            [-39.96848694, -6.6949485003],
            [-39.9710304711, -6.6869249075],
            [-39.9815571416, -6.6859486179],
            [-39.9756414723, -6.6799457172],
            [-39.9756183226, -6.6685864429],
            [-39.9853658232, -6.6719064993],
            [-39.9884341023, -6.6811620237],
            [-40.004850882, -6.6864177427],
            [-40.0082513989, -6.6868121469],
            [-40.0137160197, -6.6773308655],
            [-40.0210490322, -6.6783676978],
            [-40.0210777901, -6.6742784504],
            [-40.0289788122, -6.6761030997],
            [-40.0296146376, -6.6715726452],
            [-40.0400606957, -6.6727729735],
            [-40.0465625598, -6.6822855723],
            [-40.0516512393, -6.679553907],
            [-40.0484879733, -6.6740776201],
            [-40.0564985886, -6.6740201119],
            [-40.0365675564, -6.637188553],
            [-40.0399998673, -6.6272003408],
            [-40.0544227478, -6.6261372189],
            [-40.0574519791, -6.6216362907],
            [-40.0518756508, -6.6063260928],
            [-40.0404540045, -6.6007149109],
            [-40.043914787, -6.5939112638],
            [-40.0438582673, -6.5883115286],
            [-40.0342889538, -6.5869963263],
            [-40.0257143538, -6.5813175463],
            [-40.0301022554, -6.5627970605],
            [-40.0348163897, -6.5583196081],
            [-40.0312094796, -6.5427121327],
            [-40.0405920419, -6.5384269313],
            [-40.0413875614, -6.5313090853],
            [-40.0515231598, -6.5215680688],
            [-40.0497915133, -6.5146860599],
            [-40.0587759229, -6.5069868829],
            [-40.0597656363, -6.499928985],
            [-40.0550517294, -6.4953248101],
            [-40.0593339411, -6.4901098221],
            [-40.0545624721, -6.4871360937],
            [-40.0562099881, -6.4778823211],
            [-40.047708424, -6.4708200407],
            [-40.0519576768, -6.4671153672],
            [-40.0483840859, -6.4604334296],
            [-40.0493154843, -6.4530833244],
            [-40.0442946196, -6.4440364601],
            [-40.0381876938, -6.4399175948],
            [-40.0294589662, -6.4425209932],
            [-40.0282293352, -6.4378105286],
            [-40.0468810193, -6.4235588865],
            [-40.0596548228, -6.4184128639],
            [-40.0668871046, -6.4060387383],
            [-40.0747472367, -6.4013784308],
            [-40.0837214889, -6.3794072582],
            [-40.0847401575, -6.3682019186],
            [-40.0755428272, -6.3687615474],
            [-40.0668116853, -6.3621292545],
            [-40.0619454205, -6.3664718425],
            [-40.0565415869, -6.3636614829],
            [-40.049053962, -6.3569268483],
            [-40.0474829315, -6.3495724752],
            [-40.0395879976, -6.3452563204],
            [-40.0411293289, -6.3388052039],
            [-40.0245075172, -6.3289624443],
            [-40.0156957938, -6.3338154054],
            [-40.0138204314, -6.3304963585],
            [-40.0028282918, -6.3342454493],
            [-39.9894279997, -6.32731722],
            [-39.979469482, -6.3318771195],
            [-39.974402236, -6.3384306523],
            [-39.9557060672, -6.3310083445],
            [-39.9465969471, -6.331013189],
            [-39.9246919508, -6.3403347599],
            [-39.9210785934, -6.3500140964],
            [-39.9244036665, -6.3518232208],
            [-39.9125720055, -6.3575192614],
            [-39.9069821498, -6.3616296378],
            [-39.8946355082, -6.3621535152],
            [-39.8946447271, -6.3678967195],
            [-39.8864340459, -6.3613618363],
            [-39.8795881244, -6.3633886522],
            [-39.8641116429, -6.356563024],
            [-39.8526976189, -6.3576903018],
            [-39.8452089646, -6.3473238621],
            [-39.8474786637, -6.3392585947],
            [-39.8367622056, -6.323151972],
            [-39.8304049284, -6.3071379474],
            [-39.8324695269, -6.2994766144],
            [-39.8381728903, -6.2966463701],
            [-39.8286482319, -6.2879626016],
            [-39.8211407139, -6.2880073041],
            [-39.8221648712, -6.2793391262],
            [-39.8168103034, -6.2630479902],
            [-39.8105033139, -6.2664183367],
            [-39.8059133903, -6.2603946657],
            [-39.809361187, -6.2521863738],
            [-39.8067125117, -6.2512997534],
            [-39.8080282386, -6.2456619023],
            [-39.7964174101, -6.2415291361],
            [-39.7888134424, -6.2243434468],
            [-39.7869151204, -6.2169271978],
            [-39.7929058443, -6.2073309542],
            [-39.7701644956, -6.204430975],
            [-39.7631142968, -6.2096691243],
            [-39.7630283583, -6.2256756962],
            [-39.7592527553, -6.2381197812],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311959',
        name: 'Salitre',
        description: 'Salitre',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.1198936082, -7.0150612739],
            [-40.1220099677, -7.0224832532],
            [-40.1231955989, -7.0184543526],
            [-40.1290828312, -7.0204625448],
            [-40.1432319172, -7.0276742726],
            [-40.1434021473, -7.0312413935],
            [-40.1664912548, -7.039534537],
            [-40.1647394999, -7.0439285754],
            [-40.1680277365, -7.0589751366],
            [-40.1772802331, -7.0673255189],
            [-40.1744615051, -7.0767559258],
            [-40.1767039973, -7.0791331067],
            [-40.182686891, -7.0853165772],
            [-40.2063186954, -7.0942216951],
            [-40.2104472827, -7.0987887947],
            [-40.2149650718, -7.0977532187],
            [-40.2196656172, -7.1038566034],
            [-40.2253543661, -7.1045239637],
            [-40.2268062637, -7.1102162681],
            [-40.2400214581, -7.1120280986],
            [-40.2423783701, -7.1290976304],
            [-40.2608228752, -7.1423953891],
            [-40.2680531269, -7.1789915823],
            [-40.2669087025, -7.1855807077],
            [-40.2517845864, -7.2025416937],
            [-40.2488475325, -7.2238759637],
            [-40.2585126592, -7.2367976833],
            [-40.2585858668, -7.2368913066],
            [-40.266386337, -7.2480570565],
            [-40.2664504032, -7.2530233962],
            [-40.2471049944, -7.3053242866],
            [-40.2471657343, -7.3133149716],
            [-40.2626277828, -7.3006173847],
            [-40.278635325, -7.298987529],
            [-40.3177762636, -7.3029957899],
            [-40.3290259711, -7.3181542259],
            [-40.3376469122, -7.3233904283],
            [-40.350424996, -7.3337676702],
            [-40.3606152766, -7.3551969108],
            [-40.3741534447, -7.3625837763],
            [-40.3935091458, -7.3677644378],
            [-40.4332223763, -7.3667743202],
            [-40.4587035274, -7.3546899513],
            [-40.475877824, -7.3443232524],
            [-40.5000987455, -7.3275917749],
            [-40.5233522768, -7.318046105],
            [-40.5138987359, -7.3074287364],
            [-40.511261397, -7.2990272725],
            [-40.5149076133, -7.2747664066],
            [-40.5151675915, -7.2694312878],
            [-40.5098985175, -7.2239527399],
            [-40.5057466873, -7.2104569701],
            [-40.5009271035, -7.1882502771],
            [-40.4944222267, -7.1819416428],
            [-40.495340578, -7.1692156697],
            [-40.4840001585, -7.1327965768],
            [-40.4893697046, -7.118288134],
            [-40.4700636459, -7.0935685208],
            [-40.469051816, -7.087242352],
            [-40.4590271109, -7.0781338575],
            [-40.4411547936, -7.0796087651],
            [-40.447201681, -7.0885099989],
            [-40.3701037748, -7.1151984609],
            [-40.3553203359, -7.1203102437],
            [-40.3537242285, -7.120863651],
            [-40.3349234288, -7.1273641501],
            [-40.3219517217, -7.1278126826],
            [-40.323589454, -7.1153846044],
            [-40.3200613937, -7.1090694718],
            [-40.3245869635, -7.0994708344],
            [-40.3215150588, -7.0901725212],
            [-40.3232879932, -7.0816780987],
            [-40.3210570411, -7.0807011022],
            [-40.3183970504, -7.0795367719],
            [-40.2983069354, -7.0779493169],
            [-40.2811135665, -7.0691144385],
            [-40.2576257293, -7.0705848571],
            [-40.2484436725, -7.0640276714],
            [-40.2498397554, -7.0581697253],
            [-40.2396594528, -7.0471396292],
            [-40.2115909533, -7.0288078162],
            [-40.1880371312, -7.022730272],
            [-40.16886163, -7.0154769178],
            [-40.1554432064, -7.0149307041],
            [-40.133116349, -7.0014907766],
            [-40.1198936082, -7.0150612739],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2312007',
        name: 'Santana do Acaraú',
        description: 'Santana do Acaraú',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.1161387394, -3.3140616302],
            [-40.101926899, -3.3187876065],
            [-40.1013907135, -3.3187767417],
            [-40.0917122289, -3.3157503773],
            [-40.0915562893, -3.3157684444],
            [-40.0828281184, -3.3146368338],
            [-40.0924286137, -3.3568062328],
            [-40.081485436, -3.3561791202],
            [-40.0711946196, -3.3668210257],
            [-40.0612583219, -3.3688678445],
            [-40.0460064119, -3.3637540371],
            [-40.0286486227, -3.3626774945],
            [-40.0000840765, -3.3611544003],
            [-39.9884726931, -3.3661823812],
            [-39.9885880046, -3.3811457137],
            [-39.9932235179, -3.3890822687],
            [-39.9973107443, -3.4097309468],
            [-40.0016926991, -3.4116283961],
            [-40.0071685638, -3.4242127952],
            [-40.0254328544, -3.4324460233],
            [-40.0271025775, -3.4377725805],
            [-40.0269976745, -3.4433197533],
            [-40.025264614, -3.4477704205],
            [-40.0376136194, -3.461315288],
            [-40.0412813018, -3.4741917994],
            [-40.0366680048, -3.4961339188],
            [-40.041002097, -3.5041672306],
            [-40.041336064, -3.5177759226],
            [-40.0457816118, -3.5225221399],
            [-40.0454746055, -3.5309318356],
            [-40.0490583195, -3.5338036943],
            [-40.0448485403, -3.5596959823],
            [-40.0433821509, -3.5631576892],
            [-40.0421171997, -3.5662183149],
            [-40.0516974611, -3.5818203586],
            [-40.0703240848, -3.5938566871],
            [-40.0704839224, -3.594056705],
            [-40.0707580254, -3.5942022047],
            [-40.0795933762, -3.6030915917],
            [-40.1199400682, -3.638889869],
            [-40.1127556202, -3.6492395357],
            [-40.1118680496, -3.6679869174],
            [-40.1128417627, -3.6879187039],
            [-40.1167668357, -3.6905429913],
            [-40.1188079727, -3.691041468],
            [-40.1335366451, -3.6843076163],
            [-40.1404504663, -3.6746664012],
            [-40.1527484381, -3.6681039568],
            [-40.1608856386, -3.667138865],
            [-40.1764920668, -3.6754273592],
            [-40.1772515574, -3.6701098109],
            [-40.1845399277, -3.6693100853],
            [-40.1796749275, -3.6533594465],
            [-40.196301138, -3.6455645718],
            [-40.207839961, -3.636703729],
            [-40.212947949, -3.6259243322],
            [-40.2043568911, -3.6131679027],
            [-40.1994223145, -3.6075930596],
            [-40.2051685719, -3.6027642075],
            [-40.2194254798, -3.5993104217],
            [-40.2246034108, -3.5913592289],
            [-40.2306688667, -3.578598621],
            [-40.236834625, -3.581044643],
            [-40.2416670122, -3.5787148395],
            [-40.2459218962, -3.5821578743],
            [-40.2571698888, -3.5804079488],
            [-40.2703087417, -3.5717306382],
            [-40.2550270505, -3.5572651131],
            [-40.25224931, -3.5246892513],
            [-40.256432429, -3.5182822273],
            [-40.2569757928, -3.5179401706],
            [-40.2561905641, -3.4981956687],
            [-40.2518422336, -3.4892806195],
            [-40.2586438506, -3.4937879126],
            [-40.2614142388, -3.4848520471],
            [-40.2694971054, -3.4828194254],
            [-40.2934530924, -3.4373969866],
            [-40.3053947188, -3.4141415169],
            [-40.3080958578, -3.4088807756],
            [-40.3336114172, -3.403801324],
            [-40.3364076584, -3.4001634924],
            [-40.3402658347, -3.3583570083],
            [-40.3407410309, -3.3532034568],
            [-40.3409283475, -3.3195470935],
            [-40.3258506304, -3.3181934783],
            [-40.3246220101, -3.3180836883],
            [-40.2999167735, -3.3158657881],
            [-40.299617486, -3.3158388844],
            [-40.2742337658, -3.3135606587],
            [-40.2651158585, -3.3127419265],
            [-40.2568886225, -3.3120037965],
            [-40.2017186286, -3.3070502969],
            [-40.2000564902, -3.3047541708],
            [-40.1976496278, -3.3072850951],
            [-40.1855678908, -3.3170812874],
            [-40.1737525442, -3.3196637093],
            [-40.1640047016, -3.3159279098],
            [-40.1585287685, -3.3099597893],
            [-40.1455378459, -3.3032254147],
            [-40.1317552083, -3.3137663972],
            [-40.1161387394, -3.3140616302],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2312106',
        name: 'Santana do Cariri',
        description: 'Santana do Cariri',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.746912659, -7.0231994351],
            [-39.7569009173, -7.0449777745],
            [-39.7541012583, -7.053045733],
            [-39.7559847389, -7.0585321379],
            [-39.7452399894, -7.0706808293],
            [-39.7487513889, -7.0798063862],
            [-39.7459297021, -7.0815069171],
            [-39.74370188, -7.0945844365],
            [-39.7420655319, -7.0969160303],
            [-39.7152177832, -7.1351453737],
            [-39.7111086451, -7.1405193383],
            [-39.7046048465, -7.1568382639],
            [-39.6929208272, -7.1702907909],
            [-39.6650538615, -7.179832018],
            [-39.6272894216, -7.1943823788],
            [-39.6057985596, -7.2100988365],
            [-39.5987401406, -7.2095323105],
            [-39.5987400167, -7.2278520493],
            [-39.5987043059, -7.3419361299],
            [-39.5987035134, -7.3435046086],
            [-39.5986407538, -7.4283684621],
            [-39.6083258705, -7.423321635],
            [-39.6285446808, -7.4252235056],
            [-39.6392567563, -7.3953999045],
            [-39.6435321651, -7.384936975],
            [-39.652725755, -7.3718575898],
            [-39.6971490045, -7.3417613174],
            [-39.7151335056, -7.3345205709],
            [-39.7409587157, -7.3264124428],
            [-39.7709414327, -7.3270729197],
            [-39.7888631699, -7.3315909316],
            [-39.8060983135, -7.3299981559],
            [-39.8150034985, -7.3282900868],
            [-39.8283316298, -7.3319660536],
            [-39.8510837458, -7.3248058359],
            [-39.8635887892, -7.3272880063],
            [-39.8897659426, -7.3394246161],
            [-39.8896556253, -7.3219521704],
            [-39.8889410406, -7.2130346142],
            [-39.8886941445, -7.1771232567],
            [-39.8869951228, -7.1406369075],
            [-39.8907525451, -7.1259490797],
            [-39.8948225048, -7.1224116961],
            [-39.8974971126, -7.0971448694],
            [-39.8973649922, -7.0968792479],
            [-39.8944739489, -7.0924861028],
            [-39.8829627391, -7.0886960298],
            [-39.8904999568, -7.0704717359],
            [-39.8890096875, -7.0528343023],
            [-39.8808964977, -7.0597930843],
            [-39.8721351557, -7.0663493237],
            [-39.862654957, -7.0523133964],
            [-39.8322131489, -7.0457301174],
            [-39.7889440351, -7.0298565208],
            [-39.78356145, -7.0324070748],
            [-39.7895349208, -7.0346565445],
            [-39.7899665078, -7.0421337989],
            [-39.7862437938, -7.0475861311],
            [-39.7828809345, -7.0607827604],
            [-39.7718133924, -7.0605176224],
            [-39.7643683722, -7.0483918526],
            [-39.7680039172, -7.045139122],
            [-39.7656560071, -7.0341325643],
            [-39.7610002822, -7.0365380829],
            [-39.7472082174, -7.0231291577],
            [-39.746912659, -7.0231994351],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2312205',
        name: 'Santa Quitéria',
        description: 'Santa Quitéria',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.1395483526, -4.0242747764],
            [-40.1398623219, -4.0319167223],
            [-40.1319107005, -4.0315360627],
            [-40.1309549283, -4.03639063],
            [-40.1228402615, -4.0358532808],
            [-40.1184314822, -4.0415735667],
            [-40.1098358101, -4.0408812096],
            [-40.105614628, -4.0484895877],
            [-40.1027829617, -4.0520066249],
            [-40.0978762133, -4.0469247605],
            [-40.0888027341, -4.0490511338],
            [-40.0817483824, -4.0593933045],
            [-40.0680208177, -4.0572892221],
            [-40.0589507845, -4.0578107112],
            [-40.0534771554, -4.0619359136],
            [-40.0565334396, -4.0649053479],
            [-40.053410161, -4.0714082054],
            [-40.0428146903, -4.0778400189],
            [-40.0334166485, -4.07855688],
            [-40.0138297039, -4.0889137637],
            [-40.0004096724, -4.0832164664],
            [-39.9967178715, -4.0866656904],
            [-39.9892468215, -4.0862841109],
            [-39.986925032, -4.0924707444],
            [-39.9939207799, -4.0980195386],
            [-39.9912764902, -4.1035824686],
            [-39.9876741221, -4.1072452605],
            [-39.9682260565, -4.1058811595],
            [-39.9728139875, -4.1140934316],
            [-39.9625861224, -4.1223450205],
            [-39.9549187327, -4.1295370531],
            [-39.9474444362, -4.126806854],
            [-39.9450391177, -4.1297831587],
            [-39.9399765371, -4.1279891612],
            [-39.9247155486, -4.1314512994],
            [-39.9190179254, -4.1370163753],
            [-39.9029503006, -4.1370344048],
            [-39.8937209326, -4.1440113425],
            [-39.8889746313, -4.1389290865],
            [-39.8815051569, -4.1394851969],
            [-39.8790904375, -4.1357303979],
            [-39.868967614, -4.1352714909],
            [-39.8610965465, -4.1390065611],
            [-39.8563567444, -4.136460636],
            [-39.8490282511, -4.1197940959],
            [-39.8430825064, -4.1182344096],
            [-39.8402638263, -4.113148138],
            [-39.8109460862, -4.1162329392],
            [-39.8103496628, -4.1202258072],
            [-39.8067770748, -4.125160331],
            [-39.8083137002, -4.1351010384],
            [-39.8053828579, -4.1393479963],
            [-39.8044123756, -4.1407534992],
            [-39.7974887115, -4.1536657937],
            [-39.7937117354, -4.1548439501],
            [-39.7950018159, -4.1592257342],
            [-39.7876186923, -4.1669055082],
            [-39.7878650214, -4.1719936774],
            [-39.7805556903, -4.1747412955],
            [-39.7735507467, -4.1733229756],
            [-39.7757243093, -4.1809752106],
            [-39.7877170755, -4.1885701776],
            [-39.7901179121, -4.1942860939],
            [-39.7835607045, -4.1982326607],
            [-39.7802814233, -4.2107566722],
            [-39.7724765039, -4.2221960252],
            [-39.7770029823, -4.2234347839],
            [-39.7777722876, -4.2307510996],
            [-39.7723597101, -4.245187519],
            [-39.7504205746, -4.254559735],
            [-39.745726612, -4.2599391254],
            [-39.730147416, -4.275294387],
            [-39.7380864843, -4.2824594958],
            [-39.7329656408, -4.2876372725],
            [-39.7387236015, -4.2948290678],
            [-39.7322355646, -4.3077365815],
            [-39.7263982572, -4.3074390089],
            [-39.7252019976, -4.3182543718],
            [-39.7329494862, -4.3178449444],
            [-39.7347386568, -4.3322970475],
            [-39.7256727341, -4.3442270022],
            [-39.7102271253, -4.3480666207],
            [-39.7107804059, -4.3534968394],
            [-39.7144707001, -4.3551104487],
            [-39.7099732029, -4.3695113337],
            [-39.7115063625, -4.3816894551],
            [-39.7257693601, -4.3839890803],
            [-39.727389593, -4.3934213323],
            [-39.7376414646, -4.4015458142],
            [-39.7455803174, -4.3976911869],
            [-39.7550263028, -4.399382635],
            [-39.7525326276, -4.4067778343],
            [-39.7382240731, -4.4233131537],
            [-39.7399628836, -4.4308973348],
            [-39.732048983, -4.4353608878],
            [-39.7368987412, -4.4378599286],
            [-39.732607083, -4.4393935919],
            [-39.7295007113, -4.4497710601],
            [-39.7340542544, -4.4531236595],
            [-39.7338368211, -4.4640766413],
            [-39.7256757078, -4.4694588737],
            [-39.7265214395, -4.473133836],
            [-39.7211969489, -4.4840685811],
            [-39.7561406994, -4.5029611993],
            [-39.7546462416, -4.5115422357],
            [-39.7652541213, -4.5240811372],
            [-39.7640846959, -4.5344470914],
            [-39.7566453021, -4.5350865108],
            [-39.7457821324, -4.5446677086],
            [-39.729007943, -4.5402690058],
            [-39.7072397273, -4.5427412264],
            [-39.6998079676, -4.5458595257],
            [-39.6955646956, -4.5564552192],
            [-39.6887280067, -4.5560627149],
            [-39.6893701306, -4.5652330887],
            [-39.6862515332, -4.5670146648],
            [-39.685229849, -4.5756694295],
            [-39.719755833, -4.5994473612],
            [-39.7416580525, -4.5998608652],
            [-39.7436907119, -4.6061591645],
            [-39.7544788364, -4.6106653753],
            [-39.7593240359, -4.619825603],
            [-39.7642253516, -4.6196289317],
            [-39.7623395639, -4.6284784267],
            [-39.776452972, -4.6260451328],
            [-39.7786144102, -4.6304324078],
            [-39.7732714917, -4.6431141379],
            [-39.7656926478, -4.6476309813],
            [-39.7561507672, -4.6464946245],
            [-39.7482460548, -4.6497481064],
            [-39.7441238799, -4.6457428957],
            [-39.7386415429, -4.6524967682],
            [-39.7154425007, -4.6547467816],
            [-39.7315319721, -4.665675073],
            [-39.7261063929, -4.667145407],
            [-39.728922774, -4.6710250122],
            [-39.7193888, -4.6743421948],
            [-39.7142238025, -4.6778507245],
            [-39.719129117, -4.6807093387],
            [-39.7206371117, -4.6861175904],
            [-39.7286771568, -4.6859171877],
            [-39.7263280925, -4.6898666207],
            [-39.7289410847, -4.6964532673],
            [-39.7406256863, -4.7203000269],
            [-39.8056670464, -4.7077213729],
            [-39.7924750088, -4.7529707705],
            [-39.7983349349, -4.7431392801],
            [-39.8117646249, -4.7389419515],
            [-39.8176233407, -4.73124702],
            [-39.8236635292, -4.730518391],
            [-39.8335014939, -4.7328526485],
            [-39.8348138909, -4.7359058056],
            [-39.839566543, -4.7346196406],
            [-39.8419495935, -4.7391686838],
            [-39.8597245152, -4.7372677246],
            [-39.8638832366, -4.7519564944],
            [-39.8739050038, -4.7618496219],
            [-39.8728970014, -4.7658332427],
            [-39.871238282, -4.7727401105],
            [-39.8783714434, -4.7767318995],
            [-39.8781612957, -4.7830367031],
            [-39.8843638749, -4.7895323062],
            [-39.9142770316, -4.7885049363],
            [-39.9161993667, -4.7821034129],
            [-39.9282201008, -4.7741557181],
            [-39.9245443581, -4.7348789161],
            [-39.9556412024, -4.7308657504],
            [-39.9949769693, -4.7257850198],
            [-40.0590854725, -4.7174973622],
            [-40.0667564288, -4.7080931347],
            [-40.0608615061, -4.700311001],
            [-40.0766550867, -4.7000800357],
            [-40.0781872972, -4.6966210535],
            [-40.0833813553, -4.6968175206],
            [-40.0808832081, -4.6922278229],
            [-40.0877079638, -4.6841153772],
            [-40.0801323593, -4.6838705248],
            [-40.0803863186, -4.6792762017],
            [-40.0917048541, -4.664085984],
            [-40.1038884678, -4.6608672694],
            [-40.1066719971, -4.6494262943],
            [-40.107043301, -4.644554286],
            [-40.1032784129, -4.6425483449],
            [-40.1076241778, -4.6422317152],
            [-40.105656718, -4.638054799],
            [-40.1075488814, -4.6273161263],
            [-40.1109350008, -4.6256602792],
            [-40.1120712233, -4.6119133151],
            [-40.1214763879, -4.6105393149],
            [-40.1115024624, -4.594998023],
            [-40.0893129913, -4.5880515631],
            [-40.0733430576, -4.5873572399],
            [-40.0573677647, -4.5680295552],
            [-40.0434469744, -4.5756027402],
            [-40.0313677184, -4.5670460902],
            [-40.0236161037, -4.5446318352],
            [-40.0074761273, -4.5224234707],
            [-40.0015300653, -4.5047811007],
            [-40.0055901129, -4.4977061992],
            [-40.0033139145, -4.4884692578],
            [-40.0367230387, -4.4568354935],
            [-40.0453690543, -4.4557995562],
            [-40.0461122574, -4.453141158],
            [-40.0497558658, -4.4562748725],
            [-40.0577830958, -4.4545166449],
            [-40.0682810663, -4.4709611078],
            [-40.0898225793, -4.4824648432],
            [-40.0980298492, -4.4845258207],
            [-40.1025814995, -4.492955937],
            [-40.0957834881, -4.4927728585],
            [-40.0915591972, -4.5011270989],
            [-40.1009113557, -4.5083410351],
            [-40.1116151202, -4.5107778792],
            [-40.1201221971, -4.5040119911],
            [-40.1354442886, -4.5117477174],
            [-40.1523083351, -4.5056374819],
            [-40.1694285438, -4.5075423876],
            [-40.1828420751, -4.5075716086],
            [-40.1837785438, -4.5029307239],
            [-40.1935371506, -4.4949973748],
            [-40.1996982726, -4.4729768932],
            [-40.2082231165, -4.4654339965],
            [-40.2541106529, -4.4726006722],
            [-40.2603618022, -4.4621251089],
            [-40.258388024, -4.4536623774],
            [-40.2615553445, -4.4434678587],
            [-40.2731132267, -4.434586189],
            [-40.2807782639, -4.4150894875],
            [-40.291921184, -4.4071987174],
            [-40.3000836901, -4.3959660108],
            [-40.3047013233, -4.3876528173],
            [-40.3057527711, -4.3756063589],
            [-40.3130810747, -4.3652276366],
            [-40.3119033115, -4.358687271],
            [-40.3201464255, -4.3544126982],
            [-40.3198678649, -4.3460897894],
            [-40.326032708, -4.3419670025],
            [-40.3218097125, -4.3117554048],
            [-40.314255509, -4.3015799971],
            [-40.3161687468, -4.2820900652],
            [-40.3023959814, -4.2668941705],
            [-40.3031714947, -4.2668548764],
            [-40.3278262631, -4.2656161166],
            [-40.3984854129, -4.2615438892],
            [-40.4481061039, -4.258286739],
            [-40.43924064, -4.2506239125],
            [-40.441755021, -4.2343486186],
            [-40.4503483027, -4.2171979615],
            [-40.4497911908, -4.2081228687],
            [-40.4497870728, -4.2080497741],
            [-40.4497577506, -4.2075662582],
            [-40.4422028924, -4.1834270095],
            [-40.4457583049, -4.1634803329],
            [-40.4325323599, -4.1579692928],
            [-40.4300870846, -4.1467652483],
            [-40.4363063626, -4.1381851309],
            [-40.4328679606, -4.1236206944],
            [-40.4363919954, -4.1145209974],
            [-40.4344502412, -4.0975394807],
            [-40.4321415496, -4.0932424916],
            [-40.4313904197, -4.0912946622],
            [-40.4312571056, -4.0912951012],
            [-40.3847825596, -4.0911936294],
            [-40.3745768842, -4.0911706],
            [-40.3607966706, -4.0319532091],
            [-40.360757204, -4.0317845825],
            [-40.3383819053, -3.9696555586],
            [-40.3051610132, -3.9336827585],
            [-40.226683403, -3.9496276481],
            [-40.2152549822, -3.951947894],
            [-40.1885554853, -3.9573677385],
            [-40.1898961758, -3.9634580926],
            [-40.1779117161, -3.9777725747],
            [-40.1694966196, -3.9847568778],
            [-40.1621557091, -4.0014308974],
            [-40.1414607754, -4.0220068929],
            [-40.1395483526, -4.0242747764],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2312304',
        name: 'São Benedito',
        description: 'São Benedito',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.8456402707, -3.9915242406],
            [-40.8470243172, -3.9962037771],
            [-40.8382251808, -3.9970519911],
            [-40.8363851388, -4.0034907246],
            [-40.8389760267, -4.007931843],
            [-40.8454521527, -4.0081657319],
            [-40.8492210663, -4.0190185403],
            [-40.8427748609, -4.0260500679],
            [-40.8431574826, -4.0376733925],
            [-40.8429934325, -4.0423506691],
            [-40.849112862, -4.0453475605],
            [-40.8440941298, -4.0501338554],
            [-40.8481884098, -4.0567151762],
            [-40.8466349942, -4.0617863576],
            [-40.8540768702, -4.0658569391],
            [-40.8539263244, -4.0691414407],
            [-40.8574386786, -4.0740176516],
            [-40.8539029379, -4.0835250032],
            [-40.8528450004, -4.089045575],
            [-40.8475386306, -4.0905828722],
            [-40.847684594, -4.1032064545],
            [-40.8372343668, -4.1050439584],
            [-40.8360758619, -4.1119106117],
            [-40.8283616925, -4.1117642167],
            [-40.8150869353, -4.1233626531],
            [-40.833202329, -4.1281230812],
            [-40.8356830718, -4.1341330121],
            [-40.8503504071, -4.1411514067],
            [-40.8541703578, -4.1464715406],
            [-40.8507463265, -4.153973152],
            [-40.8694244258, -4.15748808],
            [-40.8862077983, -4.1522983044],
            [-40.8943674605, -4.1546374909],
            [-40.9005577945, -4.1585453832],
            [-40.8958930281, -4.1496138136],
            [-40.8871102995, -4.1426914947],
            [-40.8869001967, -4.1349024685],
            [-40.8900771588, -4.1302685842],
            [-40.8907235136, -4.1100161976],
            [-40.8906563575, -4.1057080346],
            [-40.908879273, -4.0880485664],
            [-40.9089506732, -4.0879791929],
            [-40.9239617223, -4.0734285592],
            [-40.9364601103, -4.0613106322],
            [-40.9405207853, -4.0573727892],
            [-40.9585958119, -4.057129878],
            [-40.9808177845, -4.0555976145],
            [-40.9840630315, -4.056806269],
            [-41.0317243215, -4.0654797354],
            [-41.0391502628, -4.0648794477],
            [-41.0639879653, -4.0495894394],
            [-41.0870678605, -4.0617770993],
            [-41.0965781055, -4.062555502],
            [-41.1056613105, -4.0747586946],
            [-41.1061646715, -4.0819897974],
            [-41.1177422846, -4.0910338591],
            [-41.1307266956, -4.1161663892],
            [-41.1390238166, -4.1206085733],
            [-41.1218621717, -4.0768073975],
            [-41.1267854841, -4.0610113532],
            [-41.1137421271, -4.0514410914],
            [-41.1139094782, -4.0401865031],
            [-41.1199719335, -4.0359609002],
            [-41.1427244984, -4.0364349702],
            [-41.1595768153, -4.0313333271],
            [-41.1714413015, -4.0320995614],
            [-41.1834329943, -4.0240232921],
            [-41.1824833745, -4.0176948406],
            [-41.181036194, -4.0128613809],
            [-41.1640325478, -3.9982558569],
            [-41.1563182025, -3.9989838207],
            [-41.1521932579, -3.9948421737],
            [-41.1431089835, -3.994555778],
            [-41.1319399781, -3.9885713543],
            [-41.118653114, -3.9862694174],
            [-41.1070125794, -4.0017318085],
            [-41.0932213507, -4.0058767214],
            [-41.0744703364, -3.9957282006],
            [-41.0561578337, -3.9947520436],
            [-41.0473775321, -3.9924605329],
            [-41.0321060225, -3.9957398972],
            [-41.0273083432, -3.990958797],
            [-40.9956467043, -3.9877735176],
            [-40.9878749786, -3.9885949884],
            [-40.9640019977, -3.9825869133],
            [-40.9374114883, -3.9871220726],
            [-40.9249155733, -3.9866319219],
            [-40.921854333, -3.988152443],
            [-40.8937702071, -3.9936840297],
            [-40.8788457383, -3.9917177222],
            [-40.8608765955, -3.9784726684],
            [-40.8473534216, -3.976521695],
            [-40.839057944, -3.9885690081],
            [-40.8392367261, -3.988874037],
            [-40.8392744291, -3.9889371881],
            [-40.8456402707, -3.9915242406],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2312403',
        name: 'São Gonçalo do Amarante',
        description: 'São Gonçalo do Amarante',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.8335433557, -3.5457851162],
            [-38.8184530222, -3.5458579738],
            [-38.8118667665, -3.540782018],
            [-38.805645218, -3.5475604495],
            [-38.8256999809, -3.5651252253],
            [-38.827243556, -3.566478843],
            [-38.8317359514, -3.5704135081],
            [-38.8534087017, -3.6197114913],
            [-38.8608911816, -3.6367394136],
            [-38.8824946048, -3.685918576],
            [-38.9089195809, -3.6718186632],
            [-38.9196091902, -3.6661136148],
            [-38.9235262419, -3.6722037706],
            [-38.922209397, -3.6831330832],
            [-38.9313641954, -3.6811573929],
            [-38.940535532, -3.6842913194],
            [-38.9609039882, -3.711166074],
            [-39.0009490011, -3.6858881807],
            [-39.0024376734, -3.6857542445],
            [-39.1435902704, -3.7040113049],
            [-39.1559718283, -3.7020760327],
            [-39.1842805135, -3.6976501433],
            [-39.2139112601, -3.693015219],
            [-39.2258637643, -3.6814253201],
            [-39.2248719253, -3.6711896622],
            [-39.2245974225, -3.6705419305],
            [-39.2272546418, -3.6639657041],
            [-39.220532787, -3.6422488405],
            [-39.2240039458, -3.6196155207],
            [-39.2179219687, -3.6119020017],
            [-39.2188191226, -3.6072388809],
            [-39.2231553155, -3.6060985755],
            [-39.224096513, -3.6058508421],
            [-39.2301020093, -3.6030509707],
            [-39.2381095559, -3.5912657957],
            [-39.2522498883, -3.5878478387],
            [-39.266610609, -3.5829427285],
            [-39.3150650896, -3.5803550172],
            [-39.3136161969, -3.5720291457],
            [-39.306210161, -3.5650679763],
            [-39.309670802, -3.5598751596],
            [-39.3118503252, -3.557245363],
            [-39.3078809675, -3.5414516032],
            [-39.30955233, -3.5278034235],
            [-39.2987277112, -3.5362437787],
            [-39.2915573243, -3.5285777019],
            [-39.2865244672, -3.5242822212],
            [-39.2839441915, -3.5142139598],
            [-39.2143336288, -3.5140480864],
            [-39.1656884324, -3.5139288169],
            [-39.1653413212, -3.5212028305],
            [-39.1694662659, -3.528279212],
            [-39.1331564109, -3.5283820874],
            [-39.1188771348, -3.5284219201],
            [-39.1133789301, -3.5284379613],
            [-39.0752588482, -3.528542822],
            [-39.0489173651, -3.5286157499],
            [-39.0638559184, -3.5371412609],
            [-39.0988302423, -3.5324701525],
            [-39.1080951801, -3.5376060858],
            [-39.1075501512, -3.5428683227],
            [-39.0943160332, -3.5465158328],
            [-39.0899243828, -3.5563824792],
            [-39.0850087171, -3.5509529067],
            [-39.0694218857, -3.544421475],
            [-39.0567334226, -3.5457437133],
            [-39.0375721039, -3.5503957482],
            [-39.0257814178, -3.5551259668],
            [-39.0076413441, -3.5513783333],
            [-38.996562766, -3.5546209212],
            [-38.9877916212, -3.5616391149],
            [-38.9740435066, -3.5526742949],
            [-38.9620497719, -3.5483322097],
            [-38.9615058575, -3.5478017011],
            [-38.958938487, -3.5412752193],
            [-38.9610133511, -3.5351601992],
            [-38.9644825425, -3.5319301418],
            [-38.9627689188, -3.524966045],
            [-38.9687657915, -3.5191901468],
            [-38.9689915469, -3.5111215043],
            [-38.9612858142, -3.5090813778],
            [-38.9538580635, -3.4981251838],
            [-38.9531877564, -3.4978332551],
            [-38.9450858027, -3.4893998077],
            [-38.9389239435, -3.4830328892],
            [-38.9383051499, -3.4710841312],
            [-38.932528769, -3.4709581069],
            [-38.9317542833, -3.4649837233],
            [-38.9209486138, -3.4895971382],
            [-38.91036532, -3.5033052818],
            [-38.890916147, -3.5047753074],
            [-38.8685453616, -3.5238627067],
            [-38.8532297935, -3.5385715231],
            [-38.8335433557, -3.5457851162],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2312502',
        name: 'São João do Jaguaribe',
        description: 'São João do Jaguaribe',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.2043348436, -5.3219492042],
            [-38.2013093765, -5.3267558172],
            [-38.1917866225, -5.3248540408],
            [-38.1855765213, -5.3315709547],
            [-38.1785908429, -5.3328592611],
            [-38.1720650823, -5.3467487899],
            [-38.1584524576, -5.3573866109],
            [-38.1749178303, -5.3655333061],
            [-38.1824707951, -5.3692710531],
            [-38.2204106409, -5.3880469271],
            [-38.2235573172, -5.3896046496],
            [-38.2307255727, -5.3931525877],
            [-38.2599549299, -5.3934194625],
            [-38.2610422053, -5.4099107492],
            [-38.3027133163, -5.3897345745],
            [-38.313233796, -5.3896970123],
            [-38.3228060358, -5.4039096584],
            [-38.3401198393, -5.4037981471],
            [-38.3416894045, -5.4025996973],
            [-38.3940974766, -5.3625632144],
            [-38.3923082933, -5.3554659364],
            [-38.3748049213, -5.3556382226],
            [-38.3626172067, -5.3488485033],
            [-38.3539112464, -5.330249124],
            [-38.3427456345, -5.3249686684],
            [-38.342661175, -5.3249195061],
            [-38.3278981069, -5.3136973953],
            [-38.3350412119, -5.3046207328],
            [-38.3385017653, -5.2967695628],
            [-38.3283989406, -5.2775550655],
            [-38.3167560887, -5.2653146199],
            [-38.3001551555, -5.2585334869],
            [-38.2697675109, -5.232432013],
            [-38.2662498764, -5.2246545283],
            [-38.2382071931, -5.2095080141],
            [-38.2325780134, -5.2155675978],
            [-38.2243937904, -5.2267055991],
            [-38.2242310957, -5.2269273976],
            [-38.2112806371, -5.2445496767],
            [-38.225914941, -5.2819939887],
            [-38.2254999991, -5.2885269512],
            [-38.221863803, -5.2965627589],
            [-38.2173082018, -5.297990069],
            [-38.2147804045, -5.3109116364],
            [-38.2066881561, -5.3133294663],
            [-38.2070893039, -5.3174573582],
            [-38.2117228182, -5.3196573014],
            [-38.2043348436, -5.3219492042],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2312601',
        name: 'São Luís do Curu',
        description: 'São Luís do Curu',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.2248719253, -3.6711896622],
            [-39.2258637643, -3.6814253201],
            [-39.2139112601, -3.693015219],
            [-39.2425676877, -3.7129543369],
            [-39.2764101429, -3.7365083723],
            [-39.2767409328, -3.7185314842],
            [-39.27753893, -3.6747697619],
            [-39.2810685077, -3.6726428252],
            [-39.2872507254, -3.676725318],
            [-39.2897712609, -3.6746425469],
            [-39.3061606785, -3.6680165132],
            [-39.3150650896, -3.5803550172],
            [-39.266610609, -3.5829427285],
            [-39.2522498883, -3.5878478387],
            [-39.2381095559, -3.5912657957],
            [-39.2301020093, -3.6030509707],
            [-39.224096513, -3.6058508421],
            [-39.2231553155, -3.6060985755],
            [-39.2188191226, -3.6072388809],
            [-39.2179219687, -3.6119020017],
            [-39.2240039458, -3.6196155207],
            [-39.220532787, -3.6422488405],
            [-39.2272546418, -3.6639657041],
            [-39.2245974225, -3.6705419305],
            [-39.2248719253, -3.6711896622],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2312700',
        name: 'Senador Pompeu',
        description: 'Senador Pompeu',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.6428877274, -5.3832688553],
            [-39.6315274591, -5.398471868],
            [-39.6178380649, -5.398796262],
            [-39.6134747402, -5.4056345435],
            [-39.6006775641, -5.4068651875],
            [-39.5977533182, -5.3996792609],
            [-39.5842812933, -5.4074083713],
            [-39.5771918057, -5.4033712155],
            [-39.5680304244, -5.3997319185],
            [-39.5660056749, -5.3796842925],
            [-39.5502533374, -5.3772447948],
            [-39.5306919563, -5.3753155714],
            [-39.5087982384, -5.4108835078],
            [-39.4838217146, -5.4466755794],
            [-39.4636453588, -5.4755750561],
            [-39.4566575749, -5.4792755915],
            [-39.4422753872, -5.4868931524],
            [-39.4000627622, -5.5092409101],
            [-39.3995422514, -5.5095159336],
            [-39.3668134353, -5.5258305148],
            [-39.3516673222, -5.5217563169],
            [-39.3513678311, -5.5216755822],
            [-39.3111023369, -5.5108431131],
            [-39.3100048078, -5.5105480071],
            [-39.3020040096, -5.509933788],
            [-39.2834573009, -5.5250977193],
            [-39.277971818, -5.5249687],
            [-39.2635242156, -5.5475406823],
            [-39.2652977838, -5.5535337024],
            [-39.2536150647, -5.5605473263],
            [-39.2529300161, -5.5675962504],
            [-39.2595095189, -5.5805674051],
            [-39.2464685399, -5.5920873287],
            [-39.251911144, -5.6007887568],
            [-39.2693991332, -5.6327146978],
            [-39.2671809756, -5.6398849234],
            [-39.2738052837, -5.6517014449],
            [-39.2760759456, -5.6511179064],
            [-39.2761765036, -5.6602254148],
            [-39.2800937674, -5.6671998809],
            [-39.2610618382, -5.6777683487],
            [-39.268282122, -5.6930946207],
            [-39.2728657916, -5.7087645127],
            [-39.2606438751, -5.7152610201],
            [-39.2674913059, -5.7538917699],
            [-39.2740012067, -5.7613687293],
            [-39.2784970062, -5.7618507881],
            [-39.2825531584, -5.7559400372],
            [-39.3037503202, -5.7725781455],
            [-39.3072812266, -5.771325533],
            [-39.3090259598, -5.7794614296],
            [-39.3266395195, -5.7850222481],
            [-39.3503668114, -5.772306377],
            [-39.3857561019, -5.7533318399],
            [-39.4335672394, -5.7276811486],
            [-39.4513879821, -5.737549881],
            [-39.4566632068, -5.7304879087],
            [-39.4560055074, -5.7249406396],
            [-39.4625899186, -5.7180759978],
            [-39.4594665744, -5.7126320806],
            [-39.4710385454, -5.71181577],
            [-39.4751137912, -5.7013214928],
            [-39.4725889646, -5.6868881758],
            [-39.4596784789, -5.6877738495],
            [-39.4508358784, -5.6774679711],
            [-39.4505896561, -5.6729432911],
            [-39.4372826441, -5.6635137223],
            [-39.4311141758, -5.6545197161],
            [-39.4411132238, -5.6570422104],
            [-39.453583448, -5.6510474987],
            [-39.4568345288, -5.6529423174],
            [-39.4688646506, -5.6433977564],
            [-39.4768261601, -5.6445581228],
            [-39.4821551775, -5.6341436389],
            [-39.4809252256, -5.6206448063],
            [-39.483256983, -5.6150212486],
            [-39.5002932614, -5.617002795],
            [-39.5110820402, -5.6306138213],
            [-39.5432425795, -5.613216883],
            [-39.5610651841, -5.6142014919],
            [-39.571046104, -5.6072065966],
            [-39.5779896251, -5.6100705944],
            [-39.5874981816, -5.606850759],
            [-39.6016824895, -5.6251419868],
            [-39.605517712, -5.6204043497],
            [-39.6372778415, -5.6392489184],
            [-39.6380013479, -5.6342370685],
            [-39.6301748513, -5.6227131704],
            [-39.6319524347, -5.6058232362],
            [-39.6250789406, -5.6078083228],
            [-39.6008029065, -5.585780702],
            [-39.5863417721, -5.5587447263],
            [-39.5831826965, -5.5498057213],
            [-39.5843733804, -5.5357040496],
            [-39.5803847202, -5.5359703736],
            [-39.5756647145, -5.5268609774],
            [-39.5611378896, -5.531997627],
            [-39.5521776249, -5.5284799063],
            [-39.5591354335, -5.5184270642],
            [-39.5703455745, -5.5149144977],
            [-39.5743537051, -5.5139249752],
            [-39.5807285197, -5.5040357471],
            [-39.596100398, -5.502102094],
            [-39.601588663, -5.4978433618],
            [-39.6044863676, -5.4893715735],
            [-39.6216544281, -5.4804007353],
            [-39.6285611347, -5.4802738944],
            [-39.6368844069, -5.4731003855],
            [-39.6362842076, -5.4642730044],
            [-39.6454268156, -5.4523138401],
            [-39.6430151354, -5.4466500684],
            [-39.6492840609, -5.4408228449],
            [-39.6568622111, -5.4390733256],
            [-39.657916983, -5.429372141],
            [-39.6695005313, -5.4059720424],
            [-39.6694500279, -5.4058200143],
            [-39.6663287309, -5.3964262048],
            [-39.6734638063, -5.3865615279],
            [-39.670736394, -5.3840704325],
            [-39.6627683945, -5.387640992],
            [-39.6638253773, -5.3794463808],
            [-39.6577183202, -5.3745277205],
            [-39.6492522231, -5.3755363189],
            [-39.6489643313, -5.3831191194],
            [-39.6428877274, -5.3832688553],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2312809',
        name: 'Senador Sá',
        description: 'Senador Sá',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.4183358678, -3.2321120221],
            [-40.4169730474, -3.2386508483],
            [-40.4059237686, -3.2384806178],
            [-40.3995588884, -3.2533073667],
            [-40.3924885287, -3.2604386816],
            [-40.3871423046, -3.2637409983],
            [-40.3868912256, -3.2714764752],
            [-40.3608411723, -3.2734543569],
            [-40.3566731096, -3.2773642001],
            [-40.3566460369, -3.2827114849],
            [-40.3475020455, -3.2885956914],
            [-40.3411458289, -3.2884333814],
            [-40.3409283475, -3.3195470935],
            [-40.3407410309, -3.3532034568],
            [-40.3402658347, -3.3583570083],
            [-40.3513446767, -3.3675843385],
            [-40.3760321637, -3.3798728896],
            [-40.3938413066, -3.3815257708],
            [-40.3996687643, -3.3721766224],
            [-40.4127225857, -3.3757323913],
            [-40.4158391443, -3.3692384712],
            [-40.4445120286, -3.3795826737],
            [-40.4533086057, -3.3833720238],
            [-40.4607731869, -3.3823934556],
            [-40.4925267721, -3.3911610223],
            [-40.5141251433, -3.3930014103],
            [-40.5365937119, -3.4477884527],
            [-40.5484114998, -3.4468709433],
            [-40.553919736, -3.4397432479],
            [-40.5697530959, -3.4387078008],
            [-40.566979439, -3.4280699291],
            [-40.5613660484, -3.4065440391],
            [-40.5409139774, -3.4104893814],
            [-40.5459932397, -3.3928524387],
            [-40.5405634967, -3.3872448097],
            [-40.5309495682, -3.389137455],
            [-40.5194261252, -3.3760485166],
            [-40.5066724545, -3.3696104705],
            [-40.5011137214, -3.3488210307],
            [-40.4929812085, -3.33634735],
            [-40.4862546334, -3.3352285293],
            [-40.4857654332, -3.3324847103],
            [-40.485894783, -3.3223847323],
            [-40.480002851, -3.3112208391],
            [-40.4792548342, -3.3008630916],
            [-40.4857551616, -3.2907430542],
            [-40.4868912103, -3.2770436617],
            [-40.4807848507, -3.2619310628],
            [-40.4923634685, -3.2595207996],
            [-40.5011186371, -3.2521747609],
            [-40.5046140313, -3.2535368081],
            [-40.5160033902, -3.2481348346],
            [-40.5214161138, -3.2404457276],
            [-40.5287179034, -3.2388607317],
            [-40.5382289944, -3.2380575796],
            [-40.5348940074, -3.2298388208],
            [-40.5376146421, -3.2173678413],
            [-40.5460363401, -3.2032184891],
            [-40.5465560598, -3.1888651126],
            [-40.5572788111, -3.1743272219],
            [-40.5747525394, -3.166072307],
            [-40.5712792881, -3.1537845546],
            [-40.5731782105, -3.1360654389],
            [-40.5098499221, -3.1079044949],
            [-40.5078511467, -3.1141991527],
            [-40.4973922602, -3.1201429876],
            [-40.4816488221, -3.1221009687],
            [-40.47169853, -3.1337748524],
            [-40.4632576819, -3.1380064172],
            [-40.4532004461, -3.1502667129],
            [-40.4546711741, -3.1696396825],
            [-40.4494600186, -3.1804657933],
            [-40.4421130784, -3.1945258504],
            [-40.4347811796, -3.1998069782],
            [-40.4293703824, -3.2153764301],
            [-40.4211938565, -3.2185177826],
            [-40.4214336393, -3.2234186669],
            [-40.4216727225, -3.2241043733],
            [-40.4183358678, -3.2321120221],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2312908',
        name: 'Sobral',
        description: 'Sobral',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.2571698888, -3.5804079488],
            [-40.2459218962, -3.5821578743],
            [-40.2416670122, -3.5787148395],
            [-40.236834625, -3.581044643],
            [-40.2306688667, -3.578598621],
            [-40.2246034108, -3.5913592289],
            [-40.2194254798, -3.5993104217],
            [-40.2051685719, -3.6027642075],
            [-40.1994223145, -3.6075930596],
            [-40.2043568911, -3.6131679027],
            [-40.212947949, -3.6259243322],
            [-40.207839961, -3.636703729],
            [-40.196301138, -3.6455645718],
            [-40.1796749275, -3.6533594465],
            [-40.1845399277, -3.6693100853],
            [-40.1772515574, -3.6701098109],
            [-40.1764920668, -3.6754273592],
            [-40.1608856386, -3.667138865],
            [-40.1527484381, -3.6681039568],
            [-40.1404504663, -3.6746664012],
            [-40.1335366451, -3.6843076163],
            [-40.1188079727, -3.691041468],
            [-40.1167668357, -3.6905429913],
            [-40.1128417627, -3.6879187039],
            [-40.1118680496, -3.6679869174],
            [-40.1127556202, -3.6492395357],
            [-40.1199400682, -3.638889869],
            [-40.0795933762, -3.6030915917],
            [-40.0707580254, -3.5942022047],
            [-40.0704839224, -3.594056705],
            [-40.0530382624, -3.5897707849],
            [-40.0438844708, -3.5809771383],
            [-40.038677193, -3.5809086034],
            [-40.03806194, -3.580762454],
            [-40.0220108354, -3.5826448831],
            [-40.0105002337, -3.5781222017],
            [-39.9975669834, -3.5806055275],
            [-39.9728966722, -3.5712291191],
            [-39.9863414994, -3.6048740346],
            [-39.9861595893, -3.6122508504],
            [-39.9935648526, -3.618101583],
            [-39.9938280113, -3.627267056],
            [-40.0063094928, -3.6436382999],
            [-40.0087823913, -3.6458106094],
            [-40.0076029224, -3.6555150827],
            [-40.0152230767, -3.6702670502],
            [-40.0154585099, -3.670748386],
            [-40.015162776, -3.6860089416],
            [-40.0202086844, -3.6935836272],
            [-40.0153716229, -3.7119695029],
            [-40.0177757039, -3.7219849436],
            [-40.0141741508, -3.7249687687],
            [-40.0128934384, -3.7387816081],
            [-40.0154383965, -3.7413047254],
            [-40.0130701734, -3.7539967061],
            [-40.0116653878, -3.7614377971],
            [-39.9757235251, -3.7774545803],
            [-39.9708625528, -3.7933517939],
            [-39.9506790419, -3.8593113948],
            [-39.9506304674, -3.85946953],
            [-39.9237739053, -3.9471062002],
            [-40.0069380359, -3.8986558213],
            [-39.9970812692, -3.9196661364],
            [-39.999770796, -3.9278006206],
            [-39.9934855994, -3.9361774907],
            [-39.9730814607, -3.9459492282],
            [-39.9520796124, -3.963924522],
            [-39.9453905705, -3.9752260266],
            [-39.9416149494, -3.9758183872],
            [-39.93943836, -3.989022014],
            [-39.9314629136, -3.9860938838],
            [-39.9278807618, -3.9892852231],
            [-39.9243534463, -3.9880184519],
            [-39.9224893615, -3.9976461191],
            [-39.9157264318, -3.9975006817],
            [-39.914428977, -4.0022370997],
            [-39.9097946616, -4.0042848719],
            [-39.9157991584, -4.0069689793],
            [-39.9139082633, -4.0191038057],
            [-39.9218802001, -4.0201147082],
            [-39.9210912746, -4.0254092658],
            [-39.9261521125, -4.0352129601],
            [-39.9226931926, -4.0378481005],
            [-39.914986105, -4.0344198805],
            [-39.905578902, -4.0404974682],
            [-39.9020057959, -4.0383065837],
            [-39.8983083369, -4.0427657885],
            [-39.8878244259, -4.0389394637],
            [-39.8683938627, -4.0439094655],
            [-39.8726850701, -4.0465372701],
            [-39.8714517896, -4.0513368235],
            [-39.8766403267, -4.0626881341],
            [-39.8532208899, -4.0778826326],
            [-39.8489708579, -4.0877869926],
            [-39.857245565, -4.1023842879],
            [-39.8402638263, -4.113148138],
            [-39.8430825064, -4.1182344096],
            [-39.8490282511, -4.1197940959],
            [-39.8563567444, -4.136460636],
            [-39.8610965465, -4.1390065611],
            [-39.868967614, -4.1352714909],
            [-39.8790904375, -4.1357303979],
            [-39.8815051569, -4.1394851969],
            [-39.8889746313, -4.1389290865],
            [-39.8937209326, -4.1440113425],
            [-39.9029503006, -4.1370344048],
            [-39.9190179254, -4.1370163753],
            [-39.9247155486, -4.1314512994],
            [-39.9399765371, -4.1279891612],
            [-39.9450391177, -4.1297831587],
            [-39.9474444362, -4.126806854],
            [-39.9549187327, -4.1295370531],
            [-39.9625861224, -4.1223450205],
            [-39.9728139875, -4.1140934316],
            [-39.9682260565, -4.1058811595],
            [-39.9876741221, -4.1072452605],
            [-39.9912764902, -4.1035824686],
            [-39.9939207799, -4.0980195386],
            [-39.986925032, -4.0924707444],
            [-39.9892468215, -4.0862841109],
            [-39.9967178715, -4.0866656904],
            [-40.0004096724, -4.0832164664],
            [-40.0138297039, -4.0889137637],
            [-40.0334166485, -4.07855688],
            [-40.0428146903, -4.0778400189],
            [-40.053410161, -4.0714082054],
            [-40.0565334396, -4.0649053479],
            [-40.0534771554, -4.0619359136],
            [-40.0589507845, -4.0578107112],
            [-40.0680208177, -4.0572892221],
            [-40.0817483824, -4.0593933045],
            [-40.0888027341, -4.0490511338],
            [-40.0978762133, -4.0469247605],
            [-40.1027829617, -4.0520066249],
            [-40.105614628, -4.0484895877],
            [-40.1098358101, -4.0408812096],
            [-40.1184314822, -4.0415735667],
            [-40.1228402615, -4.0358532808],
            [-40.1309549283, -4.03639063],
            [-40.1319107005, -4.0315360627],
            [-40.1398623219, -4.0319167223],
            [-40.1395483526, -4.0242747764],
            [-40.1414607754, -4.0220068929],
            [-40.1621557091, -4.0014308974],
            [-40.1694966196, -3.9847568778],
            [-40.1779117161, -3.9777725747],
            [-40.1898961758, -3.9634580926],
            [-40.1885554853, -3.9573677385],
            [-40.1727068208, -3.9444299868],
            [-40.1721575974, -3.9373634222],
            [-40.1676065397, -3.9368635947],
            [-40.1669712334, -3.9334620912],
            [-40.1665587126, -3.9306266244],
            [-40.156746528, -3.9240813612],
            [-40.153330847, -3.9173689561],
            [-40.1475747383, -3.9112930448],
            [-40.1495164727, -3.9022857967],
            [-40.147092746, -3.8976204791],
            [-40.1388910649, -3.897021446],
            [-40.1350837857, -3.8858926889],
            [-40.139922556, -3.8745411081],
            [-40.1359207202, -3.8564409012],
            [-40.1298463249, -3.8558910298],
            [-40.1265704401, -3.8479313099],
            [-40.1242474845, -3.8243246001],
            [-40.1211111878, -3.8202678279],
            [-40.1210393478, -3.8085320245],
            [-40.1343964641, -3.805739302],
            [-40.1302385157, -3.7881927285],
            [-40.1239075037, -3.7727216642],
            [-40.1258971677, -3.7156275314],
            [-40.1262116739, -3.7065536144],
            [-40.211218777, -3.701149698],
            [-40.239082595, -3.6993761576],
            [-40.2364979664, -3.7141677156],
            [-40.2428784342, -3.7216300746],
            [-40.2416401041, -3.7323424404],
            [-40.23263993, -3.7345343797],
            [-40.2278940095, -3.7305292772],
            [-40.2277213584, -3.7372042667],
            [-40.2221682036, -3.742009851],
            [-40.2199836684, -3.7488076166],
            [-40.2224580752, -3.7561752905],
            [-40.2187234479, -3.7592143925],
            [-40.2331289504, -3.7721500012],
            [-40.2403376683, -3.7744816657],
            [-40.2500052177, -3.7720446349],
            [-40.2513113283, -3.7654260353],
            [-40.2605886015, -3.7677339743],
            [-40.2608519194, -3.7630313069],
            [-40.2702726228, -3.7626722903],
            [-40.2777077239, -3.7428593454],
            [-40.2831182938, -3.7429419388],
            [-40.2862904009, -3.7444594781],
            [-40.2876425642, -3.7538242275],
            [-40.2828535465, -3.7700439256],
            [-40.3018684958, -3.7565670127],
            [-40.3110902984, -3.7638996918],
            [-40.3112958783, -3.7701000289],
            [-40.3081521255, -3.774201956],
            [-40.3020852381, -3.7741043371],
            [-40.2968771787, -3.7861146163],
            [-40.3064881746, -3.7849658803],
            [-40.3092897848, -3.7888119209],
            [-40.3171486752, -3.7897437998],
            [-40.3461741368, -3.7796484849],
            [-40.3563601311, -3.8037634674],
            [-40.3611585662, -3.8288459634],
            [-40.359753653, -3.8366144177],
            [-40.3707457971, -3.8614246383],
            [-40.3801706743, -3.8467721241],
            [-40.3796956803, -3.8371602244],
            [-40.3852239021, -3.826678308],
            [-40.3893377643, -3.7990982043],
            [-40.4005849281, -3.8021192418],
            [-40.4159383232, -3.8062441794],
            [-40.4222781596, -3.810352111],
            [-40.4848407963, -3.8509084128],
            [-40.4933285174, -3.8507318058],
            [-40.5148711375, -3.8513244813],
            [-40.5330833908, -3.8522323243],
            [-40.5349185492, -3.8469388019],
            [-40.5697374474, -3.8585644809],
            [-40.5698610457, -3.858605996],
            [-40.5784191605, -3.8614640117],
            [-40.5845328278, -3.8582221141],
            [-40.5943619439, -3.8467096381],
            [-40.6001442919, -3.8467261751],
            [-40.6004522472, -3.8444042419],
            [-40.6141152653, -3.8423739848],
            [-40.6217455347, -3.8271307259],
            [-40.6285847702, -3.8186456942],
            [-40.6317902912, -3.8201268449],
            [-40.6667231516, -3.8362656572],
            [-40.6698766225, -3.8457725431],
            [-40.693602191, -3.8359506931],
            [-40.7113307283, -3.8208571343],
            [-40.7145430437, -3.8166791971],
            [-40.7121297888, -3.8107007119],
            [-40.7149360834, -3.8061589553],
            [-40.7112845384, -3.804168211],
            [-40.7028280632, -3.8028423042],
            [-40.6644856464, -3.7986003882],
            [-40.65667116, -3.7980281714],
            [-40.6419365918, -3.7504975639],
            [-40.6187276496, -3.6756954747],
            [-40.6137362402, -3.6596177957],
            [-40.6134323394, -3.6586397096],
            [-40.6109401655, -3.6506117425],
            [-40.5673988869, -3.6528739007],
            [-40.5496603092, -3.653794908],
            [-40.5332015005, -3.6560401944],
            [-40.5309257062, -3.6515951594],
            [-40.521662856, -3.6494874163],
            [-40.5157299079, -3.6483911945],
            [-40.5203253406, -3.6378921729],
            [-40.5129578178, -3.6427750203],
            [-40.5059610295, -3.64285309],
            [-40.4931427329, -3.6354405194],
            [-40.4999031733, -3.6296216952],
            [-40.4734271771, -3.6376351763],
            [-40.4687384993, -3.6340428058],
            [-40.4638533234, -3.6407185944],
            [-40.4550848736, -3.6397937847],
            [-40.452031521, -3.6354618738],
            [-40.4404437815, -3.6371904586],
            [-40.4089743407, -3.6437739811],
            [-40.3975878562, -3.6360312554],
            [-40.3972851125, -3.6320379231],
            [-40.4030284503, -3.6261876765],
            [-40.3862944526, -3.6222946718],
            [-40.3636197909, -3.6168882518],
            [-40.3611820483, -3.6132230125],
            [-40.3480346373, -3.6145313635],
            [-40.3343055436, -3.6223310091],
            [-40.3200332395, -3.6167131067],
            [-40.3129709389, -3.6209097129],
            [-40.3016832351, -3.6212690869],
            [-40.2930389244, -3.6077160982],
            [-40.298909484, -3.5916097358],
            [-40.2816170906, -3.5841571102],
            [-40.278727546, -3.5727606392],
            [-40.2703087417, -3.5717306382],
            [-40.2571698888, -3.5804079488],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313005',
        name: 'Solonópole',
        description: 'Solonópole',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.9015938101, -5.4694381735],
            [-38.907562949, -5.5649134159],
            [-38.9092956095, -5.5955587808],
            [-38.9091641339, -5.595855407],
            [-38.8798468441, -5.6591066696],
            [-38.8040394098, -5.787267718],
            [-38.8087821143, -5.7953288453],
            [-38.814058306, -5.7971270219],
            [-38.8369490297, -5.8136221519],
            [-38.8376638147, -5.8168739878],
            [-38.8332753065, -5.8191646433],
            [-38.8393350659, -5.8198639813],
            [-38.8402430533, -5.8325254461],
            [-38.8434139091, -5.834649915],
            [-38.8557891059, -5.8349719263],
            [-38.8568860445, -5.8474461133],
            [-38.8620812881, -5.8513405577],
            [-38.8602009361, -5.8546737753],
            [-38.8697477036, -5.8593463919],
            [-38.8729788124, -5.8571847772],
            [-38.8807241914, -5.859930874],
            [-38.8760358801, -5.8688142763],
            [-38.8805627843, -5.8704100599],
            [-38.8788308034, -5.8755588243],
            [-38.9146940909, -5.9401756152],
            [-38.9148320912, -5.9404109759],
            [-38.9208926172, -5.9507528107],
            [-38.9336929452, -5.9492360035],
            [-38.9378942568, -5.9570783698],
            [-38.9351395314, -5.9650422402],
            [-38.9472375144, -5.9702202656],
            [-38.9450049405, -5.9761045581],
            [-38.9470872297, -5.9859992416],
            [-38.9529770569, -5.9891753412],
            [-38.9539906979, -5.9942765219],
            [-38.9530037156, -6.0011821843],
            [-38.9468338746, -6.0049886074],
            [-38.952047671, -6.0151429182],
            [-38.9587562901, -6.0148738389],
            [-38.96186329, -6.0186824198],
            [-38.9648050728, -6.0373999036],
            [-38.9604758392, -6.0471253197],
            [-38.9670932114, -6.0608033387],
            [-38.9688055638, -6.0762692796],
            [-38.9724807503, -6.0788862247],
            [-38.9703981303, -6.08412101],
            [-38.97407151, -6.1014412699],
            [-38.9718676898, -6.102631692],
            [-38.9775855489, -6.105586759],
            [-38.9807121335, -6.0994135685],
            [-38.9779222218, -6.0916026075],
            [-38.9830164645, -6.0895066815],
            [-38.98561236, -6.0787957082],
            [-38.9896695213, -6.0762621456],
            [-38.9851603289, -6.0690121795],
            [-38.9835580202, -6.0468602579],
            [-38.9865229323, -6.0284114327],
            [-38.9919407641, -6.0199607081],
            [-39.006672843, -6.0182608079],
            [-39.0157589523, -6.0004809651],
            [-39.0214692531, -6.008929062],
            [-39.0336699043, -6.0071639557],
            [-39.0297112648, -6.0255731115],
            [-39.0329567781, -6.0279681257],
            [-39.0393492708, -6.0229873434],
            [-39.0429833513, -6.0280296889],
            [-39.0455465003, -6.024752001],
            [-39.0519373273, -6.0254762508],
            [-39.0598984367, -6.0087363093],
            [-39.0603693185, -5.9975428542],
            [-39.0721421551, -5.9981465563],
            [-39.078158413, -5.9913371781],
            [-39.0866569032, -5.9922583509],
            [-39.1022979881, -5.9830048464],
            [-39.1130165239, -5.988784944],
            [-39.1135896617, -5.9767540102],
            [-39.1306282295, -5.9834610145],
            [-39.1383125052, -5.9714181945],
            [-39.1372762765, -5.9649535059],
            [-39.1425210889, -5.9673819874],
            [-39.1452113448, -5.9578371269],
            [-39.1589794594, -5.9563595404],
            [-39.1620711819, -5.9559971361],
            [-39.1654271936, -5.9648968987],
            [-39.1711155148, -5.961346654],
            [-39.1708442834, -5.9590819502],
            [-39.1724116214, -5.9492027565],
            [-39.1768970226, -5.945253922],
            [-39.1750507539, -5.9286441901],
            [-39.1782605725, -5.9261632343],
            [-39.1796286871, -5.9168018996],
            [-39.1729629916, -5.9000085343],
            [-39.1744656109, -5.8722920466],
            [-39.1702633189, -5.8679797744],
            [-39.1729996398, -5.8630234947],
            [-39.1801498729, -5.8599921583],
            [-39.1820358801, -5.8591921548],
            [-39.1773368324, -5.843258686],
            [-39.175206132, -5.8365320745],
            [-39.1627775929, -5.7973105943],
            [-39.1625335011, -5.7965411147],
            [-39.1326316238, -5.7284083655],
            [-39.1326077415, -5.7283552534],
            [-39.0942593337, -5.6414644568],
            [-39.0895386857, -5.6311183256],
            [-39.0756859049, -5.600757597],
            [-39.0320488435, -5.5006933846],
            [-39.0291881645, -5.4929539036],
            [-39.0306922142, -5.4858014134],
            [-39.0092901123, -5.5110910144],
            [-39.0034744585, -5.5040236501],
            [-38.9928114253, -5.5150636114],
            [-38.9897767648, -5.5120630681],
            [-38.9875260104, -5.5156186108],
            [-38.981385858, -5.5147979192],
            [-38.9773097935, -5.521361849],
            [-38.958975324, -5.5132483225],
            [-38.9448220487, -5.4910015825],
            [-38.9351988549, -5.4850011834],
            [-38.9348215798, -5.4851397727],
            [-38.9279482882, -5.4837027575],
            [-38.9244837428, -5.4579057195],
            [-38.9110611364, -5.4554022101],
            [-38.9075554551, -5.4572283965],
            [-38.9069677938, -5.4667484087],
            [-38.9015938101, -5.4694381735],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313104',
        name: 'Tabuleiro do Norte',
        description: 'Tabuleiro do Norte',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.1423150409, -5.2107598429],
            [-38.1405263521, -5.2112287126],
            [-38.1276065153, -5.209327575],
            [-38.1146545564, -5.1996994522],
            [-38.1077428155, -5.1957083866],
            [-38.0914117287, -5.1905448398],
            [-38.0723697436, -5.1812284526],
            [-38.0674406056, -5.1879694384],
            [-38.0541897007, -5.1967633281],
            [-38.0148879483, -5.2246370369],
            [-38.0045755332, -5.2364033059],
            [-37.9934226641, -5.245449403],
            [-37.9672007223, -5.254032265],
            [-37.9537512448, -5.2733651837],
            [-37.9249777322, -5.2968069191],
            [-37.916958689, -5.2968049557],
            [-37.7865246108, -5.2967794214],
            [-37.8124569688, -5.31617926],
            [-37.8353669787, -5.3349965641],
            [-37.8471248132, -5.3396329929],
            [-37.8633902977, -5.3737002068],
            [-37.858970112, -5.3953439619],
            [-37.8601358003, -5.4062950035],
            [-37.8706182746, -5.4199180984],
            [-37.8808817894, -5.4357933077],
            [-37.9158431877, -5.4366082834],
            [-37.9516569475, -5.4362533851],
            [-37.9606833026, -5.4361631409],
            [-38.0086579257, -5.4359278031],
            [-38.0091562262, -5.4359265831],
            [-38.0118987305, -5.4359181721],
            [-38.0667884185, -5.4357510065],
            [-38.0757001633, -5.435724063],
            [-38.1114065066, -5.4356896836],
            [-38.115814277, -5.4356855771],
            [-38.1772541527, -5.4356215054],
            [-38.1915922973, -5.4356053313],
            [-38.1686999203, -5.3815651636],
            [-38.168278615, -5.3805708195],
            [-38.1643074418, -5.3712000212],
            [-38.1627859922, -5.3676103153],
            [-38.1584524576, -5.3573866109],
            [-38.1720650823, -5.3467487899],
            [-38.1785908429, -5.3328592611],
            [-38.1855765213, -5.3315709547],
            [-38.1917866225, -5.3248540408],
            [-38.2013093765, -5.3267558172],
            [-38.2043348436, -5.3219492042],
            [-38.2117228182, -5.3196573014],
            [-38.2070893039, -5.3174573582],
            [-38.2066881561, -5.3133294663],
            [-38.2147804045, -5.3109116364],
            [-38.2173082018, -5.297990069],
            [-38.221863803, -5.2965627589],
            [-38.2254999991, -5.2885269512],
            [-38.225914941, -5.2819939887],
            [-38.2112806371, -5.2445496767],
            [-38.2242310957, -5.2269273976],
            [-38.2243937904, -5.2267055991],
            [-38.2325780134, -5.2155675978],
            [-38.2288623575, -5.2068157893],
            [-38.2250032872, -5.2051197914],
            [-38.2236781775, -5.1941120005],
            [-38.2362757044, -5.1787530115],
            [-38.2384651457, -5.1688793688],
            [-38.2310958623, -5.161254129],
            [-38.207313812, -5.1937176993],
            [-38.1648236437, -5.2048597189],
            [-38.150881435, -5.2085142013],
            [-38.1423150409, -5.2107598429],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313203',
        name: 'Tamboril',
        description: 'Tamboril',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.204231938, -4.7900483118],
            [-40.1958390722, -4.7914430392],
            [-40.1789217003, -4.7879731637],
            [-40.1581871773, -4.8042217816],
            [-40.1551937207, -4.8093478761],
            [-40.1452907739, -4.8077347306],
            [-40.1420245234, -4.813705735],
            [-40.138276332, -4.823003746],
            [-40.111171237, -4.8499985364],
            [-40.106474481, -4.8710542983],
            [-40.1129226459, -4.881824664],
            [-40.1365267906, -4.8888905276],
            [-40.139562993, -4.8984776577],
            [-40.1440584962, -4.902163364],
            [-40.1590349848, -4.9022196742],
            [-40.1683087185, -4.8981409391],
            [-40.1970293416, -4.9059402099],
            [-40.1949755071, -4.908330249],
            [-40.1653522047, -4.9376358795],
            [-40.1650861102, -4.9644999474],
            [-40.1571662714, -4.9692321252],
            [-40.1360315568, -4.9733001553],
            [-40.1333080551, -4.9771374725],
            [-40.1364483501, -4.9800560406],
            [-40.1307452049, -4.9870810613],
            [-40.1333844784, -4.9919131198],
            [-40.1192006285, -4.9851957373],
            [-40.1154235883, -4.9878853262],
            [-40.1183594303, -4.9954104006],
            [-40.1142539502, -5.0001141105],
            [-40.1047773974, -4.9970105656],
            [-40.0996977861, -5.0012905248],
            [-40.1078683177, -5.0236907],
            [-40.1218306835, -5.0297888084],
            [-40.136714408, -5.0291844529],
            [-40.1499394077, -5.0335170843],
            [-40.1542949419, -5.043468822],
            [-40.1474117797, -5.0502313372],
            [-40.1474537439, -5.0505846227],
            [-40.1485395806, -5.0597954699],
            [-40.1421416033, -5.07379224],
            [-40.1510973957, -5.0757629331],
            [-40.1516140371, -5.075897618],
            [-40.1537010055, -5.0767426301],
            [-40.1550601628, -5.0811064251],
            [-40.1501254039, -5.0826671677],
            [-40.149592309, -5.0910271178],
            [-40.1352269667, -5.0990823621],
            [-40.1398089007, -5.1048810298],
            [-40.1346433849, -5.1113144805],
            [-40.1369452736, -5.1218901169],
            [-40.1344240843, -5.130784154],
            [-40.1457828305, -5.1461748849],
            [-40.1450867833, -5.152247056],
            [-40.162214329, -5.1550534812],
            [-40.1656711681, -5.1590272115],
            [-40.1650056429, -5.1647322491],
            [-40.1652492692, -5.1650160263],
            [-40.1702215739, -5.1744573992],
            [-40.1806126447, -5.170019174],
            [-40.1882548386, -5.1757380376],
            [-40.1866693432, -5.1678347849],
            [-40.1983111916, -5.1648356443],
            [-40.2114418013, -5.1557516534],
            [-40.2187567277, -5.1519594746],
            [-40.2280344553, -5.1522426321],
            [-40.2389919495, -5.1624728751],
            [-40.2416665541, -5.1706520529],
            [-40.248404695, -5.167869001],
            [-40.2577928948, -5.1712645966],
            [-40.2571999385, -5.1659775031],
            [-40.2695833099, -5.1622610631],
            [-40.2980273003, -5.1700122128],
            [-40.3054180925, -5.1667447573],
            [-40.3109613, -5.1563658766],
            [-40.3312935271, -5.1691562519],
            [-40.3548781102, -5.1861618498],
            [-40.3812657896, -5.2051931191],
            [-40.406863071, -5.2236564651],
            [-40.4141967763, -5.2289463476],
            [-40.3915980425, -5.1666523805],
            [-40.3906753546, -5.164110105],
            [-40.3729243246, -5.1152102884],
            [-40.3728808779, -5.115091517],
            [-40.3769476958, -5.1105565049],
            [-40.3741412445, -5.1026353245],
            [-40.3996141782, -5.0923439655],
            [-40.4037367764, -5.0831789555],
            [-40.421243634, -5.0710224523],
            [-40.4220441874, -5.0642149678],
            [-40.4317697224, -5.0507291029],
            [-40.4461765215, -5.0342766832],
            [-40.4490495396, -5.0350766266],
            [-40.4512576869, -5.043960078],
            [-40.4625601681, -5.0456350265],
            [-40.4952176107, -5.0354091438],
            [-40.5056733469, -5.023702686],
            [-40.5207433751, -5.0215739885],
            [-40.5380344351, -5.0130628247],
            [-40.5562534606, -5.0140553435],
            [-40.5666104143, -5.0185558848],
            [-40.594371473, -4.9502484507],
            [-40.6354497739, -4.9243361364],
            [-40.5967579912, -4.9203140548],
            [-40.5420839082, -4.9148046882],
            [-40.5366749266, -4.8997870021],
            [-40.5399776086, -4.8924588483],
            [-40.5395989336, -4.8767406801],
            [-40.5458101956, -4.8679186812],
            [-40.5398369657, -4.843412951],
            [-40.522440011, -4.8477379611],
            [-40.4977374686, -4.8108460595],
            [-40.4923193342, -4.8024489859],
            [-40.4826387759, -4.7874432991],
            [-40.4572596071, -4.7481126676],
            [-40.4466596341, -4.7316890192],
            [-40.4402719333, -4.7128408607],
            [-40.4378439843, -4.7056778474],
            [-40.4228705312, -4.6615170092],
            [-40.4227333117, -4.6611123752],
            [-40.3743901879, -4.5535305145],
            [-40.3464691642, -4.6318928466],
            [-40.3417651533, -4.6450855485],
            [-40.3366118571, -4.6545368031],
            [-40.3367064811, -4.6643428755],
            [-40.3312013443, -4.6666756396],
            [-40.3222482902, -4.6836361916],
            [-40.3142217888, -4.6892188811],
            [-40.3148649756, -4.7068444978],
            [-40.3051130989, -4.7164874578],
            [-40.3040404576, -4.7225256626],
            [-40.3103193264, -4.7231702948],
            [-40.314432593, -4.7251302553],
            [-40.3131542937, -4.7279672528],
            [-40.2844727229, -4.7496943797],
            [-40.268686972, -4.77308181],
            [-40.2448413181, -4.7772217867],
            [-40.2212561616, -4.788687915],
            [-40.204231938, -4.7900483118],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313252',
        name: 'Tarrafas',
        description: 'Tarrafas',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.7391315669, -6.6320352376],
            [-39.7302499427, -6.6402324026],
            [-39.7229241741, -6.6373203904],
            [-39.719522407, -6.6394139116],
            [-39.7125807472, -6.6348260513],
            [-39.705218081, -6.6378440938],
            [-39.701005772, -6.6442906216],
            [-39.687725574, -6.6369516578],
            [-39.6831777248, -6.6388493283],
            [-39.6803606325, -6.6399162603],
            [-39.6657547656, -6.6369798548],
            [-39.6582776481, -6.6512862476],
            [-39.6591464023, -6.6573887971],
            [-39.6548136087, -6.6653793373],
            [-39.6569917769, -6.6698773424],
            [-39.655145064, -6.6738696829],
            [-39.6488347517, -6.6747337856],
            [-39.6458942326, -6.7156515792],
            [-39.6385273126, -6.7210733731],
            [-39.6414806344, -6.7326391667],
            [-39.6457735885, -6.7367364754],
            [-39.64343408, -6.7376514755],
            [-39.6415737264, -6.7387617074],
            [-39.6329705322, -6.7383470008],
            [-39.6272959388, -6.7337971892],
            [-39.6252176077, -6.7393284172],
            [-39.6191114208, -6.7417286284],
            [-39.6064741473, -6.7359317322],
            [-39.6048323702, -6.7379847062],
            [-39.6103370415, -6.7478952115],
            [-39.5973881772, -6.7497313911],
            [-39.6164840407, -6.7899675204],
            [-39.6237660251, -6.7972720079],
            [-39.6312520519, -6.7924766485],
            [-39.6513304931, -6.8020805274],
            [-39.657404931, -6.7968840856],
            [-39.6566929496, -6.7916995874],
            [-39.6654194874, -6.7928844475],
            [-39.6543713218, -6.8203437927],
            [-39.6906004644, -6.8302233199],
            [-39.6961849324, -6.8057643719],
            [-39.7000178873, -6.8002758686],
            [-39.6953084005, -6.7960087906],
            [-39.7064701845, -6.7912590458],
            [-39.7077818062, -6.7848186624],
            [-39.7217405714, -6.7849664941],
            [-39.7233888774, -6.7888986294],
            [-39.7403612039, -6.7990769038],
            [-39.7405460503, -6.8052327524],
            [-39.7744309923, -6.8023871355],
            [-39.7865585666, -6.8057886878],
            [-39.7894368151, -6.8065949796],
            [-39.7974271012, -6.8088366028],
            [-39.8810373046, -6.8322832192],
            [-39.8727921866, -6.8244746977],
            [-39.8716907697, -6.8176923507],
            [-39.8835530759, -6.8036817512],
            [-39.8855854042, -6.7886093207],
            [-39.8986108835, -6.7809293594],
            [-39.9092554593, -6.7631287663],
            [-39.8783099342, -6.7347256755],
            [-39.8533328937, -6.7155415529],
            [-39.83724659, -6.7081096676],
            [-39.832276022, -6.6994593776],
            [-39.8103337845, -6.6877077989],
            [-39.7952207138, -6.6724387976],
            [-39.7831315113, -6.6687668956],
            [-39.7752012022, -6.656676156],
            [-39.7781260624, -6.6424670652],
            [-39.7719359902, -6.6335137084],
            [-39.7737422202, -6.6275628924],
            [-39.7629856934, -6.6249596847],
            [-39.7381137911, -6.6110092072],
            [-39.7340678837, -6.6174768668],
            [-39.7391315669, -6.6320352376],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313302',
        name: 'Tauá',
        description: 'Tauá',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.043632886, -5.4888344191],
            [-40.0386019296, -5.4906389636],
            [-40.0323324971, -5.485139631],
            [-40.0256411777, -5.4899256658],
            [-40.0297646279, -5.4958128761],
            [-40.0307260547, -5.5074192936],
            [-40.0254665559, -5.5084581792],
            [-40.0250968377, -5.5144988633],
            [-40.0208631714, -5.5132110866],
            [-40.0133211846, -5.5194409248],
            [-40.0180682572, -5.5288916142],
            [-40.0143451293, -5.533877578],
            [-40.0345263137, -5.5647499247],
            [-40.0447272134, -5.5655265762],
            [-40.0532335389, -5.5738506669],
            [-40.0776427859, -5.5831186195],
            [-40.0755673897, -5.58922233],
            [-40.0642527561, -5.5893885499],
            [-40.0550667853, -5.5971524569],
            [-40.0589669704, -5.6078718534],
            [-40.0512986858, -5.6098600299],
            [-40.0498765125, -5.619732948],
            [-40.0633391104, -5.6364727475],
            [-40.0533826217, -5.6404100487],
            [-40.0732435332, -5.6683522211],
            [-40.0711676808, -5.6742205617],
            [-40.0662330282, -5.6764677566],
            [-40.0649631996, -5.6883742488],
            [-40.0552903086, -5.6874206425],
            [-40.053682316, -5.6928134812],
            [-40.047427645, -5.6956237573],
            [-40.0440452949, -5.7105974577],
            [-40.0214708139, -5.7104749114],
            [-40.0154677047, -5.7069958236],
            [-40.0078680195, -5.7142882515],
            [-40.008178894, -5.7344486651],
            [-40.0025586762, -5.7353737122],
            [-40.0073854472, -5.7475026947],
            [-40.0031843273, -5.7634997999],
            [-40.0089218645, -5.7742289916],
            [-39.9922059412, -5.7796617357],
            [-39.9898506922, -5.7913835003],
            [-39.9853895692, -5.7985800061],
            [-39.9856592791, -5.8133228445],
            [-39.9747943, -5.8212355367],
            [-39.9664280758, -5.8218318275],
            [-39.9604131922, -5.8163140291],
            [-39.9540157693, -5.8210232652],
            [-39.9498488497, -5.8216444043],
            [-39.9483535007, -5.8255488702],
            [-39.9350217943, -5.8259092184],
            [-39.920012842, -5.8321989692],
            [-39.9320951078, -5.8539549506],
            [-39.937893308, -5.8566780371],
            [-39.9401406592, -5.8622347564],
            [-39.9461666544, -5.8624404405],
            [-39.9594666018, -5.872706492],
            [-39.95361382, -5.8731767772],
            [-39.9465329322, -5.8797391925],
            [-39.938206249, -5.8795593954],
            [-39.9388263763, -5.8834904923],
            [-39.9319389818, -5.8890012015],
            [-39.9269427323, -5.8881183004],
            [-39.9205193976, -5.8967888886],
            [-39.9108701811, -5.9004595562],
            [-39.9055926165, -5.8967840864],
            [-39.8906942198, -5.8961333583],
            [-39.8766378619, -5.8845448973],
            [-39.8699462493, -5.8832733512],
            [-39.8676034962, -5.8893175525],
            [-39.8607060821, -5.8892997015],
            [-39.865218078, -5.8927021625],
            [-39.8639632059, -5.8950864825],
            [-39.8623124825, -5.8974065884],
            [-39.8687810495, -5.898223565],
            [-39.8772596992, -5.909699664],
            [-39.8654085602, -5.9137047389],
            [-39.858383041, -5.919834622],
            [-39.8533075069, -5.9218993922],
            [-39.853470535, -5.928317428],
            [-39.8494013906, -5.9330844791],
            [-39.8368262173, -5.9288035692],
            [-39.8337608179, -5.9563030728],
            [-39.8318493229, -5.9630270308],
            [-39.8347817804, -5.975893112],
            [-39.8445383049, -5.9807933893],
            [-39.8416321125, -5.9851161938],
            [-39.8472501236, -5.9896217471],
            [-39.8553079064, -5.9867543188],
            [-39.8624334983, -5.9950975429],
            [-39.8628015107, -6.0010004638],
            [-39.8683321912, -6.0057704158],
            [-39.8737082494, -6.0055294512],
            [-39.8726559016, -6.008370584],
            [-39.8719675363, -6.0138583064],
            [-39.8828924703, -6.0199301512],
            [-39.8892875464, -6.0340333527],
            [-39.9058893581, -6.0362527744],
            [-39.905282732, -6.0486584443],
            [-39.910100255, -6.0580458689],
            [-39.9207462511, -6.0677678713],
            [-39.9347377764, -6.0793587457],
            [-39.9486823249, -6.0818460896],
            [-39.9500696437, -6.0795519281],
            [-39.9626088369, -6.0874764461],
            [-39.9632849088, -6.0931007821],
            [-39.9566703492, -6.0992503178],
            [-39.962579183, -6.0995737896],
            [-39.9636161864, -6.1023149605],
            [-39.9743369008, -6.099823808],
            [-39.9862231164, -6.1023870777],
            [-40.0126256094, -6.1026287515],
            [-40.0164111514, -6.096065072],
            [-40.0311429455, -6.0985025749],
            [-40.0401805909, -6.104195076],
            [-40.0454168378, -6.0971292764],
            [-40.0660830392, -6.1104831266],
            [-40.0727634087, -6.1079070189],
            [-40.0839487665, -6.1140417298],
            [-40.0865861862, -6.1087783159],
            [-40.1079142652, -6.1164195202],
            [-40.1158466405, -6.1111451438],
            [-40.1234352212, -6.1139616941],
            [-40.1253540415, -6.1096864699],
            [-40.1416665092, -6.1136629783],
            [-40.1578596699, -6.1178879457],
            [-40.1613834533, -6.1259069171],
            [-40.1632732792, -6.1312707929],
            [-40.1798072029, -6.1402225044],
            [-40.1968656742, -6.1447667451],
            [-40.2049189119, -6.1333352056],
            [-40.2120673971, -6.1353771721],
            [-40.2122074658, -6.1355846574],
            [-40.2204182446, -6.1420635692],
            [-40.2204468813, -6.1352773328],
            [-40.2257789227, -6.131446044],
            [-40.2323769773, -6.1327319797],
            [-40.2549826788, -6.1480056007],
            [-40.2600286685, -6.1568017016],
            [-40.2602762194, -6.1667345295],
            [-40.2648169367, -6.1679405807],
            [-40.2869476803, -6.1921506715],
            [-40.290073209, -6.2044138871],
            [-40.3008641673, -6.2059387462],
            [-40.3009695266, -6.2059537104],
            [-40.4050064359, -6.3126661218],
            [-40.4088047806, -6.3169053414],
            [-40.4221412587, -6.3195065053],
            [-40.4255941355, -6.3246031413],
            [-40.42968368, -6.3248953644],
            [-40.4324913515, -6.3334818019],
            [-40.4391002139, -6.3324996225],
            [-40.4402833315, -6.3324786496],
            [-40.4464621661, -6.3317212547],
            [-40.4558155961, -6.3364666717],
            [-40.4615224139, -6.3330567841],
            [-40.4694702741, -6.3344906583],
            [-40.471145602, -6.3301253225],
            [-40.4788469481, -6.3321275294],
            [-40.4817347909, -6.3374766122],
            [-40.4987609965, -6.3434784314],
            [-40.507467812, -6.3433690156],
            [-40.5110601401, -6.339465232],
            [-40.5182887664, -6.3467647336],
            [-40.5324816335, -6.3479613408],
            [-40.542976376, -6.3399651274],
            [-40.5537604274, -6.3422316663],
            [-40.5535081691, -6.3473345243],
            [-40.5661677629, -6.350927106],
            [-40.5748239873, -6.3607839141],
            [-40.5817539721, -6.340496145],
            [-40.5919730047, -6.3404646965],
            [-40.6042078901, -6.348489482],
            [-40.6087029919, -6.3377981608],
            [-40.5896017251, -6.3142186693],
            [-40.5894499209, -6.3006753071],
            [-40.5874626699, -6.2941446803],
            [-40.5776378437, -6.2883983285],
            [-40.5719704237, -6.2934198864],
            [-40.5654280913, -6.2928299566],
            [-40.5553993226, -6.2898432381],
            [-40.553863246, -6.2854606831],
            [-40.5329848175, -6.2794328299],
            [-40.5343703745, -6.276125641],
            [-40.5188231011, -6.2698901705],
            [-40.5072117702, -6.2569173279],
            [-40.5032311027, -6.2563847155],
            [-40.4950428412, -6.2433813828],
            [-40.4952018533, -6.2265950012],
            [-40.4894826402, -6.2216017446],
            [-40.4921524186, -6.2164335516],
            [-40.4886327933, -6.2023649019],
            [-40.5039024495, -6.1876974935],
            [-40.5331761461, -6.1609476868],
            [-40.5259922073, -6.1409198232],
            [-40.5038694171, -6.1010004852],
            [-40.4964296462, -6.0875770754],
            [-40.4942514612, -6.0836476185],
            [-40.4869280805, -6.0811333449],
            [-40.488801571, -6.0775684338],
            [-40.4856389082, -6.0734752747],
            [-40.4858706099, -6.0655206369],
            [-40.4778297526, -6.0522437912],
            [-40.4811110164, -6.0331052293],
            [-40.4717859463, -6.0274266485],
            [-40.4681811172, -6.0204633158],
            [-40.4646550353, -6.0154989888],
            [-40.4755621108, -6.0068911657],
            [-40.4740559091, -6.0014272072],
            [-40.4860526678, -5.9822393911],
            [-40.491885796, -5.9817928809],
            [-40.4980144872, -5.990879785],
            [-40.5068336141, -5.9912688919],
            [-40.5169713926, -5.9871551948],
            [-40.5259091819, -5.9953083384],
            [-40.5356871968, -5.9953636094],
            [-40.5363204459, -6.0003588179],
            [-40.5419488413, -6.0046778649],
            [-40.5516629059, -6.0037914338],
            [-40.5528836711, -6.0084154206],
            [-40.562525703, -6.0034675289],
            [-40.564368106, -5.9990416121],
            [-40.5781570163, -5.9982174038],
            [-40.5882938943, -5.9925271601],
            [-40.5941189682, -6.0024697756],
            [-40.5974170655, -6.0030380115],
            [-40.5983771909, -5.9940018037],
            [-40.6142863954, -5.9894604605],
            [-40.6174206149, -5.9933568073],
            [-40.6336941913, -5.9976580893],
            [-40.6372158256, -6.0022200493],
            [-40.6758985219, -6.0091818872],
            [-40.6950547399, -6.0181289404],
            [-40.7015551916, -6.0250785566],
            [-40.7031710841, -6.0335561045],
            [-40.7154269526, -6.0401472423],
            [-40.7341043684, -6.0298948555],
            [-40.7342310673, -6.0218074521],
            [-40.7405983382, -6.0130130452],
            [-40.7375214316, -5.9922176217],
            [-40.7453006275, -5.9885269264],
            [-40.7461951756, -5.9829233256],
            [-40.7364071886, -5.9735207466],
            [-40.7286044896, -5.9728792352],
            [-40.7188214228, -5.9644976545],
            [-40.7094020363, -5.9671965746],
            [-40.7140151935, -5.9595166392],
            [-40.7098343814, -5.9479487869],
            [-40.7156365752, -5.9411182638],
            [-40.7054335686, -5.9387176089],
            [-40.7056495532, -5.9301963381],
            [-40.6973722657, -5.9237722842],
            [-40.6872344882, -5.9144388012],
            [-40.6632351078, -5.914038254],
            [-40.6623905344, -5.9117021045],
            [-40.673290279, -5.9033144926],
            [-40.6741762017, -5.8924326534],
            [-40.6816810086, -5.8799429189],
            [-40.6803814241, -5.871197066],
            [-40.6728420824, -5.8691689807],
            [-40.6654845371, -5.8515027996],
            [-40.6520910052, -5.8442914427],
            [-40.6415359781, -5.8396455424],
            [-40.6281506262, -5.8525214488],
            [-40.6184500647, -5.852099888],
            [-40.6016808316, -5.8436216291],
            [-40.6020783501, -5.8377946293],
            [-40.5867064312, -5.8344002671],
            [-40.5856125619, -5.8377612259],
            [-40.5650381583, -5.8416306763],
            [-40.5570075843, -5.8420389179],
            [-40.5481987003, -5.8319465062],
            [-40.5331198071, -5.8239370245],
            [-40.5298969249, -5.8164236433],
            [-40.5237969413, -5.8172748809],
            [-40.5208899173, -5.8132373593],
            [-40.5115887151, -5.8151091403],
            [-40.5159584386, -5.8245562368],
            [-40.4941594224, -5.8276473792],
            [-40.4939496366, -5.827694415],
            [-40.4854085764, -5.8347113421],
            [-40.4762258588, -5.8317005813],
            [-40.4729632963, -5.8260779729],
            [-40.4529940611, -5.8281976381],
            [-40.4445673317, -5.8257493948],
            [-40.4170057795, -5.8116484534],
            [-40.4119952948, -5.8100433627],
            [-40.3976112427, -5.7806029971],
            [-40.3883387955, -5.7764463147],
            [-40.3820838853, -5.7677509838],
            [-40.3806148058, -5.759000121],
            [-40.3709858427, -5.7526436999],
            [-40.3681174045, -5.7453906654],
            [-40.3516956027, -5.7433186736],
            [-40.3409548017, -5.7481401444],
            [-40.3196081902, -5.7422947497],
            [-40.3118702195, -5.7462483575],
            [-40.3116419975, -5.7464233408],
            [-40.2960546096, -5.7496947684],
            [-40.2842633463, -5.7575963201],
            [-40.2816617237, -5.7514886256],
            [-40.2558007446, -5.7284892664],
            [-40.2408134175, -5.7192397042],
            [-40.2405255028, -5.7078017214],
            [-40.2364015213, -5.7093948155],
            [-40.2256048051, -5.7093298468],
            [-40.2216768316, -5.6992630282],
            [-40.2105561487, -5.6913214027],
            [-40.2201622487, -5.6674575851],
            [-40.2308984385, -5.6595580963],
            [-40.2272323908, -5.6486979259],
            [-40.2336456513, -5.6307793359],
            [-40.2293638345, -5.6187765181],
            [-40.2328218432, -5.6143233846],
            [-40.2241314464, -5.6064859651],
            [-40.2196352357, -5.6044093453],
            [-40.2289280796, -5.599726461],
            [-40.2266770578, -5.5929993052],
            [-40.1944190847, -5.5740347157],
            [-40.1859066847, -5.5704686093],
            [-40.1851430082, -5.5616256013],
            [-40.1795203231, -5.5585581651],
            [-40.1728088475, -5.5599348776],
            [-40.1668110076, -5.5448108445],
            [-40.1587833298, -5.5456638237],
            [-40.1430660765, -5.5408538645],
            [-40.1410986354, -5.5456526548],
            [-40.1238265611, -5.5543111831],
            [-40.1187227726, -5.5481599212],
            [-40.1151077896, -5.5386629738],
            [-40.110009235, -5.5347127602],
            [-40.1088995969, -5.5270140995],
            [-40.111731141, -5.5167589984],
            [-40.1080279323, -5.5060296447],
            [-40.1119109247, -5.4909604987],
            [-40.1062554196, -5.4671820483],
            [-40.1091549428, -5.4620221682],
            [-40.1066998786, -5.4520951255],
            [-40.1007912641, -5.451370283],
            [-40.0974663132, -5.4464706366],
            [-40.1009237726, -5.4433276281],
            [-40.0897541876, -5.4402839131],
            [-40.0906285285, -5.4358617336],
            [-40.0834148989, -5.4275373885],
            [-40.08296452, -5.4223230097],
            [-40.0772915018, -5.4211822505],
            [-40.0686819362, -5.4028140014],
            [-40.0583072461, -5.3964660694],
            [-40.0653192707, -5.4116304121],
            [-40.0612355591, -5.4123132316],
            [-40.061098255, -5.4177652701],
            [-40.0677209457, -5.4251495373],
            [-40.0611130599, -5.4265461447],
            [-40.0603538921, -5.4297877912],
            [-40.0649166839, -5.433050357],
            [-40.0563674042, -5.4338610726],
            [-40.0570321824, -5.4384955025],
            [-40.0554165876, -5.4620891081],
            [-40.0495124634, -5.462895092],
            [-40.0470519578, -5.4669080706],
            [-40.053580929, -5.4875497216],
            [-40.043632886, -5.4888344191],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313351',
        name: 'Tejuçuoca',
        description: 'Tejuçuoca',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.492320462, -3.7593242932],
            [-39.4926010811, -3.8183981975],
            [-39.4882072366, -3.8171246943],
            [-39.487679595, -3.8130790109],
            [-39.4796636555, -3.8179851297],
            [-39.4761496663, -3.8572591855],
            [-39.4592001687, -3.8572055665],
            [-39.4567532569, -3.8613188315],
            [-39.4391511417, -3.8529096735],
            [-39.4290151605, -3.854069314],
            [-39.4345859921, -3.8624733818],
            [-39.4517942503, -3.9129618289],
            [-39.4583345277, -3.9305229978],
            [-39.4655424558, -3.9427288125],
            [-39.4837910203, -3.9751514633],
            [-39.4846086466, -3.9995341298],
            [-39.4937026931, -3.9956506033],
            [-39.5077531919, -4.0021858467],
            [-39.5168293896, -4.0311625337],
            [-39.5329266808, -4.0825866803],
            [-39.540271898, -4.0896895189],
            [-39.5450846542, -4.1010853779],
            [-39.5472175836, -4.0985344215],
            [-39.5565622878, -4.1021502677],
            [-39.5752816292, -4.0921088585],
            [-39.5761271756, -4.0922209512],
            [-39.5852021099, -4.0953769467],
            [-39.5972873505, -4.0940452037],
            [-39.6065348814, -4.0831368158],
            [-39.6106179872, -4.0793150554],
            [-39.616920724, -4.079393089],
            [-39.6191019735, -4.0753398628],
            [-39.6252263481, -4.0795383265],
            [-39.6278353415, -4.0694220592],
            [-39.643142745, -4.072219678],
            [-39.6661112206, -4.0681623146],
            [-39.6645876159, -4.0812948809],
            [-39.6712563407, -4.0742340583],
            [-39.6752412897, -4.0555289319],
            [-39.6842046433, -4.0354447221],
            [-39.6921295424, -4.0222200718],
            [-39.6953655528, -4.0218174364],
            [-39.7080856349, -4.0172986941],
            [-39.708063458, -4.0113435381],
            [-39.6998086664, -4.0035453325],
            [-39.7042867211, -4.0013513715],
            [-39.7033918612, -3.9930282817],
            [-39.7079459254, -3.9767453375],
            [-39.7193699433, -3.9708520346],
            [-39.7139195375, -3.9580041697],
            [-39.716033394, -3.9545933033],
            [-39.7048341023, -3.9465898138],
            [-39.710768995, -3.9289922247],
            [-39.7118721739, -3.9229724511],
            [-39.7161714249, -3.9177178889],
            [-39.7113104467, -3.9030217322],
            [-39.7175022269, -3.898538388],
            [-39.7155613733, -3.8935321012],
            [-39.7195362223, -3.886614647],
            [-39.7124204938, -3.8850941149],
            [-39.721114209, -3.8715667029],
            [-39.7130001928, -3.868026909],
            [-39.7075835683, -3.8530607084],
            [-39.6932805118, -3.8437127529],
            [-39.6922557845, -3.8357140623],
            [-39.6828641216, -3.837307318],
            [-39.679524721, -3.8326767464],
            [-39.6669245371, -3.8315850244],
            [-39.6616283228, -3.8243649524],
            [-39.6415095543, -3.8206819522],
            [-39.6344996908, -3.8283120786],
            [-39.629053192, -3.8256533433],
            [-39.6236394917, -3.8289503814],
            [-39.6221977346, -3.8242527225],
            [-39.6139238436, -3.8205924135],
            [-39.5935566776, -3.8252670845],
            [-39.5880716141, -3.8226588455],
            [-39.5819205255, -3.8208261628],
            [-39.5782693432, -3.8129479781],
            [-39.5761933562, -3.8107033303],
            [-39.5360442612, -3.7797817326],
            [-39.5357571796, -3.7795605968],
            [-39.5203772711, -3.7677174484],
            [-39.5151764245, -3.7459263724],
            [-39.492320462, -3.7593242932],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313401',
        name: 'Tianguá',
        description: 'Tianguá',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.8900432233, -3.6699987426],
            [-40.8750150254, -3.7228676307],
            [-40.8803405909, -3.738298591],
            [-40.8925189685, -3.7736002591],
            [-40.8934001555, -3.77615496],
            [-40.8950124215, -3.7808312308],
            [-40.9007814131, -3.7795679375],
            [-40.9037178062, -3.7831437101],
            [-40.9097649551, -3.7888975514],
            [-40.9230339612, -3.7883496595],
            [-40.9383941906, -3.8005502093],
            [-40.9513889788, -3.8050503394],
            [-40.9572468559, -3.810697735],
            [-40.9612101989, -3.8170524679],
            [-40.9612290874, -3.8250845573],
            [-40.9671091456, -3.8329597465],
            [-40.9678873685, -3.8347547581],
            [-40.9704768098, -3.8407228553],
            [-40.973865169, -3.8423532057],
            [-40.9818311457, -3.8394974111],
            [-41.0070916546, -3.8406660653],
            [-41.0223095636, -3.8448937383],
            [-41.0255516247, -3.846323693],
            [-41.0358707337, -3.8498253704],
            [-41.0440894, -3.8454626096],
            [-41.048980323, -3.8435986984],
            [-41.0551991865, -3.8401581928],
            [-41.0860186774, -3.8383433796],
            [-41.1092557418, -3.8444260728],
            [-41.1325517533, -3.8430560791],
            [-41.1616152529, -3.8727584597],
            [-41.1789426646, -3.88357804],
            [-41.1815180365, -3.8923713529],
            [-41.1909300551, -3.8908331755],
            [-41.1934252951, -3.8952281469],
            [-41.2100811176, -3.900822272],
            [-41.2453201846, -3.8959247598],
            [-41.247235316, -3.8819113641],
            [-41.2584829662, -3.869901662],
            [-41.2595807715, -3.8643744526],
            [-41.2673829789, -3.8614732363],
            [-41.2701610733, -3.8527074595],
            [-41.2682071396, -3.8441168853],
            [-41.2764820579, -3.8252447283],
            [-41.2822988282, -3.8264351276],
            [-41.2870896459, -3.821881374],
            [-41.2947856159, -3.8298019438],
            [-41.3004397425, -3.8259225025],
            [-41.2644543746, -3.7912021734],
            [-41.2537488604, -3.7746653983],
            [-41.2608087009, -3.7571667961],
            [-41.256988741, -3.7295248631],
            [-41.2515145908, -3.7229642052],
            [-41.2390555161, -3.7203740815],
            [-41.2389650459, -3.7122698754],
            [-41.2253452298, -3.7124787576],
            [-41.2208878793, -3.718514066],
            [-41.2164052015, -3.7137869924],
            [-41.2072915353, -3.7089309777],
            [-41.1959487742, -3.6956262029],
            [-41.1930096686, -3.7035026802],
            [-41.1504411112, -3.696313894],
            [-41.1348315229, -3.7024439329],
            [-41.1316625712, -3.7116302733],
            [-41.1212780664, -3.7110015379],
            [-41.1087206724, -3.6993365099],
            [-41.0801630516, -3.6906572694],
            [-41.0655980829, -3.6774526907],
            [-41.0655312763, -3.6774041208],
            [-41.0511010108, -3.6648208837],
            [-41.0388782594, -3.6645931652],
            [-41.0386433599, -3.6645889563],
            [-41.0287079348, -3.6575457122],
            [-41.0251915761, -3.6532397305],
            [-41.0237972838, -3.6466307086],
            [-41.0154378734, -3.6439160549],
            [-41.0161832789, -3.639651978],
            [-40.9890946745, -3.6255305876],
            [-40.9678263536, -3.6105647819],
            [-40.9412560863, -3.5864944094],
            [-40.9329231974, -3.5851343243],
            [-40.9320122488, -3.5808307925],
            [-40.9119513627, -3.5662339038],
            [-40.9047494079, -3.5571554971],
            [-40.8845061815, -3.5383535218],
            [-40.8765036231, -3.5321666591],
            [-40.8884758064, -3.5220686388],
            [-40.8695788851, -3.5071061974],
            [-40.8744052194, -3.4997956708],
            [-40.883266195, -3.4973912782],
            [-40.8830804339, -3.4871546992],
            [-40.8681126817, -3.4793571672],
            [-40.8683351788, -3.4603790464],
            [-40.8645835189, -3.4499187169],
            [-40.848744649, -3.4319462499],
            [-40.8519604926, -3.4190375569],
            [-40.8490443104, -3.4193782457],
            [-40.8267803476, -3.4538144768],
            [-40.8213282833, -3.4710036812],
            [-40.8314875605, -3.4997137728],
            [-40.8568282733, -3.5358792817],
            [-40.8595340682, -3.5376324126],
            [-40.8928658997, -3.559225388],
            [-40.9034638957, -3.5745457303],
            [-40.8967267582, -3.6225052477],
            [-40.8954596057, -3.6315136991],
            [-40.8903912653, -3.6675334597],
            [-40.8900603671, -3.6698747258],
            [-40.8900432233, -3.6699987426],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313500',
        name: 'Trairi',
        description: 'Trairi',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.3449829945, -3.1796548999],
            [-39.3245079731, -3.2012349371],
            [-39.3134269327, -3.2077209059],
            [-39.3065456777, -3.2069498012],
            [-39.2972122469, -3.2150596853],
            [-39.2788850265, -3.2204485557],
            [-39.2637694511, -3.2180897623],
            [-39.2521693806, -3.2217379519],
            [-39.2412990489, -3.2337295758],
            [-39.2259002565, -3.243689019],
            [-39.1873184979, -3.2933936505],
            [-39.1695201745, -3.3232617651],
            [-39.1621418263, -3.3241410821],
            [-39.1596444232, -3.3274461928],
            [-39.1608509253, -3.3312289241],
            [-39.1750937921, -3.329537535],
            [-39.1806687464, -3.3406527392],
            [-39.1956936594, -3.3453150039],
            [-39.2025659554, -3.3545523651],
            [-39.2072159837, -3.3564253035],
            [-39.2157278964, -3.3504335967],
            [-39.23825382, -3.3592698393],
            [-39.2550463649, -3.3598909572],
            [-39.2501819505, -3.3706755139],
            [-39.261286, -3.3779006389],
            [-39.2629161171, -3.3828417229],
            [-39.2617696215, -3.3862746817],
            [-39.2502516081, -3.4044454024],
            [-39.2554541334, -3.4386864213],
            [-39.2528716247, -3.447578897],
            [-39.2571840309, -3.4560106232],
            [-39.2560688133, -3.4654127715],
            [-39.2561454644, -3.4656879221],
            [-39.2634177461, -3.4821626327],
            [-39.2618660416, -3.4897418504],
            [-39.282307767, -3.5011539681],
            [-39.2839441915, -3.5142139598],
            [-39.2865244672, -3.5242822212],
            [-39.2915573243, -3.5285777019],
            [-39.2987277112, -3.5362437787],
            [-39.30955233, -3.5278034235],
            [-39.3078809675, -3.5414516032],
            [-39.3118503252, -3.557245363],
            [-39.309670802, -3.5598751596],
            [-39.306210161, -3.5650679763],
            [-39.3136161969, -3.5720291457],
            [-39.3150650896, -3.5803550172],
            [-39.3317587923, -3.5776410395],
            [-39.3504889549, -3.5735681686],
            [-39.3654002824, -3.5805043655],
            [-39.3748048154, -3.5785334051],
            [-39.3625344777, -3.5601775992],
            [-39.3633099473, -3.5488061107],
            [-39.3509091808, -3.5475477172],
            [-39.3423314986, -3.5391682599],
            [-39.3403634629, -3.5310562149],
            [-39.3340545697, -3.527570618],
            [-39.3370044239, -3.5209781387],
            [-39.3328018464, -3.5166949058],
            [-39.3363394052, -3.5124554884],
            [-39.3315202565, -3.502454598],
            [-39.3317910676, -3.5005375191],
            [-39.3318052664, -3.500437034],
            [-39.3483040557, -3.4886746601],
            [-39.3711992978, -3.4725173612],
            [-39.3753468704, -3.4695905892],
            [-39.4109358929, -3.4444647808],
            [-39.4328065437, -3.4268104719],
            [-39.4360106403, -3.4179984108],
            [-39.4530184371, -3.4073118409],
            [-39.4673369287, -3.4015203834],
            [-39.4706438844, -3.4026710597],
            [-39.4805179915, -3.397525146],
            [-39.4867581516, -3.3813482192],
            [-39.4913925367, -3.3788280605],
            [-39.4907269792, -3.3716743441],
            [-39.4957069767, -3.3695576424],
            [-39.492653236, -3.3670108136],
            [-39.4939795156, -3.3581438825],
            [-39.5028202807, -3.3526036704],
            [-39.5043274669, -3.3403274547],
            [-39.5009990328, -3.3292332588],
            [-39.5037047872, -3.3216517484],
            [-39.5116741541, -3.3138628332],
            [-39.5115224317, -3.3126268157],
            [-39.5050697594, -3.2932135637],
            [-39.5131089244, -3.2892751946],
            [-39.514554137, -3.2829816675],
            [-39.5221846738, -3.2761476189],
            [-39.5197112015, -3.2687033206],
            [-39.5152778588, -3.2550816571],
            [-39.5044590779, -3.2432827492],
            [-39.5050142332, -3.2359778626],
            [-39.5091849008, -3.2330643159],
            [-39.5058553152, -3.2330180575],
            [-39.5091208952, -3.2290350626],
            [-39.5069098516, -3.228426029],
            [-39.505983218, -3.2169574293],
            [-39.5111477091, -3.2163628257],
            [-39.5106010517, -3.2103367966],
            [-39.5073345202, -3.2093532251],
            [-39.5094056259, -3.2061166873],
            [-39.5096648097, -3.2022303513],
            [-39.5055125311, -3.2039643417],
            [-39.5024352235, -3.1993276514],
            [-39.4919280221, -3.2069202585],
            [-39.4907997079, -3.202164279],
            [-39.4874410409, -3.2020198693],
            [-39.485178244, -3.2035702497],
            [-39.4887232579, -3.207647121],
            [-39.4873027181, -3.2123739937],
            [-39.4817087645, -3.2107422813],
            [-39.4839134037, -3.2065511874],
            [-39.4777432686, -3.1991875705],
            [-39.4660581037, -3.2072745231],
            [-39.4560844129, -3.2095371655],
            [-39.4628059395, -3.2139599827],
            [-39.450060355, -3.211270775],
            [-39.4495600604, -3.2062264019],
            [-39.4422136855, -3.2098646337],
            [-39.4324030102, -3.2096848935],
            [-39.4311545054, -3.2072296587],
            [-39.4200320068, -3.2023786253],
            [-39.4105691159, -3.2085753416],
            [-39.4063601527, -3.199973271],
            [-39.4095859047, -3.1914320963],
            [-39.4083736409, -3.1860757553],
            [-39.4047141976, -3.191512],
            [-39.4001504562, -3.1860175937],
            [-39.3916278719, -3.1845065757],
            [-39.3855717789, -3.186980494],
            [-39.3839799183, -3.1837433555],
            [-39.3806757199, -3.1826327903],
            [-39.3660392594, -3.1735506737],
            [-39.3565938145, -3.1745257996],
            [-39.3449829945, -3.1796548999],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313559',
        name: 'Tururu',
        description: 'Tururu',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.3748048154, -3.5785334051],
            [-39.3784574614, -3.5849328975],
            [-39.3794316463, -3.5866401642],
            [-39.3834237228, -3.5936346377],
            [-39.390434199, -3.6059185977],
            [-39.3867232807, -3.6134887151],
            [-39.3795790912, -3.6165238846],
            [-39.3805998456, -3.6266884326],
            [-39.3761143374, -3.6340643847],
            [-39.3591048202, -3.6477914425],
            [-39.3594282443, -3.6547388721],
            [-39.3529651889, -3.6638616588],
            [-39.3841976721, -3.660071912],
            [-39.4152990215, -3.6562964143],
            [-39.4278068904, -3.6548898993],
            [-39.4505684965, -3.657804447],
            [-39.4553678067, -3.6511250521],
            [-39.455516407, -3.6420190169],
            [-39.4286305096, -3.6289221586],
            [-39.4213909208, -3.6255131631],
            [-39.4306279437, -3.6224908537],
            [-39.4544214348, -3.614704934],
            [-39.461461607, -3.6124007652],
            [-39.4907574689, -3.6028098242],
            [-39.5041561497, -3.575908388],
            [-39.4924804917, -3.581374783],
            [-39.4543096986, -3.5848866429],
            [-39.4507305323, -3.5824369053],
            [-39.4505484311, -3.5822860291],
            [-39.4394760439, -3.5787885165],
            [-39.4348108538, -3.5739077999],
            [-39.4319117594, -3.5654434088],
            [-39.4260203357, -3.562414544],
            [-39.4158077864, -3.5525707719],
            [-39.411255841, -3.5519490292],
            [-39.4009495585, -3.54794456],
            [-39.4096534823, -3.5325455885],
            [-39.4137348719, -3.5187342508],
            [-39.4164411325, -3.515355568],
            [-39.4162565481, -3.5026317759],
            [-39.4307793359, -3.4935138397],
            [-39.4345487495, -3.4837361767],
            [-39.4334808162, -3.4778576421],
            [-39.4271419849, -3.476516767],
            [-39.4272812063, -3.4694108439],
            [-39.4385557053, -3.4492393499],
            [-39.4457438271, -3.4470097969],
            [-39.4515596966, -3.4434805574],
            [-39.4509174345, -3.4327606517],
            [-39.4574231536, -3.4252135927],
            [-39.4558156821, -3.4215373125],
            [-39.4631423173, -3.4138914398],
            [-39.4673369287, -3.4015203834],
            [-39.4530184371, -3.4073118409],
            [-39.4360106403, -3.4179984108],
            [-39.4328065437, -3.4268104719],
            [-39.4109358929, -3.4444647808],
            [-39.3753468704, -3.4695905892],
            [-39.3711992978, -3.4725173612],
            [-39.3483040557, -3.4886746601],
            [-39.3318052664, -3.500437034],
            [-39.3317910676, -3.5005375191],
            [-39.3315202565, -3.502454598],
            [-39.3363394052, -3.5124554884],
            [-39.3328018464, -3.5166949058],
            [-39.3370044239, -3.5209781387],
            [-39.3340545697, -3.527570618],
            [-39.3403634629, -3.5310562149],
            [-39.3423314986, -3.5391682599],
            [-39.3509091808, -3.5475477172],
            [-39.3633099473, -3.5488061107],
            [-39.3625344777, -3.5601775992],
            [-39.3748048154, -3.5785334051],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313609',
        name: 'Ubajara',
        description: 'Ubajara',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.8494156322, -3.7876239682],
            [-40.8193715585, -3.7880111559],
            [-40.7793379163, -3.7861772169],
            [-40.7798631064, -3.7919868291],
            [-40.7798957402, -3.7948247824],
            [-40.774767752, -3.7960677484],
            [-40.775830272, -3.8033247448],
            [-40.7829517632, -3.8226499689],
            [-40.7862719467, -3.8206023793],
            [-40.7924185387, -3.8227729959],
            [-40.7932676347, -3.8320703712],
            [-40.7895184909, -3.8374168022],
            [-40.7942410337, -3.8367947625],
            [-40.7928152386, -3.839931542],
            [-40.7968770512, -3.841026287],
            [-40.7941962358, -3.8438031665],
            [-40.7977027727, -3.8471792787],
            [-40.8054373578, -3.8455782667],
            [-40.8131857423, -3.8513208198],
            [-40.8164321752, -3.8603153083],
            [-40.8254841301, -3.8681884533],
            [-40.8301708665, -3.8656752993],
            [-40.8387448843, -3.8705572497],
            [-40.8463778275, -3.8635708773],
            [-40.860896984, -3.8684398437],
            [-40.8710764521, -3.8708666618],
            [-40.8731096633, -3.8683391625],
            [-40.8845091557, -3.867667269],
            [-40.885827584, -3.8680599823],
            [-40.8915534478, -3.8682416831],
            [-40.8960703517, -3.8785836094],
            [-40.8943913974, -3.8871070652],
            [-40.9021515393, -3.8879210851],
            [-40.9084013786, -3.8885764445],
            [-40.9176471908, -3.8889978092],
            [-40.9231020638, -3.8948652908],
            [-40.9319040705, -3.9071400289],
            [-40.936183369, -3.9065598864],
            [-40.9404617704, -3.9049483497],
            [-40.9426569005, -3.907943527],
            [-40.9510955377, -3.9030118561],
            [-40.9543831548, -3.9016980122],
            [-40.9645655188, -3.8937340392],
            [-40.9781482383, -3.8924203747],
            [-40.9815676212, -3.8922313381],
            [-41.0434319193, -3.9112800412],
            [-41.0675987148, -3.9187222151],
            [-41.1019240015, -3.9292513007],
            [-41.1073694052, -3.9338082328],
            [-41.1241166906, -3.9345456121],
            [-41.133228549, -3.9427698135],
            [-41.1672904199, -3.9499252098],
            [-41.1809489572, -3.9571335423],
            [-41.2200272902, -3.9678807535],
            [-41.2286501414, -3.9679987811],
            [-41.2198657422, -3.9411281895],
            [-41.2274955774, -3.9149384853],
            [-41.2453201846, -3.8959247598],
            [-41.2100811176, -3.900822272],
            [-41.1934252951, -3.8952281469],
            [-41.1909300551, -3.8908331755],
            [-41.1815180365, -3.8923713529],
            [-41.1789426646, -3.88357804],
            [-41.1616152529, -3.8727584597],
            [-41.1325517533, -3.8430560791],
            [-41.1092557418, -3.8444260728],
            [-41.0860186774, -3.8383433796],
            [-41.0551991865, -3.8401581928],
            [-41.048980323, -3.8435986984],
            [-41.0440894, -3.8454626096],
            [-41.0358707337, -3.8498253704],
            [-41.0255516247, -3.846323693],
            [-41.0223095636, -3.8448937383],
            [-41.0070916546, -3.8406660653],
            [-40.9818311457, -3.8394974111],
            [-40.973865169, -3.8423532057],
            [-40.9704768098, -3.8407228553],
            [-40.9678873685, -3.8347547581],
            [-40.9671091456, -3.8329597465],
            [-40.9612290874, -3.8250845573],
            [-40.9612101989, -3.8170524679],
            [-40.9572468559, -3.810697735],
            [-40.9513889788, -3.8050503394],
            [-40.9383941906, -3.8005502093],
            [-40.9230339612, -3.7883496595],
            [-40.9097649551, -3.7888975514],
            [-40.9037178062, -3.7831437101],
            [-40.9007814131, -3.7795679375],
            [-40.8950124215, -3.7808312308],
            [-40.8936748776, -3.7813358654],
            [-40.8494156322, -3.7876239682],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313708',
        name: 'Umari',
        description: 'Umari',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.6067315531, -6.5291158944],
            [-38.6262527908, -6.5525551434],
            [-38.6253080154, -6.5575843199],
            [-38.6322785152, -6.5620381794],
            [-38.6327279924, -6.5688111434],
            [-38.6425000574, -6.5807783824],
            [-38.6322856828, -6.5901365377],
            [-38.6288071005, -6.6041829219],
            [-38.6463058617, -6.6155811093],
            [-38.648775888, -6.6271861886],
            [-38.6549098543, -6.6300791016],
            [-38.6567277459, -6.6418897787],
            [-38.6466118867, -6.6538076901],
            [-38.6468631591, -6.6635961171],
            [-38.6516112222, -6.672782522],
            [-38.6620069005, -6.673917854],
            [-38.660229814, -6.678401847],
            [-38.6641089806, -6.6797895669],
            [-38.6727967659, -6.6968210213],
            [-38.6696828699, -6.7021480376],
            [-38.67251926, -6.7063204421],
            [-38.6762805817, -6.7030493877],
            [-38.6868867051, -6.704073258],
            [-38.7016349469, -6.6978831357],
            [-38.7365071938, -6.6793465464],
            [-38.7524665165, -6.6742728089],
            [-38.7598074884, -6.6762715303],
            [-38.7852798192, -6.6641999325],
            [-38.7942478111, -6.664990505],
            [-38.8020898252, -6.6585280741],
            [-38.8317351841, -6.6600659148],
            [-38.8343913586, -6.6653718719],
            [-38.8385292369, -6.6635529844],
            [-38.8438165036, -6.6664743732],
            [-38.8474983309, -6.656961979],
            [-38.8733045717, -6.6120216461],
            [-38.8692203417, -6.5927616302],
            [-38.8768354229, -6.5852446442],
            [-38.8767639583, -6.5813593634],
            [-38.8625315421, -6.5867747542],
            [-38.8543854889, -6.5970741032],
            [-38.8400736712, -6.5979406596],
            [-38.8328224083, -6.593966574],
            [-38.79570376, -6.5990081862],
            [-38.7738454268, -6.6121116542],
            [-38.7676708372, -6.6059291428],
            [-38.760721911, -6.6055804002],
            [-38.7556009402, -6.5960577699],
            [-38.7509437653, -6.5916589992],
            [-38.7312947662, -6.5854012658],
            [-38.7361988037, -6.5823390075],
            [-38.7352252167, -6.570268606],
            [-38.7027063357, -6.5557068447],
            [-38.6893600312, -6.5645544654],
            [-38.6867854313, -6.5624157585],
            [-38.6796515952, -6.5588880189],
            [-38.6595837229, -6.5457294826],
            [-38.6530861319, -6.5475207857],
            [-38.6268382408, -6.5314556386],
            [-38.6101669641, -6.5318949622],
            [-38.6067315531, -6.5291158944],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313757',
        name: 'Umirim',
        description: 'Umirim',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.3317587923, -3.5776410395],
            [-39.3150650896, -3.5803550172],
            [-39.3061606785, -3.6680165132],
            [-39.2897712609, -3.6746425469],
            [-39.2872507254, -3.676725318],
            [-39.2810685077, -3.6726428252],
            [-39.27753893, -3.6747697619],
            [-39.2767409328, -3.7185314842],
            [-39.2764101429, -3.7365083723],
            [-39.2968131888, -3.7384383328],
            [-39.3101975846, -3.7338340995],
            [-39.3219218087, -3.7326636673],
            [-39.3385939719, -3.7440283741],
            [-39.3478310605, -3.7339191019],
            [-39.3521130182, -3.7341338393],
            [-39.3510743224, -3.7382602273],
            [-39.355551039, -3.74180351],
            [-39.3627841338, -3.7509390017],
            [-39.3649829022, -3.7672639979],
            [-39.3720277783, -3.7691411833],
            [-39.3798664781, -3.765072843],
            [-39.3962577555, -3.7637991502],
            [-39.3987771597, -3.7776266092],
            [-39.4233935058, -3.7859670739],
            [-39.4321140459, -3.7836311814],
            [-39.4357783893, -3.7724929226],
            [-39.4330842815, -3.7641859742],
            [-39.4434380377, -3.7449500458],
            [-39.4563674538, -3.7476452883],
            [-39.4572797458, -3.7416495677],
            [-39.4696762425, -3.7302019391],
            [-39.4733263554, -3.7266470262],
            [-39.4794787074, -3.7264600329],
            [-39.482031772, -3.7222257384],
            [-39.495497826, -3.721203925],
            [-39.4967746686, -3.7152593416],
            [-39.4967361712, -3.7110875218],
            [-39.5098536379, -3.7024779124],
            [-39.5133781181, -3.6974422615],
            [-39.510861373, -3.6949695493],
            [-39.514380827, -3.6756129801],
            [-39.5142122605, -3.6688456607],
            [-39.4719807818, -3.6495427779],
            [-39.4696700746, -3.6484874577],
            [-39.455516407, -3.6420190169],
            [-39.4553678067, -3.6511250521],
            [-39.4505684965, -3.657804447],
            [-39.4278068904, -3.6548898993],
            [-39.4152990215, -3.6562964143],
            [-39.3841976721, -3.660071912],
            [-39.3529651889, -3.6638616588],
            [-39.3594282443, -3.6547388721],
            [-39.3591048202, -3.6477914425],
            [-39.3761143374, -3.6340643847],
            [-39.3805998456, -3.6266884326],
            [-39.3795790912, -3.6165238846],
            [-39.3867232807, -3.6134887151],
            [-39.390434199, -3.6059185977],
            [-39.3834237228, -3.5936346377],
            [-39.3794316463, -3.5866401642],
            [-39.3784574614, -3.5849328975],
            [-39.3748048154, -3.5785334051],
            [-39.3654002824, -3.5805043655],
            [-39.3504889549, -3.5735681686],
            [-39.3317587923, -3.5776410395],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313807',
        name: 'Uruburetama',
        description: 'Uruburetama',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.4719807818, -3.6495427779],
            [-39.5142122605, -3.6688456607],
            [-39.5156919725, -3.6572973843],
            [-39.5201643761, -3.655816386],
            [-39.5224196453, -3.6547080714],
            [-39.5255425327, -3.6594931336],
            [-39.5360503762, -3.6592671452],
            [-39.5426709063, -3.6545854565],
            [-39.5535270561, -3.6536439962],
            [-39.557841263, -3.6523616344],
            [-39.5581702884, -3.6411770427],
            [-39.5619250632, -3.634037421],
            [-39.5941489775, -3.6249439612],
            [-39.593995885, -3.624780286],
            [-39.587344053, -3.6175984772],
            [-39.5798355582, -3.6094947732],
            [-39.5727427729, -3.6018406083],
            [-39.5719526145, -3.6009878017],
            [-39.5545721961, -3.5822326666],
            [-39.5506505111, -3.5821540949],
            [-39.5505272321, -3.5821513268],
            [-39.5316063636, -3.5817696988],
            [-39.5125604174, -3.5813858288],
            [-39.5028525247, -3.5811900513],
            [-39.5048293438, -3.5753552565],
            [-39.5041561497, -3.575908388],
            [-39.4907574689, -3.6028098242],
            [-39.461461607, -3.6124007652],
            [-39.4544214348, -3.614704934],
            [-39.4306279437, -3.6224908537],
            [-39.4213909208, -3.6255131631],
            [-39.4286305096, -3.6289221586],
            [-39.455516407, -3.6420190169],
            [-39.4696700746, -3.6484874577],
            [-39.4719807818, -3.6495427779],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313906',
        name: 'Uruoca',
        description: 'Uruoca',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.6920817401, -3.2421032241],
            [-40.6691726626, -3.2472710998],
            [-40.5390593932, -3.2432157335],
            [-40.5287179034, -3.2388607317],
            [-40.5214161138, -3.2404457276],
            [-40.5160033902, -3.2481348346],
            [-40.5046140313, -3.2535368081],
            [-40.5011186371, -3.2521747609],
            [-40.4923634685, -3.2595207996],
            [-40.4807848507, -3.2619310628],
            [-40.4868912103, -3.2770436617],
            [-40.4857551616, -3.2907430542],
            [-40.4792548342, -3.3008630916],
            [-40.480002851, -3.3112208391],
            [-40.485894783, -3.3223847323],
            [-40.4857654332, -3.3324847103],
            [-40.4862546334, -3.3352285293],
            [-40.4929812085, -3.33634735],
            [-40.5011137214, -3.3488210307],
            [-40.5066724545, -3.3696104705],
            [-40.5194261252, -3.3760485166],
            [-40.5309495682, -3.389137455],
            [-40.5405634967, -3.3872448097],
            [-40.5459932397, -3.3928524387],
            [-40.5409139774, -3.4104893814],
            [-40.5613660484, -3.4065440391],
            [-40.5643859966, -3.4056786486],
            [-40.5705488022, -3.4117494931],
            [-40.5857366219, -3.4087103336],
            [-40.5891077074, -3.4122991641],
            [-40.5922055079, -3.4101266805],
            [-40.5978344612, -3.4123907589],
            [-40.617433522, -3.4052204602],
            [-40.6196797567, -3.397022418],
            [-40.6297634675, -3.3917181664],
            [-40.6010586491, -3.3689518342],
            [-40.6367469138, -3.3557576078],
            [-40.640341609, -3.3609322275],
            [-40.6527318557, -3.3582672173],
            [-40.6547912104, -3.3637086466],
            [-40.6627232811, -3.3611036653],
            [-40.6685109969, -3.3631039607],
            [-40.6773290877, -3.3768436067],
            [-40.6790677621, -3.388565815],
            [-40.6889509657, -3.391616023],
            [-40.6944288042, -3.3999637844],
            [-40.7002212021, -3.4011117839],
            [-40.7125143669, -3.3959097427],
            [-40.7108970623, -3.392161946],
            [-40.7232782028, -3.3825982067],
            [-40.7277776267, -3.3859364177],
            [-40.7206451168, -3.3939353066],
            [-40.7229055045, -3.4046916722],
            [-40.7196446311, -3.408322856],
            [-40.7201527504, -3.4227606831],
            [-40.7370167647, -3.4307262998],
            [-40.7368051351, -3.437719216],
            [-40.771507253, -3.4220490484],
            [-40.7783014728, -3.4239059831],
            [-40.7804092756, -3.4210662105],
            [-40.7863399333, -3.422248151],
            [-40.7981191286, -3.4179738349],
            [-40.813458057, -3.4262210008],
            [-40.8490443104, -3.4193782457],
            [-40.8519604926, -3.4190375569],
            [-40.8526649228, -3.4109289912],
            [-40.8440933524, -3.4005167679],
            [-40.8363500186, -3.3797158272],
            [-40.8253734433, -3.377937623],
            [-40.8235530062, -3.3618864182],
            [-40.8391826, -3.358088978],
            [-40.8778109049, -3.3698099636],
            [-40.8800754847, -3.3642342656],
            [-40.8555028686, -3.342572665],
            [-40.8502497449, -3.327420623],
            [-40.8488907654, -3.3190815342],
            [-40.8526194365, -3.3169086959],
            [-40.8517746367, -3.3108645743],
            [-40.8469507118, -3.3074854275],
            [-40.8386662914, -3.3076960284],
            [-40.8205055504, -3.2958429682],
            [-40.8122006594, -3.2742161551],
            [-40.8158389524, -3.2712243786],
            [-40.8272012022, -3.2743465811],
            [-40.8388935043, -3.2671838271],
            [-40.8346740339, -3.2603992085],
            [-40.8208615309, -3.259789788],
            [-40.8392309456, -3.2419009133],
            [-40.8268336232, -3.2360577695],
            [-40.8286982116, -3.2303555162],
            [-40.837670913, -3.2287212319],
            [-40.8325695113, -3.2107137373],
            [-40.7945650174, -3.2189694138],
            [-40.7007640461, -3.2401444982],
            [-40.6920817401, -3.2421032241],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313955',
        name: 'Varjota',
        description: 'Varjota',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.4457583049, -4.1634803329],
            [-40.4422028924, -4.1834270095],
            [-40.4497577506, -4.2075662582],
            [-40.4501871543, -4.2080952674],
            [-40.4839226028, -4.2325382569],
            [-40.4889708358, -4.2430542268],
            [-40.4939587707, -4.24536468],
            [-40.5073372926, -4.2471925167],
            [-40.5190896933, -4.2569607275],
            [-40.5302803283, -4.2500667693],
            [-40.5526936331, -4.2131187203],
            [-40.5641723851, -4.2055890193],
            [-40.5785322121, -4.2072826588],
            [-40.5890299942, -4.2005995793],
            [-40.6087099054, -4.2072871575],
            [-40.6089438913, -4.2073007629],
            [-40.6041224086, -4.2046651555],
            [-40.5621462733, -4.1817242049],
            [-40.5527675209, -4.1765988301],
            [-40.5433566511, -4.171456472],
            [-40.5390027159, -4.172409124],
            [-40.5387086036, -4.1692128808],
            [-40.5267370413, -4.1710178198],
            [-40.517894903, -4.1664354327],
            [-40.5188103927, -4.159713912],
            [-40.5145787654, -4.148914791],
            [-40.5169388262, -4.1407185884],
            [-40.5143285653, -4.1343284312],
            [-40.5234971407, -4.121815358],
            [-40.5259312512, -4.1084872398],
            [-40.5308079618, -4.1060020515],
            [-40.5338669347, -4.0989737112],
            [-40.5355519063, -4.0976688904],
            [-40.4723680933, -4.0861073799],
            [-40.4704607648, -4.0863487907],
            [-40.4313904197, -4.0912946622],
            [-40.4321415496, -4.0932424916],
            [-40.4344502412, -4.0975394807],
            [-40.4363919954, -4.1145209974],
            [-40.4328679606, -4.1236206944],
            [-40.4363063626, -4.1381851309],
            [-40.4300870846, -4.1467652483],
            [-40.4325323599, -4.1579692928],
            [-40.4457583049, -4.1634803329],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2314003',
        name: 'Várzea Alegre',
        description: 'Várzea Alegre',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.2147054514, -6.5958468002],
            [-39.2095154806, -6.6074141102],
            [-39.1936712149, -6.6096962285],
            [-39.1782235264, -6.6129118069],
            [-39.180626887, -6.6180321681],
            [-39.1727943329, -6.622321161],
            [-39.1773011151, -6.6321751014],
            [-39.1835074201, -6.6409718996],
            [-39.1739530355, -6.6452077092],
            [-39.1762024081, -6.6572783299],
            [-39.1713740161, -6.6626889538],
            [-39.1743271684, -6.6707614345],
            [-39.1612759282, -6.6745068732],
            [-39.1441393501, -6.6714940042],
            [-39.1410714788, -6.6771481499],
            [-39.1345827386, -6.6827564651],
            [-39.1351392061, -6.6878823998],
            [-39.1294185664, -6.6917234334],
            [-39.1318639532, -6.6989591394],
            [-39.1300033077, -6.7085740611],
            [-39.1258506086, -6.7107973115],
            [-39.1135709519, -6.7104305731],
            [-39.1090309445, -6.7141353794],
            [-39.1292243812, -6.7222097285],
            [-39.1408826106, -6.7507279312],
            [-39.1603261736, -6.7872590631],
            [-39.1629485394, -6.7921853579],
            [-39.1804118262, -6.8250066042],
            [-39.1854004461, -6.8595429498],
            [-39.1860910491, -6.8643278371],
            [-39.1956730063, -6.8668883565],
            [-39.2069430751, -6.8772431736],
            [-39.2196674305, -6.8767050534],
            [-39.2258113991, -6.879675378],
            [-39.2513981154, -6.8855590967],
            [-39.2715025321, -6.8875029337],
            [-39.3216005738, -6.9011729413],
            [-39.3290776121, -6.9033127613],
            [-39.3359159336, -6.9052688523],
            [-39.342967076, -6.9074249449],
            [-39.3512864456, -6.9090948424],
            [-39.3783511788, -6.9198440429],
            [-39.3854229105, -6.9241182859],
            [-39.3948144904, -6.9230508222],
            [-39.4182013768, -6.89498237],
            [-39.453459071, -6.896327034],
            [-39.4609337846, -6.8966117096],
            [-39.4828905074, -6.8974478775],
            [-39.4776402068, -6.8854198346],
            [-39.4844410485, -6.8809286137],
            [-39.4857180244, -6.875974915],
            [-39.4791403007, -6.8679631106],
            [-39.4741942413, -6.8672383695],
            [-39.4743910256, -6.8606505637],
            [-39.4754434423, -6.859168269],
            [-39.4805443625, -6.8507541563],
            [-39.4843902999, -6.8384357252],
            [-39.4935523762, -6.8464644894],
            [-39.5029624128, -6.8436655742],
            [-39.5005548497, -6.8367580897],
            [-39.5062246987, -6.8271270736],
            [-39.5078215301, -6.8163301593],
            [-39.5153900629, -6.8076951756],
            [-39.511781607, -6.8073702904],
            [-39.509107412, -6.7916574197],
            [-39.5136564698, -6.7832858622],
            [-39.5119946049, -6.7776287953],
            [-39.5015649552, -6.7546230749],
            [-39.4952583869, -6.7513497275],
            [-39.4882085061, -6.7520458633],
            [-39.4825056643, -6.7576280212],
            [-39.4781418316, -6.7554347037],
            [-39.4731855017, -6.7657860518],
            [-39.4711706118, -6.7703038925],
            [-39.4668098585, -6.7710620921],
            [-39.4676008701, -6.7749974916],
            [-39.4523850121, -6.7779012053],
            [-39.4484390598, -6.7865269907],
            [-39.4421982939, -6.7894975871],
            [-39.4167892801, -6.7827722044],
            [-39.4211354392, -6.7742128634],
            [-39.4119649268, -6.7694719013],
            [-39.405166063, -6.7658938519],
            [-39.3985587385, -6.7551543679],
            [-39.3868301476, -6.7657811471],
            [-39.3824245435, -6.7648440482],
            [-39.3820227218, -6.7694383446],
            [-39.3671421924, -6.7627840027],
            [-39.3654332995, -6.7583187067],
            [-39.3592783616, -6.7708862276],
            [-39.3534166935, -6.7613351792],
            [-39.3482194482, -6.7689459602],
            [-39.338220373, -6.7654778786],
            [-39.3305390663, -6.770576997],
            [-39.3131702971, -6.7578591731],
            [-39.3004759414, -6.7534138408],
            [-39.2965401248, -6.7391793255],
            [-39.3022272156, -6.7396920467],
            [-39.2979153584, -6.7337478523],
            [-39.3118753417, -6.7390822765],
            [-39.3249708096, -6.7366591877],
            [-39.3292539704, -6.7317013788],
            [-39.3353043214, -6.7289832972],
            [-39.3363350241, -6.7246718932],
            [-39.3273704845, -6.7189034614],
            [-39.3292649759, -6.7164027741],
            [-39.3187450527, -6.7026163702],
            [-39.316666009, -6.6841743636],
            [-39.3080379477, -6.6852907816],
            [-39.2904471203, -6.6744183068],
            [-39.2896164936, -6.6574320582],
            [-39.2896087911, -6.6572689064],
            [-39.2812234665, -6.6481066219],
            [-39.2834526105, -6.6334226146],
            [-39.2794958142, -6.6290221319],
            [-39.2965586206, -6.6084095128],
            [-39.3080552483, -6.6103580292],
            [-39.3168596312, -6.6036318947],
            [-39.3133507168, -6.5979451822],
            [-39.3128274529, -6.5844208386],
            [-39.300079654, -6.5749299125],
            [-39.2942694497, -6.5623258226],
            [-39.2813573297, -6.5673299434],
            [-39.2412256894, -6.5825441755],
            [-39.2394696493, -6.5832085832],
            [-39.2302595871, -6.5832095648],
            [-39.2255398515, -6.5884250872],
            [-39.2196326034, -6.5894397588],
            [-39.221660321, -6.5938492311],
            [-39.2147054514, -6.5958468002],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2314102',
        name: 'Viçosa do Ceará',
        description: 'Viçosa do Ceará',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-41.1589722841, -3.326028156],
            [-41.1488004092, -3.3628287316],
            [-41.1411375391, -3.3591766742],
            [-41.1349822812, -3.3628074373],
            [-41.1250098796, -3.3610741547],
            [-41.0931263664, -3.3704182323],
            [-41.0929863213, -3.3704596306],
            [-41.0590723223, -3.3883481182],
            [-41.0485271772, -3.3911033523],
            [-41.0515303939, -3.4022554449],
            [-41.0260669012, -3.4075283602],
            [-41.0224603261, -3.4090088261],
            [-41.0162035376, -3.4122520348],
            [-41.0129831233, -3.4203695583],
            [-41.0030720613, -3.4223039328],
            [-40.9983269848, -3.427378322],
            [-40.992942398, -3.4320860486],
            [-40.9652821512, -3.456260234],
            [-40.9648466475, -3.4566413766],
            [-40.9616332367, -3.4594499232],
            [-40.9317991095, -3.4855138237],
            [-40.8884758064, -3.5220686388],
            [-40.8765036231, -3.5321666591],
            [-40.8845061815, -3.5383535218],
            [-40.9047494079, -3.5571554971],
            [-40.9119513627, -3.5662339038],
            [-40.9320122488, -3.5808307925],
            [-40.9329231974, -3.5851343243],
            [-40.9412560863, -3.5864944094],
            [-40.9678263536, -3.6105647819],
            [-40.9890946745, -3.6255305876],
            [-41.0161832789, -3.639651978],
            [-41.0154378734, -3.6439160549],
            [-41.0237972838, -3.6466307086],
            [-41.0251915761, -3.6532397305],
            [-41.0287079348, -3.6575457122],
            [-41.0386433599, -3.6645889563],
            [-41.0388782594, -3.6645931652],
            [-41.0511010108, -3.6648208837],
            [-41.0655312763, -3.6774041208],
            [-41.0655980829, -3.6774526907],
            [-41.0801630516, -3.6906572694],
            [-41.1087206724, -3.6993365099],
            [-41.1212780664, -3.7110015379],
            [-41.1316625712, -3.7116302733],
            [-41.1348315229, -3.7024439329],
            [-41.1504411112, -3.696313894],
            [-41.1930096686, -3.7035026802],
            [-41.1959487742, -3.6956262029],
            [-41.2072915353, -3.7089309777],
            [-41.2164052015, -3.7137869924],
            [-41.2208878793, -3.718514066],
            [-41.2253452298, -3.7124787576],
            [-41.2389650459, -3.7122698754],
            [-41.2550582488, -3.7028847004],
            [-41.2789599905, -3.7003719703],
            [-41.2904264133, -3.6893136429],
            [-41.3072902171, -3.6844280624],
            [-41.3261682973, -3.6869442306],
            [-41.3413730721, -3.680397353],
            [-41.3433179904, -3.6755980044],
            [-41.338722564, -3.6700448706],
            [-41.3309408875, -3.6243095073],
            [-41.3445294278, -3.588364419],
            [-41.3528629563, -3.5852117234],
            [-41.3616333511, -3.5828053967],
            [-41.3702509211, -3.5744918938],
            [-41.3708712137, -3.5674193404],
            [-41.3671198987, -3.5615461774],
            [-41.3642866202, -3.5576242985],
            [-41.3364947437, -3.5535193007],
            [-41.325304238, -3.5360386556],
            [-41.3237996086, -3.502994947],
            [-41.320213463, -3.4974841533],
            [-41.2988404899, -3.4906471593],
            [-41.2869411146, -3.4789545942],
            [-41.2746892439, -3.4852576481],
            [-41.2505831128, -3.4864525679],
            [-41.2468900511, -3.4817290182],
            [-41.2382902804, -3.4760572686],
            [-41.2333933935, -3.4631767082],
            [-41.2254752531, -3.4665224213],
            [-41.2165432539, -3.4377938885],
            [-41.1988118094, -3.3807925582],
            [-41.1800032086, -3.32037349],
            [-41.1696847882, -3.2872456732],
            [-41.1589722841, -3.326028156],
          ],
        ],
      },
    },
  ],
};

export { geojsonState };
