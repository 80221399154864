import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import PageCovid from '../views/PageCovid.vue';
import PageCidBr from '../views/PageCidBr.vue';
import PageNewsletter from '../views/PageNewsletter.vue';
import About from '../views/About.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home - Observatório de dados abertos',
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home - Observatório de dados abertos',
    },
  },
  {
    path: '/covid-19',
    name: 'PageCovid',
    component: PageCovid,
    meta: {
      title: 'Covid 19',
    },
  },
  {
    path: '/doencas-cidBr',
    name: 'PageCidBr',
    component: PageCidBr,
    meta: {
      title: 'Doenças CID BR',
    },
  },
  {
    path: '/boletins',
    name: 'PageNewsletter',
    component: PageNewsletter,
    meta: {
      title: 'Boletins',
    },
  },
  {
    path: '/sobre',
    name: 'Sobre',
    component: About,
    meta: {
      title: 'Sobre',
    },
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function() {
  //     return import(/* webpackChunkName: "about" */ '../views/About.vue');
  //   },
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
}); // aqui ele altera o titulo da pagina de acordo com dado meta do component

export default router;
