<template>
  <div
    class="card-size"
    :style="
      'width:' +
        this.styleCard.width +
        '; height:' +
        this.styleCard.height +
        '; margin-right: ' +
        this.styleCard.marginRight
    "
  >
    <div v-if="this.styleCard.cardStyleType == 'home'" class="card">
      <div
        :style="'background-color:' + this.styleCard.color"
        class="flag-color"
      ></div>
      <div class="data-info">
        <h5 class="text-custom-color-title">{{ this.styleCard.title }}</h5>
        <p class="text-number-custom-color-card">
          {{ this.styleCard.totalCasos }}
        </p>
        <h6 class="text-custom-color-title">Novos casos</h6>
        <p class="text-number-custom-color-card">
          {{ this.styleCard.novosCasos }}
        </p>
      </div>
    </div>

    <div
      :style="
        'height:' +
          this.styleCard.height +
          '; margin-bottom:' +
          this.styleCard.marginBottom
      "
      v-if="this.styleCard.cardStyleType == 'pageCovid'"
      class="card"
    >
      <div class="content-card" v-if="this.styleCard.title == 'Confirmados'">
        <div
          :style="'background-color:' + this.styleCard.color"
          class="flag-color"
        ></div>
        <div class="data-info">
          <div class="info-linha">
            <h5 :style="'color:' + this.styleCard.color + ';font-size: 16px'">
              {{ this.styleCard.title }}
            </h5>
            <p class="text-number-custom-color-card">
              {{ citySelected.totalCases ? citySelected.totalCases : '0' }}
            </p>
          </div>
          <div class="info-linha">
            <h6 class="text-custom-color-title">Novos casos</h6>
            <p class="text-number-custom-color-card">
              {{ citySelected.newCases ? citySelected.newCases : '0' }}
            </p>
          </div>
        </div>
      </div>
      <div class="content-card" v-if="this.styleCard.title == 'Óbitos'">
        <div
          :style="'background-color:' + this.styleCard.color"
          class="flag-color"
        ></div>
        <div class="data-info">
          <div class="info-linha">
            <h5 :style="'color:' + this.styleCard.color + ';font-size: 16px'">
              {{ this.styleCard.title }}
            </h5>
            <p class="text-number-custom-color-card">
              {{ citySelected.deaths ? citySelected.deaths : '0' }}
            </p>
          </div>
          <div class="info-linha">
            <h6 class="text-custom-color-title">Novos casos</h6>
            <p class="text-number-custom-color-card">
              {{ citySelected.newDeaths ? citySelected.newDeaths : '0' }}
            </p>
          </div>
        </div>
      </div>

      <div class="content-card" v-if="this.styleCard.title == 'Letalidade'">
        <div
          :style="'background-color:' + this.styleCard.color"
          class="flag-color"
        ></div>
        <div class="data-info">
          <div class="info-linha">
            <h5 :style="'color:' + this.styleCard.color + ';font-size: 16px'">
              {{ this.styleCard.title }}
            </h5>
            <p class="text-number-custom-color-card">
              {{
                citySelected.deaths_by_totalCases
                  ? citySelected.deaths_by_totalCases
                  : '0'
              }}
            </p>
          </div>
          <div class="info-linha">
            <h6 class="text-custom-color-title">Mortes por total de casos</h6>
            <p class="text-number-custom-color-card"></p>
          </div>
        </div>
      </div>
    </div>

    <div
      :style="
        'height:' +
          this.styleCard.height +
          '; margin-bottom:' +
          this.styleCard.marginBottom
      "
      v-if="this.styleCard.cardStyleType == 'pageCidBr'"
      class="card"
    >
      <div v-if="this.styleCard.title == 'Doença'">
        <div
          :style="'background-color:' + this.styleCard.color"
          class="flag-color"
        ></div>
        <div class="data-info">
          <div class="info-linha">
            <h5 :style="'color:' + this.styleCard.color + ';font-size: 16px'">
              {{ 'CID BR - ' + this.styleCard.title }}
            </h5>
            <p class="text-number-custom-color-card">
              {{ this.styleCard.totalCasos }}
            </p>
          </div>
          <div class="info-linha">
            <h6 class="text-custom-color-title">{{ diseaseName }}</h6>
            <p class="text-number-custom-color-card">
              {{ this.styleCard.novosCasos }}
            </p>
          </div>
        </div>
      </div>

      <div v-if="this.styleCard.title == 'Casos'">
        <div
          :style="'background-color:' + this.styleCard.color"
          class="flag-color"
        ></div>
        <div class="data-info">
          <div class="info-linha">
            <h5 :style="'color:' + this.styleCard.color + ';font-size: 16px'">
              {{ this.styleCard.title }}
            </h5>
            <p class="text-number-custom-color-card">
              {{ this.styleCard.totalCasos }}
            </p>
          </div>
          <div class="info-linha">
            <h6 class="text-custom-color-title">
              {{ this.styleCard.subtitle }}
            </h6>
            <p class="text-number-custom-color-card">
              {{ citySelectedCidBr.valor }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store';

export default {
  name: 'CardHome',
  props: {
    styleCard: {
      type: Object,
    },
  },
  data() {
    return {
      totalll: '',
    };
  },

  mounted() {
    this.totalll = this.styleCard.title;
  },
  computed: {
    cityName: () => store.state.city.cityName,
    citySelected: () => store.state.city.citySelected,
    citySelectedCidBr: () => store.state.cidBr.citySelectedCidBr,
    diseaseName: () => store.state.cidBr.diseaseName,
  },
  watch: {
    citySelected: () => {
      // console.log('total alterado');
    },
  },
};
</script>

<style scoped>
@media (max-width: 830px) {
  .card-size {
    width: 100% !important;
  }
}

@media (max-width: 1250px) {
  .card-size {
    width: 100% !important;
  }
}

.content-card {
  width: 100%;
  border-radius: 8px;
  height: 100%;
}

.card {
  /* height: 200px;
  width: 285px;
  margin-right: 17px; */
  margin-top: 6%;
  margin-bottom: 20px;
  border: none;
  display: flex;
  border-radius: 8px;
  flex-direction: row;
}
.flag-color {
  transform: translateX(-3px);
  position: absolute;
  margin-top: 20px;
  height: 42px;
  width: 6px;
  background-color: tomato;
  border-radius: 5px;
}
.data-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  border-radius: 5px;

  /* align-items: baseline; */
  padding: 30px 13px 5px 24px;
}
h5,
h6,
p {
  font-family: 'Inter', sans-serif;
}
h5,
h6 {
  font-weight: 600;
}

p {
  margin-top: 10px;
}
.info-linha {
  display: flex;
}
.info-linha > p {
  margin-top: 0px;
  margin-left: 40px;
}
.text-custom-color-title {
  color: #232121;
}
.text-number-custom-color-card {
  color: #565756;
}
</style>
