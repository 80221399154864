<template>
  <div class="menu">
    <div class="container menu-home" v-if="activatePageBar === 'home'">
      <b-navbar toggleable="lg">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item
              ><router-link id="pagina-inicial" to="/home">
                Pagina Inicial
              </router-link></b-nav-item
            >
            <b-nav-item href="/covid-19">
              <router-link to="/covid-19"> Covid-19 </router-link>
            </b-nav-item>
            <b-nav-item href="/doencas-cidBr"
              ><router-link to="/doencas-cidBr">
                Doenças CID BR
              </router-link></b-nav-item
            >
            <b-nav-item href="/boletins"
              ><router-link to="/boletins"> Boletins </router-link></b-nav-item
            >
            <b-nav-item href="/sobre"
              ><router-link to="/sobre"> Sobre </router-link></b-nav-item
            >
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav v-if="activatePageBar === 'home'" class="ml-auto">
            <div class="icons-left">
              <span class="material-icons-outlined sun">
                wb_sunny
              </span>
              <div @click="modeColor(!darkMode)" class="button-dark-light">
                <div id="home-button-mode"></div>
              </div>
              <span class="material-icons-outlined moon">
                nightlight
              </span>
              <span class="material-icons bell">
                notifications
              </span>
            </div>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    <div class="menuShared" v-if="activatePageBar !== 'home'">
      <div class="container">
        <b-navbar toggleable="lg">
          <!-- <button class="voltar"> -->
          <router-link class="voltar" to="/home"
            ><button>
              <span class="material-icons">
                arrow_back
              </span>
            </button>
          </router-link>
          <!-- </button> -->

          <b-navbar-nav class="ml-auto">
            <div class="icons-left">
              <span class="material-icons-outlined sun">
                wb_sunny
              </span>
              <div @click="modeColor(!darkMode)" class="button-dark-light">
                <div id="button-mode"></div>
              </div>
              <span class="material-icons-outlined moon">
                nightlight
              </span>
              <span class="material-icons bell">
                notifications
              </span>
            </div>
          </b-navbar-nav>
        </b-navbar>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store';

export default {
  name: 'BarHeader',
  props: {
    activatePageBar: {
      type: String,
    },
  },
  data() {
    return {
      darkMode: false,
      // baseURI: 'http://localhost:8080/api/cesta',
      // baseURIPedido: 'http://localhost:8080/api/pedidos',
    };
  },
  components: {},
  mounted() {
    this.darkMode = store.state.systemConfig.darkMode;
    this.modeColor(this.darkMode);
  },
  methods: {
    modeColor(isActive) {
      let elem;

      store.dispatch('changeDarkModeActive', isActive);
      this.darkMode = store.state.systemConfig.darkMode;
      localStorage.setItem('DarkMode', this.darkMode);

      if (this.activatePageBar === 'home') {
        elem = document.getElementById('home-button-mode');
      } else {
        elem = document.getElementById('button-mode');
      }

      var pos = 0;
      var mode = !isActive;

      if (!mode) {
        pos = 0;
      } else {
        pos = 23;
      }

      var id = setInterval(frame, 0.01);

      function frame() {
        if (!mode) {
          if (pos == 22) {
            clearInterval(id);
          } else {
            pos++;
            elem.style.left = pos + 'px';
          }
        } else {
          if (pos == 0) {
            clearInterval(id);
          } else {
            pos--;
            elem.style.left = pos + 'px';
          }
        }
      }
      // busca pelos elementos para alterar a cor
      let sun = document.getElementsByClassName('sun');
      let moon = document.getElementsByClassName('moon');
      let pageBody = document.getElementsByClassName('pageBody');
      // console.log(pageBody);
      let cardsCovid = document.getElementsByClassName('cards-covid');
      let cardsInfo = document.getElementsByClassName('cards-info');
      let app = document.getElementById('app');
      let cicleMap = document.getElementById('cicle');

      let dataInfo = document.getElementsByClassName('data-info');
      let contentCard = document.getElementsByClassName('content-card');
      let card = document.getElementsByClassName('card');

      let table = document.getElementsByClassName('table');
      let tableDataStyle = document.getElementsByClassName('table-data-style');
      let captionTable = document.getElementsByTagName('caption');

      let divCustomColor = document.getElementsByClassName('div-custom-color');
      let contentCovid = document.getElementsByClassName('content-covid');
      let contentCidBr = document.getElementsByClassName('content-cidBr');

      let textCustomColorTitle = document.getElementsByClassName(
        'text-custom-color-title'
      );
      let textNumberCustomColorCard = document.getElementsByClassName(
        'text-number-custom-color-card'
      );
      let textCustomColor = document.getElementsByClassName(
        'text-custom-color'
      );
      let textCustomColorNewsletter = document.getElementsByClassName(
        'text-custom-color-newsletter'
      );

      let buttonSearch = document.getElementsByClassName('search');

      // converte as coleções encontradas
      dataInfo = Array.from(dataInfo);
      contentCard = Array.from(contentCard);
      card = Array.from(card);

      table = Array.from(table);
      tableDataStyle = Array.from(tableDataStyle);
      captionTable = Array.from(captionTable);

      divCustomColor = Array.from(divCustomColor);
      textCustomColorTitle = Array.from(textCustomColorTitle);
      textNumberCustomColorCard = Array.from(textNumberCustomColorCard);
      textCustomColor = Array.from(textCustomColor);
      textCustomColorNewsletter = Array.from(textCustomColorNewsletter);

      buttonSearch = Array.from(buttonSearch);

      if (this.activatePageBar === 'home') {
        // aplica as alterações de cor caso o modo esteja ativa e esta na tela home
        if (isActive) {
          // console.log('oii' + this.darkMode);
          sun[0].style.color = '#0F0F0F';
          moon[0].style.color = '#fff';

          // document.body.style.backgroundColor = '#0F0F';
          cardsCovid[0].style.backgroundColor = '#0F0F0F';
          app.style.backgroundColor = '#0F0F0F';
          cicleMap.style.backgroundImage =
            'linear-gradient(to bottom, #272727, #000000)';

          dataInfo.map((item) => (item.style.backgroundColor = '#181818'));
          card.map((item) => (item.style.backgroundColor = '#181818'));

          divCustomColor.map(
            (item) => (item.style.backgroundColor = '#161616')
          );

          textCustomColorTitle.map((item) => (item.style.color = '#EEEEEE'));
          textNumberCustomColorCard.map(
            (item) => (item.style.color = '#B1B1B1')
          );
          textCustomColor.map((item) => (item.style.color = '#BBBBBB'));
        } else {
          // console.log('xau' + this.darkMode);
          moon[0].style.color = '#0F0F0F';
          sun[0].style.color = '#fff';

          cardsCovid[0].style.backgroundColor = '#f7f8f9';
          app.style.backgroundColor = '#f7f8f9';
          document.body.style.backgroundColor = '#f7f8f9';
          cicleMap.style.backgroundImage =
            'linear-gradient(to bottom, #f4f3f3, #ffffff)';

          dataInfo.map((item) => (item.style.backgroundColor = '#fff'));
          card.map((item) => (item.style.backgroundColor = '#fff'));

          divCustomColor.map(
            (item) => (item.style.backgroundColor = '#eaeaea')
          );
          textCustomColorTitle.map((item) => (item.style.color = '#232121'));
          textNumberCustomColorCard.map(
            (item) => (item.style.color = '#565756')
          );
          textCustomColor.map((item) => (item.style.color = '#292929'));
        }
      } else if (this.activatePageBar === 'PageCovid') {
        // aplica as alterações se nao estiver na home
        if (isActive) {
          contentCard.map((item) => (item.style.backgroundColor = '#181818'));

          sun[0].style.color = '#0F0F0F';
          moon[0].style.color = '#FFF';
          pageBody[0].style.backgroundColor = '#0F0F0F';
          cardsInfo[0].style.backgroundColor = '#0F0F0F';
          dataInfo.map((item) => (item.style.backgroundColor = '#181818'));
          card.map((item) => (item.style.backgroundColor = '#181818'));

          textCustomColorTitle.map((item) => (item.style.color = '#EEEEEE'));
          textNumberCustomColorCard.map(
            (item) => (item.style.color = '#B1B1B1')
          );
          textCustomColor.map((item) => (item.style.color = '#BBBBBB'));
          tableDataStyle.map((item) => {
            item.style.backgroundColor = '#565756';
            item.style.color = '#EEEEEE';
          });
          table.map((item) => {
            item.style.backgroundColor = '#565756';
            item.style.color = '#EEEEEE';
          });
          captionTable.map((item) => {
            item.style.color = '#EEEEEE';
          });

          buttonSearch.map((item) => (item.style.backgroundColor = '#565756'));
          // Chart.defaults.global.defaultFontColor = 'red';
        } else {
          contentCard.map((item) => (item.style.backgroundColor = '#f1f2f3'));

          moon[0].style.color = '#0F0F0F';
          sun[0].style.color = '#FFF';
          pageBody[0].style.backgroundColor = '#f7f8f9';
          // document.body.style.backgroundColor = '#f7f8f9';
          cardsInfo[0].style.backgroundColor = '#f1f2f3';
          dataInfo.map((item) => (item.style.backgroundColor = '#FFFFFF'));
          card.map((item) => (item.style.backgroundColor = '#fff'));

          textCustomColorTitle.map((item) => (item.style.color = '#232121'));
          textNumberCustomColorCard.map(
            (item) => (item.style.color = '#565756')
          );
          textCustomColor.map((item) => (item.style.color = '#292929'));

          tableDataStyle.map((item) => {
            item.style.backgroundColor = 'white';
            item.style.color = '#212529';
          });
          table.map((item) => {
            item.style.backgroundColor = 'white';
            item.style.color = '#212529';
          });
          captionTable.map((item) => {
            item.style.color = '#212529';
          });
          buttonSearch.map((item) => (item.style.backgroundColor = '#181818'));
        }
      } else if (this.activatePageBar === 'PageCidBr') {
        //customização para a tela de doenças  no estado
        if (isActive) {
          contentCard.map((item) => (item.style.backgroundColor = '#181818'));

          sun[0].style.color = '#0F0F0F';
          moon[0].style.color = '#FFF';
          pageBody[0].style.backgroundColor = '#0F0F0F';
          cardsInfo[0].style.backgroundColor = '#0F0F0F';
          dataInfo.map((item) => (item.style.backgroundColor = '#181818'));
          card.map((item) => (item.style.backgroundColor = '#181818'));

          textCustomColorTitle.map((item) => (item.style.color = '#EEEEEE'));
          textNumberCustomColorCard.map(
            (item) => (item.style.color = '#B1B1B1')
          );
          textCustomColor.map((item) => (item.style.color = '#BBBBBB'));
          tableDataStyle.map((item) => {
            item.style.backgroundColor = '#565756';
            item.style.color = '#EEEEEE';
          });
          table.map((item) => {
            item.style.backgroundColor = '#565756';
            item.style.color = '#EEEEEE';
          });
          captionTable.map((item) => {
            item.style.color = '#EEEEEE';
          });

          buttonSearch.map((item) => (item.style.backgroundColor = '#565756'));
          // Chart.defaults.global.defaultFontColor = 'red';
        } else {
          contentCard.map((item) => (item.style.backgroundColor = '#f1f2f3'));

          moon[0].style.color = '#0F0F0F';
          sun[0].style.color = '#FFF';
          pageBody[0].style.backgroundColor = '#f7f8f9';
          // document.body.style.backgroundColor = '#f7f8f9';
          cardsInfo[0].style.backgroundColor = '#f1f2f3';
          dataInfo.map((item) => (item.style.backgroundColor = '#FFFFFF'));
          card.map((item) => (item.style.backgroundColor = '#fff'));

          textCustomColorTitle.map((item) => (item.style.color = '#232121'));
          textNumberCustomColorCard.map(
            (item) => (item.style.color = '#565756')
          );
          textCustomColor.map((item) => (item.style.color = '#292929'));

          tableDataStyle.map((item) => {
            item.style.backgroundColor = 'white';
            item.style.color = '#212529';
          });
          table.map((item) => {
            item.style.backgroundColor = 'white';
            item.style.color = '#212529';
          });
          captionTable.map((item) => {
            item.style.color = '#212529';
          });
          buttonSearch.map((item) => (item.style.backgroundColor = '#181818'));
        }
      } else if (this.activatePageBar === 'PageNewsletter') {
        //customização para a tela de listagem de boletins
        if (isActive) {
          sun[0].style.color = '#0F0F0F';
          moon[0].style.color = '#FFF';
          pageBody[0].style.backgroundColor = '#0F0F0F';

          textCustomColorTitle.map((item) => (item.style.color = '#EEEEEE'));
          textNumberCustomColorCard.map(
            (item) => (item.style.color = '#B1B1B1')
          );
          textCustomColor.map((item) => (item.style.color = '#BBBBBB'));
          textCustomColorNewsletter.map(
            (item) => (item.style.color = '#BBBBBB')
          );

          contentCovid[0].style.backgroundColor = '#181818';
          contentCidBr[0].style.backgroundColor = '#181818';
        } else {
          moon[0].style.color = '#0F0F0F';
          sun[0].style.color = '#FFF';
          pageBody[0].style.backgroundColor = '#f7f8f9';

          textCustomColorTitle.map((item) => (item.style.color = '#232121'));
          textNumberCustomColorCard.map(
            (item) => (item.style.color = '#565756')
          );
          textCustomColor.map((item) => (item.style.color = '#292929'));

          textCustomColorNewsletter.map(
            (item) => (item.style.color = '#2c3e50')
          );

          contentCovid[0].style.backgroundColor = 'white';
          contentCidBr[0].style.backgroundColor = 'white';
        }
      } else if (this.activatePageBar === 'About') {
        //customização para a tela de listagem de sobre
        if (isActive) {
          sun[0].style.color = '#0F0F0F';
          moon[0].style.color = '#FFF';
          pageBody[0].style.backgroundColor = '#0F0F0F';

          textCustomColorTitle.map((item) => (item.style.color = '#EEEEEE'));
          textCustomColor.map((item) => (item.style.color = '#BBBBBB'));
        } else {
          moon[0].style.color = '#0F0F0F';
          sun[0].style.color = '#FFF';
          pageBody[0].style.backgroundColor = '#f7f8f9';

          textCustomColorTitle.map((item) => (item.style.color = '#232121'));
          textCustomColor.map((item) => (item.style.color = '#292929'));
        }
      }
    },
  },
};
</script>

<style scoped>
.menu-home,
.menuShared,
.menu {
  width: 100%;
  background-image: linear-gradient(to right, #29b6d1, #02bdf8);
  border-bottom-right-radius: 60px;
  z-index: 30;
}
.menuShared {
  height: 140px;
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1410px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
  }
}

@media (max-width: 1600px) {
  .container {
    max-width: 1600px;
  }
}

@media (max-width: 480px) {
  .icons-left {
    margin: 0px !important;
  }
  .menuShared {
    height: 70px !important;
  }
}

#pagina-inicial {
  color: white;
}

.voltar {
  width: 43px;
  height: 43px;
  border: none;
  border-radius: 16px;
  padding: 0px;
  background-color: #17d6eb;
  margin-left: 10px;
}

.voltar > button {
  color: white;
  background-color: transparent;
  border: none;
}

.voltar > button > span {
  margin-top: 9px;
}

a {
  margin-left: 20px;
  font-family: 'Righteous', cursive;
  color: #323232;
}
a:hover {
  text-decoration: none;
  color: white;
}

.nav {
  padding-left: 40px;
  float: left;
}

.icons-left {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.ml-auto,
.mx-auto {
  align-items: center;
}

@media screen {
  .icons-left {
    min-height: 55px;
    margin-right: 55px;
    width: 200px;
  }
  .menuShared {
    max-height: 140px;
  }
}
@media (min-width: 992px) {
  .menuShared {
    max-height: 140px;
  }
}
.button-dark-light {
  min-width: 60px;
  background-color: #fff;
  border-radius: 20px;
  height: 23px;
  padding-top: 2px;
  margin: 0px 12px 0px 12px;
  padding-left: 4px;
}
.button-dark-light > div {
  border-radius: 20px;
  min-width: 20px;
  max-width: 30px;
  background-color: #17d6eb;
  height: 19px;
  position: relative;
}
.bell {
  color: #fff;
  font-weight: 500;
}

.sun {
  color: #fff;
  font-weight: 500;
}

.moon {
  margin-right: 50px;
  font-weight: 500;
}
</style>
