<template>
  <div class="pageBody">
    <BarHeader :activatePageBar="'About'" />
    <div class="content container">
      <div>
        <h1 class="text-custom-color-title">
          Sobre
        </h1>
      </div>
      <p class="text-custom-color">
        Este portal faz parte do Trabalho de Conclusão de Curso (TCC) do
        estudante Emanoel Bezerra Alves, academico em Ciência da Computação na
        Universidade Federal do Ceará (UFC).
        <br />
        <br />
        O objetivo desse portal é possibilitar compartilhar e tornar acessível
        dados abertos para a populaçao que faz parte do Sertão dos Crateús. De
        início, os dados que estão presente até o momento são relacionados ao
        covid-19 e a doenças catalogadas no CID BR 10. As informações
        relacionadas ao covid-19 são extraidas da base de dados do
        <a
          href="https://github.com/wcota/covid19br"
          target="_blank"
          rel="noopener noreferrer"
          >Wesley Cota.</a
        >
        A coleta dos dados é feita diariamente de forma automática.
        <br />
        <br />
        Já as informações das doenças do CID BR 10 são extraidas de tabelas
        disponibilizadas pelo
        <a
          href="http://extranet.saude.ce.gov.br/tabulacao/deftohtm.exe?sim/obito.def"
          target="_blank"
          rel="noopener noreferrer"
          >Governo do Estado do Ceará.</a
        >
        As informações são atualizadas a cada 15 dias, nossa aplicação executa a
        extração e atualiza a base de dados de acordo com o intervalo de dias.
        <br /><br />
        Buscamos apresentar as informaçoes da melhor forma possível, com a
        finalidade de tornar mais acessível as informações para os usuários.
        Você vai encontrar as informações em forma de mapa interativo, onde é
        possível selecionar a região e o estado, gráficos onde exibe as
        informações centrada na região do Sertão dos Crateús e por a cidade
        selecionada pelo usuário, também há tabelas onde é feita a listagem das
        informações por cada cidade pertencente a região, o historico dos dados
        com base na cidade selecionada e no intervalo de dias marcado pelo
        usuário.
        <br /><br />
        É disponibilizado uma lista de PDF's com os dados de cada atualização do
        covid-19 e das doenças do CID BR 10. É possível visualizar o arquivo e
        também baixa-lo caso queira compartilhar.
        <br />
      </p>
      <div>
        <h1 class="text-custom-color-title">
          Ferramentas utilizadas no projeto
        </h1>
        <br />
        <div class="logo-tools">
          <div>
            <a href="https://vuejs.org/" target="_blank"
              ><img src="../../src/assets/logo.png" alt=""
            /></a>
          </div>
          <div>
            <a href="https://pm2.keymetrics.io/" target="_blank"
              ><img class="img-config" src="../../public/pm2.png" alt=""
            /></a>
            <a href="https://nodejs.org/en/" target="_blank"
              ><img class="img-config" src="../../public/nodejs.png" alt=""
            /></a>
          </div>
          <div>
            <a href="https://www.chartjs.org/" target="_blank"
              ><img class="img-config" src="../../public/chartjs.svg" alt=""
            /></a>
            <a href="https://leafletjs.com/" target="_blank"
              ><img class="img-config" src="../../public/leaflet.svg" alt=""
            /></a>
            <a href="https://pptr.dev/" target="_blank"
              ><img src="../../public/puppeteer.png" alt=""
            /></a>
          </div>
          <div>
            <a href="https://id.heroku.com/login" target="_blank"
              ><img class="img-config" src="../../public/heroku.png" alt=""
            /></a>
            <a href="https://www.mapbox.com/" target="_blank"
              ><img class="img-config" src="../../public/mapbox.png" alt=""
            /></a>
          </div>
          <div>
            <a href="https://www.mongodb.com/" target="_blank"
              ><img class="img-config" src="../../public/mongodb.png" alt=""
            /></a>
          </div>
        </div>
      </div>
      <br /><br />
      <br />
    </div>
    <BarFooter />
  </div>
</template>

<script>
import BarFooter from '@/components/BarFooter.vue';
import BarHeader from '@/components/BarHeader.vue';

export default {
  name: 'About',
  components: {
    BarHeader,
    BarFooter,
  },
};
</script>

<style scoped>
* {
  margin: 0px;
  padding: 0px;
  width: 100%;
  /* font-family: 'Righteous', cursive; */
  background-color: transparent;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1410px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
    margin: auto;
  }
}

@media (max-width: 800px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 800px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
  }

  .content-covid,
  .content-cidBr {
    width: 100% !important;
    margin: 5px !important;
  }
  h1,
  h3,
  p {
    padding: 8px !important;
  }
}

@media (max-width: 900px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 800px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
  }

  .content-covid,
  .content-cidBr {
    width: 100% !important;
    margin: 5px !important;
  }

  div > a {
    width: 90px !important;
    height: 90px !important;
    margin-top: 6px !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }

  img {
    width: 50% !important;
  }
  .img-config {
    width: 90% !important;
  }
}

h1 {
  /* background-color: transparent; */
  font-size: 50px;
  font-weight: bold;
  /* width: 70%; */
  padding-left: 10%;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 10px;
  font-family: 'Righteous', cursive;
  color: #323232;
  font-weight: 100;
}

p {
  padding-left: 10%;
  padding-right: 12%;
  text-align: left;
}
img {
  width: 70px;
}

div > a {
  border: solid 3px #17d6eb;
  border-radius: 23px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  margin-left: 5%;
  margin-right: 5%;
}
.logo-tools > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

div > a:hover {
  background-color: #17d6eb;
}
</style>
