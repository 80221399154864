const cidBrDiseases = {
  state: {
    diseaseName: '001-031 ALGUMAS DOENÇAS INFECCIOSAS E PARASITÁRIAS',
    dataCidBrRegionCrateus: [],
    dataCidBrByDisease: [],
    citySelectedCidBr: [],
    itemsSelectedByDiseaseRegion: [],
    updateDateSplit: '',
  },
  mutations: {
    SET_DISEASE_NAME(state, diseaseName) {
      state.diseaseName = diseaseName;
    },
    SET_DATA_CID_BR_REGION(state, dataCidBrRegionCrateus) {
      state.dataCidBrRegionCrateus = dataCidBrRegionCrateus;
    },
    SET_DATA_CID_BR_BY_DISEASE(state, dataCidBrByDisease) {
      state.dataCidBrByDisease = dataCidBrByDisease;
    },
    SET_CITY_SELECTED_CID_BR(state, citySelectedCidBr) {
      state.citySelectedCidBr = citySelectedCidBr;
    },
    SET_ITEMS_SELECTED_BY_DISEASE_REGION(state, itemsSelectedByDiseaseRegion) {
      state.itemsSelectedByDiseaseRegion = itemsSelectedByDiseaseRegion;
    },
    SET_UPDATE_DATE_SPLIT(state, updateDateSplit) {
      state.updateDateSplit = updateDateSplit;
    },
  },
  actions: {
    changeDiseaseName(context, diseaseName) {
      context.commit('SET_DISEASE_NAME', diseaseName);
    },
    changeDataCidBrRegionCrateus(context, dataCidBrRegionCrateus) {
      context.commit('SET_DATA_CID_BR_REGION', dataCidBrRegionCrateus);
    },
    changeDataCidBrByDisease(context, dataCidBrByDisease) {
      context.commit('SET_DATA_CID_BR_BY_DISEASE', dataCidBrByDisease);
    },
    changeCitySelectedCidBr(context, citySelectedCidBr) {
      context.commit('SET_CITY_SELECTED_CID_BR', citySelectedCidBr);
    },
    changeItemsSelectedByDiseaseRegion(context, itemsSelectedByDiseaseRegion) {
      context.commit(
        'SET_ITEMS_SELECTED_BY_DISEASE_REGION',
        itemsSelectedByDiseaseRegion
      );
    },
    changeUpdateDateSplit(context, updateDateSplit) {
      context.commit('SET_UPDATE_DATE_SPLIT', updateDateSplit);
    },
  },
  getters: {
    getDiseaseName: (state) => state.diseaseName,
  },
};

export { cidBrDiseases };
