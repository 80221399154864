const geojsonRegion = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2301257',
        name: 'Ararendá',
        description: 'Ararendá',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.6566878969, -4.708463615],
            [-40.6457134044, -4.7205942598],
            [-40.6413094869, -4.726979289],
            [-40.6482831831, -4.7336606623],
            [-40.6452511193, -4.7459393873],
            [-40.6373757028, -4.759978071],
            [-40.6336206763, -4.7840975471],
            [-40.6336440005, -4.788347378],
            [-40.6341183414, -4.7929474405],
            [-40.6474162416, -4.8065684095],
            [-40.6501306709, -4.8137713641],
            [-40.6595641836, -4.8309904599],
            [-40.6651977371, -4.8433506082],
            [-40.6765297694, -4.851369051],
            [-40.6966327438, -4.8626365783],
            [-40.7186169333, -4.8514752728],
            [-40.7211735064, -4.8476071881],
            [-40.7235624849, -4.8427748221],
            [-40.7621142409, -4.8662502795],
            [-40.7644274646, -4.8433265918],
            [-40.7806948284, -4.8350560791],
            [-40.7828390011, -4.8245331701],
            [-40.7951506218, -4.8165275257],
            [-40.7985622197, -4.8153902312],
            [-40.802097908, -4.8166588841],
            [-40.8094913447, -4.8167986101],
            [-40.8240701775, -4.8109876269],
            [-40.8327561475, -4.803208121],
            [-40.8422536208, -4.7855319385],
            [-40.8648672719, -4.7838249857],
            [-40.8640397221, -4.7878150791],
            [-40.8788615124, -4.78705221],
            [-40.8823367504, -4.7874000566],
            [-40.8832313035, -4.7811537029],
            [-40.8787196432, -4.7703433621],
            [-40.882257849, -4.7528302871],
            [-40.8760662136, -4.7461634758],
            [-40.8658736474, -4.7317404557],
            [-40.8712335549, -4.7251819631],
            [-40.8723047148, -4.7133389915],
            [-40.8634756415, -4.7151672781],
            [-40.858724653, -4.7119643726],
            [-40.8444651722, -4.7180641072],
            [-40.8331400192, -4.7177571124],
            [-40.8138381809, -4.7090937973],
            [-40.8078832136, -4.7047033523],
            [-40.7852215808, -4.7114148987],
            [-40.7811496542, -4.7079361111],
            [-40.7780165882, -4.7110039379],
            [-40.7621088959, -4.706217113],
            [-40.7523765428, -4.7111240627],
            [-40.7369041934, -4.7084548965],
            [-40.7316945571, -4.7141012842],
            [-40.6705221018, -4.7029342995],
            [-40.6611410839, -4.6866698966],
            [-40.6550834556, -4.689783756],
            [-40.6595881852, -4.7035256491],
            [-40.6566878969, -4.708463615],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2303659',
        name: 'Catunda',
        description: 'Catunda',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.0453690543, -4.4557995562],
            [-40.0367230387, -4.4568354935],
            [-40.0033139145, -4.4884692578],
            [-40.0055901129, -4.4977061992],
            [-40.0015300653, -4.5047811007],
            [-40.0074761273, -4.5224234707],
            [-40.0236161037, -4.5446318352],
            [-40.0313677184, -4.5670460902],
            [-40.0434469744, -4.5756027402],
            [-40.0573677647, -4.5680295552],
            [-40.0733430576, -4.5873572399],
            [-40.0893129913, -4.5880515631],
            [-40.1115024624, -4.594998023],
            [-40.1214763879, -4.6105393149],
            [-40.1120712233, -4.6119133151],
            [-40.1109350008, -4.6256602792],
            [-40.1075488814, -4.6273161263],
            [-40.105656718, -4.638054799],
            [-40.1076241778, -4.6422317152],
            [-40.1032784129, -4.6425483449],
            [-40.107043301, -4.644554286],
            [-40.1066719971, -4.6494262943],
            [-40.1038884678, -4.6608672694],
            [-40.0917048541, -4.664085984],
            [-40.0803863186, -4.6792762017],
            [-40.0801323593, -4.6838705248],
            [-40.0877079638, -4.6841153772],
            [-40.0808832081, -4.6922278229],
            [-40.0833813553, -4.6968175206],
            [-40.0781872972, -4.6966210535],
            [-40.0766550867, -4.7000800357],
            [-40.0608615061, -4.700311001],
            [-40.0667564288, -4.7080931347],
            [-40.0590854725, -4.7174973622],
            [-40.1118214157, -4.7518829545],
            [-40.1281963366, -4.7985066082],
            [-40.1324323034, -4.8037133668],
            [-40.1452907739, -4.8077347306],
            [-40.1551937207, -4.8093478761],
            [-40.1581871773, -4.8042217816],
            [-40.1789217003, -4.7879731637],
            [-40.1958390722, -4.7914430392],
            [-40.204231938, -4.7900483118],
            [-40.2212561616, -4.788687915],
            [-40.2448413181, -4.7772217867],
            [-40.268686972, -4.77308181],
            [-40.2844727229, -4.7496943797],
            [-40.3131542937, -4.7279672528],
            [-40.314432593, -4.7251302553],
            [-40.3103193264, -4.7231702948],
            [-40.3040404576, -4.7225256626],
            [-40.3051130989, -4.7164874578],
            [-40.3148649756, -4.7068444978],
            [-40.3142217888, -4.6892188811],
            [-40.3222482902, -4.6836361916],
            [-40.3312013443, -4.6666756396],
            [-40.3367064811, -4.6643428755],
            [-40.3366118571, -4.6545368031],
            [-40.3309807674, -4.6473711542],
            [-40.3314947222, -4.639505412],
            [-40.3170952263, -4.6357526458],
            [-40.318904265, -4.6220786334],
            [-40.3166020455, -4.617514462],
            [-40.311843731, -4.6176942747],
            [-40.3029346373, -4.6093307002],
            [-40.3034554993, -4.6047617281],
            [-40.2978855876, -4.6062129355],
            [-40.2970692595, -4.60379358],
            [-40.2942418728, -4.6006392981],
            [-40.2823834003, -4.6084959295],
            [-40.2773925749, -4.6069699537],
            [-40.2714776112, -4.611172422],
            [-40.2644815269, -4.6060382516],
            [-40.2526315275, -4.6055615436],
            [-40.2509171259, -4.6014148756],
            [-40.247043181, -4.6027909427],
            [-40.2363713145, -4.5926837445],
            [-40.2428411239, -4.5880253052],
            [-40.2390344488, -4.579441549],
            [-40.244092707, -4.5784249315],
            [-40.2330718146, -4.5572490698],
            [-40.2406180949, -4.5596849349],
            [-40.2429593471, -4.5575641352],
            [-40.2425616081, -4.5498046539],
            [-40.2400897997, -4.5496418007],
            [-40.2438808216, -4.523326256],
            [-40.246942353, -4.5199999029],
            [-40.2565313468, -4.5192686286],
            [-40.2679684114, -4.5069340562],
            [-40.2556720616, -4.4916005314],
            [-40.2562168653, -4.4866608393],
            [-40.2503026494, -4.4916919964],
            [-40.2490591237, -4.4876691273],
            [-40.2455661194, -4.4877580932],
            [-40.2475559313, -4.4799122778],
            [-40.2541106529, -4.4726006722],
            [-40.2082231165, -4.4654339965],
            [-40.1996982726, -4.4729768932],
            [-40.1935371506, -4.4949973748],
            [-40.1837785438, -4.5029307239],
            [-40.1828420751, -4.5075716086],
            [-40.1694285438, -4.5075423876],
            [-40.1523083351, -4.5056374819],
            [-40.1354442886, -4.5117477174],
            [-40.1201221971, -4.5040119911],
            [-40.1116151202, -4.5107778792],
            [-40.1009113557, -4.5083410351],
            [-40.0915591972, -4.5011270989],
            [-40.0957834881, -4.4927728585],
            [-40.1025814995, -4.492955937],
            [-40.0980298492, -4.4845258207],
            [-40.0898225793, -4.4824648432],
            [-40.0682810663, -4.4709611078],
            [-40.0577830958, -4.4545166449],
            [-40.0497558658, -4.4562748725],
            [-40.0461122574, -4.453141158],
            [-40.0453690543, -4.4557995562],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305605',
        name: 'Independência',
        description: 'Independência',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.1866693432, -5.1678347849],
            [-40.1882548386, -5.1757380376],
            [-40.1806126447, -5.170019174],
            [-40.1702215739, -5.1744573992],
            [-40.1652492692, -5.1650160263],
            [-40.1586407427, -5.1665740492],
            [-40.1531745499, -5.1636369722],
            [-40.1409314093, -5.1690493907],
            [-40.1385520942, -5.1630764744],
            [-40.1215310477, -5.1651907117],
            [-40.1154360629, -5.1618537964],
            [-40.1126265233, -5.1667422511],
            [-40.0977372445, -5.1633676993],
            [-40.0913261684, -5.1724273035],
            [-40.0851272114, -5.1738090154],
            [-40.0747542835, -5.1650459527],
            [-40.0591659482, -5.1856861046],
            [-40.0566482536, -5.18611449],
            [-40.0550030899, -5.1809319524],
            [-40.0351439059, -5.1827923391],
            [-40.0294223594, -5.1916349533],
            [-40.0308663565, -5.2000489496],
            [-40.0208894806, -5.2054656644],
            [-40.0350283529, -5.2216001282],
            [-40.0373680223, -5.2302245239],
            [-40.0462533851, -5.2386930528],
            [-40.0459334631, -5.2428221134],
            [-40.0426715546, -5.2609651903],
            [-40.0468392976, -5.2717587021],
            [-40.0480291108, -5.2816749423],
            [-40.0550071377, -5.2848318275],
            [-40.0552230906, -5.2910662824],
            [-40.0291698912, -5.2962836438],
            [-40.0244218218, -5.3018656711],
            [-40.0278886284, -5.3111900235],
            [-40.0180852588, -5.3184787768],
            [-40.0224773334, -5.3241192508],
            [-40.0205332514, -5.3275777954],
            [-40.023166357, -5.3372459216],
            [-40.0281730951, -5.3415423329],
            [-40.0271121258, -5.3458636901],
            [-40.0385021466, -5.3534632485],
            [-40.0550060348, -5.3578442221],
            [-40.0556693713, -5.3638497564],
            [-40.0516471377, -5.366106347],
            [-40.056420488, -5.3699556732],
            [-40.0610865729, -5.3895028851],
            [-40.0583072461, -5.3964660694],
            [-40.0686819362, -5.4028140014],
            [-40.0772915018, -5.4211822505],
            [-40.08296452, -5.4223230097],
            [-40.0834148989, -5.4275373885],
            [-40.0906285285, -5.4358617336],
            [-40.0897541876, -5.4402839131],
            [-40.1009237726, -5.4433276281],
            [-40.0974663132, -5.4464706366],
            [-40.1007912641, -5.451370283],
            [-40.1066998786, -5.4520951255],
            [-40.1091549428, -5.4620221682],
            [-40.1062554196, -5.4671820483],
            [-40.1119109247, -5.4909604987],
            [-40.1080279323, -5.5060296447],
            [-40.111731141, -5.5167589984],
            [-40.1088995969, -5.5270140995],
            [-40.110009235, -5.5347127602],
            [-40.1151077896, -5.5386629738],
            [-40.1187227726, -5.5481599212],
            [-40.1238265611, -5.5543111831],
            [-40.1410986354, -5.5456526548],
            [-40.1430660765, -5.5408538645],
            [-40.1587833298, -5.5456638237],
            [-40.1668110076, -5.5448108445],
            [-40.1728088475, -5.5599348776],
            [-40.1795203231, -5.5585581651],
            [-40.1851430082, -5.5616256013],
            [-40.1859066847, -5.5704686093],
            [-40.1944190847, -5.5740347157],
            [-40.2266770578, -5.5929993052],
            [-40.2289280796, -5.599726461],
            [-40.2196352357, -5.6044093453],
            [-40.2241314464, -5.6064859651],
            [-40.2328218432, -5.6143233846],
            [-40.2293638345, -5.6187765181],
            [-40.2336456513, -5.6307793359],
            [-40.2272323908, -5.6486979259],
            [-40.2308984385, -5.6595580963],
            [-40.2201622487, -5.6674575851],
            [-40.2105561487, -5.6913214027],
            [-40.2216768316, -5.6992630282],
            [-40.2256048051, -5.7093298468],
            [-40.2364015213, -5.7093948155],
            [-40.2405255028, -5.7078017214],
            [-40.2408134175, -5.7192397042],
            [-40.2558007446, -5.7284892664],
            [-40.2816617237, -5.7514886256],
            [-40.2842633463, -5.7575963201],
            [-40.2960546096, -5.7496947684],
            [-40.3116419975, -5.7464233408],
            [-40.3118702195, -5.7462483575],
            [-40.3196081902, -5.7422947497],
            [-40.3409548017, -5.7481401444],
            [-40.3516956027, -5.7433186736],
            [-40.3681174045, -5.7453906654],
            [-40.3709858427, -5.7526436999],
            [-40.3806148058, -5.759000121],
            [-40.3820838853, -5.7677509838],
            [-40.3883387955, -5.7764463147],
            [-40.3976112427, -5.7806029971],
            [-40.4119952948, -5.8100433627],
            [-40.4170057795, -5.8116484534],
            [-40.4445673317, -5.8257493948],
            [-40.4529940611, -5.8281976381],
            [-40.4729632963, -5.8260779729],
            [-40.4762258588, -5.8317005813],
            [-40.4854085764, -5.8347113421],
            [-40.4939496366, -5.827694415],
            [-40.4941594224, -5.8276473792],
            [-40.5159584386, -5.8245562368],
            [-40.5115887151, -5.8151091403],
            [-40.5208899173, -5.8132373593],
            [-40.5237969413, -5.8172748809],
            [-40.5298969249, -5.8164236433],
            [-40.5331198071, -5.8239370245],
            [-40.5481987003, -5.8319465062],
            [-40.5570075843, -5.8420389179],
            [-40.5650381583, -5.8416306763],
            [-40.5691162029, -5.8285508421],
            [-40.5659416239, -5.8214144708],
            [-40.5687192324, -5.8137399492],
            [-40.5673669553, -5.7942690452],
            [-40.563975954, -5.7864476382],
            [-40.569666681, -5.7713924143],
            [-40.5705735902, -5.7541006409],
            [-40.5569645019, -5.7389649451],
            [-40.5468461927, -5.7395142538],
            [-40.5377370049, -5.7225596142],
            [-40.5705076577, -5.7393802764],
            [-40.5981540455, -5.7373947027],
            [-40.6027436489, -5.7258191324],
            [-40.6115987122, -5.7239547456],
            [-40.6129665243, -5.7270149719],
            [-40.620553325, -5.7184141382],
            [-40.6193042334, -5.7131912264],
            [-40.6335814899, -5.7006417317],
            [-40.6274174481, -5.6940193309],
            [-40.6245825668, -5.6952647447],
            [-40.6194769631, -5.6912836043],
            [-40.6175564794, -5.6839481857],
            [-40.6324367397, -5.6800109743],
            [-40.6407648231, -5.6842768099],
            [-40.6459279125, -5.67696288],
            [-40.6532735557, -5.6676966827],
            [-40.6470165773, -5.6653651163],
            [-40.6475928295, -5.65739738],
            [-40.6382047157, -5.6438390471],
            [-40.6345707666, -5.6468108685],
            [-40.6314640944, -5.6435508276],
            [-40.6282224422, -5.6312017587],
            [-40.6380956571, -5.6244317518],
            [-40.6385992938, -5.6195783371],
            [-40.6383969232, -5.6153423693],
            [-40.6266924201, -5.6085300781],
            [-40.62003231, -5.5984349009],
            [-40.6277678972, -5.5853414626],
            [-40.62704382, -5.5786522829],
            [-40.6214397937, -5.5761647543],
            [-40.6210950804, -5.5702828331],
            [-40.6131645724, -5.57077488],
            [-40.6136372766, -5.5649517294],
            [-40.6086012283, -5.5579106561],
            [-40.6160310027, -5.5482406905],
            [-40.6206744437, -5.5291378545],
            [-40.5969607997, -5.4907941339],
            [-40.5841777049, -5.4670258354],
            [-40.5771452987, -5.4629716737],
            [-40.5686890317, -5.4379910084],
            [-40.5525067147, -5.4318854758],
            [-40.5543385102, -5.4263034406],
            [-40.5484316361, -5.3984170698],
            [-40.539185464, -5.389086791],
            [-40.5234785107, -5.3893259423],
            [-40.4868957714, -5.2948684037],
            [-40.4810756953, -5.2792233993],
            [-40.479092614, -5.2738941182],
            [-40.4141967763, -5.2289463476],
            [-40.406863071, -5.2236564651],
            [-40.3812657896, -5.2051931191],
            [-40.3548781102, -5.1861618498],
            [-40.3312935271, -5.1691562519],
            [-40.3109613, -5.1563658766],
            [-40.3054180925, -5.1667447573],
            [-40.2980273003, -5.1700122128],
            [-40.2695833099, -5.1622610631],
            [-40.2571999385, -5.1659775031],
            [-40.2577928948, -5.1712645966],
            [-40.248404695, -5.167869001],
            [-40.2416665541, -5.1706520529],
            [-40.2389919495, -5.1624728751],
            [-40.2280344553, -5.1522426321],
            [-40.2187567277, -5.1519594746],
            [-40.2114418013, -5.1557516534],
            [-40.1983111916, -5.1648356443],
            [-40.1866693432, -5.1678347849],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305654',
        name: 'Ipaporanga',
        description: 'Ipaporanga',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.8422536208, -4.7855319385],
            [-40.8327561475, -4.803208121],
            [-40.8240701775, -4.8109876269],
            [-40.8094913447, -4.8167986101],
            [-40.802097908, -4.8166588841],
            [-40.7985622197, -4.8153902312],
            [-40.7951506218, -4.8165275257],
            [-40.7828390011, -4.8245331701],
            [-40.7806948284, -4.8350560791],
            [-40.7644274646, -4.8433265918],
            [-40.7621142409, -4.8662502795],
            [-40.7235624849, -4.8427748221],
            [-40.7211735064, -4.8476071881],
            [-40.7186169333, -4.8514752728],
            [-40.6966327438, -4.8626365783],
            [-40.6765297694, -4.851369051],
            [-40.6651977371, -4.8433506082],
            [-40.6595641836, -4.8309904599],
            [-40.6501306709, -4.8137713641],
            [-40.6474162416, -4.8065684095],
            [-40.6341183414, -4.7929474405],
            [-40.615580218, -4.8131107439],
            [-40.6014789385, -4.8323347679],
            [-40.5990021368, -4.8357101305],
            [-40.5420839082, -4.9148046882],
            [-40.5967579912, -4.9203140548],
            [-40.6354497739, -4.9243361364],
            [-40.6518992559, -4.9854015222],
            [-40.7148185973, -4.9476797483],
            [-40.7150651975, -4.9477488653],
            [-40.8264412231, -4.9787872086],
            [-40.8649024481, -4.9867746161],
            [-40.8752328114, -4.9888169294],
            [-41.0307615761, -5.0195699621],
            [-41.0310445623, -5.0196257114],
            [-41.0514930258, -5.0236693105],
            [-41.0522684105, -5.0173429206],
            [-41.0433627781, -4.9981110563],
            [-41.0358559025, -4.9651406136],
            [-41.0242394428, -4.9536847296],
            [-41.0218953883, -4.9545129413],
            [-41.0079280402, -4.9393146618],
            [-41.0078176048, -4.935417573],
            [-41.0033948372, -4.933884382],
            [-41.0061972178, -4.9306680922],
            [-40.9997381382, -4.9280891595],
            [-40.9918261974, -4.9151510187],
            [-40.9505009652, -4.8871208831],
            [-40.941502835, -4.8824787084],
            [-40.9266187242, -4.8858713734],
            [-40.9189133878, -4.8833563117],
            [-40.9092680384, -4.8745027772],
            [-40.8836147519, -4.8662821315],
            [-40.8790581456, -4.8678966605],
            [-40.8720772686, -4.8626733493],
            [-40.880316263, -4.8594152859],
            [-40.8751754115, -4.8531949969],
            [-40.8810375033, -4.8430803064],
            [-40.879523278, -4.8392327106],
            [-40.8760110689, -4.8320617473],
            [-40.879720831, -4.8295563509],
            [-40.8775457102, -4.818081445],
            [-40.879310732, -4.8127382985],
            [-40.885901015, -4.797102836],
            [-40.8801441126, -4.7996136248],
            [-40.8823041454, -4.7933117072],
            [-40.8788615124, -4.78705221],
            [-40.8640397221, -4.7878150791],
            [-40.8648672719, -4.7838249857],
            [-40.8422536208, -4.7855319385],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305902',
        name: 'Ipueiras',
        description: 'Ipueiras',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.7741627877, -4.4365684251],
            [-40.7334364683, -4.4644736673],
            [-40.7163237088, -4.4761945216],
            [-40.7068546715, -4.4826792253],
            [-40.7031034132, -4.4806962687],
            [-40.6949856263, -4.4513554928],
            [-40.6797721148, -4.4336541617],
            [-40.6808039982, -4.4266244858],
            [-40.6771249479, -4.4381781434],
            [-40.6616766326, -4.4622279623],
            [-40.6570915926, -4.4637406184],
            [-40.65609479, -4.4699168428],
            [-40.6464862397, -4.4873974795],
            [-40.6441364496, -4.5046244551],
            [-40.6360165568, -4.5220371719],
            [-40.6241225063, -4.5325579207],
            [-40.6140187773, -4.550927173],
            [-40.5970964094, -4.5574653864],
            [-40.593741392, -4.5620694678],
            [-40.5883015111, -4.5578012728],
            [-40.5878488585, -4.5575254258],
            [-40.5876024863, -4.574794015],
            [-40.5834125889, -4.5780082309],
            [-40.5879214706, -4.5976692773],
            [-40.602056366, -4.6187591472],
            [-40.6223375769, -4.6254527794],
            [-40.6260720273, -4.6216288789],
            [-40.6262774719, -4.6220103779],
            [-40.6611410839, -4.6866698966],
            [-40.6705221018, -4.7029342995],
            [-40.7316945571, -4.7141012842],
            [-40.7369041934, -4.7084548965],
            [-40.7523765428, -4.7111240627],
            [-40.7621088959, -4.706217113],
            [-40.7780165882, -4.7110039379],
            [-40.7811496542, -4.7079361111],
            [-40.7852215808, -4.7114148987],
            [-40.8078832136, -4.7047033523],
            [-40.8138381809, -4.7090937973],
            [-40.8331400192, -4.7177571124],
            [-40.8444651722, -4.7180641072],
            [-40.858724653, -4.7119643726],
            [-40.8634756415, -4.7151672781],
            [-40.8723047148, -4.7133389915],
            [-40.8668315385, -4.7062643915],
            [-40.8673662452, -4.698926707],
            [-40.8628115586, -4.6906602252],
            [-40.8527291239, -4.6856107523],
            [-40.8546973615, -4.6773814015],
            [-40.8442362599, -4.6729521321],
            [-40.8570692523, -4.6729514269],
            [-40.8608699721, -4.6794525126],
            [-40.8710056297, -4.6858820332],
            [-40.8745355138, -4.6915431256],
            [-40.8743071586, -4.701556221],
            [-40.8881432437, -4.7166420869],
            [-40.954540492, -4.7158950021],
            [-40.9628969878, -4.6873171004],
            [-40.9685238536, -4.6918144773],
            [-40.973438945, -4.6891213409],
            [-40.9874905206, -4.6596503871],
            [-40.9881152544, -4.6598406515],
            [-41.0078553873, -4.6510084704],
            [-41.0211747698, -4.6389816944],
            [-41.0300474219, -4.6398444106],
            [-41.0348487051, -4.6349116635],
            [-41.0515460697, -4.6290640474],
            [-41.0691546736, -4.6147309115],
            [-41.0870258155, -4.6130724808],
            [-41.0991019006, -4.6023725264],
            [-41.114231088, -4.6013811023],
            [-41.1464042503, -4.599271889],
            [-41.158800175, -4.593687272],
            [-41.1650587148, -4.5844738716],
            [-41.1836982512, -4.5918681823],
            [-41.1874737348, -4.5880509996],
            [-41.1849410845, -4.5765975413],
            [-41.2076719394, -4.5853370934],
            [-41.2168696406, -4.5833568915],
            [-41.2197816958, -4.5740700963],
            [-41.2394837713, -4.573143929],
            [-41.2421652858, -4.5711315337],
            [-41.2403654145, -4.5680713142],
            [-41.2217057308, -4.5672766215],
            [-41.2137380735, -4.5522949061],
            [-41.207557408, -4.5473562883],
            [-41.2034166963, -4.5280395537],
            [-41.190402206, -4.5151472015],
            [-41.188973482, -4.4882991096],
            [-41.1771980797, -4.467296141],
            [-41.1618680622, -4.4662905604],
            [-41.1458661214, -4.4697462124],
            [-41.1257154517, -4.4830084306],
            [-41.1199487671, -4.4785505693],
            [-41.0627637344, -4.4737779085],
            [-41.0274686131, -4.4565002899],
            [-41.0053746645, -4.4591378584],
            [-40.9208361221, -4.4692221342],
            [-40.9185552996, -4.4685195812],
            [-40.8885267556, -4.4719344423],
            [-40.8830453803, -4.4690175805],
            [-40.864440677, -4.4638351473],
            [-40.852965156, -4.465527712],
            [-40.8251662925, -4.4585439789],
            [-40.8109526479, -4.4430991522],
            [-40.7971663945, -4.4410375126],
            [-40.7886286922, -4.4305392029],
            [-40.7936203727, -4.4232289709],
            [-40.7826622558, -4.4307418521],
            [-40.7741627877, -4.4365684251],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2308609',
        name: 'Monsenhor Tabosa',
        description: 'Monsenhor Tabosa',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-39.9245443581, -4.7348789161],
            [-39.9282201008, -4.7741557181],
            [-39.9392926638, -4.7701640451],
            [-39.9350792554, -4.7766849093],
            [-39.9386828597, -4.7823709342],
            [-39.9289378471, -4.7950563595],
            [-39.93003035, -4.7996887174],
            [-39.9482988421, -4.8159774441],
            [-39.9463970458, -4.821321982],
            [-39.9490016642, -4.8261950354],
            [-39.9448832992, -4.838227192],
            [-39.9402608914, -4.8412397605],
            [-39.9241355092, -4.864287901],
            [-39.9086660196, -4.8744488361],
            [-39.9004586827, -4.8840030854],
            [-39.8925631889, -4.8867776754],
            [-39.8957479489, -4.8907204537],
            [-39.8919863684, -4.8925073595],
            [-39.8938660435, -4.8934605706],
            [-39.9025958091, -4.899427287],
            [-39.9001128324, -4.901552868],
            [-39.9021333754, -4.9051103765],
            [-39.907697018, -4.9030636748],
            [-39.9190734421, -4.9082773566],
            [-39.9228753441, -4.9128752123],
            [-39.9290265453, -4.9132540964],
            [-39.9338683448, -4.9238249426],
            [-39.9391139601, -4.9215213679],
            [-39.9468148236, -4.9290447481],
            [-39.9513935968, -4.9236451873],
            [-39.9570122215, -4.9269172322],
            [-39.9621432317, -4.9318610601],
            [-39.9600333814, -4.9390748274],
            [-39.9680666968, -4.9400960682],
            [-39.9660309717, -4.9603287007],
            [-39.959713476, -5.0205515594],
            [-39.9599450305, -5.0285775414],
            [-39.9731144638, -5.0298259275],
            [-39.9687026447, -5.0434072244],
            [-39.9792332677, -5.0569130011],
            [-39.9967763795, -5.0722861943],
            [-40.0018008778, -5.0846603074],
            [-40.0456846234, -5.1061847939],
            [-40.048120066, -5.1073791268],
            [-40.1650056429, -5.1647322491],
            [-40.1656711681, -5.1590272115],
            [-40.162214329, -5.1550534812],
            [-40.1450867833, -5.152247056],
            [-40.1457828305, -5.1461748849],
            [-40.1344240843, -5.130784154],
            [-40.1369452736, -5.1218901169],
            [-40.1346433849, -5.1113144805],
            [-40.1398089007, -5.1048810298],
            [-40.1352269667, -5.0990823621],
            [-40.149592309, -5.0910271178],
            [-40.1501254039, -5.0826671677],
            [-40.1550601628, -5.0811064251],
            [-40.1537010055, -5.0767426301],
            [-40.1516140371, -5.075897618],
            [-40.1510973957, -5.0757629331],
            [-40.1421416033, -5.07379224],
            [-40.1485395806, -5.0597954699],
            [-40.1474537439, -5.0505846227],
            [-40.1474117797, -5.0502313372],
            [-40.1542949419, -5.043468822],
            [-40.1499394077, -5.0335170843],
            [-40.136714408, -5.0291844529],
            [-40.1218306835, -5.0297888084],
            [-40.1078683177, -5.0236907],
            [-40.0996977861, -5.0012905248],
            [-40.1047773974, -4.9970105656],
            [-40.1142539502, -5.0001141105],
            [-40.1183594303, -4.9954104006],
            [-40.1154235883, -4.9878853262],
            [-40.1192006285, -4.9851957373],
            [-40.1333844784, -4.9919131198],
            [-40.1307452049, -4.9870810613],
            [-40.1364483501, -4.9800560406],
            [-40.1333080551, -4.9771374725],
            [-40.1360315568, -4.9733001553],
            [-40.1571662714, -4.9692321252],
            [-40.1650861102, -4.9644999474],
            [-40.1653522047, -4.9376358795],
            [-40.1949755071, -4.908330249],
            [-40.1970293416, -4.9059402099],
            [-40.1683087185, -4.8981409391],
            [-40.1590349848, -4.9022196742],
            [-40.1440584962, -4.902163364],
            [-40.139562993, -4.8984776577],
            [-40.1365267906, -4.8888905276],
            [-40.1129226459, -4.881824664],
            [-40.106474481, -4.8710542983],
            [-40.111171237, -4.8499985364],
            [-40.138276332, -4.823003746],
            [-40.1420245234, -4.813705735],
            [-40.1452907739, -4.8077347306],
            [-40.1324323034, -4.8037133668],
            [-40.1281963366, -4.7985066082],
            [-40.1118214157, -4.7518829545],
            [-40.0590854725, -4.7174973622],
            [-39.9949769693, -4.7257850198],
            [-39.9556412024, -4.7308657504],
            [-39.9245443581, -4.7348789161],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2309300',
        name: 'Nova Russas',
        description: 'Nova Russas',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.3743901879, -4.5535305145],
            [-40.4227333117, -4.6611123752],
            [-40.4228705312, -4.6615170092],
            [-40.4378439843, -4.7056778474],
            [-40.4402719333, -4.7128408607],
            [-40.4466596341, -4.7316890192],
            [-40.4572596071, -4.7481126676],
            [-40.4826387759, -4.7874432991],
            [-40.4923193342, -4.8024489859],
            [-40.4977374686, -4.8108460595],
            [-40.522440011, -4.8477379611],
            [-40.5398369657, -4.843412951],
            [-40.5458101956, -4.8679186812],
            [-40.5395989336, -4.8767406801],
            [-40.5399776086, -4.8924588483],
            [-40.5366749266, -4.8997870021],
            [-40.5420839082, -4.9148046882],
            [-40.5990021368, -4.8357101305],
            [-40.6014789385, -4.8323347679],
            [-40.615580218, -4.8131107439],
            [-40.6341183414, -4.7929474405],
            [-40.6336440005, -4.788347378],
            [-40.6336206763, -4.7840975471],
            [-40.6373757028, -4.759978071],
            [-40.6452511193, -4.7459393873],
            [-40.6482831831, -4.7336606623],
            [-40.6413094869, -4.726979289],
            [-40.6457134044, -4.7205942598],
            [-40.6566878969, -4.708463615],
            [-40.6595881852, -4.7035256491],
            [-40.6550834556, -4.689783756],
            [-40.6611410839, -4.6866698966],
            [-40.6262774719, -4.6220103779],
            [-40.6260720273, -4.6216288789],
            [-40.6223375769, -4.6254527794],
            [-40.602056366, -4.6187591472],
            [-40.5879214706, -4.5976692773],
            [-40.5834125889, -4.5780082309],
            [-40.5876024863, -4.574794015],
            [-40.5878488585, -4.5575254258],
            [-40.5166232022, -4.5121183476],
            [-40.5091183304, -4.5073350457],
            [-40.5058094782, -4.5087484376],
            [-40.4667994241, -4.5254062925],
            [-40.458814613, -4.5288147953],
            [-40.3938310503, -4.5460921466],
            [-40.3874086066, -4.5477817684],
            [-40.3743901879, -4.5535305145],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2309409',
        name: 'Novo Oriente',
        description: 'Novo Oriente',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.5841777049, -5.4670258354],
            [-40.5969607997, -5.4907941339],
            [-40.6206744437, -5.5291378545],
            [-40.6160310027, -5.5482406905],
            [-40.6086012283, -5.5579106561],
            [-40.6136372766, -5.5649517294],
            [-40.6131645724, -5.57077488],
            [-40.6210950804, -5.5702828331],
            [-40.6214397937, -5.5761647543],
            [-40.62704382, -5.5786522829],
            [-40.6277678972, -5.5853414626],
            [-40.62003231, -5.5984349009],
            [-40.6266924201, -5.6085300781],
            [-40.6383969232, -5.6153423693],
            [-40.6385992938, -5.6195783371],
            [-40.6380956571, -5.6244317518],
            [-40.6282224422, -5.6312017587],
            [-40.6314640944, -5.6435508276],
            [-40.6345707666, -5.6468108685],
            [-40.6382047157, -5.6438390471],
            [-40.6475928295, -5.65739738],
            [-40.6470165773, -5.6653651163],
            [-40.6532735557, -5.6676966827],
            [-40.6459279125, -5.67696288],
            [-40.6407648231, -5.6842768099],
            [-40.6324367397, -5.6800109743],
            [-40.6175564794, -5.6839481857],
            [-40.6194769631, -5.6912836043],
            [-40.6245825668, -5.6952647447],
            [-40.6274174481, -5.6940193309],
            [-40.6335814899, -5.7006417317],
            [-40.6193042334, -5.7131912264],
            [-40.620553325, -5.7184141382],
            [-40.6129665243, -5.7270149719],
            [-40.6165641375, -5.7346345041],
            [-40.6238668587, -5.7327392097],
            [-40.6399007896, -5.7184020204],
            [-40.6364020916, -5.7120322914],
            [-40.6398086579, -5.7066916362],
            [-40.6544838275, -5.700874305],
            [-40.6594386643, -5.6943736757],
            [-40.6695809161, -5.6679902405],
            [-40.6906675985, -5.6561469637],
            [-40.6950444515, -5.6429925458],
            [-40.6962631216, -5.6446756739],
            [-40.7504931829, -5.7192902406],
            [-40.7507949237, -5.7190917448],
            [-40.7568379783, -5.7166575643],
            [-40.760362836, -5.7235465282],
            [-40.7672778267, -5.7251046996],
            [-40.7800812078, -5.7356224415],
            [-40.7921690432, -5.7338597072],
            [-40.8143580491, -5.7545212097],
            [-40.8287448599, -5.7568738264],
            [-40.8451692977, -5.7705104408],
            [-40.8655528886, -5.7657742587],
            [-40.8685836436, -5.7674730568],
            [-40.8815376484, -5.7703284201],
            [-40.9094227418, -5.7653247081],
            [-40.9173806345, -5.7444017357],
            [-40.9131691552, -5.7263550501],
            [-40.9247775137, -5.7087585647],
            [-40.93000516, -5.6858230195],
            [-40.9383969302, -5.6729497939],
            [-40.9287497873, -5.6627396859],
            [-40.9108607847, -5.6561681156],
            [-40.9073860598, -5.641955905],
            [-40.9024918809, -5.629268928],
            [-40.9009119164, -5.6114811218],
            [-40.9122187411, -5.5896410268],
            [-40.9141131793, -5.5670019565],
            [-40.9325842638, -5.5468902193],
            [-40.9313817015, -5.5360388245],
            [-40.9360823114, -5.5259983182],
            [-40.928506777, -5.5174586915],
            [-40.9283873532, -5.5065585105],
            [-40.9326834016, -5.5003282235],
            [-40.9289487104, -5.4926983233],
            [-40.9287847818, -5.4829442009],
            [-40.918140225, -5.4735173401],
            [-40.9081686922, -5.4734928979],
            [-40.8995925285, -5.4734715774],
            [-40.9000703233, -5.4780206663],
            [-40.8927096577, -5.4798831907],
            [-40.8903549817, -5.4848318208],
            [-40.8736364188, -5.4933090175],
            [-40.8652380069, -5.4932366081],
            [-40.8584727765, -5.4898710419],
            [-40.8522120632, -5.4810784488],
            [-40.8451454123, -5.4792129674],
            [-40.8266857165, -5.4834265278],
            [-40.81489153, -5.479967502],
            [-40.8119282711, -5.4884887],
            [-40.8069435762, -5.489551761],
            [-40.7980616667, -5.4919257511],
            [-40.7935510882, -5.4967198999],
            [-40.7845777634, -5.4933420304],
            [-40.7719924359, -5.4946547894],
            [-40.7718306048, -5.4859770797],
            [-40.7622379938, -5.4800725905],
            [-40.7316663967, -5.4858345579],
            [-40.7308427858, -5.4671999303],
            [-40.7242633763, -5.4618572808],
            [-40.715449287, -5.4674989146],
            [-40.7080644676, -5.4670013833],
            [-40.6948775554, -5.4624518875],
            [-40.6912164579, -5.4652698352],
            [-40.6911956783, -5.4711707416],
            [-40.6815648956, -5.4663922073],
            [-40.6841785855, -5.4496452838],
            [-40.6606655975, -5.4479476748],
            [-40.6566518626, -5.4411917118],
            [-40.6492925289, -5.4387284072],
            [-40.6504211528, -5.4346858664],
            [-40.6456968311, -5.4262832195],
            [-40.6421380631, -5.4262841588],
            [-40.5543385102, -5.4263034406],
            [-40.5525067147, -5.4318854758],
            [-40.5686890317, -5.4379910084],
            [-40.5771452987, -5.4629716737],
            [-40.5841777049, -5.4670258354],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2311009',
        name: 'Poranga',
        description: 'Poranga',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-41.2168696406, -4.5833568915],
            [-41.2076719394, -4.5853370934],
            [-41.1849410845, -4.5765975413],
            [-41.1874737348, -4.5880509996],
            [-41.1836982512, -4.5918681823],
            [-41.1650587148, -4.5844738716],
            [-41.158800175, -4.593687272],
            [-41.1464042503, -4.599271889],
            [-41.114231088, -4.6013811023],
            [-41.0991019006, -4.6023725264],
            [-41.0870258155, -4.6130724808],
            [-41.0691546736, -4.6147309115],
            [-41.0515460697, -4.6290640474],
            [-41.0348487051, -4.6349116635],
            [-41.0300474219, -4.6398444106],
            [-41.0211747698, -4.6389816944],
            [-41.0078553873, -4.6510084704],
            [-40.9881152544, -4.6598406515],
            [-40.9874905206, -4.6596503871],
            [-40.973438945, -4.6891213409],
            [-40.9685238536, -4.6918144773],
            [-40.9628969878, -4.6873171004],
            [-40.954540492, -4.7158950021],
            [-40.8881432437, -4.7166420869],
            [-40.8743071586, -4.701556221],
            [-40.8745355138, -4.6915431256],
            [-40.8710056297, -4.6858820332],
            [-40.8608699721, -4.6794525126],
            [-40.8570692523, -4.6729514269],
            [-40.8442362599, -4.6729521321],
            [-40.8546973615, -4.6773814015],
            [-40.8527291239, -4.6856107523],
            [-40.8628115586, -4.6906602252],
            [-40.8673662452, -4.698926707],
            [-40.8668315385, -4.7062643915],
            [-40.8723047148, -4.7133389915],
            [-40.8712335549, -4.7251819631],
            [-40.8658736474, -4.7317404557],
            [-40.8760662136, -4.7461634758],
            [-40.882257849, -4.7528302871],
            [-40.8787196432, -4.7703433621],
            [-40.8832313035, -4.7811537029],
            [-40.8823367504, -4.7874000566],
            [-40.8788615124, -4.78705221],
            [-40.8823041454, -4.7933117072],
            [-40.8801441126, -4.7996136248],
            [-40.885901015, -4.797102836],
            [-40.879310732, -4.8127382985],
            [-40.8775457102, -4.818081445],
            [-40.879720831, -4.8295563509],
            [-40.8760110689, -4.8320617473],
            [-40.879523278, -4.8392327106],
            [-40.8810375033, -4.8430803064],
            [-40.8751754115, -4.8531949969],
            [-40.880316263, -4.8594152859],
            [-40.8720772686, -4.8626733493],
            [-40.8790581456, -4.8678966605],
            [-40.8836147519, -4.8662821315],
            [-40.9092680384, -4.8745027772],
            [-40.9189133878, -4.8833563117],
            [-40.9266187242, -4.8858713734],
            [-40.941502835, -4.8824787084],
            [-40.9505009652, -4.8871208831],
            [-40.9918261974, -4.9151510187],
            [-40.9997381382, -4.9280891595],
            [-41.0061972178, -4.9306680922],
            [-41.0033948372, -4.933884382],
            [-41.0078176048, -4.935417573],
            [-41.0079280402, -4.9393146618],
            [-41.0218953883, -4.9545129413],
            [-41.0242394428, -4.9536847296],
            [-41.0358559025, -4.9651406136],
            [-41.0433627781, -4.9981110563],
            [-41.0522684105, -5.0173429206],
            [-41.0514930258, -5.0236693105],
            [-41.1208526023, -5.0235607779],
            [-41.1222260635, -5.0076827656],
            [-41.1350932133, -4.9974326009],
            [-41.1379041397, -4.9841016427],
            [-41.146096629, -4.9918129335],
            [-41.1505482501, -4.9917250114],
            [-41.1798422948, -4.9471597547],
            [-41.1886359347, -4.9402337182],
            [-41.1981783198, -4.9370698572],
            [-41.2034169076, -4.949364186],
            [-41.2092372521, -4.9455750742],
            [-41.2102136966, -4.9382308139],
            [-41.220992343, -4.9378447966],
            [-41.2225755567, -4.9261235956],
            [-41.2345536094, -4.9053595491],
            [-41.2277288549, -4.890603128],
            [-41.2491993369, -4.8693057191],
            [-41.2395406658, -4.8559959628],
            [-41.2395023817, -4.8484615307],
            [-41.2420895076, -4.824851384],
            [-41.232458956, -4.8153540744],
            [-41.232091335, -4.8041521482],
            [-41.2250559299, -4.7974354929],
            [-41.2235769929, -4.7902909391],
            [-41.2096017522, -4.7829629962],
            [-41.2076413183, -4.7763846445],
            [-41.2123917684, -4.7716412452],
            [-41.2415445745, -4.7646438914],
            [-41.2476348119, -4.7611672657],
            [-41.2491587079, -4.7556591271],
            [-41.234859552, -4.7461453095],
            [-41.2362758646, -4.7383681489],
            [-41.2337097732, -4.7333575579],
            [-41.2021184337, -4.7015117418],
            [-41.1813424737, -4.6734202416],
            [-41.1735322066, -4.6681614658],
            [-41.1746361864, -4.6649526435],
            [-41.1852260186, -4.6615844158],
            [-41.2024421564, -4.6378123548],
            [-41.2289565161, -4.6197124153],
            [-41.233061893, -4.6098066018],
            [-41.2226666222, -4.5894096553],
            [-41.2208410048, -4.578553964],
            [-41.2394837713, -4.573143929],
            [-41.2197816958, -4.5740700963],
            [-41.2168696406, -4.5833568915],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2312205',
        name: 'Santa Quitéria',
        description: 'Santa Quitéria',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.1395483526, -4.0242747764],
            [-40.1398623219, -4.0319167223],
            [-40.1319107005, -4.0315360627],
            [-40.1309549283, -4.03639063],
            [-40.1228402615, -4.0358532808],
            [-40.1184314822, -4.0415735667],
            [-40.1098358101, -4.0408812096],
            [-40.105614628, -4.0484895877],
            [-40.1027829617, -4.0520066249],
            [-40.0978762133, -4.0469247605],
            [-40.0888027341, -4.0490511338],
            [-40.0817483824, -4.0593933045],
            [-40.0680208177, -4.0572892221],
            [-40.0589507845, -4.0578107112],
            [-40.0534771554, -4.0619359136],
            [-40.0565334396, -4.0649053479],
            [-40.053410161, -4.0714082054],
            [-40.0428146903, -4.0778400189],
            [-40.0334166485, -4.07855688],
            [-40.0138297039, -4.0889137637],
            [-40.0004096724, -4.0832164664],
            [-39.9967178715, -4.0866656904],
            [-39.9892468215, -4.0862841109],
            [-39.986925032, -4.0924707444],
            [-39.9939207799, -4.0980195386],
            [-39.9912764902, -4.1035824686],
            [-39.9876741221, -4.1072452605],
            [-39.9682260565, -4.1058811595],
            [-39.9728139875, -4.1140934316],
            [-39.9625861224, -4.1223450205],
            [-39.9549187327, -4.1295370531],
            [-39.9474444362, -4.126806854],
            [-39.9450391177, -4.1297831587],
            [-39.9399765371, -4.1279891612],
            [-39.9247155486, -4.1314512994],
            [-39.9190179254, -4.1370163753],
            [-39.9029503006, -4.1370344048],
            [-39.8937209326, -4.1440113425],
            [-39.8889746313, -4.1389290865],
            [-39.8815051569, -4.1394851969],
            [-39.8790904375, -4.1357303979],
            [-39.868967614, -4.1352714909],
            [-39.8610965465, -4.1390065611],
            [-39.8563567444, -4.136460636],
            [-39.8490282511, -4.1197940959],
            [-39.8430825064, -4.1182344096],
            [-39.8402638263, -4.113148138],
            [-39.8109460862, -4.1162329392],
            [-39.8103496628, -4.1202258072],
            [-39.8067770748, -4.125160331],
            [-39.8083137002, -4.1351010384],
            [-39.8053828579, -4.1393479963],
            [-39.8044123756, -4.1407534992],
            [-39.7974887115, -4.1536657937],
            [-39.7937117354, -4.1548439501],
            [-39.7950018159, -4.1592257342],
            [-39.7876186923, -4.1669055082],
            [-39.7878650214, -4.1719936774],
            [-39.7805556903, -4.1747412955],
            [-39.7735507467, -4.1733229756],
            [-39.7757243093, -4.1809752106],
            [-39.7877170755, -4.1885701776],
            [-39.7901179121, -4.1942860939],
            [-39.7835607045, -4.1982326607],
            [-39.7802814233, -4.2107566722],
            [-39.7724765039, -4.2221960252],
            [-39.7770029823, -4.2234347839],
            [-39.7777722876, -4.2307510996],
            [-39.7723597101, -4.245187519],
            [-39.7504205746, -4.254559735],
            [-39.745726612, -4.2599391254],
            [-39.730147416, -4.275294387],
            [-39.7380864843, -4.2824594958],
            [-39.7329656408, -4.2876372725],
            [-39.7387236015, -4.2948290678],
            [-39.7322355646, -4.3077365815],
            [-39.7263982572, -4.3074390089],
            [-39.7252019976, -4.3182543718],
            [-39.7329494862, -4.3178449444],
            [-39.7347386568, -4.3322970475],
            [-39.7256727341, -4.3442270022],
            [-39.7102271253, -4.3480666207],
            [-39.7107804059, -4.3534968394],
            [-39.7144707001, -4.3551104487],
            [-39.7099732029, -4.3695113337],
            [-39.7115063625, -4.3816894551],
            [-39.7257693601, -4.3839890803],
            [-39.727389593, -4.3934213323],
            [-39.7376414646, -4.4015458142],
            [-39.7455803174, -4.3976911869],
            [-39.7550263028, -4.399382635],
            [-39.7525326276, -4.4067778343],
            [-39.7382240731, -4.4233131537],
            [-39.7399628836, -4.4308973348],
            [-39.732048983, -4.4353608878],
            [-39.7368987412, -4.4378599286],
            [-39.732607083, -4.4393935919],
            [-39.7295007113, -4.4497710601],
            [-39.7340542544, -4.4531236595],
            [-39.7338368211, -4.4640766413],
            [-39.7256757078, -4.4694588737],
            [-39.7265214395, -4.473133836],
            [-39.7211969489, -4.4840685811],
            [-39.7561406994, -4.5029611993],
            [-39.7546462416, -4.5115422357],
            [-39.7652541213, -4.5240811372],
            [-39.7640846959, -4.5344470914],
            [-39.7566453021, -4.5350865108],
            [-39.7457821324, -4.5446677086],
            [-39.729007943, -4.5402690058],
            [-39.7072397273, -4.5427412264],
            [-39.6998079676, -4.5458595257],
            [-39.6955646956, -4.5564552192],
            [-39.6887280067, -4.5560627149],
            [-39.6893701306, -4.5652330887],
            [-39.6862515332, -4.5670146648],
            [-39.685229849, -4.5756694295],
            [-39.719755833, -4.5994473612],
            [-39.7416580525, -4.5998608652],
            [-39.7436907119, -4.6061591645],
            [-39.7544788364, -4.6106653753],
            [-39.7593240359, -4.619825603],
            [-39.7642253516, -4.6196289317],
            [-39.7623395639, -4.6284784267],
            [-39.776452972, -4.6260451328],
            [-39.7786144102, -4.6304324078],
            [-39.7732714917, -4.6431141379],
            [-39.7656926478, -4.6476309813],
            [-39.7561507672, -4.6464946245],
            [-39.7482460548, -4.6497481064],
            [-39.7441238799, -4.6457428957],
            [-39.7386415429, -4.6524967682],
            [-39.7154425007, -4.6547467816],
            [-39.7315319721, -4.665675073],
            [-39.7261063929, -4.667145407],
            [-39.728922774, -4.6710250122],
            [-39.7193888, -4.6743421948],
            [-39.7142238025, -4.6778507245],
            [-39.719129117, -4.6807093387],
            [-39.7206371117, -4.6861175904],
            [-39.7286771568, -4.6859171877],
            [-39.7263280925, -4.6898666207],
            [-39.7289410847, -4.6964532673],
            [-39.7406256863, -4.7203000269],
            [-39.8056670464, -4.7077213729],
            [-39.7924750088, -4.7529707705],
            [-39.7983349349, -4.7431392801],
            [-39.8117646249, -4.7389419515],
            [-39.8176233407, -4.73124702],
            [-39.8236635292, -4.730518391],
            [-39.8335014939, -4.7328526485],
            [-39.8348138909, -4.7359058056],
            [-39.839566543, -4.7346196406],
            [-39.8419495935, -4.7391686838],
            [-39.8597245152, -4.7372677246],
            [-39.8638832366, -4.7519564944],
            [-39.8739050038, -4.7618496219],
            [-39.8728970014, -4.7658332427],
            [-39.871238282, -4.7727401105],
            [-39.8783714434, -4.7767318995],
            [-39.8781612957, -4.7830367031],
            [-39.8843638749, -4.7895323062],
            [-39.9142770316, -4.7885049363],
            [-39.9161993667, -4.7821034129],
            [-39.9282201008, -4.7741557181],
            [-39.9245443581, -4.7348789161],
            [-39.9556412024, -4.7308657504],
            [-39.9949769693, -4.7257850198],
            [-40.0590854725, -4.7174973622],
            [-40.0667564288, -4.7080931347],
            [-40.0608615061, -4.700311001],
            [-40.0766550867, -4.7000800357],
            [-40.0781872972, -4.6966210535],
            [-40.0833813553, -4.6968175206],
            [-40.0808832081, -4.6922278229],
            [-40.0877079638, -4.6841153772],
            [-40.0801323593, -4.6838705248],
            [-40.0803863186, -4.6792762017],
            [-40.0917048541, -4.664085984],
            [-40.1038884678, -4.6608672694],
            [-40.1066719971, -4.6494262943],
            [-40.107043301, -4.644554286],
            [-40.1032784129, -4.6425483449],
            [-40.1076241778, -4.6422317152],
            [-40.105656718, -4.638054799],
            [-40.1075488814, -4.6273161263],
            [-40.1109350008, -4.6256602792],
            [-40.1120712233, -4.6119133151],
            [-40.1214763879, -4.6105393149],
            [-40.1115024624, -4.594998023],
            [-40.0893129913, -4.5880515631],
            [-40.0733430576, -4.5873572399],
            [-40.0573677647, -4.5680295552],
            [-40.0434469744, -4.5756027402],
            [-40.0313677184, -4.5670460902],
            [-40.0236161037, -4.5446318352],
            [-40.0074761273, -4.5224234707],
            [-40.0015300653, -4.5047811007],
            [-40.0055901129, -4.4977061992],
            [-40.0033139145, -4.4884692578],
            [-40.0367230387, -4.4568354935],
            [-40.0453690543, -4.4557995562],
            [-40.0461122574, -4.453141158],
            [-40.0497558658, -4.4562748725],
            [-40.0577830958, -4.4545166449],
            [-40.0682810663, -4.4709611078],
            [-40.0898225793, -4.4824648432],
            [-40.0980298492, -4.4845258207],
            [-40.1025814995, -4.492955937],
            [-40.0957834881, -4.4927728585],
            [-40.0915591972, -4.5011270989],
            [-40.1009113557, -4.5083410351],
            [-40.1116151202, -4.5107778792],
            [-40.1201221971, -4.5040119911],
            [-40.1354442886, -4.5117477174],
            [-40.1523083351, -4.5056374819],
            [-40.1694285438, -4.5075423876],
            [-40.1828420751, -4.5075716086],
            [-40.1837785438, -4.5029307239],
            [-40.1935371506, -4.4949973748],
            [-40.1996982726, -4.4729768932],
            [-40.2082231165, -4.4654339965],
            [-40.2541106529, -4.4726006722],
            [-40.2603618022, -4.4621251089],
            [-40.258388024, -4.4536623774],
            [-40.2615553445, -4.4434678587],
            [-40.2731132267, -4.434586189],
            [-40.2807782639, -4.4150894875],
            [-40.291921184, -4.4071987174],
            [-40.3000836901, -4.3959660108],
            [-40.3047013233, -4.3876528173],
            [-40.3057527711, -4.3756063589],
            [-40.3130810747, -4.3652276366],
            [-40.3119033115, -4.358687271],
            [-40.3201464255, -4.3544126982],
            [-40.3198678649, -4.3460897894],
            [-40.326032708, -4.3419670025],
            [-40.3218097125, -4.3117554048],
            [-40.314255509, -4.3015799971],
            [-40.3161687468, -4.2820900652],
            [-40.3023959814, -4.2668941705],
            [-40.3031714947, -4.2668548764],
            [-40.3278262631, -4.2656161166],
            [-40.3984854129, -4.2615438892],
            [-40.4481061039, -4.258286739],
            [-40.43924064, -4.2506239125],
            [-40.441755021, -4.2343486186],
            [-40.4503483027, -4.2171979615],
            [-40.4497911908, -4.2081228687],
            [-40.4497870728, -4.2080497741],
            [-40.4497577506, -4.2075662582],
            [-40.4422028924, -4.1834270095],
            [-40.4457583049, -4.1634803329],
            [-40.4325323599, -4.1579692928],
            [-40.4300870846, -4.1467652483],
            [-40.4363063626, -4.1381851309],
            [-40.4328679606, -4.1236206944],
            [-40.4363919954, -4.1145209974],
            [-40.4344502412, -4.0975394807],
            [-40.4321415496, -4.0932424916],
            [-40.4313904197, -4.0912946622],
            [-40.4312571056, -4.0912951012],
            [-40.3847825596, -4.0911936294],
            [-40.3745768842, -4.0911706],
            [-40.3607966706, -4.0319532091],
            [-40.360757204, -4.0317845825],
            [-40.3383819053, -3.9696555586],
            [-40.3051610132, -3.9336827585],
            [-40.226683403, -3.9496276481],
            [-40.2152549822, -3.951947894],
            [-40.1885554853, -3.9573677385],
            [-40.1898961758, -3.9634580926],
            [-40.1779117161, -3.9777725747],
            [-40.1694966196, -3.9847568778],
            [-40.1621557091, -4.0014308974],
            [-40.1414607754, -4.0220068929],
            [-40.1395483526, -4.0242747764],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2313203',
        name: 'Tamboril',
        description: 'Tamboril',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.204231938, -4.7900483118],
            [-40.1958390722, -4.7914430392],
            [-40.1789217003, -4.7879731637],
            [-40.1581871773, -4.8042217816],
            [-40.1551937207, -4.8093478761],
            [-40.1452907739, -4.8077347306],
            [-40.1420245234, -4.813705735],
            [-40.138276332, -4.823003746],
            [-40.111171237, -4.8499985364],
            [-40.106474481, -4.8710542983],
            [-40.1129226459, -4.881824664],
            [-40.1365267906, -4.8888905276],
            [-40.139562993, -4.8984776577],
            [-40.1440584962, -4.902163364],
            [-40.1590349848, -4.9022196742],
            [-40.1683087185, -4.8981409391],
            [-40.1970293416, -4.9059402099],
            [-40.1949755071, -4.908330249],
            [-40.1653522047, -4.9376358795],
            [-40.1650861102, -4.9644999474],
            [-40.1571662714, -4.9692321252],
            [-40.1360315568, -4.9733001553],
            [-40.1333080551, -4.9771374725],
            [-40.1364483501, -4.9800560406],
            [-40.1307452049, -4.9870810613],
            [-40.1333844784, -4.9919131198],
            [-40.1192006285, -4.9851957373],
            [-40.1154235883, -4.9878853262],
            [-40.1183594303, -4.9954104006],
            [-40.1142539502, -5.0001141105],
            [-40.1047773974, -4.9970105656],
            [-40.0996977861, -5.0012905248],
            [-40.1078683177, -5.0236907],
            [-40.1218306835, -5.0297888084],
            [-40.136714408, -5.0291844529],
            [-40.1499394077, -5.0335170843],
            [-40.1542949419, -5.043468822],
            [-40.1474117797, -5.0502313372],
            [-40.1474537439, -5.0505846227],
            [-40.1485395806, -5.0597954699],
            [-40.1421416033, -5.07379224],
            [-40.1510973957, -5.0757629331],
            [-40.1516140371, -5.075897618],
            [-40.1537010055, -5.0767426301],
            [-40.1550601628, -5.0811064251],
            [-40.1501254039, -5.0826671677],
            [-40.149592309, -5.0910271178],
            [-40.1352269667, -5.0990823621],
            [-40.1398089007, -5.1048810298],
            [-40.1346433849, -5.1113144805],
            [-40.1369452736, -5.1218901169],
            [-40.1344240843, -5.130784154],
            [-40.1457828305, -5.1461748849],
            [-40.1450867833, -5.152247056],
            [-40.162214329, -5.1550534812],
            [-40.1656711681, -5.1590272115],
            [-40.1650056429, -5.1647322491],
            [-40.1652492692, -5.1650160263],
            [-40.1702215739, -5.1744573992],
            [-40.1806126447, -5.170019174],
            [-40.1882548386, -5.1757380376],
            [-40.1866693432, -5.1678347849],
            [-40.1983111916, -5.1648356443],
            [-40.2114418013, -5.1557516534],
            [-40.2187567277, -5.1519594746],
            [-40.2280344553, -5.1522426321],
            [-40.2389919495, -5.1624728751],
            [-40.2416665541, -5.1706520529],
            [-40.248404695, -5.167869001],
            [-40.2577928948, -5.1712645966],
            [-40.2571999385, -5.1659775031],
            [-40.2695833099, -5.1622610631],
            [-40.2980273003, -5.1700122128],
            [-40.3054180925, -5.1667447573],
            [-40.3109613, -5.1563658766],
            [-40.3312935271, -5.1691562519],
            [-40.3548781102, -5.1861618498],
            [-40.3812657896, -5.2051931191],
            [-40.406863071, -5.2236564651],
            [-40.4141967763, -5.2289463476],
            [-40.3915980425, -5.1666523805],
            [-40.3906753546, -5.164110105],
            [-40.3729243246, -5.1152102884],
            [-40.3728808779, -5.115091517],
            [-40.3769476958, -5.1105565049],
            [-40.3741412445, -5.1026353245],
            [-40.3996141782, -5.0923439655],
            [-40.4037367764, -5.0831789555],
            [-40.421243634, -5.0710224523],
            [-40.4220441874, -5.0642149678],
            [-40.4317697224, -5.0507291029],
            [-40.4461765215, -5.0342766832],
            [-40.4490495396, -5.0350766266],
            [-40.4512576869, -5.043960078],
            [-40.4625601681, -5.0456350265],
            [-40.4952176107, -5.0354091438],
            [-40.5056733469, -5.023702686],
            [-40.5207433751, -5.0215739885],
            [-40.5380344351, -5.0130628247],
            [-40.5562534606, -5.0140553435],
            [-40.5666104143, -5.0185558848],
            [-40.594371473, -4.9502484507],
            [-40.6354497739, -4.9243361364],
            [-40.5967579912, -4.9203140548],
            [-40.5420839082, -4.9148046882],
            [-40.5366749266, -4.8997870021],
            [-40.5399776086, -4.8924588483],
            [-40.5395989336, -4.8767406801],
            [-40.5458101956, -4.8679186812],
            [-40.5398369657, -4.843412951],
            [-40.522440011, -4.8477379611],
            [-40.4977374686, -4.8108460595],
            [-40.4923193342, -4.8024489859],
            [-40.4826387759, -4.7874432991],
            [-40.4572596071, -4.7481126676],
            [-40.4466596341, -4.7316890192],
            [-40.4402719333, -4.7128408607],
            [-40.4378439843, -4.7056778474],
            [-40.4228705312, -4.6615170092],
            [-40.4227333117, -4.6611123752],
            [-40.3743901879, -4.5535305145],
            [-40.3464691642, -4.6318928466],
            [-40.3417651533, -4.6450855485],
            [-40.3366118571, -4.6545368031],
            [-40.3367064811, -4.6643428755],
            [-40.3312013443, -4.6666756396],
            [-40.3222482902, -4.6836361916],
            [-40.3142217888, -4.6892188811],
            [-40.3148649756, -4.7068444978],
            [-40.3051130989, -4.7164874578],
            [-40.3040404576, -4.7225256626],
            [-40.3103193264, -4.7231702948],
            [-40.314432593, -4.7251302553],
            [-40.3131542937, -4.7279672528],
            [-40.2844727229, -4.7496943797],
            [-40.268686972, -4.77308181],
            [-40.2448413181, -4.7772217867],
            [-40.2212561616, -4.788687915],
            [-40.204231938, -4.7900483118],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2305209',
        name: 'Hidrolândia',
        description: 'Hidrolândia',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.2475559313, -4.4799122778],
            [-40.2455661194, -4.4877580932],
            [-40.2490591237, -4.4876691273],
            [-40.2503026494, -4.4916919964],
            [-40.2562168653, -4.4866608393],
            [-40.2556720616, -4.4916005314],
            [-40.2679684114, -4.5069340562],
            [-40.2565313468, -4.5192686286],
            [-40.246942353, -4.5199999029],
            [-40.2438808216, -4.523326256],
            [-40.2400897997, -4.5496418007],
            [-40.2425616081, -4.5498046539],
            [-40.2429593471, -4.5575641352],
            [-40.2406180949, -4.5596849349],
            [-40.2330718146, -4.5572490698],
            [-40.244092707, -4.5784249315],
            [-40.2390344488, -4.579441549],
            [-40.2428411239, -4.5880253052],
            [-40.2363713145, -4.5926837445],
            [-40.247043181, -4.6027909427],
            [-40.2509171259, -4.6014148756],
            [-40.2526315275, -4.6055615436],
            [-40.2644815269, -4.6060382516],
            [-40.2714776112, -4.611172422],
            [-40.2773925749, -4.6069699537],
            [-40.2823834003, -4.6084959295],
            [-40.2942418728, -4.6006392981],
            [-40.2970692595, -4.60379358],
            [-40.2978855876, -4.6062129355],
            [-40.3034554993, -4.6047617281],
            [-40.3029346373, -4.6093307002],
            [-40.311843731, -4.6176942747],
            [-40.3166020455, -4.617514462],
            [-40.318904265, -4.6220786334],
            [-40.3170952263, -4.6357526458],
            [-40.3314947222, -4.639505412],
            [-40.3309807674, -4.6473711542],
            [-40.3366118571, -4.6545368031],
            [-40.3417651533, -4.6450855485],
            [-40.3464691642, -4.6318928466],
            [-40.3743901879, -4.5535305145],
            [-40.3874086066, -4.5477817684],
            [-40.3938310503, -4.5460921466],
            [-40.458814613, -4.5288147953],
            [-40.4667994241, -4.5254062925],
            [-40.5058094782, -4.5087484376],
            [-40.5091183304, -4.5073350457],
            [-40.5166232022, -4.5121183476],
            [-40.5878488585, -4.5575254258],
            [-40.5883015111, -4.5578012728],
            [-40.5983540809, -4.5295487796],
            [-40.5905057452, -4.5171906177],
            [-40.5903958737, -4.5099531267],
            [-40.5828621136, -4.5041308257],
            [-40.5839769242, -4.4912172538],
            [-40.5794706028, -4.4878890983],
            [-40.5848264124, -4.4755144066],
            [-40.5653679554, -4.4557609247],
            [-40.5538997261, -4.4380926054],
            [-40.5536975071, -4.4341985177],
            [-40.5609512754, -4.42936961],
            [-40.5649776458, -4.4191517065],
            [-40.5607333571, -4.4019704765],
            [-40.5605490479, -4.4010597239],
            [-40.5590460728, -4.3995765314],
            [-40.552338479, -4.398285281],
            [-40.5543070808, -4.3876791406],
            [-40.548753766, -4.3773311352],
            [-40.5231728552, -4.355641112],
            [-40.5217600494, -4.338508527],
            [-40.5141688747, -4.3268284509],
            [-40.499892315, -4.3256373181],
            [-40.4952856075, -4.3217830693],
            [-40.4882228005, -4.2991845263],
            [-40.4784408479, -4.2935160205],
            [-40.4782535966, -4.293407198],
            [-40.4757512127, -4.2891571333],
            [-40.4626891587, -4.2680006116],
            [-40.4481061039, -4.258286739],
            [-40.3984854129, -4.2615438892],
            [-40.3278262631, -4.2656161166],
            [-40.3031714947, -4.2668548764],
            [-40.3023959814, -4.2668941705],
            [-40.3161687468, -4.2820900652],
            [-40.314255509, -4.3015799971],
            [-40.3218097125, -4.3117554048],
            [-40.326032708, -4.3419670025],
            [-40.3198678649, -4.3460897894],
            [-40.3201464255, -4.3544126982],
            [-40.3119033115, -4.358687271],
            [-40.3130810747, -4.3652276366],
            [-40.3057527711, -4.3756063589],
            [-40.3047013233, -4.3876528173],
            [-40.3000836901, -4.3959660108],
            [-40.291921184, -4.4071987174],
            [-40.2807782639, -4.4150894875],
            [-40.2731132267, -4.434586189],
            [-40.2615553445, -4.4434678587],
            [-40.258388024, -4.4536623774],
            [-40.2603618022, -4.4621251089],
            [-40.2541106529, -4.4726006722],
            [-40.2475559313, -4.4799122778],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        data: [],
        id: '2304103',
        name: 'Crateús',
        description: 'Crateús',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-40.594371473, -4.9502484507],
            [-40.5666104143, -5.0185558848],
            [-40.5562534606, -5.0140553435],
            [-40.5380344351, -5.0130628247],
            [-40.5207433751, -5.0215739885],
            [-40.5056733469, -5.023702686],
            [-40.4952176107, -5.0354091438],
            [-40.4625601681, -5.0456350265],
            [-40.4512576869, -5.043960078],
            [-40.4490495396, -5.0350766266],
            [-40.4461765215, -5.0342766832],
            [-40.4317697224, -5.0507291029],
            [-40.4220441874, -5.0642149678],
            [-40.421243634, -5.0710224523],
            [-40.4037367764, -5.0831789555],
            [-40.3996141782, -5.0923439655],
            [-40.3741412445, -5.1026353245],
            [-40.3769476958, -5.1105565049],
            [-40.3728808779, -5.115091517],
            [-40.3729243246, -5.1152102884],
            [-40.3906753546, -5.164110105],
            [-40.3915980425, -5.1666523805],
            [-40.4141967763, -5.2289463476],
            [-40.479092614, -5.2738941182],
            [-40.4810756953, -5.2792233993],
            [-40.4868957714, -5.2948684037],
            [-40.5234785107, -5.3893259423],
            [-40.539185464, -5.389086791],
            [-40.5484316361, -5.3984170698],
            [-40.5543385102, -5.4263034406],
            [-40.6421380631, -5.4262841588],
            [-40.6456968311, -5.4262832195],
            [-40.6504211528, -5.4346858664],
            [-40.6492925289, -5.4387284072],
            [-40.6566518626, -5.4411917118],
            [-40.6606655975, -5.4479476748],
            [-40.6841785855, -5.4496452838],
            [-40.6815648956, -5.4663922073],
            [-40.6911956783, -5.4711707416],
            [-40.6912164579, -5.4652698352],
            [-40.6948775554, -5.4624518875],
            [-40.7080644676, -5.4670013833],
            [-40.715449287, -5.4674989146],
            [-40.7242633763, -5.4618572808],
            [-40.7308427858, -5.4671999303],
            [-40.7316663967, -5.4858345579],
            [-40.7622379938, -5.4800725905],
            [-40.7718306048, -5.4859770797],
            [-40.7719924359, -5.4946547894],
            [-40.7845777634, -5.4933420304],
            [-40.7935510882, -5.4967198999],
            [-40.7980616667, -5.4919257511],
            [-40.8069435762, -5.489551761],
            [-40.8119282711, -5.4884887],
            [-40.81489153, -5.479967502],
            [-40.8266857165, -5.4834265278],
            [-40.8451454123, -5.4792129674],
            [-40.8522120632, -5.4810784488],
            [-40.8584727765, -5.4898710419],
            [-40.8652380069, -5.4932366081],
            [-40.8736364188, -5.4933090175],
            [-40.8903549817, -5.4848318208],
            [-40.8927096577, -5.4798831907],
            [-40.9000703233, -5.4780206663],
            [-40.8995925285, -5.4734715774],
            [-40.9081686922, -5.4734928979],
            [-40.918140225, -5.4735173401],
            [-40.9154415656, -5.4657920757],
            [-40.920829707, -5.450456835],
            [-40.9366675938, -5.4381521494],
            [-40.9413923125, -5.4305815351],
            [-40.942089085, -5.419317281],
            [-40.9378193873, -5.4159645517],
            [-40.9173271743, -5.415901932],
            [-40.9111774179, -5.4069878515],
            [-40.9145276358, -5.3927551004],
            [-40.9286165553, -5.3754289991],
            [-40.93573106, -5.3655790584],
            [-40.9333501583, -5.3490159876],
            [-40.918080937, -5.3378669303],
            [-40.9131412159, -5.3190907432],
            [-40.9123615347, -5.3085869866],
            [-40.9128334315, -5.3076631837],
            [-40.9230873708, -5.3007314327],
            [-40.9263853939, -5.2916591437],
            [-40.9215723248, -5.2839698949],
            [-40.9238713348, -5.2744494318],
            [-40.9192702641, -5.2707733391],
            [-40.9114866463, -5.2705370922],
            [-40.9108353083, -5.2675653393],
            [-40.9326935034, -5.24483928],
            [-40.9407312245, -5.2415770042],
            [-40.9400259192, -5.2373793628],
            [-40.9336299426, -5.2363941275],
            [-40.9262504179, -5.231698601],
            [-40.9216560877, -5.2260637048],
            [-40.9316268468, -5.2008764568],
            [-40.9249183121, -5.1817096759],
            [-40.9286157374, -5.1712845888],
            [-40.9373741958, -5.166201789],
            [-40.9489480976, -5.1664894638],
            [-40.9581215863, -5.1541324119],
            [-40.9680472037, -5.1489734956],
            [-40.9820899764, -5.15313592],
            [-40.9882311686, -5.1383599756],
            [-41.0114632077, -5.1232145722],
            [-41.0137025024, -5.1054189625],
            [-41.0260324182, -5.1093104155],
            [-41.0336282131, -5.090677887],
            [-41.0398935012, -5.0909975558],
            [-41.0520407819, -5.1030165149],
            [-41.0797518817, -5.0907062025],
            [-41.080059187, -5.078304952],
            [-41.0869744516, -5.072929457],
            [-41.0877947836, -5.065227079],
            [-41.0954908399, -5.0612308266],
            [-41.0966318696, -5.0527625251],
            [-41.1315022114, -5.0476701052],
            [-41.1208526023, -5.0235607779],
            [-41.0514930258, -5.0236693105],
            [-41.0310445623, -5.0196257114],
            [-41.0307615761, -5.0195699621],
            [-40.8752328114, -4.9888169294],
            [-40.8649024481, -4.9867746161],
            [-40.8264412231, -4.9787872086],
            [-40.7150651975, -4.9477488653],
            [-40.7148185973, -4.9476797483],
            [-40.6518992559, -4.9854015222],
            [-40.6354497739, -4.9243361364],
            [-40.594371473, -4.9502484507],
          ],
        ],
      },
    },
  ],
};

export { geojsonRegion };
