<template>
  <div class="footer-page">
    <div class="container column-footer">
      <div class="column-left">
        <p class="title-column">
          Observatório de Dados Abertos<br />
          do Sertão dos <span>Ceará</span>
        </p>
        <div>
          <router-link class="nav-footer" to="/home"
            >Página Inicial
          </router-link>
          <router-link class="nav-footer" to="/covid-19">Covid-19 </router-link>
          <router-link class="nav-footer" to="/doencas-cidBr"
            >Doenças CID BR</router-link
          >
        </div>
        <p id="copyrigth">Copyright 2021. All rights reserved</p>
      </div>
      <div class="column-mid">
        <p class="title-column">QUEM SOMOS?</p>
        <p class="text-column">
          Grupo de pesquisa formado por professores e estudantes na Universidade
          Federal do Ceará.
        </p>
      </div>
      <div class="column-rigth">
        <p class="title-column">APOIO</p>
        <div class="logo-support">
          <a
            target="_blank"
            href="https://site.crateus.ufc.br/"
            class="ufc-support"
          >
            <img class="logo-footer" src="../../public/ufc-logo.png" alt="" />
            <p>
              UFC CRATEÚS
            </p>
          </a>
          <a
            target="_blank"
            href="https://github.com/wcota/covid19br"
            class="wcota-support"
          >
            <img class="logo-footer" src="../../public/github.png" alt="" />
            <p>
              WCOTA
            </p>
          </a>
        </div>
      </div>
      <p id="copyrigthMobile">Copyright 2021. All rights reserved</p>
    </div>
  </div>
</template>

<script>
export default { name: 'BarFooter' };
</script>

<style scoped>
@media (max-width: 1024px) {
  .container {
    width: auto !important;
  }
  .column-left {
    padding: 50px 0px 0px 10px !important;
  }
  .column-mid,
  .column-rigth {
    padding: 50px 20px 0px 10px !important;
  }
  #copyrigth {
    margin-top: 25px !important;
  }
}
@media (min-width: 550px) {
  .container {
    max-width: 100vw !important;
    max-height: 100vh !important;
  }
  .column-left {
    padding: 50px 0px 0px 10px !important;
  }
  .column-mid,
  .column-rigth {
    padding: 50px 20px 0px 10px !important;
  }
  #copyrigth {
    margin-top: 25px !important;
  }

  .footer-page {
    height: 380px !important;
  }
}
@media (min-width: 700px) {
  .footer-page {
    height: 360px !important;
    padding: 20px 0 0 70px !important;
  }
  .container {
    /* width: auto !important; */
  }
}

@media (max-width: 550px) {
  .container {
    /* max-width: 100vw !important; */
    margin: 0px;
  }

  #copyrigthMobile {
    display: inline !important;
    margin-top: 30px !important;
  }
  .column-footer {
    display: flex;
    flex-direction: column !important;
  }
  .text-column {
    display: none;
  }
  .column-rigth,
  .column-mid,
  .column-left {
    width: 100% !important;
    padding: 50px 0px 0px 10px !important;
  }
  .column-mid,
  .column-rigth {
    padding: 10px 0px 0px 10px !important;
  }
  .column-left > div {
    display: none !important;
  }
  #copyrigth {
    display: none;
    /* margin-top: 30px !important; */
  }
  .footer-page {
    height: 430px !important;
    display: inline-grid !important;
  }

  .logo-support {
    margin-top: 10px !important;
  }
}
.footer-page {
  height: 325px;
  background-color: #121212;
  display: flex;
  width: 100%;

  /* z-index: 23; */
}
.container {
  display: flex;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1410px !important;
    padding-left: 0px;
    padding-right: 0px;
    /* margin-top: -40px; */
    overflow: hidden;
  }
}

/* @media (min-width: 1600px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1610px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: -40px;
    overflow: hidden;
  }
} */
/* .footer-page > div {
  width: 33%;
} */
a {
  color: white;
}
/* coluna esquerda ----------------------------------------------------------------- */
.column-left {
  width: 34%;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 50px 0px 0px 80px;
}

.column-left > div {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.column-left > div > a {
  color: #d4d4d4;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  margin-top: 10px;
}
.title-column > span {
  color: #02bdf8;
}
#copyrigth,
#copyrigthMobile {
  margin-top: 50px;
  font-family: 'Roboto', sans-serif;
  color: #eeeeee;
}
#copyrigthMobile {
  display: none;
}
/* title das colunas geral ----------------------------------------------------------------- */
.title-column {
  color: #f0f0f0;
  font-size: 18.5px;
}
.text-column {
  font-family: 'Roboto', sans-serif;
  margin-top: 20px;
  font-size: 18px;
  color: #d9d9d9;
}

/* coluna do meio ----------------------------------------------------------------- */
.column-mid {
  /* background-color: teal; */
  display: flex;
  width: 33%;
  flex-direction: column;
  text-align: left;
  padding: 50px 80px 0px 80px;
}
/* coluna direita ----------------------------------------------------------------- */
.column-rigth {
  /* background-color: rgb(138, 255, 71); */
  display: flex;
  width: 33%;
  flex-direction: column;
  text-align: left;
  padding: 50px 80px 0px 80px;
}
.column-rigth {
  text-align: center;
}
.logo-support {
  color: #f0f0f0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 30px;
}

.ufc-support,
.wcota-support {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: 'Righteous', cursive;
}
.ufc-support:hover,
.wcota-support:hover {
  color: #17d6eb;
}
.ufc-support > p,
.wcota-support > p {
  margin-top: 10px;
}

.logo-footer {
  width: 70px;
}

.nav-footer:hover {
  color: #17d6eb;
}
</style>
