<template>
  <div class="container">
    <div class="boxContentChart">
      <div class="chart-container">
        <canvas id="chart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';
import planetChartData from '../data/chart-data';

export default {
  name: 'BarChart',
  props: {
    dataChart: {
      type: Array,
      default: [],
    },
  },
  data() {
    return { dataChartRegion: [] };
  },
  methods: {
    createChart(chartId, nameCities, totalCases) {
      const ctx = document.getElementById(chartId);

      const myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: nameCities,
          datasets: [
            {
              label: 'Total   de Casos',
              data: totalCases,
              backgroundColor: 'rgba(71, 183,132,.5)',
              borderColor: '#47b784',
              borderWidth: 3,
            },
          ],
        },
        options: {
          // maintainAspectRatio: false,
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        },
      });
    },
  },
  async mounted() {
    // console.log('mounted');
    const nameCities = [];
    const totalCases = [];
    if (this.dataChart === undefined) {
      // console.log('ta null');
    }
    this.dataChartRegion.forEach(function(index) {
      nameCities.push(index.city);
    });

    this.dataChartRegion.forEach(function(index) {
      totalCases.push(index.totalCases);
    });
    // this.nameCities = copyItems;

    this.createChart('chart', nameCities, totalCases);
  },
  created() {
    this.dataChartRegion = this.dataChart;
    // console.log('created: ' + this.dataChartRegion);
  },
  beforeMount() {
    // console.log('update: ');
  },
  watch: {
    updateDataChart(newData) {
      // console.log('newdata: ' + newData);
      this.$emit('update:dataChart', newData);
    },
  },
};
</script>

<style scoped>
canvas {
  border: 1px dotted red;
}

.chart-container {
  /* position: relative; */
  /* margin: auto; */
}
.boxContentChart {
  position: relative;
  margin: auto;

  height: 80vh;
  width: 80vw;
}
</style>
