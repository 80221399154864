<template>
  <div>
    <BarHeader :activatePageBar="'home'" style="z-index: 10" />
    <div class="content container">
      <div class="info">
        <div class="info-obs">
          <div class="info-center">
            <h1 class="text-custom-color-title">
              OBSERVATÓRIO DE <br />
              DADOS ABERTOS DO <br />
              SERTÃO DOS <br />
              <span class="city">CRATEÚS</span>
            </h1>
            <p class="text-custom-color">
              Confira diariamente a situação de risco de seu <br />
              município e região.
            </p>
            <router-link to="/covid-19">
              <button class="acess-map">Acessar Dados</button>
            </router-link>
          </div>
          <div class="painel-info">
            <h3 class="text-custom-color-title">
              PAINEL CORONAVÍRUS SERTÃO DOS CARTEÚS
            </h3>
            <p id="updateDate">Atualização: {{ this.updateDateCovid }}</p>
            <p>
              Fonte: <span id="source"> {{ this.sourceCovid }}</span>
            </p>
          </div>
        </div>
        <div class="map">
          <div id="cicle" class="cicle">
            <img src="../../public/map.png" alt="" />
          </div>
        </div>
      </div>
      <div class="cards-covid">
        <CardHome :styleCard="cardConfirmados" />
        <CardHome :styleCard="cardObitos" />
        <!-- <CardHome :styleCard="cardObtosTotalCasos" />
        <CardHome :styleCard="cardRecuperados" /> -->
      </div>
      <div class="acess-info">
        <p class="text-custom-color">
          Os dados sobre o covid-19 no estado Ceará por cidades. Podem ser visto
          diretamento no mapa, graficos e tabelas.
        </p>
        <div class="button-acess">
          <router-link to="/covid-19">
            <button>Acessar Dados</button>
          </router-link>
        </div>
      </div>
      <div class="painel-doencas">
        <div class="info-doencas">
          <h2 class="text-custom-color-title">
            PAINEL DE DOENÇAS COMUNS <br />
            NA REGIÃO
          </h2>
          <div class="painel-info-doencas">
            <p id="updateDate">
              Atualização: {{ this.updateDateStateDiseases }}
            </p>
            <p>
              Fonte: <span id="source"> {{ this.sourceStateDiseases }}</span>
            </p>
            <p class="text-custom-color" id="dados-covid">
              Os dados sobre o covid-19 no estado Ceará por cidades. <br />
              Podem ser vistos diretamente no mapa, gráficos e tabelas.
            </p>
          </div>
          <div class="buttons-doencas">
            <router-link to="/doencas-cidBr">
              <button>Acessar Dados</button>
            </router-link>
          </div>
        </div>
        <div class="img-doctor">
          <img src="../../public/pexels-edward-jenner-4033151.jpg" alt="" />
          <div class="square-gray div-custom-color"></div>
        </div>
      </div>
    </div>

    <div class="boletins-info div-custom-color">
      <div class="container">
        <p class="text-custom-color">
          Lista dos boletins epidemiológicos gerados e o atual em destaque podem
          ser vistos diretamente na página de boletins.
          <router-link to="/boletins"> Ver pagina </router-link>
        </p>
      </div>
    </div>
    <BarFooter />
  </div>
</template>

<script>
import BarHeader from '@/components/BarHeader.vue';
import CardHome from '@/components/CardHome.vue';
import BarFooter from '@/components/BarFooter.vue';

import config from '../config';

export default {
  name: 'Home',
  // props: [],
  components: {
    BarHeader,
    CardHome,
    BarFooter,
  },
  data() {
    return {
      cardConfirmados: {
        color: '#089C17',
        title: 'Confirmados',
        totalCasos: null,
        novosCasos: null,
        height: '25%',
        width: '50%',
        marginRight: '17px',
        cardStyleType: 'home',
      },
      cardObitos: {
        color: '#F91010',
        title: 'Óbitos',
        totalCasos: null,
        novosCasos: null,
        height: '25%',
        width: '50%',
        marginRight: '17px',
        cardStyleType: 'home',
      },
      cardObtosTotalCasos: {},
      cardRecuperados: {
        color: '#0FB5DA',
        title: 'Recuperados',
        totalCasos: '120.000',
        novosCasos: '500',
        height: '25%',
        width: '25%',
        marginRight: '17px',
        cardStyleType: 'home',
      },
      dataCovid: {},
      dataStateDiseases: {},
      updateDateCovid: '',
      sourceCovid: '',
      updateDateStateDiseases: '',
      sourceStateDiseases: '',
      dataCovidRegionSertaoCrateus: [],
      ibgeIdListCitiesRegion: [
        '2301257',
        '2303659',
        '2304103',
        '2305209',
        '2305605',
        '2305654',
        '2305902',
        '2308609',
        '2309300',
        '2309409',
        '2311009',
        '2312205',
        '2313203',
      ],
      baseURICovid: config.BACKEND_URL + '/covidRegionCrateus',
      baseURIStateDiseases: config.BACKEND_URL + '/cidBrRegion',
    };
  },
  methods: {
    filterCrateusRegionCities() {
      this.dataCovidRegionSertaoCrateus = this.dataCovid[0].listOfCities.filter(
        (city) => {
          for (var i = 0; i < this.ibgeIdListCitiesRegion.length; i++) {
            if (city.ibgeID === this.ibgeIdListCitiesRegion[i]) return true;
          }
          return false;
        }
      );
      // console.log(this.dataCovidRegionSertaoCrateus);
    },

    totalConfirmedCases() {
      return this.dataCovidRegionSertaoCrateus.reduce(
        (x, y) => x + parseInt(y.totalCases),
        0
      );
    },
    newConfirmedCases() {
      return this.dataCovidRegionSertaoCrateus.reduce(
        (x, y) => x + parseInt(y.newCases),
        0
      );
    },

    totalConfirmedDeaths() {
      return this.dataCovidRegionSertaoCrateus.reduce(
        (x, y) => x + parseInt(y.deaths),
        0
      );
    },
    newConfirmedDeaths() {
      return this.dataCovidRegionSertaoCrateus.reduce(
        (x, y) => x + parseInt(y.newDeaths),
        0
      );
    },

    lastUpdateDateCovid() {
      return this.dataCovid[0].updateDate;
    },
    getSourceCovid() {
      return this.dataCovid[0].source;
    },
    lastUpdateDateStateDiseases() {
      return this.dataStateDiseases[0].updateDate;
    },
    getSourceStateDiseases() {
      return this.dataStateDiseases[0].source;
    },
  },
  mounted() {
    this.$http.get(this.baseURICovid).then((result) => {
      this.dataCovid = result.data;
      this.filterCrateusRegionCities();

      this.cardConfirmados = {
        color: '#089C17',
        title: 'Confirmados',
        totalCasos: this.totalConfirmedCases().toLocaleString('pt-BR'),
        novosCasos: this.newConfirmedCases().toLocaleString('pt-BR'),
        height: '25%',
        width: '50%',
        marginRight: '17px',
        cardStyleType: 'home',
      };

      this.cardObitos = {
        color: '#F91010',
        title: 'Óbitos',
        totalCasos: this.totalConfirmedDeaths().toLocaleString('pt-BR'),
        novosCasos: this.newConfirmedDeaths().toLocaleString('pt-BR'),
        height: '25%',
        width: '50%',
        marginRight: '17px',
        cardStyleType: 'home',
      };

      this.cardObtosTotalCasos = {
        color: '#B4A200',
        title: 'Suspeitos',
        totalCasos: '40.000',
        novosCasos: '210.200',
        height: '25%',
        width: '25%',
        marginRight: '17px',
        cardStyleType: 'home',
      };

      this.updateDateCovid = this.lastUpdateDateCovid();
      this.sourceCovid = this.getSourceCovid();
    });

    this.$http.get(this.baseURIStateDiseases).then((result) => {
      this.dataStateDiseases = result.data;
      // console.log(this.dataStateDiseases[0]);
      this.updateDateStateDiseases = this.dataStateDiseases[0].updateDate;
      this.sourceStateDiseases = this.dataStateDiseases[0].source;
    });
  },
};
</script>
<style scoped>
* {
  margin: 0px;
  padding: 0px;
  width: 100%;
  font-family: 'Righteous', cursive;
  background-color: transparent;
}

.container {
  background-color: transparent;
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1410px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
    margin: auto;
  }
}
@media (max-width: 800px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 800px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
    margin: auto;
  }
  .info-obs,
  .cards-covid,
  .acess-info,
  .painel-doencas {
    padding-left: 10px !important;
  }
  .painel-doencas {
    height: 330px !important;
  }
  .cicle {
    transform: translateX(-360px) !important;
  }
  .img-doctor {
    display: none;
  }
  .info-doencas > h2 > br {
    display: none;
  }

  .info-doencas > h2 {
    text-align: center;
  }

  .info-doencas {
    width: 100% !important;
  }
  .buttons-doencas {
    text-align: center !important;
    justify-content: center !important;
  }
  .acess-info {
    flex-direction: column !important;
    align-items: center !important;
    height: 160px !important;
    flex-wrap: nowrap !important;
    padding-left: 0px !important;
    margin-top: 0px !important;
  }
  .button-acess {
    width: auto !important;
  }
  .text-custom-color {
    margin-top: 4% !important;
  }
}
@media (max-width: 480px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 480px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
  }

  .cicle {
    display: none;
  }
  .info-center {
    width: 100% !important;
  }
  .info-center > p {
    width: 100vw !important;
  }

  .info-center > h1 > br,
  p > br {
    display: none;
  }

  .info-center > h1 {
    font-size: 45px !important;
  }

  .cards-covid {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap;
    height: 100% !important;
    padding-left: 10px !important;
  }
  .cards-covid > div {
    width: 100% !important;
    margin-top: 0px !important;
    padding-left: 25px !important;
  }

  .card {
    height: 40vh !important;
  }

  .info-obs {
    padding-left: 10px !important;
  }

  .acess-info {
    padding-left: 10px !important;
    display: flex;
    flex-direction: column;
    height: 200px !important;
    flex-wrap: wrap;
    margin-top: 0px !important;
  }

  .acess-info > p {
    width: 100% !important;
    height: 20px;
  }

  .acess-info > p > br {
    display: none;
  }

  .button-acess {
    /* height: 3em !important; */
    width: 100% !important;
    padding: 0px !important;
    margin-top: 70px !important;

    text-align: center !important;
  }

  .button-acess > button {
    margin: 0px !important;
  }

  .painel-doencas {
    padding-left: 10px !important;
  }
  .info-doencas > h2 > br {
    display: none;
  }

  .info-doencas > h2 {
    text-align: center;
  }

  .info-doencas {
    width: 100% !important;
  }

  .img-doctor {
    display: none;
  }

  .column-footer {
    display: flex;
    flex-direction: column !important;
  }

  .buttons-doencas {
    text-align: center !important;
    justify-content: center !important;
  }

  .buttons-doencas > a > button {
    margin: 0px !important;
  }

  #dados-covid > br {
    display: none;
    text-align: center;
  }
  .painel-doencas {
    height: 100% !important;
  }
  .text-custom-color {
    margin-top: 8% !important;
  }
}
@media (min-width: 1300px) {
  .painel-doencas {
    margin: auto;
    flex-direction: column;
  }
}

.info {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.info-center {
  padding-right: 0px;
  background-color: transparent;
  z-index: 2;
  text-align: left;
}

.info-obs {
  display: flex;
  flex-direction: column;
  height: 20%;
  width: 100%;
  margin-top: 7%;
  padding-left: 110px;
  background-color: transparent;
}

.info-center > p {
  font-size: 25px;
  margin-top: 5%;
  height: 20px;
  font-weight: 100;
  background-color: transparent;
}

h1 {
  background-color: transparent;
  font-size: 50px;
  font-weight: 500;
}

.text-custom-color-title {
  color: #232121;
}

.city {
  color: #29b6d1;
}

.acess-map {
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  font-weight: 900;
  border: none;
  width: 236px;
  height: 52px;
  margin-top: 100px;
  border-radius: 7.5px;
  background-color: #29b6d1;
  color: white;
  text-decoration: none;
}

.map {
  transform: translateY(-65px);
  background-color: transparent;
}

.cicle {
  transform: translateX(-100px);
  clip: rect(65px, 760px, 750px, 0px);
  width: 765px;
  height: 750px;
  border-radius: 1000px;
  position: absolute;
  background-color: yellow;
  overflow: hidden;
  z-index: -10;
  background-image: linear-gradient(to bottom, #f4f3f3, #ffffff);
}

.cicle > img {
  z-index: -1;
  width: 490px;
  height: 564px;
  margin-top: 100px;
  background-color: transparent;
}

.painel-info {
  margin-top: 5rem;
  text-align: left;
  background-color: transparent;
}

#updateDate {
  margin-top: 20px;
}

#source {
  font-size: 14px;
}

.painel-info > p {
  color: #686868;
  font-size: 19px;
}

.cards-covid {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  padding-left: 110px;
  padding-right: 35px;
}

.cards-covid > div {
  background-color: transparent;
}

.acess-info {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  padding-left: 110px;
  flex-wrap: wrap;
  width: 100%;
  height: 100px;
  justify-content: center;
}

.button-acess {
  flex-wrap: wrap;
  width: 20%;
  height: 90%;
  text-align: left;
  justify-content: center;
  justify-items: center;
}

.button-acess > a > button {
  background-image: linear-gradient(to right, #29b6d1, #02bdf8);
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  margin-left: 24px;
  font-weight: 900;
  font-size: 15px;
  color: white;
  border: none;
  width: 160px;
  height: 3em;
}

.acess-info > p {
  text-align: center;
  width: 75%;
  font-weight: 600;
  font-size: 17px;
  font-family: 'Inter', sans-serif;
}

.painel-doencas {
  height: 435px;
  text-align: left;
  padding-left: 110px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}

.info-doencas {
  width: 50%;
}

.img-doctor {
  width: 50%;
  z-index: 20;
}

.buttons-doencas {
  flex-wrap: wrap;
  height: 50px;
  margin-top: 8%;
  padding-left: 0px;
}

.buttons-doencas > a > button {
  background-image: linear-gradient(to right, #29b6d1, #02bdf8);
  font-family: 'Inter', sans-serif;
  text-decoration: none;
  margin-right: 25px;
  border-radius: 8px;
  font-weight: 900;
  font-size: 15px;
  border: none;
  width: 150px;
  height: 3em;
  color: white;
}

.painel-info-doencas {
  margin-top: 2rem;
  text-align: left;
  background-color: transparent;
}

.painel-info-doencas > p {
  color: #686868;
  font-size: 19px;
}

#dados-covid {
  margin-top: 2rem;
  font-family: 'Inter', sans-serif;
  font-weight: 550;
}

.text-custom-color {
  color: #292929;
}

.img-doctor > div {
  transform: translate(170px, -270px);
  border-bottom-left-radius: 23px;
  background-color: #eaeaea;
  text-align: right;
  height: 340px;
  width: 450px;
  z-index: -1;
}

.img-doctor > img {
  z-index: 2;
  width: 520px;
  border-radius: 10px;
  position: relative;
}

.boletins-info {
  height: 130px;
  display: flex;
  flex-direction: row;
  background-color: #eaeaea;
  text-align: center;
  margin-top: 50px;
}

.boletins-info > .container {
  background-color: transparent;
  margin-top: 0px;
}

.container > p {
  font-family: 'Roboto', sans-serif;
  margin-top: 50px;
  font-weight: 600;
  background-color: transparent;
}

.container > p > a {
  width: 20vw;
  text-decoration: none;
  color: #20a5be;
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  margin-left: 10px;
  background-color: transparent;
}
</style>
